import React from 'react'

//COMPONENTS

import Icon from "components/Icon/"
import Griditem from 'components/Grid/GridItemV2'
import { translate } from 'functions'

const ErrorModal = ({ resetComponent, message, title, icon, color }) => {

    const GetModal = ({ children }) => {

        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    left: "0% !important",
                    right: "0%",
                    top: "0%",
                    bottom: "0%",
                    zIndex: "9999",
                    display: "flex",
                    justifyContent: "center",
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >
                {children}
            </div>
        );
    }

    const BoxModal = ({ children, widthSize, id }) => {

        return (
            <div
                style={{
                    width: '500px',
                    position: "absolute",
                    borderRadius: "5px",
                    backgroundColor: "#fafbfc",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    alignSelf: "center"
                }}
                id={id}
            >
                {children}
            </div>
        );
    }


    const GetButton = ({ name, handleEvent, style, disabled, id }) => {

        return (
            <button
                id={id}
                onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent' }}
                onMouseUp={handleEvent}
                disabled={disabled}
                style={{
                    ...style,
                    minWidth: "160px",
                    height: "45px",
                    cursor: "pointer",
                    border: "1px solid #d1d1cf",
                    fontWeight: "bolder",
                    backgroundColor: 'transparent'
                }}
            >
                {name}
            </button>
        )
    }

    return (
        <div style={{ height: '80vh', maxHeight: '600px', display: 'flex', justifyContent: 'center' }}>
            <GetModal>
                <BoxModal>
                    <Griditem item xs={12} style={{ borderBottom: "1px solid #ebebeb", justifyContent: "center", display: "flex", padding: '0px' }} >
                        <Icon style={{ alignSelf: 'center' }} color={color} size='30px'>{icon}</Icon>
                        <p
                            style={{
                                padding: '7px',
                                marginTop: "20px",
                                fontSize: "20px",
                                fontWeight: 'bolder',
                                marginBottom: "20px",
                                color: '#141414'
                            }}
                        >
                            {title}
                        </p>
                    </Griditem>
                    <Griditem item xs={12} style={{ justifyContent: "center", display: "flex", marginTop: '50px', padding: '0px' }} >
                        <p style={{ marginLeft: '30px', marginRight: '30px', fontWeight: 'bolder' }}>
                            {message}
                        </p>
                    </Griditem>
                    <Griditem item xs={12} style={{ justifyContent: "center", display: "flex", marginTop: '30px' }} >
                        <GetButton
                            handleEvent={resetComponent}
                            name={translate("$__back")}
                            color='red'
                            style={{
                                width: "120px",
                                height: "30px",
                                marginTop: "25px",
                                marginBottom: "25px",
                                backgroundColor: 'none',
                                color: 'black'
                            }} />
                    </Griditem>
                </BoxModal>
            </GetModal>
        </div>
    )
}

export default ErrorModal
import React, { useState, useEffect } from 'react'

//COMPONENTS
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import TableRow from './Tablerow'
import Icon from 'components/Icon';

//FUNCTIONS
import { translate } from 'functions';

const UserAddList = (props) => {
    const { edit, setEdit, dataUser, columns, orderData, orderColumn, column, setSelectUser } = props

    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([])
    const listRef = React.createRef()

    useEffect(() => {
        setData(dataUser);
    }, []);

    const handleCheckboxChange = (index) => {
        setSelected((prevUsuarios) => {
            if (prevUsuarios.includes(index)) {
                return prevUsuarios.filter((item) => item !== index);
            } else {
                return [...prevUsuarios, index];
            }
        });
    }
    const handleCheckAll = () => {
        if (selected.length === data.length) {
            setSelected([]);
        } else {
            const allIndexes = data.map((_, index) => index);
            setSelected(allIndexes);
        }
    };

    const handleDataChange = (index, newName, prop) => {
        let newData = [...data];
        newData[index][prop] = newName;
        newData && setData(newData)
    };

    const divStyles = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        flex: 1,
        /* border: '1px solid #ebebeb', */
        fontWeight: 'bold',
        alignSelf: 'stretch',
        overflow: 'hidden',
    }


    const RenderColumn = ({ name }) => {
        let colunmName = ''

        switch (name) {
            case 'nomecompleto': colunmName = translate("$__completeName");
                break;
            case 'nomedeexibição': colunmName = translate("$__showName");
                break;
            case 'email': colunmName = translate("$__email");
                break;
            case 'departamento/grupo': colunmName = translate("$__department/group");
                break;
            case 'ramal': colunmName = translate("$__branchLine");
                break;
            case 'observações': colunmName = translate("$__observation");
                break;
            case 'celular': colunmName = translate("$__phone");
                break;
            default:
                break;
        }

        return <div style={{ ...divStyles, cursor: 'pointer' }} onClick={() => orderColumn(name)}>
            <p style={{
                width: 'auto',
                alignSelf: 'center',
                padding: '0px',
                overflow: 'auto',
                margin: '0px 5px 0px 10px'
            }}>
                {colunmName}
            </p>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                color: column && column === name ? 'rgb(192,178,131)' : 'rgb(0, 24, 54)',
                padding: '0px 5px'
            }}>
                <Icon style={{ fontSize: '1.1rem', width: '0.9rem', height: '0.9rem' }}>arrow_drop_up</Icon>
                <Icon style={{ fontSize: '1.1rem', width: '0.9rem', height: '0.9rem' }}>arrow_drop_down</Icon>
            </div>
        </div>
    }

    return (
        <>
            <div style={{ justifyContent: "center", padding: '10px', position: 'relative', height: '100%', backgroundColor: '#fff', borderRadius: '5px', width: '100%' }} >
                {data?.length === 0 && null}
                <div>
                    {data?.length > 0 && <div id='table-container' style={{ marginBottom: '5px', height: '65vh', maxHeight: '600px', overflow: 'hidden', border: '1px solid #ebebeb' }}>
                        <div style={{ overflowY: 'hidden' }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '50px', color: '#141414', backgroundColor: 'rgb(241 241 241)' }}>
                                {edit ?
                                    <div style={{
                                        ...divStyles,
                                        width: '50px',
                                        borderTop: '1px solid #ebebeb',
                                        borderBottom: '1px solid #ebebeb',
                                        flex: 'none',
                                        justifyContent: 'center',
                                        display: 'flex'
                                    }}>
                                        <input
                                            type="checkbox"
                                            style={{
                                                width: "16px",
                                                height: "16px",
                                                borderRadius: "15px",
                                                cursor: "pointer",
                                                backgroundColor: "transparent",
                                            }}
                                            checked={selected.length === data.length}
                                            indeterminate={selected.length > 0 && selected.length < data.length}
                                            onChange={handleCheckAll}
                                        />
                                    </div> : null}
                                {columns.length > 0 && columns.map((item, ii) => {
                                    return (
                                        <RenderColumn name={item} key={ii} />
                                    )
                                })}
                                {edit ? <>
                                    <div style={{
                                        ...divStyles,
                                        width: '60px',
                                        borderTop: '1px solid #ebebeb',
                                        borderBottom: '1px solid #ebebeb',
                                        flex: 'none',
                                        display: 'flex',
                                        color: 'rgb(159 159 159)',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <p style={{
                                            alignSelf: 'center',
                                            padding: '0px',
                                            margin: '0px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>{translate("$__action")}</p>
                                    </div></> : null}
                            </div>
                        </div>
                        {data?.length > 0 &&
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        itemData={orderData}
                                        height={height}
                                        itemCount={data?.length}
                                        itemSize={44}
                                        width={width}
                                        ref={listRef}
                                    >
                                        {({ data, index, style }) => {
                                            return (
                                                <div key={`a_${index}_${Math.random(0, 99999999)}`}>
                                                    <TableRow
                                                        edit={edit}
                                                        selected={selected && selected.includes(index) ? true : false}
                                                        column={columns}
                                                        handleDataChange={(index, value, prop) => handleDataChange(index, value, prop)}
                                                        handleCheckboxChange={handleCheckboxChange}
                                                        style={style}
                                                        data={data}
                                                        usuario={dataUser[index]}
                                                        index={index}
                                                        isActive={data.length > 0 ? true : false}
                                                        setSelectUser={setSelectUser}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </FixedSizeList >
                                )}
                            </AutoSizer>
                        }
                    </div>}
                </div>
                {
                    edit ?
                        <div
                            style={{
                                display: 'flex',
                                borderTop: '#e8e8e8 1px solid',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '20px',
                                padding: '15px 20px',
                            }}>
                            <button
                                style={{
                                    padding: '7px 30px',
                                    border: 'rgb(1, 30, 65) 1px solid',
                                    background: 'linear-gradient(90deg, rgb(1, 30, 65) 0%, rgb(0, 49, 112) 100%)',
                                    color: 'rgb(192,178,131)',
                                    borderRadius: '4px',
                                    fontWeight: '600',
                                    cursor: 'pointer'
                                }}>{translate('$__deleteSelected')}</button>
                            <button
                                style={{
                                    padding: '7px 30px',
                                    border: 'rgb(1, 30, 65) 1px solid',
                                    background: 'linear-gradient(90deg, rgb(1, 30, 65) 0%, rgb(0, 49, 112) 100%)',
                                    color: 'rgb(192,178,131)',
                                    borderRadius: '4px',
                                    fontWeight: '600',
                                    cursor: 'pointer'
                                }}>{translate('$__save')}</button>
                            <button
                                onClick={() => setEdit(!edit)}
                                style={{
                                    padding: '7px 25px',
                                    border: '1px solid rgb(184 184 184)',
                                    backgroundColor: '#e8e8e8',
                                    color: 'rgb(1, 30, 65)',
                                    borderRadius: '4px',
                                    fontWeight: '600',
                                    cursor: 'pointer'
                                }}>{translate('$__cancel')}</button>
                        </div> : null
                }
            </div>
        </>
    );
}


export default UserAddList
import React from "react"

//COMPONENTS
import HeaderWithIcon from "components/Header/card"

//@MATERIAL
import Dialog from "@material-ui/core/Dialog";

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { translate } from 'functions/'


function CardActions(props) {
    console.log("AQUI PRECISA SER CRIADO O PAINEL DE MODELOS DE FLUXOS")
    return (
        <Dialog
            fullWidth={true}
            // onClose={() => setMigrator(false)}
            aria-labelledby="simple-dialog-title"
            open={props.card.action ? true : false}
        >
            <div style={{ position: "relative", marginLeft: "10px", marginTop: '5px', width: "calc(100% - 10px)" }}>
                <HeaderWithIcon
                    title={translate(`$__${props.card.action}`, 1)}
                    icon="label"
                    onCancel={() => {
                        props.reduxFunction("ASYNC", "SET_DB", {
                            ...reduxStore.getState().db,
                            history: `${window.location.pathname}${window.location.hash}`
                        })

                    }}
                />
            </div>
            {/*
                <div style={{ color: "#CCCCCC", padding: "15px" }}>
                    {
                        MigratorData._childrens
                        && !MigratorData._skipGoals
                        && MigratorData._goals.length > 0
                        && MigratorData._goals.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length > 0
                        &&
                        <React.Fragment>
                            <span style={{ color: 'gray' }}>#NEED_TRANSLATE:: Você possui metas vinculadas aos objetivos/ou diretrizes que deseja migrar. Você deseja transformar essas metas em ações?
                                <p style={{ color: 'red' }}>Essa ação só pode ser desfeita manualmente</p>
                            </span>
                            {MigratorData._goals
                                .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type)
                                .sort((a, b) => {
                                    if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'ASC'
                                        && a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'DESC'
                                        && a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle) {
                                        return 0
                                    } else if (!reduxStore.getState().db.orderByTitle && String(a.order) && String(b.order) && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                }
                                ).map((a, i) =>
                                    <div style={{ width: 'calc(100%)', display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                                        <Card db="cards" data={a} db='cards' noShadow />
                                        <Button
                                            onClick={() => {
                                                updateCard(props, {
                                                    _id: a._id,
                                                    type: 'task'
                                                })
                                            }}
                                        >
                                            <Icon
                                                icon="change_circle"
                                                color={customApp('menu')}
                                                title={translate('$__transformInTask')}
                                            />
                                        </Button>
                                    </div>
                                )
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__skipAll")}
                                    text={translate("$__skipAll")}
                                    color="lightgrey"
                                    icon={"skip_next"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigratorData({
                                            ...MigratorData,
                                            _skipGoals: true
                                        })
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__changeAllInTasks")}
                                        text={translate("$__changeAllInTasks")}
                                        icon={"change_circle"}
                                        size={"25px"}
                                        onClick={() => {
                                            iniMigration('goals')
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipGoals: true
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                    {
                        MigratorData._childrens
                        && !MigratorData._skipObjectives
                        && MigratorData._objectives.length > 0
                        && MigratorData._objectives
                            .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length > 0
                        &&
                        <React.Fragment>
                            <span style={{ color: 'gray' }}>#NEED_TRANSLATE:: Você possui OBJETIVOS vinculadas aos à diretriz que deseja migrar. Você deseja transformar em metas?
                                <p style={{ color: 'red' }}>Essa ação só pode ser desfeita manualmente</p>
                            </span>
                            {MigratorData._objectives
                                .filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type)
                                .sort((a, b) => {
                                    if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'ASC'
                                        && a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle
                                        && reduxStore.getState().db.orderByTitlePosition === 'DESC'
                                        && a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
                                    ) {
                                        return -1
                                    } else if (reduxStore.getState().db.orderByTitle) {
                                        return 0
                                    } else if (!reduxStore.getState().db.orderByTitle && String(a.order) && String(b.order) && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
                                        return -1
                                    } else {
                                        return 1
                                    }
                                }
                                ).map((a, i) =>
                                    <div style={{ width: 'calc(100%)', display: "flex", alignItems: "center", justifyContent: "center" }} key={a._id}>
                                        <Card db="cards" data={a} noShadow />
                                        <Button
                                            onClick={() => {
                                                updateCard(props, {
                                                    _id: a._id,
                                                    type: 'goal'
                                                })
                                            }}
                                        >
                                            <Icon
                                                icon="change_circle"
                                                color={customApp('menu')}
                                                title={translate('$__transformInGoal')}
                                            />
                                        </Button>
                                    </div>
                                )
                            }
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__skipAll")}
                                    text={translate("$__skipAll")}
                                    color="lightgrey"
                                    icon={"skip_next"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigratorData({
                                            ...MigratorData,
                                            _skipObjectives: true
                                        })
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__changeAllInGoal")}
                                        text={translate("$__changeAllInGoal")}
                                        icon={"change_circle"}
                                        size={"25px"}
                                        onClick={() => {
                                            iniMigration('objectives')
                                            setMigratorData({
                                                ...MigratorData,
                                                _skipObjectives: true
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ marginLeft: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__cancel")}
                                    text={translate("$__cancel")}
                                    icon={"cancel"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigrator(false)
                                    }}
                                />
                            </div>
                        </React.Fragment>
                    }

                    {(
                        (
                            MigratorData
                            && MigratorData._childrens
                            && Object.keys(MigratorData._childrens).length > 0
                            && MigratorData._goals.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length === 0
                            && MigratorData._objectives.filter(a => reduxStore.getState().db.cards[a._id] && reduxStore.getState().db.cards[a._id].type === a.type).length === 0
                        )
                        || (
                            (
                                MigratorData._skipGoals
                                && MigratorData._goals.length > 0
                            )
                            &&
                            (
                                MigratorData._skipObjectives
                                && MigratorData._objectives.length > 0
                            )
                        )
                    )
                        &&
                        <React.Fragment>
                            <span style={{ color: 'red' }}>#NEED_TRANSLATE:: Clique em salvar para finalizar as migrações.</span>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__cancel")}
                                    text={translate("$__cancel")}
                                    color="lightgrey"
                                    icon={"close"}
                                    size={"25px"}
                                    onClick={() => {
                                        setMigrator(false)
                                        setMigratorData({})
                                    }}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                    <CustomButton
                                        shadow
                                        title={translate("$__save")}
                                        text={translate("$__save")}
                                        icon={"done_all"}
                                        size={"25px"}
                                        onClick={() => {
                                            finishMigration()
                                        }}
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div> */}
        </Dialog>
    )
}

const mapStateToProps = (store) => ({
    card: store.card
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CardActions)

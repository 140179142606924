import React from "react";

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment";

//COMPONENTS
import Icon from "components/Icon";
import TextField from "components/TextField";

//FUNCTIONS
import { translate } from "functions/";

export default function LoginPage(props) {
    const [user, setUser] = React.useState({ cPassword: '', password: '' });
    const [confirmed, setConfirmed] = React.useState(false)
    const [strength, setStrength] = React.useState(0)

    const calcPass = (password) => {
        let matchedCase = [];
        matchedCase.push("[$@$!%*#?&]");
        matchedCase.push("[A-Z]");
        matchedCase.push("[0-9]");
        matchedCase.push("[a-z]");

        let ctr = 0;
        for (let i = 0; i < matchedCase.length; i++) {
            if (new RegExp(matchedCase[i]).test(password)) {
                ctr++;
            }
        }

        switch (ctr) {
            case 0:
                setStrength(0)
                break;
            case 1:
                setStrength(1)
                break;
            case 2:
                setStrength(2)
                break;
            case 3:
                setStrength(3)
                break;
            case 4:
                setStrength(4)
                break;
            default:
        }

        if (password.length < 8) {
            setStrength(0)
        }
    }

    return (
        <React.Fragment>
            <div style={{ textAlign: 'left' }}>
                <p>{translate('$__passwordTip_1')}</p>
                <p>{translate('$__passwordTip_2')}</p>
                <ul>
                    <li>{translate('$__passwordTip_3')}</li>
                    <li>{translate('$__passwordTip_4')}</li>
                    <li>{translate('$__passwordTip_5')}</li>
                    <li>{translate('$__passwordTip_6')}</li>
                    <li>{translate('$__passwordTip_7')}</li>
                </ul>
            </div>
            <TextField
                label={translate('$__password', 1)}
                variant={'standard'}
                name={'password_primary'}
                type={'password'}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    value: user.password,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon>lock_outline</Icon>
                        </InputAdornment>
                    ),
                    autoComplete: 'new-password',
                    onChange: e => {
                        setUser({
                            ...user,
                            password: e.target.value
                        })
                        calcPass(e.target.value)
                        if (strength > 2 && user.password === e.target.value) {
                            setConfirmed(true)
                        } else {
                            setConfirmed(false)
                        }
                    },
                }}
                value={user.password}
            />
            <TextField
                label={translate('$__passwordConfirm', 1)}
                variant={'standard'}
                name={'password_secondary'}
                type={'password'}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    value: user.cPassword,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon color={confirmed ? "green" : "lightgray"}>
                                {confirmed ? 'verified' : 'check'}
                            </Icon>
                        </InputAdornment>
                    ),
                    autoComplete: 'new-password',
                    onChange: e => {
                        setUser({
                            ...user,
                            cPassword: e.target.value
                        })
                        if (strength > 2 && user.password === e.target.value) {
                            setConfirmed(true)
                            props.onConfirmed(e.target.value)
                        } else {
                            setConfirmed(false)
                            props.onConfirmed(null)
                        }
                    },
                }}
                value={user.cPassword}
            />
            {
                user.password && strength > 0 &&
                <div style={{
                    position: 'relative',
                    width: '100%',
                    margin: 'auto',
                    marginBottom: '7px',
                    padding: '3px 0',
                }}>
                    {strength === 1 ?
                        translate('$__passwordTooWeak', 1)
                        : strength === 2 ?
                            translate('$__weakPassword', 1)
                            : strength === 3 ?
                                translate('$__goodPassword', 1)
                                : strength === 4 ?
                                    translate('$__veryGoodPassword', 1)
                                    : null
                    }
                </div>
            }
            {
                user.password &&
                <div style={{
                    position: 'relative',
                    width: '100%',
                    margin: 'auto',
                    height: '13px',
                    padding: '3px 0',
                    border: '1px solid rgba(0,0,0,0.1)'
                }}>
                    {strength >= 0 &&
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            width: 'calc(20% - 6px)',
                            height: '5px',
                            backgroundColor: 'red',
                            margin: '0 3px'
                        }} />
                    }
                    {strength >= 1 &&
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            width: 'calc(20% - 6px)',
                            height: '5px',
                            backgroundColor: 'rgb(129,153,179)',
                            margin: '0 3px'
                        }} />
                    }
                    {strength >= 2 &&
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            width: 'calc(20% - 6px)',
                            height: '5px',
                            backgroundColor: 'rgb(95,151,254)',
                            margin: '0 3px'
                        }} />
                    }
                    {strength >= 3 &&
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            width: 'calc(20% - 6px)',
                            height: '5px',
                            backgroundColor: 'rgb(42,66,255)',
                            margin: '0 3px'
                        }} />
                    }
                    {strength === 4 &&
                        <div style={{
                            position: 'relative',
                            float: 'left',
                            width: 'calc(20% - 6px)',
                            height: '5px',
                            backgroundColor: 'rgb(38,165,45)',
                            margin: '0 3px'
                        }} />
                    }
                </div>
            }
        </React.Fragment>
    );
}
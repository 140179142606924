//ICONS
import GuidelineD from "assets/icons/GuidelineC";
import GuidelineC from "assets/icons/GuidelineD";
import GuidelineR from "assets/icons/GuidelineE";
import GuidelineE from "assets/icons/GuidelineR";
import MicrosoftWord from "assets/icons/microsoftWord.svg";
import MicrosoftExcel from "assets/icons/microsoftExcel.svg";
import MicrosoftPowerpoint from "assets/icons/microsoftPowerpoint.svg";
import SwotIcon from "assets/icons/SWOT";
import MatrixIcon from "assets/icons/Matrix.js"
import PDF from "assets/icons/pdf.svg";
import BrightAndConnectedIcon from "assets/icons/BrigthAndConnected";
import packageJson from '../../package.json';


const initialState = {
    version: packageJson.version,
    swot: [
        { value: 'strength', label: '$__strength', icon: 'arrow_upward' },
        { value: 'weakness', label: '$__weakness', icon: 'arrow_downward' },
        { value: 'opportunity', label: '$__opportunity', icon: 'trending_up' },
        { value: 'threat', label: '$__threat', icon: 'trending_down' }
    ],
    languages: [
        { value: 'pt-BR', label: '$__pt-BR' },
        { value: 'en-US', label: '$__en-US' },
        { value: 'es-ES', label: '$__es-ES' },
    ],
    planType: [
        {
            label: "$__strategic",
            value: null,
            swot: true,
            overview: true,
            description: "$__strategicPlanDescription"
        },
        {
            label: "$__flowOrProcess", //fluxos / processos
            value: "operational",
            overview: false,
            swot: false,
            kanban: true,
            description: "$__flowOrProcesPlanDescription"
        },
        {
            label: "$__tacticAndOrOperational",
            value: "flow",
            swot: false,
            kanban: false,
            overview: true,
            description: "$__operationalPlanDescription"
        },
        {
            label: "$__crm",
            value: "crm",
            swot: false,
            kanban: true,
            overview: false,
            description: "$__crmPlanDescription"
        }
    ],
    status: [
        { value: 'notStarted', label: '$__notStarted', icon: 'query_builder', color: '#929497' },
        { value: 'inProgress', label: '$__inProgress', icon: 'play_circle_outline', color: '#011E41' },
        { value: 'awaitAction', label: '$__await', icon: 'hourglass_top', color: 'pink' },
        { value: 'paused', label: '$__paused', icon: 'pause_presentation', color: '#EBA900' },
        {
            value: 'completed', label: '$__completed', icon: 'verified', color: '#5A7F71', props: {
                periodFilter: true
            }
        }
    ],
    currency: [
        { value: 'BRL', label: '$__currencyBRL', symbol: 'R$' },
        { value: 'USD', label: '$__currencyUSD', symbol: '$' },
        { value: 'EUR', label: '$__currencyEUR', symbol: '€' },
        // { value: 'CLP', label: '$__currencyCLP', symbol: '$' },
        // { value: 'AUD', label: '$__currencyAUD', symbol: '$' },
        // { value: 'NZD', label: '$__currencyNZD', symbol: '$' },
        // { value: 'CNY', label: '$__currencyCNY', symbol: '¥' },
        // { value: 'PYG', label: '$__currencyPYG', symbol: 'Gs' },
        // { value: 'RUB', label: '$__currencyRUB', symbol: '₽' }
    ],
    expiresIn: [
        // { value: '48h', label: '$__48hours' },
        // { value: '24h', label: '$__24hours' },
        { value: '12h', label: '$__12hours' },
        { value: null, label: '$__6hours' },
        { value: '3h', label: '$__3hours' },
        { value: '1h', label: '$__1hour' },
    ],
    passwordExpire: [
        { value: '30', label: '$__30days' },
        { value: '60', label: '$__60days' },
        { value: '90', label: '$__90days' },
    ],
    plans: [
        {
            value: 'p0', label: '$__saasPlanName0', settings: {
                space: 5368709120,
                admins: 1,
                price: 0,
                onBoarding: false,
                brightAndConnected: {
                    feed: false,
                    feedDays: false,
                    radarLimit: 25,
                    // radarDays: 10,
                    insight: false,
                },
                plan: {
                    activeLimit: 2,
                    planClient: false,
                    clientAdmin: false,
                    machenPlan: false,
                    templates: false,
                    guidelineLimit: 2,
                    feedLimit: 4,
                    radarLimit: 25,
                    insightLimit: 25,
                },
                task: {
                    activeLimit: 25,
                    filter: false,
                    kpi: false,
                    timeSheet: false,
                    recurrence: false
                },
                files: {
                    uploadLimit: 26214400,
                },
                chat: {
                    groupUsersLimit: 7,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: false,
                },
                mobile: {

                },
                addOn: {
                    learneng: false,
                    denkenDiscount: 0,
                }
            }
        },
        {
            value: 'p1', label: '$__saasPlanName1', settings: {
                space: 26843545600,
                admins: 1,
                price: 149.00,
                onBoarding: 0,
                whiteLabel: false,
                brightAndConnected: {
                    feed: true,
                    feedDays: 15,
                    radarLimit: 0,
                    // radarDays: 90,
                    insight: true,
                },
                plan: {
                    planLimit: 0,
                    planClient: true,
                    clientAdmin: 1,
                    machenPlan: true,
                    templates: true,
                    guidelineLimit: 0,
                    feedLimit: 0,
                    radarLimit: 0,
                    insightLimit: 0,
                },
                task: {
                    activeLimit: 0,
                    filter: true,
                    kpi: true,
                    timeSheet: true,
                    recurrence: true
                },
                files: {
                    uploadLimit: 104857600,
                },
                chat: {
                    groupLimit: 0,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: true,
                },
                mobile: {

                },
                addOn: {
                    learneng: true,
                    denkenDiscount: 5,
                }
            }
        },
        {
            value: 'p2', label: '$__saasPlanName2', settings: {
                space: 53687091200,
                admins: 9,
                price: 890.00,
                onBoarding: 4990.00,
                brightAndConnected: {
                    feed: true,
                    feedDays: 0,
                    radarLimit: 0,
                    // radarDays: 90,
                    insight: true,
                },
                plan: {
                    planLimit: 0,
                    planClient: true,
                    clientAdmin: 2,
                    machenPlan: true,
                    templates: true,
                    guidelineLimit: 0,
                    feedLimit: 0,
                    radarLimit: 0,
                    insightLimit: 0,
                },
                task: {
                    activeLimit: 0,
                    filter: true,
                    kpi: true,
                    timeSheet: true,
                    recurrence: true
                },
                files: {
                    uploadLimit: 314572800,
                },
                chat: {
                    groupLimit: 0,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: true,
                },
                mobile: {

                },
                addOn: {
                    learneng: true,
                    denkenDiscount: 0,
                    whiteLabel: false,
                }
            }
        },
        {
            value: 'p3', label: '$__saasPlanName3', settings: {
                space: 107374182400,
                admins: 19,
                price: 1790.00,
                onBoarding: 6990.00,
                brightAndConnected: {
                    feed: true,
                    feedDays: 0,
                    radarLimit: 0,
                    // radarDays: 90,
                    insight: true,
                },
                plan: {
                    planLimit: 0,
                    planClient: true,
                    clientAdmin: 3,
                    machenPlan: true,
                    templates: true,
                    guidelineLimit: 0,
                    feedLimit: 0,
                    radarLimit: 0,
                    insightLimit: 0,
                },
                task: {
                    activeLimit: 0,
                    filter: true,
                    kpi: true,
                    timeSheet: true,
                    recurrence: true
                },
                files: {
                    uploadLimit: 314572800,
                },
                chat: {
                    groupLimit: 0,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: true,
                },
                mobile: {

                },
                addOn: {
                    learneng: true,
                    denkenDiscount: 0,
                    whiteLabel: false,
                }
            }
        },
        {
            value: 'p4', label: '$__saasPlanName4', settings: {
                space: 214748364800,
                admins: 49,
                price: 3990.00,
                onBoarding: 10990.00,
                brightAndConnected: {
                    feed: true,
                    feedDays: 0,
                    radarLimit: 0,
                    // radarDays: 90,
                    insight: true,
                },
                plan: {
                    planLimit: 0,
                    planClient: true,
                    clientAdmin: 3,
                    machenPlan: true,
                    templates: true,
                    guidelineLimit: 0,
                    feedLimit: 0,
                    radarLimit: 0,
                    insightLimit: 0,
                },
                task: {
                    activeLimit: 0,
                    filter: true,
                    kpi: true,
                    timeSheet: true,
                    recurrence: true
                },
                files: {
                    uploadLimit: 314572800,
                },
                chat: {
                    groupLimit: 0,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: true,
                },
                mobile: {

                },
                addOn: {
                    learneng: true,
                    denkenDiscount: 0,
                    whiteLabel: false,
                }
            }
        },
        {
            value: 'p5', label: '$__saasPlanName5', settings: {
                space: 0,
                admins: 0,
                price: 0,
                onBoarding: 0,
                brightAndConnected: {
                    feed: true,
                    feedDays: 0,
                    radarLimit: 0,
                    // radarDays: 90,
                    insight: true,
                },
                plan: {
                    planLimit: 0,
                    planClient: true,
                    clientAdmin: 3,
                    machenPlan: true,
                    templates: true,
                    guidelineLimit: 0,
                    feedLimit: 0,
                    radarLimit: 0,
                    insightLimit: 0,
                },
                task: {
                    activeLimit: 0,
                    filter: true,
                    kpi: true,
                    timeSheet: true,
                    recurrence: true
                },
                files: {
                    uploadLimit: 314572800,
                },
                chat: {
                    groupLimit: 0,
                },
                support: {
                    email: true,
                    priority: 0,
                    chat: true,
                },
                mobile: {

                },
                addOn: {
                    learneng: true,
                    denkenDiscount: 0,
                    whiteLabel: false,
                },
            }
        },
    ],
    type: [
        { value: 'plan', label: '$__plan', icon: 'view_comfy', color: null, calendar: false },
        { value: 'strength', label: '$__strength', icon: 'arrow_upward', color: null, calendar: false },
        { value: 'weakness', label: '$__weakness', icon: 'arrow_downward', color: null, calendar: false },
        { value: 'opportunity', label: '$__opportunity', icon: 'trending_up', color: null, calendar: false },
        { value: 'threat', label: '$__threat', icon: 'trending_down', color: null, calendar: false },
        { value: 'guideline', label: '$__guideline', icon: MatrixIcon, color: null, calendar: true },
        { value: 'guidelineD', label: '$__guidelineD', icon: GuidelineD, color: null, calendar: true },
        { value: 'guidelineR', label: '$__guidelineR', icon: GuidelineR, color: null, calendar: true },
        { value: 'guidelineC', label: '$__guidelineC', icon: GuidelineC, color: null, calendar: true },
        { value: 'guidelineE', label: '$__guidelineE', icon: GuidelineE, color: null, calendar: true },
        { value: 'objective', label: '$__objective', icon: 'gps_fixed', color: null, calendar: true },
        { value: 'goal', label: '$__goal', icon: 'flag', color: null, calendar: true },
        { value: 'iniciative', label: '$__iniciative', icon: 'play_arrow', color: null, calendar: true },
        { value: 'task', label: '$__task', icon: 'fact_check', color: null, calendar: true },
        { value: 'meeting', label: '$__meeting', icon: 'meeting_room', color: null, calendar: true },
        { value: 'myTask', label: '$__myTask', icon: 'person_pin', color: null, calendar: true },
        { value: 'link', label: '$__radar', icon: 'public', color: null, calendar: true },
        { value: 'insight', label: '$__insight', icon: BrightAndConnectedIcon, color: null, calendar: true },
    ],
    dateStatus: [
        { value: 'notStarted', label: '$__notStarted', icon: 'query_builder' },
        { value: 'inProgress', label: '$__inProgress', icon: 'play_circle_outline' },
    ],
    priority: [
        { value: null, label: '$__none', color: '#c6c6c6' },
        { value: 'normal', label: '$__normal', color: '#c6c6c6' },
        { value: 'low', label: '$__low', color: '#faba00' },
        { value: 'medium', label: '$__medium', color: '#ff7c32' },
        { value: 'high', label: '$__high', color: '#ff0000' }
    ],
    automation: [
        { value: 'doNotChange', label: '$__doNotChange' },
        { value: 'replace', label: '$__replaceWith' },
        { value: 'add', label: '$__add' },
        { value: 'remove', label: '$__remove' },
        { value: 'removeAll', label: '$__removeAll' },
    ],
    fileExtensions: [
        { ext: ['jpg', 'jpeg', 'jpe', 'jfif', 'gif', 'png', 'webp', 'svg', 'eps'], icon: false, title: 'image', viewer: 'img' },
        { ext: ['txt'], icon: 'description', title: 'Text', viewer: 'text' },
        { ext: ['doc', 'docx', 'dot', 'doc', 'dotx'], icon: MicrosoftWord, title: 'Microsoft Word', viewer: 'microsoft' },
        { ext: ['xls', 'xlt', 'ooxml', 'xlsx'], icon: MicrosoftExcel, title: 'Microsoft Excel', viewer: 'microsoft' },
        { ext: ['ppt', 'pptx', 'ppsx', 'sldx'], icon: MicrosoftPowerpoint, title: 'Microsoft PowerPoint', viewer: 'microsoft' },
        { ext: ['pdf'], icon: PDF, title: 'PDF', viewer: 'pdf' },
        { ext: ['aac', 'wav', 'ts', 'smf', 'ogg', 'mp3', '3gp', 'caf', 'm4a'], icon: 'audiotrack', title: 'audioFile', viewer: 'audio' },
        { ext: ['avi', 'mov', 'mp4', '3gpp'], icon: 'movie', title: 'movieFile', viewer: 'movie' }
    ],
    access: [
        {
            name: '$__admin',
            permissions: {
                create: true,
                read: true,
                run: true,
                update: true,
                delete: true,
                moderate: true,
                levels: 0 //0=infinito
            }
        },
        {
            name: '$__manager',
            permissions: {
                create: true,
                read: true,
                run: true,
                update: true,
                delete: true,
                moderate: true,
                levels: 0 //0=infinito
            }
        },
        {
            name: '$__user',
            permissions: {
                create: true,
                read: true,
                run: true,
                update: false,
                delete: false,
                moderate: false,
                levels: 0 //0=infinito
            }
        },
        {
            name: '$__guest',
            permissions: {
                create: false,
                read: true,
                run: true,
                update: false,
                delete: false,
                moderate: false,
                levels: 1 //0=infinito
            }
        }
    ],
    tag: [],
    userGroup: [],
    dataType: [
        {
            name: 'user',
            icon: 'contact_page',
            module: 'user',
            reqId: false,
            view: {
                'c': {
                    pages: ['users'],
                    access: ['userAdmin', 'userModerate', 'master']
                },
            },
            hash: null,
            db: 'users',
            iniModule: 'userEdit',
            modules: ['resumeUser', 'userEdit', 'access', 'twoFactor', 'cards', 'mobile', 'settings', 'delete'],
            requireFields: ['name', 'email'],
            hideFields: ['independentAccount'],
            disabledFields: [],
            data: {
                type: "user"
            },
            sharedIn: {
                attach: [],
                copy: [],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'user',
            icon: 'contact_page',
            module: 'user',
            reqId: false,
            view: {
                'c': {
                    pages: ['units'],
                    access: ['userAdmin', 'userModerate', 'master']
                },
            },
            hash: true,
            db: 'users',
            iniModule: 'userEdit',
            modules: ['resumeUser', 'userEdit', 'access', 'twoFactor', 'cards', 'mobile', 'settings', 'delete'],
            requireFields: ['name', 'email'],
            hideFields: ['independentAccount'],
            disabledFields: [],
            data: {
                type: "user"
            },
            sharedIn: {
                attach: [],
                copy: [],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'departments',
            icon: 'groups',
            module: 'user',
            reqId: false,
            view: {
                'c': {
                    pages: ['units'],
                    access: ['userAdmin', 'userModerate'],
                    noId: true
                },
            },
            hash: null,
            db: 'cards',
            iniModule: 'userEdit',
            modules: ['userEdit', 'delete', 'settings'],
            requireFields: ['name'],
            disabledFields: ['email'],
            hideFields: ['independentAccount'],
            data: {
                type: "group"
            },
        },
        {
            name: 'client',
            title: 'unity',
            icon: 'business',
            module: 'user',
            reqId: false,
            view: {
                'c': {
                    pages: ['units'],
                    access: ['userAdmin', 'userModerate'],
                    noId: true
                },
            },
            hash: null,
            db: 'cards',
            iniModule: 'userEdit',
            modules: ['userEdit', 'delete', 'settings'],
            requireFields: ['name'],
            disabledFields: ['email'],
            hideFields: [],
            data: {
                type: "client"
            },
        },
        // {
        //     name: 'unity',
        //     icon: 'badge',
        //     module: 'user',
        //     reqId: false,
        //     view: {
        //         'c': {
        //             pages: ['units'],
        //             access: ['master'],
        //             noId: true
        //         },
        //     },
        //     hash: null,
        //     db: 'users',
        //     iniModule: 'userEdit',
        //     modules: ['userEdit', 'delete', 'settings'],
        //     requireFields: ['name'],
        //     disabledFields: ['email'],
        //     data: {
        //         type: "client"
        //     },
        // },
        {
            name: 'step',
            icon: '123',
            module: 'step',
            reqId: true,
            db: 'cards',
            iniModule: 'columnEdit',
            modules: ['columnEdit', 'users', 'flow', 'forms', 'delete', 'log', 'settings'],
            requireFields: ['name'],
            disabledFields: [],
            fieldsTitle: {
                name: '$__columnName',
                placeholder: '$__columnPlaceholder'
            },
            sharedIn: {
                attach: [],
                copy: [],
                shared: []
            }
        },
        {
            name: 'chatGroup',
            icon: 'group_work',
            module: 'chat',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['cardEdit', 'users', 'files', 'delete', 'log'],
            hideFields: ['image', 'progress', 'status', 'tags', 'deadline', 'priority', 'recurrence', 'plan'],
            requireFields: ['name'],
            disabledFields: [],
            fieldsTitle: {
                name: '$__groupName',
                placeholder: '$__groupNamePlaceholder'
            },
            sharedIn: {
                attach: [],
                copy: [],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'feed',
            icon: 'rss_feed',
            module: 'feeds',
            reqId: false,
            view: {
                'bright-and-connected': {
                    pages: ['feeds'],
                },
                'sp': {
                    pages: ['bright-and-connected']
                }
            },
            db: 'feeds',
            iniModule: 'feedEdit',
            modules: ['resumeFeed', 'feedEdit'],
            disabledFields: [],
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: [],
                copy: ['plan', 'client'],
                shared: []
            }
        },
        {
            name: 'link',
            icon: 'public',
            module: 'insights',
            reqId: true,
            view: {
                'bright-and-connected': {
                    pages: ['radar']
                },
                'sp': {
                    pages: ['radar']
                }
            },
            hash: null,
            db: 'cards',
            iniModule: 'link',
            modules: ['resumeLink', 'cardEdit', 'checklist', 'steps', 'timeline', 'sendTo', 'log', 'users', 'files', 'timeLog', 'settings', 'delete', 'myDay'],
            hideFields: ['image', 'status', 'client', 'recurrence'],
            disabledFields: ['url'],
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan', 'client'],
                copy: ['plan', 'client'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'insight',
            icon: BrightAndConnectedIcon,
            module: 'insights',
            reqId: true,
            view: {
                'bright-and-connected': {
                    pages: ['insight-boards']
                },
                'sp': {
                    pages: ['insight-boards']
                }
            },
            hash: null,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resumeLink', 'cardEdit', 'checklist', 'timeline', 'sendTo', 'log', 'users', 'files', 'timeLog', 'settings', 'delete', 'myDay'],
            hideFields: ['image', 'status', 'client', 'recurrence'],
            // disabledFields: ['url'],
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan', 'client'],
                copy: ['plan', 'client'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'plan',
            icon: 'view_comfy',
            module: 'plan',
            reqId: false,
            view: {
                'sp': {
                    pages: ['StrategyAndPlans', 'home'],
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'steps', 'timeline', 'users', 'sendTo', 'log', 'files', 'timeLog', 'delete', 'settings'],
            hideFields: ['image', 'recurrence'],
            requireFields: ['name'],
            disabledFields: [],
            data: {
                status: "notStarted"
            },
            loadChildrens: true,
            sharedIn: {
                attach: [],
                copy: ['client'],
                shared: []
            },
            fieldsTitle: {
                name: "$__planName",
                placeholder: "$__setPlanName"
            }
        }, {
            name: 'plan',
            icon: 'tips_and_updates',
            module: 'plan',
            reqId: false,
            view: {
                'sp': {
                    pages: ['templates'],
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'steps', 'templateOptions', 'steps', 'timeline', 'files', 'delete'],
            hideFields: ['image', 'recurrence'],
            requireFields: ['name'],
            disabledFields: [],
            data: {
                status: "notStarted"
            },
            fieldsTitle: {
                name: "$__planName",
                placeholder: "$__setPlanName"
            },

        },
        {
            name: 'objective',
            icon: 'gps_fixed',
            module: 'planning',
            myTasks: true,
            reqId: true,
            view: {
                'sp': {
                    pages: ['objectives'],
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'budget', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'client'],
            requireFields: ['name'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            loadChildrens: true,
            sharedIn: {
                attach: [
                    'plan',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                ],
                copy: [
                    'plan',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                ],
                shared: [
                    'plan',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                ]
            }
        },
        {
            name: 'goal',
            icon: 'flag',
            module: 'planning',
            myTasks: true,
            reqId: true,
            view: {
                'sp': {
                    pages: ['goals'],
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'budget', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'client'],
            requireFields: ['name'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            loadChildrens: true,
            sharedIn: {
                attach: [
                    'plan',
                    'objective',
                ],
                copy: [
                    'plan',
                    'objective',
                ],
                shared: [
                    'plan',
                    'objective',
                ]
            }
        },
        {
            name: 'iniciative', //action
            icon: 'play_arrow',
            module: 'agile',
            myTasks: true,
            reqId: true,
            view: {
                'sp': {
                    pages: ['agile', 'actions', 'dashboard'],
                },
                't': {
                    pages: ['myDay', 'ourDay', 'allTasks', 'unlinkedCards', 'myTasks']
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'budget', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'client'],
            requireFields: ['name'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                copy: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'myTask',
            alias: 'task',
            icon: 'person_pin',
            module: 'myTasks',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'sendTo', 'myDay', 'client', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image'],
            disabledFields: [],
            sendTo: true,
            myTasks: true,
            // view: {
            //     't': {
            //         pages: ['myDay'],
            //     },
            // },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ],
                copy: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'task',
            icon: 'fact_check',
            module: 'agile',
            myTasks: true,
            reqId: true,
            view: {
                'sp': {
                    pages: ['agile', 'actions', 'dashboard'],
                },
                't': {
                    pages: ['myDay', 'ourDay', 'allTasks', 'unlinkedCards', 'myTasks'],
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'budget', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'client'],
            requireFields: ['name'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                copy: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'meeting',
            icon: 'meeting_room',
            module: 'agile',
            myTasks: true,
            reqId: true,
            view: {
                'sp': {
                    pages: ['agile', 'actions', 'dashboard'],
                },
                't': {
                    pages: ['myDay', 'ourDay', 'allTasks', 'unlinkedCards', 'myTasks']
                },
            },
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'myDay', 'budget', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'client'],
            requireFields: ['name', 'startDate'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                copy: [
                    'plan',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'myTask',
                ],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'strength',
            icon: 'arrow_upward',
            module: 'swot',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'timeline', 'log', 'files', 'timeLog', 'delete'],
            hideFields: ['image', 'imageUpload', 'deadline', 'progress', 'priority', 'status', 'client', 'private'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'weakness',
            icon: 'arrow_downward',
            module: 'swot',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'timeline', 'log', 'files', 'timeLog', 'delete'],
            hideFields: ['image', 'imageUpload', 'deadline', 'progress', 'priority', 'status', 'client', 'private'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'opportunity',
            icon: 'trending_up',
            module: 'swot',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'timeline', 'log', 'files', 'timeLog', 'delete'],
            hideFields: ['image', 'imageUpload', 'deadline', 'progress', 'priority', 'status', 'client', 'private'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'threat',
            icon: 'trending_down',
            module: 'swot',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'timeline', 'log', 'files', 'timeLog', 'delete'],
            hideFields: ['image', 'imageUpload', 'deadline', 'progress', 'priority', 'status', 'client', 'private'],
            disabledFields: [],
            sendTo: true,
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'guideline',
            icon: MatrixIcon,
            module: 'matrix',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['client'],
            disabledFields: [],
            sendTo: true,
            view: {
                'sp': {
                    pages: ['guidelines', 'matrix'],
                },
            },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'guidelineD',
            icon: GuidelineD,
            module: 'matrix',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'timeline', 'kpi', 'users', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'imageUpload', 'client', 'recurrence'],
            disabledFields: [],
            sendTo: true,
            myTasks: true,
            view: {
                'sp': {
                    pages: ['matrix', 'guidelines'],
                },
            },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'guidelineR',
            icon: GuidelineR,
            module: 'matrix',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'imageUpload', 'client', 'recurrence'],
            disabledFields: [],
            sendTo: true,
            myTasks: true,
            view: {
                'sp': {
                    pages: ['matrix', 'guidelines'],
                },
            },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'guidelineE',
            icon: GuidelineE,
            module: 'matrix',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'imageUpload', 'client', 'recurrence'],
            disabledFields: [],
            sendTo: true,
            myTasks: true,
            view: {
                'sp': {
                    pages: ['matrix', 'guidelines'],
                },
            },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },
        {
            name: 'guidelineC',
            icon: GuidelineC,
            module: 'matrix',
            reqId: true,
            db: 'cards',
            iniModule: 'cardEdit',
            modules: ['resume', 'cardEdit', 'checklist', 'catenation', 'steps', 'recurrence', 'timeline', 'kpi', 'users', 'myDay', 'sendTo', 'log', 'files', 'timeLog', 'settings', 'delete'],
            hideFields: ['image', 'imageUpload', 'client', 'recurrence'],
            disabledFields: [],
            sendTo: true,
            myTasks: true,
            view: {
                'sp': {
                    pages: ['matrix', 'guidelines'],
                },
            },
            data: {
                status: "notStarted"
            },
            sharedIn: {
                attach: ['plan'],
                copy: ['plan'],
                shared: [
                    'plan',
                    'objective',
                    'goal',
                    'iniciative',
                    'task',
                    'meeting',
                    'chatGroup',
                    'strength',
                    'weakness',
                    'opportunity',
                    'threat',
                    'guideline',
                    'guidelineD',
                    'guidelineR',
                    'guidelineE',
                    'guidelineC',
                    'myTask',
                    'user'
                ]
            }
        },

        {
            name: 'swot',
            icon: SwotIcon,
            db: 'cards',
            hash: ['matrix'],
        },
        {
            name: 'matrix',
            icon: MatrixIcon,
            db: 'cards',
            hash: ['matrix'],
        },
    ],
    cards: {},
    users: {},
    feeds: {},
    notifications: {},
    loaded: false,
    viewList: false,
    myTasksMenuExpanded: false,
    adminView: false,
    adminViewLoader: false,
    orderByTitle: true,
    orderByTitlePosition: 'ASC',
    groupByColor: false,
}

const session = (state = initialState, data) => {
    if (data.action === 'SET_DB' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    }
    else {
        return state
    }
};

export default session;
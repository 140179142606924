import React from 'react'
import moment from "moment-timezone"
import Tooltip from "@material-ui/core/Tooltip";

//COMPONENTS
import SelectUsers from "components/User/select"
import DataPicker from "components/DataPicker"

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import { textColor, translate } from 'functions'

const DisplayFilterOptions = ({ options, handleDateChange }) => {
    return (
        <div style={{ display: 'flex' }}>
            {options?.users && <div
                style={{ marginTop: 5 }}>
                <SelectUsers
                    includeMe
                    defineResponsible
                    avatarSize="23px"
                    permission={true}
                    data={options?.users || null}
                    noLimit
                    multiple={true}
                    // onSelect={(e) => {
                    //     setSearch({
                    //         ...searchValues,
                    //         users: e
                    //     })
                    // }}
                    selected={options?.users || []}
                    transparent
                />
            </div>}
            {options?.startDate && <div
                style={{ marginTop: 5, marginLeft: '10px' }}>
                <DataPicker
                    type="datetime"
                    onChangeStartDate={(d) => {
                        let values = {
                            startDate: moment(d).unix('x'),
                        }
                        console.log(values)
                        handleDateChange(values)
                    }}
                    onChangeEndDate={(d) => {
                        let values = {
                            endDate: moment(d).unix('x'),
                        }
                        console.log(values)
                        handleDateChange(values)
                    }}
                    range
                    horizontal
                    startDate={options?.startDate ? new Date(options.startDate * 1000) : null}
                    endDate={options?.endDate ? new Date(options.endDate * 1000) : null}
                    onClear={() => {
                        handleDateChange({
                            startDate: false,
                            endDate: false
                        })
                    }}
                />
            </div>}
            {options?.tags &&
                <div style={{ display: "flex", fontSize: 11 }}>{options.tags.map((t, i) =>
                    <div
                        key={t.value}
                        className={'tag'}
                        style={{
                            backgroundColor: t.color || "lightgray",
                            color: t.color ? textColor(t.color, true) : '#FFFFFF',
                            display: "flex", alignItems: "center",
                            padding: "3px 7px",
                            borderRadius: "7px",
                            marginLeft: 5,
                            whiteSpace: "nowrap",
                            height: "fit-content"
                        }}
                    >
                        {t.parent && reduxStore.getState().db.tag.filter(tt => tt.value === t.parent).length > 0 ?
                            <div
                                style={{
                                    fontSize: 8,
                                    marginRight: 3
                                }}
                            >{reduxStore.getState().db.tag.filter(tt => tt.value === t.parent)[0].label} / </div>
                            : <React.Fragment></React.Fragment>}
                        {t.label}
                    </div>
                )}
                </div>
            }
            {options?.status &&
                <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__status")}: <div style={{ display: "flex", alignItems: "center" }}>
                    {Object.keys(options.status)
                        .filter((a, i) => options.status[a])
                        .map((a, i) => {
                            return (
                                <Tooltip
                                    title={translate(reduxStore.getState().db.status.filter(fil => fil.value === a)[0].label, 1)}
                                    key={i}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            float: 'left',
                                            width: '11px',
                                            height: '11px',
                                            borderRadius: '50%',
                                            backgroundColor: reduxStore.getState().db.status.filter(fil => fil.value === a)[0].color,
                                            margin: '7px 3px',
                                        }}></div>
                                </Tooltip>
                            )
                        })}
                </div>
                </div>
            }

        </div>
    )
}

export default DisplayFilterOptions
import React, { useState, useRef, useEffect } from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import TextField from "components/TextField"
// import SendingMessage from "components/Timeline/sending"
import PeriodFilter from "components/Filter/period"
import CustomButton from "components/Buttons/custom"
import Icons from "components/Icons"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"
import Badge from "components/Icon/Badge"
import Tooltip from "@material-ui/core/Tooltip"

import styles from "assets/jss/material-dashboard-pro-react/components/headerWithIcon.js"

//FUNCTIONS
import { translate, customApp, Highlighted, stripHtml } from "functions/"

const useStyles = makeStyles(styles)

function WithIcon(props) {
    const { title, icon: propIcon, editable, _id, onEdit, customButtons: propCustomButtons } = props
    const { functions, session } = reduxStore.getState();
    const { socket } = functions;
    const classes = useStyles();

    const [name, setName] = useState(title ? title.trim() : "");
    const [icon, setIcon] = useState(propIcon || null);
    const [editing, setEditing] = useState(false);
    const [modMessageOpen, setModMessageOpen] = useState(false);
    const [buttonsHover, setButtonsHover] = useState(false);

    const customButtons = propCustomButtons && propCustomButtons.filter(bt => bt !== false);

    const mounted = useRef(true);

    useEffect(() => {
        if (!title && editable) setEditing(true);
        return () => {
            mounted.current = false;
        }
    }, [title, editable]);


    const saveName = async () => {
        try {
            if (_id) {
                socket.emit("data", {
                    module: "node",
                    method: "put",
                    action: "update",
                    noLog: true
                }, {
                    _id,
                    name
                });
            }
            if (onEdit) onEdit({ name, ...icon ? { icon } : {} }, _id || null);
        } catch (e) {
            console.log(e);
        }
        setEditing(false);
    };


    return (
        <div
            className={classes.header}
            style={{
                minHeight: '30px',
                display: 'flex',
                alignItems: 'center',
                ...props.style,

            }}
            onMouseEnter={() => setButtonsHover(true)}
            onMouseLeave={() => {
                if (mounted.current && !modMessageOpen) {
                    setButtonsHover(false)
                }
            }}
        >
            <div className={classes.content}>
                <div className={classes.info}>
                    {props.searchText ?
                        <div style={{
                            marginLeft: '7px',
                        }}>
                            {Highlighted(props.title ? props.title : '', props.searchText)}
                        </div>
                        :
                        <div
                            className={classes.headerTitle}
                            style={{
                                fontSize: props.fontSize ? props.fontSize : '16px',
                                color: props.color ? props.color : customApp('colorText'),
                                paddingRight: '7px',
                                marginLeft: props.icon ? '7px' : 'none',
                                width: "100%"
                            }}
                            onDoubleClick={() => {
                                if (props.onEdit && props.editable && mounted.current) {
                                    setName(props.title ? props.title.trim() : '')
                                    setEditing(true)
                                }
                            }
                            }
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                    width: '100%',
                                    // paddingTop: '7px',
                                    display: 'flex',
                                    alignItems: "center"
                                }}>
                                {(props.icon || props.permitIcon || (props.iconOnEditable && editing)) ?
                                    <Icons
                                        icon={props.icon || 'perm_media'}
                                        color={props.color ? props.color : customApp('colorText')}
                                        onChange={(props.onChangeIcon || (props.editable && props.permitIcon)) ? (e) => {
                                            if (props.onChangeIcon)
                                                props.onChangeIcon(e)
                                            if (props.onEdit) {
                                                props.onEdit({
                                                    name,
                                                    ...e ? { icon: e } : {}
                                                })
                                            }
                                            setIcon(e)
                                        } : null}
                                        size={`calc(${props.fontSize} + 4px)` || '25px'}
                                        style={{ marginRight: 7 }}
                                    />
                                    : <React.Fragment></React.Fragment>
                                }
                                {(editing) ?
                                    <React.Fragment>
                                        <TextField
                                            label={props.placeholder ? props.placeholder : translate('$__nameOfCard', 1)}
                                            variant={customApp('fieldVariant')}
                                            name={'name'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: 'new-password',
                                                onChange: (d) => {
                                                    setName(d.target.value)
                                                },
                                                onKeyDown: (e) => {
                                                    if (e.keyCode === 13 && name)
                                                        saveName()
                                                    if (e.keyCode === 27) {
                                                        setEditing(false)
                                                        setName(props.title ? props.title.trim() : '')
                                                    }
                                                }
                                            }}
                                            value={name}
                                            placeholder={props.placeholder ? props.placeholder : translate('$__titleOfCard_placeholder', 1)}
                                            autoFocus={props.noAutoFocus ? false : true}
                                            multiline={props.multiline ? true : false}
                                        />
                                        {(editing || props.onSave) &&
                                            <CustomButton
                                                title={translate(`$__save`, 1)}
                                                color='green'
                                                icon={'save'}
                                                onClick={() => {
                                                    if (editing) {
                                                        if (!props.noCancel) {
                                                            saveName()
                                                        } else {
                                                            if (name)
                                                                saveName()
                                                        }
                                                    } else {
                                                        props.onSave()
                                                    }
                                                }}
                                                size={props.actionsSize || '25px'}
                                                transparent
                                            />
                                        }
                                        {(editing || props.onCancel) &&
                                            <CustomButton
                                                title={translate(`$__close`, 1)}
                                                color={customApp("colorText")}
                                                icon={'cancel'}
                                                onClick={() => {
                                                    if (editing) {
                                                        if (!props.onCancel) {
                                                            setEditing(false)
                                                        } else {
                                                            if (name)
                                                                setEditing(false)
                                                        }
                                                    }
                                                    if (props.onCancel)
                                                        props.onCancel()
                                                }
                                                }
                                                size={props.actionsSize || '25px'}
                                                transparent
                                            />
                                        }
                                    </React.Fragment>
                                    :
                                    <Tooltip
                                        title={props.tooltipText ? props.tooltipText : props.title ? props.title : ''}
                                    >
                                        <div style={{
                                            ...!props.expanded ? {
                                                width: '100%',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                            }
                                                : {
                                                    width: '100%',

                                                }
                                        }}>
                                            {`${props.showOrder ? `${props.showOrder}. ` : ``}${props.title && props.title.indexOf('$__') > -1 ? translate(props.title, 1) : props.title ? stripHtml(props.title) : ''}`}
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    }
                </div>
                {props.periodFilter && session && session.period &&
                    <div
                        style={{
                            display: "flex",
                            whiteSpace: "nowrap",
                            marginRight: 7
                        }}>
                        {translate(session.period)}
                        <PeriodFilter
                            style={{
                                color: customApp('menu'),
                                height: '20px',
                                width: '20px'
                            }}
                        />
                    </div>
                }
                <div
                    className={classes.actionButtons}
                    style={{ ...props.actionButtonsStyle ? props.actionButtonsStyle : {} }}
                >
                    {(props.actionsButtons && props.onSave) &&
                        <CustomButton
                            title={translate(`$__save`, 1)}
                            color='green'
                            icon={'save'}
                            onClick={() => {
                                if (editing) {
                                    if (!props.noCancel) {
                                        saveName()
                                    } else {
                                        if (name)
                                            saveName()
                                    }
                                } else {
                                    props.onSave()
                                }
                            }}
                            size={props.actionsSize || '25px'}
                            transparent
                        />
                    }
                    {(props.actionsButtons && props.onCancel) &&
                        <CustomButton
                            title={translate(`$__close`, 1)}
                            color='lightgrey'
                            icon={'cancel'}
                            onClick={() => {
                                if (editing) {
                                    if (!props.noCancel) {
                                        setEditing(false)
                                    } else {
                                        if (name)
                                            setEditing(false)
                                    }
                                } else {
                                    props.onCancel()
                                }
                            }
                            }
                            size={props.actionsSize || '25px'}
                            transparent
                        />
                    }
                    {!editing && props.timelineMessage && buttonsHover &&
                        <div className={classes.button}
                            style={{
                                opacity: buttonsHover ? '1' : '0'
                            }}
                        >
                            <Badge
                                overlap="rectangular"
                                onClick={() => setModMessageOpen(true)}
                                icon={'add_comment'}
                                size={'20px'}
                                tooltipprops={modMessageOpen ? {
                                    open: modMessageOpen,
                                    disableFocusListener: true,
                                    disableHoverListener: true,
                                    disableTouchListener: true,
                                    PopperProps: {
                                        disablePortal: true,
                                    },
                                    interactive: true,
                                } : {}}
                            // title={
                            //     modMessageOpen ?
                            //         <div style={{ position: 'relative', minWidth: '220px' }}>
                            //             <SendingMessage
                            //                 nodeId={props._id}
                            //                 onSending={() => {
                            //                     setButtonsHover(false)
                            //                     setModMessageOpen(false)
                            //                 }}
                            //                 onClose={() => {
                            //                     setModMessageOpen(false)
                            //                     setButtonsHover(false)
                            //                 }}
                            //                 relative
                            //                 closeOption
                            //             />
                            //         </div>
                            //         : '$__addComment'
                            // }
                            />
                        </div>
                    }

                    {
                        !editing && customButtons && customButtons.map((bt, i) => {
                            if (bt && bt.component)
                                return (
                                    bt.component
                                )
                            return (
                                bt && bt !== false && bt !== null && (buttonsHover || bt.show !== 'hover') &&
                                <div key={i} className={classes.button}
                                    {...bt.provided}
                                >
                                    <CustomButton
                                        title={bt.title ? translate(bt.title, 1) : translate(bt.name, 1)}
                                        color={customApp(bt.color || 'colorText')}
                                        icon={bt.icon}
                                        style={bt.style}
                                        onClick={e => {
                                            if (bt.onClick)
                                                bt.onClick(e)
                                        }
                                        }
                                        onMouseLeave={() => {
                                            if (!modMessageOpen) {
                                                setButtonsHover(false)
                                            }
                                        }}
                                        onClose={bt.onClose ? () => {
                                            if (bt.onClose) bt.onClose()
                                            setButtonsHover(false)
                                        } : null}
                                        size={bt.actionsSize || '16px'}
                                        transparent
                                        badgeContent={bt.count ? bt.count : null}
                                    />
                                </div>
                            )
                        }
                        )}
                    {props.children}
                </div>
            </div >
        </div>
    )
}
const mapStateToProps = (store) => ({
    users: store.db.users,
    groups: store.db.groups
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(WithIcon)

//REDUX
import reduxStore from "store/";

//FUNCTIONS
import api from "api/";
import apims from "apims";
import { upload } from "functions/upload";
import { cardAccessUpdate } from "functions/cards";

export const loadUsers = async (props, id) => {
    try {
        let reqUsers = await apims.post(`/M_User_List_V3`, { id: id })
        if (reqUsers && reqUsers.data) {
            let users = {}
            reqUsers.data.map(async (a, i) => {
                users = {
                    ...users,
                    [a.data._id]: {
                        ...reduxStore.getState().db.users && reduxStore.getState().db.users[a.data._id] ? reduxStore.getState().db.users[a.data._id] : {},
                        ...a.data,
                        ...a.access ? { _access: JSON.parse(a.access) } : {},
                        ...a._safety ? { _safety: a._safety } : {},
                        ...a._token ? { _token: { _id: a._token } } : {},
                        ...a.groups && a.groups.filter(t => t.value).length > 0 ? { groups: a.groups } : {},
                        ...a.tags && a.tags.filter(t => t.value).length > 0 ? { tags: a.tags } : {},
                        ...a._parent && a._parent !== reduxStore.getState().session.account ? { _parent: a._parent } : {},
                    }
                }
            })
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                users: {
                    ...reduxStore.getState().db.users,
                    ...users,
                },
            })
            return true
        }
    } catch (e) {
        console.group('Layout-ReqUsers::ERR::')
        console.log(e)
        console.groupEnd()
    }
}

export const loadUsersV2 = async (props, id, min) => {
    try {
        let reqUsers = await apims.post(`/UsersList${min ? `_min` : ``}`, { id: id })
        if (reqUsers && reqUsers.data) {
            let users = {}
            reqUsers.data.map(async (a, i) => {
                users = {
                    ...users,
                    [a.data._id]: {
                        ...reduxStore.getState().db.users && reduxStore.getState().db.users[a.data._id] ? reduxStore.getState().db.users[a.data._id] : {},
                        ...a.data,
                        ...a.access ? { _access: JSON.parse(a.access) } : {},
                        ...a._safety ? { _safety: a._safety } : {},
                        ...a._token ? { _token: { _id: a._token } } : {},
                        ...a.groups && a.groups.filter(t => t.value).length > 0 ? { groups: a.groups } : {},
                        ...a.tags && a.tags.filter(t => t.value).length > 0 ? { tags: a.tags } : {},
                        ...a._parent && a._parent !== reduxStore.getState().session.account ? { _parent: a._parent } : {},
                        ...a._lastAccess ? { _lastAccess: a._lastAccess } : {},
                    }
                }
            })
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                users: {
                    ...reduxStore.getState().db.users,
                    ...users,
                },
            })
            return true
        }
    } catch (e) {
        console.group('Layout-ReqUsers::ERR::')
        console.log(e)
        console.groupEnd()
    }
}


const save = async (props) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    const { sideModule } = reduxStore.getState();
    const newData = sideModule.data
    const oldData = reduxStore.getState().db[sideModule.db][sideModule.id] || {}

    //HASHS
    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    if (newData.type === 'user')
        if (hashs && hashs.length > 2 && hashs[2] && hashs[2].length === 36) {
            newData.type = 'user'
            newData._parent = hashs[2]
        }

    Object.keys(newData).forEach(f => {
        if ((newData[f] && newData[f].low) || (newData[f] && newData[f].low && String(newData[f].low) === '0'))
            newData[f] = newData[f].low
    })
    let file = newData.file || null
    delete newData.file


    if (newData._id === 'new' || !newData._id) {
        try {
            socket.emit("data", {
                module: "user",
                method: "post",
                action: "add"
            }, {
                ...newData,
                oldData: oldData,
            }, async (response) => {
                newData._id = response.id
                newData._token = {
                    _id: response.tokenId
                }
                let imageResult = file ? await upload(newData._id, file) : newData.image
                newData.image = imageResult

                dbUserUpdate(props, newData)

                socket.emit("data", {
                    module: "user",
                    method: "put",
                    action: "update"
                }, {
                    ...newData,
                    image: imageResult,
                    oldData: oldData
                })

                props.reduxFunction("ASYNC", "SET_MODULE", {
                    ...reduxStore.getState().sideModule,
                    id: response.id,
                    data: {
                        ...reduxStore.getState().sideModule.data,
                        _id: response.id,
                    }
                });
            })
            return true
        } catch (e) {
            console.log(e)
        }
    } else {
        let imageResult = file ? await upload(newData._id, file) : newData.image
        if (
            (file && imageResult)
            || !file
        ) {
            dbUserUpdate(props, newData)
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...reduxStore.getState().sideModule,
                id: newData._id,
                data: {
                    ...reduxStore.getState().sideModule.data,
                    _id: newData._id,
                }
            });
            socket.emit("data", {
                module: "user",
                method: "put",
                action: "update"
            }, {
                ...newData,
                image: imageResult,
                oldData: oldData
            })
        }
        return true
    }
}

const saveAccess = async (props) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    const { sideModule } = reduxStore.getState()
    const { data } = sideModule

    socket.emit("data", {
        module: "user",
        method: "post",
        action: "access"
    }, {
        _id: data._id,
        email: data.email,
        name: data.name,
        access: data._access,
        safety: data._safety
    }, async (response) => {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            users: {
                ...reduxStore.getState().db.users,
                [data._id]: {
                    ...reduxStore.getState().db.users[data._id] || {},
                    ...data || {},
                },
            }
        });
        socket.emit('user.ACCESS', {
            _id: data._id,
            [data._id]: {
                ...data,
            },
        })
    })
    return true

}

const dbUserUpdate = (props, data) => {
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        users: {
            ...reduxStore.getState().db.users,
            [data._id]: {
                ...reduxStore.getState().db.users[data._id] || {},
                ...data || {},
            },
        }
    });
}

const dbUpdateUser = (props, data) => {
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        users: {
            ...reduxStore.getState().db.users,
            [data._id]: {
                ...data[data._id] ? data[data._id] : {},
            },
        }
    });
}

async function emailExist(email) {
    const { users } = reduxStore.getState().db
    if (Object.keys(users).filter(a => users[a].email === email).length === 1) {
        return true
    } else {
        try {
            await apims.get(`/M_User_Verify_MailExist/?email=${email}`)
            return false
        } catch (e) {
            console.log(e)
            return true
        }
    }
}

async function emailExistV2(email) {
    const { users } = reduxStore.getState().db
    if (Object.keys(users).filter(a => users[a].email === email).length === 1)
        return { exists: true }
    try {
        await apims.get(`/M_User_Verify_MailExist/?email=${email}`)
        return { exists: false }
    } catch (e) {
        console.log(e)
        return { exists: true }
    }
}

async function emailExistDB(email) {
    try {
        const verify = await apims.get(`/M_User_Verify_MailExist/?email=${email}`)
        if (verify)
            return false
    } catch (e) {
        return true
    }
}

async function validateEmailToken(token, email) {
    try {
        const verify = await api.post(`user/email-token-validate`, {
            token: token,
            email: email
        })
        if (verify)
            return true
    } catch (e) {
        return false
    }
}
async function emailTokenGenerate(name, email) {
    try {
        const verify = await api.post(`user/email-token-generate`, {
            name: name,
            email: email
        })
        if (verify)
            return true
    } catch (e) {
        return false
    }
}

const cardsAccess = (props, userId, access, nodeId, userData) => {

    let user = reduxStore.getState().db.users[userId] ? reduxStore.getState().db.users[userId] : userData

    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    const { db } = reduxStore.getState()

    cardAccessUpdate(props, {
        cardId: nodeId,
        userId: userId,
        access: access,
        userData: {
            _id: userId,
            name: user.name,
            image: user.image ? user.image : null,
        }
    })
    socket.emit("data", {
        module: "cards",
        method: "post",
        action: "access"
    }, {
        cardType: db.cards[nodeId].type,
        cardId: nodeId,
        parentUser: db.cards[nodeId]._parent,
        userId: userId,
        access: access,
        userData: {
            _id: userId,
            name: user.name,
            image: user.image ? user.image : null,
        }
    }, async (response) => {
        // console.log(response)
    })
    return true
}

const groupAccess = async (props, groupId, cardId) => {
    api.post("/card/groupAccess", {
        groupId,
        cardId
    })
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [cardId]: {
                ...reduxStore.getState().db.cards[cardId],
                _groups: {
                    ...reduxStore.getState().db.cards[cardId]._groups || {},
                    [groupId]: {
                        _id: groupId
                    }
                }
            }
        }
    })

}

const groupAccessRemove = async (props, groupId, cardId) => {
    api.post("/card/groupAccessRemove", {
        groupId,
        cardId
    })
    let groups = reduxStore.getState().db.cards[cardId]._groups
    delete groups[groupId]
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        cards: {
            ...reduxStore.getState().db.cards,
            [cardId]: {
                ...reduxStore.getState().db.cards[cardId],
                _groups: groups
            }
        }
    })
}

const transferResponse = async (props, data) => {

}

const isResponsible = async (props, data) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    socket.emit("data", {
        module: "cards",
        method: "post",
        action: "userResponsible"
    }, {
        ...data
    })
    return true
}

export const userParam = async (props, data) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    socket.emit("data", {
        module: "cards",
        method: "post",
        action: "userParam"
    }, {
        ...data
    }, async (response) => {

    })
    return true
}

async function calcCards(user) {
    try {
        apims.post(`/M_User_CountCards`, { id: user })
        return false
    } catch (e) {
        return true
    }
}

const getAdminList = (options = {}, type) => {
    const { db } = reduxStore.getState()

    let userList = {
    }

    Object.keys(db.users).filter(idu => !db.users[idu]._parent && db.users[idu]._access && db.users[idu]._access.admin).forEach(idu => {
        userList = {
            ...userList,
            [idu]: db.users[idu]
        }
    })

    if ((!options || (options && !options.isArray)) && userList) {
        let result = {}
        Object.keys(userList).forEach(a => {
            if (!type || (type && type.indexOf(userList[a].type) > -1))
                result = {
                    ...result,
                    [a]: userList[a]
                }
        })
        return result
    } else if (options && options.isArray && userList) {
        let result = []
        Object.keys(userList).forEach(a => {
            if (!type || (type && type.indexOf(userList[a].type) > -1))
                if (result.filter(us => us._id === a).length === 0)
                    result.push(userList[a])
        })
        return result
    } else {
        return {}
    }
}

export const cardINNExists = (id) => {
    const { db, session } = reduxStore.getState()
    const card = db.cards[id]
    if (
        card._users[session._id]
        ||
        (
            card._groups
            && Object.keys(card._groups).length > 0
            && Object.keys(card._groups).filter(group => db?.users[session._id]?.groups?.filter(ug => ug.value === group).length > 0).length > 0
        )
    ) return true
    return false
}

export const deleteUser = (id) => {
    try {
        api.delete(`/user/unique/${id}`)
        return true
    } catch (e) {
        console.log(e)
        return false
    }
}

export {
    calcCards,
    groupAccess,
    groupAccessRemove,
    cardsAccess,
    dbUpdateUser,
    emailExist,
    emailExistV2,
    emailExistDB,
    emailTokenGenerate,
    getAdminList,
    isResponsible,
    save,
    saveAccess,
    transferResponse,
    validateEmailToken,
}
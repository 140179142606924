/**
 * @createDate 2019-07-02 v:1.0
 * @author Cristofer Marinho
 * @param {fullScreen} @Boolean
 * @param {text} // Info text loader @String
 * @param {type} // default|progressBar|
 *
 * Used in:
 *    /layouts/d    
 */

const initialState = {
    fullScreen: true,
    visible: true,
    callBack: null,
    autoSave: false,
    cards: true,

};

const preLoader = (state = initialState, data) => {
    if (data.action === 'preLoader' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else {
        return state
    }
}

export default preLoader
const initialState = {
    open: false,
    nodeId: null,
    activeChats: [],
    activeModule: 'users',
    groups: {},
    notifications: true,
}

const sideModule = (state = initialState, data) => {
    if (data.action === 'SET_CHAT' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLOSE_CHAT') {
        return {
            ...initialState
        }
    }
    else {
        return state
    }
};

export default sideModule;
import React from 'react';
import PropTypes from 'prop-types';

//@MATERIAL
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

//COMPONENTS
import Icon from "components/Icon";
import BoardModels from "components/BoardModels";
import Button from "components/CustomButtons/Custom";

//REDUX
import * as reduxActions from 'store/actions';
import history from "store/history";
import reduxStore from "store/";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//VIEWS
import Languages from "views/BackOffice/languages";
import Data from "./data";

//FUNTIONS
import { customApp, translate } from "functions/";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-strength-tabpanel-${index}`}
            aria-labelledby={`scrollable-strength-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-strength-tab-${index}`,
        'aria-controls': `scrollable-strength-tabpanel-${index}`,
    };
}

function App() {
    const [value, setValue] = React.useState(0);
    const [version, setVersion] = React.useState("")

    React.useEffect(() => {
        let hashs = window.location.hash.split('/')
        hashs[0] = hashs[0].replace('#', '')
        if (String(hashs[0])) {
            setValue(Math.ceil(hashs[0]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        history.push(`#${newValue}`)
    };



    const sendSocket = (fn) => {
        const { socket } = reduxStore.getState().functions
        socket.emit('global', {
            fn: fn
        })
    }

    return (
        <div>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label=""
                >
                    <Tab label="Data" icon={<Icon color={customApp(value === 0 ? "medium" : "color", 1)}>Data</Icon>} {...a11yProps(0)} />
                    <Tab label="Languages" icon={<Icon color={customApp(value === 1 ? "medium" : "color", 1)}>translate</Icon>} {...a11yProps(1)} />
                    <Tab label="Versions" icon={<Icon color={customApp(value === 2 ? "medium" : "color", 1)}>app_registration</Icon>} {...a11yProps(2)} />
                    {/* <Tab label="Board Models" hash="boards" icon={<Icon color={customApp(value === 3 ? 'medium' : 'color'), 1}>view_column</Icon>} {...a11yProps(0)} />
                    <Tab label="Accounts" icon={<Icon color={customApp(value === 4 ? 'medium' : 'color'), 1}>contacts</Icon>} {...a11yProps(1)} />
                    <Tab label="Users" icon={<Icon color={customApp(value === 5 ? 'medium' : 'color'), 1}>supervisor_account</Icon>} {...a11yProps(2)} />
                    <Tab label="Support" icon={<Icon color={customApp(value === 6 ? 'medium' : 'color'), 1}>support</Icon>} {...a11yProps(3)} />
                    <Tab label="Finance" icon={<Icon color={customApp(value === 7 ? 'medium' : 'color'), 1}>account_balance</Icon>} {...a11yProps(4)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <div>
                    <Data />
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div>
                    <Languages />
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div>
                    <input type="text" value={version}
                        onChange={e => {
                            setVersion(e.target.value)
                        }} />
                    <Button
                        style={{ position: 'relative', float: 'left', margin: '3px' }}
                        color={'primary'}
                        onClick={() => {
                            sendSocket('version')
                        }}
                    >
                        {translate('$__send', '*')}
                    </Button>
                    <Button
                        style={{ position: 'relative', float: 'left', margin: '3px' }}
                        color={'primary'}
                        onClick={() => {
                            sendSocket('logout')
                        }}
                    >
                        {translate('$__logout', '*')}
                    </Button>
                    <Button
                        style={{ position: 'relative', float: 'left', margin: '3px' }}
                        color={'primary'}
                        onClick={() => {
                            sendSocket('reload')
                        }}
                    >
                        {translate('$__reload', '*')}
                    </Button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <div>
                    <BoardModels />
                </div>
            </TabPanel>
            <TabPanel value={value} index={4}>
                {translate('$__accounts', 1)}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {translate('$__users', 1)}
            </TabPanel>
            <TabPanel value={value} index={6}>
                {translate('$__support', 1)}
            </TabPanel>
            <TabPanel value={value} index={7}>
                {translate('$__finance', 1)}
            </TabPanel>
        </div>
    );
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(App);
// ID_CREATED*************************

//CORE
import React from "react"

//COMPONENTS
import CircularProgress from "components/Progress/circular"
import CustomButton from "components/Buttons/custom"

import {
    translate,
    customApp,
} from "functions/"

function Component(props) {
    return (
        <div
            id={`sideModule_closeButton`}
            style={{
                position: "fixed",
                display: "inline-flex",
                paddingLeft: "10px",
                top: "7px",
                right: 0,
                backgroundColor: "#FFFFFF",
                ...props.style ? props.style : {},
            }}>
            {props.saveLoader &&
                <React.Fragment>
                    <CircularProgress size="25px" />
                </React.Fragment>
            }
            {!props.saveLoader &&
                <React.Fragment>
                    <div
                        id={`sideModule_saveOptions`}
                        style={{ display: "flex" }}
                    >
                        {props.save &&
                            <CustomButton
                                id={`sideModule_saveButton`}
                                title={translate(`$__save`, 1)}
                                color={customApp("color")}
                                icon={"save"}
                                onClick={() => { props.save() }}
                                size={"25px"}
                            />
                        }
                        {props.saveClose &&
                            <CustomButton
                                id={`sideModule_saveAndCloseButton`}
                                title={translate(`$__saveAndClose`, 1)}
                                color="green"
                                icon={"playlist_add_check"}
                                onClick={() => { props.saveClose() }}
                                size={"25px"}
                            />
                        }
                    </div>
                    {props.close &&
                        <CustomButton
                            title={translate(`$__close`, 1)}
                            color="lightgrey"
                            icon={"cancel"}
                            onClick={() => {
                                props.close()
                            }}
                            size={"25px"}
                        />
                    }
                </React.Fragment>
            }
        </div>
    )
}

export default Component

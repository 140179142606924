//COMPONENTS
import AboutPlan from './Widgets/AboutPlan';
import Notes from './Widgets/Notes';
import TimeLine from './Widgets/TimeLine';
import ObjetiveDough from './Widgets/ObjetiveDough';
import ActionsDough from './Widgets/ActionsDough';
import MatrixDough from './Widgets/MatrixDough';
import GoalsDough from './Widgets/GoalsDough';
import LastCompleted from './Widgets/LastCompleted';
import DayTasks from './Widgets/DayTasks';
import LateTask from './Widgets/LateTask';
import Timesheet from './Widgets/Timesheet';
import NextTask from './Widgets/NextTask';
import taskByPerson from './Widgets/TaskByPerson'
import taskByCreator from './Widgets/TaskByCreator'
import taskByResponsible from './Widgets/TaskByResponsible'
/* import taskByPerson from './Widgets/TaskByPerson' */
import taskDoneByTime from './Widgets/TaskDoneByTime';
import Funnel from './Widgets/Funnel';



const initialState1 = [
    { Component: AboutPlan, widget: 'AboutPlan', name: 'planResume', datagrid: { x: 0, y: 0, w: 6, h: 3, minW: 2, maxW: 12, minH: 3 }, icon: 'close' },
    { Component: TimeLine, widget: 'TimeLine', name: 'TimeLine', datagrid: { x: 6, y: 0, w: 6, h: 7, minW: 6, maxW: 12, minH: 4 }, icon: 'close' },
    { Component: Notes, widget: 'Notes', name: 'Notes', datagrid: { x: 0, y: 4, w: 6, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'close' },
    { Component: taskByPerson, widget: 'TaskByPerson', name: 'taskByPerson', datagrid: { x: 0, y: 18, w: 6, h: 6, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart' },
    { Component: taskByCreator, widget: 'TaskByCreator', name: 'taskByCreator', datagrid: { x: 0, y: 18, w: 6, h: 6, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart' },
    { Component: taskByResponsible, widget: 'TaskByResponsible', name: 'taskByResponsible', datagrid: { x: 0, y: 18, w: 6, h: 6, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart' },
    { Component: taskDoneByTime, widget: 'TaskDoneByTime', name: 'taskDoneInWeek', datagrid: { x: 6, y: 18, w: 6, h: 6, minW: 4, maxW: 12, minH: 4 }, icon: 'area_chart' },
    { Component: ObjetiveDough, widget: 'ObjetiveDough', name: 'objectives', datagrid: { x: 0, y: 7, w: 3, h: 5, minW: 2, maxW: 12, minH: 3 }, icon: 'gps_fixed' },
    { Component: ActionsDough, widget: 'ActionsDough', name: 'actions', datagrid: { x: 3, y: 7, w: 3, h: 5, minW: 2, maxW: 12, minH: 3 }, icon: 'play_arrow' },
    { Component: MatrixDough, widget: 'MatrixDough', name: 'guidelines', datagrid: { x: 6, y: 7, w: 3, h: 5, minW: 2, maxW: 12, minH: 3 }, icon: 'MatrixIcon' },
    { Component: GoalsDough, widget: 'GoalsDough', name: 'goals', datagrid: { x: 9, y: 7, w: 3, h: 5, minW: 2, maxW: 12, minH: 3 }, icon: 'gps_fixed' },
    { Component: LastCompleted, widget: 'LastCompleted', name: 'lastCompletedCards', datagrid: { x: 0, y: 12, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'flag' },
    { Component: DayTasks, widget: 'DayTasks', name: 'todayTasks', datagrid: { x: 3, y: 12, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'flag' },
    { Component: LateTask, widget: 'LateTask', name: 'lateActivities', datagrid: { x: 6, y: 12, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'flag' },
    { Component: NextTask, widget: 'NextTask', name: 'nextActivities', datagrid: { x: 9, y: 12, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'flag' },
    { Component: Timesheet, widget: 'Timesheet', type: "charts", name: 'Timesheet', datagrid: { x: 0, y: 18, w: 4, h: 8, minW: 3, maxW: 12, minH: 8 }, icon: 'update' },
]

const initialState2 = [
    { Component: AboutPlan, widget: 'AboutPlan', name: 'planResume', datagrid: { x: 0, y: 0, w: 6, h: 3, minW: 2, maxW: 12, minH: 3 }, icon: 'close' },
    { Component: TimeLine, widget: 'TimeLine', name: 'TimeLine', datagrid: { x: 6, y: 0, w: 6, h: 7, minW: 6, maxW: 12, minH: 4 }, icon: 'close' },
    { Component: Notes, widget: 'Notes', name: 'Notes', datagrid: { x: 0, y: 4, w: 6, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'close' },
    { Component: taskByPerson, widget: 'TaskByPerson', name: 'taskByPerson', datagrid: { x: 0, y: 18, w: 6, h: 6, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart' },
    { Component: taskDoneByTime, widget: 'TaskDoneByTime', name: 'taskDoneInWeek', datagrid: { x: 6, y: 18, w: 6, h: 6, minW: 4, maxW: 12, minH: 4 }, icon: 'area_chart' },
    { Component: Funnel, widget: 'Funnel', type: "charts", name: 'Etapas', datagrid: { x: 0, y: 22, w: 12, h: 5, minW: 4, maxW: 12, minH: 4 }, icon: 'filter_alt' },
    { Component: Timesheet, widget: 'Timesheet', type: "charts", name: 'Timesheet', datagrid: { x: 0, y: 27, w: 4, h: 8, minW: 3, maxW: 12, minH: 8 }, icon: 'update' },
]

export { initialState1, initialState2 };
import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
  container: {
    borderRadius: '2px',
    marginRight: '7px',
    marginLeft: '7px',
    marginBottom: '15px',
    height: 'min-content',
    width: '100%',
  },
  title: {
    ...defaultFont,
    padding: '3px 0px',
    width: '100%',
    margin: '0px',
    background: customApp('ColumnTitleColor'),
    "&$reverse": {},
  },
  reverse: {
    flexDirection: "row-reverse",
  },
  cardList: {
    //height: 'auto',
    width: 'calc(100% - 6px)',
    padding: '0px',
    minHeight: '30px',
    "&::-webkit-scrollbar-track": {
      // boxShadow: 'inset 0 0 5px grey',
      borderRadius: '0px',
    },
    "&::-webkit-scrollbar": {
      width: '7px',
    },
    "&::-webkit-scrollbar-thumb": {
      background: customApp('medium'),
      opacity: '0.5 !important',
      borderRadius: '0px',
    }

  },
  button: {
    padding: '3px auto',
    cursor: 'pointer',
    alignItems: 'center',
    display: 'flex',
    opacity: 1,
    transition: 'visibility 0.1s, opacity 0.5s linear'
  },
  cardAdd: {
    position: 'relative',
    border: `0.5px solid ${customApp('light')}`,
    borderRadius: '2px',
    display: 'block',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    padding: '3px'
  }
};

export default style;

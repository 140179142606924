//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import Icon from "components/Icon"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule.js"


//IMPORT MODULES TO CARDS
import IconButton from '@material-ui/core/IconButton'


//@MATERIAL
import Badge from '@material-ui/core/Badge'

//FUNCTIONS
import { customApp } from "functions/"
import { countNotifications } from "functions/cards"

const Component = (props) => {
    const { classes, sideModule } = props

    let notifications = {}
    if (props.count)
        notifications = countNotifications(sideModule.id)

    return (
        <div
            className={classes.MenuButton}
            id={`sideModule_buttom_${props.module}`}
        >
            <IconButton
                onClick={() => {
                    props.openModule(props.module)
                }
                }
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={props.count && notifications && notifications[props.count] ? notifications[props.count] : null}
                    color="secondary"
                >
                    <Icon
                        // disabled={sideModule.activeModule === props.module ? false : true}
                        icon={props.icon}
                        size={props.size}
                        color={sideModule.activeModule === props.module ? customApp('active') : customApp('colorText')}
                        title={props.title}
                    />
                </Badge>
                {sideModule.activeModule === props.module &&
                    <Icon
                        className={classes.MenuButton_arrow}
                        color={sideModule.activeModule === props.module ? customApp('active') : customApp('colorText')}
                        icon={'play_arrow'}
                    />
                }
            </IconButton>
        </div >
    )
}

const mapStateToProps = (store) => (
    {
        timeline: store.timeline && store.sideModule.id && store.timeline[store.sideModule.id] ? store.timeline[store.sideModule.id] : {},
        card: store.db.cards && store.db.cards[store.sideModule.id] ? store.db.cards[store.sideModule.id] : {},
        sideModule: store.sideModule
    }
)
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))

//CORE
import React, { useState } from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Autocomplete from "components/AutoComplete"
import Switch from "components/Switch"
import TextField from "components/TextField"

import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users.js"

import {
    customApp,
    appAlert,
    translate,
    getAppAccess
} from "functions/"

import { save } from "../../functions/users.js"

function Component(props) {
    const { classes } = props
    const { sideModule, db } = reduxStore.getState()
    const [data, setData] = React.useState(sideModule.data)
    const [iniData] = useState(sideModule.data)
    const [language, setLanguage] = React.useState(sideModule.data && sideModule.data.language ? sideModule.data.language : 'pt-BR')

    const reqSave = JSON.stringify(iniData) !== JSON.stringify(data)

    const changeData = (d) => {
        let newData = {
            ...data,
            [d.name]: d.value,
        }

        if (d.name === "language")
            setLanguage(d.value)

        if (!d.value && newData[d.name])
            delete newData[d.name]

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: newData
        })

        setData(newData)
    }

    const close = (force = false) => {
        if (reqSave && !force) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }

    const saveUser = async (cl) => {
        const reqSave = await save(props)
        if (reqSave) {
            appAlert({
                message: translate('$__userDataSaved', 1),
                variant: null,
                persist: false,
                horizontal: 'right',
                confirm: null
            })

            if (data.language !== language)
                props.reduxFunction("ASYNC", "SET_SESSION", {
                    ...reduxStore.getState().session,
                    language: language
                })

            if (cl) {
                close(true)
                if (data.language !== language)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
            }
        }
    }

    console.log(iniData, data)

    const AppAccess = getAppAccess()
    let permission = false
    if ((AppAccess
        &&
        (
            AppAccess.user
            && (
                AppAccess.userAdmin
                || AppAccess.userEdit
            )
        )
    ))
        permission = true

    return (
        <div>
            <SideModuleCloseAndSave
                save={reqSave ? () => saveUser() : null}
                saveClose={reqSave ? () => saveUser(true) : null}
                close={() => close(true)}
            />
            <div style={{
                position: "relative",
                width: "100%",
                textAlign: "center",
                padding: '15px',
            }}>
                <fieldset>
                    <legend>{translate('$__language', 1)}</legend>
                    <Autocomplete
                        id="combo-box-demo"
                        options={db.languages}
                        getOptionLabel={(option) => translate(option.label, 1)}
                        style={{ width: "100%" }}
                        name="status"
                        disableClearable
                        value={
                            data
                                && data.language
                                && db.languages
                                ? db.languages.filter(a =>
                                    String(a.value) === String(data.language)
                                )[0]
                                : db.languages[0]
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={translate("$__language", 1)}
                                variant={customApp("fieldVariant")}
                                value={
                                    data
                                        && data.language
                                        && db.languages
                                        ? db.languages.filter(a =>
                                            String(a.value) === String(data.language)
                                        )[0]
                                        : db.languages[0]
                                }
                            />
                        }
                        onChange={
                            (d, v) => {
                                changeData({ name: "language", value: v["value"] })
                            }
                        }
                    />
                </fieldset>
                <fieldset>
                    <legend>{translate('$__sessionDuration', 1)}</legend>
                    <div>{translate('$__sessionDurationDescription', 1)}</div>
                    <Autocomplete
                        id="combo-box-demo"
                        options={db.expiresIn}
                        getOptionLabel={(option) => translate(option.label, 1)}
                        style={{ width: "100%" }}
                        name="status"
                        disableClearable
                        value={
                            data
                                && data.expiresIn
                                && db.expiresIn
                                ? db.expiresIn.filter(a =>
                                    String(a.value) === String(data.expiresIn)
                                )[0]
                                : db.expiresIn.filter(a =>
                                    !a.value
                                )[0]
                        }
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={translate("$__expiresIn", 1)}
                                variant={customApp("fieldVariant")}
                                value={
                                    data
                                        && data.expiresIn
                                        && db.expiresIn
                                        ? db.expiresIn.filter(a =>
                                            String(a.value) === String(data.expiresIn)
                                        )[0]
                                        : db.expiresIn.filter(a =>
                                            !a.value
                                        )[0]
                                }
                            />
                        }
                        onChange={
                            (d, v) => {
                                changeData({ name: "expiresIn", value: v["value"] })
                            }
                        }
                    />
                </fieldset>
                {reduxStore.getState().session.isResale && data.type !== "user" ?
                    <fieldset style={{ marginBottom: "14px" }}>
                        {reduxStore.getState().db.users[data._id].independentAccount ?
                            <div
                                style={{ color: "gray", fontSize: 11 }}
                            >
                                {translate("$__thisIsAIndependentAccountExplain", 1)}
                            </div>
                            :
                            <React.Fragment>
                                <div className={classes.switch}>
                                    <Switch
                                        checked={
                                            data.independentAccount
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            if (permission)
                                                changeData({
                                                    name: "independentAccount",
                                                    value: data.independentAccount ? false : true
                                                })
                                        }}
                                        name="independentAccount"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__turnIntoIndependentAccount", 1)}
                                </div>
                                <div
                                    style={{ color: "gray", fontSize: 11 }}
                                >
                                    {translate("$__turnIntoIndependentAccountExplain", 1)}
                                </div>
                            </React.Fragment>
                        }
                    </fieldset>
                    : <React.Fragment></React.Fragment>}
            </div>
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
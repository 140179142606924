import React from 'react'

//COMPONENTES
import GridItem from 'components/Grid/GridItem';
import UserCard from "components/User/card";

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

const Department = () => {
  const { db, session } = reduxStore.getState()

  const filterUsersGroup = (group, user) => {
    if (
      db.users[user]
      && db.users[user].groups
      && db.users[user].groups.length > 0
      && db.users[user].groups.filter(ug =>
        ug.value === group.value
      ).length > 0
      && session?.groups.some(sgroup => sgroup.label === group.label)
    )
      return true
    return false
  }

  let groups = ``
  if (session?.groups
    .filter(a => a.value).length > 0
  )
    session.groups
      .filter(a => a.value).sort((a, b) => {
        if (a.label < b.label)
          return -1
        return 1
      }).forEach((item, i) => {
        groups += `${i > 0 ? ` / ` : ``}${item.label}`
      })

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
        }}
      >
        <p>{groups}
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {reduxStore
            .getState()
            .db.userGroup.filter((group) => {
              if (
                Object.keys(db.users).filter((user) => {
                  return filterUsersGroup(group, user);
                }).length > 0
              )
                return true;
              return false;
            })
            .map((group) => {
              let users = Object.keys(db.users).filter(
                (user) => {
                  return filterUsersGroup(group, user);
                }
              );
              return (
                <>
                  {users
                    .sort((a, b) => {
                      let nameA = reduxStore
                        .getState()
                        .db.users[a].name.toLowerCase();
                      let nameB = reduxStore
                        .getState()
                        .db.users[b].name.toLowerCase();
                      if (nameA < nameB) return -1;
                      if (nameA < nameB) return 1;
                      return 0;
                    })
                    .map((id) => (
                      <GridItem
                        key={`${group.value}_${id}`}
                        xs={12}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{ minWidth: "300px" }}
                      >
                        <UserCard data={db.users[id]} />
                      </GridItem>
                    )
                    )}
                </>
              );
            })}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (store) => ({
  users: store.db.users
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(Department)
import Switch from '@material-ui/core/Switch';

//@MATERIAL 
import { withStyles } from "@material-ui/core/styles";

//Styles 

//FUNCTIONS
import { customApp } from "functions/";

const Component = withStyles({
    switchBase: {
        color: customApp('medium'),
        '&$checked': {
            color: customApp('color'),
        },
        '&$checked + $track': {
            backgroundColor: customApp('color'),
        },
    },
    checked: {},
    track: {},
})(Switch);

export default Component
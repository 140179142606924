//CORE
import React from "react"
import styled from "styled-components"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card/"
import CustomButton from "components/Buttons/custom"
import IconButton from "components/CustomButtons/IconButton"
import TextField from "components/TextField"
import LinearProgress from "components/Progress/linear"

//FUNCTIONS
import { translate, customApp } from "functions/"
import {
    loadCards
} from "functions/loadData"

function Component(props) {
    const { db, session } = reduxStore.getState()
    const [name, setName] = React.useState(props.name ? props.name : '')
    const [add, setAdd] = React.useState(false)
    const [loadingData, setLoadingData] = React.useState(false)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    React.useEffect(() => {
        reqFullData()
        return () => {
            // return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reqFullData = async () => {
        const loadParents = await loadCards({ ...props, ignoreLoader: true }, [props.nodeId], false, null, "childrens")
        if (loadParents)
            setLoadingData(false)
    }


    let parent = {}
    if (reduxStore.getState().db[props.db] && reduxStore.getState().db[props.db][props.nodeId])
        parent = reduxStore.getState().db[props.db][props.nodeId]

    let tasksCards = Object.keys(reduxStore.getState().db.cards)
        .filter(fil => {
            if (
                !reduxStore.getState().db.cards[fil].deleted
                && !reduxStore.getState().db.cards[fil].checklist
                && !reduxStore.getState().db.cards[fil]._recurrenceId
                && reduxStore.getState().db.cards[fil]._parent === props.nodeId &&
                (
                    !reduxStore.getState().db.cards[fil].private
                    || (
                        reduxStore.getState().db.cards[fil].private
                        && reduxStore.getState().db.cards[fil]._users
                        && reduxStore.getState().db.cards[fil]._users[session._id]
                    )
                )
            )
                return true
            return false
        }
        )
        .map(card => card)

    const addCard = async () => {
        const { socket } = reduxStore.getState().functions
        let type = 'task'
        if (parent && parent.type) {
            if (parent.type.indexOf('guideline') > -1)
                type = 'objective'
            if (parent.type === 'objective')
                type = 'goal'
            if (parent.type === 'goal')
                type = 'task'
        }
        if (parent.childrenAutoTag && parent._tags && parent._tags.length > 0)
            console.log(parent)


        socket.emit("data", {
            module: "cards",
            method: "post",
            action: "add"
        }, {
            _parent: parent._id,
            planId: parent._planId ? parent._planId : null,
            name,
            type,
            status: 'notStarted',
            ...(parent.childrenAutoTag && parent._tags && parent._tags.length > 0) ? {
                _tags: parent._tags
            } : {}
        }, async (response) => {
            socket.emit('card.UPD', {
                cardId: response.id,
                cardData: {
                    ...response.data,
                    // _loaded: true,
                    _planId: parent._planId ? parent._planId : null,
                    _parent: parent._id,
                    _timeline: {
                        [response.log._id]: {
                            ...response.log,
                            _parent: [response.id],
                        }
                    },
                    _users: {
                        [session._id]: {
                            name: reduxStore.getState().db.users[session._id].name ? reduxStore.getState().db.users[session._id].name : null,
                            displayName: reduxStore.getState().db.users[session._id].displayName ? reduxStore.getState().db.users[session._id].displayName : null,
                            image: reduxStore.getState().db.users[session._id].image ? reduxStore.getState().db.users[session._id].image : null,
                            access: 5,
                            _id: session._id
                        }
                    }
                },
            })
            setName('')
        })
    }

    const onDragEnd = async result => {
        let cardsOn = Object.keys(reduxStore.getState().db.cards)
            .filter(fil => {
                if (reduxStore.getState().db.cards[fil] && reduxStore.getState().db.cards[fil].checklist && reduxStore.getState().db.cards[fil]._parent === props.nodeId)
                    return true
                return false
            }
            )
            .sort((a, b) => {
                if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low)) {
                    return -1
                } else if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) > parseInt(db.cards[b].order.low)) {
                    return 1
                } else {
                    return 0
                }
            })
            .map(card => card)

        const { destination, source, draggableId } = result

        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const itemOrderIds = cardsOn
        itemOrderIds.splice(source.index, 1)
        itemOrderIds.splice(destination.index, 0, draggableId)

        // socket.emit("data", {
        //     module: "cards",
        //     method: "put",
        //     action: "reorder"
        // }, {
        //     cards: itemOrderIds
        // })
        // let cards = {}

        // itemOrderIds.map((a, i) => {
        //     cards = {
        //         ...cards,
        //         [a]: db.cards[a]
        //     }
        //     cards = {
        //         ...cards,
        //         [a]: {
        //             ...cards[a],
        //             order: {
        //                 low: i
        //             }
        //         }
        //     }
        //     return true
        // })
        // props.reduxFunction("ASYNC", "SET_DB", {
        //     ...reduxStore.getState().db,
        //     cards: {
        //         ...reduxStore.getState().db.cards,
        //         ...cards
        //     }
        // })
        return

    }
    const onDragStart = async result => {
    }
    const onDragUpdate = async result => {
    }

    if (loadingData)
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <span style={{ color: customApp('menu'), fontSize: "12px", paddingRight: "15px", whiteSpace: "nowrap" }}>{translate('$__loadingCatenation')}</span>
            <LinearProgress />
        </div>

    let openCards = tasksCards
        .filter(a => db.cards[a].status !== 'completed')
        .sort((a, b) => {
            if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase()) {
                return -1
            } else if (db.cards[a].name.toLowerCase() > db.cards[b].name.toLowerCase()) {
                return 1
            } else {
                return 0
            }
        }
        )

    let completedCards = tasksCards
        .filter(a => db.cards[a].status === 'completed')
        .sort((a, b) => {
            if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase()) {
                return -1
            } else if (db.cards[a].name.toLowerCase() > db.cards[b].name.toLowerCase()) {
                return 1
            } else {
                return 0
            }
        }
        )

    return (
        <div style={{ width: '100%' }}>
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
            >
                <Droppable
                    droppableId={`Drop_tasks`} //ID OF BOARD
                    type="column"
                    direction={"vertical"}
                >
                    {(provided, snapshot) => (
                        <Container
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {openCards.length > 0 ?
                                openCards.map((card, index) => (
                                    <Draggable
                                        draggableId={card}
                                        index={index}
                                        key={card}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <Card
                                                    provided={provided}
                                                    snapshot={snapshot}
                                                    cardId={card}
                                                    db={'cards'}
                                                    onlyOption
                                                    showCompleteOption
                                                    directDeletion
                                                    editable
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                )) : <React.Fragment></React.Fragment>}
                            {completedCards.length > 0 ?
                                completedCards.map((card, index) => (
                                    <div key={card} >
                                        <Card
                                            provided={provided}
                                            snapshot={snapshot}
                                            cardId={card}
                                            db={'cards'}
                                            onlyOption
                                            showCompleteOption
                                            directDeletion
                                            editable
                                        />
                                    </div>
                                )) : <React.Fragment></React.Fragment>}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
            {
                !add &&
                <div style={{ position: 'relative', float: 'right', width: '100%', padding: '7px', display: 'flex', alignContent: 'flex-end', justifyContent: 'center' }}>
                    <div>
                        <IconButton
                            reverse
                            text={translate("$__add", '*')}
                            icon="add_box"
                            iconColor={customApp('medium')}
                            onClick={() => {
                                setAdd(!add)
                            }}
                        />
                    </div>
                </div>
            }
            {
                add &&
                <React.Fragment>
                    <div
                        style={{ position: 'relative', float: 'left', width: '100%', padding: '7px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 'calc(100% - 50px)', display: 'flex' }}>
                            <TextField
                                label={translate('$__taskTitle')}
                                variant={customApp('fieldVariant')}
                                name={'name'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    onChange: (d) => {
                                        setName(d.target.value)
                                    },
                                    onBlur: (d) => {
                                        if (d.target.value === '')
                                            setAdd(!add)
                                    },
                                    onKeyDown: (e) => {
                                        if (e.keyCode === 13 && name)
                                            addCard()
                                        if (e.keyCode === 27) {
                                            setAdd(!add)
                                            setName('')
                                        }
                                    }
                                }}
                                autoFocus
                                value={name}
                                placeholder={translate('$__taskTitlePlaceholder')}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <CustomButton
                                title={translate(`$__save`, 1)}
                                color='green'
                                icon={'save'}
                                onClick={(e) => {
                                    addCard()
                                }}
                                transparent
                            />
                            <CustomButton
                                title={translate(`$__cancel`, 1)}
                                color='lightgrey'
                                icon={'cancel'}
                                onClick={(e) => {
                                    setAdd(!add)
                                    setName('')
                                }}
                                transparent
                            />
                        </div>
                    </div>
                </React.Fragment>
            }
        </div >
    )
}

const Container = styled.div`
            background-color:none
            `

const mapStateToProps = (store, props) => {
    return ({
        cards: Object.keys(store.db.cards).filter(fil => store.db.cards[fil]._parent === props.nodeId)
    })
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

// ID_CREATED*************************

//CORE
import React, { useState } from "react"
import { Panel as ColorPicker } from 'rc-color-picker';
import { GradientPickerPopover } from 'react-linear-gradient-picker';

//FUNCTIONS
import {
    hexToRgba
} from "functions/"

import 'rc-color-picker/assets/index.css';
import 'react-linear-gradient-picker/dist/index.css';

const WrappedColorPicker = ({ onSelect, ...rest }) => (
    <ColorPicker {...rest} onChange={c => {
        onSelect(c.color, c.alpha / 100);
    }}
        animation="slide-up"
    />
);

function convertLinearToPallet(x) {
    let c = x.replace('linear-gradient(90deg,', "").replace('radial-gradient(90deg,', "")
    let s = c.split('rgba(')
    let colors = []
    s.forEach(color => {
        let xc = color.split(') ')
        if (xc.length === 2) {
            let cl = xc[0].split(',')
            let os = (parseInt(xc[1].replace(')', "").replace("%", "")) / 100).toFixed(2)
            colors.push({
                color: `rgb(${cl[0].trim()}, ${cl[1].trim()}, ${cl[2].trim()})`,
                offset: `${os.trim()}`,
                opacity: `${cl[3].trim()}`
            })
        }
    })
    return colors
}

function GradientColor(props) {
    const [open, setOpen] = useState(false);
    const value = props?.value?.css ? convertLinearToPallet(props.value.css) : props?.value?.color || []

    const setGradientColor = (options) => {
        let x = options.color
        let colorConstruct = (!props.type || props.type === 'linear')
            ? `linear-gradient(90deg, `
            : `radial-gradient(`
        x.forEach((c, i) => {
            if (c.color.indexOf("#") > -1) {
                colorConstruct = `${colorConstruct}${hexToRgba(c.color, c.opacity || 1)} ${Math.ceil(c.offset * 100)}% ${i < x.length - 1 ? `,` : ``} `
            }
            if (c.color.indexOf("rgb") > -1 && c.color.indexOf("rgba") === -1) {
                colorConstruct = `${colorConstruct}rgba(${c.color.replace("rgb(", "").replace(')', `, ${c.opacity || 1}`)}) ${Math.ceil(c.offset * 100)}% ${i < x.length - 1 ? `,` : ``} `
            }
        })
        colorConstruct = `${colorConstruct})`

        if (props.onChange)
            props.onChange({
                css: colorConstruct,
                palette: x
            })
    }


    return (
        <GradientPickerPopover {...{
            showAnglePicker: false,
            showGradientTypePicker: false,
            open,
            setOpen,
            width: 320,
            paletteHeight: 32,
            palette: value,
            onPaletteChange: (e) => {
                setGradientColor({
                    name: 'ColumnTitleColor',
                    color: e,
                })
            }
        }}>
            <WrappedColorPicker />
        </GradientPickerPopover>
    )
}

export default GradientColor
//FUNCTIONS
import { customApp } from "functions";

const styles = theme => ({
  root: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
  },
  user: {
    display: 'flex',
    margin: 'auto',
    height: '37px',
    alignItems: 'center',
  },
  userExtended: {
    display: 'flex',
    height: '37px',
    alignItems: 'center',
  },
  userActive: {
    display: 'inherit',
    height: '37px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: customApp('color', 0.33),
  },
  userActiveExtended: {
    display: 'inherit',
    height: '37px',
    alignItems: 'center',
    backgroundColor: customApp('color', 0.33),
    justifyContent: 'left',
  },
  name: {
    position: 'absolute',
    top: '5px',
    left: '36px',
    right: '0px',
    height: '30px',
    border: `1px solid ${customApp('color', 0.2)}`,
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '22px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: customApp('darkText'),
    boxShadow: '-5px 3px 7px 1px rgba(0,0,0,0.05)',
  },
  lastMessage: {
    position: 'absolute',
    top: '30px',
    left: '36px',
    right: '15px',
    height: '30px',
    border: `1px solid ${customApp('color', 0.2)}`,
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '22px',
    paddingTop: '3px',
    fontSize: '12px',
    color: customApp('medium'),
    boxShadow: '-5px 3px 7px 1px rgba(0,0,0,0.05)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  lastMessageUnReaded: {
    position: 'absolute',
    top: '30px',
    left: '36px',
    right: '15px',
    height: '30px',
    border: `1px solid ${customApp('color', 0.2)}`,
    backgroundColor: '#FFFFFF',
    borderRadius: '5px',
    display: 'flex',
    fontWeight: 'bold',
    alignItems: 'center',
    paddingLeft: '22px',
    paddingTop: '3px',
    fontSize: '12px',
    color: customApp('medium'),
    boxShadow: '-5px 3px 7px 1px rgba(0,0,0,0.05)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  access: {
    position: 'absolute',
    top: '33px',
    left: '45px',
    right: '0px',
    height: '30px',
    border: `1px solid ${customApp('color', 0.2)}`,
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    fontSize: '14px',
    color: customApp('color'),
    boxShadow: '0px 3px 7px 1px rgba(0,0,0,0.05)'
  },
  accessEditing: {
    position: 'absolute',
    top: '33px',
    left: '45px',
    right: '0px',
    height: 'auto',
    minHeight: '290px',
    padding: '7px',
    border: `1px solid ${customApp('color', 0.2)}`,
    borderRadius: '0 0 5px 5px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    fontSize: '14px',
    fontWeight: 'bold',
    color: customApp('color'),
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 7px 1px rgba(0,0,0,0.05)',
    '& ul': {
      fontSize: '12px'
    }
  },
});

export default styles;

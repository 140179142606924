import {
  infoColor,
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react";

import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

import { customApp } from "functions/";;
const timelineStyle = theme => ({
  root: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
  rootRelative: {
    position: 'relative',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
  content: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    overflowY: 'auto',
    backgroundColor: 'rgba(249,249,249,1)'
  },
  timeline: {
    listStyle: "none",
    padding: "0",
    position: "absolute",
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    width: '100%',
    '& .file-drop-target': {
      width: '100%',
      display: 'flex',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      right: '0px',
      height: 'auto'
    }
  },
  scrollEnd: {
    marginTop: '160px',
    position: 'relative',
    width: '100%',
    height: '0px',
    marginBottom: '-100px'
  },
  timelineSimple: {
    position: 'relative',
    // overflowX: 'auto',
    overflow: "hidden",
    marginTop: "0px",
    padding: "0 0 20px",
    width: '-webkit-fill-available',
    "&:before": {
      left: "15px"
    },
  },
  item: {
    marginBottom: "5px",
    position: "relative",
    width: 'calc(100% - 15px)',
    "&:before,&:after": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      clear: "both"
    }
  },
  timelineBadge: {
    [theme.breakpoints.down("sm")]: {
      left: "30px !important"
    },
    color: whiteColor,
    width: "30px",
    height: "30px",
    lineHeight: "25px",
    fontSize: "12px",
    textAlign: "center",
    position: "absolute",
    bottom: "23px",
    left: "50%",
    marginLeft: "-24px",
    zIndex: "1",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  },
  timelineSimpleBadge: {
    left: "30px"
  },
  custom: {
    backgroundColor: customApp('color'),
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.4)"
  },
  info: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.4)"
  },
  success: {
    backgroundColor: successColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.4)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.4)"
  },
  warning: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.4)"
  },
  primary: {
    backgroundColor: primaryColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.4)"
  },
  badgeIcon: {
    width: "14px",
    height: "14px"
  },
  timelinePanel: {
    [theme.breakpoints.down("sm")]: {
      float: "right !important",
      width: "calc(100% - 60px) !important",
      "&:before": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "15px !important",
        left: "-20px !important",
        right: "auto !important"
      },
      "&:after": {
        borderLeftWidth: "0 !important",
        borderRightWidth: "14px !important",
        left: "-19px !important",
        right: "auto !important"
      }
    },
    width: "45%",
    float: "left",
    padding: "7px",
    marginBottom: "5px",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: whiteColor,
    "&:before": {
      position: "absolute",
      bottom: "16px",
      right: "-15px",
      display: "inline-block",
      borderTop: "15px solid transparent",
      borderLeft: "15px solid " + grayColor[10],
      borderRight: "0 solid " + grayColor[10],
      borderBottom: "15px solid transparent",
      content: '" "'
    },
    "&:after": {
      position: "absolute",
      bottom: "16px",
      right: "-14px",
      display: "inline-block",
      borderTop: "14px solid transparent",
      borderLeft: "14px solid " + whiteColor,
      borderRight: "0 solid " + whiteColor,
      borderBottom: "14px solid transparent",
      content: '" "'
    }
  },
  timelineSimplePanel: {
    width: "calc(100% - 60px)"
  },
  timelinePanelInverted: {
    [theme.breakpoints.up("sm")]: {
      float: "right",
      backgroundColor: whiteColor,
      "&:before": {
        borderLeftWidth: "0",
        borderRightWidth: "15px",
        left: "-15px",
        right: "auto"
      },
      "&:after": {
        borderLeftWidth: "0",
        borderRightWidth: "14px",
        left: "-14px",
        right: "auto"
      }
    }
  },
  timelineHeading: {
    marginBottom: "0px"
  },
  timelineBody: {
    fontSize: "12px",
    lineHeight: "21px"
  },
  timelineFooter: {
    zIndex: "1",
    position: "relative",
    float: "left",
    width: '100%',
    display: "inline-flex",
    justifyContent: "space-between"
  },
  footerUser: {
    display: "flex",
    alignItems: "center",
    fontSize: '12px',
    fontWeight: 'bold',
    color: customApp('medium')
  },
  footerLine: {
    position: 'relative',
    marginTop: "10px",
    marginBottom: "5px"
  },
  dropFile: {
    display: 'none',
  },
  onDropFile: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    backgroundColor: 'rgba(0,0,0,0.77)',
    zIndex: 11,
  },
  editor: {
    position: 'absolute',
    bottom: '0px',
    // minHeight: '50px',
    height: 'auto',
    width: 'calc(100% - 0px)',
    zIndex: 10,
  },
  editorRelative: {
    position: 'relative',
    bottom: '0px',
    minHeight: '50px',
    height: 'auto',
    width: 'calc(100% - 0px)',
    zIndex: 10,
  },
  files: {
    display: 'inline-table',
    position: 'absolute',
    // backgroundColor: 'rgba(250,250,250,0.95)',
    width: '100%',
    bottom: 0,
  },
  textEditor: {
    zIndex: 100
  },
  textEditorIcon: {
    cursor: 'pointer',
    color: customApp('color'),
  },
  progressBar: {
    backgroundColor: `${customApp('color', '0.1')} !important`,
    '& .MuiLinearProgress-indeterminate': {
      backgroundColor: `${customApp('color')} !important`
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: `${customApp('color')} !important`
    },
  },
  actionButton: {
    alignItems: 'center',
    cursor: 'pointer',
    float: 'left',
    height: '20px',
    position: 'relative',
    textAlign: 'center',
    width: '30px',
  },
  sendingMessage: {
    width: '100%'
  },
  valuePositive: {
    positive: 'relative',
    float: 'left',
    color: 'blue',
  },
  valueNegative: {
    positive: 'relative',
    float: 'left',
    color: 'red',
  },
  lastValue: {
    positive: 'relative',
    float: 'right',
    color: 'grey',
  },
  timeInfo: {
    display: 'flex',
    alignItems: 'center',
    color: 'grey',
    width: '100%',
    position: 'relative',
    float: 'left',
    marginBottom: '7px',
    '& .text': {
      marginLeft: '7px',
    }
  },
  urlPreview: {
    position: 'absolute',
    width: '100%',
    top: '-90px',
    maxHeight: '120px',
    background: 'linear-gradient(60deg, rgba(250,250,250,1), rgba(250,250,250,0.9))',
    padding: '7px',
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    '& img': {
      maxWidth: '20%',
      height: 'auto',
      position: 'relative',
      float: 'left',
      marginTop: '0px',
      marginRight: '7px'
    },
    '& .data': {
      position: 'relative',
      float: 'left',
      width: '75%',
      '& .title': {
        fontSize: '16px',
        fontWeight: 'bold',
      },
      '& .siteName': {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#909090'
      },
      '& .contentSnippet': {
        fontSize: '12px',
        color: '#909090'
      }
    }
  },
  mentionInput: {
    ...defaultFont,
    '& textarea': {
      ...defaultFont,
      padding: '7px',
      borderRadius: '7px',
      fontSize: '16px',
      width: 'calc(100% - 14px)',
      minHeight: '38px',
      border: 'solid 1px rgba(0,0,0,0.1)'
    },
    '& .sugestions': {
      top: 'none !important',
      bottom: '40px',
      border: 'solid 10px red'
    },
    '& textarea::placeholder': {
      color: 'rgba(0,0,0,0.2)',
    }
  },
  mentionWrapper: {
    width: '100%',
    background: 'transparent',
    fontSize: '0.9rem',
    color: ' #a9b5c4',
    '&&.mentionWrapper__control': {
      borderRadius: '25px',
      border: '1px solid #3a546f',
      minHeight: '45px',
      '&& .mentionWrapper__highlighter': {
        padding: '0.7rem 1rem',
      },
      '&& .mentionWrapper__input': {
        padding: '0.7rem 1rem',
        border: 0,
        resize: 'none',
        outline: 'none',
        fontSize: '0.9rem',
        color: '#7288a3',
        borderColor: ' #3a546f',
        overflow: 'hidden',
        '&& :: placeholder': {
          color: '#7288a3',
        }
      }
    },
  },
})

export default timelineStyle;

//CORE
import React from 'react'

//REDUX
import * as reduxActions from 'store/actions'
import reduxStore from "store/"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//COMPONENTS
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS

import { translate, customApp } from "functions/"

function Component(props) {
    const { classes } = props
    const { sideModule } = reduxStore.getState()
    const data = sideModule.data || {}
    const [reqSave, setReqSave] = React.useState(false)

    React.useEffect(() => {
        if (data.type === 'link' && data._id === 'new')
            setReqSave(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sideModule.data.id])

    return (
        <React.Fragment>
            <SideModuleCloseAndSave
                save={reqSave ? () => {
                } : null}
                saveClose={reqSave ? () => {
                } : null}
                close={() => {
                }}
            />
            <div className={classes.root}>
                <fieldset style={{ textAlign: "center" }}>
                    <legend style={{ color: customApp("color") }}>{translate("$__integration", 1)}</legend>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {translate("$__useIdToFeelFlowIdIntegration", 1)}
                    </div>
                    <div
                        style={{ fontSize: 14, fontWeight: "bold", color: "blue" }}
                    >{data._id}</div>
                </fieldset>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import React from 'react';
import MD5 from "crypto-js/md5"
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import cx from "classnames";
import moment from "moment-timezone";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import IconButton from "components/CustomButtons/IconButton";

//DEPENDENCIES
import CardItem from './Card';

//FUNCTIONS
import { translate, customApp } from "functions/";
import { updateCardData, filterCards } from "functions/cards";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Agile-Column";

function Column(props) {
    const { session } = reduxStore.getState()
    const { classes } = props

    let searchID = MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const search = props.searchs && props.searchs[searchID] ? props.searchs[searchID] : null

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let filtered = props.cards

    if (search)
        filtered = filterCards(filtered, search)

    let subtractDays = String(session.dashboard.period).replace('$__last', '')
    subtractDays = subtractDays.replace('Days', '')

    const startDate = moment(new Date()).tz('America/Sao_Paulo').subtract(parseInt(subtractDays), 'd').format('YYYY-MM-DDT00:00')
    const endDate = moment(new Date()).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59')

    if (props && props.periodFilter)
        filtered = filtered.filter(fil => {
            if (
                (fil.status === 'completed'
                    && fil.completed_at
                    && (
                        (fil.completed_at.low
                            && moment(new Date(fil.completed_at.low * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59') <= endDate
                            && moment(new Date(fil.completed_at.low * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00') >= startDate)
                        || (fil.completed_at && !fil.completed_at.low
                            && moment(new Date(fil.completed_at * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59') <= endDate
                            && moment(new Date(fil.completed_at * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00') >= startDate)
                    )
                )
                ||
                (
                    fil.status !== 'completed'
                    && fil.updated_at
                    && (
                        (fil.updated_at.low
                            && moment(new Date(fil.updated_at.low * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59') <= endDate
                            && moment(new Date(fil.updated_at.low * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00') >= startDate)
                        || (fil.updated_at && !fil.updated_at.low
                            && moment(new Date(fil.updated_at * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT23:59') <= endDate
                            && moment(new Date(fil.updated_at * 1000)).tz('America/Sao_Paulo').format('YYYY-MM-DDT00:00') >= startDate)
                    )
                )
            )
                return true
            return false
        }
        ).map(res => res)

    if (!props.showDeleted && !reduxStore.getState().controls.deleted)
        filtered = filtered.filter(fil => !fil.deleted).map(res => res)


    const list =
        classes.cardList +
        " " +
        cx({
            [classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
        });


    let columnName = translate(props.column.name, 1)
    let customColumns = {}
    if (reduxStore.getState().db.cards[hashs[0]] && reduxStore.getState().db.cards[hashs[0]].customColumns)
        customColumns = JSON.parse(reduxStore.getState().db.cards[hashs[0]].customColumns)

    if (
        customColumns
        && customColumns[props.column.name.replace("$__", "")]
        && customColumns[props.column.name.replace("$__", "")].name
    )
        columnName = translate(customColumns[props.column.name.replace("$__", "")].name, 1)


    return (
        <Draggable draggableId={props.columnId ? String(props.columnId) : 'NOT_ID_SENDED'} index={props.index} >
            {provided => (
                <Container
                    {...!props.isDropDisabled && provided.draggableProps}
                    {...props}
                    ref={provided.innerRef}
                >
                    <div {...provided.draggableProps}{...provided.dragHandleProps} style={{ display: 'none' }}></div>
                    <div {...!props.isDropDisabled && provided.dragHandleProps}>
                        <HeaderWithIcon
                            title={columnName}
                            icon={customColumns[props.column.name.replace("$__", "")]?.icon ? customColumns[props.column.name.replace("$__", "")]?.icon : props.column.icon || "topic"}
                            color={customApp('menu')}
                            style={{
                                padding: '7px'
                            }}
                            {...hashs.length > 0
                                && hashs[1]
                                && hashs[1] === 'overview'
                                && reduxStore.getState().db.cards[hashs[0]]
                                && reduxStore.getState().db.cards[hashs[0]].planType
                                && reduxStore.getState().db.cards[hashs[0]].planType === 'operational'
                                && reduxStore.getState().db.cards[hashs[0]]._users
                                && reduxStore.getState().db.cards[hashs[0]]._users[session._id]
                                && reduxStore.getState().db.cards[hashs[0]]._users[session._id].access
                                && parseInt(reduxStore.getState().db.cards[hashs[0]]._users[session._id].access) > 3
                                ? {
                                    editable: true,
                                    onEdit: (x) => {
                                        updateCardData(
                                            {
                                                ...props,
                                                data: { _id: hashs[0] }
                                            }, {
                                            customColumns: JSON.stringify({
                                                ...reduxStore.getState().db.cards[hashs[0]] && reduxStore.getState().db.cards[hashs[0]].customColumns ? JSON.parse(reduxStore.getState().db.cards[hashs[0]].customColumns) : {},
                                                [props.column.name.replace("$__", "")]: x
                                            })
                                        })
                                    },
                                    placeholder: translate("$__columnName")
                                } : {}}
                            customButtons={[
                                props.columnsSettings &&
                                {
                                    name: "settings",
                                    icon: "more_horiz",
                                    color: customApp('color'),
                                    onClick: () => console.log('s'),
                                    show: 'hover'
                                },
                            ]}

                            periodFilter={props && props.periodFilter ? true : false}
                        />
                    </div>
                    <Droppable
                        droppableId={props.columnId ? String(props.columnId) : 'NOT_ID_SENDED'}
                        type="card"
                        isDropDisabled={props.column.isDropDisabled}
                        direction={`${props.inLine ? 'horizontal' : 'vertical'}`}
                        isCombineEnabled={props.isCombineEnabled}
                    >
                        {(provided, snapshot) => (
                            <CardList
                                className={list}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                isDraggingOver={snapshot.isDraggingOver}
                                inLine={props.inLine}
                                {...props}
                            >
                                {filtered.sort((a, b) => {
                                    let orderA = props.orderByTitlePosition === "ASC" ? a.name.toLowerCase() : b.name.toLowerCase()
                                    let orderB = props.orderByTitlePosition === "ASC" ? b.name.toLowerCase() : a.name.toLowerCase()

                                    if (orderA < orderB)
                                        return -1
                                    if (orderA > orderB)
                                        return 1
                                    return 0

                                }).map((card) => {
                                    if (card) {
                                        return (
                                            <div
                                                key={card._id ? card._id : card.id ? card._id : null}
                                            >
                                                <CardItem
                                                    data={card}
                                                    inLine={props.inLine}
                                                    isDragDisable={props.column.isDragDisable}
                                                    db={props.db}
                                                    showOrder={props.showOrder || false}
                                                    showSelected={props.showSelected || false}
                                                    locked={props.locked || false}
                                                    inLineAdd={props.inLineAdd || false}
                                                    viewButton={props.viewButton || false}
                                                    cardColorPicker={props.cardColorPicker ? true : false}
                                                />
                                            </div>
                                        )
                                    }
                                    return (<></>)
                                })}
                                {props.inLineAdd &&
                                    <div style={{ position: 'relative', float: 'left', width: '100%', padding: '7px' }}>
                                        <IconButton
                                            text={translate("$__add", '*')}
                                            icon="add_box"
                                            iconColor={customApp('medium')}
                                            onClick={() => {
                                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                    ...reduxStore.getState().control,
                                                    addCard: {
                                                        _parent: props.selectedParent ? props.selectedParent : hashs[0] ? hashs[0] : null,
                                                        rows: filtered.length,
                                                        initialData: {
                                                            type: props.typeAdd ? props.typeAdd : props.columnId.length < 36 ? props.columnId : null,
                                                        }
                                                    },
                                                })
                                            }}
                                        />
                                    </div>
                                }
                                {provided.placeholder}
                                <div style={{ clear: 'both' }}></div>
                            </CardList>
                        )
                        }
                    </Droppable>
                </Container>
            )
            }
        </Draggable>
    )
}

const Container = styled.div`
    margin:0 7px;
    min-width:180px;
    ${props => props.columnStyle && props.columnStyle.width ? `width:calc(${props.columnStyle.width} - 14px);` : `width:calc(100% - 14px);`}
    @media(min-width: 960px) {
        position:relative;
        max-height:${props => props.columnStyle && props.columnStyle.maxHeight ? props.columnStyle.maxHeight : 'calc(100% - 0px)'};
       
        ${props => props.inLine
        ? `display:block;
           `
        : `margin-bottom:0px !important;
            ${props => props.columnStyle && props.columnStyle.width ? `width:${props.columnStyle.width};` : `width:100%;`}
                `
    }
      }
    @media(max-width: 959px) {
        float:left;
        position: relative;
      }
      @media(max-width: 599px) {
        float:left;
        position: relative;
      }
    `


const CardList = styled.div`
position:relative;
height:100%;
width:100%;
max-height:${props => props.columnStyle && props.columnStyle.maxHeight ? props.columnStyle.maxHeight : 'calc(100% - 30px)'};
${props => props.columnStyle && props.columnStyle.maxWidth ? props.columnStyle.maxWidth : '250px'};
 ${props =>
        props.isDraggingOver
            ? `background-color:${customApp('color')}; opacity:0.5;`
            : ''};
${props =>
        props.inLine ? `
                display:table;
                margin-bottom:33px`
            : `display:block;
                min-height: 150px !important;
                padding-bottom:15px;
                overflow-y: auto;
                `
    }
`;

//REACT
const mapStateToProps = (store) => ({
    searchs: store.searchs,
    orderByTitle: store.db.orderByTitle,
    orderByTitlePosition: store.db.orderByTitlePosition,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Column));
//CORE
import React from 'react';

//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Card from "components/Card";
import Icon from "components/Icon";


//@MATERIAL
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

//FUNCTIONS
import { translate } from 'functions/';
import { countUnRead } from "functions/chat";

function Component() {
    const { db, timeline, session } = reduxStore.getState()
    const [open, setOpen] = React.useState(false);
    const [count, setCount] = React.useState({})
    const [view, setView] = React.useState('users')
    const anchorRef = React.useRef(null)

    React.useEffect(() => {
        iniChatNotification()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeline])

    const iniChatNotification = async () => {
        const reqCount = countUnRead()
        setCount(reqCount)
        if (count.total > 0 && count.users === 0)
            setView('groups')
        if (view === 'groups' && count.total > 0 && count.groups === 0)
            setView('users')
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    let timerCards = Object.keys(db.cards).filter(a =>
        db.cards[a]
        && db.cards[a]._kpiTimer
        && db.cards[a]
        && db.cards[a]._users
        && Object.keys(db.cards[a]._kpiTimer).filter(t =>
            db.cards[a]._kpiTimer &&
            db.cards[a]._kpiTimer[t] &&
            db.cards[a]._kpiTimer[t].userId &&
            session &&
            session._id &&
            db.cards[a]._kpiTimer[t].userId === session._id
        ).length > 0
        && Object.keys(db.cards[a]._users).filter(u => session && session._id && u === session._id).length > 0
    ).map(a => db.cards[a])

    if (timerCards.length === 0)
        return (<React.Fragment></React.Fragment>)

    return (
        <div id="notificationsBt"
            style={{
                zIndex: '100 !important'
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={timerCards.length} color="secondary" overlap="rectangular">
                    <Icon
                        icon={'timer'}
                        color={'parent'}
                        title="$__timersInProgress"
                    />
                </Badge>
            </Button>
            {timerCards.length > 0 &&
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ width: '400px' }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div style={{
                                        height: 'auto',
                                        maxHeight: 'calc(90vh - 80px)',
                                        width: '100%',
                                        overflowY: 'auto'
                                    }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '7px'
                                            }}
                                        >
                                            {translate('$__timersInProgress')}
                                        </div>
                                        {
                                            timerCards.map((card, i) => (
                                                <Card db="cards" key={i} cardId={card._id} notGlobalExpanded viewTimer />
                                            ))
                                        }
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            }
        </div >
    )
}

const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

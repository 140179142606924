import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
  containerNoShadow: {
    ...defaultFont,
    position: 'relative',
    border: `0.5px solid ${customApp('light')}`,
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    padding: '3px',
    // minWidth: '150px',
    display: 'flow-root',
  },
  container: {
    ...defaultFont,
    position: 'relative',
    border: `0.5px solid ${customApp('light')}`,
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    padding: '3px',
    // minWidth: '150px',
    display: 'flow-root',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
  },
  parent: {
    width: '100%',
    padding: '0 5px',
    '& .title': {
      color: customApp('medium'),
      fontSize: '10px',
      textTransform: 'uppercase'
    },
    '& .info': {
      marginTop: '-8px',
      color: customApp('color'),
      fontWeight: '500',
      //display: 'flex',
      alignItems: 'center',
    }
  },
  infos: {
    position: 'relative',
    float: 'left',
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  indicators: {
    position: 'relative',
    float: 'left',
    height: 'auto',
    // display: 'flex',
    zIndex: '0 !important'
  },
  indicatorsIcon: {
    position: 'relative',
    float: 'left',
    margin: '0px 0px',
    '& MuiBadge-badge': {
      position: 'relative',
      float: 'left',
      //zIndex: '30 !important'
    }
  },
  image: {
    position: 'relative',
    float: 'left',
    width: '100%',
    marginTop: '7px',
    maxWidth: '100%',
    display: 'none',
  },
  imagePreview: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: '48%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    float: 'left',
    width: '100%'
  },
  imagePreviewList: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    float: 'left',
    width: "100%",
    paddingTop: "48%",
    '@media screen and (max-width: 800px)': {
      width: '100%'
    }
  },
  PreLoader: {
    top: 'calc(50% - 25px)',
    left: 'calc(50% - 25px)',
    position: 'absolute',
  },
  description: {
    position: 'relative',
    float: 'left',
    padding: '5px',
    minHeight: '75px',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipses'
  },
  dates: {
    position: 'relative',
    float: 'left',
    width: '100%',
    marginTop: '7px',
    padding: '0 5px',
    marginRight: '15px',
    color: customApp('medium'),
    fontSize: '10px',
    display: 'flex',
  },
  lateDates: {
    position: 'relative',
    float: 'left',
    width: '100%',
    marginTop: '7px',
    padding: '0 5px',
    marginRight: '15px',
    color: 'red',
    fontSize: '10px',
    display: 'flex',
  },
  dateInfo: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center'
  },
  usersContent: {
    minHeight: '20px',
    minWidth: '40px',
    zIndex: '0 !important'
  },
  users: {
    position: 'absolute',
    bottom: '0px',
    right: '2px',
    fontSize: '10px',
    '& .MuiAvatarGroup-root': {
      justifyContent: 'flex-end',
    },
    '& .MuiAvatar-root': {
      width: '25px',
      height: '25px',
      fontSize: '10px',
    }
  }
};

export default style;

import React, { useEffect, useState } from 'react'
import { stepsData, stepsData2, stepsData3 } from './getSteps';
import Tour from "reactour";
import EndTour from './EndTour';
import IntroTour from './IntroTour';
import { customApp, translate } from 'functions';
import "./style.css"

//REDUX
import reduxStore from "store/"

import api from "api"

const ReactTour = () => {
    const { session } = reduxStore.getState()
    const [stepTour, setStepTour] = useState([])
    const [show, setShow] = useState(true)
    const [tourData, setTourData] = useState([])
    const [currentStep, setCurrentStep] = useState(0)
    const [start, setStart] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(true);
    const [interaction, setInteraction] = useState(true)
    const [mask, setMask] = useState(0);
    const [end, setEnd] = useState(false);
    const [forceUpdated, setForceUpdated] = useState("")
    const [maskClassName, setMaskClassName] = useState('mask');

    const options = React.useRef({})

    useEffect(() => {
        if (session.disabledTour && window.location.hostname.indexOf("a1b2c") === -1)
            options.current = {
                disabledTour: true
            }
        const checkStrategicElement = document.getElementById("sideBar_subMenu_plan_overview");
        const checkFluxElement = document.getElementById("sideBar_subMenu_plan_board");
        if (checkStrategicElement) {
            setStepTour(stepsData2);
            setStart(true);
        } else if (checkFluxElement) {
            setStepTour(stepsData3);
            setStart(true);
        } else {
            setStepTour(stepsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const StepContent = ({ text, children, tittle }) => {
        return (
            <div >
                <h1 style={{ fontWeight: "bolder", fontSize: '22px' }}>{translate(tittle, 1)}</h1>
                {text !== undefined && text.map((text, index) => (
                    <div key={index}>
                        {console.log(text, translate(text))}
                        <h1 style={{
                            fontSize: '18px',
                            display: "flex",
                            fontWeight: "bolder",
                            marginTop: "25px",
                            color: "grey"
                        }}>{translate(text.trim(), 1)}</h1>
                        {children}
                    </div>
                ))}
            </div>
        );
    };

    const checkNextElement = React.useCallback(() => {

        const removeEvent = () => {
            const removeEvent = document.getElementById(stepTour[currentStep].id);
            removeEvent && removeEvent.removeEventListener('click', checkNextElement);
            removeEvent && removeEvent.removeEventListener('change', checkNextElement);
        };

        removeEvent();

        const nextElement = document.getElementById(stepTour[currentStep + 1]?.id);
        let item = stepTour[currentStep + 1];
        if (nextElement) {
            processStepTour(item);
            setCurrentStep((prev) => prev + 1);
        } else {
            if (currentStep !== stepTour.length - 1) {
                setTimeout(() => {
                    checkNextElement(currentStep);
                }, 300);
            } else {
                return
            }
        }
    }, [currentStep, stepTour]);

    const handleStepType = () => {

        const checkElementsExistence = () => {
            const checkStrategicElement = document.getElementById("sideBar_subMenu_plan_overview");
            const checkFluxElement = document.getElementById("sideBar_subMenu_plan_board");

            if (checkStrategicElement) {
                setCurrentStep(0);
                setStepTour(stepsData2);
                clearInterval(checkInterval);
            } else if (checkFluxElement) {
                setCurrentStep(0);
                setStepTour(stepsData3);
                clearInterval(checkInterval);
            }
        };

        const checkInterval = setInterval(checkElementsExistence, 200);

    };

    const checkFirstElement = () => {
        if (stepTour.length > 0) {
            const firstElement = document.getElementById(stepTour[0].id);

            if (firstElement && currentStep === 0) {
                let item = stepTour[currentStep];
                processStepTour(item);

                firstElement.addEventListener("click", checkNextElement);

            } else {
                setTimeout(() => {
                    checkFirstElement();
                }, 300);
            }
        }
    };

    function processStepTour(item) {
        if (typeof item.show !== 'undefined' || typeof item.fake !== 'undefined') {
            setShow(false);
        } else {
            setShow(true);
            setMask(0);
        }

        if (typeof item.maskValue !== 'undefined') {
            setMask(item.maskValue);
        }

        if (typeof item.click !== 'undefined') {
            setInteraction(false);
        }

    }

    const addEvent = () => {
        if (stepTour.length > 0) {
            const element = document.getElementById(stepTour[currentStep].id);
            if (stepTour[currentStep]?.input !== undefined) {
                element.addEventListener('change', checkNextElement);
            } else {
                if (stepTour[currentStep]?.show !== undefined) {
                    element.addEventListener('click', checkNextElement);
                } else if (stepTour[currentStep]?.fake !== undefined) {
                    const elementButton = document.getElementById(stepTour[currentStep].buttonId);
                    elementButton && elementButton.addEventListener('click', checkNextElement);
                } else {
                    element.addEventListener('click', checkNextElement);
                }

                if (stepTour[currentStep]?.last !== undefined) {
                    element.addEventListener('click', () => endTour(true));
                }

                if (stepTour[currentStep]?.change !== undefined) {
                    const elementButton = document.getElementById(stepTour[currentStep].buttonId);
                    elementButton && elementButton.addEventListener('click', handleStepType);
                    elementButton && elementButton.addEventListener('click', setMaskClassName("maskHide"));
                }

                if (stepTour[currentStep]?.handleClose !== undefined) {
                    const elementButton = document.getElementById(stepTour[currentStep].handleClose);
                    elementButton && elementButton.addEventListener('click', () => endTour());
                }


                if (stepTour[currentStep]?.handleBack !== undefined) {
                    const elementButton = document.getElementById(stepTour[currentStep].handleBack);
                    elementButton && elementButton.addEventListener('click', () => endTour());
                }
            }
        }
    };

    useEffect(() => {
        if (!options.current.disabledTour) {
            if (stepTour) {
                checkFirstElement();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepTour]);

    useEffect(() => {
        if (!options.current.disabledTour) {
            if (currentStep === 0) {
                return
            }
            if (currentStep < stepTour.length) {
                const actualElement = document.getElementById(stepTour[currentStep].id);
                actualElement && addEvent()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);



    const endTour = (end) => {
        setIsTourOpen(false);
        end && setEnd(true)
        api.post("/user/preffers", {
            disabledTour: true
        })
    }

    useEffect(() => {
        if (!options.current.disabledTour) {
            if (currentStep === 0 && stepTour[currentStep]?.id === 'quick_388') {
                const handleDelay = () => {
                    const waitElement = document.getElementById("loadBar");
                    const interval = setInterval(() => {
                        if (!document.contains(waitElement)) {
                            clearInterval(interval);
                            setTimeout(() => {
                                setMaskClassName('mask')
                                setForceUpdated('upd');
                            }, 900);
                        }
                    }, 100);
                    clearInterval(Interval)
                }
                const Interval = setInterval(handleDelay, 1000);

            }

            const generateSteps = (data, show, currentStep) => {

                const buttonStyle = {
                    padding: '10px 20px',
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    backgroundColor: customApp('menu'),
                    color: customApp('color'),
                    borderRadius: '5px',
                    border: 'none',
                    marginRight: '10px',
                    cursor: 'pointer',
                    position: "absolute",
                    right: "20px",
                    bottom: "20px"
                };

                const primaryButtonStyle = {
                    ...buttonStyle,
                    boxShadow: "none",
                    backgroundColor: 'transparent',
                    color: "black",
                    border: "none",
                    right: "none",
                    bottom: "7px",
                    left: "10px"
                };




                return data.map(({ text, id, tittle, margin, position, scale }) => {


                    return {
                        selector: '#' + id,
                        content: (
                            <>
                                {currentStep === 0 && maskClassName === 'maskHide' ? null :
                                    <>
                                        <StepContent text={translate(text)} tittle={tittle} />
                                        <div style={{ paddingTop: text ? "50px" : "25px" }}>
                                            {show && <button id="Tour_173" onClick={() => checkNextElement()} style={buttonStyle}>  {translate("$__next")} </button>}
                                            <button
                                                onClick={() => endTour()}
                                                style={primaryButtonStyle}>
                                                <p style={{ borderBottom: "1px solid black", marginTop: "20px" }}>
                                                    {translate("$__skipTour", "*")}
                                                </p>
                                            </button>
                                        </div>
                                    </>
                                }
                            </>
                        ),
                        style: {
                            fontSize: '12px',
                            fontWeight: 'bolder',
                            borderRadius: '5px',
                            color: '#333',
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            margin: margin ? margin : '10px',
                            display: (currentStep === 0 && maskClassName === 'maskHide')
                                || (window.innerWidth < 1480 && currentStep === 11) ? 'none' : 'block'
                        },
                        position: position ? position : "bottom"
                    }
                });
            }

            if (stepTour)
                setTourData(generateSteps(stepTour, show, currentStep))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepTour, show, currentStep, maskClassName]);


    if (options.current.disabledTour)
        return (<></>)

    return (
        <>
            {start ? <Tour
                maskClassName={maskClassName}
                disableFocusLock={true}
                rounded={5}
                update={forceUpdated}
                disableDotsNavigation={true}
                showNavigation={false}
                showNumber={false}
                disableInteraction={interaction}
                closeWithMask={false}
                goToStep={currentStep}
                steps={tourData}
                isOpen={isTourOpen}
                showButtons={false}
                maskSpace={mask}
                onRequestClose={() => endTour()}
            /> : <IntroTour
                handleEnd={() => {
                    setStart(true)
                    endTour()
                }}
                handleStart={() => setStart(true)} />
            }
            {end && <EndTour handleEnd={() => {
                endTour()
                setEnd(false)
            }} />}
        </>
    )

}

export default ReactTour
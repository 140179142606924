import React from "react"

//COMPONENTS
import Icon from "components/Icon/"
import CircularProgress from "components/Progress/circular"
import Tooltip from "components/Tooltip/html"

//@MATERIAL
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Badge from '@material-ui/core/Badge'
import { makeStyles } from "@material-ui/core/styles"

//FUNCTIONS
import {
    customApp,
    translate
} from "functions/"

const useStyles = makeStyles((props) => ({
    root: {
        "& .MuiIconButton-root": {
            padding: "3px",
            fontSize: "none !important",
        }
    },
    iconButtonStyleTransaparent: {
        padding: "3px",
        margin: 0,
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.2)"
        }
    },
    iconButtonStyleWhite: {
        padding: "3px !important",
        margin: 0,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.9)"
        },
    },
    shadow: {
        boxShadow: "0 0px 5px rgb(0 0 0 / 20%)"
    },
    iconText: {
        display: "flex",
        alignItens: "center",
    },
    iconText_Text: {
        marginLeft: "15px"
    },
    badge: {
        fontSize: '11px',
        whiteSpace: "nowrap"
    }
}))

function CustomButtom(props, ref) {
    const classes = useStyles()

    return (
        <div className={classes.root} style={{ ...props.style ? props.style : {} }}>
            <Tooltip
                title={props.title ? props.title : "INSERT_BUTTON_TITLE"}
            >
                <div style={{ display: "flex", alignItems: "center", paddingLeft: 7, paddingRight: 7 }}>
                    {props.text &&
                        <Button
                            disabled={props.load ? true : props.disabled ? true : false}
                            onClick={props.onClick ? (e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.onClick()
                            } : null}
                            {...props.shadow ? {
                                className: `${!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent} ${classes.shadow}`,
                            } : {
                                className: `${!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}`,
                            }}
                            {...props.buttonStyle ? { style: props.buttonStyle } : {}}
                        >
                            <div className={classes.iconText}>
                                {props.load ?
                                    <div>
                                        <CircularProgress size={25} style={{ margin: "auto", display: "flex" }} />
                                        <span style={{ marginLeft: "15px", color: "lightgray" }}>{props.loadText ? props.loadText : translate("$__saving")}</span>
                                    </div>
                                    :
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {props.icon ?
                                            <Badge
                                                overlap="rectangular"
                                                badgeContent={props.badgeContent}
                                                classes={{ badge: classes.badge }}
                                                color="secondary"
                                            >
                                                <Icon
                                                    usePropagation={props.usePropagation ? true : false}
                                                    size={props.size ? props.size : "22px"}
                                                    color={props.color ? props.color : customApp("menu")}
                                                    icon={props.icon ? props.icon : "help_outline"} />
                                            </Badge>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        <span style={{ ...props.textSize ? { fontSize: props.textSize } : {}, marginLeft: "5px", color: props.textColor ? props.textColor : props.color ? props.color : customApp("menu"), whiteSpace: "nowrap" }}>{props.text}</span>
                                    </div>
                                }

                            </div>
                        </Button>
                    }
                    {!props.text &&
                        <IconButton
                            onClick={props.onClick ? (e) => {
                                props.onClick()
                                e.preventDefault()
                                e.stopPropagation()

                            } : null}
                            className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                        >
                            <Badge
                                badgeContent={props.badgeContent}
                                color="secondary"
                                classes={{ badge: classes.badge }}
                                overlap="rectangular"
                            >
                                <Icon
                                    usePropagation={props.usePropagation ? true : false}
                                    size={props.size ? props.size : "22px"}
                                    color={props.color ? props.color : customApp("menu")}
                                    icon={props.icon ? props.icon : "help_outline"} />
                            </Badge>
                        </IconButton>
                    }
                </div>
            </Tooltip>
        </div>
    )
}

export default CustomButtom

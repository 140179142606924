import React, { useRef, useEffect, useState } from 'react';


function ViewportChecker({ children, component }) {
    const [inViewport, setInViewport] = useState(false);
    const elementRef = useRef(null);

    const isSupported = () => {
        return 'IntersectionObserver' in window;
    }

    useEffect(() => {
        if (component === 'TimeLine')
            return <></>
        let newRef = elementRef.current
        if (!isSupported()) {
            setInViewport(true);
            return;
        }
        const observer = new IntersectionObserver(
            ([entry]) => {
                setInViewport(entry.isIntersecting);
            },
            { threshold: 0.2 } //render at 20% of apear
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (newRef) {
                observer.unobserve(newRef);
            }
        };
    }, []);

    if (component === 'TimeLine')
        return <div style={{ width: '100%', height: '100%' }} >{children(true)}</div>

    return <div style={{ width: '100%', height: '100%' }} ref={elementRef}>{children(inViewport)}</div>;
}

export default ViewportChecker;
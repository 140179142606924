import React, { useState } from 'react'

//REDUX
import reduxStore from "store";

//FUNCTIONS
import { customApp } from 'functions';

const Enterprise = () =>{
  const { session } = reduxStore.getState();
  console.log(session);
  return (
    <>
    <div style={{ 
      width: "100%", 
      display: "flex",
      gap: "20px", 
      flexDirection:'column',
      alignItems:'flex-start',
      justifyContent:'center',
    }}>
      <img 
        src={session?.custom.loginBackground} 
        alt="background"
        style={{
          height:'150px',
          width:'100%',
          objectFit:'cover',
        }}
      />
      <div
        style={{
          height: "auto",
          backgroundColor: "none",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap:'0px',
          marginTop:'-110px',
          padding:'0 17px',
        }}
      >
        <img
          src={session?.custom.customLogo}
          style={{
            borderRadius: "50%",
            border: `2px solid ${customApp('color')}`,
            width: "110px",
            height: "110px",
            margin: "5px",
            zIndex:'998',
            cursor: 'auto',
            objectFit:'contain',
            backgroundColor:session?.custom.menu,
          }}
          alt='Profile'
        />
        <div style={{
          backgroundColor: 'rgb(238,238,238)',
          padding: '15px 20px 15px 50px',
          marginLeft: '-40px',
          zIndex: '0',
          borderRadius: '0px 10px 10px 0px',
          boxShadow:'rgba(0, 0, 0, 0.14) 0px 1px 4px 0px',
          border: `2px solid ${customApp('color')}`,
        }}>
          <p style={{
            color:customApp('medium'),
            fontSize:'1.7rem',
            fontWeight:'bold',
            margin:'0px',
            display:'flex',
            textAlign:'left',
            justifyContent:'flex-start',
          }}>{session?.GlobalData.fullName}</p>
          <p style={{
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
            color: customApp('color'),
            margin:'0px',
            textAlign:'left',
          }}>{session?.GlobalData.email}</p>
        </div>
      </div>
      </div>
    </>
  );
}

export default Enterprise;

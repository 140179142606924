import { initialState } from "./search-initialState";

const session = (state = initialState, data) => {
    if (data.action === 'SEARCH' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    }
    if (data.action === 'SEARCH_CLEAR') {
        return initialState
    }
    return state
}

export default session;
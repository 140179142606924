//CORE
import React from 'react'

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

//COMPONENTS
import Skeleton from "@material-ui/lab/Skeleton"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card";



function Component(props) {
    const { classes } = props;

    return (
        <div className={classes.feed}>
            <Skeleton variant="rect" animation="wave"
                style={
                    {
                        borderRadius: 0,
                        paddingTop: "calc((9 / 16) * 100%)",
                        width: "100%",
                        borderTopRightRadius: 22,
                    }
                }
            />
            <div className={classes.infos}>
                <div className={classes.linkTitle}>
                    <Skeleton variant="rect" animation="wave"
                        style={
                            {
                                width: "100%",
                                height: "12px",
                                marginBottom: 10
                            }
                        }
                    />
                    <Skeleton variant="rect" animation="wave"
                        style={
                            {
                                width: "100%",
                                height: "12px",
                                marginBottom: 10
                            }
                        }
                    />
                    <Skeleton variant="rect" animation="wave"
                        style={
                            {
                                width: "100%",
                                height: "12px",
                                marginBottom: 10
                            }
                        }
                    />
                </div>
                <div
                    className={classes.linkContent}
                >
                    <Skeleton variant="rect"
                        style={
                            {
                                marginTop: 15,
                                borderRadius: 7,
                                width: "100%",
                                height: 8,
                                marginBottom: 12
                            }
                        }
                    />
                    <Skeleton variant="rect"
                        style={
                            {
                                borderRadius: 7,
                                width: "100%",
                                height: 8,
                                marginBottom: 12
                            }
                        }
                    />
                    <Skeleton variant="rect"
                        style={
                            {
                                borderRadius: 7,
                                width: "100%",
                                height: 8,
                                marginBottom: 12
                            }
                        }
                    />
                    <Skeleton variant="rect"
                        style={
                            {
                                borderRadius: 7,
                                width: "100%",
                                height: 8,
                                marginBottom: 12
                            }
                        }
                    />
                </div>
                <div className={classes.feedFooter}>
                    <div
                        className={classes.feedDate}
                        style={{
                            width: "50%",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <div style={{
                            width: "100%",
                            height: "8px"
                        }}>
                            <Skeleton variant="rect"
                                style={
                                    {
                                        borderRadius: 7,
                                        width: "100%",
                                        height: 8,
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))

import React from 'react';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//@MATERIAL
import Paper from "@material-ui/core/Paper";
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from "@material-ui/core/styles";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Backoffice-languages";
import { translate, customApp } from 'functions';

function LanguagesView(props) {
    const { classes } = props
    const [languages, setLanguages] = React.useState({})
    const { socket } = reduxStore.getState().functions
    const [onlyEmpty, setOnlyEmpty] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [limit, setLimit] = React.useState(25)
    const [filter, setFilter] = React.useState("")

    React.useEffect(() => {
        setLanguages(reduxStore.getState().languages)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(reduxStore.getState().languages)])



    //AQUI TEM QUE ATUALIZAR O DB LOCAL 
    const updateLanguage = async (id, alias, locale, text) => {
        // console.log(id, alias, locale, text)
        if (!id) {
            socket.emit("data", {
                module: "language",
                method: "post",
                action: "addLocale"
            }, {
                alias,
                locale,
                text
            })
        } else {
            socket.emit('language.UPD', {
                alias,
                locale,
                text
            })
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{
                position: "relative",
                float: 'left',
                width: '100%',
                left: '0px',
                right: '0px',
                padding: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* PAGINATION */}
                {translate('$__page')} {page + 1} {translate('$__of')} {Math.ceil(Object.keys(languages).length / limit)}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TablePagination
                        component="div"
                        count={Object.keys(languages).length}
                        page={page}
                        onChangePage={(event, newPage) => {
                            setPage(newPage)
                        }}
                        rowsPerPage={limit}
                        onChangeRowsPerPage={(event) => {
                            setLimit(parseInt(event.target.value, 10))
                        }}
                        labelRowsPerPage={translate('$__aliasPerPage')}
                    />
                </div>
            </div>
            <div style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
                <TextField
                    label={translate('$__search', 1)}
                    variant={customApp('fieldVariant')}
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value)
                    }}
                />
                <IconButton
                    text={onlyEmpty ? translate("$__all", '*') : translate("$__onlyEmpty", '*')}
                    icon={onlyEmpty ? "subtitles_off" : "subtitles"}
                    iconColor={customApp('medium')}
                    onClick={() => {
                        setOnlyEmpty(!onlyEmpty);
                    }}
                />
            </div>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.column}>
                        {translate(`$__alias`, 1)}
                    </div>
                    <div className={classes.column}>
                        {translate(`$__translation`, 1)}
                    </div>
                </div>
                <div className={classes.body}>
                    <ul>
                        {languages
                            && Object.keys(languages)
                            && Object.keys(languages).length > 0
                            && Object.keys(languages)
                                .filter(alias => {
                                    if (
                                        (
                                            !filter
                                            ||
                                            (
                                                filter
                                                && (
                                                    alias.indexOf(filter) > -1
                                                    ||
                                                    (
                                                        languages[alias]
                                                        && languages[alias].locales
                                                        && languages[alias].locales.filter(a =>
                                                            a.name.toLowerCase().indexOf(filter.toLowerCase()) > -1
                                                        ).length > 0
                                                    )
                                                )
                                            )
                                        )
                                        &&
                                        (
                                            (onlyEmpty
                                                && languages[alias]
                                                &&
                                                (
                                                    !languages[alias].locales
                                                    ||
                                                    (
                                                        languages[alias].locales &&
                                                        (
                                                            (
                                                                !languages[alias].locales.filter(lc => lc.locale === 'pt-BR').length === 0
                                                                || (
                                                                    languages[alias].locales.filter(lc => lc.locale === 'pt-BR').length > 0 &&
                                                                    !languages[alias].locales.filter(lc => lc.locale === 'pt-BR')[0].name
                                                                )
                                                            )
                                                            || (
                                                                languages[alias].locales.filter(lc => lc.locale === 'en-US').length === 0
                                                                || (
                                                                    languages[alias].locales.filter(lc => lc.locale === 'en-US').length > 0 &&
                                                                    !languages[alias].locales.filter(lc => lc.locale === 'en-US')[0].name
                                                                )
                                                            )
                                                            || (
                                                                languages[alias].locales.filter(lc => lc.locale === 'es-ES').length === 0
                                                                || (
                                                                    languages[alias].locales.filter(lc => lc.locale === 'es-ES').length > 0 &&
                                                                    !languages[alias].locales.filter(lc => lc.locale === 'es-ES')[0].name
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                            ||
                                            !onlyEmpty
                                        )
                                    )
                                        return true
                                    return false
                                }
                                )
                                .sort((a, b) => {
                                    if (a < b) {
                                        return -1
                                    } else if (a > b) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .filter((alias, i) => i <= ((page + 1) * limit) && i >= page * limit)
                                .map((alias, i) => {
                                    return (<li key={alias.trim()}>
                                        <div className={classes.column}>
                                            {alias}
                                        </div>
                                        <div className={classes.column2}>
                                            <fieldset style={{ marginBottom: 15 }}>
                                                <legend>Português - Brasil</legend>
                                                <div style={{ position: 'relative' }}>
                                                    <HeaderWithIcon
                                                        key={`${alias}_pt-BR`}
                                                        title={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'pt-BR').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'pt-BR')[0].name}
                                                        _id={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'pt-BR').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'pt-BR')[0]._id}
                                                        editable
                                                        onEdit={(text, id) => {
                                                            updateLanguage(id, alias, 'pt-BR', text.name)

                                                        }
                                                        }
                                                        color={customApp('menu')}
                                                        noAutoFocus={true}
                                                        multiline
                                                        noCancel
                                                    />
                                                </div>
                                            </fieldset>
                                            <fieldset style={{ marginBottom: 15 }}>
                                                <legend>Inglês - Internacional</legend>
                                                <HeaderWithIcon
                                                    key={`${alias}_en-US`}
                                                    editable
                                                    title={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'en-US').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'en-US')[0].name}
                                                    _id={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'en-US').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'en-US')[0]._id}
                                                    onEdit={(text, id) => {
                                                        updateLanguage(id, alias, 'en-US', text.name)
                                                    }
                                                    }
                                                    color={customApp('menu')}
                                                    noAutoFocus={true}
                                                    multiline
                                                    noCancel
                                                />
                                            </fieldset>
                                            <fieldset style={{ marginBottom: 15 }}>
                                                <legend>Espanhol</legend>
                                                <HeaderWithIcon
                                                    key={`${alias}_es-ES`}
                                                    editable
                                                    title={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'es-ES').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'es-ES')[0].name}
                                                    _id={languages[alias].locales && languages[alias].locales.length > 0 && languages[alias].locales.filter(lc => lc.locale === 'es-ES').length > 0 && languages[alias].locales.filter(lc => lc.locale === 'es-ES')[0]._id}
                                                    onEdit={(text, id) => {
                                                        updateLanguage(id, alias, 'es-ES', text.name)
                                                    }
                                                    }
                                                    color={customApp('menu')}
                                                    noAutoFocus={true}
                                                    multiline
                                                    noCancel
                                                />
                                            </fieldset>
                                        </div>
                                    </li>
                                    )
                                })}
                    </ul>
                </div>
            </Paper >
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    store: {
        languages: store.languages
    }
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LanguagesView));
import axios from "axios";

/**
 * Create base url for requisition api
 */

const api = axios.create({
  baseURL: process.env.REACT_APP_WS
});

axios.defaults.withCredentials = true;
/**
 * intecept verbs http to verify token
 */
api.interceptors.request.use(async config => {
  const token = localStorage.getItem('TOKEN');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = "application/json;charset=UTF-8;application/x-www-form-urlencoded";
  }
  return config;
});

api.interceptors.response.use(
  async data => {
    return Promise.resolve(data);
  }
  ,
  error => {
    const response = Promise.reject(error.response);
    if (error && error.response && error.response.status && error.response.status === 401 && window.location.pathname !== '/login' && window.location.pathname !== '/register' && window.location.pathname !== '/activate') {
      // console.log(error.response.status)
      localStorage.setItem('TOKEN', null)
      localStorage.clear();
      window.open('/login', '_self')
    }
    return response
  }
);

/**
 * Export api
 */
export default api;
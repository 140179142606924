// 
import React from "react"
import moment from "moment-timezone"

//COMPONENTS
import CurrencyTextField from 'components/TextField/currency'
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import Navigation from "components/Navigation"
import ProgressLinear from "components/Progress/linear"
import SelectUsers from "components/User/select"
import TextField from "components/TextField"
import TextareaAutosize from 'react-textarea-autosize'
import FormAnswer from "componentsV3/Forms/AnswerForm"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import Modal from '@material-ui/core/Modal'

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//STYLE
import { addCard } from "./functions"

//FUNCTIONS
import { customApp, translate } from "functions"

const QuickCardAdd = (props) => {
    const [parentCard, setParentCard] = React.useState({})
    const [selectTarget, setSelectTarget] = React.useState(false)
    const [loader, setLoader] = React.useState(false)
    const [formError, setFormError] = React.useState(null)
    const [selectedUsers, setSelectedUsers] = React.useState([reduxStore.getState().session._id])
    const { db } = reduxStore.getState()

    const inputRef = React.createRef()
    const descriptionRef = React.createRef()
    const formsRef = React.useRef({})
    const valueRef = React.createRef()
    const saveRef = React.useRef({})



    React.useEffect(() => {
        if (JSON.stringify(props.addCard) !== "{}" && props.addCard._parent && reduxStore.getState().db.cards[props.addCard._parent]) {
            let theParentForm = reduxStore.getState().db.cards[props.addCard._parent] || {}
            let parentForm = Object.keys(db.cards).filter(a => db.cards[a]._parent === theParentForm._parent && db.cards[a].form).map(a => db.cards[a])[0]
            let formSettings = {
                shortCutButtonToAdd: parentForm?.shortCutButtonToAdd,
                shortCutDescription: parentForm?.shortCutDescription,
                shortCutTitle: parentForm?.shortCutTitle,
                form: parentForm?.form || {},
            }
            setParentCard({
                ...theParentForm,
                form: formSettings.form,
            })
        } else {
            setParentCard({})
            setLoader(false)
            setSelectTarget(false)
        }
        return () => {
            setParentCard({})
            setLoader(false)
            setSelectTarget(false)
        }
    }, [props.addCard])

    const init = () => {
        if (parentCard.automationUsers && parentCard.automationUsers !== "doNotChange" && parentCard.automationUsers !== "remove" && parentCard._users && Object.keys(parentCard._users).length > 0) {
            let user = reduxStore.getState().db.users[reduxStore.getState().session._id] || {}
            let users = {
                ...parentCard.automationUsers === 'add' ? {
                    [user._id]: {
                        _id: user._id,
                        name: user.name,
                        image: user.image || null
                    }
                } : {},
                ...parentCard._users,
            }
            let newRefUsers = []
            Object.keys(users)
                .sort((a, b) => {
                    if (users[a].isResponsible)
                        return -1
                    return 1
                })
                .filter(us => us)
                .forEach((us, i) => {
                    if (us && newRefUsers.indexOf(us) === -1)
                        newRefUsers.push(us)
                })
            if (JSON.stringify(newRefUsers) !== JSON.stringify(selectedUsers))
                setSelectedUsers(newRefUsers)
        }
    }
    React.useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentCard])



    const createCards = async (list) => {
        if (formsRef && formsRef.current && formsRef.current.error) {
            setFormError(formsRef.current.error)
            return
        }
        let formData = formsRef.current || {}

        if (formData.error)
            delete formData.error

        if (Object.keys(formData).length > 0)
            formData.formId = parentCard.form

        setLoader(true)
        if (saveRef && (!saveRef.current || (saveRef.current && !saveRef.current.loading))) {
            saveRef.current = ({
                ...saveRef.current, loading: true
            })


            let newData = {
                name: inputRef.current.value,
                _parent: parentCard._id,
                myDay: props.myDay || props.addCard?.initialData?.myDay || props.addCard?.myDay ? true : false,
                type: props.addCard?.initialData?.type || props.type || "task",
                status: props.status ? props.status : "notStarted",
                users: selectedUsers,
                ...formData,
                ...list ? { checklist: true } : {},
                ...descriptionRef && descriptionRef.current && descriptionRef.current.value ? { description: descriptionRef.current.value } : {},
                ...valueRef && valueRef.current && valueRef.current.getValue() ? { value: valueRef.current.getValue() } : {},
                ...(parentCard?.childrenAutoTag && parentCard?._tags && parentCard?._tags.length > 0) ? {
                    _tags: parentCard._tags
                } : {},
                ...parentCard?.setIcon ? { icon: parentCard?.setIcon } : {},
                ...props.addCard?.initialData || {},
            }
            if (
                parentCard
                && parentCard.automationDueDateType
                && parentCard.automationDueDateType === "add"
            )
                newData = {
                    ...newData,
                    startDate: moment(`${moment().format("YYYY-MM-DD")} 00:00:00`).unix("X"),
                    endDate: moment(`${moment().format("YYYY-MM-DD")} 23:59:59`).add(parentCard?.automationDueDate || 1, "days").unix("X")
                }

            if (parentCard
                && parentCard.automationStatusType
                && parentCard.automationStatusType === "replace"
            )
                newData = {
                    ...newData,
                    status: parentCard.automationStatus || props.addCard?.initialData?.status || 'notStarted',
                    ...parentCard.automationStatus && parentCard.automationStatus === 'completed' ? { completed_at: moment().unix('x') } : {},
                }

            let result = addCard(newData, props.addCard?.rows || 0, props)
            if (result) {
                formsRef.current = {}
                props.reduxFunction("ASYNC", "SET_DB", {
                    ...reduxStore.getState().db,
                    cards: {
                        ...reduxStore.getState().db.cards,
                        ...result
                    }
                })
                props.reduxFunction("ASYNC", "SET_CONTROL", {
                    ...reduxStore.getState().control,
                    addCard: {},
                })
            }
        }


        saveRef.current = ({
            ...saveRef.current, loading: false
        })
    }



    if (props.addCard && (JSON.stringify(props.addCard) === "{}" || Object.keys(props.addCard).length === 0))
        return (<React.Fragment></React.Fragment>)

    return (
        <Modal
            open={true}
            onClose={() => {
                props.reduxFunction("ASYNC", "SET_CONTROL", {
                    ...reduxStore.getState().control,
                    addCard: {},
                })
            }
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >

                <div style={{
                    position: "relative",
                    width: '400px',
                    backgroundColor: '#FFFFFF',
                    margin: 'auto',
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
                }}>
                    <div style={{
                        position: "relative",
                        width: '400px',
                        backgroundColor: '#FFFFFF',
                        padding: '9px',
                        height: "40px",
                        margin: 'auto',
                        background: customApp('ColumnTitleColor'),
                    }}>
                        <div
                            style={{
                                fontSize: "14px",
                                color: customApp('colorText')
                            }}
                        >
                            {parentCard.shortCutTitle ? parentCard.shortCutTitle : translate("$__new")}
                        </div>
                        <div style={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <CustomButton
                                id={`assistant_navigation`}
                                title={translate(`$__selectTarget`, "*")}
                                color={customApp("colorText")}
                                icon={"assistant_navigation"}
                                onClick={() => {
                                    setSelectTarget(prev => !prev)
                                }}
                                size={"20px"}
                                transparent
                            />
                            <CustomButton
                                id={`close_addCard`}
                                title={translate(`$__close`, "*")}
                                color={customApp("colorText")}
                                icon={"close"}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                                        ...reduxStore.getState().control,
                                        addCard: {

                                        },
                                    })
                                }}
                                size={"25px"}
                                transparent
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            padding: '9px',
                        }}
                    >
                        {selectTarget ?
                            <div
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                    width: "370px",
                                    marginBottom: "15px"
                                }}
                            >
                                <Navigation
                                    onSelect={(e) => {
                                        setParentCard(reduxStore.getState().db.cards[e._id])
                                    }}
                                    ignoreTypes={['chatGroup']}
                                />
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                        }

                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                display: "grid"
                            }}
                        >
                            <div
                            // style={{
                            //     position: 'absolute',
                            //     top: 0,
                            //     left: 0,
                            //     bottom: 0,
                            //     right: 0,
                            //     backgroundColor: props.backgroundColor || "rgba(250,250,250,0.77)",
                            //     width: '100%',
                            //     padding: '7px',
                            //     display: 'flex',
                            //     alignItems: 'center',
                            //     ...props.styles ? props.styles : {}
                            // }}
                            >
                                <div id="addCard_321" style={{ width: 'calc(100% - 0px)', maxHeight: "70vh", overflowY: "auto" }}>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            padding: 3
                                        }}
                                    >{parentCard.shortCutTitle ? parentCard.shortCutTitle : props.placeHolder ? props.placeHolder : translate(props.checklist && !props.myDay ? "$__newSimpleTask" : "$__newSubActivity")}</div>
                                    {props.explain ?
                                        <div
                                            style={{
                                                position: "relative",
                                                width: "100%",
                                                padding: 3,
                                                color: customApp("menu")
                                            }}
                                        >{props.explain}</div>
                                        : <React.Fragment></React.Fragment>}
                                    <TextareaAutosize
                                        ref={inputRef}
                                        style={{
                                            width: "100%",
                                            padding: 7,
                                            border: "none",
                                            borderBottom: `solid 1px ${customApp("color")}`,
                                            background: "none",
                                            resize: "none",
                                            fontFamily: "inherit",
                                            fontSize: 14,
                                        }}
                                        onKeyDown={(e) => {
                                            const keyCode = e.which || e.keyCode;
                                            if (keyCode === 13 && !e.shiftKey) {
                                                e.preventDefault();
                                                if (
                                                    inputRef.current
                                                    && inputRef.current.value
                                                    && inputRef.current.value.trim().length > 0
                                                )
                                                    createCards((((props.checklist || props.addCard?.checklist) && !props.myDay) || props.addCard?.initialData?.checklist) ? true : false)
                                                return
                                            }
                                            if (
                                                keyCode === 13
                                                && e.shiftKey
                                                && inputRef.current
                                                && inputRef.current.value
                                                && inputRef.current.value.trim().length === 0) {
                                                e.preventDefault();
                                                inputRef.current.value = ""
                                                return
                                            }

                                            if (keyCode === 27 && props.onCancel) {
                                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                    ...reduxStore.getState().control,
                                                    addCard: {

                                                    },
                                                })
                                                return
                                            }
                                        }}
                                        autoFocus={true}
                                    />
                                    {(
                                        parentCard.requireDescription
                                    ) &&
                                        <TextField
                                            inputRef={descriptionRef}
                                            label={translate("$__description", 1)}
                                            variant={customApp("fieldVariant")}
                                            name="description"
                                            InputProps={{
                                                autoComplete: "new-password",
                                                multiline: true,
                                                minRows: 3,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon icon="description" color={"lightgray"} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder={translate("$__description", 1)}
                                        />
                                    }
                                    {parentCard && (
                                        parentCard.type === "plan"
                                        || (
                                            parentCard._planId
                                            && db.cards[parentCard._planId]
                                            && db.cards[parentCard._planId].refs
                                        )
                                    ) ?
                                        <fieldset style={{ marginBottom: "14px" }}>
                                            <legend>{translate("$__references")}</legend>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ width: "46%" }}>
                                                    <TextField
                                                        label={parentCard._planId && db.cards[parentCard._planId] && db.cards[parentCard._planId].refs && db.cards[parentCard._planId].ref1 || translate("$__ref1Title", 1)}
                                                        variant={customApp("fieldVariant")}
                                                        name={"ref1Title"}
                                                        id={"edit_ref_1"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            autoComplete: "new-password",
                                                            onChange: (d) => {
                                                                formsRef.current = {
                                                                    ...formsRef.current,
                                                                    ref1: d.target.value
                                                                }
                                                            },
                                                        }}
                                                        // value={data.ref1 || ""}
                                                        placeholder={parentCard._planId && db.cards[parentCard._planId] && db.cards[parentCard._planId].refs && db.cards[parentCard._planId].ref1 || translate("$__ref1Title_placeholder", 1)}
                                                    />
                                                </div>
                                                <div style={{ width: "8%", textAlign: "center", justifyContent: "center", alignItems: "center", fontSize: "24px", display: "flex", color: customApp("menu") }}>|</div>
                                                <div style={{ width: "46%" }}>
                                                    <TextField
                                                        label={parentCard._planId && db.cards[parentCard._planId] && db.cards[parentCard._planId].refs && db.cards[parentCard._planId].ref2 || translate("$__ref2Title", 1)}
                                                        variant={customApp("fieldVariant")}
                                                        name={"ref2Title"}
                                                        id={"edit_ref_2"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            autoComplete: "new-password",
                                                            onChange: (d) => {
                                                                formsRef.current = {
                                                                    ...formsRef.current,
                                                                    ref2: d.target.value
                                                                }
                                                            },
                                                        }}
                                                        // value={data.ref2 || ""}
                                                        placeholder={parentCard._planId && db.cards[parentCard._planId] && db.cards[parentCard._planId].refs && db.cards[parentCard._planId].ref2 || translate("$__ref2Title_placeholder", 1)}
                                                    />
                                                </div>
                                            </div>
                                        </fieldset>
                                        : <></>}
                                    {parentCard && parentCard.form && reduxStore.getState().forms[parentCard.form] ?
                                        <div
                                            style={{
                                                ...formError ? { border: "solid 1px red", padding: 7 } : {}
                                            }}
                                        >
                                            <FormAnswer
                                                formId={parentCard.form}
                                                boardId={parentCard._parent}
                                                onLoad={(requires) => {

                                                }}
                                                columnChange={(parent) => {
                                                    if (parent)
                                                        formsRef.current = {
                                                            ...formsRef.current,
                                                            _parent: parent
                                                        }
                                                }}
                                                onChange={(values, error) => {
                                                    formsRef.current = {
                                                        ...formsRef.current,
                                                        ...values,
                                                        error
                                                    }
                                                    setFormError(null)
                                                }}
                                            />
                                            {formError ? <span style={{ color: "red" }}>{translate(formError)}</span> : <></>}
                                        </div>
                                        : <></>}
                                    {(
                                        parentCard.requireValue
                                    ) &&
                                        <CurrencyTextField
                                            label={translate("$__value", 1)}
                                            variant="standard"
                                            currencySymbol="$"
                                            outputFormat="string"
                                            inputRef={valueRef}
                                            ref={valueRef}
                                        />
                                    }
                                    {(!parentCard.showInPanel) &&
                                        <SelectUsers
                                            includeMe
                                            defineResponsible
                                            avatarSize="23px"
                                            permission={true}
                                            data={parentCard}
                                            noLimit
                                            multiple
                                            onSelect={(e) => {
                                                setSelectedUsers(e)
                                            }}
                                            selected={selectedUsers}
                                            transparent
                                        />
                                    }
                                </div>
                                {!loader ?
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: "space-between",
                                        marginTop: 33
                                    }}>
                                        <CustomButton
                                            id={`addModal_cancelButton`}
                                            title={translate(`$__cancel`, 1)}
                                            text={props.placeHolder || translate(`$__cancel`, 1)}
                                            color={customApp("medium")}
                                            icon={"cancel"}
                                            onClick={() => {
                                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                    ...reduxStore.getState().control,
                                                    addCard: {

                                                    },
                                                })
                                            }}
                                            size={"25px"}
                                        />
                                        <div id="addCard_441">
                                            <CustomButton
                                                id={`sideModule_saveButton`}
                                                title={parentCard.shortCutButtonToAdd ? parentCard.shortCutButtonToAdd : props.placeHolder || translate(`$__save`, 1)}
                                                text={parentCard.shortCutButtonToAdd ? parentCard.shortCutButtonToAdd : props.placeHolder || translate(`$__save`, 1)}
                                                color={'green'}
                                                icon={"save"}
                                                onClick={() => {
                                                    if (!inputRef.current.value || (
                                                        inputRef.current.value
                                                        && inputRef.current.value.trim() === ""
                                                    )) {
                                                        inputRef.current.focus()
                                                    } else {
                                                        createCards((((props.checklist || props.addCard?.checklist) && !props.myDay) || props.addCard?.initialData?.checklist) ? true : false)
                                                    }
                                                }
                                                }
                                                size={"25px"}
                                            />
                                        </div>

                                    </div>
                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: 40
                                        }}
                                    >
                                        <ProgressLinear />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

//REACT
const mapStateToProps = (store) => ({
    addCard: store.controls.addCard
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuickCardAdd)
import React from "react"
import moment from "moment-timezone"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Checkbox from "components/Checkbox"
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import TextField from "components/TextField";

//DEPENDENCIES
import List from "./avatarList"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Button from "@material-ui/core/Button"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment";

//FUNCTIONS
import {
    getUserList,
    getUserAccess
} from "functions/cards"

import {
    appAlert,
    customApp,
    getAppAccess,
    translate,
} from "functions/"

import {
    cardsAccess,
    isResponsible as setResponsible,
    userParam as setUserParam,
} from "functions/users"

import api from "api/"
import { setMyDay } from "functions/cards"

const useStyles = makeStyles((props) => ({
    iconButtonStyleTransaparent: {
        margin: 0,
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.9)"
        },
        "& .MuiAvatarGroup-avatar": {
            border: 'none',
            zIndex: 0,
            borderRadius: "50%"
        }
    },
    iconButtonStyleWhite: {
        margin: 0,
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.9)"
        },
        "& .MuiAvatarGroup-avatar": {
            border: 'none',
            zIndex: 0,
            borderRadius: "50%"
        }
    },
}))

function Comp(props) {
    const classes = useStyles()
    const anchorRef = React.useRef(null)
    const { db, session } = reduxStore.getState()
    const [data, setData] = React.useState(db.cards[props._id] || {})
    const [open, setOpen] = React.useState(!props.open ? false : true)
    const [removeUsers, setRemoveUsers] = React.useState([])
    const [myDayUsers, setMyDayUsers] = React.useState([])
    const [searchValue, setSearchValue] = React.useState("")
    const [search, setSearch] = React.useState(false)
    const [selectToMyDay, setSelectToMyDay] = React.useState(false)
    const [userList, setUserList] = React.useState(getUserList(data._id))

    React.useEffect(() => {
        setUserList(getUserList(data._id))
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.users])


    React.useEffect(() => {
        if (db.cards[props._id] && JSON.stringify(db.cards[props._id]) !== JSON.stringify(data)) {
            setData(db.cards[props._id])
            setUserList(getUserList(data._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(data._id)

    let admin = false
    if (CardAccess > 3)
        admin = true

    if (!admin && AppAccess.plan && AppAccess.planAdmin)
        admin = true

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    const handleClose = (event) => {
        setOpen(false)
        setSelectToMyDay(false)
        setMyDayUsers([])
    }

    function handleListKeyDown(event) {
        if (event.key === "Tab") {
            event.preventDefault()
            setOpen(false)
        }
    }

    const selectUser = async (id, acc) => {
        let access = 1
        if (data && data.type === 'plan')
            access = 3

        if (!acc) {
            if (
                data._planId
                && db.cards[data._planId]
                && db.cards[data._planId]._users
                && db.cards[data._planId]._users[id]
                && db.cards[data._planId]._users[id].access
            ) {
                if (data._parent
                    && db.cards[data._parent]
                    && db.cards[data._parent]._users
                    && db.cards[data._parent]._users[id]
                    && db.cards[data._parent]._users[id].access
                    && parseInt(db.cards[data._parent]._users[id].access) > parseInt(db.cards[data._planId]._users[id].access)
                ) {
                    access = parseInt(db.cards[data._parent]._users[id].access)
                } else {
                    access = parseInt(db.cards[data._planId]._users[id].access)
                }
            }
        } else {
            access = acc
        }
        const noNotification = data && data._users && data._users[id] ? true : false
        await cardsAccess(props, id, access, data._id, noNotification)
    }

    const deleteAccessUser = async (userId) => {
        api.put(`cards/removeAccess/`, {
            cardId: data._id,
            userIds: removeUsers
        })
        setRemoveUsers([])
    }

    const sendToDay = async () => {
        myDayUsers.forEach(s => {
            setMyDay(props, {
                _id: data._id,
                userId: s,
                option: true
            })
        })
        setSelectToMyDay(false)
    }


    const validateUser = (pr) => {
        let usersToRemove = Array.from(removeUsers)
        if (data._users[pr] && usersToRemove.indexOf(pr) === -1) {
            usersToRemove.push(pr)
            setRemoveUsers(usersToRemove)
            // setCountRemove(usersToRemove.length)
        } else if (data._users[pr] && usersToRemove.indexOf(pr) > -1) {
            usersToRemove.splice(usersToRemove.indexOf(pr), 1)
            setRemoveUsers(usersToRemove)
            // setCountRemove(usersToRemove.length)
        } else if (!data._users[pr]) {

            selectUser(pr)
        }
    }

    const GroupMenuItem = (({ data }) => {
        return (
            <MenuItem >
                {data.label}
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                </div>

            </MenuItem>
        )
    })

    const UserMenuItem = ({ pr }) => {
        let userPermission = false
        if (props.permission)
            userPermission = true
        let myDay = false
        if (data._users && data._users[pr] && data._users[pr].myDay
            && data._users[pr].myDay
            && moment(parseInt(data._users[pr].myDay) * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY")
        ) {
            myDay = true
        }
        let isResponsible = Object.keys(userList).length > 1 && userList[pr] && userList[pr].isResponsible ? true : false
        let customDefine = props.customDefine && props.customDefine.name && Object.keys(userList).length > 1 && userList[pr] && userList[pr][props.customDefine.name] ? true : false
        let user = userList[pr] ? userList[pr] : data._users[pr]
        return (
            <MenuItem
                onClick={userPermission ? () => {
                } : null}
                disabled={!userPermission}
            >
                <div style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    ...!userPermission ? { cursor: "pointer" } : {}
                }}>
                    <div
                        style={{
                            width: "33px",
                            height: "33px",
                            borderRadius: "50%",
                            ...!props.customDefine ? {
                                border: isResponsible ? `3px deepskyblue solid` : myDay ? `2px #ff8800 solid` : `none`,
                            } : {
                                border: customDefine ? `3px green solid` : `none`,
                            },
                            backgroundImage: user.image ? `url("${user.image}")` : `null`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundColor: "lightgray",
                            textAlign: "center",
                            marginRight: 7
                        }}
                        src={user.image || null} >
                        {!user.image && user && user.name ? user.name.substr(0, 1) : ""}
                    </div>
                    {user.name || user.name}
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                        {!props.customDefine && data._users[pr] && !data._users[pr].isResponsible && admin ?
                            <CustomButton
                                title={translate(`$__defineAsResponsible`, 1)}
                                color={"lightGray"}
                                icon={"admin_panel_settings"}
                                onClick={() => {
                                    appAlert({
                                        message: translate('$__confirmSetAsResponsibleCard', 1),
                                        variant: 'warning',
                                        persist: false,
                                        horizontal: 'left',
                                        confirm: () => {
                                            setResponsible(props, {
                                                cardId: data._id,
                                                userId: pr
                                            })
                                        }
                                    })
                                }}
                                size={"25px"}
                            />

                            : <React.Fragment></React.Fragment>}

                        {props.customDefine && data._users[pr] && admin ?
                            <CustomButton
                                title={translate(!data._users[pr][props.customDefine.name] ? `$__defineAs${props.customDefine.name}` : `$__removeThe${props.customDefine.name}`, 1)}
                                color={!data._users[pr][props.customDefine.name] ? "lightgray" : "green"}
                                icon={(props.customDefine && props.customDefine.icon) || "check"}
                                onClick={() => {
                                    appAlert({
                                        message: translate(!data._users[pr][props.customDefine.name] ? `$__confirmSetAs${props.customDefine.name}` : `$__confirmUnSetAs${props.customDefine.name}`, 1),
                                        variant: 'warning',
                                        persist: false,
                                        horizontal: 'left',
                                        confirm: () => {
                                            setUserParam(props, {
                                                cardId: data._id,
                                                userId: pr,
                                                param: props.customDefine.name,
                                                on: !data._users[pr][props.customDefine.name] ? true : false
                                            })
                                        }
                                    })
                                }}
                                size={"25px"}
                            />

                            : <React.Fragment></React.Fragment>}
                        {!props.customDefine ?
                            <Checkbox
                                {...selectToMyDay ? {
                                    checked: myDay || myDayUsers.indexOf(pr) > -1 ? true : false
                                } : {
                                    checked: data._users[pr] ? true : false
                                }}
                                disabled={selectToMyDay && myDay ? true : false}
                                onClick={userPermission ? () => {
                                    if (!selectToMyDay) {
                                        validateUser(pr)
                                    } else {
                                        let mdu = Array.from(myDayUsers)
                                        if (mdu.indexOf(pr) === -1) {
                                            mdu.push(pr)
                                        } else if (mdu.indexOf(pr) > -1) {
                                            mdu.splice(mdu.indexOf(pr), 1)
                                        }
                                        setMyDayUsers(mdu)
                                    }
                                } : null}
                                color="default"
                            />
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                    {removeUsers.indexOf(pr) > -1 &&
                        <div style={{
                            height: "2px",
                            width: "100%",
                            backgroundColor: "red",
                            position: "absolute",
                            top: "50%"
                        }}>

                        </div>
                    }
                </div>
            </MenuItem>
        )
    }

    return (
        <div
            id="quickUser"
            onClick={(e) => {
                e.stopPropagation()
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                id="quick_388"
                aria-label="menu"
                onClick={(e) => {
                    if (
                        (
                            props.permission
                        )
                        ||
                        (
                            data && data._users && data._users[session._id] && data._users[session._id].access && parseInt(data._users[session._id].access) > 3
                        )
                    ) {
                        setOpen((prevOpen) => !prevOpen)
                        e.stopPropagation()
                    }
                }}
                className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
            >
                <List
                    data={data || {}}
                    {...props && props.customDefine ? { customDefine: props.customDefine } : {}}
                />
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                // role={undefined}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom", maxHeight: "333px", minHeight: "50px", overflowY: "auto" }}
                        id="quick_417"
                    >
                        <Paper>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingBottom: ((removeUsers && removeUsers.length > 0) || (myDayUsers && myDayUsers.length > 0)) ? "50px" : 0,
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                            >
                                <ClickAwayListener
                                    onClickAway={() => {
                                        handleClose()
                                    }}>
                                    <div>
                                        {search &&
                                            <div style={{
                                                position: "relative",
                                                marginTop: 15,
                                                width: "100%",
                                                padding: "4px 15px",
                                                fontSize: "16px",
                                                color: "lightGray"
                                            }}>
                                                <TextField
                                                    id={`quickUser_Search`}
                                                    label={translate('$__searchByName', 1)}
                                                    variant={customApp('fieldVariant')}
                                                    name={'fullName'}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        autoComplete: 'new-password',
                                                        endAdornment: (
                                                            <InputAdornment position="end"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSearchValue("")
                                                                    setSearch(false)
                                                                }}>
                                                                <Icon icon='close' />
                                                            </InputAdornment>
                                                        ),
                                                        onChange: (d) => {
                                                            setSearchValue(d.target.value)
                                                        },
                                                        onBlur: (d) => {

                                                        }
                                                    }}
                                                    value={searchValue}
                                                    placeholder={translate('$__searchByName', 1)}
                                                    autoFocus
                                                />
                                            </div>
                                        }
                                        <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                            {selectToMyDay ?
                                                <div style={{ position: "relative", overflow: "auto" }}>
                                                    <MenuItem
                                                        disabled
                                                    >{translate("$__selectUsersToMyDay", "*")}</MenuItem>
                                                    {Object.keys(data._users)
                                                        .filter(a =>
                                                            a !== session._id

                                                        )
                                                        .sort((a, b) => {
                                                            if (data._users[a]?.name && data._users[b]?.name && data._users[a].name.toLowerCase() < data._users[b].name.toLowerCase())
                                                                return -1
                                                            else if (data._users[a]?.name && data._users[b]?.name && data._users[a].name.toLowerCase() > data._users[b].name.toLowerCase())
                                                                return 1
                                                            return 0
                                                        }).map(pr => <UserMenuItem pr={pr} key={pr} />)}
                                                </div>
                                                :
                                                <div>
                                                    {props.types && props.types.indexOf("teams") > -1 ?
                                                        <React.Fragment>
                                                            <MenuItem
                                                                disabled
                                                            >{translate("$__usersGroups", "*")}</MenuItem>
                                                            {reduxStore.getState().db.userGroup.map(group => {
                                                                return (
                                                                    <GroupMenuItem data={group} key={group.value} />
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                        : <React.Fragment></React.Fragment>}
                                                    <MenuItem
                                                        disabled
                                                    >{translate("$__users", "*")}</MenuItem>
                                                    {Object.keys(userList).filter(a => {
                                                        if (
                                                            (!searchValue ||
                                                                (
                                                                    searchValue &&
                                                                    userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                                ))
                                                            && userList[a].type === "user"
                                                            && !userList[a].deleted
                                                            && !userList[a]._parent
                                                            && (
                                                                !props.customDefine
                                                                ||
                                                                (props.customDefine
                                                                    && data._users[a]
                                                                )
                                                            )
                                                        ) return true
                                                        return false
                                                    }).sort((a, b) => {
                                                        let nameA = db.cards[props._id]._users[a] ? `A${userList[a].name}` : `W${userList[a].name}`
                                                        let nameB = db.cards[props._id]._users[b] ? `A${userList[b].name}` : `W${userList[b].name}`
                                                        if (nameA < nameB)
                                                            return -1
                                                        else if (nameA > nameB)
                                                            return 1
                                                        return 0
                                                    }).map(pr =>
                                                        userList && userList[pr] && userList[pr]._id &&
                                                        <UserMenuItem pr={pr} key={pr} />
                                                    )}
                                                    {
                                                        Object.keys(userList).filter(a => {
                                                            if (
                                                                (!searchValue ||
                                                                    (
                                                                        searchValue &&
                                                                        userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                                    ))
                                                                && userList[a].type === "external"
                                                                && !userList[a].deleted
                                                                && (
                                                                    !props.customDefine
                                                                    ||
                                                                    (props.customDefine
                                                                        && data._users[a]
                                                                    )
                                                                )
                                                            ) return true
                                                            return false
                                                        }).length > 0 &&
                                                        <MenuItem
                                                            disabled
                                                        >{translate("$__UnityUsers", "*")}</MenuItem>
                                                    }
                                                    {Object.keys(userList).filter(a => (
                                                        (!searchValue ||
                                                            (
                                                                searchValue &&
                                                                userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                            ))
                                                        && userList[a].type === "external"
                                                        && !userList[a].deleted
                                                        && (
                                                            !props.customDefine
                                                            ||
                                                            (props.customDefine
                                                                && data._users[a]
                                                            )
                                                        )
                                                    )).sort((a, b) => {
                                                        if (userList[a].name < userList[b].name)
                                                            return -1
                                                        else if (userList[a].name > userList[b].name)
                                                            return 1
                                                        return 0
                                                    }).map(pr =>
                                                        userList && userList[pr] && userList[pr]._id &&
                                                        <UserMenuItem pr={pr} key={pr} />
                                                    )}
                                                    {
                                                        Object.keys(userList).filter(a => {
                                                            if (
                                                                (!searchValue ||
                                                                    (
                                                                        searchValue &&
                                                                        userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                                    ))
                                                                &&
                                                                userList[a].type === "consultant"
                                                                && !userList[a].deleted
                                                                && (
                                                                    !props.customDefine
                                                                    ||
                                                                    (props.customDefine
                                                                        && data._users[a]
                                                                    )
                                                                )
                                                            ) return true
                                                            return false
                                                        }).length > 0 &&
                                                        <MenuItem
                                                            disabled
                                                        >{translate("$__planningUsers", "*")}</MenuItem>
                                                    }
                                                    {Object.keys(userList).filter(a => {
                                                        if (
                                                            (!searchValue ||
                                                                (
                                                                    searchValue &&
                                                                    userList[a].name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                                                                ))
                                                            &&
                                                            userList[a].type === "consultant"
                                                            && !userList[a].deleted
                                                            && (
                                                                !props.customDefine
                                                                ||
                                                                (props.customDefine
                                                                    && data._users[a]
                                                                )
                                                            )
                                                        ) return true
                                                        return false
                                                    }).sort((a, b) => {
                                                        if (userList[a].name < userList[b].name)
                                                            return -1
                                                        else if (userList[a].name > userList[b].name)
                                                            return 1
                                                        return 0
                                                    })
                                                        .filter(pr => userList && userList[pr] && userList[pr]._id)
                                                        .map(pr =>
                                                            <UserMenuItem pr={pr} key={pr} />
                                                        )}
                                                </div>
                                            }
                                        </MenuList>
                                        {(!search && !selectToMyDay) ?
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 15,
                                                    top: 10,
                                                }}
                                            >
                                                <CustomButton
                                                    title={translate(`$__searchUser`, 1)}
                                                    color={customApp("menu")}
                                                    icon={"search"}
                                                    onClick={() => { setSearch(true) }}
                                                    size={"25px"}
                                                />
                                            </div>
                                            :
                                            <React.Fragment></React.Fragment>
                                        }
                                        {(!props.customDefine && !search && !selectToMyDay && admin) ?
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: 45,
                                                    top: 10,
                                                }}
                                            >
                                                <CustomButton
                                                    title={translate(`$__sendToMyDay`, 1)}
                                                    color={'orange'}
                                                    icon={"schedule_send"}
                                                    onClick={() => { setSelectToMyDay(true) }}
                                                    size={"25px"}
                                                />
                                            </div>
                                            : selectToMyDay ?
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: 15,
                                                        top: 10,
                                                    }}
                                                >
                                                    <CustomButton
                                                        title={translate(`$__back`, 1)}
                                                        color={customApp("menu")}
                                                        icon={"arrow_back"}
                                                        onClick={() => { setSelectToMyDay(false) }}
                                                        size={"25px"}
                                                    />
                                                </div>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </ClickAwayListener>
                            </div>
                            {removeUsers && removeUsers.length > 0 &&
                                <div style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    padding: "7px",
                                    backgroundColor: "red",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                    zIndex: 10
                                }}
                                    onClick={(e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    })}
                                >
                                    {translate("$__confirmSelectedUsersRemove")}
                                    <CustomButton
                                        title={translate(`$__remove`, 1)}
                                        color="#FFFFFF"
                                        icon={"delete_forever"}
                                        onClick={() => {
                                            deleteAccessUser()
                                        }}
                                        size={"25px"}
                                        transparent
                                    />
                                </div>
                            }
                            {selectToMyDay && myDayUsers && myDayUsers.length > 0 &&
                                <div style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    padding: "7px",
                                    backgroundColor: "orange",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    color: "#FFFFFF",
                                    alignItems: "center",
                                }}
                                    onClick={(e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                    })}
                                >
                                    <AvatarGroup
                                        style={{
                                            flexWrap: "wrap"
                                        }}
                                    >
                                        {myDayUsers
                                            .map((a, i) => {
                                                if (a && reduxStore.getState().db.users[a]) {
                                                    return (
                                                        <Tooltip
                                                            title={reduxStore.getState().db.users[a].name}
                                                            key={a}
                                                        >
                                                            <Avatar
                                                                alt={reduxStore.getState().db.users[a].name}
                                                                src={reduxStore.getState().db.users[a].image || null}
                                                                style={{
                                                                    width: "23px",
                                                                    height: "23px"
                                                                }}>
                                                                {reduxStore.getState().db.users[a].name.substr(0, 1)}
                                                            </Avatar>
                                                        </Tooltip>
                                                    )
                                                } else {
                                                    return (<React.Fragment></React.Fragment>)
                                                }
                                            })}
                                    </AvatarGroup>
                                    <CustomButton
                                        title={translate(`$__sendToMyDay`, 1)}
                                        color="#FFFFFF"
                                        icon={"schedule_send"}
                                        onClick={() => {
                                            sendToDay()
                                        }}
                                        size={"25px"}
                                        transparent
                                    />
                                </div>
                            }
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
const mapStateToProps = (store) => {
    return ({
        cards: store.db.cards,
        users: store.db.users
    })
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Comp)
import React from "react"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import Navigation from "components/Navigation"
import ProgressLinear from "components/Progress/linear"
import Switch from "components/Switch"
import TextField from "components/TextField"

//@MATERIAL
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InputAdornment from "@material-ui/core/InputAdornment"
import Modal from '@material-ui/core/Modal'

import {
    copyCards,
    saveCard,
} from "functions/cards"

import {
    customApp,
    translate,
} from "functions/"

import {
    loadCardsV2
} from "functions/loadData"

function Card(props) {
    const [data, setData] = React.useState({})
    const [copyName, setCopyName] = React.useState(data?.name || "")
    // const [copyModel, setCopyModel] = React.useState(false)
    const [copyRel, setCopyRel] = React.useState({})
    const [selectTarget, setSelectTarget] = React.useState(false)
    const [duplicateLoader, setDuplicateLoader] = React.useState(false)
    const [options, setOptions] = React.useState({})

    React.useEffect(() => {
        if (props.toCopyMove && reduxStore.getState().db.cards[props.toCopyMove]) {
            setData(reduxStore.getState().db.cards[props.toCopyMove])
            setCopyName(`${reduxStore.getState().db.cards[props.toCopyMove].name} (${translate("$__copy", '*')})` || "")
        } else {
            setData({})
            setCopyName("")
        }
        return () => {
            setData({})
            setCopyName("")
        }
    }, [props.toCopyMove])

    if (JSON.stringify(data) === "{}" || !data)
        return (<React.Fragment></React.Fragment>)

    const duplicateCard = async () => {
        setDuplicateLoader(true)
        let newData = {}
        Object.keys(data).forEach(x => {
            if (x.indexOf("_") === -1 && x !== "created_at" && x !== "updated_at")
                newData = {
                    ...newData,
                    [x]: data[x]
                }
        })
        newData = {
            ...data,
            ...newData,
            idRel: copyRel && copyRel._id ? copyRel._id : data._parent ? data._parent : null,
            name: copyName,
            // ...copyModel ? { isModel: true } : {},
            status: "notStarted"
        }
        Object.keys(newData).forEach(x => {
            if (x.indexOf("_") === 0)
                delete newData[x]
            if (typeof newData[x] !== "string" && typeof newData[x] !== "boolean")
                delete newData[x]
        })
        newData._oldId = data._id

        try {
            const request = await saveCard(props, true, {
                ...newData,
                _oldId: data._id
            }, newData.idRel);
            if (request && request._id) {
                let copyResult = await copyCards(data._id, request._id, options);
                if (copyResult) {
                    setTimeout(async () => {
                        await loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'childrens', idRel: request._id })
                        await loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'childrens', idRel: data._id })
                        setOptions({})
                        setSelectTarget(false)
                        setDuplicateLoader(false)
                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                            ...reduxStore.getState().control,
                            toCopyMove: null,
                        })
                    }, 1000)
                }
            }
        } catch (e) {
            console.log("ERROR", e)
        }
    }


    const planningTypes = ["guideline", "guidelineD", "guidelineR", "guidelineC", "guidelineE", "objective", "goal"]

    return (
        <Modal
            open={true}
            onClose={() => {
                props.reduxFunction("ASYNC", "SET_CONTROL", {
                    ...reduxStore.getState().control,
                    toCopyMove: null,
                })
            }
            }
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <ClickAwayListener
                onClickAway={() => {
                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                        ...reduxStore.getState().control,
                        toCopyMove: null,
                    })
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >

                    <div style={{
                        position: "relative",
                        width: '400px',
                        backgroundColor: '#FFFFFF',
                        margin: 'auto',
                        boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
                    }}>
                        <div style={{
                            position: "relative",
                            width: '400px',
                            backgroundColor: '#FFFFFF',
                            padding: '9px',
                            height: "40px",
                            margin: 'auto',
                            background: customApp('ColumnTitleColor'),
                        }}>
                            <div
                                style={{
                                    fontSize: "14px",
                                    color: customApp("color")
                                }}
                            >{translate("$__duplicate")}</div>
                            <div style={{
                                position: "absolute",
                                right: 5,
                                top: 5
                            }}>
                                <CustomButton
                                    id={`close_duplicate`}
                                    title={translate(`$__close`, "*")}
                                    color={customApp("color")}
                                    icon={"close"}
                                    onClick={() => {
                                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                                            ...reduxStore.getState().control,
                                            toCopyMove: null,
                                        })
                                    }}
                                    size={"25px"}
                                    transparent
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                padding: '9px',
                            }}
                        >
                            {selectTarget ?
                                <div
                                    style={{
                                        position: 'relative',
                                        float: 'left',
                                        width: "370px",
                                        marginBottom: "15px"
                                    }}
                                >
                                    <Navigation
                                        onSelect={(e) => {
                                            setCopyRel(e)
                                        }}
                                        ignoreTypes={['chatGroup']}
                                    />
                                </div>
                                :
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end"
                                    }}
                                >
                                    <CustomButton
                                        title={translate(`$__selectTarget`, 1)}
                                        text={translate(`$__selectTarget`, 1)}
                                        color={customApp("menu")}
                                        icon={"assistant_navigation"}
                                        onClick={() => {
                                            setSelectTarget(true)
                                        }}
                                        size={"14px"}
                                        textSize={"14px"}
                                    />
                                </div>
                            }

                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    display: "grid"
                                }}
                            >
                                <React.Fragment>
                                    {(
                                        copyRel
                                        && copyRel.type
                                        && planningTypes.indexOf(copyRel.type) > -1
                                        && (
                                            copyRel.type.indexOf(data.type) > -1
                                            ||
                                            data.type.indexOf(copyRel.type) > -1
                                        )
                                    ) ?
                                        <div style={{
                                            color: "red",
                                            fontSize: 12,
                                            marginBottom: 15,
                                            textTransform: "uppercase",
                                            textAlign: "center"
                                        }}>
                                            {translate(`$__youAreDuplicating`)}: {translate(`$__${copyRel.type}`)} {translate(`$__for`)}: {translate(`$__${copyRel.type}`)}.
                                            {translate(`$__thisWillBeTurnedIntoAnActivity`)}
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    <TextField
                                        label={translate("$__copyAs", 1)}
                                        variant={customApp("fieldVariant")}
                                        name={"copyAs"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            autoComplete: "new-password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Icon icon="clear" onClick={() => {
                                                        setCopyName(data.name)
                                                    }} />
                                                </InputAdornment>
                                            ),
                                            onChange: (d) => {
                                                setCopyName(d.target.value)
                                            },
                                        }}
                                        value={copyName}
                                        placeholder={translate("$__copyAs_placeholder", 1)}
                                    />
                                    {/* <SelectUsers
                                            includeMe
                                            defineResponsible
                                            avatarSize="23px"
                                            permission={true}
                                            noLimit
                                            multiple
                                            onSelect={(e) => {
                                                setSelectedUsers(e)
                                            }}
                                            selected={selectedUsers}
                                            transparent
                                        /> */}
                                    <fieldset>
                                        <legend>{translate("$__options")}</legend>
                                        <div>
                                            <Switch
                                                checked={options.copyUsers ? true : false}
                                                onClick={(x) => { setOptions({ ...options, copyUsers: options.copyUsers ? false : true }) }}
                                                name="copyUsers"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__copyUsersAccess")}
                                        </div>
                                        <div>
                                            <Switch
                                                checked={options.copyTimeline ? true : false}
                                                onClick={(x) => { setOptions({ ...options, copyTimeline: options.copyTimeline ? false : true }) }}
                                                name="copyTimeline"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__copyTimelineIfExists")}
                                        </div>
                                        <div>
                                            <Switch
                                                checked={options.copyTags ? true : false}
                                                onClick={(x) => { setOptions({ ...options, copyTags: options.copyTags ? false : true }) }}
                                                name="copyTags"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__copyTagsIfExists")}
                                        </div>
                                        {/* <div>
                                                <Switch
                                                    checked={copyModel ? true : false}
                                                    onClick={(x) => { setCopyModel(!copyModel) }}
                                                    name="copyModel"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                                {translate("$__saveAsTemplate")}
                                            </div> */}
                                    </fieldset>
                                </React.Fragment>
                                {!duplicateLoader ?

                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: 40
                                        }}
                                    >
                                        <CustomButton
                                            title={translate(`$__cancel`, 1)}
                                            text={translate(`$__cancel`, 1)}
                                            color={"lightgray"}
                                            icon={"cancel"}
                                            onClick={() => {
                                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                    ...reduxStore.getState().control,
                                                    toCopyMove: null,
                                                })
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                        />
                                        <CustomButton
                                            title={translate(`$__duplicateAllLinkedItems`, 1)}
                                            text={translate(`$__duplicateAllLinkedItems`, 1)}
                                            color={customApp("menu")}
                                            icon={"dynamic_feed"}
                                            onClick={() => {
                                                duplicateCard(true)
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                            transparent
                                        />

                                        {/* <CustomButton
                                            title={translate(`$__confirmMoveCard`, 1)}
                                            text={translate(`$__confirmMoveCard`, 1)}
                                            color={customApp("menu")}
                                            icon={"dynamic_feed"}
                                            onClick={() => {
                                                moveCard(true)
                                            }}
                                            size={"12px"}
                                            textSize={"12px"}
                                            transparent
                                        /> */}
                                    </div>
                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginTop: 40
                                        }}
                                    >
                                        <ProgressLinear />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        </Modal>
    )
}

//REACT
const mapStateToProps = (store) => ({
    toCopyMove: store.controls.toCopyMove
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Card)
import React, { useRef, useState } from 'react'
import { Icon } from "@material-ui/core";
import MessageEditor from "./MessageEditor";
import Draggable from 'react-draggable';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Chip, makeStyles, Menu, MenuItem, ClickAwayListener } from '@material-ui/core';
import ModalNotes from "./ModalNotes";

const useStyles = makeStyles({
    TextField: {
        color: 'rgb(150,150,150)',
        width: '100%',
        padding: '0',
        fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '300',
        fontSize: '14px',
        '& label': {
            marginTop: '5px',
        },
        '& .Mui-focused': {
            color: 'rgb(150,150,150)',
        },
        '& .MuiInput-underline:after': {
            border: '1px solid rgb(192,178,131)',
        },
        '& +:before': {
            borderBottom: '1px solid rgb(192,178,131)',
        },

    },
})
const CardNotes = ({ addNote, notes, setNotes, note, id }) => {
    const elementRef = useRef();
    const style = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [modal, setModal] = useState(false)
    const open = Boolean(anchorEl);
    const tags = ["Weekly", "Daily", "Yearly"]
    const colors = ["#ff8686", "#ffc864", "#ffff8c", "#c4c4c4", "white"]
    const styleMenu = {
        display: 'flex',
        gap: '10px',
        alignItems: 'center'
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleChangeData = (data) => {
        updateNote('data', data);
    };
    const handleChangePosition = (e, data) => {
        const updatedNotes = notes.map((note) =>
            note.id === id ? ({
                ...note,
                x: data.x,
                y: data.y
            }) : note
        );
        setNotes(updatedNotes);
    };
    const updateNote = (property, value) => {
        let values
        if (property === "color") {
            values = { [property]: value, changeColor: false }
        } else if (property === "edit" && value === false) {
            values = { [property]: value, date: new Date() }
        } else {
            values = { [property]: value }
        }
        setNotes(notes.map((note) =>
            note.id === id ? ({
                ...note,
                ...values,
            }) : note
        ))
    };
    function handleClickDelete(deleteNote) {
        setNotes(notes.filter((note) => note !== deleteNote))
    }
    return (
        <ClickAwayListener
            onClickAway={() => {
                updateNote("edit", false)
            }}
        >
            <div>
                <Draggable elementRef={elementRef} position={{ x: note?.x, y: note?.y }} onStop={handleChangePosition}>
                    <div
                        style={{
                            minWidth: "290px",
                            width: "auto",
                            backgroundColor: "white",
                            position: "absolute",
                            zIndex: "997",
                            borderRadius: '5px',
                            cursor: note?.cursor,
                            boxShadow: "0 0px 5px 0 rgba(0, 0, 0, 0.15)",
                        }}
                        ref={elementRef}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: "5px",
                                justifyContent: "space-between",
                                borderBottom: "#e8e8e8 1px solid",
                                padding: "7px",
                                color: '#000000CC',
                                fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
                                cursor: note?.cursor,
                            }}
                            onMouseDown={() => updateNote("cursor", "grabbing")}
                            onMouseOver={() => updateNote("cursor", "grab")}
                            onMouseUp={() => updateNote("cursor", "grab")}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "5px",
                                }}
                            >
                                <Icon>note</Icon>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Anotações
                                </span>

                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                            }}>
                                {note?.edit && <Icon
                                    onClick={() => updateNote("edit", false)}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "1.2rem",
                                    }}>
                                    cancel
                                </Icon>}
                                <Icon
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "1.2rem",
                                    }}>
                                    more_vert
                                </Icon>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}>
                                    <MenuItem style={styleMenu} onClick={() => { updateNote("hidden", true); handleClose() }}><Icon>visibility_off</Icon>Fechar anotação</MenuItem>
                                    <MenuItem style={styleMenu} onClick={() => { updateNote("edit", !note?.edit); handleClose() }}><Icon>{note?.edit ? "cancel" : "edit"}</Icon>Editar anotação</MenuItem>
                                    <MenuItem style={styleMenu} onClick={() => { addNote(id); handleClose() }}><Icon>add</Icon>Adicionar anotação</MenuItem>
                                    {/* <MenuItem style={styleMenu} onClick={() => {setModal(true); handleClose()}}><Icon>autorenew</Icon>Converter em atividade</MenuItem> */}
                                    <MenuItem style={styleMenu} onClick={() => { handleClickDelete(note); handleClose() }}><Icon>delete</Icon>Excluir anotação</MenuItem>
                                </Menu>
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                minHeight: "100px",
                                flexDirection: "column",
                                alignItems: "center",
                                cursor: note?.edit === false ? 'pointer' : 'auto',
                            }}
                            onClick={() => note?.edit === false ? updateNote("edit", true) : null}
                        >
                            <MessageEditor edit={note?.edit} data={note?.data} handleChangeData={handleChangeData} />
                        </div>
                        <div style={{
                            width: '90%',
                            margin: '10px auto'
                        }}>
                            {note.edit ? <Autocomplete
                                className={style.TextField}
                                multiple
                                id="tags-filled"
                                options={tags.map((option) => option)}
                                value={note?.tags}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip label={option} {...getTagProps({ index })} size='small' />
                                    ))
                                }
                                onChange={(event, newValue) =>
                                    setNotes(notes.map((note) => (
                                        note.id === id ? ({
                                            ...note,
                                            tags: newValue,
                                        }) : note
                                    )))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                        label="Etiquetas"
                                    />
                                )}
                            /> :
                                <div style={{
                                    display: 'flex',
                                    gap: '5px',
                                    flexWrap: 'wrap'
                                }}>
                                    {note?.tags.map((option, index) => (
                                        <Chip label={option} key={index} size='small' />
                                    ))}
                                </div>}
                        </div>
                        <div style={{
                            width: '100%',
                            height: '15px',
                            borderRadius: '0px 0px 5px 5px',
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'center',
                            borderTop: "#e8e8e8 1px solid",
                            backgroundColor: note?.color,
                        }}>
                            {note?.changeColor && colors.map((color, index) => <><div style={{
                                backgroundColor: color,
                                width: '100%',
                                height: '15px',
                                borderBottomLeftRadius: index === 0 ? '5px' : '0px',
                                borderBottomRightRadius: index === 4 ? '5px' : '0px',
                                cursor: 'pointer',
                            }}
                                onClick={() => updateNote("color", color)}>
                            </div></>)}
                            {!note?.changeColor && <Icon style={{
                                position: 'absolute',
                                bottom: '-5px',
                                color: 'rgba(0, 0, 0, 0.15)',
                                cursor: 'pointer',
                            }}
                                onClick={() => updateNote("changeColor", !note?.changeColor)}
                            >more_horiz</Icon>}
                        </div>
                    </div>
                </Draggable>
                {modal && <ModalNotes note={note} setModal={setModal} />}
            </div>
        </ClickAwayListener>
    )
}

export default CardNotes
import React from 'react'

//COMPONENTS
import CircularProgress from "components/Progress/circular"
import Icon from 'components/Icon'
import HtmlTooltip from 'components/Tooltip/html'

const TableRow = (props) => {

    const { usuario, index, handleCheckboxChange, isActive, style, handleDataChange, selected, dataError, column, add, data, data1 } = props

    const divStyles = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        flex: 1,
        border: '1px solid #ebebeb',
        alignSelf: 'stretch'
    };


    const handleChange = () => {
        handleCheckboxChange(index);
    };

    const InputRender = (props) => {
        const prop = props.item
        return (
            <div style={{ ...divStyles }}>
                <input
                    style={{ border: 'none', backgroundColor: 'transparent', height: '100%', width: '100%', marginLeft: '5px', color: '#1C1C1C', fontWeight: prop === "nomecompleto" ? 'bold' : 'normal' }}
                    type="text"
                    defaultValue={usuario?.[prop]}
                    onMouseUp={(e) => e.target.focus()}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent' }}
                    onBlur={(e) => handleDataChange(index, e.target.value, [prop])}
                />
            </div>
        )
    }

    const renderIcon = () => {

        if (data1 && Object.keys(data1).length > 0 && index === Object.keys(data1).length) {
            return (
                <CircularProgress/>
            );
        } else if (add && index === 0 && !Object.keys(data1).length) {
            return (
                <CircularProgress/>
            );
        } else if (index === 0 && isActive === false && Object.keys(data1).length) {
            return (
                <Icon style={{ color: 'red', alignSelf: 'center' }}>warning</Icon>
            );
        } else if (isActive === false && Object.keys(data1).length >= index && index !== 0) {
            return (
                <Icon style={{ color: 'red', alignSelf: 'center' }}>warning</Icon>
            );
        } else if (dataError) {
            return (
                <Icon style={{ color: 'red', alignSelf: 'center' }}>warning</Icon>
            );
        }

    }

    const getRowColor = () => {

        if (isActive === false && Object.keys(data1).length >= index && index !== 0) {
            return 'rgba(255, 0, 0, 0.02)';
        } else if (isActive && Object.keys(data1).length >= index && index !== 0) {
            return 'rgba(0, 128, 0, 0.02)';
        } else if (dataError && isActive === false) {
            return 'rgba(255, 0, 0, 0.02)';
        } else if (index === 0 && isActive === false && Object.keys(data1).length) {
            return 'rgba(255, 0, 0, 0.02)';
        } else if (isActive && index === 0) {
            return 'rgba(0, 128, 0, 0.02)';
        } else {
            return 'transparent';
        }
    }

    return (
        <div style={style}>
            <div style={{ backgroundColor: getRowColor(), width: '100%', display: 'flex', justifyContent: 'center', height: '44px', overflowY: data.length > 11 ? 'hidden' : 'scroll' }}>
                <div
                    style={{
                        ...divStyles,
                        width: '80px',
                        border: '1px solid #ebebeb',
                        flex: 'none',
                        height: '100%',
                        justifyContent: 'center',
                        display: 'flex'
                    }}>
                    <input
                        type="checkbox"
                        style={{ width: '16px', height: '16px', borderRadius: '15px', cursor: 'pointer', backgroundColor: 'transparent' }}
                        defaultChecked={selected}
                        onMouseUp={handleChange}
                    />
                </div>
                {/* <div style={divStyles}>
                    <Switch checked={true} />
                </div> */}

                {column.length > 0 && column.map((item, itemIndex) => {
                    return (
                        <InputRender item={item} key={itemIndex} />
                    )
                })}
                <div style={{
                    ...divStyles,
                    width: '60px',
                    border: '1px solid #ebebeb',
                    flex: 'none',
                    height: '100%',
                    justifyContent: 'center',
                    display: 'flex'
                }}>
                    {isActive ?
                        <div>
                            <Icon style={{ color: 'green', alignSelf: 'center' }}>done</Icon>
                        </div>
                        :
                        <div>
                            <HtmlTooltip

                                title={(
                                    <React.Fragment>
                                        <div style={{ width: '200px', marginBottom: '20px', fontSize: '16px', marginTop: '20px' }}>
                                            <p>{dataError?.emailError}</p>
                                            <p>{dataError?.nameError}</p>
                                            <p>{dataError?.emailErrorInv}</p>
                                        </div>
                                    </React.Fragment>
                                )} >
                                <div style={{ alignSelf: 'center' }}>
                                    {renderIcon()}
                                </div>
                            </HtmlTooltip>
                        </div>}
                </div>
            </div>
        </div>
    );
};


export default TableRow


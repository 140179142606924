import React from "react"
import moment from "moment-timezone"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Badge from "components/Icon/Badge"
import Checklist from "components/Checklist"
import ConnectedIn from "components/ConnectedIn"
import Icon from "components/Icon"
import KPIChange from "components/KPI/change"
import LinearProgress from "components/Progress/linear"

//DEPENDENCIES
import SWOT from "./SWOT"

//@MATERIAL
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Card.js"

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"
import MachenIco from "assets/icons/machen.svg"
import BrightAndConnectedIco from "assets/icons/BrigthAndConnected"

//FUNCTIONS
import PreLoader from "components/PreLoader"

import {
    translate,
    customApp,
    nl2br
} from "functions/"
import {
    loadCardsV2
} from "functions/loadData.js"
import {
    loadTimeline
} from "functions/chat"


function ExpandedCard(props) {
    const { classes } = props
    const { db, session } = reduxStore.getState()
    const [ImagePreview, setImagePreview] = React.useState(null)
    const [stopLoading, setStopLoading] = React.useState(true)
    const [loadingData, setLoadingData] = React.useState(false)

    const mounted = React.useRef(true)
    const cardRef = React.createRef()

    React.useEffect(() => {
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let data = reduxStore.getState().db.cards[props.cardId || props.data._id] || {}

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    React.useEffect(() => {
        if (mounted.current) {
            if (data._parent && (!hashs[0] || (hashs[0] && hashs[0].length !== 36))) {
                init()
            } else {
                setLoadingData(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = async () => {
        if (
            (
                !data._loadTimeline
                ||
                (
                    data._loadTimeline
                    && !data._loadTimeline['kpi']
                )
            )
            && data.kpi_type && data.kpiType !== 'task' && data.kpiType !== 'timer' && data.kpiType !== 'time')
            loadTimeline(
                {
                    ...props,
                    fnIdentification: "Card/index/init()"
                },
                {
                    ids: [data._id],
                    db: 'cards',
                    type: 'kpi'
                }
            )

        const reqParents = async (parent) => {
            if (!db.cards[parent]) {
                if (mounted.current)
                    setLoadingData(true)
                let response = await loadCardsV2({ ...props, ignoreLoader: true }, {
                    idRel: [parent],
                    cardLoad: "parents"
                })
                if (response && mounted.current)
                    setLoadingData(false)
            } else if (db.cards[parent] && db.cards[parent]._parent) {
                let response = reqParents(db.cards[parent]._parent)
                if (response && mounted.current)
                    setLoadingData(false)
            } else {
                setLoadingData(false)
            }
        }

        const reqFullData = async () => {
            reqParents(data._parent)
        }
        reqFullData()
        if (props.onResize)
            props.onResize()
    }

    if (loadingData)
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <span style={{ color: customApp('menu'), fontSize: "12px", paddingRight: "15px", whiteSpace: "nowrap" }}>{translate('$__loadingData')}</span>
            <LinearProgress />
        </div>

    if (!data && !data._id)
        return null


    let hasYoutube = false
    let youtube = (<React.Fragment></React.Fragment>)
    if (data && data.url && (
        data.url.indexOf(".youtube.com/watch?") > -1
        || data.url.indexOf("youtu.be") > -1
    )) {
        let ybQuery = data.url.indexOf(".youtube.com/watch?") > -1 ? data.url.split("?")[1].split("v=")[1].split("&")[0] : data.url.split(".be/")[1]
        if (ybQuery) {
            youtube = (
                <iframe
                    width="560"
                    height="315"
                    style={{
                        top: 0,
                        width: "100%",
                        maxWidth: "500px",
                        height: "100%",
                        alignSelf: "center",
                        position: "absolute",
                        overflow: "hidden"
                    }}
                    src={`https://www.youtube.com/embed/${ybQuery}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen >

                </iframe >
            )
            hasYoutube = true
        }
    }
    if (data && data.url && (
        data.url.indexOf("https://vimeo.com") > -1
    )) {
        let vmQuery = data.url.replace("https://", "").split("/")[1]
        if (vmQuery) {
            youtube = (
                <iframe
                    width="560"
                    height="315"
                    style={{
                        top: 0,
                        width: "100%",
                        maxWidth: "500px",
                        height: "100%",
                        alignSelf: "center",
                        position: "absolute",
                        overflow: "hidden"
                    }}
                    src={`https://player.vimeo.com/video/${vmQuery}`}
                    title="Vimeo video player"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                >

                </iframe >
            )
            hasYoutube = true
        }
    }

    return (
        <div ref={cardRef}>
            <div>
                <div {...props.provided && props.provided.dragHandleProps} style={{ display: "none" }}></div>
                <React.Fragment>
                    <div className={classes.infos}
                        onClick={(e) => {
                            if (props.onClick) {
                                props.onClick()
                            }
                        }
                        }
                    >
                        <div style={{ position: "relative", display: "flex" }}>
                            {
                                (hasYoutube || (data.image && (((data.image || props.Avatar)) || ((data.image || props.Avatar))) && !session.cards.hide.image)) ?
                                    <div
                                        className={classes.imagePreview}
                                        style={ImagePreview ? {
                                            backgroundImage: `url("${!ImagePreview ? defaultAvatar : ImagePreview}")`,
                                        } : {
                                            background: customApp('AvatarBackground'),
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        {hasYoutube ?
                                            youtube
                                            :
                                            data.image && !ImagePreview ?
                                                <React.Fragment>
                                                    <div style={{ marginTop: `calc(-48% - ${(data.type === 'insight' || data.type === 'link') ? `10px` : `0px`})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {(data.type === 'insight' || data.type === 'link') ?
                                                            <Icon icon={BrightAndConnectedIco} size="39px" color="rgba(250,250,250,0.77)" />
                                                            :
                                                            <img alt="MACHEN_IMAGE" src={MachenIco} style={{ width: '25%' }} />
                                                        }
                                                        <div style={{ clear: "both" }}></div>
                                                    </div>
                                                    {!stopLoading &&
                                                        <div
                                                            style={{ position: 'absolute', marginTop: "calc(-48% + 0px)", zIndex: 1 }}>
                                                            <PreLoader />
                                                            <div style={{ clear: "both" }}></div>
                                                        </div>
                                                    }
                                                </React.Fragment>
                                                :
                                                <React.Fragment></React.Fragment>
                                        }
                                        {!hasYoutube && (!ImagePreview || props.Avatar || data.image !== ImagePreview) &&
                                            <React.Fragment>
                                                <img
                                                    alt="MACHEN_IMAGE"
                                                    className={classes.image}
                                                    src={`${data.image ? data.image : defaultAvatar}`}
                                                    onError={(e) => {
                                                        if (data.image) {
                                                            setImagePreview(null)
                                                        } else {
                                                            setStopLoading(true)
                                                        }
                                                    }}
                                                    onLoad={(e) => {
                                                        setImagePreview(`${(props.Avatar && !data.image) ? defaultAvatar : data.image}`)
                                                    }}
                                                    width="100%" />
                                            </React.Fragment>
                                        }
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                    : props.Avatar && !session.cards.hide.image &&
                                    <div
                                        className={db.viewList ? classes.imagePreviewList : classes.imagePreview}
                                        style={{
                                            background: customApp('AvatarBackground'),
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        <div style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            {(data.type === 'insight' || data.type === 'link') ?
                                                <Icon icon={BrightAndConnectedIco} size="39px" color="rgba(250,250,250,0.77)" />
                                                :
                                                <img alt="MACHEN_IMAGE" src={MachenIco} style={{ width: '25%' }} />
                                            }
                                            <div style={{ clear: "both" }}></div>
                                        </div>
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                            }
                            {data.image ?
                                <div style={{ position: "absolute", bottom: 0, right: 0, padding: "2px 7px", backgroundColor: "rgba(0,0,0,0.7)", color: "#FFFFFF", borderRadius: "10px 0px 0px 0px", fontSize: 10 }}># {data._cardCode}</div>
                                : <></>}
                            {
                                (
                                    (
                                        Object.keys(reduxStore.getState().db.cards).filter(fil => reduxStore.getState().db.cards[fil]
                                            && reduxStore.getState().db.cards[fil].type === "step"
                                            && reduxStore.getState().db.cards[fil]._parent === data._id
                                        ).length > 0

                                    )
                                )
                                &&
                                <div style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    padding: "2px 7px",
                                    backgroundColor: "rgba(0,0,0,0.7)",
                                    color: "#FFFFFF",
                                    borderRadius: "0px 10px 0px 0px",
                                    fontSize: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    // zIndex: 1
                                }}
                                    onClick={(e) => {
                                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                                            ...reduxStore.getState().control,
                                            board: data._id,
                                        })
                                        e.preventDefault()
                                        e.stopPropagation()
                                    }}
                                ><Icon
                                        size={"10px"}
                                        color={"#FFFFFF"}
                                        icon={"open_in_new"}
                                    />{translate("$__openTaskList")}</div>
                            }
                        </div>
                        {(
                            db.dataType.filter(c => c.name === data.type)[0].module !== 'swot'
                            &&
                            (
                                (
                                    data._parent
                                    && data._parent !== data._id
                                )
                                ||
                                (
                                    data._planId
                                    && data._planId !== data._id
                                )
                            )
                        ) &&
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    display: 'flex',
                                    paddingTop: '7px'
                                }}>
                                {db.cards[data._parent] ?
                                    <ConnectedIn
                                        id={data._parent && reduxStore.getState().db.cards[data._parent] ? data._parent : data._planId && reduxStore.getState().db.cards[data._planId] ? data._planId : null}
                                        loading={loadingData}
                                        showLegend={props.Resume ? false : true}
                                    />
                                    : <React.Fragment></React.Fragment>
                                }
                                <div style={{ clear: "both" }}></div>
                            </div>
                        }
                        <div style={!db.viewList || props.ignoreList ? {} : {
                            position: 'relative',
                            float: 'left',
                            width: '100%'
                        }}>
                            {
                                (data.type.indexOf("guideline") > -1 && ((data._aspects && data._aspects.length > 0) || (data._aspects && data._aspects.length > 0))) ?
                                    <SWOT data={data._aspects} type={data.type} />
                                    : <React.Fragment></React.Fragment>
                            }
                            {!props.searchText && (data.description || data.type === "plan") ?
                                <Tooltip
                                    title={data.description || ""}
                                >
                                    {props.Resume ?
                                        <div className={classes.description}
                                            style={{
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                height: 90,
                                                ...!data.description ? { color: "lightgray" } : {}
                                            }}
                                        >{data.description ? `${data.description}` : (data.type === "plan") ? translate("$__planDescriptionCardPlaceholder") : <></>}</div>
                                        :
                                        <div className={classes.description} style={{
                                            wordBreak: 'break-word',
                                            height: 90,
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }} dangerouslySetInnerHTML={{
                                            __html: data.description ? nl2br(data.description) : ``
                                        }} />
                                    }
                                </Tooltip> : <></>
                            }
                            {(data.kpi_type && !session.cards.hide.kpi) &&
                                <KPIChange data={data} chartShow={!session.cards.hide.kpiChart ? true : false} onResize={() => {
                                    // if (props.onResize) props.onResize()
                                }} />
                            }
                            {(data.type === "link" || data.type === "insight") &&
                                <div className={classes.dates}>
                                    <div className={classes.dateInfo}>
                                        <Badge
                                            overlap="rectangular"
                                            className={classes.dateIco} icon="event_available" title={"$__pubDate"} size="14px" disabled />
                                        {moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY HH:mm")}
                                    </div>
                                </div>
                            }
                            <div style={{ clear: "both" }}></div>
                        </div>
                        <div style={{ clear: "both" }}></div>
                    </div>
                </React.Fragment>
                {
                    (
                        (data.type !== 'plan'
                            &&
                            (

                                Object.keys(reduxStore.getState().db.cards).filter(fil => reduxStore.getState().db.cards[fil]
                                    && reduxStore.getState().db.cards[fil].checklist
                                    && reduxStore.getState().db.cards[fil]._parent === data._id
                                ).length > 0
                                && Object.keys(reduxStore.getState().db.cards).filter(fil => reduxStore.getState().db.cards[fil]
                                    && reduxStore.getState().db.cards[fil].type === "step"
                                    && reduxStore.getState().db.cards[fil]._parent === data._id
                                ).length === 0

                            )
                        )
                    )
                    &&
                    <div style={{
                        position: 'relative',
                        width: 'calc(100%)',
                        top: '16px',
                        clear: 'both',
                        marginBottom: '16px'
                    }}>
                        <div style={{
                            padding: '7px',
                            backgroundColor: 'rgba(0,0,0,0.05)',
                            fontSize: '16px',
                            borderTopLeftRadius: 7,
                            borderTopRightRadius: 7,
                        }}>{translate('$__list', 1)}</div>
                        <Checklist nodeId={data._id} db={"cards"} noDelete showProgress />
                        <div style={{ clear: 'both' }}></div>
                    </div>
                }
                <div style={{ clear: "both" }}></div>
            </div >
            <div style={{ clear: "both" }}></div>
        </div >
    )
}

//REACT
const mapStateToProps = (store, props) => ({
    card: store.db.cards,
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ExpandedCard))
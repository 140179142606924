import React
    // { useEffect, useRef, useState } 
    from "react"
// import Peer from "simple-peer"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
// import reduxStore from "store/"

// //@MATERIAL
// import Avatar from "@material-ui/core/Avatar"
// import Button from "@material-ui/core/Button"
// import CustomButton from "components/Buttons/custom"

// import defaultAvatar from "assets/img/default-avatar.png"


// import { translate } from "functions/"

//STYLES
import "./styles.css"

function MachenPeerCall(props) {
    return (<></>)
    // const { session } = reduxStore.getState()
    // const [me, setMe] = useState("")
    // const [idToCall, setIdToCall] = useState("")
    // const [stream, setStream] = useState()
    // const [receivingCall, setReceivingCall] = useState(false)
    // const [callerOn, setCallerOn] = useState(false)
    // const [caller, setCaller] = useState("")
    // const [callerSignal, setCallerSignal] = useState()
    // const [callAccepted, setCallAccepted] = useState(false)
    // const [callEnded, setCallEnded] = useState(false)
    // const [name, setName] = useState("")
    // const [fromData, setFrom] = useState({})
    // const myVideo = useRef()
    // const userVideo = useRef()
    // const connectionRef = useRef()

    // var peerFrom = new Peer({ initiator: true, stream: stream })
    // var peerTo = new Peer()

    // useEffect(() => {
    //     const { socket } = reduxStore.getState().functions
    //     socket.on("callToUser", (data) => {
    //         setReceivingCall(true)
    //         setFrom({ name: data.name, image: data.image })
    //         setCaller(data.from)
    //         setIdToCall(data.from)
    //         setName(data.name || "")
    //         setCallerSignal(data.signal)
    //     })

    // }, [props.socket?.id])

    // useEffect(() => {
    //     const { socket } = reduxStore.getState().functions
    //     socket.on("cancelCallToUser", (data) => {
    //         if (data?.to === me)
    //             declineCall()
    //     })
    // }, [caller, me])


    // useEffect(() => {
    //     if (props.usersStatus[session._id]?.socketId)
    //         setMe(props.usersStatus[session._id].socketId)
    //     if (props.nodeId && props.usersStatus[props.nodeId]?.socketId) {
    //         setIdToCall(props.usersStatus[props.nodeId].socketId)
    //     }
    // }, [JSON.stringify(props.usersStatus), props.nodeId])

    // const declineCall = () => {
    //     setReceivingCall(true)
    //     setFrom({})
    //     setCaller("")
    //     setName("")
    //     setCallerSignal()
    //     setCallEnded(true)
    //     setStream(true)
    //     setStream()
    //     setReceivingCall(false)
    //     setCallerOn(false)
    //     setCaller("")
    //     setCallerSignal()
    //     setIdToCall("")
    //     setCallEnded(false)
    //     setName("")
    //     if (stream && stream.getTracks())
    //         stream.getTracks().forEach(track => track.stop())
    //     if (connectionRef?.current)
    //         connectionRef.current.destroy()
    // }

    // const leaveCall = (id) => {
    //     const { socket } = reduxStore.getState().functions
    //     socket.emit("cancelCallToUser", {
    //         to: id,
    //         from: me
    //     })
    //     declineCall()
    // }

    // const callUser = (id) => {
    //     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
    //         setStream(stream)
    //         myVideo.current.srcObject = stream

    //         const { socket } = reduxStore.getState().functions

    //         const peer = new Peer({
    //             initiator: true,
    //             trickle: false,
    //             stream: stream
    //         })

    //         peer.on("signal", (data) => {
    //             socket.emit("callToUser", {
    //                 userToCall: id,
    //                 signalData: data,
    //                 from: me,
    //                 name: name,
    //                 image: session.image || {}
    //             })
    //             setCallerOn(true)
    //         })
    //         peer.on("stream", (stream) => {
    //             userVideo.current.srcObject = stream
    //         })
    //         socket.on("callAccepted", (signal) => {
    //             setCallAccepted(true)
    //             peer.signal(signal)
    //         })
    //         connectionRef.current = peer
    //         props.reduxFunction("ASYNC", "SET_CHAT", {
    //             ...reduxStore.getState().chat,
    //             callId: id,
    //         })
    //     })

    // }

    // const answerCall = async () => {
    //     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((stream) => {
    //         setStream(stream)
    //         myVideo.current.srcObject = stream
    //         setCallerOn(true)
    //         setCallAccepted(true)

    //         const { socket } = reduxStore.getState().functions

    //         const peer = new Peer({
    //             initiator: false,
    //             trickle: false,
    //             stream: stream
    //         })

    //         peer.on("signal", (data) => {
    //             socket.emit("answerCall", { signal: data, to: caller })
    //         })
    //         peer.on("stream", (streamFrom) => {
    //             userVideo.current.srcObject = streamFrom
    //         })

    //         peer.signal(callerSignal)
    //         connectionRef.current = peer
    //     })

    // }



    // return (
    //     <>
    //         {!callerOn && receivingCall && !callAccepted ? (
    //             <div className="caller">
    //                 <div
    //                     style={{ display: "flex", alignItems: "center" }}>
    //                     <Avatar alt={fromData.name} src={fromData.image || defaultAvatar} />
    //                     <div style={{ marginLeft: 15 }}>{fromData.name || "NOME"}</div>
    //                 </div>
    //                 <h1 className={"callerEffect"}>{name} is calling...</h1>
    //                 <div style={{
    //                     display: "flex",
    //                     alignItems: "center"
    //                 }}>
    //                     <Button variant="contained" color="primary" onClick={answerCall}>
    //                         {translate("$__answer", "*")}
    //                     </Button>
    //                     <Button
    //                         variant="contained"
    //                         color="secondary"
    //                         onClick={() => { leaveCall(idToCall) }} style={{ marginLeft: 7 }}>
    //                         {translate("$__decline", "*")}
    //                     </Button>
    //                 </div>
    //             </div >
    //         ) : <></>
    //         }
    //         {
    //             !callerOn && props.nodeId ?
    //                 <>
    //                     <CustomButton
    //                         id={`chat_call`}
    //                         title={translate(`$__call`, "*")}
    //                         color={"#FFFFFF"}
    //                         icon={"video_call"}
    //                         onClick={() => {
    //                             callUser(idToCall)
    //                         }}
    //                         size={"25px"}
    //                         transparent
    //                     />
    //                 </> : <></>
    //         }
    //         <div className="container" style={{ ...!callerOn ? { display: "none" } : {} }} >
    //             <div className="video-container">
    //                 <div className="video-from">
    //                     {callAccepted && !callEnded ?
    //                         <video playsInline ref={userVideo} autoPlay style={{ width: "800px" }} /> :
    //                         <></>}
    //                 </div>
    //                 <div className="video-me">
    //                     {stream && <video playsInline muted ref={myVideo} autoPlay style={{ width: "150px" }} />}
    //                 </div>
    //                 <Button
    //                     variant="contained"
    //                     color="secondary"
    //                     onClick={() => { leaveCall(idToCall) }}
    //                 >
    //                     {translate("$__endCall", "*")}
    //                 </Button>
    //             </div>
    //         </div >
    //     </>
    // )
}

const mapStateToProps = (store) => ({
    usersStatus: store.usersStatus.users,
    chat: store.chat,
    socket: store.functions.socket
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MachenPeerCall)
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

//FUNCTIONS
import { customApp } from "functions/";

const styles = {
    root: {
        position: 'relative',
        width: `100%`,
        backgroundColor: `${customApp('color', '0.1')} !important`,
        '& .MuiLinearProgress-indeterminate': {
            backgroundColor: `#FFFFFF !important`
        },
        '& .MuiLinearProgress-bar': {
            backgroundColor: `${customApp('color')} !important`
        },
        '& .text': {
            position: 'absolute',
            width: '40px',
            right: '-40px',
            top: '-9px',
            textAlign: 'right',
            textSize: '11px',
            fontWeight: 'bold',
            color: customApp('color')
        }
    },
    bar: {
        borderRadius: 7,
    },

}

function Component(props) {
    const { classes } = props
    return (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    )
}

export default withStyles(styles)(Component)
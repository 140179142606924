//CORE
import React from "react"
import Cards from "react-credit-cards"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import Icon from "components/Icon"
import Switch from "components/Switch"
import TextField from "components/TextField"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users.js"

//FUNCTIONS
import {
    customApp,
    translate,
} from "functions/"


import 'react-credit-cards/es/styles-compiled.css';

function Component(props) {
    const [data, setData] = React.useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    })

    return (
        <div>
            <Cards
                cvc={data.cvc}
                expiry={data.expiry}
                focused={data.focus}
                name={data.name}
                number={data.number}
            />
            <fieldset style={{
                wordBreak: "break-word"
            }}>
                <legend>
                    {translate("$__paymentPreffer")}
                </legend>
                <div
                    style={{
                        borderRadius: 15,
                        background: customApp("ColumnTitleColor"),
                        color: "#FFFFFF",
                        boxShadow: "0 0 3px 7px rgba(0,0,0,0.03)",
                        padding: 15
                    }}
                >
                </div>
                <TextField
                    id={`sideModule_user_displayName`}
                    label={translate('$__displayName', 1)}
                    variant={customApp('fieldVariant')}
                    name="number"
                    InputProps={{
                        autoComplete: 'new-password',
                        multiline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon='view_comfy_ind' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            setData({
                                ...data,
                                number: d.target.value
                            })
                        },
                    }}
                    value={data && data.number ? data.number : ''}
                    placeholder={translate('$__cardNumber', 1)}
                // disabled={permission ? false : true}
                />
            </fieldset>
            <fieldset style={{
                wordBreak: "break-word"
            }}>
                <legend>
                    {translate("$__forceChangePassword")}
                </legend>
                <Switch
                    checked={data && data._access && data._access.user ? true : false}
                    onChange={(d) => {
                        // changeData({
                        //     name: 'user',
                        //     value: data && data._access && data._access.user ? false : true
                        // })
                    }}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                {translate("$__forceChangePasswordDescription")}
            </fieldset>
        </div >
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
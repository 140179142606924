import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip"

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(0,0,0,0.5) !important',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: "5px !important",
        fontSize: theme.typography.pxToRem(12),
        border: 'none',
        borderRadius: '7px',
        boxShadow: '3px 3px 7px 0px rgba(0,0,0,0.7)',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))(Tooltip);

export default HtmlTooltip
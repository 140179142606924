import React, { memo } from "react";
import { Draggable } from "react-beautiful-dnd";

//REDUX
import reduxStore from "store";

//COMPONENTS
import Card from "components/Card";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Agile-Card";
import { customApp } from "functions";

function Component(props) {
    const { data } = props
    const { db } = reduxStore.getState()
    let indicators = []

    let cards = db.cards

    const getStyle = (style, snapshot) => {
        if (!snapshot.isDropAnimating) {
            return {
                ...style,
            };
        }
        const { moveTo } = snapshot.dropAnimation;
        // move to the right spot
        const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
        const rotate = ``;
        return {
            ...style,
            clear: 'both',
            transition: `all 0.1s ease`,
            transform: `${translate} ${rotate}`,
            border: `solid 3px ${customApp('color')}`,
            backgroundColor: customApp('color'),
            padding: '3px'
        };


    }

    Object.keys(cards).map(a => {
        if (cards[a]._parent === data._id) {
            indicators[cards[a].type] = indicators[cards[a].type] == null ? 1 : indicators[cards[a].type] + 1
        }
        return false
    })
    return (
        <Draggable
            draggableId={data.id ? data.id : data._id ? data._id : null}
            index={Math.floor(Math.random() * 10)}
            key={data.id ? data.id : data._id ? data._id : null}
            isDragDisable={data.isDragDisable || props.isDragDisable || false}
        >
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getStyle(provided.draggableProps.style, snapshot)}>
                    <Card
                        cardId={props.data._id}
                        db={props.db}
                        showOrder={props.showOrder || false}
                        showSelected={props.showSelected || false}
                        locked={props.locked || false}
                        viewButton={props.viewButton || false}
                        showConnectedIn={props.showConnectedIn ? true : false}
                        isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                        snapshot={snapshot}
                        cardColorPicker={props.cardColorPicker ? true : false}
                    />
                </div>
            )}
        </Draggable>
    )
}

export default withStyles(styles)(memo(Component))
//CORE
import React from 'react'

//COMPONENTES
import PreLoader from "components/PreLoader";

function Component() {
    return (
        <div style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
        }}>
            <div
                style={{
                    width: "100%",
                    maxWidth: "250px",
                    height: "150px",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                <PreLoader />
            </div>
        </div>
    )
}


export default Component

//REDUX
import reduxStore from "store/";
require('dotenv').config()

export const countUnReadsMessages = (id, type = "comment") => {
    const { session, timeline } = reduxStore.getState()
    return timeline && timeline[id] ? Object.keys(timeline[id])
        .filter(m => timeline[id]
            && timeline[id][m]
            && timeline[id][m].type
            && timeline[id][m].type === type
            && !timeline[id][m].deleted
            && timeline[id][m].user
            && timeline[id][m].user._id
            && timeline[id][m].user._id !== session._id

            && (
                !timeline[id][m].readedBy
                || (
                    timeline[id][m].readedBy
                    && !timeline[id][m].readedBy[session._id]
                )
            )).length : 0
}
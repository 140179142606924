//CORE
import React from 'react'
import Moment from "moment-timezone"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import CircularProgress from "components/Progress/circular"
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import Switch from "components/Switch"
import TextField from "components/TextField"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Feed.js"

//FUNCTIONS
import api from "api/"
import { translate, customApp, appAlert } from "functions/"
import {
    getUserAccess,
} from "functions/cards"
import { saveFeed } from "functions/feeds"

const useStyles = makeStyles(styles)

function Component(props) {
    const classes = useStyles()
    const { sideModule } = reduxStore.getState()
    const data = sideModule.data || {}
    const [reqSave, setReqSave] = React.useState(false)
    const [feedLoading, setFeedLoading] = React.useState(false)

    const url = React.useRef()

    let CardAccess = 0

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    if (hashs[0] && hashs[0].length === 36)
        CardAccess = getUserAccess(hashs[0])


    const changeData = (d) => {
        if (data.name === '' || (d.name === 'name' && d.value === '')) {
            setReqSave(false)
        } else if (data._lock && ((d.value && !sideModule.data[d.name]) || (sideModule.data[d.name] && sideModule.data[d.name] !== d.value))) {
            setReqSave(true)
        }

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                [d.name]: d.value,
            }
        })
    }

    const close = (force = false) => {
        if (reqSave && !force) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }

    const save = async (cl) => {
        setReqSave(false)
        if (!sideModule.data.name) {
            appAlert({
                message: translate('$__fillName', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        } else {
            const reqSave = await saveFeed(props, true)
            if (reqSave) {
                appAlert({
                    message: translate('$__feedSigned', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                if (cl)
                    close(true)
            } else {
                appAlert({
                    message: translate('$__AnErrorHasOccurredPleaseTryAgain', 1),
                    variant: 'error',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                setReqSave(true)
            }
        }
    }

    const urlValidate = async (lk) => {
        let str = lk.trim()
        if (str)
            if (str.indexOf('http') === -1) {
                appAlert({
                    message: `${translate('$__invalidUrl', 1)}: ${translate('$__urlMustContainHttps', 1)}`,
                    variant: 'error',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                props.reduxFunction("ASYNC", "SET_MODULE", {
                    ...sideModule,
                    data: {
                        ...sideModule.data,
                        url: '',
                    }
                })
            } else {
                var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

                if (!!pattern.test(str)) {
                    setFeedLoading(true)
                    try {
                        let hashs = window.location.hash.split('/')
                        hashs[0] = hashs[0].replace('#', '')
                        const result = await api.post('/link/exist', {
                            idRel: hashs[0],
                            url: str
                        })
                        props.reduxFunction("ASYNC", "SET_MODULE", {
                            ...reduxStore.getState().sideModule,
                            id: 'new',
                            activeModule: 'cardEdit',
                            db: 'cards',
                            data: {
                                _id: 'new',
                                type: 'link',
                                name: result.data.title,
                                description: result.data.contentSnippet,
                                image: result.data.img,
                                url: result.data.link,
                                content: result.data.article,

                            }
                        })
                        if (result)
                            setFeedLoading(false)
                        if (result.data.title)
                            setReqSave(true)
                    } catch (e) {
                        appAlert({
                            message: `${translate('$__invalidFeedUrl', 1)}: ${translate('$__pleaseVerifyTheFeedAddress', 1)}`,
                            variant: 'error',
                            persist: false,
                            horizontal: 'right',
                            confirm: null
                        })
                    }
                } else {
                    appAlert({
                        message: `${translate('$__invalidUrl', 1)}: ${translate('$__pleaseVerifyTheUrl', 1)}`,
                        variant: 'error',
                        persist: false,
                        horizontal: 'right',
                        confirm: null
                    })
                    props.reduxFunction("ASYNC", "SET_MODULE", {
                        ...sideModule,
                        data: {
                            ...sideModule.data,
                            url: '',
                        }
                    })
                }
                return true
            }
        return false
    }

    let permission = props.permission ? props.permission : false
    if (!permission && parseInt(CardAccess) >= 3)
        permission = true

    return (
        <div>
            <div className={classes.btClose}>
                <div
                    style={{
                        position: 'fixed',
                        display: 'inline-flex',
                        paddingLeft: '10px',
                        top: '7px',
                        right: 0,
                        backgroundColor: "#FFFFFF"
                    }}>
                    {(permission && reqSave) &&
                        <React.Fragment>
                            <CustomButton
                                title={translate(`$__save`, '*')}
                                color={customApp('color')}
                                icon={'save'}
                                onClick={() => { save() }}
                                size={'25px'}
                            />
                            <CustomButton
                                title={translate(`$__saveAndClose`, 1)}
                                color='green'
                                icon={'playlist_add_check'}
                                onClick={() => { save(true) }}
                                size={'25px'}
                            />
                        </React.Fragment>
                    }
                    <CustomButton
                        title={translate(`$__close`, 1)}
                        color='lightgrey'
                        icon={'cancel'}
                        onClick={() => {
                            if (props.onClose)
                                props.onClose()
                        }}
                        size={'25px'}
                    />
                </div>
            </div>
            <div className={classes.data}>
                {data._lock &&
                    <React.Fragment>
                        <TextField
                            label={translate('$__titleOfUrl', 1)}
                            variant={customApp('fieldVariant')}
                            name={'name'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='view_headline' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.name ? data.name : ''}
                            placeholder={translate('$__titleOfUrl_placeholder')}
                            disabled
                        />
                        <TextField
                            label={translate('$__customTitle', 1)}
                            variant={customApp('fieldVariant')}
                            name={'customTitle'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='title' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.customTitle ? data.customTitle : ''}
                            placeholder={translate('$__customTitle', 1)}
                        />
                        <TextField
                            label={translate('$__nameOfFontData', 1)}
                            variant={customApp('fieldVariant')}
                            name={'font'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='copyright' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.font ? data.font : ''}
                            placeholder={translate('$__nameOfFontData', 1)}
                        />
                        <TextField
                            label={translate('$__descriptionOfUrl', 1)}
                            variant={customApp('fieldVariant')}
                            name={'description'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='subtitles' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.description ? data.description : ''}
                            placeholder={translate('$__descriptionOfUrl', 1)}
                        />
                        <TextField
                            label={translate('$__customDescription', 1)}
                            variant={customApp('fieldVariant')}
                            name={'customDescription'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='subtitles' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.customDescription ? data.customDescription : ''}
                            placeholder={translate('$__customDescription', 1)}
                        />
                        <div className={classes.switch}>
                            <Switch
                                checked={data.private ? true : false}
                                onChange={(d) => {
                                    changeData({
                                        name: 'private',
                                        value: data.private ? false : true
                                    })
                                }}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            {translate('$__private', 1)}
                        </div>
                    </React.Fragment>
                }
                <TextField
                    label={translate('$__url', '*')}
                    variant={customApp('fieldVariant')}
                    ref={url}
                    name="url"
                    InputProps={{
                        autoComplete: 'new-password',
                        multiline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon='http' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            changeData(d.target)
                        },
                        onBlur: (d) => {
                            urlValidate(d.target.value)
                        }
                    }}
                    value={data && data.url ? data.url : ''}
                    placeholder={'http://www.domain.com/'}
                    disabled={data._lock ? true : false}
                />
                {!data._lock && !feedLoading &&
                    <IconButton text={translate("$__validUrl", 1)} icon="check" onClick={() => urlValidate(url.current.value)} />
                }
                {feedLoading &&
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                }
                {data.feed && data.feed.length > 0 &&
                    <ul className={classes.preview}>
                        <div className={classes.previewTitle}>{translate('$__preview', 1)}</div>
                        {data.feed.map((f, i) => (
                            <li
                                key={i}
                            >
                                {f && f.title &&
                                    <div
                                        dangerouslySetInnerHTML={{ __html: f.title }}
                                        className={classes.feedTitle}
                                    />
                                }
                                <div
                                    dangerouslySetInnerHTML={{ __html: f.contentSnippet }}
                                    className={classes.feedContent}
                                />
                                <div className={classes.feedFooter}>
                                    <div className={classes.feedDate}>{Moment(f.pubDate).format('DD/MM/YYYY HH:MM')}</div>
                                    <div className={classes.feedLink}>
                                        <a href={f.link} target="_blank" rel="noopener noreferrer">{translate('$__openLink', '*')}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
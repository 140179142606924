//REACT
import React, { useState } from 'react'
import PropTypes from "prop-types";

//COMPONENTES
import Icon from 'components/Icon';

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//@MATERIAL
import { Tooltip } from '@mui/material';

//FUNCTIONS
import { customApp, stringLength } from 'functions';

const User = (props) => {
  const { db, session } = reduxStore.getState();
  const { cards } = db;

  return (
    <>
      <div style={{
        width: "100%",
        display: "flex",
        gap: "20px",
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}>
        <input type="file" id='fileBack' style={{ display: 'none' }} />
        <img
          src={session?.custom.loginBackground}
          alt="background"
          style={{
            height: '150px',
            width: '100%',
            objectFit: 'cover',
          }}
        />
        <div
          style={{
            height: "auto",
            backgroundColor: "none",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: '0px',
            marginTop: '-110px',
            padding: '0 17px',
          }}
        >
          <img
            src={session?.image}
            style={{
              borderRadius: "50%",
              border: `2px solid ${customApp('color')}`,
              width: "110px",
              height: "110px",
              margin: "5px",
              zIndex: '998',
              cursor: 'auto',
            }}
            alt='Profile'
          />
          <div style={{
            backgroundColor: 'rgb(238,238,238)',
            padding: '5px 20px 5px 50px',
            marginLeft: '-40px',
            zIndex: '0',
            borderRadius: '0px 10px 10px 0px',
            boxShadow: 'rgba(0, 0, 0, 0.14) 0px 1px 4px 0px',
            border: `2px solid ${customApp('color')}`,
          }}>
            <p style={{
              color: customApp('medium'),
              fontSize: '1.7rem',
              fontWeight: 'bold',
              margin: '5px 0px',
              display: 'flex',
              textAlign: 'left',
              justifyContent: 'flex-start',
            }}>{session?.name}
              <sup style={{ fontSize: '1rem !important' }}>
                <Icon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    props.reduxFunction("ASYNC", "SET_MODULE", {
                      ...db.sideModule,
                      id: session?._id,
                      activeModule: 'userEdit',
                      db: 'users',
                      data: {
                        type: 'user',
                        ...reduxStore.getState().db.users[session._id],
                      }
                    })
                  }}
                >edit</Icon>
              </sup>
            </p>
            <p style={{
              fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
              color: customApp('colorText'),
              margin: '0px',
              textAlign: 'left',
            }}>{session?.email}</p>
            <p style={{ margin: '0px', color: customApp('mediumText'), textAlign: 'left', fontSize: '11px' }}>{session?.GlobalData?.name} ●
              <Tooltip title={session?.groups
                .filter(a => a.value)
                .sort((a, b) => {
                  if (a.label < b.label)
                    return -1
                  return 1
                }).map((item, i) => `${i > 0 ? ` / ` : ``}${item.label}`)}>
                <>
                  {session?.groups
                    ?.filter(a => a.value)
                    ?.sort((a, b) => {
                      if (a.label < b.label)
                        return -1
                      return 1
                    }).map((item, i) => `${i > 0 ? ` / ` : ``}${item.label}`)
                    .map((group) => stringLength(group, 32))}
                </>
              </Tooltip></p>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(User);

import React, { useState } from "react";

//COMPONENTES
import ListUsers from "./TableUsers/ListUsers";
import User from "./TableUsers/User";

//FUNCTIONS
import { translate } from "functions";

const Department = () => {
  const [selectUser, setSelectUser] = useState(null);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
        }}
      >
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <p
            style={{
              marginTop: "0px",
              color: "#7d7d7d",
              cursor: "pointer",
            }}
            onClick={() => setSelectUser(null)}
          >
            {translate('$__usersList')}
          </p>
          <p
            style={{
              marginTop: "0px",
              color: "#7d7d7d",
            }}
          >
            {selectUser !== null ? " - " : null}
          </p>
          <p
            style={{
              marginTop: "0px",
              color: "#7d7d7d",
              textDecoration: "underline",
            }}
          >
            {selectUser !== null ? `${selectUser.nomecompleto}` : null}
          </p>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "20px",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
        >
          {selectUser === null ? (
            <ListUsers setSelectUser={setSelectUser} />
          ) : (
            <div
              style={{
                width: "82%",
                position: "absolute",
                zIndex: "9",
                height: "auto",
                margin: "0px",
                fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
                display: "flex",
                minHeight: "100vh",
                backgroundColor: "#eee",
                gap: "20px",
              }}
            >
              <User selectUser={selectUser} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Department;

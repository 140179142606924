import React, { useRef } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import Header from 'components/Dashboards/Header'

//REDUX



const TaskByPersonPreview = (props) => {

    const sliceConfig = useRef(Math.floor(Math.random() * 7) + 3)

    const data = [
        {
            "country": "AD",
            "hot dog": 129,
            "hot dogColor": "hsl(182, 70%, 50%)",
            "burger": 161,
            "burgerColor": "hsl(324, 70%, 50%)",
            "sandwich": 75,
            "sandwichColor": "hsl(266, 70%, 50%)",
            "kebab": 66,
            "kebabColor": "hsl(148, 70%, 50%)",
            "fries": 180,
            "friesColor": "hsl(130, 70%, 50%)",
            "donut": 180,
            "donutColor": "hsl(253, 70%, 50%)"
        },
        {
            "country": "AE",
            "hot dog": 115,
            "hot dogColor": "hsl(121, 70%, 50%)",
            "burger": 87,
            "burgerColor": "hsl(30, 70%, 50%)",
            "sandwich": 22,
            "sandwichColor": "hsl(170, 70%, 50%)",
            "kebab": 164,
            "kebabColor": "hsl(24, 70%, 50%)",
            "fries": 5,
            "friesColor": "hsl(103, 70%, 50%)",
            "donut": 47,
            "donutColor": "hsl(308, 70%, 50%)"
        },
        {
            "country": "AF",
            "hot dog": 129,
            "hot dogColor": "hsl(354, 70%, 50%)",
            "burger": 15,
            "burgerColor": "hsl(353, 70%, 50%)",
            "sandwich": 28,
            "sandwichColor": "hsl(223, 70%, 50%)",
            "kebab": 178,
            "kebabColor": "hsl(134, 70%, 50%)",
            "fries": 26,
            "friesColor": "hsl(30, 70%, 50%)",
            "donut": 182,
            "donutColor": "hsl(206, 70%, 50%)"
        },
        {
            "country": "AG",
            "hot dog": 192,
            "hot dogColor": "hsl(276, 70%, 50%)",
            "burger": 179,
            "burgerColor": "hsl(165, 70%, 50%)",
            "sandwich": 184,
            "sandwichColor": "hsl(21, 70%, 50%)",
            "kebab": 107,
            "kebabColor": "hsl(292, 70%, 50%)",
            "fries": 45,
            "friesColor": "hsl(63, 70%, 50%)",
            "donut": 193,
            "donutColor": "hsl(97, 70%, 50%)"
        },
        {
            "country": "AI",
            "hot dog": 117,
            "hot dogColor": "hsl(84, 70%, 50%)",
            "burger": 151,
            "burgerColor": "hsl(183, 70%, 50%)",
            "sandwich": 190,
            "sandwichColor": "hsl(11, 70%, 50%)",
            "kebab": 46,
            "kebabColor": "hsl(85, 70%, 50%)",
            "fries": 133,
            "friesColor": "hsl(65, 70%, 50%)",
            "donut": 66,
            "donutColor": "hsl(190, 70%, 50%)"
        },
        {
            "country": "AL",
            "hot dog": 0,
            "hot dogColor": "hsl(89, 70%, 50%)",
            "burger": 53,
            "burgerColor": "hsl(348, 70%, 50%)",
            "sandwich": 3,
            "sandwichColor": "hsl(87, 70%, 50%)",
            "kebab": 45,
            "kebabColor": "hsl(193, 70%, 50%)",
            "fries": 169,
            "friesColor": "hsl(108, 70%, 50%)",
            "donut": 25,
            "donutColor": "hsl(153, 70%, 50%)"
        },
        {
            "country": "AM",
            "hot dog": 98,
            "hot dogColor": "hsl(233, 70%, 50%)",
            "burger": 193,
            "burgerColor": "hsl(144, 70%, 50%)",
            "sandwich": 178,
            "sandwichColor": "hsl(298, 70%, 50%)",
            "kebab": 183,
            "kebabColor": "hsl(261, 70%, 50%)",
            "fries": 128,
            "friesColor": "hsl(309, 70%, 50%)",
            "donut": 72,
            "donutColor": "hsl(326, 70%, 50%)"
        }
    ]

    console.log(sliceConfig)
    return (
        <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
            <Header styles={{}} tittle={props.title} icon={props.icon} datafilter={true} />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{ position: 'relative', flex: '1 1 auto' }}>
                <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter' }}>
                    <ResponsiveBar
                        data={data.slice(0, sliceConfig.current)}
                        keys={[
                            'donut',
                            'fries',
                            'kebab',
                        ]} // data
                        indexBy="country" //data
                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        padding={0.3}
                        valueScale={{ type: 'linear' }}
                        indexScale={{ type: 'band', round: true }}
                        enableLabel={false}
                        colors={["#011E41", "#EBA900", "#5A7F71"]}
                        yScale={{
                            max: 100,
                        }}
                        theme={{
                            fontSize: '14px',
                            color: 'white',
                            background: '#f5f5f5',
                            axis: {
                                "ticks": {
                                    "text": {
                                        "fontSize": 14,
                                        //cor dos numeros
                                    },

                                }
                            },
                        }}
                        fill={false}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    1.6
                                ]
                            ]
                        }}
                        isInteractive={false}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        role="application"
                    />
                </div>
            </div>
        </div >
    )
}

export default TaskByPersonPreview

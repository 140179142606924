//CORE
import React from "react";
import Cropper from "react-cropper";
import Resizer from "react-image-file-resizer";

//REDUX
import * as reduxActions from "store/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import Icon from "components/Icon";
import IconButton from "components/CustomButtons/IconButton";

//@MATERIAL
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import "cropperjs/dist/cropper.css";
import styles from "./Cropper.css";

//FUNCTIONS
import { translate, customApp } from "functions/";

const cropper = React.createRef(null);

function Component(props) {
    const [CroppedImage, SetCroppedImage] = React.useState(null)
    const [ImageCropDialog, SetImageCropDialog] = React.useState(false)
    const [ImagetoCrop, SetImagetoCrop] = React.useState(null)
    const [files, setFiles] = React.useState([])
    const fileInput = React.useRef(null)
    const { classes } = props

    React.useEffect(() => {
        ini()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.files, props.img])

    const ini = () => {
        let ff = files
        if (props.files) {
            Object.keys(props.files).forEach(f => {
                if (ff.filter(fil => fil._id === f).length === 0)
                    ff.push(props.files[f])
            })
            setFiles(ff)
        }
    }

    const handleImageChange = (e) => {
        SetImageCropDialog(true)
        let reader = new FileReader();
        if (e.files[0] != null) {
            let file = e.files[0];
            reader.onloadend = () => {
                SetImagetoCrop(reader.result)
                SetImageCropDialog(true)
            };
            reader.readAsDataURL(file);
        } else {
            SetImageCropDialog(false)
        }
    }

    const _crop = async (e) => {
        var img = cropper.current.getCroppedCanvas().toDataURL()
        SetCroppedImage(img)
        SetImagetoCrop(null)
        SetImageCropDialog(false)
        var nameImg = fileInput.current.files[0].name;
        var typeImg = fileInput.current.files[0].type;
        var imgResult = await urltoFile(img, nameImg, typeImg);

        try {
            const imgResizer = new Promise(resolve => Resizer.imageFileResizer(
                imgResult, // Is the file of the image which will resized.
                props.maxWidthOrHeight ? props.maxWidthOrHeight : 600, //maxWidth, // Is the maxWidth of the resized new image.
                props.maxWidthOrHeight ? props.maxWidthOrHeight : 600, //maxHeight, // Is the maxHeight of the resized new image.
                imgResult.type.replace('image/', ''), // compressFormat, // Is the compressFormat of the resized new image.
                50,//quality, // Is the quality of the resized new image.
                0,
                (e) => {
                    resolve(urltoFile(e, nameImg, typeImg))
                },
            )
            )
            let response = await imgResizer.then(a => {
                return a
            })
            if (response)
                if (props.onChange)
                    props.onChange(img, response)
        } catch (e) {
            console.log(e)
        }
    }

    const urltoFile = (url, filename, mimeType) => {
        return fetch(url)
            .then(function (res) {
                return res.arrayBuffer();
            })
            .then(function (buf) {
                return new File([buf], filename, { type: mimeType });
            });
    }

    const openLightBox = () => {
        if (files && files.length > 0) {
            let openFile = files.filter(f => props.img && props.img.indexOf(f.file) > -1)

            if (openFile.length > 0)
                props.reduxFunction("ASYNC", "LIGHTBOX", {
                    open: true,
                    files: openFile,
                    file: openFile[0]._id
                })
        }
    }

    return (
        <div className={classes.root}>
            <div
                className={classes.viewer}
                style={{}}
            >
                {!CroppedImage && !props.img && props.avatar && props.avatarIcon &&
                    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: '15px' }}>
                        <div
                            style={{
                                width: "133px",
                                height: "133px",
                                top: "-4px",
                                left: "-13px",
                                borderRadius: '50%',
                                border: `solid 2px ${customApp('color')}`,
                                zIndex: '5 !important',
                                background: props.transparent ? "none" : customApp('AvatarBackground'),
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Icon size="40px" color="rgba(250,250,250,0.77)">{props.avatarIcon}</Icon>
                        </div>
                    </div>
                }
                {!props.avatar && (CroppedImage || props.img) ?
                    <img alt="MACHEN_IMAGE" className={props.avatar ? classes.avatarImage : classes.image} src={`${CroppedImage || props.img}`}
                        style={{ cursor: "pointer" }}
                        onError={(e) => e.target.src = CroppedImage || props.img} width="100%"
                        onClick={() => {
                            openLightBox()
                        }}
                    />
                    : props.avatar && (CroppedImage || props.img) ?
                        <div
                            style={{
                                width: "133px",
                                height: "133px",
                                top: "-4px",
                                left: "-13px",
                                borderRadius: '50%',
                                border: `solid 2px ${customApp('color')}`,
                                zIndex: '5 !important',
                                background: props.transparent ? "none" : customApp('AvatarBackground'),
                                backgroundImage: `url("${CroppedImage || props.img}")`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                backgroundSize: "cover",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                        </div>
                        : <React.Fragment></React.Fragment>
                }
            </div>
            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                {props.onChange ?
                    <React.Fragment>
                        <div style={{ padding: "7px" }} onClick={() => { fileInput.current.click(); }}>
                            <IconButton text={translate(CroppedImage || props.img ? "$__changeImage" : "$__addImage", 1)} icon={CroppedImage || props.img ? "image" : "add_a_photo"} textColor="color" iconColor="color" />
                        </div>

                        <input
                            type="file"
                            onChange={(e) => { handleImageChange(e.target) }}
                            ref={fileInput}
                            id="file-picker"
                            name="image"
                            accept={props.accept || ".jpg,.jpeg,.png,.gif"}
                            style={{ display: "none" }}
                        />
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
                {
                    props.onDelete && (CroppedImage || props.img) ?
                        <div style={{ padding: "7px" }} onClick={() => { props.onDelete() }}>
                            <IconButton text={translate("$__removeImage", 1)} icon={"hide_image"} textColor="color" iconColor="color" />
                        </div>
                        : <React.Fragment></React.Fragment>
                }
            </div>
            <Dialog fullWidth={true} onClose={() => SetImageCropDialog(false)} aria-labelledby="simple-dialog-title" open={ImageCropDialog}>
                <div style={{
                    position: 'relative',
                    marginLeft: '10px',
                    width: 'calc(100% - 10px)',
                    height: '45px',
                    display: "flex"
                }}>
                    <HeaderWithIcon
                        title={translate("$__cropImage", 1)}
                        icon="crop"
                        onSave={() => { _crop() }}
                        onCancel={() => { SetImageCropDialog(false) }}
                        actionsButtons={true}
                        actionButtonsStyle={{ top: 0, height: 45, display: "flex", alignItems: "center" }}
                    />
                </div>
                <Cropper
                    className={props.avatar ? classes.circleCropper : classes.cropper}
                    ref={cropper}
                    src={ImagetoCrop || null}
                    style={{ height: '50vh', width: '100%', margin: 'auto', padding: '0px' }}
                    aspectRatio={props.ignoreAspect ? null : props.aspectRatio ? props.aspectRatio : props.avatar ? 1 : 16 / 9}
                    guides={!props.noGuides ? true : false}
                    viewMode={String(props.viewMode) ? props.viewMode : 2}
                    checkOrientation={props.checkOrientation || false}
                />
            </Dialog>
        </div>
    )
}
const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
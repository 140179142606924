import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//COMPONENTS
import Card from "components/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SkeletonCard from "components/BrightAndConnected/skeletonCard";
import Icon from "components/Icon";

//FUNCTIONS
import {
    translate,
} from "functions/"

import {
    loadCardsV2
} from "functions/loadData"

import { cardINNExists } from "functions/users"

function Radar(props) {
    const { db, search } = reduxStore.getState()
    const [page, setPage] = React.useState(0)
    const limit = 18
    const [loading, setLoading] = React.useState(false)
    const [end, setEnd] = React.useState(false)

    const mounted = React.useRef(true)

    React.useEffect(() => {
        loadLinks(page, limit)
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        // console.log(38)
        if (!loading && !end && mounted.current)
            loadLinks(page, limit)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit])

    const loadLinks = async (page, limit) => {
        if (mounted.current) {
            setLoading(true)
            let res = await loadCardsV2(props, {
                cardLoad: 'links',
                limit: limit,
                skip: page * limit,
                ignoreLoader: true
            })
            if (res && mounted.current) {
                setLoading(false)
                if (res.total < limit)
                    setEnd(true)
            }
        }
    }

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let radarCards = {}

    radarCards = Object.keys(db.cards)
        .filter(a =>
            db.cards[a].type === 'link'
            && db.cards[a]._users
            && cardINNExists(a)
            && !db.cards[a].deleted
        )
        .sort((a, b) => {
            if (db.cards[a].created_at > db.cards[b].created_at)
                return -1
            return 0
        })
        .map(a => db.cards[a])

    radarCards = radarCards.filter(a =>
    ((a.description && a.description.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
        || (a.name && a.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
    )).map(a => a)

    if (search.tags.length > 0)
        radarCards = radarCards.filter(fil =>
            fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                let result = false
                search.tags.forEach(tagFilter => {
                    if (tagFilter.value === tag.value)
                        result = true
                })
                return result
            }
            ).length > 0
        ).map(res => res)

    const scrollFunction = (e) => {
        var scrollDiv = document.getElementById(`linkList`);
        if (scrollDiv.scrollTop + window.innerHeight + 150 > scrollDiv.scrollHeight && mounted.current)
            setPage(page + 1)
    }

    const RenderSkeleton = () => {
        let rows = []
        for (var i = 0; i < limit; i++) {
            rows.push(<GridItem
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={`sk_${i}`}
            >
                <SkeletonCard />
            </GridItem>)
        }
        return <React.Fragment>{rows}</React.Fragment>
    }

    if (Object.keys(radarCards)
        .filter((i, ii) => (!props.max || (props.max && ii < props.max))).length === 0)
        return (
            <div
                id={"insightList"}
                style={!props.horizontal ? {
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    overflowY: "auto",

                } : { position: 'relative', justifyContent: 'space-between', margin: 'auto', width: '100%' }}
                onScroll={!props.horizontal ? (e) => {
                    scrollFunction(e)
                } : null}
            >
                <GridContainer
                    style={{
                        ...props.horizontal ? {
                            overflowX: "auto",
                            flexFlow: "row",
                            display: "-webkit-inline-box"
                        } : {}
                    }}
                >
                    <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                    >
                        <div
                            style={{
                                justifyContent: "center",
                                textAlign: "center",
                                display: "block", paddingBottom: 15
                            }}
                        >
                            <div style={{ margin: "auto", marginBottom: 15, display: "flex", justifyContent: "center" }}><Icon size={"33px"} icon={"info"} color={"lightblue"} /></div>
                            <div style={{ color: "lightgray", fontSize: 33, texAlign: "center", marginBottom: 15 }}>{translate("$__youHaveNoRadarAddedYet")}</div>
                            <div style={{ color: "lightgray", fontSize: 18, texAlign: "center", marginBottom: 15 }}>{translate("$__visitLinkToHowGetStarted")}</div>
                            <a href="https://support.machen.ai/manual/bright-connected/radar/" target="_blank" rel="noopener noreferrer"
                                style={{ fontSize: 18, color: "blue", texAlign: "center", }}>{translate("$__getStarted")}</a>

                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        )

    return (
        <div
            id={"linkList"}
            style={!props.horizontal ? {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                overflowY: "auto",

            } : {
                position: 'relative',
                justifyContent: 'space-between',
                margin: 'auto',
                width: '100%'
            }}
            onScroll={!props.horizontal ? (e) => {
                scrollFunction(e)
            } : null}
        >
            <GridContainer
                style={{
                    ...props.horizontal ? {
                        overflowX: "auto",
                        flexFlow: "row",
                        display: "-webkit-inline-box"
                    } : {}
                }}
            >
                <div>

                </div>

                {Object.keys(radarCards)
                    .filter((r, ri) => (!props.max || (props.max && ri < props.max)))
                    .map((card, i) => {
                        return (
                            <GridItem
                                key={radarCards[card]._id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                <Card db="cards" data={radarCards[card]} expanded Avatar Resume showConnectedIn LoadParent />
                            </GridItem>
                        )
                    })}
                {loading ?
                    <RenderSkeleton />
                    : <React.Fragment></React.Fragment>}
            </GridContainer>
        </div>
    )
}

const mapStateToProps = (store, props) => {
    return {
        cards: store.db.cards,
        search: store.search,
        users: store.db.users
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(Radar);
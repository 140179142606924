// ID_CREATED*************************

//CORE
import React, { useState } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import ImgCropper from "components/Cropper";
import TextField from "components/TextField";
import MultInput from "components/MultInput/input";
import SideModuleCloseAndSave from "components/SideModule/closeAndSave";

//@MATERIAL
import Icon from "components/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card";

//FUNCTIONS
import {
    translate,
    customApp,
    validateEmail,
    appAlert,
    getAppAccess
} from "functions/";
import { save, emailExist } from "functions/users";

function Component(props) {
    const { classes } = props;
    const { sideModule, db, session } = reduxStore.getState()
    const { users, dataType } = db
    const { data } = sideModule
    const [iniData] = useState(data)
    const [emailError, setEmailError] = React.useState(!validateEmail(data && data.email ? data.email : ''))
    const AppAccess = getAppAccess()

    const reqSave = JSON.stringify(iniData) !== JSON.stringify(data)

    const changeData = (d) => {
        let value = d.value
        let newData = {
            ...data,
            [d.name]: value,
        }
        if (!value && newData[d.name])
            delete newData[d.name]

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: newData
        });

    }

    const close = (cl) => {
        if (reqSave && !cl) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE");
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE");
        }
    }
    const saveUser = async (cl) => {
        const exist = await emailExist(data.email)

        if (
            !data.name
        ) {
            appAlert({
                message: translate('$__fillName', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        } else if (
            dataType.filter(a => a.name === data.type)[0].disabledFields.indexOf('email') === -1 &&
            emailError
        ) {
            appAlert({
                message: emailError,
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        } else if (
            dataType.filter(a => a.name === data.type)[0].disabledFields.indexOf('email') === -1 &&
            (
                !validateEmail(sideModule.data.email) ||
                (
                    users[data._id] &&
                    data.email !== users[data._id].email &&
                    exist
                )
            )
        ) {
            setEmailError(translate('$__invalidEmail', 1))
        } else {
            const reqSave = await save(props);
            if (reqSave) {
                appAlert({
                    message: translate('$__userDataSaved', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                if (cl)
                    close(true)
            }
        }
    }

    let permission = false
    if (data._id === session._id ||
        (AppAccess
            &&
            (
                AppAccess.user
                && (
                    AppAccess.userAdmin
                    || AppAccess.userEdit
                )
            )
        ))
        permission = true

    // console.log(data)
    // if (data.independentAccount)
    //     permission = false



    return (
        <div>
            <SideModuleCloseAndSave
                save={permission && reqSave ? () => saveUser() : null}
                saveClose={permission && reqSave ? () => saveUser(true) : null}
                close={() => close(true)}
            />
            {(
                permission
                ||
                (!permission && sideModule.data.image)
            )}
            <div
                id={`sideModule_user_foto`}
            >
                <ImgCropper
                    maxWidthOrHeight={600}
                    img={
                        sideModule.data &&
                            sideModule.data.image ?
                            sideModule.data.image :
                            null
                    }
                    onChange={permission ? (a, file) => {
                        changeData({ name: 'file', value: file })
                    } : null}
                    avatar
                    avatarIcon={
                        (
                            !data.type ||
                            data.type === 'user'
                        ) ?
                            'person' :
                            (
                                !data.type ||
                                data.type === 'group'
                            ) ?
                                'groups' :
                                'business'
                    }
                />
            </div>
            <form
                className={classes.data}
                autoComplete="off"
                id={`sideModule_user_data`}
            >
                <TextField
                    id={`sideModule_user_fullName`}
                    label={translate('$__fullName', 1)}
                    variant={customApp('fieldVariant')}
                    name={'fullName'}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        autoComplete: 'new-password',
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon='person' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            changeData(d.target)
                        },
                        onBlur: (d) => {
                            if (data && !data.name) {
                                let name = d.target.value ? `${d.target.value.split(' ')[0]} ${d.target.value.split(' ')[1] ? d.target.value.split(' ')[1] : ''}` : ""
                                changeData({
                                    name: 'name',
                                    value: name.trim()
                                })
                            }
                        }
                    }}
                    value={data && data.fullName ? data.fullName : ''}
                    placeholder={translate('$__fullName', 1)}
                    autoFocus
                    disabled={permission ? false : true}
                />
                <TextField
                    id={`sideModule_user_displayName`}
                    label={translate('$__displayName', 1)}
                    variant={customApp('fieldVariant')}
                    name="name"
                    InputProps={{
                        autoComplete: 'new-password',
                        multiline: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <Icon icon='view_comfy_ind' />
                            </InputAdornment>
                        ),
                        onChange: (d) => {
                            changeData(d.target)
                        },
                        onBlur: (d) => {
                            changeData(d.target)
                        }
                    }}
                    value={data && data.name ? data.name : ''}
                    placeholder={translate('$__displayName', 1)}
                    disabled={permission ? false : true}
                />
                {dataType.filter(a => a.name === data.type)[0].disabledFields.indexOf('email') === -1 &&
                    <TextField
                        id={`sideModule_user_email`}
                        label={translate('$__email', 1)}
                        variant={customApp('fieldVariant')}
                        name="email"
                        type="email"
                        // helperText={emailError}
                        InputProps={{
                            autoComplete: 'new-password',
                            className: ((emailError || !data.email) || !validateEmail(data && data.email ? data.email : '')) ? "invalid" : "",
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon icon='alternate_email' />
                                </InputAdornment>
                            ),
                            onChange: (d) => {
                                setEmailError(null)
                                changeData(d.target)
                            },
                            onBlur: async (e) => {
                                if ((data._id && data._id !== 'new' && users[data._id] && users[data._id].email !== e.target.value)
                                    || (!data._id || data._id === 'new'))
                                    if (validateEmail(e.target.value) && (!users[data._id] || e.target.value !== users[data._id].email)) {
                                        const exist = await emailExist(e.target.value)
                                        if (exist) {
                                            setEmailError(translate('$__emailAlreadyExists', 1))
                                        } else {
                                            setEmailError(null)
                                        }
                                    } else {
                                        setEmailError(translate('$__invalidEmail', 1))
                                    }
                            }
                        }}
                        value={data && data.email ? data.email : ''}
                        placeholder={translate('$__email', 1)}
                        disabled={!permission}
                    />
                }
                {(
                    permission &&
                    (AppAccess
                        &&
                        (
                            AppAccess.user
                            && (
                                AppAccess.userAdmin
                                || AppAccess.userEdit
                            )
                        )
                    )
                )
                    ?
                    <React.Fragment>
                        <fieldset
                            style={{ marginBottom: '14px' }}
                            id={`sideModule_user_tags`}
                        >
                            <MultInput
                                data={{
                                    values: data.tags || [],
                                    _id: data._id,
                                }}
                                options={reduxStore.getState().db.tag ? reduxStore.getState().db.tag : []}
                                placeholder={"$__tagsPlaceholder"}
                                dbNode={"tag"}
                                callBack={(v) => {
                                    changeData({ name: 'tags', value: v })
                                }}
                                secondaryParent={data._parent}
                                colorPickerCallBack={(res) => {
                                    reduxStore.getState().functions.socket.emit('tag.UPD', res)
                                }}
                            />
                        </fieldset>
                        <fieldset
                            style={{ marginBottom: '14px' }}
                            id={`sideModule_user_group`}
                        >
                            <MultInput
                                data={{
                                    values: data.groups || [],
                                    _id: data._id,
                                }}
                                options={reduxStore.getState().db.userGroup ? reduxStore.getState().db.userGroup : []}
                                placeholder={"$__userGroupPlaceholder"}
                                dbNode={"userGroup"}
                                callBack={(v) => {
                                    changeData({ name: 'groups', value: v })
                                }}
                                secondaryParent={data._parent}
                                colorPickerCallBack={(res) => {
                                    // reduxStore.getState().functions.socket.emit('userGroup.UPD', res)
                                }}
                            />
                        </fieldset>
                        {/* {dataType.filter(a => a.name === data.type)[0].hideFields.indexOf('independentAccount') === -1 ?
                                <fieldset style={{ marginBottom: "14px" }}>
                                    {reduxStore.getState().db.users[data._id].independentAccount ?
                                        <div
                                            style={{ color: "gray", fontSize: 11 }}
                                        >
                                            {translate("$__thisIsAIndependentAccountExplain", 1)}
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={
                                                        data.independentAccount
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(d) => {
                                                        if (permission)
                                                            changeData({
                                                                name: "independentAccount",
                                                                value: data.independentAccount ? false : true
                                                            })
                                                    }}
                                                    name="independentAccount"
                                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                                />
                                                {translate("$__turnIntoIndependentAccount", 1)}
                                            </div>
                                            <div
                                                style={{ color: "gray", fontSize: 11 }}
                                            >
                                                {translate("$__turnIntoIndependentAccountExplain", 1)}
                                            </div>
                                        </React.Fragment>
                                    }
                                </fieldset>
                                : <React.Fragment></React.Fragment>} */}
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
            </form>
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    tag: store.db.tag,
    userGroup: store.db.userGroup,
    sideModule: store.sideModule
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import { customApp } from "functions/";

export default {
    control: {
        backgroundColor: '#fff',
        fontSize: 14,
        fontFamily: "Roboto",
        fontWeight: 'normal',
        lineHeight: '17px',
    },

    '&multiLine': {
        control: {
            fontFamily: 'Roboto',
            // minHeight: 63,
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
            boxSizing: 'border-box',
            overflow: 'hidden',
            maxHeight: 150,
        },
        input: {
            padding: 9,
            border: '1px solid silver',
            overflow: 'auto',
            maxHeight: 150,
            borderColor: 'transparent',
        },
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,
        lineHeight: '17px',
        highlighter: {
            lineHeight: '17px',
            padding: 1,
            border: '2px inset transparent',
        },
        input: {
            lineHeight: '17px',
            padding: 1,
            border: '2px inset',
        },
    },

    suggestions: {
        position: 'absolute',
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            borderRadius: '7px 7px',
            boxShadow: '0 0 3px 5px rgba(0,0,0,0.05)',
            fontSize: 14,
            zIndex: 200,
            maxHeight: '151px !important',
            overflow: 'auto !important'
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: customApp('color'),
            },
        },
        zIndex: 500,
        bottom: '100%',
        top: 'none',
        left: '33px',
        height: 'auto',
        maxHeight: '151px !important',
        overflow: 'auto !important'
    },
}
// ID_CREATED*************************

import React from "react"
// import { Carousel } from 'react-responsive-carousel';

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import HeaderWithMenu from "components/Header/card"
import CustomButton from "components/Buttons/custom"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Insights from "views/BrightAndConnected/InsightBoards"
import ListLinks from "components/BrightAndConnected/listLinks"
import Radar from "views/BrightAndConnected/Radar"
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//DEPENDENCIES
import ResumeTasks from "./resumeTasks"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

//ICONS
import Ideas from "assets/icons/Ideas"


//FUNCTIONS
import {
    translate,
    customApp,
} from "functions/"
import ErrorBoundary from "components/ErrorBoundary"

//VARIABLES
function DashboardResume(props) {
    const { session } = reduxStore.getState()
    React.useEffect(() => {
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    let countProfile = 0

    if (session && session.GlobalData) {
        if (session.GlobalData.message)
            countProfile = countProfile + 1
        if (session.GlobalData.history)
            countProfile = countProfile + 1
        if (session.GlobalData.vocation)
            countProfile = countProfile + 1
        if (session.GlobalData.legacy)
            countProfile = countProfile + 1
    }

    return (
        <div style={{ width: "100%" }}>
            {countProfile ?
                // <Carousel
                //     utoPlay={true}
                //     showArrows={true}
                //     stopOnHover={true}
                //     transitionTime={1}
                //     showStatus={false}
                // >
                <GridContainer>
                    {
                        session.GlobalData.strategicStatement ?
                            <GridItem
                                md={6}
                            >
                                <div style={{
                                    height: "calc(100% - 33px)"
                                }}
                                >
                                    <UiCard
                                        style={{
                                            height: "calc(100% - 33px)"
                                        }}
                                    >
                                        <HeaderWithMenu
                                            title={translate(`$__strategicStatement`, 1)}
                                            icon={'history_edu'}
                                            color={customApp('colorText')}
                                            style={{
                                                background: customApp('ColumnTitleColor'),
                                                padding: '7px'
                                            }}
                                        />
                                        <UiCardBody style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}>
                                            <div dangerouslySetInnerHTML={{ __html: session.GlobalData.strategicStatement }} />
                                        </UiCardBody>
                                    </UiCard>
                                </div>
                            </GridItem>
                            : <React.Fragment></React.Fragment>
                    }
                    {session.GlobalData.message ?
                        <GridItem
                            md={6}
                        >
                            <div style={{
                                height: "calc(100% - 33px)"
                            }}
                            >
                                <UiCard
                                    style={{
                                        height: "calc(100% - 33px)"
                                    }}
                                >
                                    <HeaderWithMenu
                                        title={translate(`$__presidentMessage`, 1)}
                                        icon={'account_box'}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                    />
                                    <UiCardBody style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: session.GlobalData.message }} />
                                    </UiCardBody>
                                </UiCard>
                            </div>
                        </GridItem>
                        : <React.Fragment></React.Fragment>
                    }
                    {session.GlobalData.vocation ?
                        <GridItem
                            md={6}
                        >
                            <div style={{
                                height: "calc(100% - 33px)"
                            }}
                            >
                                <UiCard
                                    style={{
                                        height: "calc(100% - 33px)"
                                    }}
                                >
                                    <HeaderWithMenu
                                        title={translate(`$__vocation`, 1)}
                                        icon={'spoke'}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                    />
                                    <UiCardBody style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: session.GlobalData.vocation }} />
                                    </UiCardBody>
                                </UiCard>
                            </div>
                        </GridItem>
                        : <React.Fragment></React.Fragment>
                    }
                    {session.GlobalData.history ?
                        <GridItem
                            md={6}
                        >
                            <div style={{
                                height: "calc(100% - 33px)"
                            }}
                            >
                                <UiCard
                                    style={{
                                        height: "calc(100% - 33px)"
                                    }}
                                >
                                    <HeaderWithMenu
                                        title={translate(`$__history`, 1)}
                                        icon={'menu_book'}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                    />
                                    <UiCardBody style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: session.GlobalData.history }} />
                                    </UiCardBody>
                                </UiCard>
                            </div>
                        </GridItem>
                        : <React.Fragment></React.Fragment>
                    }
                    {session.GlobalData.values ?
                        <GridItem
                            md={6}
                        >
                            <div style={{
                                height: "calc(100% - 33px)"
                            }}
                            >
                                <UiCard
                                    style={{
                                        height: "calc(100% - 33px)"
                                    }}
                                >
                                    <HeaderWithMenu
                                        title={translate(`$__values`, 1)}
                                        icon={'join_inner'}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                    />
                                    <UiCardBody style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}>
                                        <div dangerouslySetInnerHTML={{ __html: session.GlobalData.values }} />
                                    </UiCardBody>
                                </UiCard>
                            </div>
                        </GridItem>
                        : <React.Fragment></React.Fragment>
                    }
                    {session.GlobalData.legacy ?
                        <GridItem
                            md={6}
                        >
                            <div style={{
                                height: "calc(100% - 33px)"
                            }}
                            >
                                <UiCard
                                    style={{
                                        height: "calc(100% - 33px)"
                                    }}
                                >
                                    <HeaderWithMenu
                                        title={translate(`$__legacy`, 1)}
                                        icon={'subtitles'}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                    />
                                    <UiCardBody
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: session.GlobalData.legacy }} />
                                    </UiCardBody>
                                </UiCard>
                            </div>
                        </GridItem>
                        : <React.Fragment></React.Fragment>
                    }
                    {/* </Carousel> */}
                </GridContainer>
                :
                <React.Fragment>

                </React.Fragment>
            }
            <ErrorBoundary>
                <ResumeTasks />
            </ErrorBoundary>
            <GridContainer
                style={{
                    paddingBottom: 33
                }}
            >
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <div style={{
                        position: "relative",
                        backgroundColor: "rgba(250,250,250,0.7)",
                        boxShadow: "0px 0px 3px 7px rgba(0,0,0,0.03)",
                        marginBottom: 33,
                        // borderRadius: 7,
                        overflow: "hidden",
                    }}>
                        <div style={{
                            position: "relative",
                            height: 40,
                            marginBottom: 15
                        }}>
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 40,
                                background: customApp('ColumnTitleColor'),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: customApp('colorText'),
                                padding: "0 15px"
                            }}>
                                <div style={{ display: "flex" }}>
                                    {translate(`$__lastNews`, '*')}
                                </div>
                                <CustomButton
                                    title={translate(`$__save`, '*')}
                                    text={translate("$__viewAllNews")}
                                    color={'white'}
                                    icon={'feed'}
                                    transparent
                                    onClick={() => {
                                        history.push(`bright-and-connected#tab/allNews`)
                                    }}
                                    size={'25px'}
                                />
                            </div>
                        </div>
                        <ErrorBoundary>
                            <ListLinks max={6} horizontal />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <div style={{
                        position: "relative",
                        backgroundColor: "rgba(250,250,250,0.7)",
                        boxShadow: "0px 0px 3px 7px rgba(0,0,0,0.03)",
                        marginBottom: 33,
                        // borderRadius: 7,
                        overflow: "hidden"
                    }}>
                        <div style={{
                            position: "relative",
                            height: 40,
                            marginBottom: 15
                        }}>
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 40,
                                background: customApp('ColumnTitleColor'),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: customApp('colorText'),
                                padding: "0 15px"
                            }}>
                                <div style={{ display: "flex" }}>
                                    {translate(`$__lastAddRadar`, '*')}
                                </div>
                                <CustomButton
                                    title={translate(`$__save`, '*')}
                                    text={translate("$__viewAllRadar")}
                                    color={'white'}
                                    icon={'settings_input_antenna'}
                                    transparent
                                    onClick={() => {
                                        history.push(`bright-and-connected#tab/radar`)
                                    }}
                                    size={'25px'}
                                />
                            </div>
                        </div>
                        <ErrorBoundary>
                            <Radar max={6} horizontal noPagination />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <div style={{
                        position: "relative",
                        backgroundColor: "rgba(250,250,250,0.7)",
                        boxShadow: "0px 0px 3px 7px rgba(0,0,0,0.03)",
                        marginBottom: 33,
                        // borderRadius: 7,
                        overflow: "hidden"
                    }}>
                        <div style={{
                            position: "relative",
                            height: 40,
                            marginBottom: 15
                        }}>
                            <div style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                height: 40,
                                background: customApp('ColumnTitleColor'),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: customApp('colorText'),
                                padding: "0 15px"
                            }}>
                                <div style={{ display: "flex" }}>
                                    {translate(`$__lastAddInsights`, '*')}
                                </div>
                                <CustomButton
                                    title={translate(`$__save`, '*')}
                                    text={translate("$__viewAllInsights")}
                                    color={'white'}
                                    icon={Ideas}
                                    transparent
                                    onClick={() => {
                                        history.push(`bright-and-connected#tab/insight-boards`)
                                    }}
                                    size={'25px'}
                                />
                            </div>
                        </div>
                        <ErrorBoundary>
                            <Insights max={6} horizontal noPagination />
                        </ErrorBoundary>
                    </div>
                </GridItem>

            </GridContainer>
        </div >
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(null, mapDispatchToProps)(withStyles(styles)(DashboardResume))
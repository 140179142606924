import React, { useState } from "react";

//COMPONENTES
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Icon from "components/Icon";

//REDUX_HISTORY
import history from "store/history"

//DEPENDENCIES
import Approval from "./Approval"
import User from "./User";
import Notifications from "./Notifications";
import Overview from "./Overview";
import Historic from "./Historic";
import Department from "./Department";

//FUNCTIONS
import { translate } from "functions";

const Profile = () => {
  const [check, setCheck] = useState('Overview');

  const handleChange = (name) => {
    history.push(`/profile#${name}`)
    setCheck(name);
  };

  React.useEffect(() => {
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")
    if (hashs[0] !== check)
      setCheck(hashs[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        borderRadius: "5px",
        backgroundColor: "#eee",
        height: "auto",
        margin: "0px",
        fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif"
      }}
    >
      <GridContainer>
        <GridItem
          item
          xs={12}
          style={{
            backgroundColor: "#eee",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '30px'
          }}
        >
          <User />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            padding: '10px',
            borderBottom: '1px solid #b9b9b9',
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '0px',
            marginBottom: '20px',
            color: 'rgb(83 83 83)',
            overflowX: 'auto',
          }}>
            <LinkMenu value={'Overview'} check={check} onClick={() => handleChange('Overview')}>
              <Icon>grid_view</Icon> {translate('$__overview', 1)}
            </LinkMenu>
            {/* <LinkMenu value={'Historic'} check={check} onClick={() => handleChange('Historic')}>
              <Icon>history</Icon> {translate('$__activityHistory', 1)}
            </LinkMenu> */}
            <LinkMenu value={'ApprovalHistoric'} check={check} onClick={() => handleChange('ApprovalHistoric')}>
              <Icon>approval</Icon> {translate('$__approvalHistory', 1)}
            </LinkMenu>
            {/* <LinkMenu value={'Notifications'} check={check} onClick={() => handleChange('Notifications')}>
              <Icon>notifications_active</Icon> {translate('$__notificationManagement', 1)}
            </LinkMenu> */}
            <LinkMenu value={'Department'} check={check} onClick={() => handleChange('Department')}>
              <Icon>group</Icon> {translate('$__department/group', 1)}
            </LinkMenu>
          </div>
          {
            check === 'Overview' ? <Overview /> :
              check === 'Notifications' ? <Notifications /> :
                check === 'Historic' ? <Historic /> :
                  check === 'ApprovalHistoric' ? <Approval /> :
                    check === 'Department' ? <Department /> : null
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default Profile;

function LinkMenu({ children, value, check, onClick }) {
  const [buttonHover, setButtonHover] = useState(false)
  return (
    <div
      // href='javascript:void(0)'
      onMouseEnter={() => setButtonHover(true)}
      onMouseLeave={() => setButtonHover(false)}
      onClick={onClick}
      style={{
        textDecoration: 'none',
        color: 'rgb(83 83 83)',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '15px',
        padding: '2px 7px',
        borderRadius: '3px',
        transition: '0.3s all',
        backgroundColor: buttonHover ? '#cfcfcf' : value === check ? '#cfcfcf' : 'transparent',
        cursor: buttonHover ? 'pointer' : 'default',
      }}>{children}</div>
  )
}
import styles from "assets/jss/material-dashboard-pro-react/components/Tags";
import React from 'react'

//REDUX
import reduxStore from "store/";

//COMPONENTS
import Icon from "components/Icon";
import ColorPicker from "components/ColorPicker";

//@MATERIAL
import IconButton from '@material-ui/core/IconButton';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

//FUNCTIONS
import { customApp, translate, textColor } from "functions/";

const useStyles = makeStyles(styles);

function CardTags(props) {
    const classes = useStyles();
    const [ShowOptions, setShowTags] = React.useState(props.showOptions ? true : false)
    const [showHiddenButton, setshowHiddenButton] = React.useState(false)
    const options = reduxStore.getState().db[props.dbNode ? props.dbNode : 'tag'] || {}

    return (
        <React.Fragment>
            <div className={(ShowOptions || props.expanded) ? classes.tagsOver : classes.tags}
                onClick={
                    (e) => {
                        if (props.options && props.options.length > 0 && !ShowOptions) {
                            setShowTags(true)
                        }
                        e.stopPropagation()
                    }
                }
                onMouseOver={() => {
                    setshowHiddenButton(true)
                }}
                onMouseLeave={() => {
                    setTimeout(() => {
                        setshowHiddenButton(false)
                    }, 500)
                }}

            >
                {options && options.length > 0 && props.options &&
                    props.options
                        .filter(tg => tg.value)
                        .filter((tg, i) => reduxStore.getState().db.tag.filter(a => a.value === tg.value).length > 0)
                        .map((tg, i) => {
                            let t = tg
                            t = reduxStore.getState().db.tag.filter(a => a.value === tg.value)[0]
                            let color = options.filter(a => a.value === t.value).length > 0 && options.filter(a => a.value === t.value)[0].color ? options.filter(a => a.value === t.value)[0].color : customApp('medium')
                            return (
                                <div
                                    key={i}
                                    className={'tag'}
                                    style={{
                                        position: 'relative',
                                        float: 'left',
                                        whiteSpace: 'nowrap',
                                        backgroundColor: color,
                                        ...props.rounded ? { borderRadius: "7px !important" } : {}
                                    }}
                                    onClick={() => {
                                        if (!props.colorPicker && !props.showOptions)
                                            setShowTags(false)
                                    }}
                                >
                                    {(!props.showOptions && ShowOptions && props.colorPicker) ?
                                        <ColorPicker
                                            label={t.label}
                                            color={color}
                                            _id={t.value}
                                            onChange={(c) => {
                                                let newTag = t
                                                newTag.color = c
                                                reduxStore.getState().functions.socket.emit('tag.UPD', newTag)
                                            }
                                            }
                                        />
                                        :
                                        (ShowOptions || props.expanded) &&
                                        <div
                                            className={'tag'}
                                            style={{
                                                backgroundColor: color,
                                                color: t.color ? textColor(t.color, true) : '#FFFFFF',
                                                display: "flex", alignItems: "center",
                                                ...props.rounded ? { borderRadius: "7px !important" } : {}
                                            }}
                                        >
                                            {t.parent && reduxStore.getState().db.tag.filter(tt => tt.value === t.parent).length > 0 ?
                                                <div
                                                    style={{
                                                        fontSize: 8,
                                                        marginRight: 3
                                                    }}
                                                >{reduxStore.getState().db.tag.filter(tt => tt.value === t.parent)[0].label} / </div>
                                                : <React.Fragment></React.Fragment>}
                                            {t.label}
                                        </div>
                                    }
                                </div>
                            )
                        })}
                {!props.showOptions && ShowOptions && !props.expanded && showHiddenButton &&
                    <div style={{ position: 'absolute', top: '0', right: '0' }}>
                        <Tooltip
                            title={translate(`$__hideTags`)}
                        >
                            <React.Fragment>
                                <IconButton
                                    onClick={(e) => {
                                        if (!props.showOptions)
                                            setShowTags(false)
                                    }}
                                >
                                    <Icon
                                        size={"22px"}
                                        color={customApp('medium')}
                                        icon={'visibility_off'}
                                    />
                                </IconButton>
                            </React.Fragment>
                        </Tooltip>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default CardTags
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import Tooltip from "@material-ui/core/Tooltip"

//FUNCTIONS
import { translate } from "functions/"

function Component(props) {
    const { session } = reduxStore.getState()
    const [typing, setTyping] = React.useState({})
    React.useEffect(() => {
        setTyping(props.typing)
    }, [props.typing])

    return (
        typing &&
            (
                (
                    props.nodeId &&
                    props.nodeId !== session._id &&
                    typing['me'] &&
                    typing['me'][props.nodeId]
                )
            ) ?
            <div style={{ marginLeft: 15, fontSite: 12, color: 'lightgray', fontWeight: "normal" }}>
                {translate(`$__typing`)}...
            </div>
            : props.nodeId &&
                typing &&
                (
                    (
                        typing[props.nodeId] &&
                        Object.keys(typing[props.nodeId]).filter(us => session && session._id && us !== session._id && typing[props.nodeId][us]).length > 0
                    )
                ) ?
                <div style={{ marginLeft: 15, fontSite: 12, color: 'lightgray', fontWeight: "normal", display: "flex", alignItems: "center" }}>
                    {Object.keys(typing[props.nodeId]).filter(us => us !== session._id && typing[props.nodeId][us])
                        .map((a, i) => {
                            let name = ``
                            if (reduxStore.getState().db.cards[props.nodeId]._users[a].name) {
                                name = reduxStore.getState().db.cards[props.nodeId]._users[a].name
                            } else if (reduxStore.getState().db.cards[props.nodeId]._users[a].name) {
                                let splitName = reduxStore.getState().db.cards[props.nodeId]._users[a].name.split(' ')
                                name = `${splitName[0]}${splitName.length > 1 ? ` ${splitName[splitName.length - 1]}` : ``}`
                            }
                            return (<Tooltip
                                title={name}
                                key={a}
                            >
                                <Avatar style={{
                                    width: 22,
                                    height: 22,
                                    border: 'none'
                                }} alt={name} src={reduxStore.getState().db.cards[props.nodeId]._users[a].image || null} >
                                    {name.substr(0, 1)}
                                </Avatar>
                            </Tooltip>)
                        })
                    }
                    <span style={{ marginLeft: 7 }}>{translate(`$__typing`)}...</span>
                </div>
                : <React.Fragment></React.Fragment>
    )
}
const mapStateToProps = store => ({
    // typing: store.chat.typing
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
const initialState = {}

const sideModule = (state = initialState, data) => {
    if (data.action === 'SET_TIMELINE_LOADER' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else {
        return state
    }
};

export default sideModule;
//CORE
import React from 'react';
import moment from "moment-timezone";

//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Checklist from "components/Checklist";
import Icon from "components/Icon";

//@MATERIAL
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';


//FUNCTIONS
import { translate } from 'functions/';

function MyDay() {
    const { db, session } = reduxStore.getState()
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null)
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');


    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    let cards = Object.keys(db.cards).filter(a =>
        !db.cards[a].deleted
        && (
            !db.cards[a].checklist
            || (
                db.cards[a].checklist
                && db.cards[a].showCard
            )
        )
        && db.cards[a]._users
        && db.cards[a]._users[session._id]
        && db.cards[a]._users[session._id].myDay
        && (
            moment(new Date(db.cards[a]._users[session._id].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
        )
    ).map(a => db.cards[a])

    if (cards.length === 0)
        return (<React.Fragment></React.Fragment>)

    return (
        <div id="notificationsBt"
            style={{
                zIndex: '100 !important'
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={cards.filter(fil => fil.status !== 'completed').length}
                    color="secondary"
                >
                    <Icon
                        icon={'hourglass_empty'}
                        color={'parent'}
                        title="$__myDay"
                    />
                </Badge>
            </Button>
            {cards.length > 0 &&
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    style={{ width: '400px' }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div style={{
                                        height: 'auto',
                                        maxHeight: 'calc(90vh - 80px)',
                                        width: '100%',
                                        overflowY: 'auto'
                                    }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '7px'
                                            }}
                                        >
                                            <p style={{ margin: '0px', marginLeft: '10px', alignSelf: 'center', padding: '0px', fontSize: '14px', fontWeight: 'bolder' }}>{translate('$__myDay')}</p>
                                        </div>
                                        <Checklist
                                            noChecklist
                                            noDelete
                                            showCard
                                            myDay
                                            db={"cards"}
                                        />
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            }
        </div >
    )
}

const mapStateToProps = (store) => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyDay)

import React from "react";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/"

//@MATERIALS
import Backdrop from "@material-ui/core/Backdrop"

//COMPONENTS
import CustomButton from "components/Buttons/custom"

//FUNTIONS
import { customApp, translate } from "functions/";


export default function PolicyAndTerms() {

  const [consent, setConsent] = React.useState(true)
  const [terms, setTerms] = React.useState(true)

  React.useEffect(() => {
    if (reduxStore.getState().session && reduxStore.getState().session.curso241021) {
      // console.log(reduxStore.getState().session.curso241021)
      if (reduxStore.getState().session && reduxStore.getState().session.consent && JSON.parse(reduxStore.getState().session.consent)) {
        let termo = JSON.parse(reduxStore.getState().session.consent)
        if (!termo.curso2410) {
          setConsent(false)
          setTerms(false)
        }
      } else {
        setConsent(false)
        setTerms(false)
      }
    }
  }, [])


  const updateConsent = async () => {
    setTerms(true)
    let consent = {
      accepted_at: `${moment().unix("X")}`,
      privacy: "Data de criação: 24 de outubro de 2024, Última atualização: 24 de outubro de 2024",
      terms: "Data de criação: 24 de outubro de 2024, Última atualização: 24 de outubro de 2024",
      curso2410: "Data de criação: 24 de outubro de 2024, Última atualização: 24 de outubro de 2024",
    }
    const { socket } = reduxStore.getState().functions
    socket.emit("data", {
      module: "user",
      method: "post",
      action: "consent"
    }, {
      version: JSON.stringify(consent)
    })
  }

  if (terms)
    return (<React.Fragment></React.Fragment>)

  return (
    <div id={`policyAndTermsConsent`}

    >
      <Backdrop
        // className={classes.backdrop}
        style={{
          zIndex: 30000,
          backdropFilter: "blur(7px) sepia(90%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        open={!consent} onClick={(e) => {
          // close()
        }}>
        <div
          style={{
            position: "fixed",
            ...window.innerWidth > 600 ? {
              top: 77,
              left: 77,
              right: 77,
              bottom: 77,
            } : {
              top: 50,
              left: 7,
              right: 7,
              bottom: 7,
            },
            ...window.innerWidth > 600 ? {
              zIndex: 3,
            } : {
              zIndex: 6000,
            },
            boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
            borderRadius: "15px",
            overflow: 'hidden',
            backgroundColor: "#FFFFFF"
          }}
        >
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 40,
            background: customApp('ColumnTitleColor'),
            display: "flex",
            alignItems: "center",
            // textAlign: "center",
            justifyContent: "center",
            color: customApp("color"),
            fontWeight: "bold"
          }}>
            {translate(`$__TermsOfUseAndPrivacyPolicy`)} - {translate(`$__licensingAgreement`)} - {translate(`$__3pillarsContract`)} - {translate(`$__imageRights`)}
          </div>
          <div style={{
            position: "absolute",
            top: 40,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <iframe title="outModule"
              id="policy"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 40,
                width: "100%",
                height: "calc(100% - 40px)",
              }}
              width={"100%"}
              height={"100%"}
              onScroll={() => {
                console.log("scroll")
              }}
              src="https://machen.ai/ContratoCompleto3Pilares.htm"
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 40,
              display: "flex",
              justifyContent: "center"
            }}
          >


            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <CustomButton
                id={`termsofuseButtonConsent`}
                title={translate(`$__IReadAndIAgreeTheTermsOfUse`, "*")}
                text={translate(`$__IReadAndIAgreeTheTermsOfUse`, "*")}
                color={customApp("menu")}
                icon={"verified"}
                onClick={() => {
                  updateConsent()
                }}
                size={"25px"}
              />
            </div>
          </div>
        </div>

      </Backdrop >

    </div >
  );
}

import React from 'react'

//COMPONENTS
import Icon from 'components/Icon';
import { Tooltip, Avatar } from '@mui/material';

//FUNCTIONS
import {
  // stringToColor, 
  stringAvatar
} from 'functions';


const TableRow = (props) => {
  const {
    // add,
    // checkAll,
    column,
    // data,
    // data1,
    // dataError,
    edit,
    handleDataChange,
    selected,
    setSelectUser,
    handleCheckboxChange,
    index,
    // isActive,
    style,
    usuario,
  } = props

  const divStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    flex: 1,
    border: 'none',
    borderBottom: '1px solid #ebebeb',
    borderTop: '1px solid #ebebeb',
    alignSelf: 'stretch'
  };

  const handleChange = () => {
    handleCheckboxChange(index);
  };
  const handleSelect = (prop, usuario) => {
    if (prop === "nomecompleto" || prop === "nomedeexibição") {
      setSelectUser(usuario)
    }
  }

  const InputRender = (props) => {
    const prop = props.item
    return (
      <div style={{ ...divStyles }} onClick={() => handleSelect(prop, usuario)}>
        {prop === "nomecompleto" ?
          <div style={{ marginLeft: '5px', cursor: 'pointer' }}>
            {/* Imagem */}
            <Tooltip title={`Ver ${usuario?.nomecompleto}`}>
              <Avatar {...stringAvatar(usuario?.nomecompleto)} src={usuario?.image} />
            </Tooltip>
          </div>
          : null
        }
        <input
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            marginLeft: "5px",
            color: "#1C1C1C",
            fontWeight: prop === "nomecompleto" ? "bold" : "normal",
            cursor: prop === "nomecompleto" || prop === "nomedeexibição" ? 'pointer' : 'default',
          }}
          type="text"
          defaultValue={prop === "departamento/grupo" ?
            usuario?.[prop]?.filter(a => a.value)
              .sort((a, b) => {
                if (a.label < b.label)
                  return -1
                return 1
              }).map((item, i) => ` ${item.label}`) :
            usuario?.[prop]}
          onMouseUp={(e) => e.target.focus()}
          onMouseEnter={(e) => {
            e.target.style.backgroundColor = "rgba(0, 0, 0, 0.12)";
          }}
          onMouseLeave={(e) => {
            e.target.style.backgroundColor = "transparent";
          }}
          onBlur={(e) => handleDataChange(index, e.target.value, [prop])}
          disabled={edit ? false : true}
        />
      </div>
    );
  }

  return (
    <div style={style}>
      <div
        style={{
          backgroundColor: selected ? '#aaaaaa63' : '#fff',
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "44px",
          overflowY: "hidden" /* data.length > 11 ? 'hidden' : 'scroll' */,
        }}
      >
        {edit ? (
          <div
            style={{
              ...divStyles,
              width: "50px",
              borderBottom: '1px solid #ebebeb',
              borderTop: '1px solid #ebebeb',
              flex: "none",
              height: "100%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <input
              type="checkbox"
              style={{
                width: "16px",
                height: "16px",
                borderRadius: "15px",
                cursor: "pointer",
                backgroundColor: "transparent",
              }}
              onMouseUp={handleChange}
              checked={selected}
            />
          </div>
        ) : null}

        {column.length > 0 &&
          column.map((item, itemIndex) => {
            return <InputRender item={item} key={itemIndex} />;
          })}
        {edit ? <>
          <div
            style={{
              ...divStyles,
              width: "60px",
              borderBottom: '1px solid #ebebeb',
              borderTop: '1px solid #ebebeb',
              flex: "none",
              display: "flex",
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <p
              style={{
                alignSelf: "center",
                padding: "0px",
                margin: "0px",
                display: 'flex',
                color: 'rgb(159 159 159)',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <Icon>delete</Icon>
            </p>
          </div>
        </> : null}
      </div>
    </div>
  );
};


export default TableRow


import React from "react";
import moment from "moment-timezone"
import DataPicker from "components/DataPicker"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Autocomplete from "components/AutoComplete/multiple"
import Checkbox from 'components/Checkbox'
import Icon from "components/Icon"
import SelectUsers from "components/User/select"
import Switch from "components/Switch"
import TextField from "components/TextField/"
import MultInput from "components/MultInput/input";

//@MATERIAL
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/filter.js"
import "assets/css/components/react-datepicker.css"

//FUNCTIONS
import { customApp, translate } from "functions/"


const useStyles = makeStyles(styles)

function RenderOptions(props) {
    const f = props.field
    const classes = useStyles()
    const {
        db,
        searchs,
    } = reduxStore.getState();
    const [searchValues, setSearch] = React.useState({ ...JSON.parse(initialIndicators) })
    const [AccordionExpanded, SetAccordionExpanded] = React.useState(null)
    const indicators = JSON.parse(initialIndicators)

    React.useEffect(() => {
        if (props.onChange) {
            let appliedFilters = {}
            Object.keys(searchValues).forEach(x => {
                if (!indicators[x])
                    appliedFilters = {
                        ...appliedFilters,
                        [x]: searchValues[x]
                    }
                if (indicators[x] && JSON.stringify(initialIndicators[x]) !== JSON.stringify(searchValues[x]))
                    appliedFilters = {
                        ...appliedFilters,
                        [x]: searchValues[x]
                    }
            })
            props.onChange(appliedFilters)
        }
        if (props?.preffers?.searchButton)
            return
        props.reduxFunction("ASYNC", "SET_SEARCH", {
            ...reduxStore.getState().searchs,
            [props.nodeId]: {
                ...JSON.parse(initialIndicators),
                ...searchValues
            },
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValues])

    React.useEffect(() => {
        let newValue = {
            ...JSON.parse(initialIndicators),
            ...searchs[props.nodeId] || {},
        }
        if (JSON.stringify(newValue) !== JSON.stringify(searchValues))
            setSearch(newValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [f])

    React.useEffect(() => {
        if (
            props.searchs
            && !props.searchs[props.nodeId]
        ) setSearch(JSON.parse(initialIndicators))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchs])


    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    const updateIndicators = (indicator, value) => {
        let indicatorValues = searchValues[indicator] || {}
        if (value === 'all') {
            Object.keys(indicatorValues).forEach(a => {
                indicatorValues[a] = true
            })
        } else {
            if (indicatorValues.all)
                Object.keys(indicatorValues).forEach(a => {
                    indicatorValues[a] = false
                })
            indicatorValues.all = false
        }
        if (props?.preffers?.unique?.status)
            Object.keys(indicatorValues).forEach(a => {
                indicatorValues[a] = false
            })

        setSearch({
            ...searchValues,
            [indicator]: {
                ...indicatorValues,
                [value]: value === 'all' ? true : !indicatorValues[value],
            }
        })
    }

    const indicatorField = (ind) => {
        return (
            <Accordion style={{ width: "100%" }}
                expanded={AccordionExpanded === ind ? true : false}
                onChange={() => {
                    SetAccordionExpanded(AccordionExpanded === ind ? null : ind)
                }}
            >
                <AccordionSummary
                    style={{ minWidth: '100%' }}
                    expandIcon={
                        <Icon>expand_more</Icon>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <div style={{ color: customApp('color'), fontSize: '16px', justifyContent: "space-between", width: "100%", display: "flex" }}>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                            }}>
                            {translate(`$__${ind}`)}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            {Object.keys(searchValues[ind])
                                .filter((a, i) => searchValues[ind][a] && a !== 'all')
                                .map((a, i) => {
                                    return (
                                        <Tooltip
                                            title={translate(db.priority.filter(fil => String(fil.value) === String(a))[0].label, 1)}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '11px',
                                                    height: '11px',
                                                    borderRadius: '50%',
                                                    backgroundColor: db.priority.filter(fil => String(fil.value) === String(a))[0].color,
                                                    margin: '7px 3px',
                                                }}></div>
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{ minWidth: '100%' }}
                >
                    <FormGroup style={{ width: "100%" }}>
                        {props?.preffers?.unique[ind] ? <></> :
                            <FormControlLabel
                                style={{ minWidth: '100%' }}
                                control={
                                    <Checkbox
                                        checked={searchValues[ind] && searchValues[ind].all ? true : false}
                                        onChange={() => {
                                            updateIndicators('priority', 'all')
                                        }}
                                        color="default"
                                    />
                                }
                                label={translate('$__allPriorities')}
                            />
                        }
                        {db.priority.map((pr, index) =>
                            <React.Fragment
                                key={index}
                            >
                                <FormControlLabel
                                    style={{ minWidth: '100%', borderLeft: `3px solid ${pr.color}` }}
                                    control={
                                        <Checkbox
                                            indeterminate={searchValues[ind] && searchValues[ind].all ? true : false}
                                            checked={searchValues[ind] && searchValues[ind][pr.value] ? true : false}
                                            onChange={() => {
                                                updateIndicators(ind, pr.value)
                                            }}
                                            color="default"
                                        />
                                    }
                                    label={translate(pr.label)}
                                />
                            </React.Fragment>
                        )}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        )
    }

    if (f === "admin")
        return (
            <Switch
                checked={
                    searchValues.admin
                        ? true
                        : false
                }
                onChange={(d) => {
                    setSearch({
                        ...searchValues,
                        admin: !searchValues.admin
                    })
                }}
                name="adminView"
                inputProps={{ "aria-label": "secondary checkbox" }}
            />
        )
    if (f === "text")
        return (
            <div className={classes.textField} style={{ width: "100%" }}>
                <TextField
                    className={classes.noLabelLight}
                    label={translate('$__search', '*')}
                    // variant={'standard'}
                    autoFocus
                    value={searchValues.text || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon color={customApp('color')}>search</Icon>
                            </InputAdornment>
                        ),
                        onChange: e => {
                            setSearch({
                                ...searchValues,
                                text: e.target.value
                            })
                        },
                        onKeyDown: e => {
                            // if (String(e.which) === '27')
                            //     setviewSearch(false)
                        }
                    }}
                />
            </div>
        )
    if (f === "ref1")
        return (
            <div className={classes.textField} style={{ width: "100%" }}>
                <TextField
                    className={classes.noLabelLight}
                    label={props.customTitles?.ref1 || translate('$__ref1', '*')}
                    autoFocus
                    value={searchValues.text || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon color={customApp('color')}>arrow_right</Icon>
                            </InputAdornment>
                        ),
                        onChange: e => {
                            setSearch({
                                ...searchValues,
                                ref1: e.target.value
                            })
                        },
                        onKeyDown: e => {
                            // if (String(e.which) === '27')
                            //     setviewSearch(false)
                        }
                    }}
                />
            </div>
        )
    if (f === "ref2")
        return (
            <div className={classes.textField} style={{ width: "100%" }}>
                <TextField
                    className={classes.noLabelLight}
                    label={props.customTitles?.ref2 || translate('$__ref2', '*')}
                    autoFocus
                    value={searchValues.text || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon color={customApp('color')}>turned_in_not</Icon>
                            </InputAdornment>
                        ),
                        onChange: e => {
                            setSearch({
                                ...searchValues,
                                ref2: e.target.value
                            })
                        },
                        onKeyDown: e => {
                            // if (String(e.which) === '27')
                            //     setviewSearch(false)
                        }
                    }}
                />
            </div>
        )
    if (f === "tagsByText")
        return (
            <div className={classes.textField} style={{ width: "100%" }}>
                <TextField
                    className={classes.noLabelLight}
                    label={translate('$__searchTags', '*')}
                    value={searchValues.tagsByText || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <Icon color={customApp('color')}>search</Icon>
                            </InputAdornment>
                        ),
                        onChange: e => {
                            setSearch({
                                ...searchValues,
                                tagsByText: e.target.value
                            })
                        },
                    }}
                />
            </div>
        )
    if (f === "date")
        return (
            <React.Fragment>
                <DataPicker
                    type="datetime"
                    onChangeStartDate={(d) => {
                        let newData = {
                            startDate: moment(d).unix('x')
                        }
                        if (!reduxStore.getState().searchs[props.nodeId]?.endDate || reduxStore.getState().searchs[props.nodeId].endDate < moment(d).unix('x')) {
                            newData = {
                                ...newData,
                                endDate: moment(`${moment(d).format("YYYY-MM-DD")} 23:59:59`).add(1, 'week').unix('X')
                            }
                        }
                        setSearch({
                            ...searchValues,
                            ...newData,
                        })
                    }}
                    onChangeEndDate={(d) => {
                        setSearch({
                            ...searchValues,
                            endDate: moment(d).unix('x'),
                        })

                    }}
                    range
                    horizontal
                    startDate={searchValues?.startDate ? new Date(searchValues.startDate * 1000) : null}
                    endDate={searchValues?.endDate ? new Date(searchValues.endDate * 1000) : null}
                    onClear={() => {
                        setSearch({
                            ...searchValues,
                            startDate: null,
                            endDate: null,
                        })
                    }}
                />
            </React.Fragment>
        )
    if (f === "tags")
        return (<Autocomplete
            dbNode={'tag'}
            options={reduxStore.getState().db.tag}
            style={{ width: '100%' }}
            name="tag"
            ws
            multiple
            value={searchValues?.tags || []}
            placeholder={translate('$__tags')}
            onChange={
                (v) => {
                    setSearch({
                        ...searchValues,
                        tags: v
                    })
                }
            }
        />)
    if (f === "status")
        return (
            <Accordion
                style={{ width: "100%", padding: 0 }}
                expanded={AccordionExpanded === "status" ? true : false}
                onChange={() => {
                    SetAccordionExpanded(AccordionExpanded === `status` ? null : 'status')
                }}
            >
                <AccordionSummary
                    style={{ minWidth: '100%' }}
                    expandIcon={
                        <Icon>expand_more</Icon>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <div style={{ color: customApp('color'), fontSize: '16px', justifyContent: "space-between", width: "100%", display: "flex" }}>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                            }}>
                            {translate('$__status')}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            {searchValues?.status && Object.keys(searchValues.status)
                                .filter((a, i) => ((searchValues.status[a] || searchValues.status.all) && a !== 'all'))
                                .map((a, i) => {
                                    return (
                                        <Tooltip
                                            title={translate(db.status.filter(fil => fil.value === a)[0].label, 1)}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '11px',
                                                    height: '11px',
                                                    borderRadius: '50%',
                                                    backgroundColor: db.status.filter(fil => fil.value === a)[0].color,
                                                    margin: '7px 3px',
                                                }}></div>
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{ minWidth: '100%' }}
                >
                    <FormGroup style={{ width: "100%" }}>
                        <FormControlLabel
                            style={{ minWidth: '100%' }}
                            control={
                                <Checkbox
                                    checked={searchValues.status && searchValues.status.all ? true : false}
                                    onChange={() => {
                                        updateIndicators('status', 'all')
                                    }}
                                    color="default"
                                />
                            }
                            label={translate('$__allStatus')}
                        />
                        {db.status.map((pr, index) =>
                            <React.Fragment
                                key={index}
                            >
                                <FormControlLabel
                                    style={{ minWidth: '100%' }}
                                    control={
                                        <Checkbox
                                            indeterminate={searchValues.status && searchValues.status.all ? true : false}
                                            checked={searchValues.status && searchValues.status[pr.value] ? true : false}
                                            onChange={() => {
                                                updateIndicators('status', pr.value)
                                            }}
                                            color="default"
                                        />
                                    }
                                    label={translate(pr.label)}
                                />
                            </React.Fragment>
                        )}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        )
    if (f === "priority")
        return indicatorField("priority")
    if (f === "complexity")
        return indicatorField("complexity")
    if (f === "risk")
        return indicatorField("risk")
    if (f === "impact")
        return indicatorField("impact")
    if (f === "users")
        return (<div
            style={{ marginTop: 5 }}
        >
            <SelectUsers
                includeMe
                defineResponsible
                avatarSize="23px"
                permission={true}
                data={reduxStore.getState().db.cards[props.nodeId] || null}
                noLimit
                multiple={true}
                onSelect={(e) => {
                    setSearch({
                        ...searchValues,
                        users: e
                    })
                }}
                selected={searchValues?.users || []}
                transparent
                {...props?.fieldsProps?.users || {}}
            />
        </div>)
    if (f === "responsible")
        return (<div
            style={{ marginTop: 5 }}
        >
            <SelectUsers
                text={translate("$__FilterByResponsible")}
                includeMe
                avatarSize="23px"
                permission={true}
                data={reduxStore.getState().db.cards[props.nodeId] || null}
                noLimit
                multiple={false}
                onSelect={(e) => {
                    setSearch({
                        ...searchValues,
                        responsible: e
                    })
                }}
                selected={searchValues?.responsible || []}
                transparent
                {...props?.fieldsProps?.responsible || {}}
            />
        </div>)
    if (f === "userGroups")
        return (
            <MultInput
                data={{
                    values: searchValues.userGroups || [],
                }}
                options={reduxStore.getState().db.userGroup ? reduxStore.getState().db.userGroup : []}
                placeholder={"$__userGroupPlaceholder"}
                dbNode={"userGroup"}
                callBack={(v) => {
                    setSearch({
                        ...searchValues,
                        userGroups: v
                    })
                }}
                colorPickerCallBack={(res) => {
                }}
                noAdd
            />
        )
    if (process.env.REACT_APP_PROD === "true")
        return (<></>)
    return (<div style={{ fontSize: 10, color: "red" }}>{f} NOT EXISTS</div>)


}

const initialIndicators = JSON.stringify({
    status: {
        all: true,
        notStarted: true,
        awaitAction: true,
        paused: true,
        inProgress: true,
        completed: true,
    },
    priority: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    risk: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    complexity: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    impact: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
})

// REACT
const mapStateToProps = (store) => ({
    searchs: store.searchs
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RenderOptions)
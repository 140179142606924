//CORE
import React from 'react'
import Moment from "moment-timezone"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import Autocomplete from "components/AutoComplete"
import CircularProgress from "components/Progress/circular"
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TagsInput from "components/Tags/input"
import TextField from "components/TextField"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Feed.js"

//FUNCTIONS
import api from "api/"
import {
    getAppAccess,
    appAlert,
    customApp,
    translate,
} from "functions/"

import {
    getUserAccess,
} from "functions/cards"

import {
    saveFeed
} from "functions/feeds"

const useStyles = makeStyles(styles)

function Component(props) {
    const classes = useStyles()
    const { sideModule, session, db } = reduxStore.getState()
    const data = sideModule.data || {}
    const [reqSave, setReqSave] = React.useState(false)
    const [feedLoading, setFeedLoading] = React.useState(false)

    const url = React.useRef()

    let CardAccess = 0
    let AppAccess = getAppAccess()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    if (hashs[0] && hashs[0].length === 36)
        CardAccess = getUserAccess(hashs[0])


    const changeData = (d) => {
        if (data.name === '' || (d.name === 'name' && d.value === '')) {
            setReqSave(false)
        } else if (data._lock && ((d.value && !sideModule.data[d.name]) || (sideModule.data[d.name] && sideModule.data[d.name] !== d.value))) {
            setReqSave(true)
        }

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                [d.name]: d.value,
            }
        })
    }

    const close = (force = false) => {
        if (reqSave && !force) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }

    const save = async (cl) => {
        setReqSave(false)
        if (!sideModule.data.name) {
            appAlert({
                message: translate('$__fillName', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        } else {
            const reqSave = await saveFeed(props, true)
            if (reqSave) {
                appAlert({
                    message: translate('$__feedSigned', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                if (cl)
                    close(true)
            } else {
                appAlert({
                    message: translate('$__AnErrorHasOccurredPleaseTryAgain', 1),
                    variant: 'error',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                setReqSave(true)
            }
        }
    }

    const feedValidate = async (str) => {
        if (str)
            if (str.indexOf('http') === -1) {
                appAlert({
                    message: `${translate('$__invalidUrl', 1)}: ${translate('$__urlMustContainHttps', 1)}`,
                    variant: 'error',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
                props.reduxFunction("ASYNC", "SET_MODULE", {
                    ...sideModule,
                    data: {
                        ...sideModule.data,
                        url: '',
                    }
                })
            } else {
                var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\@[a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

                if (!!pattern.test(str)) {
                    setFeedLoading(true)
                    let urlToVerify = str
                    if (urlToVerify[str.length - 1] === '/')
                        urlToVerify = urlToVerify.substring(0, str.length - 1)
                    if (urlToVerify.indexOf("http://") === '/')
                        urlToVerify = urlToVerify.replace("http://", "https://")

                    if (sideModule.data.type === 'feed')
                        try {
                            const result = await api.post('/feed/exist', {
                                url: str
                            })
                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                ...sideModule,
                                data: {
                                    ...sideModule.data,
                                    _id: result.data.id,
                                    url: urlToVerify,
                                    _lock: true,
                                    feed: result.data.feed,
                                    name: result.data.title,
                                    customTitle: result.data.customTitle,
                                    customDescription: result.data.customDescription,
                                }
                            })
                            if (result)
                                setFeedLoading(false)
                            if (result.data.title)
                                setReqSave(true)
                        } catch (e) {
                            feedInsert(str)
                        }
                    if (sideModule.data.type === 'link')
                        try {
                            let hashs = window.location.hash.split('/')
                            hashs[0] = hashs[0].replace('#', '')
                            const result = await api.post('/link/exist', {
                                idRel: hashs[0],
                                url: urlToVerify
                            })
                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                ...reduxStore.getState().sideModule,
                                id: 'new',
                                activeModule: 'cardEdit',
                                db: 'cards',
                                data: {
                                    _id: 'new',
                                    type: 'link',
                                    name: result.data.title,
                                    description: result.data.contentSnippet,
                                    image: result.data.img,
                                    url: result.data.link,
                                    content: result.data.article,

                                }
                            })
                            if (result)
                                setFeedLoading(false)
                            if (result.data.title)
                                setReqSave(true)
                        } catch (e) {
                            appAlert({
                                message: `${translate('$__invalidFeedUrl', 1)}: ${translate('$__pleaseVerifyTheFeedAddress', 1)}`,
                                variant: 'error',
                                persist: false,
                                horizontal: 'right',
                                confirm: null
                            })
                        }
                }
                return
            }
        return false
    }

    const feedInsert = async (str) => {
        try {
            const result = await api.post('/feed/request/', {
                url: str
            })
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...sideModule,
                data: {
                    ...sideModule.data,
                    _id: result.data.id,
                    url: str,
                    _lock: true,
                    feed: result.data.feed,
                    name: result.data.title,
                    customTitle: result.data.customTitle,
                    customDescription: result.data.customDescription,
                }
            })
            if (result)
                setFeedLoading(false)
            if (result.data.title)
                setReqSave(true)
        } catch (e) {
            appAlert({
                message: `${translate('$__invalidFeedUrl', 1)}: ${translate('$__pleaseVerifyTheFeedAddress', 1)}`,
                variant: 'error',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        }
    }

    let permission = props.permission ? props.permission : false
    if (!permission && parseInt(CardAccess) >= 3)
        permission = true

    return (
        <div>
            <div className={classes.btClose}>
                <div
                    style={{
                        position: 'fixed',
                        display: 'inline-flex',
                        paddingLeft: '10px',
                        top: '7px',
                        right: 0,
                        backgroundColor: "#FFFFFF"
                    }}>
                    {(permission && reqSave) &&
                        <SideModuleCloseAndSave
                            save={reqSave ? () => {
                                // setSaveLoader(true)
                                save()
                            } : null}
                            saveClose={reqSave ? () => {
                                // setSaveLoader(true)
                                save(true)
                            } : null}
                            close={() => {
                                if (props.onClose)
                                    props.onClose()
                            }}
                        />
                    }
                    <CustomButton
                        title={translate(`$__close`, 1)}
                        color='lightgrey'
                        icon={'cancel'}
                        onClick={() => {
                            if (props.onClose)
                                props.onClose()
                        }}
                        size={'25px'}
                    />
                </div>
            </div>
            <div className={classes.data}>
                {!data._lock ?
                    <TextField
                        label={translate('$__feedUrl', '*')}
                        variant={customApp('fieldVariant')}
                        ref={url}
                        name="url"
                        InputProps={{
                            autoComplete: 'new-password',
                            multiline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon icon='rss_feed' />
                                </InputAdornment>
                            ),
                            onChange: (d) => {
                                changeData(d.target)
                            },
                            onBlur: (d) => {
                                feedValidate(d.target.value)
                            }
                        }}
                        value={data && data.url ? data.url : ''}
                        placeholder={'http://www.domain.xpto/rss...'}
                        disabled={data._lock ? true : false}
                    />
                    :
                    <React.Fragment>
                        <TextField
                            label={translate('$__titleOfFeed', 1)}
                            variant={customApp('fieldVariant')}
                            name={'name'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='view_headline' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.name ? data.name : ''}
                            placeholder={translate('$__titleOfFeed_placeholder')}
                            {...(
                                (session
                                    && session.account !== "7806912b-4c7d-4f4f-b9e0-b93fc8f08776"
                                )
                                ||
                                (
                                    data && data.name
                                )
                            ) ? { disabled: true } : {}}
                        />
                        <TextField
                            label={translate('$__customTitle', 1)}
                            variant={customApp('fieldVariant')}
                            name={'customTitle'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='title' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.customTitle ? data.customTitle : ''}
                            placeholder={translate('$__customTitle', 1)}
                        />
                        {session && session.account !== "7806912b-4c7d-4f4f-b9e0-b93fc8f08776" ?
                            <React.Fragment>
                                <TextField
                                    label={translate('$__nameOfFontData', 1)}
                                    variant={customApp('fieldVariant')}
                                    name={'font'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon icon='copyright' />
                                            </InputAdornment>
                                        ),
                                        onChange: (d) => {
                                            changeData(d.target)
                                        },
                                    }}
                                    value={data && data.font ? data.font : ''}
                                    placeholder={translate('$__nameOfFontData', 1)}
                                />
                                <TextField
                                    label={translate('$__descriptionOfRss', 1)}
                                    variant={customApp('fieldVariant')}
                                    name={'description'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon icon='subtitles' />
                                            </InputAdornment>
                                        ),
                                        onChange: (d) => {
                                            changeData(d.target)
                                        },
                                    }}
                                    value={data && data.description ? data.description : ''}
                                    placeholder={translate('$__descriptionOfRss', 1)}
                                />
                            </React.Fragment>
                            : <React.Fragment></React.Fragment>
                        }
                        <TextField
                            label={translate('$__customDescription', 1)}
                            variant={customApp('fieldVariant')}
                            name={'customDescription'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='subtitles' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.customDescription ? data.customDescription : ''}
                            placeholder={translate('$__customDescription', 1)}
                        />
                        <TextField
                            label={translate('$__url', '*')}
                            variant={customApp('fieldVariant')}
                            ref={url}
                            name="url"
                            InputProps={{
                                autoComplete: 'new-password',
                                multiline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='http' />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                                onBlur: (d) => {
                                    feedValidate(d.target.value)
                                }
                            }}
                            value={data && data.url ? data.url : ''}
                            placeholder={'http://www.domain.xpto/rss...'}
                            disabled={data._lock ? true : false}
                        />
                        {(AppAccess && AppAccess.feed && (
                            AppAccess.feedAdmin
                            || AppAccess.feedModerate
                        )) &&
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate("$__tags", 1)}</legend>
                                <TagsInput
                                    data={{
                                        values: data._tags ? data._tags : [],
                                    }}
                                    callBack={(v) => {
                                        changeData({ name: "_tags", value: v })
                                    }}
                                />
                            </fieldset>
                        }
                        {AppAccess && AppAccess.feed && AppAccess.feedAdmin ?
                            <fieldset style={{ marginBottom: "14px" }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={db.plans}
                                    getOptionLabel={(option) => translate(option.label, 1)}
                                    style={{ width: "100%" }}
                                    name="plans"
                                    disableClearable
                                    value={
                                        data
                                            && data.plans
                                            && db.plans
                                            ? db.plans.filter(a =>
                                                String(a.value) === String(data.plans)
                                            )[0]
                                            : db.plans[0]
                                    }
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label={translate("$__avaiablefromplan", 1)}
                                            variant={customApp("fieldVariant")}
                                            value={
                                                data
                                                    && data.plans
                                                    && db.plans
                                                    ? db.plans.filter(a =>
                                                        String(a.value) === String(data.plans)
                                                    )[0]
                                                    : db.plans[0]
                                            }
                                        />
                                    }
                                    onChange={
                                        (d, v) => {
                                            if (permission)
                                                changeData({ name: "plans", value: v["value"] })
                                        }
                                    }
                                />
                            </fieldset>
                            : <React.Fragment></React.Fragment>
                        }
                        <div className={classes.switch}>
                            <Switch
                                checked={data.private ? true : false}
                                onChange={(d) => {
                                    changeData({
                                        name: 'private',
                                        value: data.private ? false : true
                                    })
                                }}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                            {translate('$__private', 1)}
                        </div>

                    </React.Fragment>
                }

                {!data._lock && !feedLoading &&
                    <IconButton text={translate(sideModule.data.type === 'link' ? "$__validUrl" : "$__validFeedUrl", 1)} icon="check" onClick={() => feedValidate(url.current.value)} />
                }
                {feedLoading &&
                    <div className={classes.loader}>
                        <CircularProgress />
                    </div>
                }
                {data.feed && data.feed.length > 0 &&
                    <ul className={classes.preview}>
                        <div className={classes.previewTitle}>{translate('$__preview', 1)}</div>
                        {data.feed.map((f, i) => (
                            <li
                                key={i}
                            >
                                {f && f.title &&
                                    <div
                                        dangerouslySetInnerHTML={{ __html: f.title }}
                                        className={classes.feedTitle}
                                    />
                                }
                                <div
                                    dangerouslySetInnerHTML={{ __html: f.contentSnippet }}
                                    className={classes.feedContent}
                                />
                                <div className={classes.feedFooter}>
                                    <div className={classes.feedDate}>{Moment(f.pubDate).format('DD/MM/YYYY HH:MM')}</div>
                                    <div className={classes.feedLink}>
                                        <a href={f.link} target="_blank" rel="noopener noreferrer">{translate('$__openLink', '*')}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
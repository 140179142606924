import React, { useState } from 'react'
import Autocomplete from "components/AutoComplete/multiple"
import AutocompleteS from "components/AutoComplete"
import SelectUsers from "components/User/select"
import moment from "moment-timezone"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"


//FUNCTIONS
import { translate, customApp } from 'functions'

import {
    cardsAccess
} from "functions/users"

import {
    updateCardv2,
    updateCardStatus
} from "functions/cards"

//COMPONENTS
import Icon from 'components/Icon'
import Checkbox from 'components/Checkbox'
import ProgressCircular from "components/Progress/circular"
import TextField from "components/TextField";

//@MATERIAL
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Tooltip from "@material-ui/core/Tooltip"
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'


const EditModal = (props) => {
    const {
        db
    } = reduxStore.getState();
    const [loading, setLoading] = useState(false)
    const [hover, setHover] = useState(false)
    const [cardsChanged, setCardsChanged] = useState(0)
    const [checkedBox, setCheckedBox] = useState({
        index: 0
    })
    // {
    //     all: true,
    //     'null': true,
    //     normal: true,
    //     low: true,
    //     medium: true,
    //     high: true,
    // },

    const [searchValues, setValues] = useState({
        status: {
            all: true,
            notStarted: true,
            awaitAction: true,
            paused: true,
            inProgress: true,
            completed: true,
        },
        users: [],
        tags: [],
        priority: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        prioritySelected: "normal",
        statusSelected: {
            color: "#929497",
            icon: "query_builder",
            label: "",
            value: null
        },
        risk: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        complexity: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        impact: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
    })


    const data = "notStarted"
    //updateCardStatus

    const selectUser = async (id, cardID) => {
        let access = 1
        const noNotification = false
        // console.log(props, id, access, cardID, noNotification)
        await cardsAccess(props, id, access, cardID, noNotification)
    }


    const updateCards = () => {
        props.cards.forEach(async (cardID, index, arr) => {
            // console.log(reduxStore.getState().db.cards[cardID])
            // console.log({
            //     ...reduxStore.getState().db.cards[cardID]?._users,
            //     ...searchValues.users
            // })

            if (searchValues?.users) {
                searchValues.users.forEach((item) => {
                    selectUser(item, cardID)
                })
            }

            if (searchValues?.statusSelected?.value) {
                // console.log("passed status")
                updateCardStatus(
                    {
                        ...props,
                        data: { _id: cardID }
                    }, { status: searchValues.statusSelected.value })
            }

            const request = await updateCardv2(props, [{
                _id: cardID,
                priority: searchValues.prioritySelected,
                _tags: [
                    ...reduxStore.getState().db.cards[cardID]?._tags,
                    ...searchValues.tags
                ]
            }])
            if (request) {
                setCardsChanged(prev => prev + 1)
                // console.log(request)
            }
            if (index === arr.length - 1) {
                setTimeout(() => {
                    props.handleClose()
                    props.handleCardsUpdate(true)
                }, 1000);
            }
        });
    }

    const indicatorField = (ind) => {
        return (
            <Accordion style={{ width: "100%" }}
                expanded={true}

            >
                <AccordionSummary
                    style={{ minWidth: '100%' }}
                    expandIcon={
                        <Icon>expand_more</Icon>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"

                >
                    <div style={{ color: customApp('color'), fontSize: '16px', justifyContent: "space-between", width: "100%", display: "flex" }}>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                            }}>
                            {translate(`$__${ind}`)}
                        </div>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}>
                            {Object.keys(searchValues[ind])
                                .filter((a, i) => searchValues[ind][a] && a !== 'all')
                                .map((a, i) => {
                                    return (
                                        <Tooltip
                                            title={translate(db.priority.filter(fil => String(fil.value) === String(a))[0].label, 1)}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '11px',
                                                    height: '11px',
                                                    borderRadius: '50%',
                                                    backgroundColor: db.priority.filter(fil => String(fil.value) === String(a))[0].color,
                                                    margin: '7px 3px',
                                                }}></div>
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{ minWidth: '100%' }}
                >
                    <FormGroup style={{ width: "100%" }}>
                        {db.priority.map((pr, i) =>
                            <React.Fragment
                                key={i}
                            >
                                <FormControlLabel
                                    style={{ minWidth: '100%', borderLeft: `3px solid ${pr.color}` }}
                                    control={
                                        <Checkbox
                                            color="default"
                                            checked={i === checkedBox.index ? true : false}
                                            onChange={() => {
                                                setCheckedBox({
                                                    index: i
                                                })
                                                setValues({
                                                    ...searchValues,
                                                    prioritySelected: pr.value
                                                })
                                            }}
                                        />
                                    }

                                    label={translate(pr.label)}
                                />
                            </React.Fragment>
                        )}
                    </FormGroup>
                </AccordionDetails>
            </Accordion>
        )
    }

    // console.log(searchValues)

    return (
        <div style={{ marginTop: "610px" }}>
            <div>
                <p
                    style={{
                        marginTop: "2px",
                        height: "60px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                        borderBottom: "1px solid #d1d1cf",
                        fontSize: "16px"
                    }}
                >
                    <Icon
                        size="25px"
                        style={{
                            alignSelf: "center",
                            marginRight: "10px",
                            color: "#001836"
                        }}
                    >
                        edit
                    </Icon>
                    {translate('$__MultipleEdit')}
                </p>
                <div id="getElements_40">
                    <Icon
                        onClick={(e) => {
                            props.handleCardsUpdate(true)
                            props.handleClose()
                        }}
                        style={{
                            alignSelf: "center",
                            height: "25px",
                            cursor: "pointer",
                            position: "absolute",
                            width: "20px",
                            right: "15px",
                            top: "13px",
                            color: "grey"
                        }}
                        size="30px"

                    >
                        close
                    </Icon>
                </div>
            </div>
            <p style={{ marginLeft: "25px", fontWeight: "bold", marginTop: "30px" }}>   {translate('$__AddUsers') + ":"}</p>
            <div style={{ marginLeft: "30px" }}>
                <SelectUsers
                    includeMe
                    defineResponsible
                    avatarSize="23px"
                    permission={true}
                    data={reduxStore.getState().db.cards[props.nodeId] || null}
                    noLimit
                    multiple={true}
                    onSelect={(v) => {
                        // console.log(v)
                        // console.log(searchValues?.users)
                        setValues({
                            ...searchValues,
                            users: [
                                ...v
                            ]
                        })
                    }}
                    selected={searchValues?.users}
                    transparent
                />
            </div>
            <div style={{ width: "490px", marginTop: "5px", marginLeft: "25px" }}>
                <Autocomplete
                    options={reduxStore.getState().db.tag}
                    style={{ width: '100%' }}
                    name="tag"
                    ws
                    multiple
                    value={searchValues.tags}
                    placeholder={translate('$__tags')}
                    onChange={
                        (v) => {
                            // console.log(v)
                            setValues({
                                ...searchValues,
                                tags: [
                                    ...v
                                ]
                            })
                        }
                    }
                />
            </div>

            <div style={{ width: "490px", marginTop: "15px", marginLeft: "25px" }}>
                <AutocompleteS
                    id="combo-box-demo"
                    options={db.status}
                    getOptionLabel={(option) => translate(option.label, 1)}
                    style={{ width: "100%" }}
                    name="status"
                    value={searchValues.statusSelected}
                    disableClearable
                    placeholder={translate('$__status')}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={translate("$__status", 1)}
                            variant={customApp("fieldVariant")}
                            value={db.status[0]}
                        />
                    }
                    onChange={
                        (d, v) => {
                            setValues({
                                ...searchValues,
                                statusSelected: {
                                    ...v
                                }
                            })
                        }
                    }
                />
                {indicatorField("priority")}
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                {!loading ? <button
                    onClick={() => {
                        updateCards()
                        setLoading(true)
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    style={{
                        minWidth: "140px",
                        height: "40px",
                        cursor: "pointer",
                        fontWeight: "bold",
                        border: "1px solid #d1d1cf",
                        fontWeight: "bolder",
                        marginBottom: "30px",
                        marginTop: "30px",
                        backgroundColor: hover ? "rgba(0, 0, 0, 0.1)" : "white"
                    }}
                >
                    {translate('$__conclude')}
                </button>
                    : <div style={{
                        marginBottom: "30px",
                        marginTop: "30px",
                        display: "flex",
                        fontWeight: 'bold',
                    }}>
                        <ProgressCircular />
                        <div style={{ marginLeft: "16px", marginTop: "3px" }}> {`${cardsChanged} / ${props.cards.length}`}</div>
                    </div >}
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(EditModal)
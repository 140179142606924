//CORE
import React from 'react'

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Icon from "components/Icon"
import CountIcon from "./countIcon"

//@MATERIAL
import Button from '@material-ui/core/Button'

function Component(props) {
    const { chat } = reduxStore.getState()

    return (
        <div id="chatNotificationsBt"
            style={{
                zIndex: '100 !important'
            }}>
            <Button
                aria-haspopup="true"
                onClick={() => {
                    props.reduxFunction("ASYNC", "SET_CHAT", {
                        ...chat,
                        open: !chat.open
                    })
                }}
            >
                <CountIcon>
                    <Icon
                        icon={'chat_bubble_outline'}
                        color={'parent'}
                        title="$__chat"
                    />
                </CountIcon>
            </Button>
        </div >
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(Component)

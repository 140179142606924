import React from 'react';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';

//@MATERIAL

import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Backoffice-languages";


function MasterAdminView(props) {
    return (
        <div style={{ width: '100%' }}>
            Resume
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    store: {
        languages: store.languages
    }
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MasterAdminView));
const initialState = {
    tags: [],
    userGroups: [],
    users: {
        userGroup: [],
        selected: {}
    },
    units: {
        userGroup: [],
        selected: {}
    },
    clients: {
        userGroup: [],
        selected: {}
    },
    groups: {
        userGroup: [],
        selected: {}
    },
    plans: {
        userGroup: [],
        selected: {}
    },
    sideModuleWindow: false,
    clearFilter: false,
    text: '',
    cards: {
        iniDate: null,
        endDate: null,
        dateStatus: [],
        status: {
            all: true,
            notStarted: true,
            awaitAction: true,
            paused: true,
            inProgress: true,
            completed: true,
        },
        priority: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        risk: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        complexity: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        impact: {
            all: true,
            'null': true,
            normal: true,
            low: true,
            medium: true,
            high: true,
        },
        kpiType: [],
        iniKpiValue: null,
        endKpiValue: null,
    },
};

export {
    initialState
}
//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history"

//COMPONENTS
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import NotificationCard from "componentsV3/Notifications/fullCard";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sidebarInteractive";

//FUNCTIONS
import { translate } from "functions/";

require('dotenv').config()

function NotificationsView(props) {
    const { db, chat, notifications, timeline } = reduxStore.getState()
    const { classes } = props
    const [viewModule, setViewModule] = React.useState(null);

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    const openModule = async (route, vm, nodeId, dbProps = 'users') => {
        if (route && (window.location.pathname !== route || vm === viewModule)) {
            history.push(route)
        }

        setViewModule(vm)

        if (vm === 'chat' && !nodeId) {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: true,
            })
        } else if (vm === 'chat' && nodeId) {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: true,
                db: dbProps,
                nodeId: nodeId,
            })
        } else {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: false,
            })
        }
    }

    //CARDS COLUMN
    const cardNotifications = []
    const cardsNotifications = []

    Object.keys(notifications).sort((a, b) => {
        if (parseInt(notifications[a].created_at) > parseInt(notifications[b].created_at))
            return -1
        return 1
    })
        .filter(fil => notifications[fil].module === 'card')
        .sort((a, b) => notifications[a].created_at.low - notifications[b].created_at.low)
        .forEach((n, i) => {
            if (cardsNotifications.indexOf(notifications[n].nodeId) === -1) {
                cardNotifications.push(n)
                cardsNotifications.push(notifications[n].nodeId)
            }
        })

    //TIMELINE COLUMN
    const tmNotifications = []
    const tmsNotifications = []

    Object.keys(notifications).sort((a, b) => {
        if (parseInt(notifications[a].created_at) > parseInt(notifications[b].created_at))
            return -1
        return 1
    })
        .filter(fil => notifications[fil].module === 'timeline')
        .sort((a, b) => notifications[a].created_at.low - notifications[b].created_at.low)
        .forEach((n, i) => {
            if (notifications[n].inNode && notifications[n].inNode._id && tmsNotifications.indexOf(notifications[n].inNode._id) === -1) {
                tmNotifications.push(n)
                tmsNotifications.push(notifications[n].inNode._id)
            }
        })

    return (
        <div>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    <div className={classes.moduleTitle}>
                        {translate('$__notifications')}
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <div className={classes.moduleTitle}>
                        {translate('$__cardsUpdated')}
                    </div>
                    {cardNotifications
                        .filter(n =>
                        (
                            !hashs[1]
                            ||
                            (
                                hashs[1]
                                &&
                                (
                                    hashs[1] === 'important'
                                    || hashs[1] === 'favorite'
                                    || hashs[1] === 'archive'
                                    || hashs[1] === 'latest'
                                )
                            )
                        )
                        )
                        .map(n => {
                            return (
                                <NotificationCard
                                    data={notifications[n]}
                                    key={n}
                                />
                            )
                        }
                        )}
                </GridItem>
                <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    lg={8}
                    xl={8}
                >
                    <div className={classes.moduleTitle}>
                        {translate('$__timelineUpdated')}
                    </div>
                    {tmNotifications
                        .filter(n =>
                            !hashs[1]
                            ||
                            (
                                hashs[1]
                                &&
                                (
                                    hashs[1] === 'important'
                                    || hashs[1] === 'favorite'
                                    || hashs[1] === 'archive'
                                    || hashs[1] === 'latest'
                                )
                            )
                        )
                        .map(n => {
                            return (
                                <NotificationCard
                                    data={notifications[n]}
                                    key={n}
                                    openChat={(id, db) => openModule(null, 'chat', id, db)}
                                    showTimeline
                                    timeline={notifications[n]
                                        && notifications[n].inNode
                                        && notifications[n].inNode._id
                                        && db.cards[notifications[n].inNode._id]
                                        && timeline[notifications[n].inNode._id]
                                        ? timeline[notifications[n].inNode._id]
                                        : {}
                                    }
                                />
                            )
                        }
                        )}
                </GridItem>
            </GridContainer>
        </div>
    )
}


const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsView))

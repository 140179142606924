import React, { memo } from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import history from "store/history"

//DEPENDENCIES
import Invoices from "./invoices"
import Integrations from "./integrations"
import Profile from "./profile"
import Resume from "./resume"
import WhiteLabel from "./whiteLabel"

import ErrorBoundary from "components/ErrorBoundary"

function App(props) {

    React.useEffect(() => {
        const scrollTimer = setTimeout(() => {
            document.getElementById("mainPannel").scrollTo(0, 0)
        }, 100)
        return () => {
            clearTimeout(scrollTimer)
        }
    }, [props.history])

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    if (!window.location.hash.split("/")[0].replace("#", "")) {
        history.push("acc#tab/resume")
    }

    return (
        <div>
            {/* <CardsLoader /> */}
            {hashs[1] && hashs[1] === "resume" ?
                <ErrorBoundary>
                    <Resume />
                </ErrorBoundary>
                : <React.Fragment></React.Fragment>
            }
            {hashs[1] && hashs[1] === "profile" ?
                <ErrorBoundary>
                    <Profile />
                </ErrorBoundary>
                : <React.Fragment></React.Fragment>
            }
            {hashs[1] && hashs[1] === "whiteLabel" ?
                <ErrorBoundary>
                    <WhiteLabel />
                </ErrorBoundary>
                : <React.Fragment></React.Fragment>
            }
            {hashs[1] && hashs[1] === "integrations" ?
                <ErrorBoundary>
                    <Integrations />
                </ErrorBoundary>
                : <React.Fragment></React.Fragment>
            }
            {hashs[1] && hashs[1] === "invoicing" ?
                <ErrorBoundary>
                    <Invoices />
                </ErrorBoundary>
                : <React.Fragment></React.Fragment>
            }
            {/* {(!hashs[0] || (hashs[0] && hashs[0].replace("#", "").length !== 36)) && hashs[1] === "home" ?
                <React.Fragment>
                    <HomePlans />
                </React.Fragment>
                : (!hashs[0] || (hashs[0] && hashs[0].replace("#", "").length !== 36)) && hashs[1] === "completed" ?
                    <React.Fragment>
                        <HomePlans completed={true} />
                    </React.Fragment>
                    :
                    (!hashs[0]
                        ||
                        (hashs[0]
                            && hashs[0].replace("#", "").length !== 36
                        )
                    )
                    &&
                    // <Plans />
            } */}
            {/* {hashs[0].length === 36 && reduxStore.getState().db.cards[hashs[0].replace("#", "")] ?
                <React.Fragment>
                    {hashs[1] && hashs[1] === "matrix" &&
                        <Matrix />
                    }
                    {hashs[1] && (hashs[1] === "actions" || hashs[1] === "guidelines" || hashs[1] === "objectives" || hashs[1] === "goals")
                        &&
                        <Actions type={hashs[1]} />
                    }
                    {hashs[1] && hashs[1] === "dashboard" &&
                        <Dashboard />
                    }
                    {hashs[1] && hashs[1] === "overview" &&
                        <Overview />
                    }
                    {hashs[1] && hashs[1] === "bright-and-connected" &&
                        <React.Fragment>
                            <BrightAndConnected />
                        </React.Fragment>
                    }
                    {hashs[1] && hashs[1] === "insight-boards" &&
                        <InsightBoards />
                    }
                    {hashs[1] && hashs[1] === "radar" &&
                        <Radar />
                    }
                    {hashs[1] && hashs[1] === "library" &&
                        <Library />
                    }
                    {hashs[1] && hashs[1] === "planningBoard" &&
                        <iframe title="outModule"
                            style={{ position: "absolute", width: "calc(100% - 30px)", height: "100%", border: 'none' }}
                            src={`https://miro.com/app/live-embed/${reduxStore.getState().db.cards[hashs[0]].miroBoard}=/?embedAutoplay=true&moveToViewport=-23165,-5837,13803,7546`}
                        ></iframe>
                    }
                </React.Fragment>
                :
                <React.Fragment>
                </React.Fragment>
            } */}
        </div >
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(App))
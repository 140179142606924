import React from "react"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'

//COMPONENTS
import CardsLoader from "components/Card/loader"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//DEPENDENCIES
import Resume from "./resume"
import DayTasks from "./daytasks"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard"
import ErrorBoundary from "components/ErrorBoundary"

function Dashboard() {
  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  // //GJP
  // if (reduxStore.getState().session?.account === "47a4ecb1-5244-4fa5-abdd-83f6eaf3cd36")
  //   return (
  //     <ErrorBoundary>
  //       <CardsLoader />
  //       {(hashs[0] === 'tab' && hashs[1] === 'resume') &&
  //         <ErrorBoundary>
  //           <ResumeGJP />
  //         </ErrorBoundary>
  //       }
  //       {(hashs[0] === 'tab' && hashs[1] === 'dayTasks') &&
  //         <ErrorBoundary>
  //           <DayTasks />
  //         </ErrorBoundary>
  //       }
  //     </ErrorBoundary>
  //   )

  return (
    <div>
      <CardsLoader />
      {(hashs[0] === 'tab' && hashs[1] === 'resume') &&
        <ErrorBoundary>
          <Resume />
        </ErrorBoundary>
      }
      {(hashs[0] === 'tab' && hashs[1] === 'dayTasks') &&
        <ErrorBoundary>
          <DayTasks />
        </ErrorBoundary>
      }
    </div >
  )
}

const mapStateToProps = (store, props) => ({
  history: store.db.history
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
//API
import apims from "apims"

const loadFiles = async (data) => {
    const { ids, page = 0, limit = 20 } = data
    let result = await new Promise(async (resolve, reject) => {
        try {
            let reqFiles = await apims.post(`/Files_List_V2/`,
                {
                    ids,
                    type: data.type ? data.type : 'cards',
                    limit,
                    skip: page * limit
                }
            )
            if (reqFiles && reqFiles.data) {
                let data = reqFiles.data
                resolve(data)
            }
        } catch (e) {
            console.group('loadFiles::ERR::')
            console.log(e)
            console.groupEnd()
            reject(true)
        }

    })
    if (result)
        return result
}

const navFolders = async (data) => {
    let result = await new Promise(async (resolve, reject) => {
        try {
            let reqFolders = await apims.post(`/Files_Folders/`,
                {
                    ids: data && data.ids ? data.ids : [],
                    childrens: data && data.childrens ? data.childrens : false,
                    types: data && data.types ? data.types : null
                }
            )
            if (reqFolders && reqFolders.data) {
                resolve(reqFolders.data)
            }
        } catch (e) {
            console.group('loadFiles::ERR::')
            console.log(e)
            console.groupEnd()
            reject(true)
        }

    })
    if (result)
        return result
}

const navFiles = async (data) => {
    const { ids, search, skip = null, limit = null, asc = false, childrens = false } = data
    let result = await new Promise(async (resolve, reject) => {
        try {
            let reqFiles = await apims.post(`/Files_Nav/`,
                {
                    ids,
                    skip,
                    limit,
                    search,
                    asc,
                    type: data.type ? data.type : 'cards',
                    childrens
                }
            )
            if (reqFiles && reqFiles.data) {
                let data = reqFiles.data

                resolve(data)
            }
        } catch (e) {
            console.group('loadFiles::ERR::')
            console.log(e)
            console.groupEnd()
            reject(true)
        }

    })
    if (result)
        return result
}

export {
    loadFiles,
    navFiles,
    navFolders
}
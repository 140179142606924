// ID_CREATED*************************

import React from "react"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import NewCard from "components/Card/new"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard"


//FUNCTIONS
import {
    translate,
    customApp,
} from "functions/"
import HtmlTooltip from "components/Tooltip/html"
import ButtonOptions from "componentsV3/Buttons"

//ICONS

//VARIABLES
function Dashboard(props) {
    const { db, session } = reduxStore.getState()
    const [showCards, setShowCards] = React.useState(false)

    let showIn = Object.keys(db.cards).filter(c => {
        if (
            db.cards[c]
            && !db.cards[c].deleted
            && db.cards[c].type === "step"
            && db.cards[c].showInPanel
            && db.cards[c].showGroups?.filter(a => db.users[session._id]?.groups?.filter(gu => gu.value === a.value).length > 0).length > 0
        ) return true
        return false
    }).sort((a, b) => {
        let paramA = db.cards[a].name.toLowerCase().trim(), paramB = db.cards[b].name.toLowerCase().trim()
        if (paramA < paramB)
            return -1
        if (paramA > paramB)
            return 1
        return 0
    }
    ).map(c => db.cards[c])


    if (showIn.length === 0)
        return (<React.Fragment></React.Fragment>)

    return (
        <div style={{ width: "100%", position: "relative", float: "left", clear: "both" }}>
            {showIn && showIn.length > 0 ?
                <GridContainer
                    style={{
                        paddingLeft: 15,
                        paddingRight: 15,
                        boxShadow: "0 5px 7px 1px rgba(0,0,0,0.03)",
                        backgroundColor: "rgba(250,250,250,0.07)",
                        paddingBottom: 33
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            float: "left",
                            width: "100%",
                            color: customApp("menu"),
                            fontWeight: "bold",
                            fontSize: "16px",
                            marginTop: "16px",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {translate("$__myChannels")}
                        <div style={{
                            display: "flex",
                        }}

                        >
                            <ButtonOptions
                                style={{ marginRight: 7, marginLeft: 7 }}
                                text={translate("$__channels")}
                                active={!showCards}
                                onClick={() => {
                                    setShowCards(false)
                                }}
                            />
                            <ButtonOptions
                                text={translate("$__showMyRequests")}
                                active={showCards}
                                onClick={() => {
                                    setShowCards(true)
                                }} />
                        </div>
                    </div>
                    {showCards ?
                        <GridItem
                            xs={12}
                            sm={6}
                            md={6}
                            style={{ paddingTop: 33, padding: 7 }}
                        >
                            <fieldset style={{ backgroundColor: "#FFFFFF", borderRadius: 7, marginTop: 15 }}>
                                <legend>{translate("$__legends")}</legend>
                                <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ alignItems: "center", display: "flex" }}>
                                        <div style={{
                                            height: 10,
                                            width: 30,
                                            backgroundColor: "#02b915",
                                            borderRadius: 7,
                                            marginRight: 7
                                        }}></div>
                                        <div>{translate("$__completedStep")}</div>
                                    </div>
                                    <div style={{ alignItems: "center", display: "flex" }}>
                                        <div style={{
                                            height: 10,
                                            width: 30,
                                            backgroundColor: "#00a2e9",
                                            borderRadius: 7,
                                            marginRight: 7
                                        }}></div>
                                        <div>{translate("$__actualStep")}</div>
                                    </div>
                                    <div style={{ alignItems: "center", display: "flex" }}>
                                        <div style={{
                                            height: 10,
                                            width: 30,
                                            backgroundColor: "#e9e9e9",
                                            borderRadius: 7,
                                            marginRight: 7
                                        }}></div>
                                        <div>{translate("$__stepNotStarted")}</div>
                                    </div>
                                </div>
                            </fieldset>
                            {Object.keys(db.cards).filter(card =>
                                db.cards[card]?._createdBy?.channel
                                && db.cards[card]?._createdBy?._id === session._id
                            )
                                .sort((a, b) => {
                                    if (reduxStore.getState().db.cards[a].created_at > reduxStore.getState().db.cards[b].created_at)
                                        return -1
                                    if (reduxStore.getState().db.cards[a].created_at < reduxStore.getState().db.cards[b].created_at)
                                        return 1
                                    return 0
                                })
                                .map((card, i) => {
                                    return (<React.Fragment key={`ch${i}-${card}`}><NewCard db="cards" data={db.cards[card]} noShadow /></React.Fragment>)
                                })}
                        </GridItem>
                        : <React.Fragment></React.Fragment>}
                    {!showCards && showIn.map(sol => {
                        return (
                            <GridItem
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                                key={sol._id}
                                style={{ marginTop: "33px", cursor: "pointer", }}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                                        ...reduxStore.getState().control,
                                        addCard: {
                                            _parent: sol._id,
                                            rows: 0
                                        },
                                    })
                                }}
                            >
                                <div style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 7,
                                    height: 152,
                                    padding: 15,
                                    position: "relative",
                                    borderBottom: `solid 2px ${customApp('menu')} `
                                }}>
                                    <div style={{
                                        padding: 3,
                                        color: customApp("menu"),
                                        fontWeight: "bold",
                                        fontSize: 14
                                    }}>
                                        {sol.shortCutTitle}
                                    </div>
                                    <HtmlTooltip
                                        arrow
                                        title={(
                                            <React.Fragment>
                                                {sol.shortCutDescription}
                                            </React.Fragment>
                                        )
                                        }
                                    >
                                        <div
                                            style={{
                                                padding: 3,
                                                color: customApp("menu"),
                                                maxWidth: '100%',
                                                display: '-webkit-box',
                                                WebkitBoxOrient: 'vertical',
                                                WebkitLineClamp: 2,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize: 12
                                            }}
                                        >
                                            {sol.shortCutDescription}
                                        </div>
                                    </HtmlTooltip>
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: 15,
                                            left: 15,
                                            right: 15,
                                            padding: 3,
                                            color: customApp("menu"),
                                            fontWeight: "bold",
                                            fontSize: 16,
                                            textTransform: "uppercase"
                                        }}

                                    >
                                        {sol.shortCutButtonToAdd}
                                    </div>
                                </div>
                            </GridItem>
                        )
                    })}
                </GridContainer>
                : <React.Fragment></React.Fragment>}
        </div >
    )
}

const mapStateToProps = (store) => {
    return {
        store: {
            cards: store.db.cards,
        },
        hidePrivate: store.controls.hidePrivate,
    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
import React from "react"
import moment from "moment-timezone"

//REDUX
import reduxStore from "store/"

//COMPONENTS
import IconButton from "components/CustomButtons/IconButton"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import {
    translate,
    customApp,
} from "functions/"

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"

const useStyles = makeStyles({
    users: {
        position: 'relative',
        bottom: '0px',
        right: '2px',
        fontSize: '10px',
        '& .MuiAvatarGroup-root': {
            justifyContent: 'flex-end',
        },
        '& .MuiAvatar-root': {
            width: '25px',
            height: '25px',
            fontSize: '10px',
        }
    }
});

const Component = (props) => {
    const { session, functions } = reduxStore.getState();
    const { socket } = functions;
    const [data, setData] = React.useState(props.data)
    const classes = useStyles();

    React.useEffect(() => {
        setData(props.data)
    }, [props.data])

    const reactThis = async (react, d) => {
        socket.emit("data", {
            module: "social",
            method: "post",
            action: "interact"
        }, {
            react: react,
            user: session._id,
            nodeId: props.data._id,
            message: props.data._id,
            delete: d
        })

        let newData = data
        if (newData && newData._reactions && Object.keys(newData._reactions).length > 0)
            Object.keys(newData._reactions).filter(r => r !== react).forEach(r => {
                if (newData._reactions[r][session._id])
                    delete newData._reactions[r][session._id]
            })

        if (d && data && data._reactions && data._reactions[react] && data._reactions[react][session._id]) {
            let newData = data
            delete newData._reactions[react][session._id]
            setData(newData)
        } else {
            setData({
                ...data,
                _reactions: {
                    ...data._reactions,
                    [react]: {
                        [session._id]: {
                            ...session.displayName ? { displayName: session.displayName } : {},
                            ...session.name ? { name: session.name } : {},
                            ...session.image ? { image: session.image } : {},
                            reactDate: { low: moment().tz('America/Sao_Paulo').unix('X') }
                        }
                    }
                }
            })
        }
    }

    const ReactionComp = ({ react, color, primaryIcon, secondaryIcon }) => {
        if (props.hideDisables
            && data && react
            && data._reactions
            &&
            (
                !data._reactions[react]
                || (
                    data._reactions[react]
                    && Object.keys(data._reactions[react]).length === 0
                )
            )
        )
            return (<React.Fragment></React.Fragment>)

        return (
            <IconButton
                icon={data._reactions && data._reactions[react] && data._reactions[react][session._id] ? primaryIcon : secondaryIcon ? secondaryIcon : primaryIcon}
                iconColor={data._reactions && data._reactions[react] && data._reactions[react][session._id] ? color ? color : customApp("menu") : "lightgray"}
                size={"18px"}
                onClick={() => {
                    reactThis(react, data._reactions && data._reactions[react] && data._reactions[react][session._id] ? true : false)
                }}
                title={data._reactions && data._reactions[react] && Object.keys(data._reactions[react]).length > 0 ?
                    <React.Fragment>
                        <div style={{
                            position: "relative",
                            width: "100%",
                            paddingBottom: 3,
                            borderBottom: "1px solid lightgray",
                            marginBottom: "7px",
                        }}>{translate(`$__${react}`, 1)}</div>
                        <div className={classes.users}>
                            {Object.keys(data._reactions[react])
                                .sort((a, b) => {
                                    if (
                                        data._reactions[react][a].reactDate
                                        && String(data._reactions[react][a].reactDate.low)
                                        && data._reactions[react][b].reactDate
                                        && String(data._reactions[react][b].reactDate.low)
                                        && parseInt(data._reactions[react][a].reactDate.low) < parseInt(data._reactions[react][b].reactDate.low)
                                    ) {
                                        return -1
                                    } else if (
                                        data._reactions[react][a].reactDate
                                        && String(data._reactions[react][a].reactDate.low)
                                        && data._reactions[react][b].reactDate
                                        && String(data._reactions[react][b].reactDate.low)
                                        && parseInt(data._reactions[react][a].reactDate.low) > parseInt(data._reactions[react][b].reactDate.low)
                                    ) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .map((a, i) => {
                                    return (
                                        <div key={a} style={{ position: "relative", display: "flex", alignItems: "center", }}>
                                            <Avatar alt={data._reactions[react][a].name} src={data._reactions[react][a].image || defaultAvatar} />
                                            <span style={{ paddingLeft: "7px" }}>
                                                {data._reactions[react][a].displayName ? data._reactions[react][a].displayName : data._reactions[react][a].name}{data._reactions[react][a].reactDate && data._reactions[react][a].reactDate.low && ` (${moment(data._reactions[react][a].reactDate.low * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")})`}
                                            </span>
                                        </div>
                                    )
                                }
                                )}
                        </div>
                    </React.Fragment>
                    :
                    translate(`$__${react}`, 1)
                }
            />
        )
    }

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <div
                style={{
                    display: "flex",
                    minWidth: "fit-content",
                    justifyContent: "center"
                }}
            >
                <ReactionComp react="like" primaryIcon="thumb_up" secondaryIcon="thumb_up_off_alt" />
                <ReactionComp react="nolike" primaryIcon="thumb_down" secondaryIcon="thumb_down_off_alt" />
                <ReactionComp react="love" primaryIcon="favorite" secondaryIcon="favorite_border" color="red" />
                <ReactionComp react="approve" primaryIcon="verified" color="green" />
                <ReactionComp react="attention" primaryIcon="report_problem" color="red" />
                {props.children &&
                    <React.Fragment>
                        {props.children}
                    </React.Fragment>
                }
            </div>
        </div>

    );
}

export default Component
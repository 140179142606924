import React from "react";

// @material-ui/core components
import Chip from '@material-ui/core/Chip';
import { withStyles } from "@material-ui/core/styles";

const CustomChip = withStyles((theme) => ({
  root: {
    border: 'none',
    boxShadow: '0 3px 3px 3px rgba(0,0,0,0.05)',
    '& .MuiIcon-root': {
      color: 'rgba(250,250,250,0.33)'
    },
    '& .MuiIcon-root:hover': {
      color: 'rgba(250,250,250,0.77)'
    },
    padding: 0
  }
}))(Chip);

export default function Component(props) {
  const [over, setOver] = React.useState(false)
  return (
    <CustomChip
      style={{
        ...props.style ? props.style : {},
        ...!over ? {
          paddingLeft: 10,
          paddingRight: 10
        } : {}
      }}
      deleteIcon={props.deleteIcon ? props.deleteIcon : null}
      label={props.label}
      onMouseOver={() => { setOver(true) }}
      onMouseLeave={() => { setOver(false) }}
      onDelete={props.onDelete && over ? () => {
        props.onDelete()
      } : null}
      onClick={props.onClick ? (e) => {
        props.onDelete()
      } : null}
    />
  );
}



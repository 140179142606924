import MD5 from "crypto-js/md5";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/";

//API
import apims from "apims"

let ld = []

let newCards = {}

const loadCardsV2 = async (props, data) => {
    // const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false,
        allStatus = false,
        search = "",
        completedDays = 7,
        limit = 0,
        skip = 0,
        startDate = null,
        endDate = null,
        searchParams = null
    } = data

    let ignoreLoader = data.ignoreLoader ? true : false
    if (cardLoad === 'search' || props.ignoreLoader)
        ignoreLoader = true

    const { sessionLoads } = reduxStore.getState()
    let loads = {
        ...sessionLoads.cards.load
    }

    let reqMd5 = MD5(`${JSON.stringify(data)}`).toString()

    if (Object.keys(loads).length > 0 && loads[reqMd5])
        return true


    if (!ignoreLoader)
        props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
            ...reduxStore.getState().sessionLoads,
            cards: {
                ...reduxStore.getState().sessionLoads.cards,
                load: {
                    ...reduxStore.getState().sessionLoads.cards.load,
                    [reqMd5]: ignoreLoader ? "completed" : "loading",
                }
            }
        })

    let subtractDays = String(reduxStore.getState().session.dashboard.period).replace('$__last', '')
    subtractDays = subtractDays.replace('Days', '')


    const load = await new Promise(async (resolve) => {
        try {
            let cards = await apims.post(`/Card_List/`,
                {
                    //Parametros
                    idRel,
                    adminView,
                    load: cardLoad,
                    completed,
                    archived,
                    templates,
                    status,
                    allStatus,
                    limit,
                    skip,
                    startDate,
                    endDate,
                    ...completed ? {
                        completedDays: completedDays || subtractDays
                    } : {},
                    ...search ? { search: search } : {},
                    ...deleted ? { deleted: true } : {},
                    searchParams
                }
            )
            if (cards && cards.data) {
                let reqData = cards.data

                let mount = await mountCards(props, reqData, cardLoad)
                if (mount) {
                    props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                        ...reduxStore.getState().sessionLoads,
                        cards: {
                            ...reduxStore.getState().sessionLoads.cards,
                            load: {
                                ...reduxStore.getState().sessionLoads.cards.load,
                                [reqMd5]: "completed",
                            }
                        }
                    })
                    if (callback)
                        callback()
                    resolve({ total: reqData.length, cards: mount })
                }
            }

        } catch (e) {
            console.log('loadCards::ERR::')
            console.log(e)
        }
    })

    if (load) {
        props.reduxFunction("ASYNC", "preLoader", {
            ...reduxStore.getState().preLoader,
            cards: false
        })
        return load
    }
}

const reqCards = async (props, data) => {
    if (ld.indexOf(JSON.stringify(ld)) === -1) {
        ld.push(JSON.stringify(ld))
    }
    const {
        idRel,
        adminView = false,
        callback = null,
        cardLoad = "my",
        completed = false,
        status,
        deleted = false,
        archived = false,
        templates = false
    } = data

    let ignoreLoader = false

    if (cardLoad === 'search' || props.ignoreLoader)
        ignoreLoader = true

    const { sessionLoads } = reduxStore.getState()
    let loads = {
        ...sessionLoads.cards.load
    }

    let reqMd5 = MD5(`${JSON.stringify(data)}`).toString()
    if (Object.keys(loads).length > 0 && loads[reqMd5])
        return true


    props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
        ...reduxStore.getState().sessionLoads,
        cards: {
            ...reduxStore.getState().sessionLoads.cards,
            load: {
                ...reduxStore.getState().sessionLoads.cards.load,
                [reqMd5]: ignoreLoader ? "completed" : "loading",
            }
        }
    })
    const load = await new Promise(async (resolve, reject) => {
        try {
            let cards = await apims.post(`/Card_List/`,
                {
                    //Parametros
                    idRel,
                    adminView,
                    load: cardLoad,
                    completed,
                    archived,
                    templates,
                    status,
                    ...props.search ? { search: props.search } : {},
                    ...deleted || props.deleted ? { deleted: true } : {},
                }
            )
            if (cards && cards.data) {
                // if (cardLoad === 'approval') console.log(cards)

                let reqData = cards.data

                let mount = await mountCards(props, reqData)
                if (mount) {


                    props.reduxFunction("ASYNC", "SET_DB", {
                        ...reduxStore.getState().db,
                        cards: {
                            ...reduxStore.getState().db.cards,
                            ...newCards
                        },
                    }, () => {
                    });
                    // if (!ignoreLoader)
                    props.reduxFunction("ASYNC", "SET_SESSION_LOADS", {
                        ...reduxStore.getState().sessionLoads,
                        cards: {
                            ...reduxStore.getState().sessionLoads.cards,
                            load: {
                                ...reduxStore.getState().sessionLoads.cards.load,
                                [reqMd5]: "completed",
                            }
                        }
                    })
                    if (callback)
                        callback()
                    resolve(mount)
                }
            }

        } catch (e) {
            console.log('loadCards::ERR::')
            console.log(e)
        }
    })

    if (load) {
        newCards = {}
        props.reduxFunction("ASYNC", "preLoader", {
            ...reduxStore.getState().preLoader,
            cards: false
        })
        return load
    }
}

const mountCards = async (props, ndata = [], cardLoad) => {
    const { session } = reduxStore.getState()

    const filterCards = (d) => {
        if (d
            && d.data
            && d.data._id
            && (
                (!d.data.private || d.data.private === false)
                ||
                (
                    d.data.private
                    && (
                        d.users.filter(u => u._id === session._id).length > 0
                    )
                )
            )
        )
            return true
        return false
    }

    let mount = await new Promise((resolve, reject) => {
        let newCards = {}
        if (
            ndata
            && ndata.filter(d => filterCards(d)).length > 0
        ) {
            ndata.filter(d => filterCards(d)).forEach((d, i) => {
                let a = d.data._id
                let users = {}
                let groups = {}
                let showGroups = []
                if (d.users.length > 0) {
                    d.users
                        .filter(user =>
                            user._id
                        )
                        .forEach(user => {
                            let myDay = null
                            if (d && d.myDayUsers && d.myDayUsers.filter(a => a._id).length > 0) {
                                myDay = d.myDayUsers.filter(a =>
                                    a._id === user._id
                                ).length > 0 ?
                                    d.myDayUsers.filter(a =>
                                        a._id === user._id
                                    )[0].date
                                    : null
                            }
                            users = {
                                ...users,
                                [user._id]: {
                                    ...myDay ? { myDay } : {},
                                    ...user,
                                }
                            }
                        })
                }
                if (d.data.type === "step" && d.showGroups?.filter(group => group.value).length > 0) {
                    showGroups = d.showGroups.filter(group => group.value)
                }
                if (d.groups?.filter(group => group._id).length > 0) {
                    d.groups.filter(group => group._id).forEach(group => {
                        groups = {
                            ...groups,
                            [group._id]: {
                                ...group,
                            }
                        }
                    })
                }
                if (d && d.data && d.data.planId)
                    delete d.data.planId

                newCards = {
                    ...newCards,
                    [a]: {
                        ...reduxStore.getState().db.cards[a] || {},
                        ...reduxStore.getState().db.cards[a] && reduxStore.getState().db.cards[a]._selected ? { _selected: true } : {},
                        ...d.data,
                        _cardCode: d.cardCode,
                        _requireApproval: d.requireApproval || d.data.requireApproval || false,
                        _isFavorite: String(d.favorite) === 'false' ? true : false,
                        ...d.account ? { _account: MD5(d.account).toString() } : {},
                        ...d && d.createdBy && d.createdBy.filter(a => a._id).length > 0 ? { _createdBy: d.createdBy.filter(a => a._id)[0] } : {},
                        ...d.myDay ? { _myDay: d.myDay } : {},
                        ...d.parent ? { _parent: d.parent } : {},
                        ...d.plan ? { _planId: d.plan } : {},
                        ...d.users ? { _users: users } : {},
                        ...d.groups ? { _groups: groups } : {},
                        ...d.tags ? { _tags: d.tags } : {},
                        ...d.aspects ? { _aspects: d.aspects } : {},
                        ...d.inFlow ? { _hasStep: d.inFlow } : {},
                        ...d.data && d.data.status ? { status: d.data.status } : { status: "notStarted" },
                        ...showGroups && showGroups.length > 0 ? { showGroups: showGroups } : {},
                        _notifications: d.notifications,
                        ...d.parentCompleted
                            && d.parentCompleted.card
                            ?
                            {
                                _parentCompleted: d.parentCompleted,
                                status: "completed",
                                completed_at: {
                                    low: d.parentCompleted.date ? d.parentCompleted.date : d.updated_at && d.updated_at.low ? d.updated_at.low : null
                                }
                            } : {},

                        ...d.timer ? {
                            _kpiTimer: {
                                [d.timer._id]: {
                                    ...d.timer,
                                    userId: session._id
                                }
                            }
                        } : {},
                        ...d.parentPrivate ? { private: true } : {},
                        type: d.data.type || "task",
                    },
                }

                Object.keys(newCards[a]).forEach(d => {
                    if (d && d.substr(0, 1) && d.substr(0, 1) !== "_" && !isNaN(d.substr(0, 1))) {
                        newCards[a] = {
                            ...newCards[a],
                            [`f${d}`]: newCards[a][d]
                        }
                        delete newCards[a][d]
                    }
                }
                )
                // }
                if (i + 1 === ndata.filter(d => filterCards(d)).length)
                    resolve(newCards)
            })
        } else {
            resolve({})
        }
    })
    if (mount) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...mount
            },
        }, () => {
        });
        return mount
    }
}
const loadCards = async (props, idRel, adminView = false, callback = null, cardLoad = "my", completed = false, status, ignoreLoader = false) => {
    let res = await loadCardsV2(props, {
        idRel,
        adminView,
        callback,
        cardLoad,
        completed,
        status,
        ignoreLoader
    })
    if (res)
        return res
    return false
}

const loadFiles = async (props, nodeId, dbR, searchText, skip = null, limit = null) => {
    let db = dbR ? dbR : 'cards'
    let result = await new Promise(async (resolve, reject) => {
        if (typeof nodeId === 'string') {
            try {
                let reqFiles = await apims.post(`/M_Files_List/`,
                    {
                        nodeId,
                        skip,
                        limit,
                        searchText
                    }
                )
                if (reqFiles && reqFiles.data) {
                    let data = reqFiles.data
                    props.reduxFunction("ASYNC", "SET_DB", {
                        ...reduxStore.getState().db,
                        [db]: {
                            ...reduxStore.getState().db[db],
                            [nodeId]: {
                                ...reduxStore.getState().db[db][nodeId],
                                _files: {
                                    ...reduxStore.getState().db[db][nodeId]._files ? reduxStore.getState().db[db][nodeId]._files : {},
                                    ...data[nodeId],
                                },
                            }
                        }
                    });
                    resolve(true)
                }
            } catch (e) {
                console.group('loadFiles::ERR::')
                console.log(e)
                console.groupEnd()
                reject(true)
            }
        } else if (typeof nodeId === 'object') {
            try {
                let reqFiles = await apims.post(`/M_Files_List/`,
                    {
                        nodesIds: nodeId
                    }
                )
                if (reqFiles && reqFiles.data) {
                    let data = reqFiles.data
                    let cardsUpdate = {}
                    Object.keys(data).forEach(id => {
                        cardsUpdate = {
                            ...cardsUpdate,
                            [id]: {
                                ...reduxStore.getState().db[db][id],
                                _files: data[id],
                                _loadedFiles: true,
                            }
                        }
                    })
                    props.reduxFunction("ASYNC", "SET_DB", {
                        ...reduxStore.getState().db,
                        cards: {
                            ...reduxStore.getState().db.cards,
                            ...cardsUpdate
                        }
                    });
                    resolve(true)
                }
            } catch (e) {
                console.group('loadFiles::ERR::')
                console.log(e)
                console.groupEnd()
                reject()
            }
        } else {
            return true
        }
    })
    if (result)
        return true
}

const pageLoadCards = async (props, days = 7) => {
    let h = window.location.hash.split("/")
    h[0] = h[0].replace("#", "")
    const request = await new Promise(async (resolve, reject) => {
        if (window.location.pathname === '/calendar') {
            await loadCardsV2(props, {
                cardLoad: 'calendar',
                iniDate: moment().startOf('month').unix('x'),
                endDate: moment().endOf('month').unix('x')
            })
            resolve(true)
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'archived') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
                archived: true
            })
            await loadCardsV2(props, {
                cardLoad: 'plans',
                archived: true,
                completed: true,
                completedDays: days
            })
            resolve(true)
        }
        if (window.location.pathname === "/forms") resolve(true)

        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'completed') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
                completed: true,
                completedDays: days
            })
            resolve(true)
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'templates') {
            await loadCardsV2(props, {
                cardLoad: 'templates',
                templates: true
            })
            resolve(true)
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'admin') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
                adminView: true,
            })
            resolve(true)
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'deleted') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
                deleted: true
            })
            resolve(true)
        }

        //DASHBOARD DATA
        if ((window.location.pathname === '/d' || window.location.pathname === '/login' || window.location.pathname === '/') && (h[1] !== 'dayTasks')) {
            await loadCardsV2(props, {
                cardLoad: 'dashboard'
            })
            await loadCardsV2(props, {
                cardLoad: 'showIn'
            })
            await loadCardsV2(props, {
                cardLoad: 'channel'
            })
            resolve(true)
        }
        if (window.location.pathname === '/d' && h[1] === 'dayTasks') {
            await loadCardsV2(props, {
                adminView: true,
                cardLoad: 'myDay',
            })
            resolve(true)
        }
        if (window.location.pathname === '/d' && h[1] === 'ourTasks') {
            await loadCardsV2(props, {
                adminView: true,
                cardLoad: 'myDay',
            })
            resolve(true)
        }

        //PLANS DATA
        if (window.location.pathname === '/sp' && h[0] && h[0].length === 36) {
            await loadCardsV2(props, {
                idRel: h[0],
                cardLoad: 'plan',
            })
            await loadCardsV2(props, {
                idRel: h[0],
                cardLoad: 'plan',
                completed: true,
            })
            resolve(true)
        }

        //PLAN DATA
        if (window.location.pathname === '/sp' && h[0].length !== 36) {
            resolve(true)
        }

        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'archived') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
                archived: true
            })
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'templates') {
            await loadCardsV2(props, {
                cardLoad: 'templates',
            })
        }
        if (window.location.pathname === '/sp' && h[0].length !== 36 && h[1] === 'trash') {
            loadCardsV2(props, {
                cardLoad: 'deletedPlans',
                deleted: true,
                adminView: reduxStore.getState().session._access && reduxStore.getState().session._access['plan'] && reduxStore.getState().session._access['planAdmin'] ? true : false
            })
        }

        //Library
        if (window.location.pathname === '/library') {
            await loadCardsV2(props, {
                cardLoad: 'plans',
            })
            resolve(true)
        }

        //Calendar
        if (window.location.pathname === '/calendar') {
            await loadCardsV2(props, {
                cardLoad: 'calendar',
                startDate: null,
                endDate: null
            })
            resolve(true)
        }

        //COMPANY PROFILE
        if (
            window.location.pathname === '/p'
            || window.location.pathname === '/acc'
        ) {
            resolve(true)
        }

        //DATA USERS
        if (window.location.pathname === '/c') {
            if (h[0] === 'tab' && h[1] !== 'users') {
                await loadCardsV2(props, {
                    cardLoad: 'plans',
                })
            }
            resolve(true)
        }

        //DATA TASKS
        if (window.location.pathname === '/t') {
            if (!h[1] || (h[1] && h[1] === 'myTasks')) {
                //MY RESPONSIBLE TASKS DATA
                await loadCardsV2(props, {
                    cardLoad: 'myResponsible',
                })
                await loadCardsV2(props, {
                    cardLoad: 'myResponsible',
                    completed: true,
                    completedDays: days
                })
                resolve(true)

            }

            if (h[1] && (h[1] === 'myDay' || h[1] === 'ourDay')) {

                //MY DAY DATA
                await loadCardsV2(props, {
                    cardLoad: 'myDay',
                })
                await loadCardsV2(props, {
                    cardLoad: 'myDay',
                    completed: true,
                    completedDays: 180
                })
                resolve(true)
            }

            if (h[1] && h[1] !== 'myTasks' && h[1] !== 'myDay') {
                //ALL TASKS DATA
                await loadCardsV2(props, {
                    cardLoad: 'in',
                })
                await loadCardsV2(props, {
                    cardLoad: 'in',
                    completed: true,
                    completedDays: days
                })

                await loadCardsV2(props, {
                    cardLoad: 'myResponsible',
                })
                resolve(true)

            }
        }
        //TAGS DATA
        if (window.location.pathname === '/tags') {
            //ALL TASKS DATA
            if (h[0].length === 36 || reduxStore.getState().searchs['tagsView'])
                await loadCardsV2(props, {
                    cardLoad: 'tags',
                    idRel: h[0],
                    searchParams: reduxStore.getState().searchs['tagsView']
                })
            resolve(true)
        }

        //CALENDAR Admin
        if (window.location.pathname === '/calendar') {
            resolve(true)
        }
        //MASTER Admin
        if (window.location.pathname === '/masterAdmin' || window.location.pathname === '/plataformAdministrator') {
            resolve(true)
        }
        //SubscriptionsPages
        if (window.location.pathname === "/subscriptionUpgrade")
            resolve(true)

        //B&C
        if (
            h[0].length === 36
            && reduxStore.getState().db.cards
            &&
            (
                !reduxStore.getState().db.cards[h[0]] ||
                (reduxStore.getState().db.cards[h[0]]
                )
            )
        ) {
            if (reduxStore.getState().db.cards
                && reduxStore.getState().db.cards[h[0]]
                && h[0]
                && !reduxStore.getState().db.cards[h[0]]._loadedFeeds
            ) {
                if (h[1] === 'bright-and-connected') {
                    // await loadFeeds({ ...props, fnIdentification: 'Layout/pageDataRequest() - withHASH' }, h[0].length === 36 ? h[0] : null)
                    resolve(true)
                }
            }
        }
        resolve(true);
    })
    if (request)
        return true
}

export {
    reqCards,
    loadCards,
    loadCardsV2,
    loadFiles,
    mountCards,
    pageLoadCards,
}
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Chats from "components/Chat/chats"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import TextField from "components/TextField/"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

//FUNCTIONS
import {
    translate,
    customApp,
} from "functions/"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/chatMenu2204"

function Component(props) {
    const { chat } = reduxStore.getState()
    const [chatList, setChatList] = React.useState('chats')
    const [findName, setFindName] = React.useState("")
    const [expanded, setExpanded] = React.useState(false)
    const useStyles = makeStyles(styles)
    const classes = useStyles()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    React.useEffect(() => {
        setChatList('chats')
        setExpanded(props.expanded)
    }, [props.expanded])


    const openChat = async (nodeId, db) => {
        let chatDb = reduxStore.getState().db.cards[nodeId] ? 'cards' : 'users'
        setFindName("")
        if (reduxStore.getState().db[chatDb] && reduxStore.getState().db[chatDb][nodeId] && !reduxStore.getState().db[chatDb][nodeId]._loadTimeline) {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                db: db,
                nodeId: nodeId,
                loading: db === 'users' ? false : true,
            })
        } else {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                db: db,
                nodeId: nodeId,
                loading: false,
            })
        }
        props.subMenuExpanded(false)
        setChatList('chats')
    }

    return (
        <React.Fragment>

            <div className={expanded ? classes.menuExpanded : classes.menu} style={{ paddingTop: 5 }}>
                {
                    (
                        expanded &&
                        (
                            chatList === "internalGroups"
                            || chatList === "externalGroups"
                        )
                    )
                    &&
                    <div className={expanded ? classes.buttonColorExpanded : classes.buttonColor} style={{ marginTop: "-15px" }}>
                        <IconButton
                            text={expanded ? translate("$__newGroup", "*") : null}
                            title={!expanded ? translate("$__newGroup", "*") : null}
                            icon="add_box"
                            iconColor={"#FFFFFF"}
                            textColor={"#FFFFFF"}
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                    ...reduxStore.getState().sideModule,
                                    id: "new",
                                    activeModule: "cardEdit",
                                    db: "cards",
                                    data: {
                                        type: "chatGroup",
                                        status: "inProgress",
                                    }
                                })
                            }}
                        />
                    </div>
                }
                <div className={classes.button} style={{
                    display: "flex",
                    height: "46px",
                    marginBottom: "7px",
                    width: "100%"
                }}>
                    {expanded ?
                        <div className={classes.textField} style={{ width: "100%" }}>
                            <TextField
                                className={classes.noLabelLight}
                                label={translate("$__search", "*")}
                                variant={"standard"}
                                autoFocus
                                value={findName}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Icon color={customApp("colorText")}>search</Icon>
                                        </InputAdornment>
                                    ),
                                    onChange: e => {
                                        setFindName(e.target.value)
                                    },
                                }}
                            />
                        </div>
                        :
                        <IconButton
                            title={!expanded ? translate("$__search", "*") : null}
                            icon="search"
                            iconColor={findName !== "" ? customApp("colorText") : customApp("colorText")}
                            textColor={findName !== "" ? customApp("colorText") : customApp("colorText")}
                            onClick={() => {
                                props.subMenuExpanded(true)
                            }}
                        />
                    }
                </div>
                <div
                    style={{
                        position: "absolute",
                        top: !expanded ? 40 : 50,
                        bottom: 7,
                        overflowY: "auto",
                        overflowX: "hidden",
                        left: expanded ? 7 : 0,
                        paddingLeft: expanded ? 5 : 0,
                        right: 0,
                        paddingTop: 7
                    }}
                >
                    <Chats
                        expanded={expanded}
                        openChat={(nodeId) => openChat(nodeId, "cards")}
                        find={findName}
                        onLine={props.onLine}
                    />
                </div>

            </div>
        </React.Fragment >
    )
}

const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
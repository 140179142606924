import React, { useState } from 'react';

//COMPONENTES
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const MessageEditor = (props) => {
    const {edit, data, changeData, id, options} = props
    
        const [editorData, setEditorData] = useState({
            historyEditor: null
        })
    
        const editorStyle = {
            backgroundColor: '#fff',
            width:'100%',
            height:'100%',
            fontFamily:'Soleto, Roboto, Helvetica, Arial, sans-serif',
            border:'none',
            margin:'0px'
        }
        const toolbarStyleObject = {
            width:'100%',
            fontFamily:'Soleto, Roboto, Helvetica, Arial, sans-serif',
            border:'none',
            display: edit ? 'flex':'none',
            justifyContent:'flex-start',
            borderBottom:'2px solid #eeeeee',
            borderRadius: '5px',
        }
        return (
            <>
                <Editor 
                    toolbar={{
                        options:options ? options : ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                        inline: {
                            inDropdown: false,
                            options: ['bold', 'italic', 'underline'],
                        },
                        list:{
                            inDropdown: false,
                            options: ['unordered']
                        },
                        link:{
                            inDropdown: false,
                            options: ['link']
                        },
                    }}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbarStyle={toolbarStyleObject}
                    editorState={data?.messageD}
                    onEditorStateChange={(d) => {
                        changeData(draftToHtml(convertToRaw(d.getCurrentContent())), d, id)
                        setEditorData({
                            ...editorData,
                            message: d,
                        })
                    }}
                    hashtag={{
                        separator: ' ',
                        trigger: '#',
                    }}
                    editorStyle={editorStyle}
                    readOnly={!edit}
                />
            </>
        )
}
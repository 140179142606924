//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"

//FUNCTIONS
import { translate, customApp } from "functions/"

function ButtonViewOnline(props) {
    const { session } = reduxStore.getState()
    const [usersStatus, setUsersStatus] = React.useState(props.usersStatus)
    const [onLine, setOnLine] = React.useState(false)

    React.useEffect(() => {
        setUsersStatus(props.usersStatus)
        return () => {

        }
    }, [props.usersStatus])

    return (
        <React.Fragment>
            <CustomButton
                id={`chat_menu`}
                title={`${translate(`$__showOnlyOnline`, "*")} ${Object.keys(usersStatus.users).filter(u => usersStatus.users[u] && u !== session._id).length > 0 ? ` (${Object.keys(usersStatus.users).filter(u => usersStatus.users[u] && u !== session._id).length})` : ``}`}
                text={`${translate(`$__showOnlyOnline`, "*")} ${Object.keys(usersStatus.users).filter(u => usersStatus.users[u] && u !== session._id).length > 0 ? ` (${Object.keys(usersStatus.users).filter(u => usersStatus.users[u] && u !== session._id).length})` : ``}`}
                color={customApp("colorText")}
                icon={onLine ? "toggle_on" : "toggle_off"}
                onClick={() => {
                    setOnLine(a => !a)
                    if (props.onClick)
                        props.onClick(!onLine)
                }}
                size={"25px"}
                textSize={"11px"}
                transparent
            />
        </React.Fragment>
    )
}
const mapStateToProps = (store) => {
    return ({
        usersStatus: store.usersStatus && store.usersStatus ? store.usersStatus : {},
    })
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ButtonViewOnline)
import React from "react"

//REDUX
import reduxStore from "store"

//COMPONENTS
import Badge from "components/Icon/Badge"

//FUNCTIONS
import {
    customApp,
    getAppAccess,
    translate,
} from "functions/"
import {
    connectInMount,
    getUserAccess,
    planAccountVerify,
} from "functions/cards"

export default function ConnectedIn(props) {
    const { db, session } = reduxStore.getState()
    const connectedIn = connectInMount(props.id)

    let AppAccess = getAppAccess()

    if (Object.keys(connectedIn).length === 0)
        return (<React.Fragment></React.Fragment>)

    const returnConnections = Object.keys(connectedIn)
        .filter((a, i) => connectedIn[a].icon && connectedIn[a].type && connectedIn[a].name)
        .map((a, i) => {
            let connectedCardAccess = getUserAccess(a)
            return (
                <div style={{ marginRight: '10px', width: '100%' }} key={i}>
                    <div style={{ display: 'flex', cursor: 'pointer' }}
                        onClick={
                            (props.open
                                && (
                                    (
                                        (
                                            (AppAccess.planAdmin
                                                || AppAccess.planModerate
                                            )
                                            &&
                                            (
                                                db.cards[connectedIn[a]._id].type !== 'plan'
                                                || (
                                                    db.cards[connectedIn[a]._id].type === 'plan'
                                                    && planAccountVerify(connectedIn[a]._id)
                                                )
                                            )
                                        )
                                        || connectedCardAccess >= 1
                                    ) &&
                                    (
                                        !db.cards[connectedIn[a]._id].private ||
                                        (
                                            db.cards[connectedIn[a]._id].private &&
                                            db.cards[connectedIn[a]._id]._users &&
                                            db.cards[connectedIn[a]._id]._users[session._id]
                                        )
                                    )
                                )
                            )
                                ? (e) => {
                                    props.open(connectedIn[a]._id)
                                    e.stopPropagation()
                                } : null}
                    >
                        <Badge
                            overlap="rectangular"
                            icon={connectedIn[a].icon}
                            title={`${translate(`$__${connectedIn[a].type}`, '*')}: ${connectedIn[a].name}`}
                            size="16px"
                            color={customApp("color")}
                        />
                        <span style={{ marginLeft: '5px' }}>
                            {connectedIn[a].name}
                        </span>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            )
        }
        )
    return (
        <div style={{ width: 'calc(100% - 5px)' }} onClick={(e) => { e.stopPropagation() }}>
            {props.showLegend ?
                <fieldset style={{ marginBottom: '14px', width: '100%' }}>
                    <legend>{translate(props.legend || '$__connectedIn', 1)}</legend>
                    {returnConnections}
                </fieldset>
                : returnConnections
            }
            <div style={{ clear: "both" }}></div>
        </div>
    )
}
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import Card from "components/BrightAndConnected/socialCard"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import LinearProgress from "components/Progress/linear"


//FUNCTIONS
import apims from "apims"

function BrightAndConnectReadViews(props) {
    const { data } = props
    const total = 0
    const [hashs, setHashs] = React.useState([])
    const [links, setLinks] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const mounted = React.useRef(true)

    React.useEffect(() => {
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")
        if (JSON.stringify(Nhashs) !== JSON.stringify(hashs)) {
            setHashs(Nhashs)
        }
        setLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (hashs.length > 0 && mounted.current) {
            loadLink()
            if (hashs[2] && hashs[2].length !== 36) {
                setLoading(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hashs])

    const loadLink = async (p, l) => {
        if ((total === 0 || total > Object.keys(links).length)) {
            if (mounted.current)
                setLoading(true)
            try {
                const response = await apims.post("/Feed_Social", {
                    id: hashs[2],
                })

                let loadFeeds = {}
                if (response && response.data && response.data.links) {
                    loadFeeds = {
                        ...loadFeeds,
                        ...response.data.links
                    }
                    if (mounted.current)
                        setLinks({
                            ...links,
                            ...loadFeeds,
                        })
                    if (mounted.current)
                        setLoading(false)
                } else {
                    if (mounted.current)
                        setLoading(false)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    return (
        <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
        }}>
            <div style={{
                position: "absolute",
                top: data && (data.customTitle || data.name) ? 50 : 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: "0px 15px",
                overflow: "auto"
            }}
                id={"linkList"}
            >
                <GridContainer>
                    {Object.keys(links).length > 0 ?
                        Object.keys(links).sort((a, b) => {
                            if (links[a].isoDate && links[b].isoDate && links[a].isoDate < links[b].isoDate)
                                return 1
                            if (links[a].isoDate && links[b].isoDate && links[a].isoDate > links[b].isoDate)
                                return -1
                            return 0
                        }).map(lk => {
                            return (
                                <GridContainer
                                    key={lk}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <GridItem
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={6}
                                        xl={6}
                                    >
                                        <Card
                                            db="cards"
                                            data={links[lk]}
                                            onClick={() => { window.open(links[lk].link, "_blank") }}
                                        />
                                    </GridItem>
                                </GridContainer>
                            )
                        })
                        :
                        <GridContainer
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <GridItem
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                A feed solicitado não pode ser exibido
                            </GridItem>
                        </GridContainer>
                    }
                </GridContainer>
                {(loading) &&
                    <div style={{ position: "relative", width: "100%" }}>
                        <LinearProgress />
                    </div>
                }
            </div>
        </div>
    )
}
const mapStateToProps = store => ({
    store
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BrightAndConnectReadViews)

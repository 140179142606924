import React, { memo } from "react"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import ErrorBoundary from "components/ErrorBoundary"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Icon from "components/Icon"
import PercentualBar from "components/Percentual/bar"

//VIEWS

//DEPENDENCIES
import AccountData from "components/Account/edit"
import AccountPreffers from "components/Account/preffers"
import SubscriptionDetails from "components/Account/SubscriptionDetails"

//FUNCTIONS
import apims from "apims/";

import { translate, BytesConvert } from "functions/"

import {
    customApp,
} from "functions"

require('dotenv').config()

function App(props) {
    const { session, db } = reduxStore.getState()
    const [usedSpace, setUsedSpace] = React.useState(0)

    React.useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const scrollTimer = setTimeout(() => {
            document.getElementById("mainPannel").scrollTo(0, 0)
        }, 100)
        init()
        return () => {
            clearTimeout(scrollTimer)
        }
    }, [props.history])

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    if (!window.location.hash.split("/")[0].replace("#", "")) {
        history.push("sp#tab/home")
    }


    if (hashs[0] && hashs[0].length === 36 && !reduxStore.getState().db.cards[hashs[0]]) {
        history.push("/deniedAccess")
    }

    const init = async () => {
        try {
            const res = await apims.post('/Account_Space')
            if (res)
                setUsedSpace(parseInt(res.data))
        } catch (e) {
            console.log(e)
        }
    }
    let space = db.plans[0].settings.space
    let subscriptionPlan = db.plans[0]
    if (
        session
        && session.GlobalData
        && String(session.GlobalData.plan)
        && db.plans[session.GlobalData.plan]
        && db.plans[session.GlobalData.plan].settings
    ) {
        space = db.plans[session.GlobalData.plan].settings.space
        subscriptionPlan = db.plans[session.GlobalData.plan]
    }

    let percent = usedSpace * (100 / parseInt(space))



    return (
        <div>
            {/* <div style={{
                position: "absolute",
                left: 0,
                top: 0,
                right: 0,
                padding: 7,
                backgroundColor: "#FFFFFF",
                borderRadius: 2,
                boxShadow: "0px 0px 0px 2px rgba(0,0,0,0.1)"
            }}> */}
            <GridContainer
                style={{
                    marginBottom: 15,
                    backgroundColor: "#FFFFFF",
                    borderRadius: 2,
                    boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                }}
            >
                <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <div
                            style={{
                                padding: 10,
                                background: customApp("AvatarBackground"),
                            }}
                        >
                            <Icon icon="card_membership" size={30} color={"#FFFFFF"} />
                        </div>
                        <div style={{ marginLeft: 7, width: "calc(100% - 40px)" }}>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    color: customApp("menu"),
                                    fontWeight: "bolder",
                                    marginTop: 7,
                                }}>{translate("$__subscriptionPlan", '*')}: {translate(subscriptionPlan.label, '*')}</div>
                            <div style={{
                                width: "100%",
                                color: "mediumgray",
                                fontSize: 12,
                                fontWeight: "bold"
                            }}>{translate("$__adminLimit", 1)}: {subscriptionPlan.settings.admins}</div>
                            {/* <a
                                href="#"
                                style={{
                                    position: "relative",
                                    top: '-7px',
                                    color: customApp("color"),
                                    fontSize: 10,
                                    fontWeight: 'bold'
                                }}>{translate("$__viewContract")}</a> */}
                        </div>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <div
                            style={{
                                padding: 10,
                                background: customApp("AvatarBackground"),
                            }}
                        >
                            <Icon icon="storage" size={30} color={"#FFFFFF"} />
                        </div>
                        <div style={{ marginLeft: 7, width: "calc(100% - 40px)" }}>
                            <div
                                style={{
                                    position: "relative",
                                    marginTop: 7,
                                    color: customApp("menu"),
                                    fontWeight: "bolder"
                                }}>{translate("$__usedStorage", "*")}<span style={{ marginLeft: 15, color: "red", fontSize: 11, cursor: "pointer" }} onClick={() => {
                                    history.push("#tab/subscriptionUpgrade")
                                }}>{translate("$__upgradeSubscription")}</span>
                            </div>
                            <PercentualBar
                                value={Math.ceil(percent)}
                                background={percent > 100 ? "red" : null}
                            />
                            <div>
                                <span style={{
                                    color: percent > 100 ? "red" : "mediumgray",
                                    fontSize: 12,
                                    fontWeight: "bold"
                                }}>{translate("$__used", 1)}: {BytesConvert(usedSpace)}</span> <span style={{ color: "lightgray", fontSize: 11 }}>{translate("$__of")} {translate("$__accountSpace", 1)}: {BytesConvert(space)}</span>
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>

            {/* </div > */}
            <GridContainer
                style={{
                    // marginTop: 15
                }}
            >

                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>Dados da conta</div>
                        <ErrorBoundary>
                            <AccountData data={session && session.GlobalData ? session.GlobalData : {}} />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        Preferências
                        <ErrorBoundary>
                            <AccountPreffers data={session && session.GlobalData ? session.GlobalData : {}} />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        {translate("$__subscriptionDetails")}
                        <ErrorBoundary>
                            <SubscriptionDetails data={session && session.GlobalData ? session.GlobalData : {}} planDetails={subscriptionPlan} />
                        </ErrorBoundary>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(App))
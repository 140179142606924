import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//DEPENDENCIES
import Column from "./Column";

//FUNCTIONS
import { selectSWOT } from "functions/cards";

function App(props) {
    const { db } = reduxStore.getState()

    React.useEffect(() => {
        selectSWOT(props)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDragEnd = async result => {
        const { socket } = reduxStore.getState().functions
        const { destination, source, draggableId } = result

        if (result.combine) {
            return;
        }


        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const sourceColumn = source.droppableId
        const destinationColumn = destination.droppableId

        const sourceCardIds = Array.from(props.cards.filter(c => c.type === sourceColumn && !c.deleted).sort((a, b) => {
            if (a.order && b.order && a.order.low && b.order.low && parseInt(a.order.low) < parseInt(b.order.low)) {
                return -1
            } else if (a.order && b.order && a.order.low && b.order.low && parseInt(a.order.low) > parseInt(b.order.low)) {
                return 1
            }
            return 0
        }
        ).map(a => a._id))


        //When move Card in single Column
        if (sourceColumn === destinationColumn) {
            const newCardsIds = Array.from(props.cards.filter(c => c.type === sourceColumn && !c.deleted).sort((a, b) => {
                if (a.order && b.order && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
                    return -1
                } else if (a.order && b.order && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) > parseInt(b.order.low)) {
                    return 1
                } else {
                    return 0
                }
            }
            ).map(a => a._id))
            newCardsIds.splice(source.index, 1)
            newCardsIds.splice(destination.index, 0, draggableId)

            let newCardsOrder = {}
            newCardsIds.forEach((card, i) => {
                newCardsOrder[card] = {
                    ...db.cards[card],
                    order: {
                        low: i
                    }
                }
            })

            const newState = {
                ...reduxStore.getState().db,
                cards: {
                    ...db.cards,
                    ...newCardsOrder,
                }
            }
            props.reduxFunction("ASYNC", "SET_DB", {
                ...newState,
            });
            socket.emit("data", {
                module: "cards",
                method: "put",
                action: "inColumn",
            }, {
                node: draggableId,
                source: source.droppableId,
                destination: destination.droppableId,
                destinationCards: newCardsIds,
                columnName: 'type',
                sourceCards: [],
            })
            return
        }

        //When move Card in mult Column
        sourceCardIds.splice(source.index, 1)


        const destinationCardIds = Array.from(props.cards.filter(c => c.type === destinationColumn).sort((a, b) => {
            if (a.order && b.order && a.order.low && b.order.low && parseInt(a.order.low) < parseInt(b.order.low))
                return -1
            return 1
        }
        ).map(a => a._id))
        destinationCardIds.splice(destination.index, 0, draggableId)

        let cards = {
            ...db.cards
        }
        sourceCardIds.forEach((a, i) => {
            cards = {
                ...cards,
                [a]: {
                    ...cards[a] ? cards[a] : {},
                    order: {
                        low: i
                    }
                }
            }
            //cards[a].order.low = i
        })

        destinationCardIds.forEach((a, i) => {
            cards = {
                ...cards,
                [a]: {
                    ...cards[a] ? cards[a] : {},
                    order: {
                        low: i
                    }
                }
            }
        })

        const newState = {
            ...reduxStore.getState().db,
            cards: {
                ...cards,
                [draggableId]: {
                    ...cards[draggableId],
                    type: destinationColumn,
                    order: {
                        low: destination.index
                    }
                }
            }
        }

        // console.log({
        //     [draggableId]: {
        //         ...db.cards[draggableId],
        //         type: destinationColumn,
        //         order: {
        //             low: destination.index
        //         }
        //     }
        // })
        props.reduxFunction("ASYNC", "SET_DB", {
            ...newState,
        });


        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "inColumn",
        }, {
            node: draggableId,
            source: source.droppableId,
            destination: destination.droppableId,
            destinationCards: destinationCardIds,
            columnName: 'type',
            sourceCards: sourceCardIds,
        })
    }

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Droppable
                droppableId="ALL-COLUMNS-STATUS"
                type="column"
                direction={`${props.inLine ? 'vertical' : 'horizontal'}`}
            >
                {provided => (
                    <Container
                        {...props}
                        ref={provided.innerRef}
                    >
                        {db.swot.map((st, index) => {
                            const theColumn = {
                                name: st.label,
                                icon: st.icon,
                                cards: [],
                                emptyHide: false,
                            }

                            return <Column
                                {...props}
                                key={`${st.value}`}
                                columnId={`${st.value}`}
                                column={theColumn}
                                cards={props.cards.filter(c => c.type === st.value).sort((a, b) => {
                                    let orderA = 0
                                    let orderB = 0
                                    if (a.order && String(a.order.low))
                                        orderA = a.order.low
                                    if (b.order && String(b.order.low))
                                        orderB = b.order.low
                                    if (parseInt(orderA) < parseInt(orderB)) {
                                        return -1
                                    } else if (parseInt(orderA) > parseInt(orderB)) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                }
                                ).map(a => {
                                    return {
                                        ...a,
                                        id: a._id
                                    }
                                })}
                                index={index}
                                columnsSettings={false}
                                columnStyle={{
                                    minWidth: '300px'
                                }}
                                viewButton={props.viewButton || false}
                                locked={props.lockColumns && props.lockColumns.indexOf(st.value) === -1 ? true : false}
                                manualOrder
                                isDropDisabled
                            />
                        })}
                        {provided.placeholder}

                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    )
}

//STYLEDS
const Container = styled.div`
${props =>
        !props.inLine && props.styles && props.styles.discountHeight && `height:calc(100vh - ${props.styles.discountHeight}px);`
    }
${props =>
        props.inLine ?
            `display: block;
                width: -webkit-fill-available;
                ${props.style}`
            :
            `   display: flex;
                width: 100%;
                ${props.style}
                @media(min-width: 960px) {
                    display: flex;
                }
                `
    }
    @media(max-width: 959px) {
        display: contents !important;
        width: -webkit-fill-available;
        height:auto;
      }
    `

//REACT
const mapStateToProps = (store) => ({
    store: {
        cards: store.db.cards,
    }
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { customApp } from "functions/";

export default function CustomizedButtons(props) {
    const useStyles = makeStyles({
        root: {
            boxShadow: `0 2px 2px 0px rgba(0,0,0,0.2)`,
            textTransform: 'none',
            padding: '6px 12px',
            border: '1px solid',
            lineHeight: 1.5,
            backgroundColor: customApp(props.color ? props.color : 'color'),
            borderColor: customApp(props.color ? props.color : 'color'),
            color: customApp('lightText'),
            '&:hover': {
                backgroundColor: customApp(props.color ? props.color : 'color', 0.8),
                borderColor: customApp(props.color ? props.color : 'color'),
                boxShadow: `0 0 0 0.2rem ${customApp(props.color ? props.color : 'color', 0.2)}`,
            },
            '&:active': {
                boxShadow: 'none',
                backgroundColor: customApp(props.color ? props.color : 'color'),
                borderColor: customApp(props.color ? props.color : 'color'),
            },
            '&:focus': {
                boxShadow: `0 0 0 0.2rem ${customApp(props.color ? props.color : 'color', 0.2)}`,
            },
        },
    });
    const classes = useStyles();

    const defaultColors = ['default', 'inherit', 'primary', 'secondary']
    if (!props.color || (props.color && defaultColors.indexOf(props.color) === -1)) {
        return (
            <Button
                className={classes.root}
                variant="contained"
                color={props.color ? props.color : "primary"}
                style={props.style ? props.style : {}}
                onClick={props.onClick ? () => props.onClick() : null}
            >
                {props.children}
            </Button>
        );
    } else {
        return (
            <Button
                variant={props.variant ? props.variant : "contained"}
                color={"primary"}
                style={props.style ? props.style : {}}
                onClick={props.onClick ? () => props.onClick() : null}
            >
                {props.children}
            </Button>
        );
    }
}
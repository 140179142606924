//@MATERIAL 
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";

//FUNCTIONS
import { customApp } from "functions/";

//STYLES
import {
    defaultFont,
} from "assets/jss/material-dashboard-pro-react";
const Component = withStyles({
    root: {
        ...defaultFont,
        padding: 0,
        width: "100%",
        marginTop: "7px",
        marginBottom: "7px",
        '& .invalid': {
            borderBottom: 'solid 2px red !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: "11px 11px",
            fontSize: "12px",
            color: '#FFFFFF',
        },
        '& label': {
            marginTop: "-10px",
            fontSize: "12px",
            color: customApp('color'), //customApp
        },
        '& label.MuiFormLabel-filled': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : 0
        },
        '& label.Mui-focused': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : 0
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customApp('line'), //customApp
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&.Mui-focused fieldset': {
                borderColor: customApp('line'), //customApp
            },
        },
        '& .MuiFilledInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '0px',
            // padding: "15px",
            '&.Mui-focused': {
                marginTop: '0px',
            },
            '&.MuiFormLabel-filled': {
                marginTop: '0px',
            }
        },
        '& .MuiInputBase-root': {
            marginTop: customApp('fieldVariant') === 'standard' ? 0 : customApp('fieldVariant') === 'filled' ? "-15px" : 0,
            borderRadius: customApp('fieldRadius'),
            color: '#FFFFFF',
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
    },
})(TextField);

export default Component
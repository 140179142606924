import React, { useState } from 'react'
import { customApp, translate } from 'functions';

const IntroTour = ({ handleStart, handleEnd }) => {

    const [buttonHover, setButtonHover] = useState(false)
    const [buttonHover1, setButtonHover1] = useState(false)
    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        zIndex: 9999
    }

    const contentStyle = {
        backgroundColor: '#fff',
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        margin: '10% auto',
        padding: '20px',
        border: '1px solid #888',
        width: '80%',
        TextAlign: "center",
        borderRadius: "5px",
        maxWidth: '400px'
    }

    const buttonStyle = {
        width: '90%',
        height: '50px',
        backgroundColor: customApp('menu'),
        color: customApp('color'),
        fontWeight: "bold",
        borderRadius: '5px',
        filter: buttonHover ? 'brightness(0.90)' : 'brightness(1)',
        marginRight: '10px',
        cursor: 'pointer',
        border: '1px solid grey'
    }

    const headingStyle = {
        display: "flex",
        fontSize: '26px',
        fontWeight: 'bold',
        justifyContent: "center"
    }

    const primaryButtonStyle = {
        ...buttonStyle,
        backgroundColor: 'white',
        filter: buttonHover1 ? 'brightness(0.98)' : 'brightness(1)',
        color: "black",
    }


    return (
        <div style={modalStyle}>
            <div style={contentStyle}>
                <div style={{ display: 'flex', flexFlow: "column", alignItems: 'center', marginBottom: '20px' }}>
                    <h2 style={headingStyle}> {translate("$__tourTitleIntro")}</h2>
                    <p style={{ width: "240px", fontWeight: "bolder", textAlign: "center", fontSize: "19px", marginBottom: '30px' }}>
                        {translate("$__tourIntro")}
                    </p>
                    <button onMouseEnter={() => { setButtonHover(true) }}
                        onMouseLeave={() => { setButtonHover(false) }} onClick={handleStart} style={buttonStyle}>{translate("$__letsStart")}</button>
                    <div style={{ width: '90%', borderBottom: '1px solid grey', display: 'flex', justifyContent: 'center', marginBottom: '25px', marginTop: '25px', position: 'relative' }}>
                        <span style={{ top: '-10px', position: 'absolute', backgroundColor: 'white', paddingRight: '10px', paddingLeft: '10px' }}> {translate("$__or")}</span>
                    </div>
                    <button onMouseEnter={() => { setButtonHover1(true) }}
                        onMouseLeave={() => { setButtonHover1(false) }} onClick={handleEnd} style={primaryButtonStyle}><p style={{ marginTop: '10px' }} >{translate("$__skipTour", "*")}</p></button>
                </div>
            </div>
        </div>
    );
}

export default IntroTour
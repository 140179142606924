//REDUX
import reduxStore from "store/";

//FUNCTIONS
require('dotenv').config()

const nodeUpdate = async (props, data) => {
    let { db } = reduxStore.getState()
    let nodeType = 'cards'
    if (db.cards[data._id]) {
        nodeType = 'cards'
    } else if (db.users[data._id]) {
        nodeType = 'users'
    }
    if (reduxStore.getState().db
        && reduxStore.getState().db[nodeType]
        && reduxStore.getState().db[nodeType][data._id]
    ) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            [nodeType]: {
                ...reduxStore.getState().db[nodeType],
                [data._id]: {
                    ...reduxStore.getState().db[nodeType][data._id],
                    ...data
                }
            }
        });
    }
}

export {
    nodeUpdate,
}
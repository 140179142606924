//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"
import history from "store/history"

//COMPONENTS
import Buttom from "components/CustomButtons/Custom"
import CircularProgress from "components/Progress/circular"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import TextField from "components/TextField/"
import Icon from "components/Icon"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import InputAdornment from '@material-ui/core/InputAdornment'

//FUNCTIONS
import { translate, customApp, appAlert, deleteNode } from "functions/"
import { getChildrens, deleteCard } from "functions/cards"

import apims from "apims"

function Component(props) {
    const { sideModule, db, functions } = reduxStore.getState()
    const { socket } = functions
    const [migrateComplete, setMigrateComplete] = React.useState(false)
    const [Load, setLoading] = React.useState(false)
    const [SelectedUser, setSelectedUser] = React.useState(null)
    const [childrensCards, setChildrensCards] = React.useState({})
    const [countCards, setCountCards] = React.useState(0)
    const [findNameUser, setFindNameUser] = React.useState("")
    const data = sideModule.data || {}
    let countChildrens = 0

    if (data && data.type === "step") {
        countChildrens = Object.keys(reduxStore.getState().db.cards).filter(card =>
            reduxStore.getState().db.cards[card]._parent === data._id
            && !reduxStore.getState().db.cards[card].deleted
        ).length
    }

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    React.useEffect(() => {
        if (data.type === 'user') {
            loadUserCards()
        } else {
            setLoading(false)
        }
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        iniChildrens()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data._id])

    const iniChildrens = async () => {
        let cards = await getChildrens(data._id)
        if (cards)
            setChildrensCards(cards)
    }

    const deleteThis = async () => {
        if (countChildrens > 0) {
            appAlert({
                message: translate("$__beforeRemovingYouneedToMoveAllActivitiesInTheColumn", 1),
                variant: "warning",
                persist: false,
                horizontal: "center",
            })
            return
        }
        appAlert({
            message: translate("$__confirmDeleteThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                deleteCard(props, { _id: data._id }, false)
                deleteNode(props, { _id: data._id }, false)
                props.reduxFunction("ASYNC", "CLEAR_MODULE");
                if (data.type === 'plan' && window.location.pathname.indexOf("sp") > -1 && hashs.length > 0 && hashs[0] && hashs[0].length === 36)
                    history.push(`sp`)
            }
        })
    }

    const deleteAll = async () => {
        appAlert({
            message: translate("$__confirmDeleteThisAndAllLinkedsActivies", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                deleteCard(props, { _id: data._id }, false, true)
                props.reduxFunction("ASYNC", "CLEAR_MODULE");
                if (data.type === 'plan' && window.location.pathname.indexOf("sp") > -1 && hashs.length > 0 && hashs[0] && hashs[0].length === 36)
                    history.push(`sp`)
            }
        })
    }

    const loadUserCards = async () => {
        setLoading(false)
        const countChildrens = await apims.post("/Card_Count", { id: data._id, all: true, rel: "INN" })
        if (countChildrens) {
            setLoading(true)
            setCountCards(countChildrens.data)
        }

    }

    const migrate = async () => {
        setLoading(true)

        socket.emit("data", {
            module: "user",
            method: "post",
            action: "migrate"
        }, {
            from: data._id,
            fromData: data,
            to: SelectedUser,
            toData: db.users[SelectedUser]
        }, async () => {
            setMigrateComplete(true)
            let cards = { ...db.cards }
            Object.keys(cards).filter(a =>
                cards[a]._users && cards[a]._users[data._id]
            ).forEach(a => {
                if (cards[a]._users && !cards[a]._users[SelectedUser])
                    cards[a]._users = {
                        ...cards[a]._users || {},
                        [SelectedUser]: {
                            access: cards[a]._users[data._id].access,
                            _id: SelectedUser
                        }
                    }
                delete cards[a]._users[data._id]
            })
            setLoading(true)
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <SideModuleCloseAndSave
                close={() => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE");
                }}
            />
            {data._id && db.cards[data._id] &&
                <React.Fragment>
                    <fieldset style={{ textAlign: "center" }}>
                        <legend style={{ color: customApp("color") }}>{translate("$__deleteOptions", 1)}</legend>
                        <fieldset style={{ textAlign: "center" }}>
                            <div style={{ textAlign: "center", width: "100%" }}>
                                {data.type === "chatGroup" ? translate("$__deleteThisChatGroup", 1) : translate("$__deleteThisActivity", 1)}
                            </div>
                            <Buttom onClick={() => { deleteThis() }} >{translate("$__delete", 1)}</Buttom>
                        </fieldset>
                        {data.type !== "step" && Object.keys(childrensCards).length > 0 &&
                            <fieldset style={{ textAlign: "center" }}>
                                <legend style={{ color: customApp("color") }}>{translate("$__thisActivityHasOtherLinkedsActivities", 1)}</legend>
                                <div style={{ textAlign: "center", width: "100%", color: 'red' }}>
                                    {translate("$__deleteAllActiviesLinkedAlert", 1)}
                                </div>
                                <Buttom onClick={() => { deleteAll() }} >{translate("$__deleteAll", 1)}</Buttom>
                            </fieldset>
                        }
                    </fieldset>

                </React.Fragment>
            }

            {/* DELETE CLIENT */}
            {data._id && db.users[data._id] &&
                (
                    db.users[data._id].type === 'client' ||
                    db.users[data._id].type === 'group' ||
                    db.users[data._id].type === 'sic' ||
                    db.users[data._id].type === 'unity'
                ) &&
                <fieldset style={{ textAlign: "center" }}>
                    <legend style={{ color: customApp("color") }}>{translate("$__deleteOptions", 1)}</legend>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {Object.keys(db.users).filter(a => db.users[a]._parent === data._id).length > 0 ?
                            translate(`$__delete${db.users[data._id].type}WithUsersDescription`, 1)
                            :
                            translate(`$__delete${db.users[data._id].type}NoUsersDescription`, 1)
                        }
                    </div>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {translate("$__deleteThisDescription", 1)}
                    </div>
                    <Buttom onClick={() => { deleteThis() }} >{translate("$__deleteThis", 1)}</Buttom>
                </fieldset>
            }

            {/* DELETE USER */}
            {data._id && db.users[data._id] &&
                db.users[data._id].type !== 'client' &&
                db.users[data._id].type !== 'group' &&
                db.users[data._id].type !== 'sic' &&
                db.users[data._id].type === 'unity' &&
                <React.Fragment>
                    {Load ?
                        <React.Fragment>
                            {countCards > 0 && !migrateComplete ?
                                <React.Fragment>

                                    <fieldset>
                                        <legend style={{ color: customApp("color") }}>{translate("$__thisUserHas", 1)}</legend>
                                        <div style={{
                                            position: "relative",
                                            marginTop: "22px",
                                            bottom: "7px",
                                            width: "100%",
                                            fontSize: "12px",
                                            color: customApp('mediumText'),
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}>
                                            {countCards} {translate("$__items")}
                                            <br></br>
                                            {translate("$__deleteCountCardsExplain")}
                                        </div>
                                    </fieldset>
                                    {SelectedUser ?
                                        <fieldset>
                                            <legend>{translate('$__selectedUserToMigrationCards')}</legend>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '7px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                onClick={() => {
                                                    setSelectedUser(null)
                                                }}
                                            >
                                                <Avatar alt={db.users[SelectedUser].name} src={db.users[SelectedUser].image || null}
                                                    style={{ marginRight: '15px' }}
                                                >
                                                    {db.users[SelectedUser].name.substr(0, 1)}
                                                </Avatar>
                                                <span>{db.users[SelectedUser].name}</span>
                                            </div>
                                            <Buttom onClick={() => {
                                                appAlert({
                                                    message: translate('$__confirmUserTransferData', 1),
                                                    autoClose: 50000,
                                                    buttons: [
                                                        {
                                                            shadow: true,
                                                            title: translate("$__confirm"),
                                                            text: translate("$__confirm"),
                                                            color: "green",
                                                            icon: "done_all",
                                                            size: "25px",
                                                            style: { marginLeft: 15 },
                                                            onClick: () => {
                                                                migrate()
                                                            },
                                                        },
                                                        {
                                                            shadow: true,
                                                            title: translate("$__cancel"),
                                                            text: translate("$__cancel"),
                                                            color: "red",
                                                            icon: "close",
                                                            size: "25px",
                                                            style: { marginLeft: 15 },
                                                            onClick: () => {

                                                            },
                                                        }],
                                                    variant: 'warning',
                                                    persist: false,
                                                    horizontal: 'right',
                                                })

                                            }} >{translate("$__migrate", 1)}</Buttom>
                                        </fieldset>
                                        :
                                        <fieldset
                                            style={{ marginTop: 7, marginBottom: 7 }}
                                        >
                                            <legend>{translate('$__chooseAUserToTransferCardsBeforeDeletingUser')}</legend>
                                            <TextField
                                                label={translate('$__search', '*')}
                                                variant={'standard'}
                                                autoFocus
                                                value={findNameUser}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icon color={customApp('color')}>search</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    onChange: e => {
                                                        setFindNameUser(e.target.value)
                                                    },
                                                }}
                                            />
                                            {Object.keys(db.users)
                                                .filter(a =>
                                                    // a === a
                                                    a !== data._id
                                                    && db.users[a].type === 'user'
                                                    && !db.users[a].deleted
                                                    && (
                                                        (!data._parent
                                                            &&
                                                            !db.users[a]._parent
                                                        )
                                                        || (
                                                            data._parent
                                                            && db.users[a]._parent === data._parent
                                                        )
                                                    )
                                                    && (
                                                        !findNameUser || (
                                                            findNameUser && db.users[a].name.toLowerCase().indexOf(findNameUser.toLowerCase().trim()) > -1
                                                        )
                                                    )
                                                )
                                                .sort((a, b) => {
                                                    var nameA = db.users[a].name.toLowerCase()
                                                    var nameB = db.users[b].name.toLowerCase()
                                                    if (nameA < nameB)
                                                        return -1
                                                    if (nameA > nameB)
                                                        return 1
                                                    return 0
                                                })
                                                .map(pr =>
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginBottom: '7px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={() => {
                                                            setSelectedUser(pr)
                                                        }}
                                                        key={pr}
                                                    >
                                                        <Avatar alt={db.users[pr].name} src={db.users[pr].image || null}
                                                            style={{ marginRight: '15px' }}
                                                        >
                                                            {db.users[pr].name.substr(0, 1)}
                                                        </Avatar>
                                                        <span>{db.users[pr].name}</span>
                                                    </div>
                                                )}
                                        </fieldset>
                                    }
                                    <fieldset style={{ textAlign: "center" }} >
                                        <legend style={{ color: customApp("color") }}>{translate("$__deactivateThis", 1)}</legend>
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            {translate("$__deactivateWhithoutMigration", 1)}
                                        </div>
                                        <Buttom onClick={() => { deleteThis() }} >{translate("$__deactivateThis", 1)}</Buttom>
                                    </fieldset>
                                </React.Fragment>
                                :
                                <fieldset style={{ textAlign: "center" }} >
                                    <legend style={{ color: customApp("color") }}>{translate("$__deactivateThis", 1)}</legend>
                                    <div style={{ textAlign: "center", width: "100%" }}>
                                        {translate("$__deactivateUserDescription", 1)}
                                    </div>
                                    <Buttom onClick={() => { deleteThis() }} >{translate("$__deactivateThis", 1)}</Buttom>
                                </fieldset>
                            }

                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    {Load ?
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '33px'
                        }}>
                            <CircularProgress />
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
            }
        </div >
    )
}
const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import {
  defaultFont,
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const styles = (theme) => ({
  ...defaultFont,
  menu: {
    position: 'relative',
    borderRight: customApp('color'),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menuExpanded: {
    position: 'relative',
    borderRight: customApp('color'),
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  button: {
    padding: '7px 0px',
    display: "flex",
    justifyContent: "center"
  },
  buttonExpanded: {
    padding: '7px 0px',
    display: "flex",
    justifyContent: "left",
    width: "100%"
  },
  buttonColor: {
    position: 'relative',
    float: 'left',
    width: 'calc(100% + 20px)',
    padding: '20px 12px',
    marginLeft: '-12px',
    display: "flex",
    justifyContent: "center",
    backgroundColor: customApp('color')
  },
  buttonColorExpanded: {
    position: 'relative',
    float: 'left',
    width: 'calc(100% + 20px)',
    padding: '12px 12px 12px 20px',
    marginLeft: '-12px',
    display: "flex",
    backgroundColor: customApp('color')
  },
  separator: {
    position: 'relative',
    float: 'left',
    width: '100%',
    padding: '7px',
    borderTop: `1px solid ${customApp('medium', 0.5)}`,
    marginTop: '15px',
    marginBottom: '0px',
    height: '1px'
  }
});

export default styles;

//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import history from "store/history"

//COMPONENTS
import Icon from "components/Icon"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";

import {
    BytesConvert,
    customApp,
    translate,
} from "functions/"

import 'react-credit-cards/es/styles-compiled.css';

const useStyles = makeStyles({
    list: {
        position: "relative",
        listStyleType: "disclosure-closed",
        "& li": {
            marginBottom: 3
        },
    },
    item: {
        display: "flex",
        alignItems: "center",
    }
});

function Component(props) {
    const { planDetails, data } = props

    const classes = useStyles();

    const upgradeSubscription = (
        <span style={{ marginLeft: 15, color: "red", fontSize: 11, cursor: "pointer" }} onClick={() => {
            history.push("#tab/subscriptionUpgrade")
        }}>{translate("$__upgradeSubscription")}</span>
    )
    return (
        <div>
            <fieldset style={{
                wordBreak: "break-word",
                padding: 0,
                position: "relative"
            }}>
                <legend>
                    {translate("$__subscription")}: <span style={{ fontWeight: "bold" }}>{translate(planDetails.label)}</span>
                </legend>
                <ul

                    className={classes.list}
                >

                    <li >{translate("$__adminLimit", 1)}: {data && data.customAdminsLimit ? data.customAdminsLimit : planDetails.settings.admins}</li>
                    <li >{translate("$__storageSpace", 1)}: {data && data.customStorageSpace ? BytesConvert(data.customStorageSpace) : BytesConvert(planDetails.settings.space)}</li>
                    <li>{translate("$__BrightAndConnected", 1)}:
                        <ul style={{
                            position: "relative",
                            paddingLeft: 10,
                            listStyleType: "disc",
                        }}>
                            <li className={classes.item}>{translate("$__feed", 1)}: {planDetails.settings.brightAndConnected.feed ? <Icon icon='check' color={customApp("color")} size={18} /> : upgradeSubscription}</li>
                            {planDetails.settings.brightAndConnected.feed ?
                                <li className={classes.item}>{translate("$__feedDays", 1)}:
                                    {planDetails.settings.brightAndConnected.feedDays ? `${planDetails.settings.brightAndConnected.feedDays} ${translate("$__days")}` : translate("$__unlimited")}
                                </li>
                                : <React.Fragment></React.Fragment>
                            }
                            <li className={classes.item}>{translate("$__radarLimit", 1)}: {planDetails.settings.brightAndConnected.radarLimit ? planDetails.settings.brightAndConnected.radarLimit : translate("$__unlimited")}</li>
                            <li className={classes.item}>{translate("$__insights", 1)}: {planDetails.settings.brightAndConnected.insight ? <Icon icon='check' color={customApp("color")} size={18} /> : upgradeSubscription}</li>
                        </ul>
                    </li>
                    <li>{translate("$__plannings", 1)}:
                        <ul style={{
                            position: "relative",
                            paddingLeft: 10,
                            listStyleType: "disc",
                        }}>
                            <li className={classes.item}>{translate("$__limit", 1)}: {planDetails.settings.plan.limit ? planDetails.settings.brightAndConnected.radarLimit : translate("$__unlimited")}</li>
                            <li className={classes.item}>{translate("$__clientPlannings", 1)}: {planDetails.settings.plan.planClient ? <Icon icon='check' color={customApp("color")} size={18} /> : upgradeSubscription}</li>
                            {planDetails.settings.plan.planClient ?
                                <li className={classes.item}>{translate("$__clientUserAdmins", 1)}: {planDetails.settings.plan.clientAdmin ? planDetails.settings.plan.clientAdmin : upgradeSubscription}</li>
                                : <React.Fragment></React.Fragment>}
                            <li className={classes.item}>{translate("$__externalPlanning", 1)}: {planDetails.settings.plan.machenPlan ? <Icon icon='check' color={customApp("color")} size={18} /> : upgradeSubscription}</li>
                            <li className={classes.item}>{translate("$__professionalTemplates", 1)}: {planDetails.settings.plan.templates ? <Icon icon='check' color={customApp("color")} size={18} /> : upgradeSubscription}</li>
                            <li className={classes.item}>{translate("$__guidelineLimit", 1)}: {planDetails.settings.plan.guidelineLimit ? planDetails.settings.plan.guidelineLimit : translate("$__unlimited")}</li>
                            <li className={classes.item}>{translate("$__feedLimit", 1)}: {planDetails.settings.plan.feedLimit ? planDetails.settings.plan.feedLimit : translate("$__unlimited")}</li>
                            <li className={classes.item}>{translate("$__radarLimit", 1)}: {planDetails.settings.plan.radarLimit ? planDetails.settings.plan.radarLimit : translate("$__unlimited")}</li>
                            <li className={classes.item}>{translate("$__insightLimit", 1)}: {planDetails.settings.plan.insightLimit ? planDetails.settings.plan.insightLimit : translate("$__unlimited")}</li>
                        </ul>
                    </li>
                    <a href="https://machen.ai/#planos" target={"_blank"} rel="noopener noreferrer" style={{ fontWeight: "bold", color: customApp("menu"), marginTop: 33 }}>{translate("$__viewMoreDetails")}</a>
                </ul>
            </fieldset>
        </div >
    )
}
const mapStateToProps = (store, ownProps) => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
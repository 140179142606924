//CORE
import React from "react"
import moment from "moment-timezone"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import Badge from "components/Icon/Badge"
import CardTags from "components/Tags/Card"
import Icon from "components/Icon"
import UserBadge from "components/Badge/user"
import Card from "components/Card"


//@MATERIAL
import Tooltip from "@material-ui/core/Tooltip"
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import { withStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule.js"

//IMAGES
import UserCover from "@material-ui/icons/Person"
import ClientCover from "@material-ui/icons/Business"

//FUNCTIONS
import { translate, customApp, getDate } from "functions/"


function Component(props) {
    const { data } = props
    const { db, session } = reduxStore.getState()
    const [ImagePreview, setImagePreview] = React.useState(null)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")


    let indicators = []
    let status = {}

    Object.keys(db.cards).filter(fil => {
        if (db.cards[fil]
            && db.cards[fil].type !== 'chatGroup'
            && db.cards[fil].type !== 'link'
            && db.cards[fil].type !== 'insight'
            && db.cards[fil]._users
            && Object.keys(db.cards[fil]._users).length > 0
            && Object.keys(db.cards[fil]._users).filter(user => user === data._id).length === 1)
            return true
        return false
    }).forEach(a => {
        if ((db.dataType.filter(fil => fil.name === db.cards[a].type)[0]
            && db.dataType.filter(fil => fil.name === db.cards[a].type)[0].module
            && db.dataType.filter(fil => fil.name === db.cards[a].type)[0].module !== "swot"
        )
            || (
                db.cards[a]
                && db.cards[a].type
                && db.dataType.filter(fil => fil.name === db.cards[a].type).length > 0
                && db.dataType.filter(fil => fil.name === db.cards[a].type)[0].module === "swot"
            )
        ) {
            if (db.cards[a] && db.cards[a].status && db.cards[a].status !== 'completed') {
                status = {
                    ...status,
                    [db.cards[a].status]: status && status[db.cards[a].status] ? status[db.cards[a].status] + 1 : 1,
                    total: status.total ? status.total + 1 : 1,
                    late: db.cards[a].endDate && moment(new Date(db.cards[a].endDate * 1000)).tz('America/Sao_Paulo').format() <= moment().tz('America/Sao_Paulo').format() ? status.late ? status.late + 1 : 1 : status.late ? status.late : 0
                }
            } else {
                status = {
                    ...status,
                    completed: status && status.completed ? status.completed + 1 : 1,
                    total: status.total ? status.total + 1 : 1
                }
            }
            indicators[db.cards[a].type] = !indicators[db.cards[a].type] ? 1 : indicators[db.cards[a].type] + 1
        } else {
            indicators.swot = indicators.swot == null ? 1 : indicators.swot + 1
        }
    })

    indicators = []
    status = {}

    return (
        props.expanded ?
            <div style={{
                boxShadow: "0 0 3px 3px rgba(0,0,0,0.1)",
                position: "relative",
                marginLeft: "15px",
                ...props.onClick ? { cursor: "pointer" } : {}
            }}
                onClick={props.onClick ? () => { props.onClick() } : null}
            >
                <div
                    style={{
                        backgroundColor: customApp("menu"),
                        height: "43px",
                        marginTop: '5px',
                        paddingLeft: "60px",
                        fontSize: "12px",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        textOverflow: "ellipsis ",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        left: "15px"
                    }}>
                    {data.name ? data.name : data.name}
                    {!props.resume &&
                        <div style={{
                            float: "right",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "7px"
                        }}>
                            {!props.noActions &&
                                <div style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px"
                                }}>
                                    <Icon color={customApp("color")} icon={"close"}
                                        onClick={
                                            () => {
                                                history.push(`c#tab/${hashs[1]}`)
                                            }
                                        } />
                                </div>
                            }
                        </div>
                    }
                </div>

                {(!ImagePreview && data.image) ?
                    <img
                        alt="MACHEN_IMAGE"
                        style={{
                            width: "70px",
                            height: "70px",
                            top: "-20px",
                            left: "-6px",
                            borderRadius: "50%",
                            border: `solid 2px ${customApp("color")}`,
                            zIndex: "5 !important",
                            position: "absolute",
                            backgroundColor: `${customApp("color")}`,
                        }}
                        src={`${data.image ? data.image : (!data.type || data.type === "user") ? UserCover : ClientCover}`}
                        onError={(e) => {
                            setImagePreview(null)
                        }}
                        onLoad={(e) => {
                            setImagePreview(`${!data.image ? (!data.type || data.type === "user") ? UserCover : ClientCover : data.image}`)
                        }}
                        width="100%" />
                    : data.image ?
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                top: "-4px",
                                left: "-13px",
                                borderRadius: "50%",
                                border: `solid 2px ${customApp("color")}`,
                                zIndex: "5 !important",
                                position: "absolute",
                                background: customApp("AvatarBackground"),
                                backgroundImage: `url("${ImagePreview}")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                            }}
                        >
                        </div>
                        :
                        <div
                            style={{
                                width: "50px",
                                height: "50px",
                                top: "-4px",
                                left: "-13px",
                                borderRadius: "50%",
                                border: `solid 2px ${customApp("color")}`,
                                zIndex: "5 !important",
                                position: "absolute",
                                background: customApp("AvatarBackground"),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            {(!data.type || data.type === "user") ?
                                <Icon size="16px" color="rgba(250,250,250,0.77)">person</Icon>
                                :
                                <Icon size="16px" color="rgba(250,250,250,0.77)">business</Icon>
                            }

                        </div>
                }
            </div>
            :
            <div style={{
                marginTop: "33px",
                width: props.style && props.style.width ? props.style.width : "calc(100% - 15px)",
                minHeight: "120px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0 0 3px 3px rgba(0,0,0,0.1)",
                position: "relative",
                marginLeft: props.style && props.style.marginLeft ? props.style.marginLeft : "15px",
            }}
            >

                <div
                    style={{
                        backgroundColor: customApp("menu"),
                        height: "43px",
                        paddingLeft: "95px",
                        fontSize: "12px",
                        color: "#FFFFFF",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}>
                    <div style={{
                        maxWidth: "calc(100% - 10px)",
                        textOverflow: "ellipsis ",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}>
                        {data.name ? data.name : data.name}
                    </div>
                    {!props.resume &&
                        <div style={{
                            float: "right",
                            display: "flex",
                            alignItems: "center",
                            marginRight: "7px"
                        }}>
                            {(!props.noActions
                                && (
                                    !data.independentAccount
                                    ||
                                    (
                                        data.independentAccount
                                        && session
                                        && session.matrixAdmin
                                    )
                                )
                                && (
                                    !data._parent
                                    ||
                                    (
                                        data._parent
                                        && reduxStore.getState().db.users[data._parent]
                                        && !reduxStore.getState().db.users[data._parent].independentAccount
                                    )
                                )

                            ) &&
                                <div style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px"
                                }}>
                                    <Icon color={customApp("color")} icon={"create"}
                                        onClick={
                                            () => {
                                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                                    ...db.sideModule,
                                                    id: data._id,
                                                    activeModule: "userEdit",
                                                    db: "users",
                                                    data: {
                                                        type: "user",
                                                        ...data,
                                                    }
                                                })
                                            }
                                        } />
                                </div>
                            }
                            {!props.noActions && data.type !== "user" &&
                                <div style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px"
                                }}>
                                    <Icon color={customApp("color")} icon={"contacts"}
                                        onClick={
                                            () => {
                                                history.push(`c#tab/units/${data._id}`)
                                            }
                                        } />
                                </div>
                            }
                            {!props.noActions && data.type === "sic" &&
                                <div style={{
                                    float: "right",
                                    display: "flex",
                                    alignItems: "center",
                                    marginRight: "7px"
                                }}>
                                    <Icon color={customApp("color")} icon={"badge"}
                                        onClick={
                                            () => {
                                                history.push(`c#tab/units/${data._id}`)
                                            }
                                        } />
                                </div>
                            }
                        </div>
                    }
                </div>
                {(data.image && (!ImagePreview || (data.image && data.image !== ImagePreview))) ?
                    <img
                        alt="MACHEN_IMAGE"
                        style={{
                            width: "90px",
                            height: "90px",
                            top: "-24px",
                            left: "-6px",
                            borderRadius: "50%",
                            border: `solid 2px ${customApp("color")}`,
                            zIndex: "5 !important",
                            position: "absolute",
                            backgroundColor: `${customApp("color")}`,
                        }}
                        src={`${data.image ? data.image : (!data.type || data.type === "user") ? UserCover : ClientCover}`}
                        onError={(e) => {
                        }}
                        onLoad={(e) => {
                            setImagePreview(`${data.image ? data.image : null}`)
                        }}
                        width="100%" />
                    : data.image ?
                        <div
                            style={{
                                width: "90px",
                                height: "90px",
                                top: "-24px",
                                left: "-6px",
                                borderRadius: "50%",
                                border: `solid 2px ${customApp("color")}`,
                                zIndex: "5 !important",
                                position: "absolute",
                                background: customApp("AvatarBackground"),
                                backgroundImage: `url("${ImagePreview}")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                            }}
                        >
                        </div>
                        :
                        <div
                            style={{
                                width: "90px",
                                height: "90px",
                                top: "-24px",
                                left: "-6px",
                                borderRadius: "50%",
                                border: `solid 2px ${customApp("color")}`,
                                zIndex: "5 !important",
                                position: "absolute",
                                background: customApp("AvatarBackground"),
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            {(!data.type || data.type === "user") ?
                                <Icon size="33px" color="rgba(250,250,250,0.77)">person</Icon>
                                :
                                <Icon size="33px" color="rgba(250,250,250,0.77)">business</Icon>
                            }

                        </div>
                }
                {props.children ?
                    props.children
                    :
                    <React.Fragment>
                        {data?._access?.enabled && data.type === "user" ?
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    fontSize: 12,
                                    color: customApp("medium"),
                                    padding: "3px 7px"
                                }}>
                                {data?._access?.admin ?
                                    <React.Fragment>
                                        <Icon color={customApp("medium")} size={"12px"}>supervisor_account</Icon><span>{translate("$__supervisorAccount")}</span>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <Icon color={customApp("medium")} size={"12px"}>supervised_user_circle</Icon><span>{translate("$__guest")}</span>
                                    </React.Fragment>
                                }
                            </div>
                            : data.type === "user" && data._access && !data._access.enabled ?
                                <div style={{ width: "100%", display: "flex", alignItems: "center", color: "red", fontSize: 10 }}>{translate("$__notEnabled")}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        {data.groups && data.groups.length > 0 ?
                            <div style={{
                                position: "relative",
                                width: "100%",
                                fontSize: "12px",
                                color: customApp("mediumText"),
                                paddingLeft: "94px",
                                paddingRight: "15px",
                                marginTop: "15px",
                            }}>
                                {data.groups
                                    .filter(a => a.value)
                                    .sort((a, b) => {
                                        if (a.label < b.label)
                                            return -1
                                        return 1
                                    }).map((item, i) => `${i > 0 ? ` / ` : ``}${item.label}`)
                                }
                            </div>
                            : data.type === "user" ?
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    fontSize: "12px",
                                    color: customApp("mediumText"),
                                    paddingLeft: "94px",
                                    marginTop: "15px",
                                }}>
                                    {translate(`$__doesntBelongToAGroup`)}
                                </div>
                                : <React.Fragment></React.Fragment>
                        }
                        {/* {console.log(data)} */}
                        {data._lastAccess ?
                            <div style={{
                                position: "relative",
                                width: "100%",
                                fontSize: 10,
                                color: customApp("menu"),
                                marginTop: 15,
                                textAlign: "right",
                                paddingRight: 5
                            }}>
                                {translate(`$__lastAccess`)}:{getDate(data._lastAccess)}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        {!props.noShowUsers && (data.type !== 'user') &&
                            <div style={{ marginTop: "11px", width: "100%", padding: "15px" }}>
                                <ClientInfo id={data._id} {...props} />
                            </div>
                        }
                        {(data.type === 'user' && status && status.total > 0) ?
                            <div style={{
                                display: "flex",
                                width: "100%",
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                flexFlow: "wrap",
                                paddingTop: 15,
                                paddingBottom: 15
                            }}>
                                <fieldset style={{
                                    padding: 7,
                                    width: "calc(100% - 14px)"
                                }}>
                                    <legend style={{
                                        color: "lightgray",
                                        fontSize: 12
                                    }}>{translate("$__openActivities")}</legend>
                                    <h3>
                                        <small>{translate('$__total', 1)}: {status.total}</small>
                                    </h3>
                                    {db.status.filter(st => st.value !== "completed").map((st, index) => {
                                        return (
                                            <div key={`${index}_${st.value}`} style={{ color: 'black', display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', width: '100%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <Icon color={st.color}>{st.icon}</Icon>
                                                    <span style={{ marginLeft: '15px' }} >{translate(st.label, 1)}</span>
                                                </div>
                                                <div>
                                                    {status[st.value] ? status[st.value] : 0}
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div style={{ color: 'black', display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', width: '100%' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Icon color={'red'}>assignment_late</Icon>
                                            <span style={{ marginLeft: '15px' }} >{translate('$__late', 1)}</span>
                                        </div>
                                        <div>
                                            {status.late}
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        {!props.resume &&
                            <CardTags options={data.tags} showOptions={false} />
                        }
                        {props.resume &&
                            <div>
                                <UserBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    userId={data._id}
                                // variant={usersStatus.users && usersStatus.users[data._id] && usersStatus.users[data._id].status ? "dot" : "standard"}
                                // status={usersStatus.users && usersStatus.users[data._id] && usersStatus.users[data._id].status ? usersStatus.users[data._id].status : null}
                                >
                                    <Icon size="27px">chat</Icon>
                                </UserBadge>
                            </div>
                        }
                    </React.Fragment>
                }

            </div >
    )
}

const ClientInfo = (props) => {
    const { users } = reduxStore.getState().db
    const { cards } = reduxStore.getState().db

    let plans = Object.keys(cards)
        .filter(card =>
            cards[card].client === props.id
            && cards[card].type === 'plan'
            && !cards[card].deleted
            && !cards[card].archived
            && !cards[card].isTemplate
            && (
                (
                    !props.completed
                    && cards[card].status !== 'completed'
                )
                ||
                (
                    props.completed
                    && cards[card].status === 'completed'
                )
            )
        )
        .sort((a, b) => {
            if (a.name < b.name) {
                return -1
            } else if (a.name > b.name) {
                return 1
            } else {
                return 0
            }
        })
        .map(card => cards[card])

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
        }}>
            {Object.keys(users).filter(u => users[u] && !users[u].deleted && users[u]._parent === props.id).length > 0 &&
                <React.Fragment>
                    <div style={{ width: "100%" }}>{translate("$__users", 1)}:</div>
                    <AvatarGroup max={12}>
                        {Object.keys(users).filter(u => users[u] && !users[u].deleted && users[u]._parent === props.id).map((a, i) =>
                            <Tooltip
                                title={users[a].name}
                                key={a}
                            >
                                <Avatar
                                    alt={users[a].name}
                                    src={users[a].image || null}
                                    style={{ width: "23px", height: "23px" }}>
                                    {users[a].name && users[a].name.substr(0, 1) ? users[a].name.substr(0, 1) : ""}
                                </Avatar>
                            </Tooltip>
                        )
                        }
                    </AvatarGroup>
                </React.Fragment>
            }
            {plans.length > 0 &&
                <div style={{ width: "100%" }}>
                    {plans.map((a, i) => {
                        return (
                            <div key={`${a._id}-${i}`}>
                                <Card data={a} db="cards" Avatar={false} expanded={false} ignoreList notGlobalExpanded />
                            </div>
                            // <div
                            //     key={a._id}
                            //     style={{
                            //         marginTop: "7px",
                            //         alignItems: "center",
                            //         display: "flex",
                            //         cursor: "pointer",
                            //         overflow: "hidden",
                            //         textOverflow: "ellipses",
                            //         width: "100%",
                            //         padding: "3px",
                            //         backgroundColor: "rgba(179,179,179,0.1)",
                            //         borderRadius: "3px",
                            //         whiteSpace: "nowrap"
                            //     }}
                            //     onClick={(e) => {
                            //         history.push(`sp#${a._id}/dashboard`)//#CORRECT
                            //     }}
                            // >
                            //     <Avatar
                            //         alt={a.name} src={a.image || null}
                            //         style={{
                            //             width: "22px",
                            //             height: "22px"
                            //         }}>
                            //         {a.name.substr(0, 1)}
                            //     </Avatar>
                            //     <span style={{ marginLeft: '7px' }}>
                            //         {a.name}
                            //     </span>
                            // </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}
const mapStateToProps = store => ({
    usersStatus: store.usersStatus,
    users: store.db.users
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))

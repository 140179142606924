//CORE
import React, { useState } from "react"
import moment from "moment"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS

import Autocomplete from "components/AutoComplete"
import DataPicker from "components/DataPicker"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TextField from "components/TextField"
//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users.js"

//FUNCTIONS

import {
    customApp,
    appAlert,
    translate,
    getAppAccess
} from "functions/"
import { save } from "../../functions/users.js"

function Component(props) {
    const { classes } = props
    const { sideModule, db } = reduxStore.getState()
    const [data, setData] = useState(sideModule.data)
    const [iniData, setIniData] = useState(sideModule.data)

    const reqSave = JSON.stringify(data) !== JSON.stringify(iniData)

    const changeData = (d) => {
        let newData = {
            ...data,
            [d.name]: d.value,
        }

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                [d.name]: d.value,
            }
        })

        if (!d.value && newData[d.name])
            delete newData[d.name]

        setData(newData)
    }

    const close = (force = false) => {
        if (reqSave && !force) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }

    const saveUser = async (cl) => {

        const reqSave = await save(props)

        if (reqSave) {
            appAlert({
                message: translate('$__userDataSaved', 1),
                variant: null,
                persist: false,
                horizontal: 'right',
                confirm: null
            })
            setIniData(data)

            if (cl) {
                close(true)
            }
        }
    }

    const AppAccess = getAppAccess()

    let permission = false
    if ((AppAccess
        &&
        (
            AppAccess.user
            && (
                AppAccess.userAdmin
                || AppAccess.userEdit
            )
        )
    ))
        permission = true

    return (
        <div>
            <SideModuleCloseAndSave
                save={reqSave ? () => saveUser() : null}
                saveClose={reqSave ? () => saveUser(true) : null}
                close={() => close(true)}
            />
            <div style={{
                position: "relative",
                width: "100%",
                textAlign: "center",
                padding: '15px',
            }}>
                <fieldset>
                    <legend>{translate('$__limitNumberOfUsers', 1)}</legend>
                    <div
                    >
                        {translate("$__limitAdminUsersExplain", 1)}
                        <TextField
                            label={translate("$__numberOfUsers")}
                            variant={"outlined"}
                            name="customAdmins"
                            type="number"
                            min={0}
                            max={99999}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                type: "number",
                                onChange: (d) => {
                                    if (d.target.value === 0) {
                                        changeData({ name: 'customAdmins', value: null })
                                    } else {
                                        changeData({ name: 'customAdmins', value: d.target.value > 0 ? d.target.value : 0 })
                                    }
                                },
                            }}
                            value={data.customAdmins || 0}
                        />
                    </div>
                </fieldset>
                {reduxStore.getState().session.isResale ?
                    <fieldset style={{ marginBottom: "14px" }}>
                        {reduxStore.getState().db.users[data._id].independentAccount ?
                            <React.Fragment>
                                <div
                                    style={{ color: "gray", fontSize: 11, width: "100%" }}
                                >
                                    {translate("$__thisIsAIndependentAccountExplain", 1)}
                                </div>
                                <ul
                                    style={{
                                        color: "gray",
                                        fontSize: 12,
                                        width: "100%",
                                        textAlign: "initial"
                                    }}
                                >
                                    {data.plan ?
                                        <li>
                                            {translate("$__signaturePlan", 1)}: {translate(db.plans.filter(a =>
                                                String(a.value) === String(data.plan)
                                            )[0].label, 1)}
                                        </li>
                                        : <React.Fragment></React.Fragment>

                                    }
                                    {data.expireAccountDate ?
                                        <li>
                                            {translate("$__expireAccountDate", 1)}: {moment(data.expireAccountDate * 1000).format("DD/MM/YYYY")}
                                        </li>
                                        : <React.Fragment></React.Fragment>

                                    }
                                </ul>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className={classes.switch}>
                                    <Switch
                                        checked={
                                            data.independentAccount
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            if (permission)
                                                changeData({
                                                    name: "independentAccount",
                                                    value: data.independentAccount ? false : true
                                                })
                                        }}
                                        name="independentAccount"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__turnIntoIndependentAccount", 1)}
                                </div>
                                <div
                                    style={{ color: "gray", fontSize: 11 }}
                                >
                                    {translate("$__turnIntoIndependentAccountExplain", 1)}
                                </div>
                            </React.Fragment>
                        }
                    </fieldset>
                    : <React.Fragment></React.Fragment>}
                {((!iniData.independentAccount && data.plan) || !data.plan) && data.independentAccount ?
                    <React.Fragment>
                        <fieldset style={{ marginBottom: "14px" }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={db.plans}
                                getOptionLabel={(option) => translate(option.label, 1)}
                                style={{ width: "100%" }}
                                name="plans"
                                disableClearable
                                value={
                                    data
                                        && data.plan
                                        && db.plans
                                        ? db.plans.filter(a =>
                                            String(a.value) === String(data.plan)
                                        )[0]
                                        : db.plans[0]
                                }
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={translate("$__demoPlanSignature", 1)}
                                        variant={customApp("fieldVariant")}
                                        value={
                                            data
                                                && data.plan
                                                && db.plans
                                                ? db.plans.filter(a =>
                                                    String(a.value) === String(data.plan)
                                                )[0]
                                                : db.plans[0]
                                        }
                                    />
                                }
                                onChange={
                                    (d, v) => {
                                        if (permission)
                                            changeData({ name: "plan", value: v["value"] })
                                    }
                                }
                            />
                        </fieldset>
                    </React.Fragment>
                    : <React.Fragment>

                    </React.Fragment>
                }
                {data.independentAccount && (data.plan && data.plan !== "p0" && !iniData.expireAccountDate) ?
                    <DataPicker
                        placeholder={translate("$__dueDate")}
                        clearButtonPosition={"flex-end"}
                        onChangeStartDate={(d) => {
                            changeData({ name: "expireAccountDate", value: String(moment(d).unix('X')) })
                        }}
                        maxDate={new Date(moment().add("days", 30).unix('X') * 1000)}
                        startDate={data && data.expireAccountDate ? new Date(data.expireAccountDate * 1000) : null}
                    />
                    : <React.Fragment></React.Fragment>
                }
            </div>
        </div>
    )
}
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
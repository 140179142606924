import React, { useState, useEffect } from 'react'
import { FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import TableRow from './Tablerow'
import ErrorModal from './errorModal';

//COMPONENTS
import Griditem from 'components/Grid/GridItemV2'
import ErrorBoundary from "components/ErrorBoundary"

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import { validateEmail } from 'functions';
import { emailExistV2 } from "functions/users";
import { translate } from 'functions';


import api from "api"

const UserAddList = (props) => {
    const { dataUser, resetComponent } = props
    const [add, addPress] = useState(false);
    const [data, setData] = useState(dataUser);
    const [data1, setData1] = useState({});
    const [selected, setSelected] = useState([])
    const [notSended, setNotSended] = useState(false)
    const listRef = React.createRef()
    const [columns, setColumns] = useState([])
    const [errorMSG, setErrorMsg] = useState({})
    const [errorScreen, setErrorScreen] = useState(false)


    // Object.fromEntries(Object.entries(data1).map(([k, v]) => {

    // }
    // ));

    useEffect(() => {
        if (dataUser.length > 0) {
            let columnsFiltered = dataUser
            let correctColumsNames = ['nomecompleto', 'nomedeexibição', 'email', 'departamento/grupo', 'ramal', 'observações', 'celular'];
            columnsFiltered = [...new Set(dataUser.flatMap(obj => Object.keys(obj)))]
            columnsFiltered = columnsFiltered.map((item) => {
                return item.toLowerCase()
            })
            let mandatoryNames = ['nomecompleto', 'email']
            columnsFiltered = columnsFiltered.filter(value => correctColumsNames.includes(value));
            setColumns(columnsFiltered)
            let checker = (arr, target) => target.every(value => arr.includes(value));
            checker(columnsFiltered, mandatoryNames) ? setErrorScreen(false) : setErrorScreen(true)
        }
    }, [dataUser])


    const objectMap = (obj, fn) =>
        Object.fromEntries(
            Object.entries(obj).map(
                ([k, v], i) => [k, fn(v, k, i)]
            )
        )

    const handleCheckboxChange = (index) => {
        setSelected((prevUsuarios) => {
            if (prevUsuarios.includes(index)) {
                return prevUsuarios.filter((item) => item !== index);
            } else {
                return [...prevUsuarios, index];
            }
        });
    }

    const handleDataChange = (index, newName, prop) => {
        let newData = [...data];
        newData[index][prop] = newName;
        newData && setData(newData)
    };

    const handleRemoveSelected = () => {
        setData(data.filter((item, index) => !selected.includes(index)));
        setSelected([])
    }


    const GetButton = ({ name, handleEvent, style, disabled, id }) => {

        return (
            <button
                id={id}
                onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent' }}
                onMouseUp={handleEvent}
                disabled={disabled}
                style={{
                    ...style,
                    minWidth: "160px",
                    height: "45px",
                    cursor: "pointer",
                    border: "1px solid #d1d1cf",
                    fontWeight: "bolder",
                    backgroundColor: 'transparent'
                }}
            >
                {name}
            </button>
        )
    }



    useEffect(() => {

        if (data1 && Object.keys(data1).length > 0 && Object.keys(data1).length < Object.keys(data).length) {
            setTimeout(() => {
                checkRow(Object.keys(data1).length)
            }, 100)
        } else {
            if (Object.keys(data1).length > 0) {
                const handleResetlist = () => {
                    if (!notSended) {
                        setNotSended(true)
                        let newData1 = data1
                        newData1 = Object.fromEntries(Object.entries(newData1).filter(([key, value]) => value.sended === false))
                        let getIndex = []
                        objectMap(newData1, (v, k) => {
                            if (v.sended === false) {
                                getIndex = [...getIndex, parseInt(k)]
                                return getIndex
                            }
                        })
                        setData(data.filter((item, index) => getIndex.includes(index)));
                        newData1 = Object.entries(newData1).reduce((obj, [key, value], objindex) => {
                            obj[objindex] = value;
                            return obj;
                        }, {});
                        setErrorMsg(newData1)
                        setData1({})
                    }
                }
                handleResetlist()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data1])

    const checkRow = async (n = 0) => {
        if (props.onAdding)
            props.onAdding(true)

        if (data.length > 0) {
            setNotSended(false)
            let validation = validateEmail(data[n].email)

            let emailExists = await emailExistV2(data[n].email)

            if (emailExists) {

                let xData = {
                    ...data1,
                    [n]: {
                        email: data[n].email,
                        sended: true,
                        ...(emailExists && (emailExists.exists || Object.keys(data1).filter(filUser => data1[filUser].email === data[n].email).length > 0)) && {
                            sended: false,
                            emailError: translate("$__emailAlreadyInUse")
                        },
                        ...(data[n]?.nomecompleto.split('').length < 3 && {
                            sended: false,
                            nameError: translate("$__minimalNameField")
                        }),
                        ...(!validation && {
                            sended: false,
                            emailErrorInv: translate("$__invalidEmail")
                        })
                    }
                }
                if (xData[n].sended) {

                    let userData = {
                        name: data[n].nomedeexibição || (`${data[n].nomecompleto.split(' ')[0]} ${data[n].nomecompleto.split(' ')[1] ? data[n].nomecompleto.split(' ')[1] : ``}`).trim(),
                        fullName: data[n].nomecompleto,
                        email: data[n].email,
                        line: data[n].ramal || null,
                        phone: data[n].phone || null,
                        obs: data[n].observações || null,
                    }

                    if (data[n]['departamento/grupo'] && reduxStore.getState().db.userGroup.filter(g => g.label === data[n]['departamento/grupo']).length > 0) {
                        userData.groups = Array.from(reduxStore.getState().db.userGroup.filter(g => g.label === data[n]['departamento/grupo']))
                    }

                    try {
                        let register = await api.post("user/addImport", userData)
                        if (register) {
                            api.post("user/accessSendInvite", {
                                _id: register.data._id
                            })
                            if (listRef && listRef.current)
                                listRef.current.scrollToItem(n)
                            setData1(xData);

                            if (data.length === n + 1) {
                                addPress(false)
                                if (props.onAdding)
                                    props.onAdding(false)
                            }
                        }
                    } catch (e) {
                        xData[n] = {
                            sended: false,
                            nameError: translate("$__serverError")
                        }
                        if (listRef && listRef.current)
                            listRef.current.scrollToItem(n)
                        setData1(xData);
                        // console.log(e)

                        if (data.length === n + 1) {
                            addPress(false)
                            if (props.onAdding)
                                props.onAdding(false)
                        }
                    }

                } else {
                    if (listRef && listRef.current)
                        listRef.current.scrollToItem(n)
                    setData1(xData);
                    if (data.length === n + 1) {
                        addPress(false)
                        if (props.onAdding)
                            props.onAdding(false)
                    }
                }

            }
        }
    }

    const divStyles = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        textAlign: 'left',
        flex: 1,
        border: '1px solid #ebebeb',
        fontWeight: 'bold',
        alignSelf: 'stretch',
        overflow: 'hidden',
    }


    const RenderColumn = ({ name }) => {

        let colunmName = ''

        switch (name) {
            case 'nomecompleto': colunmName = translate("$__completeName")
                break;
            case 'nomedeexibição': colunmName = translate("$__showName")
                break;
            case 'email': colunmName = translate("$__email")
                break;
            case 'departamento/grupo': colunmName = translate("$__department/group")
                break;
            case 'ramal': colunmName = translate("$__branchLine")
                break;
            case 'observações': colunmName = translate("$__observation")
                break;
            case 'celular': colunmName = translate("$__phone")
                break;
            default:
                break;
        }

        return <div style={divStyles}>
            <p style={{
                width: '100%',
                alignSelf: 'center',
                padding: '0px',
                overflow: 'hidden',
                margin: '0px 0px 0px 10px'
            }}>
                {colunmName}
            </p>
        </div>
    }

    return (
        <ErrorBoundary>
            <Griditem item xs={12} style={{ justifyContent: "center", padding: '0px 4px 0px', position: 'reçative' }} >
                {add && <div style={{ position: 'absolute', width: '100%', height: '100%', zIndex: '99' }}></div>}
                {data.length === 0 &&
                    <ErrorModal
                        message={translate("$__finalMsgUserImportConcluded")}
                        title={translate("$__concluded")}
                        icon={"done"}
                        color={"green"}
                        resetComponent={resetComponent}
                    />}
                {errorScreen ?
                    <ErrorModal
                        message={translate("$__finalMsgUserImportError")}
                        title={translate("$__error")}
                        icon={"warning"}
                        color={"red"}
                        resetComponent={resetComponent}
                    /> :
                    <div>
                        {data.length > 0 && <div id='table-container' style={{ marginBottom: '5px', height: '80vh', maxHeight: '600px', overflow: 'hidden' }}>
                            <div style={{ overflowY: 'scroll' }}>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', height: '50px', color: '#141414' }}>
                                    <div style={{
                                        ...divStyles,
                                        width: '80px',
                                        border: '1px solid #ebebeb',
                                        flex: 'none',
                                        justifyContent: 'center',
                                        display: 'flex'
                                    }}>
                                        <p style={{ alignSelf: 'center', padding: '0px' }}>{translate("$__remove")}</p>
                                    </div>
                                    {columns.length > 0 && columns.map((item, ii) => {
                                        return (
                                            <RenderColumn name={item} key={ii} />
                                        )
                                    })}
                                    <div style={{
                                        ...divStyles,
                                        width: '60px',
                                        border: '1px solid #ebebeb',
                                        flex: 'none',
                                        display: 'flex'
                                    }}>
                                        <p style={{ marginLeft: '2px', alignSelf: 'center', padding: '0px', margin: '0px 0px 0px 10px' }}> {translate("$__stats")}</p>
                                    </div>
                                </div>
                            </div>
                            {data.length > 0 &&
                                <AutoSizer>
                                    {({ height, width }) => (
                                        <FixedSizeList
                                            itemData={data}
                                            height={height}
                                            itemCount={data.length}
                                            itemSize={44}
                                            width={width}
                                            ref={listRef}
                                        >
                                            {({ data, index, style }) => {
                                                return (
                                                    <div key={`a_${index}_${Math.random(0, 99999999)}`}>
                                                        <TableRow
                                                            selected={selected && selected.includes(index) ? true : false}
                                                            column={columns}
                                                            handleDataChange={(index, value, prop) => handleDataChange(index, value, prop)}
                                                            handleCheckboxChange={handleCheckboxChange}
                                                            style={style}
                                                            add={add}
                                                            data={data}
                                                            data1={data1}
                                                            dataError={errorMSG[index]}
                                                            usuario={data[index]}
                                                            index={index}
                                                            isActive={data1 && data.length > 0 && data1[index]?.sended ? true : false}
                                                        />
                                                    </div>
                                                )
                                            }}
                                        </FixedSizeList >
                                    )}
                                </AutoSizer>
                            }
                        </div>}
                    </div>
                }


            </Griditem>
            <Griditem item xs={12} style={{ borderTop: "1px solid #ebebeb", display: "flex", justifyContent: "right", marginTop: "10px" }} >
                {!errorScreen && !add && <>
                    <GetButton handleEvent={handleRemoveSelected}
                        name={translate("$__removeSelected")}
                        color='red'
                        style={{
                            width: "120px",
                            height: "30px",
                            marginTop: "25px",
                            marginBottom: "25px",
                            marginRight: '30px',
                            backgroundColor: 'none',
                            color: 'black',
                            justifySelf: 'left'
                        }} />
                    <GetButton
                        handleEvent={() => {
                            checkRow(0)
                            addPress(true)
                        }}
                        name={translate("$__add")}
                        color='red'
                        style={{
                            width: "120px",
                            height: "30px",
                            marginTop: "25px",
                            marginBottom: "25px",
                            marginRight: '30px',
                            backgroundColor: 'none',
                            color: 'black'
                        }} /> </>}
            </Griditem>
        </ErrorBoundary>
    );
}


export default UserAddList
import React from "react"
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import ErrorBoundary from "components/ErrorBoundary"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CustomButton from "components/Buttons/custom"


//VIEWS

//FUNCTIONS
import api from "api/";

import { translate } from "functions/"


import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

require('dotenv').config()

function App(props) {
    const { session } = reduxStore.getState()
    const [data, setData] = React.useState({})
    const [iniData, setIniData] = React.useState(session.GlobalData ? session.GlobalData : {})
    const [editorData, setEditorData] = React.useState({
        historyEditor: null
    })

    React.useEffect(() => {
        setData(session.GlobalData ? session.GlobalData : {})
        setIniData(session.GlobalData ? session.GlobalData : {})
        let history = EditorState.createEmpty(),
            vocation = EditorState.createEmpty(),
            legacy = EditorState.createEmpty(),
            message = EditorState.createEmpty(),
            strategicStatement = EditorState.createEmpty(),
            values = EditorState.createEmpty()

        if (session.GlobalData.history) {
            history = htmlToDraft(`<div>${session.GlobalData.history}</div>`)
            history = ContentState.createFromBlockArray(history.contentBlocks)
            history = EditorState.createWithContent(history);
        }

        if (session.GlobalData.strategicStatement) {
            strategicStatement = htmlToDraft(`<div>${session.GlobalData.strategicStatement}</div>`)
            strategicStatement = ContentState.createFromBlockArray(strategicStatement.contentBlocks)
            strategicStatement = EditorState.createWithContent(strategicStatement);
        }

        if (session.GlobalData.values) {
            values = htmlToDraft(`<div>${session.GlobalData.values}</div>`)
            values = ContentState.createFromBlockArray(values.contentBlocks)
            values = EditorState.createWithContent(values);
        }


        if (session.GlobalData.vocation) {
            vocation = htmlToDraft(`<div>${session.GlobalData.vocation}</div>`)
            vocation = ContentState.createFromBlockArray(vocation.contentBlocks)
            vocation = EditorState.createWithContent(vocation);
        }



        if (session.GlobalData.legacy) {
            legacy = htmlToDraft(`<div>${session.GlobalData.legacy}</div>`)
            legacy = ContentState.createFromBlockArray(legacy.contentBlocks)
            legacy = EditorState.createWithContent(legacy);
        }
        if (session.GlobalData.message) {
            message = htmlToDraft(`<div>${session.GlobalData.message}</div>`)
            message = ContentState.createFromBlockArray(message.contentBlocks)
            message = EditorState.createWithContent(message)
        }

        setEditorData({
            history,
            vocation,
            legacy,
            message,
            values,
            strategicStatement
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const saveData = async () => {
        let newData = {}
        Object.keys(data).forEach(mp => {
            if (data[mp] && (!iniData[mp] || JSON.stringify({ a: data[mp] }) !== JSON.stringify({ a: iniData[mp] }))) {
                newData = {
                    ...newData,
                    [mp]: data[mp] && typeof data[mp] === 'string' ? data[mp].trim() : data[mp]
                }
            }
        })
        // JSON.str
        console.log(newData)
        try {
            let res = await api.post("account/save", newData)
            if (res) {
                setData({
                    ...reduxStore.getState().session.GlobalData,
                    ...newData
                })
                setIniData(
                    {
                        ...reduxStore.getState().session.GlobalData,
                        ...newData
                    }
                )
                props.reduxFunction("ASYNC", "SET_SESSION", {
                    ...reduxStore.getState().session,
                    GlobalData: {
                        ...reduxStore.getState().session.GlobalData,
                        ...newData
                    }
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <GridContainer
                style={{
                    marginTop: 15
                }}
            >
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__adminMessage")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.message}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        message: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        message: d,
                                    })
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__strategicStatement")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.strategicStatement}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        strategicStatement: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        strategicStatement: d,
                                    })
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__vocation")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.vocation}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        vocation: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        vocation: d,
                                    })
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__history")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.history}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        history: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        history: d,
                                    })
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__values")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.values}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        values: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        values: d,
                                    })
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{
                        marginBottom: 33
                    }}
                >
                    <div
                        style={{
                            padding: 7,
                            width: "100%",
                            backgroundColor: "#FFFFFF",
                            borderRadius: 2,
                            boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)"
                        }}
                    >
                        <div>{translate("$__legacy")}</div>
                        <ErrorBoundary>
                            <Editor
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic'],
                                    },
                                }}
                                editorState={editorData.legacy}
                                onEditorStateChange={(d) => {
                                    setData({
                                        ...data,
                                        legacy: draftToHtml(convertToRaw(d.getCurrentContent())),
                                    })
                                    setEditorData({
                                        ...editorData,
                                        legacy: d,
                                    })
                                }}
                                style={{
                                    minHeight: 150
                                }}
                                hashtag={{
                                    separator: ' ',
                                    trigger: '#',
                                }}
                                editorStyle={{
                                    minHeight: 150
                                }}
                            />
                        </ErrorBoundary>
                    </div>
                </GridItem>


            </GridContainer>
            {JSON.stringify(data) !== JSON.stringify(iniData) ?
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        // shadow
                        title={translate("$__save")}
                        text={translate("$__save")}
                        color="green"
                        // tr
                        icon={"save"}
                        size={"25px"}
                        onClick={() => {
                            saveData()
                        }}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(App)
//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null ,_react.default.createElement("path", {
  fill: "none",
   d: "M.2,11.6H0V17H.2c0,.1,0,.1.1.1H1L18,.4H16.4L1.1,15.7V12.2L12.9.4H11.3L.2,11.6Z"
}),_react.default.createElement("path", {
    d: "M.2,11.6H0V17H.2c0,.1,0,.1.1.1H1L18,.4H16.4L1.1,15.7V12.2L12.9.4H11.3L.2,11.6Z"
}),_react.default.createElement("path", {
    d: "M0,1V7.1H0c.1,0,.1,0,.1.1H.4a.1.1,0,0,0,.1.1H1L7.1,1.4h0l1-1H.6A.6.6,0,0,0,0,1Z"
})), 'GuidelineC');

exports.default = _default;
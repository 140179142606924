import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
  container: {
    ...defaultFont,
    position: 'relative',
    border: `0.5px solid ${customApp('light')}`,
    borderRadius: '2px',
    padding: '0px',
    display: 'block',
    minWidth: '240px !important',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    overflow: 'hidden',
    minHeight: '50px',
  },
  parent: {
    width: '100%',
    padding: '0 5px',
    '& .title': {
      color: customApp('medium'),
      fontSize: '10px',
      textTransform: 'uppercase'
    },
    '& .info': {
      marginTop: '-8px',
      color: customApp('color'),
      fontWeight: '500'
    }
  },
  indicators: {
    position: 'relative',
    float: 'left',
    width: '100%',
    padding: '0 5px',
    height: 'auto',
    display: 'flex',
  },
  indicatorsIcon: {
    margin: '0px 0px',
  },
  image: {
    position: 'relative',
    float: 'left',
    width: '100%',
    maxWidth: '100%',
    // paddingBottom: '48%',
    backgroundColor: 'rgb(0,0,0)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  progress: {
    position: 'relative',
    float: 'left',
    padding: '0 12px',
    width: 'calc(100% - 15x)'
  },
  description: {
    position: 'relative',
    float: 'left',
    padding: '5px',
  },
  dates: {
    position: 'relative',
    float: 'left',
    marginTop: '7px',
    padding: '0 5px',
    marginRight: '15px',
    color: customApp('medium'),
    fontSize: '10px',
    display: 'flex',
  },
  dateInfo: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center'
  },
  users: {
    position: 'absolute',
    bottom: '2px',
    right: '2px',
    fontSize: '10px',
    '& .MuiAvatarGroup-root': {
      justifyContent: 'flex-end',
    },
    '& .MuiAvatar-root': {
      width: '25px',
      height: '25px',
      fontSize: '10px',
    }
  }
};

export default style;

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
    fill: "none",
    d: "M0 5.22H6.6V6.32H0z"
}), _react.default.createElement("path", {
    d: "M8.8 5.22H15.4V6.32H8.8z"
}), _react.default.createElement("path", {
    d: "M17.6 5.22H24.200000000000003V6.32H17.6z"
}), _react.default.createElement("path", {
    d: "M26.4 5.22H33V6.32H26.4z"
}), _react.default.createElement("path", {
    d: "M0 0H6.6V3.03H0z"
}), _react.default.createElement("path", {
    d: "M8.8 0H15.4V3.03H8.8z"
}), _react.default.createElement("path", {
    d: "M17.6 0H24.200000000000003V3.03H17.6z"
}), _react.default.createElement("path", {
    d: "M26.4 0H33V3.03H26.4z"
}), _react.default.createElement("path", {
    d: "M0 7.58H6.6V8.68H0z"
}), _react.default.createElement("path", {
    d: "M8.8 7.58H15.4V8.68H8.8z"
}), _react.default.createElement("path", {
    d: "M17.6 7.58H24.200000000000003V8.68H17.6z"
}), _react.default.createElement("path", {
    d: "M26.4 7.58H33V8.68H26.4z"
}), _react.default.createElement("path", {
    d: "M0 9.94H6.6V11.04H0z"
}), _react.default.createElement("path", {
    d: "M8.8 9.94H15.4V11.04H8.8z"
}), _react.default.createElement("path", {
    d: "M17.6 9.94H24.200000000000003V11.04H17.6z"
}), _react.default.createElement("path", {
    d: "M26.4 9.94H33V11.04H26.4z"
}), _react.default.createElement("path", {
    d: "M0 12.3H6.6V13.4H0z"
}), _react.default.createElement("path", {
    d: "M8.8 12.3H15.4V13.4H8.8z"
}), _react.default.createElement("path", {
    d: "M26.4 12.3H33V13.4H26.4z"
}), _react.default.createElement("path", {
    d: "M0 14.65H6.6V15.75H0z"
}), _react.default.createElement("path", {
    d: "M8.8 14.65H15.4V15.75H8.8z"
}), _react.default.createElement("path", {
    d: "M26.4 14.65H33V15.75H26.4z"
}), _react.default.createElement("path", {
    d: "M0 17.01H6.6V18.110000000000003H0z"
}), _react.default.createElement("path", {
    d: "M8.8 17.01H15.4V18.110000000000003H8.8z"
}), _react.default.createElement("path", {
    d: "M26.4 17.01H33V18.110000000000003H26.4z"
}), _react.default.createElement("path", {
    d: "M0 19.37H6.6V20.470000000000002H0z"
}), _react.default.createElement("path", {
    d: "M26.4 19.37H33V20.470000000000002H26.4z"
})), 'SWOT');

exports.default = _default;
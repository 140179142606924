//CORE
import React from "react"

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import { customApp, translate } from "functions/"
import ButtonOptions from "componentsV3/Buttons"

export default function SelectCardTypes({ options = [], onChange = () => { } }) {
    const { db } = reduxStore.getState()
    return (
        <ButtonOptions
            text={translate("$__showActivitiesType")}
            icon={"collections_bookmark"}
            options={[
                ...db.type.filter(x => x.calendar).map(x => {
                    return {
                        name: translate(`${x.label}`, 1),
                        icon: x.icon,
                        iconColor: options.indexOf(x.value) > -1 ? 'lightgray' : customApp('menu'),
                        color: options.indexOf(x.value) > -1 ? 'lightgray' : customApp('menu'),
                        onClick: () => {
                            let types = Array.from(options)
                            if (types.indexOf(x.value) === -1) {
                                types.push(x.value)
                            } else {
                                types.splice(types.indexOf(x.value), 1)
                            }
                            onChange(types)
                        }
                    }
                }),
                {
                    name: options.length === db.type.filter(xx => xx.calendar).length ? translate(`$__showAll`, 1) : translate(`$__hideAll`, 1),
                    icon: 'clear_all',
                    iconColor: options.length !== db.type.filter(xx => xx.calendar).length ? 'lightgray' : customApp('menu'),
                    color: options.length !== db.type.filter(xx => xx.calendar).length ? 'lightgray' : customApp('menu'),
                    onClick: () => {
                        if (options.length === db.type.filter(xx => xx.calendar).length) {
                            onChange([])
                        } else {
                            let types = []
                            db.type.filter(xx => xx.calendar).forEach(xx => {
                                types.push(xx.value)
                            })
                            onChange(types)
                        }
                    }
                }
            ]}
        />
    )
}

import React, { memo } from "react";

//COMPONENTS
import Icon from "components/Icon";

//@MATERIAL
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { translate, customApp } from "functions/";

function ButtonOptions(props) {
    const useStyles = makeStyles((props) => ({
        iconButtonStyleTransaparent: {
            padding: "3px 3px 3px 3px",
            margin: 0,
            '&:hover': {
                backgroundColor: 'rgba(250,250,250,0.33)'

            }
        },
        iconButtonStyleWhite: {
            padding: "3px 3px 3px 3px",
            margin: 0,
            '&:hover': {
                backgroundColor: 'rgba(250,250,250,0.33)'
            },
            border: "solid 1px rgb(130,130,130)",
        },
        active: {
            padding: "3px 3px 3px 3px",
            margin: 0,
            '&:hover': {
                backgroundColor: 'rgba(250,250,250,0.33)'
            },
            border: `solid 1px ${customApp("color")}`,
            backgroundColor: "#FFFFFF",
        },
    }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null)

    const handleClose = (event) => {
        if ((
            event
            && event.target
            && event.target.className
            && typeof event.target.className === "string"
            && event.target.className.indexOf("ignoreClickAway") > -1
        )
            || (
                document.getElementById("MuiDialog-container")
                && document.getElementById("MuiDialog-container").querySelectorAll("div.ignoreClickAway")
            ))
            return
        setTimeout(() => {
            setOpen(false);
            if (props.onClose)
                props.onClose()
        }, 100)

    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
            if (props.onClose)
                props.onClose()
        }
    }

    return (
        <div
            style={{
                ...props.style ? props.style : {},
                ...props.onClick ? { cursor: "pointer" } : {}
            }}
            onClick={props.onClick ? () => { props.onClick() } : null}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                ...props.onClick ? { pointerEvents: "none" } : {}
            }}>

                <Tooltip
                    title={translate(`$__options`, 1)}
                >
                    {props.hideText ?
                        <IconButton
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            aria-label="menu"
                            onClick={(e) => {
                                setOpen((prevOpen) => !prevOpen);
                                if (!open && props.onOpen)
                                    props.onOpen()
                                if (open && props.onClose)
                                    props.onClose()
                                e.stopPropagation()
                            }}
                            className={props.active ? classes.active : !props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                        >
                            <Icon
                                usePropagation
                                size={!props.size ? "17px" : props.size}
                                color={props.btColor ? props.btColor : customApp('menu')}
                                icon={props.icon ? props.icon : !props.horizontalButton ? 'more_vert' : 'more_horiz'} />
                        </IconButton>
                        :
                        <div style={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                            <Button
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                aria-label="menu"
                                onClick={(e) => {
                                    setOpen((prevOpen) => !prevOpen);
                                    if (!open && props.onOpen)
                                        props.onOpen()
                                    if (open && props.onClose)
                                        props.onClose()
                                    e.stopPropagation()
                                }}
                                className={props.active ? classes.active : !props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        pointerEvents: "none",
                                        ...!props.icon ? { paddingLeft: 7 } : {}
                                    }}
                                >
                                    {props.icon ?
                                        <div style={{ marginTop: -2, marginRight: 7 }}>
                                            <Icon
                                                size={!props.size ? "17px" : props.size}
                                                color={props.btColor ? props.btColor : customApp('menu')}
                                                icon={props.icon} />
                                        </div> : <></>}
                                    <span
                                        style={{
                                            marginRight: '15px',
                                            color: props.textColor ? props.textColor : props.color ? props.color : customApp('menu'),
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            width: "90%",
                                            maxWidth: props.maxWidth ? props.maxWidth : "90%",
                                            whiteSpace: "nowrap"
                                        }}>{props.text || "BUTTON_NAME"}</span>
                                    {(props.popper || props.options) ?
                                        <Icon
                                            size={!props.size ? "17px" : props.size}
                                            color={props.btColor ? props.btColor : customApp('menu')}
                                            icon={'arrow_drop_down'}
                                        /> : <></>}

                                </div>
                            </Button>

                        </div>
                    }
                </Tooltip >
            </div>
            {(props.popper || props.options) ?
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    // role={undefined}
                    transition
                    // disablePortal
                    style={{ zIndex: "100 !important" }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                ...placement === 'bottom' ? { transformOrigin: 'center top' } : { transformOrigin: 'center bottom' },
                                zIndex: "100 !important",
                            }}
                        >
                            <Paper style={{
                                marginTop: 5
                            }}>
                                <ClickAwayListener onClickAway={(e) => {
                                    handleClose(e)
                                }} >
                                    {props.popper ?
                                        props.popper :
                                        props.options && props.options.length > 0 ?
                                            <MenuList
                                                autoFocusItem={open}
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                                style={{ zIndex: "100 !important" }}
                                            >
                                                {props.options
                                                    .filter(a => a && !a.hide)
                                                    .map((a, i) =>
                                                        <MenuItem
                                                            key={i}
                                                            style={{
                                                                color: a && a.color ? a.color : customApp('menu'),
                                                                padding: "12px 7px",
                                                            }}
                                                            className={"ignoreClickAway"}
                                                            onClick={(e) => {
                                                                if (props.stopPropagation)
                                                                    handleClose(e)
                                                                if (a.onClick)
                                                                    a.onClick()
                                                                if (props.onClose)
                                                                    props.onClose()
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                            }}
                                                            disabled={a.disabled ? true : false}
                                                        >
                                                            <div style={{
                                                                pointerEvents: 'none'
                                                            }}>
                                                                {a.render ?
                                                                    a.render
                                                                    : a.avatar ?
                                                                        <Avatar
                                                                            alt={a.name} src={a.avatar || null}
                                                                            style={{
                                                                                width: !props.size ? "20px" : props.size,
                                                                                height: !props.size ? "20px" : props.size
                                                                            }}>
                                                                            {a.name.substr(0, 1)}
                                                                        </Avatar>
                                                                        :
                                                                        <Badge
                                                                            overlap="rectangular"
                                                                            badgeContent={a.count ? a.count : null}
                                                                            color="secondary"
                                                                        >
                                                                            <Icon
                                                                                usePropagation
                                                                                icon={a.icon}
                                                                                style={{ margin: 'none !important' }}
                                                                                color={a.iconColor ? a.iconColor : a.color ? a.color : customApp('menu')} />
                                                                        </Badge>
                                                                }
                                                                {!a.render &&
                                                                    <span style={{ marginLeft: '7px' }}>
                                                                        {translate(a.name, 1)}
                                                                    </span>
                                                                }
                                                            </div>
                                                        </MenuItem>
                                                    )
                                                }
                                            </MenuList>
                                            : <></>
                                    }
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> : <></>
            }
        </div >
    )
}

export default memo(ButtonOptions)
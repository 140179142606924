import React from "react";
import { encode as base64_encode } from 'base-64';
import bcrypt from 'bcryptjs';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import CardHeader from "components/Card/CardHeader";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import IconButton from "components/CustomButtons/IconButton";
import LinearProgress from "components/Progress/linear"
import TextField from "components/TextField";

// styles
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";

//FUNCTIONS
import apims from "apims/"

import { translate, appAlert, customApp } from "functions/"

require('dotenv').config()
const LKEY = process.env.REACT_APP_PROD === "true" ? `${String(process.env.REACT_APP_KP)}` : `${String(process.env.REACT_APP_KL)}`
const useStyles = makeStyles(styles);

function LoginPage(props) {
  const [user, setUser] = React.useState({ email: null, password: null });
  const [token, setToken] = React.useState(null)
  const [reqTwoFactor, setReqTwoFactor] = React.useState(false)
  const [loginToken, setLoginToken] = React.useState(null)
  const [login, setLogin] = React.useState(false)
  const classes = useStyles();

  const emailLogin = async () => {
    if (
      (
        window.location.origin.indexOf('localhost') === -1
        && window.location.origin.indexOf('hom2022') === -1
        && window.location.origin.indexOf('homologation') === -1
        && window.location.origin.indexOf('a1b2c3d4') === -1
        && window.location.origin.indexOf('192.168') === -1
      )
      &&
      (
        (
          window.location.origin.indexOf('sonne.machen') > -1
          && user.email.indexOf('@sonne.global') === -1
        ) || (
          window.location.origin.indexOf('sonne.machen') === -1
          && user.email.indexOf('@sonne.global') > -1
        )
      )
    ) {
      props.callback({
        fn: 'alert',
        variant: "warning",
        horizontal: "center",
        message: `${translate(`$__emailDomainNotPermitedForThisAddress`, 1)}`
      })
    } else if (!user.email) {
      props.callback({
        fn: 'alert',
        variant: "warning",
        horizontal: "center",
        message: `${translate(`$__alertLoginEmptyEmail`, 1)}`
      })
    } else if (!user.password) {
      props.callback({
        fn: 'alert',
        variant: "warning",
        horizontal: "center",
        message: `${translate(`$__alertLoginEmptyPassword`, 1)}`
      })
    } else {
      setLogin(true)
      let pss = await bcrypt.hash(String(user.password), 10)
      let pss2 = await bcrypt.hash(String(user.password), 10)
      let newKey = base64_encode(`${pss2}//${LKEY}`)


      if (pss && pss2 && newKey)
        try {
          const res = await apims.post('/Login',
            base64_encode(base64_encode(JSON.stringify({
              lp: newKey,
              login: user.email,
              password: base64_encode(`${pss}///${base64_encode(user.password)}///${pss2}`),
            })))
          )
          if (res) {
            if (res.data && res.data.code === 400) {
              props.callback({
                fn: 'alert',
                variant: "warning",
                horizontal: "center",
                message: `${translate(`$__${res.data.error}`, 1)}`
              })
            } else if (res && res.data && res.data.twoFactor) {
              setLoginToken(res.data.token)
              setReqTwoFactor(true)
            } else if (res.data.token) {
              localStorage.setItem('TOKEN', res.data.token)
              if (props.callback) {
                props.callback({ fn: 'login', token: res.data.token })
              }
            }
          }

        } catch (err) {
          console.log(err)
          props.callback({
            fn: 'alert',
            variant: "warning",
            horizontal: "center",
            message: `${translate("$__alertServerConectionLogin", 1)}`
          })
        }
      setTimeout(() => {
        setLogin(false)
      }, 1500)
    }
  }


  const validToken = async () => {
    setLogin(true)
    if (!token || (token && token.length !== 6)) {
      appAlert({
        message: translate('$__invalidToken', 1),
        variant: 'warning',
        persist: false,
        horizontal: 'right',
      })
    } else {
      const res = await apims.post('/M_User_TwoFactor', {
        action: 'verify',
        code: token,
        token: loginToken
      })
      if (res && res.data && res.data.result) {
        localStorage.setItem('TOKEN', loginToken)
        if (props.callback) {
          props.callback({ fn: 'login', token: loginToken })
        }
      } else {
        appAlert({
          message: translate('$__invalidToken', 1),
          variant: 'warning',
          persist: false,
          horizontal: 'right',
        })
      }
      setToken(null)
    }
    setTimeout(() => {
      setLogin(false)
    }, 1500)
  }

  const recoveryPassword = async (email) => {
    if (!email) {
      props.callback({
        fn: 'alert',
        message: translate('$__loginAlertRecoveryEmailEmpty')
      })
    } else {
      try {
        const res = await apims.get(`/M_User_RecoveryPass/?email=${email}`)
        if (res && res.data) {
          props.callback({
            fn: 'alert',
            message: translate('$__loginAlertRecoveryEmailSended')
          })
        } else {
          props.callback({
            fn: 'alert',
            message: translate('$__loginAlertRecoveryInvalidEmail')
          })
        }
      } catch (err) {
        props.callback({
          fn: 'alert',
          message: translate('$__loginAlertRecoveryError')
        })
      }
    }
  }
  return (
    <div className={classes.container}>
      <GridContainer style={{ display: "flex", justifyContent: "center" }}>
        <GridItem xl={4} lg={4} md={4} sm={12} xs={12}>
          <Card db="cards" login>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="primary"
              style={{
                background: customApp("ColumnTitleColor"),
                boxShadow:
                  "0 4px 20px 0 rgba(15,18,35,0.14), 0 7px 10px -5px rgba(15,18,35,0.4)"
              }}
            >
              <h4 className={classes.cardTitle}>{translate('$__login')}</h4>
              {/* <div className={classes.socialLine}>
                  {[
                    "fab fa-facebook-square",
                    "fab fa-twitter",
                    "fab fa-google-plus"
                  ].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    );
                  })}
                </div> */}
            </CardHeader>
            <CardBody>
              {!reqTwoFactor ?
                <React.Fragment>
                  <CustomInput
                    labelText={translate('$__email')}
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        setUser({
                          ...user,
                          email: e.target.value
                        })
                      },
                      onLoad: e => {
                        setUser({
                          ...user,
                          email: e.target.value
                        })
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText={translate('$__password')}
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => {
                        setUser({
                          ...user,
                          password: e.target.value
                        })
                      },
                      onLoad: e => {
                        setUser({
                          ...user,
                          password: e.target.value
                        })
                      },
                      onKeyDown: (e) => {
                        if (e.keyCode === 13)
                          emailLogin()
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      type: "password",
                      autoComplete: "off"
                    }}
                  />
                  <IconButton
                    icon="lock"
                    text={translate('$__recoveryPass', '*')}
                    reverse
                    style={{ float: 'right' }}
                    onClick={() => {
                      recoveryPassword(user.email)
                    }}
                  />
                </React.Fragment>
                :
                <React.Fragment>
                  <fieldset>
                    <legend>{translate('$__token', 1)}</legend>
                    <TextField
                      labelText="token"
                      id="token_to_access"
                      name="token_to_access"
                      formControlProps={{
                        fullWidth: true
                      }}
                      value={token}
                      inputProps={{
                        onKeyDown: e => {

                        },
                        onChange: e => {
                          setToken(e.target.value)
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              pin
                            </Icon>
                          </InputAdornment>
                        ),
                        type: "number",
                        autoComplete: false
                      }}
                      autoComplete={'new-password'}
                    />
                  </fieldset>
                </React.Fragment>
              }
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              {!reqTwoFactor ?
                <Button style={{ backgroundColor: !login ? customApp("menu") : 'gray', color: "#FFFFFF" }} simple size="lg" block onClick={() => emailLogin()}>
                  {translate('$__sigin')}
                  {login ?
                    <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                      <LinearProgress />
                    </div>
                    : <React.Fragment></React.Fragment>
                  }
                </Button>
                :
                <Button style={{ backgroundColor: !login ? '#c0b283' : 'gray', color: "#FFFFFF" }} simple size="lg" block onClick={() => validToken()}>
                  {translate('$__validateTokenAndSigin')}
                  {login ?
                    <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
                      <LinearProgress />
                    </div>
                    : <React.Fragment></React.Fragment>
                  }
                </Button>
              }
              {/* <InteractiveButton
                  style={
                    {
                      color: 'rgba(15,18,35,1)'
                    }
                  }
                  concludedText={translate("$__redirecting")}
                  text={translate("$__login")}
                  onClick={() => emailLogin()}
                /> */}
            </CardFooter>
          </Card>
          {/* </form> */}
        </GridItem>
      </GridContainer>
    </div >
  );
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
import {
  defaultFont,
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const styles = (theme) => ({
  ...defaultFont,
  menu: {
    position: "absolute",
    borderRight: customApp("color"),
    background: customApp('ColumnTitleColor'),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // boxShadow: "inset 4px 0px 3px 0px rgb(0 0 0 / 17%)",
  },
  menuExpanded: {
    position: "absolute",
    borderRight: customApp("color"),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    paddingLeft: "15px",
    paddingRight: "15px",
    boxShadow: "inset 4px 0px 3px 0px rgb(0 0 0 / 17%)",
  },
  button: {
    padding: "7px 0px",
    display: "flex",
    justifyContent: "center"
  },
  buttonColor: {
    // position: "relative",
    // float: "left",
    padding: "12px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: customApp("color"),
    alignItems: "center",
    width: "-webkit-fill-available"
  },
  buttonColorExpanded: {
    position: "relative",
    float: "left",
    padding: "12px",
    display: "flex",
    backgroundColor: customApp("color"),
    alignItems: "center",
    width: "100%"
  },
  separator: {
    position: "relative",
    float: "left",
    width: "100%",
    padding: "7px",
    borderTop: `1px solid ${customApp("medium", 0.5)}`,
    marginTop: "15px",
    marginBottom: "0px",
    height: "1px"
  }
});

export default styles;

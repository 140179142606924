import React, { memo } from "react"
import MD5 from "crypto-js/md5"
import Routes from "routes";

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import KanbanBoard from "components/AgileKanban"
import CardsLoader from "components/Card/loader"
import Filter from "componentsV3/Filter/menuFilter"

//VIEWS
import Actions from "views/StrategyAndPlans/Actions"
import BrightAndConnected from "views/BrightAndConnected/"
import Dashboard from "components/Dashboards/Dashboard"
import Statistics from "views/StrategyAndPlans/Dashboard/tagsStatistics"
import InsightBoards from "views/InsightBoards"
import Library from "views/Library"
import Matrix from "views/StrategyAndPlans/MATRIX"
import Overview from "views/StrategyAndPlans/Overview"
import Radar from "views/Radar"

//DEPENDENCIES
import Plans from "./plans"
import HomePlans from "./homePlans"

import {
    planAccountVerify
} from "functions/cards"


function StrategyAndPlans(props) {
    const { session } = reduxStore.getState()

    React.useEffect(() => {
        const scrollTimer = setTimeout(() => {
            document.getElementById("mainPannel").scrollTo(0, 0)
        }, 100)
        return () => {
            clearTimeout(scrollTimer)
        }
    }, [props.history])

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()

    if (!window.location.hash.split("/")[0].replace("#", "")) {
        history.push("sp#tab/home")
    }

    let permission = true

    if (
        hashs[0]
        && hashs[0].length === 36
        && !reduxStore.getState().db.cards[hashs[0]]
    ) {
        permission = false
    }

    if (
        hashs[0]
        && hashs[0].length === 36
        && reduxStore.getState().db.cards[hashs[0]]
        && !reduxStore.getState().db.cards[hashs[0]].isTemplate
        && (
            reduxStore.getState().db.cards[hashs[0]]._users
            && (
                !reduxStore.getState().db.cards[hashs[0]]._users[session._id]
                && (
                    reduxStore.getState().db.cards[hashs[0]]._account
                    && reduxStore.getState().db.cards[hashs[0]]._account !== MD5(session.account).toString()
                )
            )
        )
    )
        permission = false

    if (planAccountVerify(hashs[0]))
        permission = planAccountVerify(hashs[0])

    if (!permission) {
        history.push("/deniedAccess")
        return (
            <React.Fragment></React.Fragment>
        )
    }

    if (hashs[0].length === 36 && reduxStore.getState().db.cards[hashs[0].replace("#", "")] && hashs[1] && hashs[1] === "board")
        return (<KanbanBoard nodeId={hashs[0] || null} />)
    const hasFilter = Routes.filter(a => {
        if (hashs[1] && a.tabs && a.tabs.filter(tf => tf.path === hashs[1]).length > 0 && a.tabs.filter(tf => tf.path === hashs[1])[0].search === false)
            return false
        if (a && a.path === window.location.pathname && a.search)
            return true
        return false
    }).length > 0
    return (
        <div style={{
            height: "-webkit-fill-available"
        }}>

            {hasFilter ?
                <div style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    right: 0,
                    zIndex: 1,
                    paddingLeft: 15,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <Filter
                        fields={['text', 'date', 'tags', 'priority', 'unit', 'usersMode', 'users']}
                        showFilters
                        nodeId={searchID}
                    />
                    <div style={{ position: "relative", width: "100%", display: "flex" }}>
                        <CardsLoader />
                    </div>
                </div> : <></>}
            <div style={{
                position: "absolute",
                top: hasFilter ? 60 : 0,
                left: 0,
                bottom: 0,
                right: 0,
                overflow: "auto",
            }}>
                {(!hashs[0] || (hashs[0] && hashs[0].replace("#", "").length !== 36)) && hashs[1] === "home" ?
                    <React.Fragment>
                        <HomePlans />
                    </React.Fragment>
                    : (!hashs[0] || (hashs[0] && hashs[0].replace("#", "").length !== 36)) && hashs[1] === "completed" ?
                        <React.Fragment>
                            <HomePlans completed={true} />
                        </React.Fragment>
                        :
                        (!hashs[0]
                            ||
                            (hashs[0]
                                && hashs[0].replace("#", "").length !== 36
                            )
                        )
                        &&
                        <Plans />
                }
                {hashs[0].length === 36 && reduxStore.getState().db.cards[hashs[0].replace("#", "")] ?
                    <React.Fragment>
                        {hashs[1] && hashs[1] === "matrix" &&
                            <Matrix />
                        }
                        {hashs[1] && (hashs[1] === "actions" || hashs[1] === "guidelines" || hashs[1] === "objectives" || hashs[1] === "goals")
                            &&
                            <Actions type={hashs[1]} />
                        }
                        {hashs[1] && hashs[1] === "dashboard" &&
                            <Dashboard />
                        }
                        {hashs[1] && hashs[1] === "statistics" &&
                            <Statistics />
                        }
                        {hashs[1] && hashs[1] === "overview" &&
                            <Overview />
                        }
                        {hashs[1] && hashs[1] === "bright-and-connected" &&
                            <div style={{ marginTop: 30 }}>
                                <BrightAndConnected />
                            </div>
                        }
                        {hashs[1] && hashs[1] === "insight-boards" &&
                            <div style={{ marginTop: 30 }}>
                                <InsightBoards />
                            </div>
                        }
                        {hashs[1] && hashs[1] === "radar" &&
                            <div style={{ marginTop: 30 }}>
                                <Radar />
                            </div>
                        }
                        {hashs[1] && hashs[1] === "library" &&
                            <div style={{ padding: 15 }}>
                                <Library />
                            </div>
                        }
                        {hashs[1] && hashs[1] === "planningBoard" &&
                            <div style={{ padding: 15 }}>
                                <iframe title="outModule"
                                    style={{ position: "absolute", width: "calc(100% - 30px)", height: "100%", border: 'none' }}
                                    src={`https://miro.com/app/live-embed/${reduxStore.getState().db.cards[hashs[0]].miroBoard}=/?embedAutoplay=true&moveToViewport=-23165,-5837,13803,7546`}
                                ></iframe>
                            </div>
                        }
                        {/* <ErrorBoundary>
                        <ReactTour />
                    </ErrorBoundary> */}
                    </React.Fragment>
                    :
                    <React.Fragment>
                    </React.Fragment>
                }
            </div>
        </div >
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(StrategyAndPlans))
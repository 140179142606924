//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import LinearProgress from "components/Progress/linear"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card-KPI.js"

//FUNCTIONS
import {
    translate,
    customApp,
} from "functions/"

function Component(props) {
    // console.log('loading', Object.keys(props.loaders).filter(l => props.loaders[l] === "loading").length)
    return (
        <React.Fragment>
            {props.loaders
                && Object.keys(props.loaders).length > 0
                && Object.keys(props.loaders).filter(l => props.loaders[l] === "loading").length > 0
                &&
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <span id = "loadBar" style={{ color: customApp('menu'), fontSize: "12px", paddingRight: "15px", whiteSpace: "nowrap" }}>{translate('$__loadingData')}</span>
                    <LinearProgress />
                </div>
            }
        </React.Fragment>
    )
}
const mapStateToProps = (store) => ({
    loaders: store.sessionLoads.cards.load
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//@MATERIAL
import Badge from "@material-ui/core/Badge"

import { countUnRead } from "functions/chat"

function CountIcon(props) {
    const count = countUnRead()
    document.title = `${count && count.total > 0 ? `(${count.total}) ` : ``}MACHEN`

    return (
        <React.Fragment>
            <Badge badgeContent={count && count.total ? count.total : 0} color="secondary"
                overlap="rectangular"
            >
                {props.children}
            </Badge>
        </React.Fragment>
    )
}

const mapStateToProps = (store) => ({
    timeline: store.timeline
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CountIcon)
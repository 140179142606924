import React, { useState, useEffect, useRef } from 'react'

//COMPONENTS
import Pagination from '@material-ui/lab/Pagination'
import ButtonOptions from 'componentsV3/Buttons';
import Filter from 'componentsV3/Filter';
import Header from '../Header'
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import Icon from 'components/Icon'
import Card from "components/Card"

//FUNCTIONS
import moment from "moment-timezone"
import { translate } from "functions"

//REDUX
import reduxStore from "store/"



const DayTasks = (props) => {
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()
    const [hover, setHover] = useState(false)
    const [dueActivities_page, setDueActivities_page] = useState(1)
    const [filter, setFilter] = useState("")
    const taskRef = useRef()
    const [height, setHeight] = useState(0)

    useEffect(() => {
        if (!taskRef.current) return;


        const resizeObserver = new ResizeObserver(() => {
            let containerItemCount = taskRef.current.clientHeight / 42

            setHeight((containerItemCount / 2) - 1)
        });
        resizeObserver.observe(taskRef.current);
        return () => resizeObserver.disconnect(); // clean up 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ height: '100%' }}>
            <UiCard style={{ marginTop: '0px', height: '100%', overflow: 'hidden', borderRadius: '0px' }}>
                <div>
                    {props.edit ?
                        <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                            <Icon size={24} style={{ alignSelf: 'center', marginLeft: '12.5px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>pending_actions</Icon>
                            <p style={{ margin: '0px', marginLeft: '7.5px', alignSelf: 'center', fontWeight: 'bold', marginTop: '1px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>{translate(`$__todayTasks`, 1)}</p>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    close
                                </Icon>
                            </div>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    open_with
                                </Icon>
                            </div>
                        </div> : <Header
                            hover={hover}
                            tittle={translate(`$__todayTasks`, 1)}
                            icon={'pending_actions'}
                            datafilter={false}
                            styles={props.styles}
                        />
                    }
                    {(hover && !props.edit) && <ButtonOptions
                        text={"Buscar"}
                        icon={"search"}
                        popper={
                            <div style={{ padding: '15px' }}>
                                <Filter
                                    fields={['text']}
                                    showFilters
                                    style={{ position: 'absolute', zIndex: '999' }}
                                    defaultValues={{
                                        text: ''
                                    }}
                                    onChange={(e) => e.text && setFilter(e?.text)}
                                />
                            </div>
                        }
                        style={{
                            alignSelf: 'center',
                            position: 'absolute',
                            right: '40px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            top: '7px'
                        }}
                    />}
                </div>
                <UiCardBody style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div ref={taskRef} style={{ overflowY: 'scroll', height: `calc(100% - 70px)`, overflowX: 'hidden' }}>
                        {Object.keys(props.planCards).filter((a, i) => {
                            if (
                                db.cards[a]._planId === hashs[0]
                                && !db.cards[a].deleted
                                && db.cards[a].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
                                && db.cards[a]._users
                                && (
                                    Object.keys(db.cards[a]._users).filter(user => db.cards[a]._users[user].myDay
                                        && moment(new Date(db.cards[a]._users[user].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
                                    ).length > 0
                                )
                            )
                                return true
                            return false
                        })
                            .filter((card, i) => i >= (dueActivities_page - 1) * height && i < dueActivities_page * height)
                            .map((card, i) => {
                                return <Card
                                    db="cards"
                                    key={card}
                                    data={db.cards[card]}
                                />
                            }
                            )}
                    </div>
                    {Math.ceil(Object.keys(props.planCards).filter(a =>
                        db.cards[a]._planId === hashs[0]
                        && !db.cards[a].deleted
                        && db.cards[a].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
                        && db.cards[a]._users
                        && (
                            Object.keys(db.cards[a]._users).filter(user => db.cards[a]._users[user].myDay
                                && moment(new Date(db.cards[a]._users[user].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
                            ).length > 0
                        )
                    ).length / height) > 1 &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30px' }} >
                            <Pagination
                                page={dueActivities_page}
                                count={Math.ceil(Object.keys(props.planCards).filter(a =>
                                    db.cards[a]._planId === hashs[0]
                                    && !db.cards[a].deleted
                                    && db.cards[a].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
                                    && db.cards[a]._users
                                    && (
                                        Object.keys(db.cards[a]._users).filter(user => db.cards[a]._users[user].myDay
                                            && moment(new Date(db.cards[a]._users[user].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
                                        ).length > 0
                                    )
                                ).length / height)}
                                onChange={(e, p) => {
                                    setDueActivities_page(p)
                                }}
                            />
                        </div>
                    }
                </UiCardBody>
            </UiCard>
        </div>
    )
}

export default DayTasks
import moment from "moment-timezone";
import { v4 as uuid } from "uuid";
import MD5 from "crypto-js/md5";

//REDUX
import reduxStore from "store/";

import apims from "apims/";
import api from "api/"

import { loadCardsV2 } from "./loadData"

import { customApp, translate } from "./index"

require('dotenv').config()

const clearReadeds = async (props, d) => {
    const { session, timeline, chat } = reduxStore.getState()

    let messages = timeline[d.nodeId]
    let nodeId = d.nodeId
    let toDelete = []

    if (d.chat) {
        nodeId = chat.nodeId
    }

    if (reduxStore.getState().db.cards[nodeId] && reduxStore.getState().db.cards[nodeId].type !== 'chatGroup' && messages && Object.keys(messages).length > 0) {
        Object.keys(messages)
            .filter(msg =>
                messages[msg]
                && messages[msg].type
                && messages[msg].type === d.type
                && messages[msg].readedBy
                && messages[msg].readedBy[session._id]
            )
            .sort((a, b) => {
                if (parseInt(messages[a].created_at) > parseInt(messages[b].created_at))
                    return -1
                if (parseInt(messages[a].created_at) < parseInt(messages[b].created_at))
                    return 1
                return 0
            })
            .forEach(msg => {
                if (toDelete.indexOf(msg) === -1)
                    toDelete.push(msg)
            })
    } else if (reduxStore.getState().db.cards[nodeId] && reduxStore.getState().db.cards[nodeId].type === 'chatGroup') {
        Object.keys(messages)
            .filter(msg =>
                messages[msg]
                && messages[msg].readedBy
                && messages[msg].readedBy[session._id]
            )
            .sort((a, b) => {
                if (parseInt(messages[a].created_at) > parseInt(messages[b].created_at))
                    return -1
                if (parseInt(messages[a].created_at) < parseInt(messages[b].created_at))
                    return 1
                return 0
            })
            .forEach((msg, msgi) => {
                if (msgi > 14 && toDelete.indexOf(msg) === -1)
                    toDelete.push(msg)
            })
    }
    let newMessages = { ...reduxStore.getState().timeline }
    toDelete.forEach(msg => {
        Object.keys(newMessages).forEach(tm => {
            if (newMessages[tm][msg])
                delete newMessages[tm][msg]
        })
    })
    props.reduxFunction("ASYNC", "SET_TIMELINE", {
        ...newMessages
    });
}

const chatReaded = async (props, data) => {
    let newTimeline = {}
    const { db, session } = reduxStore.getState()
    if (
        reduxStore.getState().timeline[data.id]
        || reduxStore.getState().timeline[data.readedBy]
    ) {
        newTimeline = reduxStore.getState().timeline

        let nodeId = data.id
        let user = {}
        if (data.db !== 'cards') {
            nodeId = newTimeline[data.readedBy] ? data.readedBy : data.id
            user = {
                _id: data.readedBy,
                name: db.users[data.readedBy].name,
                image: db.users[data.readedBy].image
            }
        } else if (db.cards[data.id] && db.cards[data.id]._users && db.cards[data.id]._users[data.readedBy]) {
            user = db.cards[data.id]._users[data.readedBy]
        }


        if (newTimeline && newTimeline[nodeId]) {
            Object.keys(newTimeline[nodeId]).filter(m => {

                if (
                    data
                    && data.type
                    && (
                        (
                            data.type !== "all"
                            && newTimeline[nodeId][m].type === data.type
                        )
                        ||
                        (
                            data.type === "all"
                        )
                    )
                    && newTimeline[nodeId][m].user._id !== data.readedBy
                    &&
                    (
                        !newTimeline[nodeId][m].readedBy
                        || (
                            session
                            && session._id
                            && newTimeline[nodeId][m].readedBy
                            && !newTimeline[nodeId][m].readedBy[session._id]
                        )
                    )) {
                    return true
                }
                return false
            }).forEach((m, mi) => {
                if (newTimeline[nodeId] && newTimeline[nodeId][m])
                    newTimeline[nodeId][m] = {
                        ...newTimeline[nodeId][m] || {},
                        readedBy: {
                            ...newTimeline[nodeId] && newTimeline[nodeId][m] && newTimeline[nodeId][m].readedBy ? newTimeline[nodeId][m].readedBy : {},
                            [data.readedBy]: {
                                ...newTimeline[nodeId] && newTimeline[nodeId][m] && newTimeline[nodeId][m].readedBy && newTimeline[nodeId][m].readedBy[data.readedBy] ? newTimeline[nodeId][m].readedBy[data.readedBy] : {},
                                ...user,
                                readedDate: {
                                    low: moment().unix('x')
                                }
                            }
                        }
                    }
            })
        }
    }

    props.reduxFunction("ASYNC", "SET_TIMELINE", {
        ...newTimeline
    });

    if (
        reduxStore.getState().db.cards[data.id]
        && data.type
        && reduxStore.getState().db.cards[data.id]._notifications
        && (
            reduxStore.getState().db.cards[data.id]._notifications[data.type]
            || (
                data.type === "all"
            )
        )
    ) {
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                [data.id]: {
                    ...reduxStore.getState().db.cards[data.id],
                    ...data.type === 'all' ? {
                        notifications: {}
                    } : {
                        _notifications: {
                            ...reduxStore.getState().db.cards[data.id] && reduxStore.getState().db.cards[data.id]._notifications ? reduxStore.getState().db.cards[data.id]._notifications : {},
                            [data.type]: 0,
                        }
                    },

                }
            }
        })
    }
}

const interact = async (props, data) => {
    const { db, timeline, session } = reduxStore.getState()
    let user = {}

    let nodeId = data.nodeId !== session._id ? data.nodeId : data.user

    if (db.users[data.user]) {
        user = db.users[data.user]
    } else if (data.user && db[data.db] && timeline[nodeId] && timeline[nodeId]._users && timeline[nodeId]._users[data.user]) {
        user = timeline[nodeId]._users[data.user]
    }

    if (nodeId && data.message && timeline && timeline[session._id] && timeline[session._id][data.message])
        nodeId = session._id

    if (db[data.db] && timeline[nodeId] && timeline[nodeId][data.message]) {
        let reactions = {
            ...timeline[nodeId][data.message]
                && timeline[nodeId][data.message]._reactions
                ? timeline[nodeId][data.message]._reactions
                : {},
            [data.react]: {
                ...timeline[nodeId][data.message]
                    && timeline[nodeId][data.message]._reactions
                    && timeline[nodeId][data.message]._reactions[data.react] ?
                    timeline[nodeId][data.message]._reactions[data.react] : {},
                [data.user]: {
                    ...user,
                    reactDate: {
                        low: moment().tz('America/Sao_Paulo').unix('x')
                    }
                }
            }
        }
        Object.keys(reactions).forEach(a => {
            if (a !== data.react)
                Object.keys(reactions[a]).forEach(u => {
                    if (reactions[a][u] && u === data.user)
                        delete reactions[a][u]
                })
        })
        let newData = {
            ...timeline,
            [nodeId]: {
                ...timeline[nodeId],
                [data.message]: {
                    ...timeline[nodeId][data.message],
                    _reactions: reactions
                }
            }
        }
        props.reduxFunction("ASYNC", "SET_TIMELINE", newData);
    }
}

export const timelineComment = async (props, dt) => {
    let verifyReg = await new Promise(async (resolve, reject) => {
        if (dt && dt.db === 'cards' && !reduxStore.getState().db.cards[dt.idRel]) {
            const loadNewCard = await loadCardsV2(props, {
                idRel: dt.idRel,
                cardLoad: "fullCatenation",
                ignoreLoader: true
            })
            if (loadNewCard)
                resolve(true)
        } else {
            resolve(true)
        }

    })
    if (verifyReg) {
        let data = dt
        const { session } = reduxStore.getState()
        let nodeId = data._parent ? data._parent : data.cardId ? data.cardId : data.node
        if (nodeId === session._id)
            nodeId = data.comment.user._id

        if (data.to) {
            data.to.forEach(idUser => {
                let user = reduxStore.getState().db.users[idUser]
                data._toUser = {
                    ...data._toUser ? data._toUser : {},
                    [idUser]: {
                        _id: idUser,
                        name: user.displayName ? user.displayName : user.name,
                        image: user.image ? user.image : null
                    }
                }
            })
            delete data.to
        }
        console.log()
        if (nodeId
            && data.comment
        ) {
            props.reduxFunction("ASYNC", "SET_TIMELINE", {
                ...reduxStore.getState().timeline,
                [nodeId]: {
                    ...reduxStore.getState().timeline[nodeId] || {},
                    [data.comment._id]: {
                        ...reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id] ? reduxStore.getState().timeline[nodeId][data.comment._id] : {},
                        ...data.comment,
                        ...data._toUser ? { _toUser: data._toUser } : {},
                        type: reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id] && reduxStore.getState().timeline[nodeId][data.comment._id].type ? reduxStore.getState().timeline[nodeId][data.comment._id].type : data && data.comment && data.comment.type ? data.comment.type : reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id] && reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id].type ? reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id].type : "comment",
                        readedBy: {
                            ...reduxStore.getState().timeline[nodeId] && reduxStore.getState().timeline[nodeId][data.comment._id] && reduxStore.getState().timeline[nodeId][data.comment._id].readedBy ? reduxStore.getState().timeline[nodeId][data.comment._id].readedBy : {},
                            ...data.comment && data.comment.readedBy ? data.comment.readedBy : {},
                        },
                        idRel: null
                    }
                }
            });
        }
        return true
    }
}

const loadMessages = async (data) => {
    const { session } = reduxStore.getState()
    let timelines = {}
    try {
        let req = await apims.post(`/Timeline_Get`,
            {
                ...data,
            })

        let unreadeds = {}

        let load = await new Promise((resolve, reject) => {
            if (req && req.data && req.data.length > 0) {
                req.data.forEach((tm, mi) => {
                    let readedBy = {}
                    let reactions = {}

                    if (tm && tm.params && tm.params.readedBy && tm.params.readedBy.filter(u => u._id).length > 0)
                        tm.params.readedBy.forEach(u => {
                            readedBy = {
                                ...readedBy,
                                [u._id]: u
                            }
                        })

                    if (tm && tm.params && tm.params.reactions && tm.params.reactions.filter(u => u._id).length > 0)
                        tm.params.reactions.forEach(r => {
                            reactions = {
                                ...reactions,
                                [r.type]: {
                                    ...reactions[r.type],
                                    [r._id]: r,
                                }
                            }
                        })

                    if (parseInt(tm.unreaded) > 0)
                        unreadeds = {
                            ...unreadeds,
                            [tm.nodeId !== session._id ? tm.nodeId : tm.params.user._id]: parseInt(tm.unreaded)
                        }

                    let timelineId = tm.nodeId !== session._id ? tm.nodeId : tm.params.user._id

                    timelines = {
                        ...timelines,
                        [timelineId]: {
                            ...timelines && timelines[timelineId] ? timelines[timelineId] : {},
                            [tm.data._id]: {
                                idRel: tm.nodeId,
                                ...tm.data,
                                ...tm.params,
                                readedBy: readedBy,
                                _reactions: reactions,
                                sended: true,
                                ...tm.data && tm.data.filesCount && (!tm.data.files || tm.data.files.length < parseInt(tm.data.filesCount)) && tm.data.localFiles ? { localFiles: JSON.parse(tm.data.localFiles), sendedFiles: false } : { localFiles: [], sendedFiles: true }
                            }
                        }
                    }
                    if (mi + 1 === req.data.length)
                        resolve(true)
                })
            } else {
                resolve(true)
            }
        })
        if (load) {
            return {
                timelines,
                unreadeds
            }
        }
    } catch (e) {
        return {
            timelines: null,
            unreadeds: null
        }
    }
}

const loadTimeline = async (props, data) => {
    let lastMessageDate = reduxStore.getState().chat?.lastMessageDate || 0;

    let loadMessages = reduxStore.getState().timelineLoader
    if (data.type && data.type === "kpi") {
        let reqMd5 = MD5(`${JSON.stringify(data)}`).toString()
        if (loadMessages[reqMd5])
            return {
                result: true,
                total: 0
            }
        props.reduxFunction("ASYNC", "SET_TIMELINE_LOADER", {
            ...loadMessages,
            [reqMd5]: true,
        });
    }

    const { ids, db, type, limit = 0, skip = 0, toId = null, ini = false, reqChildrens = false, newUpdates = false } = data
    const { session } = reduxStore.getState()

    let nodeId = []
    let nodes = []
    if (typeof ids === 'string') {
        nodes.push(ids)
    } else {
        nodes = ids
    }

    if (nodes && nodes.length > 0)
        nodes.forEach(a => {
            if (nodeId.indexOf(a) === -1) {
                nodeId.push(a)
            }
        })

    if (nodeId.length > 0) {
        const load = await new Promise(async (resolve, reject) => {
            if (nodeId.length > 0) {
                try {
                    let reqTimeline = await apims.post(`/M_Timeline_Get_V3`,
                        {
                            nodesIds: nodeId,
                            db,
                            type: type ? type : 'comment',
                            skip: parseInt(skip),
                            limit: limit,
                            toId,
                            ini,
                            newUpdates,
                            reqChildrens,
                            lastDate: newUpdates ? data.lastDate ? data.lastDate : lastMessageDate : null
                        }
                    )
                    if (reqTimeline && reqTimeline.data && reqTimeline.data.length > 0) {
                        let timelines = { ...reduxStore.getState().timeline }
                        let chatOpenId = null
                        reqTimeline.data.forEach(tm => {
                            let idRel = tm.nodeId !== session._id ? tm.nodeId : tm.params.user._id
                            let readedBy = timelines
                                && timelines[idRel]
                                && timelines[idRel][tm.data._id]
                                && timelines[idRel][tm.data._id].readedBy ? timelines[idRel][tm.data._id].readedBy : {}

                            let reactions = {}


                            if (tm && tm.params && tm.params.readedBy && tm.params.readedBy.filter(u => u._id).length > 0)
                                tm.params.readedBy.forEach(u => {
                                    readedBy = {
                                        ...readedBy,
                                        [u._id]: u
                                    }
                                })

                            if (tm && tm.params && tm.params.reactions && tm.params.reactions.filter(u => u._id).length > 0)
                                tm.params.reactions.forEach(r => {
                                    reactions = {
                                        ...reactions,
                                        [r.type]: {
                                            ...reactions[r.type],
                                            [r._id]: r,
                                        }
                                    }
                                })
                            let toUsers = {}
                            if (tm && tm.params && tm.params.users && tm.params.users.filter(a => a.name).length > 0)
                                tm.params.users.filter(a => a.name).forEach(a => {
                                    toUsers = {
                                        ...toUsers,
                                        [a._id]: a
                                    }
                                })
                            if (session && session._id && readedBy && !readedBy[session._id] && timelines[idRel])
                                Object.keys(timelines[idRel]).forEach(msg => {
                                    if (
                                        timelines[idRel][msg]
                                        && timelines[idRel][msg].readedBy
                                        && timelines[idRel][msg].readedBy[session._id]
                                        && parseInt(timelines[idRel][msg].created_at) > parseInt(tm.data.created_at)

                                    )
                                        readedBy = {
                                            ...readedBy,
                                            [session._id]: {
                                                ...timelines[idRel][msg].readedBy[session._id]
                                            }
                                        }
                                })

                            if (
                                (
                                    data.ini
                                    || data.newUpdates
                                )
                                && parseInt(tm.data.created_at) > parseInt(lastMessageDate)
                            ) {
                                lastMessageDate = parseInt(tm.data.created_at)
                                if (
                                    (
                                        reduxStore.getState().db.cards[idRel]
                                        && reduxStore.getState().db.cards[idRel].type === "chatGroup"
                                    )
                                    ||
                                    (
                                        reduxStore.getState().db.users[idRel]
                                    )
                                )
                                    chatOpenId = idRel
                            }

                            timelines = {
                                ...timelines,
                                [idRel]: {
                                    ...timelines && timelines[idRel] ? timelines[idRel] : {},
                                    [tm.data._id]: {
                                        ...tm.data,
                                        ...tm.params,
                                        ...tm.data.type === 'timer' && String(tm.data.ini) === "0" ? {
                                            manualEntry: true,
                                            ini: parseInt(tm.data.created_at),
                                            fin: parseInt(tm.data.created_at) + parseInt(tm.data.fin),
                                        } : {},
                                        readedBy: readedBy,
                                        _reactions: reactions,
                                        ...Object.keys(toUsers).length > 0 ? { _toUser: toUsers } : {},
                                    }
                                }
                            }
                        })
                        if (!props.noReg)
                            props.reduxFunction("ASYNC", "SET_TIMELINE", {
                                ...reduxStore.getState().timeline,
                                ...timelines,
                            });

                        resolve({
                            result: true,
                            total: reqTimeline.data.length,
                            timelines: timelines,
                            chatOpenId,
                            lastMessageDate
                        })
                    } else {
                        resolve({
                            result: true,
                            total: reqTimeline.data.length,
                        })
                    }

                } catch (e) {
                    console.group('loadTimeline::ERR::')
                    console.log(e)
                    console.groupEnd()
                }
            }
        })
        if (
            load
            && (
                load.chatOpenId
                || load.lastMessageDate
            )
        ) {
            props.reduxFunction("IMMEDIATE", "SET_CHAT", {
                ...reduxStore.getState().chat,
                ...(data.ini || data.newUpdates) && load.lastMessageDate ? { lastMessageDate: load.lastMessageDate } : {},
                ...data.ini && load.chatOpenId ? { nodeId: load.chatOpenId } : {},
            })
        }
        if (load)
            return load
    } else {
        return {
            result: true,
            total: 0
        }
    }
}

const countUnRead = () => {
    const { db, session, timeline } = reduxStore.getState()
    let unReadCount = 0
    let unReadCountGroup = 0
    let unReadCountExternalGroups = 0
    let unReadCountUser = 0
    let unReadCountExternalUser = 0

    Object.keys(timeline).forEach(id => {

        Object.keys(timeline[id]).forEach(m => {
            if (
                timeline[id]
                && timeline[id][m]
                && timeline[id][m].type
                && timeline[id][m].type === "comment"
                && !timeline[id][m].deleted
                && timeline[id][m].user
                && timeline[id][m].user._id
                && timeline[id][m].user._id !== session._id

                && (
                    !timeline[id][m].readedBy
                    || (
                        timeline[id][m].readedBy
                        && !timeline[id][m].readedBy[session._id]
                    )
                )
            ) {
                if (
                    id
                    && db.cards[id]
                    && db.cards[id].type === 'chatGroup'
                )
                    unReadCountGroup = unReadCountGroup + 1

                if (
                    id
                    && db.users[id]
                    && db.users[id].type === "user"
                )
                    unReadCountUser = unReadCountUser + 1
                unReadCount = unReadCount + 1
            }
        })
    })

    return {
        total: unReadCountGroup + unReadCountUser + unReadCountExternalGroups + unReadCountExternalUser,
        groups: unReadCountGroup,
        externalGroups: unReadCountExternalGroups,
        users: unReadCountUser,
        externalUsers: unReadCountExternalUser
    }
}

const deleteMessage = async (props, data) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }

    const comm = await new Promise(async (resolve, reject) => {
        socket.emit("data", {
            module: "chat",
            method: "put",
            action: "cancel"
        },
            data,
            (req) => {
                let idRel = data.idRel
                const { session } = reduxStore.getState()
                if (reduxStore.getState().timeline[session._id] && reduxStore.getState().timeline[session._id][data.id]) {
                    idRel = session._id
                }
                props.reduxFunction("ASYNC", "SET_TIMELINE", {
                    ...reduxStore.getState().timeline,
                    [idRel]: {
                        ...reduxStore.getState().timeline[idRel],
                        [data.id]: {
                            ...reduxStore.getState().timeline && reduxStore.getState().timeline[idRel] && reduxStore.getState().timeline[idRel][data.id],
                            deleted: true,
                            ...data.deletedReason ? { deletedReason: data.deletedReason } : {}
                        }
                    }
                });
                resolve(true)
            })
    })
    if (comm)
        return comm
}

const updateTimelineMessage = (props, data) => {
    // console.log(data)
}

export const mentionedUsers = (text, storie) => {
    const { db, session } = reduxStore.getState()
    let cardId
    if (storie && storie._parent && db.cards[storie._parent]) {
        cardId = storie._parent
    } else if (storie && storie.idRel && db.cards[storie.idRel]) {
        cardId = storie.idRel
    }

    let newText
    newText = text
    if (text) {
        const test1 = text.split("@[")
        if (test1.length > 0)
            test1.map(a => {
                if (a) {
                    let toReplace = `@[${a.split(")")[0]})`
                    let id = a.split("](")[1]
                    if (id) {
                        id = id.split(")")[0]
                        if (
                            id
                            && id.length === 36
                            && db.cards[cardId]
                            && db.cards[cardId]._users
                            && db.cards[cardId]._users[id]
                        ) {
                            let userName = db.cards[cardId]._users
                                && db.cards[cardId]._users[id]
                                && db.cards[cardId]._users[id].name
                                ? db.cards[cardId]._users[id].name
                                : db.cards[cardId]._users
                                    && db.cards[cardId]._users[id]
                                    && db.cards[cardId]._users[id].name
                                    ? db.cards[cardId]._users[id].name
                                    : a.split("](")[0]
                            if (
                                session
                                && session.GlobalData
                                && session.GlobalData.hideDeletedUsers
                                && reduxStore.getState().db.users[id]
                                && reduxStore.getState().db.users[id].deleted
                            )
                                userName = translate("$__deletedUser")
                            newText = newText.replace(toReplace, `<span style="color:${customApp("menu")}"><b>${userName}</b></span>`)
                        } else if (
                            id
                            && id.length === 36
                            && db.users[id]
                        ) {
                            let userName = db.users[id] && db.users[id].name
                                ? db.users[id].name : a.split('](')[0]
                            if (
                                session
                                && session.GlobalData
                                && session.GlobalData.hideDeletedUsers
                                && reduxStore.getState().db.users[id]
                                && reduxStore.getState().db.users[id].deleted
                            ) userName = translate("$__deletedUser")
                            newText = newText.replace(toReplace, `<span style="color:${customApp("menu")}"><b>${userName}</b></span>`)
                        } else {
                            let userName = a.split('](')[0]
                            if (
                                session
                                && session.GlobalData
                                && session.GlobalData.hideDeletedUsers
                                && reduxStore.getState().db.users[id]
                                && reduxStore.getState().db.users[id].deleted
                            ) userName = translate("$__deletedUser")
                            newText = newText.replace(toReplace, `<span style="color:${customApp("menu")}"><b>${userName}</b></span>`)
                        }
                    }
                }
                return true
            })
    }

    return newText
}

const forwardMessage = (props, data) => {
    let dataMessage = {
        ...data,
        forwardedId: data._id,
        _id: uuid(),
        created_at: `${moment().tz('America/Sao_Paulo').unix('x')}`
    }

    if (dataMessage._reactions)
        delete dataMessage._reactions

    if (dataMessage.readedBy)
        delete dataMessage.readedBy

    if (dataMessage._reactions)
        delete dataMessage._reactions

    if (dataMessage._toUser)
        delete dataMessage._toUser


    data._forwardToId.forEach(idRel => {
        timelineComment(props, {
            comment: {
                ...dataMessage,
                idRel: idRel,
            },
            node: idRel
        })

        api.post("timeline/add", {
            ...dataMessage,
            idRel: idRel
        })
    })


}

export {
    chatReaded,
    countUnRead,
    forwardMessage,
    deleteMessage,
    interact,
    loadTimeline,
    loadMessages,
    updateTimelineMessage,
    clearReadeds,
}
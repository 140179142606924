import React, { memo } from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import ErrorBoundary from "components/ErrorBoundary"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import TextField from "components/TextField"
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import ProgressLinear from "components/Progress/linear"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"


//FUNCTIONS
import { translate, appAlert, customApp } from "functions/"

import apims from "apims"
import api from "api"

function App(props) {
    const [data, setData] = React.useState({})
    const [secretView, setSecretView] = React.useState(false)
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        reqIntegrationToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const scrollTimer = setTimeout(() => {
            document.getElementById("mainPannel").scrollTo(0, 0)
        }, 100)
        return () => {
            clearTimeout(scrollTimer)
        }
    }, [props.history])

    const reqIntegrationToken = async (n = false) => {
        let ntk = n
        let result = await apims.post("/ReqIntegrationToken")
        if (!ntk && result && result.data?.token) {
            setData({
                secretKey: `Bearer ${result.data.token}`
            })
        } else {
            let result = await api.post("/api/token")
            if (result)
                setData({
                    secretKey: `Bearer ${result.data.token}`
                })
        }
        setLoading(false)
    }


    return (
        <GridContainer>
            <GridItem
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
            >
                <div
                    style={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        borderRadius: 2,
                        boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)",
                        paddingBottom:"15px",
                    }}
                >
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                        justifyContent: "center",
                        borderBottom: "#e8e8e8 1px solid",
                        padding: "7px",
                        color: customApp('colorText'),
                        background: customApp('ColumnTitleColor'),
                        }}
                    >
                        <Icon style={{color: customApp('color')}}>integration_instructions</Icon>
                        <span
                        style={{
                            fontWeight: "bold",
                        }}
                        >
                            {translate('$__integrations')}
                        </span>
                    </div>
                    <ErrorBoundary>
                        {loading ?
                            <ProgressLinear />
                            :
                            <div>
                                <fieldset style={{
                                    wordBreak: "break-word",
                                    margin:'15px',
                                }}>
                                    <legend>
                                        {translate("$__authorizationToken")}
                                    </legend>
                                    <div style={{
                                        position: "relative",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <TextField
                                            label={translate('$__authorizationToken', 1)}
                                            variant={customApp('fieldVariant')}
                                            name={'secretKey'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                multiline: true,
                                                minRows: 3,
                                                autoComplete: 'new-password',
                                                type: secretView ? "text" : "password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <div style={{
                                                            cursor: "pointer",
                                                            marginRight: 7
                                                        }}
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(data.secretKey);
                                                            }}
                                                        >
                                                            <Icon icon='content_copy' />
                                                        </div>
                                                        <Icon icon={secretView ? 'visibility_off' : 'visibility'} onClick={() => {
                                                            setSecretView(prev => !prev)
                                                        }} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={`${data && data.secretKey ? data.secretKey : ''}`}
                                            placeholder={translate('$__secretKey', 1)}
                                            autoFocus
                                            disabled={true}
                                        />
                                    </div>
                                    <i>{translate("$__secretKeyDescription")}</i>
                                </fieldset>
                                <div style={{
                                    display: "none",
                                    justifyContent: "flex-end",
                                }}>
                                    <CustomButton
                                        // shadow
                                        title={translate("$__newToken")}
                                        text={translate("$__newToken")}
                                        color="green"
                                        // tr
                                        icon={"refresh"}
                                        size={"25px"}
                                        onClick={() => {
                                            appAlert({
                                                message: translate("$__confirmApiTokenRevokeAndRecreate", 1),
                                                variant: "warning",
                                                persist: false,
                                                horizontal: "right",
                                                confirm: () => {

                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div >
                        }
                    </ErrorBoundary>
                </div>
            </GridItem>
        </GridContainer>
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(App))
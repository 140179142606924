import React from 'react';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: '#44b700',
        color: '#44b700',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const StyledBadgeAway = withStyles((theme) => ({
    badge: {
        backgroundColor: '#ffb100',
        color: '#ffb100',
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: '$ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}))(Badge);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function BadgeAvatars(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {(!props.status || props.status === 'online') ?
                <StyledBadge
                    overlap="circular"
                    anchorOrigin={props.anchorOrigin ? props.anchorOrigin : {
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    variant={props.status ? "dot" : "standard"}
                >
                    {props.children}
                </StyledBadge>
                :
                <StyledBadgeAway
                    overlap="circular"
                    anchorOrigin={props.anchorOrigin ? props.anchorOrigin : {
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    variant={props.status ? "dot" : "standard"}
                >
                    {props.children}
                </StyledBadgeAway>
            }
        </div>
    );
}

const mapStateToProps = (store, props) => {
    return ({
        status: store.usersStatus && store.usersStatus.users && store.usersStatus.users[props.userId] && store.usersStatus.users[props.userId].status ? store.usersStatus.users[props.userId].status : null,
    })
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BadgeAvatars)
//CORE
import React from "react"
import Nestable from "react-nestable";

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment-timezone"

//COMPONENTS
import ErrorBoundary from "components/ErrorBoundary"
import ColorPicker from "components/ColorPicker"
import CustomButton from "components/Buttons/custom"
import Dialog from "components/Dialog"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import TextField from "components/TextField"
import Preloader from "components/PreLoader"
import ReactExport from "react-export-excel"
import Filter from "componentsV3/Filter/"

import RenderTag from "./renderTag"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { makeStyles } from "@material-ui/core/styles"

//FUNCTIONS
import api from "api"
import apims from "apims"

import {
    tagUpdate,
    tagLink,
} from "functions/tags";

import {
    translate,
    customApp,
    getAppAccess,
    removeSeats,
} from "functions/"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/tagsMenu"
import "./styles.css"

function Component(props) {
    const useStyles = makeStyles(styles)
    const classes = useStyles()
    const { db, session } = reduxStore.getState()
    const { tag } = props
    const [mounted, setMounted] = React.useState(false)
    const [editor, setEditor] = React.useState(false)
    const [showAll, setShowAll] = React.useState(false)
    const [data, setData] = React.useState({})
    const [saveLoading, setSaveLoading] = React.useState(false)
    const [search, setSearch] = React.useState(null)
    const [tags, setTags] = React.useState([])
    const [loader, setLoader] = React.useState(false)
    const [exportComponent, setExportComponent] = React.useState(null)
    const [showAllTags, setShowAllTags] = React.useState(true)


    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    let hashs = window.location.hash.split("/")
    if (hashs.length > 0)
        hashs[0] = hashs[0].replace("#", "")

    React.useEffect(() => {
        if (!mounted) {
            setMounted(true)
            mountTags()
            if (props.onLoad)
                props.onLoad()
        }
        return () => {
            // return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        mountTags()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, JSON.stringify(props.tag)])

    let AppAccess = getAppAccess()

    const mountTags = () => {
        let newTags = []
        tag.filter(a => {
            let searchLabel = search ? removeSeats(search.toLowerCase().trim()) : ``
            let tagLabel = removeSeats(a.label.toLowerCase().trim())
            if (
                !a.parent
                &&
                (
                    !search
                    ||
                    (
                        search
                        && tagLabel.indexOf(searchLabel) > -1
                    )
                ))
                return true
            return false
        })
            .sort((a, b) => {
                if (removeSeats(a.label.toLowerCase().trim()) < removeSeats(b.label.toLowerCase().trim()))
                    return -1
                if (removeSeats(a.label.toLowerCase().trim()) > removeSeats(b.label.toLowerCase().trim()))
                    return 1
                return 0
            }).forEach(ta => {
                let a = ta
                a._count = Object.keys(db.cards).filter(card => db.cards[card]._users && db.cards[card]._users[session._id] && db.cards[card]._tags && db.cards[card]._tags.filter(cta => cta.value === a.value).length > 0).length > parseInt(a._count) ? Object.keys(db.cards).filter(card => db.cards[card]._users && db.cards[card]._users[session._id] && db.cards[card]._tags && db.cards[card]._tags.filter(cta => cta.value === a.value).length > 0).length : parseInt(a._count)
                a._countOthers = Object.keys(db.cards).filter(card => db.cards[card]._users && !db.cards[card]._users[session._id] && db.cards[card]._tags && db.cards[card]._tags.filter(cta => cta.value === a.value).length > 0).length > parseInt(a._countOthers) ? Object.keys(db.cards).filter(card => db.cards[card]._users && !db.cards[card]._users[session._id] && db.cards[card]._tags && db.cards[card]._tags.filter(cta => cta.value === a.value).length > 0).length : parseInt(a._countOthers)
                if (
                    newTags.filter(tag =>
                        tag.id === a.label.substr(0, 1)
                    ).length === 0
                ) {
                    let achi = []
                    if (tag.filter(ca => ca.parent && ca.parent === a.value).length > 0) {
                        tag.filter(ca => ca.parent && ca.parent === a.value).forEach(xx => {
                            if (achi.filter(aa => aa.value === xx.value).length === 0)
                                achi.push({
                                    id: xx.value,
                                    text: xx.label,
                                    ...xx
                                })
                        })
                    }
                    newTags.push({
                        id: a.label.substr(0, 1), text: a.label.substr(0, 1),
                        children: [{
                            id: a.value,
                            text: a.label,
                            ...a,
                            children: achi
                        }]
                    })
                } else {
                    if (
                        newTags.filter(t => t.id === a.label.substr(0, 1)).length > 0
                        && newTags.filter(t => t.id === a.label.substr(0, 1))[0].children.filter(c => c.value === a.value).length === 0
                    ) {
                        let achi = []
                        if (tag.filter(ca => ca.parent && ca.parent === a.value).length > 0) {
                            tag.filter(ca => ca.parent && ca.parent === a.value).forEach(xx => {
                                if (achi.filter(aa => aa.value === xx.value).length === 0)
                                    achi.push({
                                        id: xx.value,
                                        text: xx.label,
                                        ...xx
                                    })
                            })
                        }
                        newTags.filter(t => t.id === a.label.substr(0, 1))[0].children.push({
                            id: a.value,
                            text: a.label,
                            ...a,
                            children: achi
                        })
                    }
                }
            })
        if (JSON.stringify(newTags) !== JSON.stringify(tags))
            setTags(newTags)
    }

    const clearEditor = () => {
        setData({})
        setSaveLoading(false)
        setEditor(false)
    }



    const editTag = (e) => {
        setEditor(true)
        if (e) {
            setData(e)
        } else {
            setData({})
        }
    }

    const saveTag = async () => {
        setSaveLoading(true)
        if (data.value) {
            try {
                const response = await api.put("tag/update", {
                    _id: data.value,
                    name: data.name ? data.name : data.label,
                    color: data.newColor ? data.newColor : data.color ? data.color : null
                })
                if (response) {
                    tagUpdate(props, { value: data.value, label: data.name ? data.name : data.label, color: data.newColor ? data.newColor : data.color }, true)
                    setSaveLoading(false)
                    clearEditor()
                }
            } catch (e) {
                console.log(e)
                setSaveLoading(false)
            }
        } else {
            try {
                const response = await api.post("tag/add", {
                    name: data.name,
                    color: data.newColor ? data.newColor : data.color ? data.color : null
                })
                if (response && response.data) {
                    setShowAll(true)
                    tagUpdate(props, response.data, true)
                    setSaveLoading(false)
                    clearEditor()
                }
            } catch (e) {
                console.log(e)
                setSaveLoading(false)
            }
        }
    }

    const exportTagReport = async () => {
        setLoader(true)
        const reqReport = await apims.post("Rel_TagUser_Used")
        if (reqReport) {
            //GERAR EXCEL
            setLoader(false)
            setExportComponent(
                <ExcelFile
                    filename={`${moment(new Date()).tz('America/Sao_Paulo').format('YYYY-MM-DDTHHMM')}_tagReport`}
                    element={
                        <CustomButton
                            title={translate("$__export")}
                            text={translate("$__export")}
                            icon={"file_download"}
                            size={"25px"}
                        />
                    }
                >
                    <ExcelSheet
                        data={reqReport.data}
                        name="timeSheet">
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__user', 1)}`} value="user" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__tag', 1)}`} value="tag" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__activity', 1)}`} value="card" />
                        <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__status', 1)}`} value="status" />
                    </ExcelSheet>
                    {/* {reqReport.data.map((dt, dti) => {
                        return (
                            <ExcelSheet data={dt} name={dt.user} key={dti}>
                                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__user', 1)}`} value="user" />
                                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__tag', 1)}`} value="tag" />
                                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__activity', 1)}`} value="card" />
                                <ExcelColumn style={{ alignment: { wrapText: true } }} label={`${translate('$__status', 1)}`} value="status" />
                            </ExcelSheet>
                        )
                    })} */}

                </ExcelFile>
            )
        }
    }

    return (
        <ErrorBoundary>
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    height: "90vh"
                }}
            >
                <div className={classes.menu}
                    style={{
                        backgroundColor: "rgba(250,250,250,0.7)",
                        position: "relative",
                        width: "100%",
                        overflow: "hidden",
                        padding: 15
                    }}
                >
                    <HeaderWithIcon
                        title={translate("$__tagList", 1)}
                        icon={"bookmarks"}
                        iconTitle={translate("$__tagList", 1)}
                        color={customApp("colorText")}
                        fontSize={"16px"}
                        moreButton
                        options={[
                            {
                                name: "$__addTag",
                                icon: "add_box",
                                color: customApp("color"),
                                show: "hover",
                                onClick: () => {
                                    editTag()
                                },
                            },
                            AppAccess && AppAccess.plan && AppAccess.planAdmin && !showAll &&
                            {
                                name: "$__showAllTags",
                                icon: "style",
                                color: customApp("color"),
                                show: "hover",
                                onClick: () => {
                                    setShowAll(true)
                                },
                            },
                            showAll &&
                            {
                                name: "$__onlyMyTags",
                                icon: "loyalty",
                                color: customApp("color"),
                                show: "hover",
                                onClick: () => {
                                    setShowAll(false)
                                },
                            },
                            {
                                name: showAllTags ? "$__showFilters" : "$__showTagList",
                                icon: showAllTags ? "filter_alt" : "bookmarks",
                                color: customApp("color"),
                                show: "hover",
                                onClick: () => {
                                    // setShowAll(false)
                                    setShowAllTags(prev => !prev)
                                },
                            },
                            AppAccess && AppAccess.plan && AppAccess.planAdmin &&
                            {
                                name: "$__exportReport",
                                icon: "print",
                                color: customApp("color"),
                                show: "hover",
                                onClick: () => {
                                    exportTagReport()
                                },
                            },
                        ]}
                        optionsProps={{
                            transparent: true
                        }}
                    />
                    {exportComponent &&
                        exportComponent
                    }
                    <div
                        className={classes.separator}
                    ></div>
                    {!showAllTags ?
                        <div style={{ display: "flex", position: "relative", float: "left", padding: 7, width: "100%" }}>
                            <Filter
                                nodeId={"tagsView"}
                                customLabels={{
                                    text: "$__"
                                }}
                                fields={['tags', 'date', 'users', 'status', 'priority', 'impact', 'risk', 'complexity', ...AppAccess && AppAccess.plan && AppAccess.planAdmin ? ['admin'] : []]}
                                showFilters
                                mode={"modal"}
                                fieldsProps={{
                                    users: {
                                        allPlataform: true,
                                        selectedShow: {
                                            internal: true,
                                            external: false
                                        }
                                    }
                                }}
                            />
                        </div>
                        :
                        <div style={{ display: "flex", position: "relative", float: "left", clear: "both", width: "100%" }}>
                            <TextField
                                label={translate("$__search", 1)}
                                variant={customApp("fieldVariant")}
                                name={"name"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: "new-password",
                                    endAdornment: search ? (
                                        <InputAdornment position="end">
                                            <Icon icon={'cancel'} color="green" onClick={() => setSearch(null)} />
                                        </InputAdornment>
                                    ) : null,
                                    onChange: (d) => {
                                        setSearch(d.target.value)
                                    },
                                }}
                                placeholder={translate("$__nameOfTagSearch_placeholder", 1)}
                            />
                        </div>
                    }
                </div>
                {showAllTags &&
                    <div
                        style={{
                            position: "absolute",
                            top: 150,
                            bottom: 0,
                            overflow: "auto",
                            padding: "3px",
                            paddingTop: "7px",
                            listStyle: "none",
                            width: "100%",
                        }}
                    >
                        <Nestable
                            collapse="ALL"
                            items={tags}
                            maxDepth={3}
                            renderItem={(e) => <RenderTag data={e} editTag={(e) => { editTag(e) }} />}
                            onChange={(a) => {
                                let newItems = a.items
                                let target = newItems[a.targetPath[0]].children[a.targetPath[1]]

                                if (!a.dragItem.parent && a.dragItem.id === target.id)
                                    return false

                                tagLink(props, {
                                    _id: a.dragItem.id,
                                    target: target.id
                                })
                                setTags(newItems)
                            }}
                        />
                    </div>
                }
                <Dialog
                    fullWidth={true}
                    aria-labelledby="simple-dialog-title"
                    open={editor}
                    style={{ overflow: "visible !important" }}
                >
                    <div style={{ position: "relative", marginLeft: "10px", marginTop: '5px', width: "calc(100% - 10px)" }}>
                        <HeaderWithIcon
                            title={data.value ? translate("$__editTag", 1) : translate("$__newTag", 1)}
                            icon="label"
                            onCancel={() => { setEditor(false) }}
                        />
                    </div>
                    <div style={{ color: "#CCCCCC", padding: "15px" }}>
                        Etiquetas permitem que você adicione palavras-chave às atividades (Tarefas, Insights, Notícias), organizando-as e permitindo melhor navegação e praticidade no seu dia a dia.
                    </div>
                    <div style={{ padding: "15px" }}>
                        <TextField
                            error={(data && (!data.label || data.label.length === 0)) ? true : false}
                            // helperText={"Campo de nome da etiqueta deve ter o compimento mínimo de 1 caractere"}
                            label={translate("$__name", 1)}
                            variant={customApp("fieldVariant")}
                            name={"name"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: "new-password",
                                onChange: (d) => {
                                    setData({
                                        ...data,
                                        name: d.target.value
                                    })
                                },
                            }}
                            defaultValue={data && data.label ? data.label : null}
                            placeholder={translate("$__nameOfTag_placeholder", 1)}
                        />

                    </div>
                    <div style={{ display: "flex", padding: "15px", overflow: "visible" }}>
                        <span style={{ marginRight: "7px" }}>{translate("$__tagColor")}:</span>
                        <ColorPicker
                            disablePortal
                            color={data.color}
                            onChange={(c) => {
                                setData({
                                    ...data,
                                    newColor: c
                                })

                            }
                            }>
                            {(data.color || data.newColor) ?
                                <div
                                    className={'tag'}
                                    style={{
                                        backgroundColor: data.newColor ? data.newColor : data.color,
                                        width: "22px",
                                        height: "22px",
                                        borderRadius: "50%",
                                        cursor: "pointer"
                                    }}
                                >
                                </div>
                                :
                                <div
                                    className={'tag'}
                                    style={{
                                        fontWeight: "bold"
                                    }}
                                >
                                    {translate("$__clickHereToAddColor")}
                                </div>
                            }

                        </ColorPicker>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", padding: "15px" }}>
                        <CustomButton
                            shadow
                            title={translate("$__cancel")}
                            text={translate("$__cancel")}
                            color="lightgrey"
                            icon={"close"}
                            size={"25px"}
                            onClick={() => {
                                setEditor(false)
                            }}
                        />
                        {
                            data
                            && (
                                data.name
                                ||
                                data.newColor
                            )
                            &&
                            <div style={{ marginLeft: "15px" }}>
                                <CustomButton
                                    shadow
                                    title={translate("$__save")}
                                    text={translate("$__save")}
                                    icon={"save"}
                                    size={"25px"}
                                    load={saveLoading ? true : false}
                                    onClick={() => {
                                        saveTag()
                                    }}
                                />
                            </div>
                        }
                    </div>
                </Dialog>
                {loader ?
                    <Preloader fullScreen />
                    : <React.Fragment></React.Fragment>
                }
            </div>
        </ErrorBoundary >
    )
}

const mapStateToProps = (store) => ({
    tag: store.db.tag
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)
//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
//COMPONENTS
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users";

import {
    appAlert,
    translate,
} from "functions/";

import GooglePlayQR from "assets/img/googlePlay-ptBR.png"
import AppleStoreQR from "assets/img/appleStore-ptBR.png"

function Component(props) {
    const [reqSave] = React.useState(false)



    const close = () => {
        if (reqSave) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE");
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE");
        }
    }

    return (
        <div>
            <SideModuleCloseAndSave
                close={() => close(true)}
            />
            <div style={{
                position: "relative",
                width: "100%",
                textAlign: "center",
                padding: '15px',
            }}>
                <div style={{ textAlign: 'left' }}>
                    <p>{translate('$__mobileTokenTip_1')}</p>
                    <ul>
                        <li>{translate('$__mobileTokenTip_2')}</li>
                        <li>{translate('$__mobileTokenTip_3')}</li>
                        <li>{translate('$__mobileTokenTip_4')}</li>
                    </ul>
                </div>
                <img alt="MACHEN_IMAGE" src={AppleStoreQR} style={{ width: "52%" }} />
                <img alt="MACHEN_IMAGE" src={GooglePlayQR} style={{ width: "52%", marginTop: "33px" }} />
                {/* {(!tokenData.status && token && token.length === 6) &&
                        <div
                            style={{
                                display: 'flex',
                                padding: '7px 15px',
                                margin: 'auto',
                                cursor: 'pointer',
                                backgroundColor: 'green',
                                marginTop: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '7px'
                            }}
                            onClick={() => {
                                verify()
                            }}
                        >
                            <IconButton
                                icon="check"
                                text={translate("$__activeToken", '*')}
                                title={!translate("$__activeToken", '*')}
                                iconColor={customApp('lightText')}
                                textColor={customApp('lightText')}
                            />
                        </div>
                    } */}
            </div>
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
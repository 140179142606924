//FUNCTIONS
import { customApp } from "functions";

const sidebarStyle = theme => ({
  bar: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '147px',
    height: 'calc(100vh - 0px)'
  },
  item: {
    height: '47px',
    alignItems: 'center',
    width: '100%',
    display: 'flex'
  },
  itemButtom: {
    width: '100%',
    padding: '7px',
    color: customApp('menuButtonColor', 0.8),
    '&:hover': {
      color: customApp('menuButtonColor', 1),
      backgroundColor: 'rgba(255,255,255,0.1)',
      borderRadius: '0px',
    },
    display: 'flex',
    fontSize: "12px",
    fontWeight: "bold",
    alignItems: 'center',
    cursor: 'pointer',
  },
  itemButtomActive: {
    width: '100%',
    padding: '7px',
    color: customApp('menuButtonColor', 1),
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: '0px',
    display: 'flex',
    alignItems: 'center',
    fontSize: "12px",
    fontWeight: "bold",
    // flexDirection: 'column',
    // textAlign: 'center',
    cursor: 'pointer',
  },
  themeExample: {
    [theme.breakpoints.up("md")]: {
      left: "auto !important",
      right: "0 !important"
    },
    [theme.breakpoints.down("sm")]: {
      left: "0  !important",
      right: "auto !important"
    }
  },
  module: {
    position: 'fixed',
    top: '0px',
    left: '147px',
    width: '50px',
    height: '100vh',
    backgroundColor: 'rgba(246,246,246,1)',
    boxShadow: 'inset 4px 0px 3px 0px rgb(0 0 0 / 17%)',
    borderRight: `solid 4px ${customApp('color')}`,
  },
  moduleExpanded: {
    position: 'fixed',
    top: '0',
    left: '147px',
    width: '311px',
    height: '100vh',
    backgroundColor: 'rgba(246,246,246,1)',
    zIndex: '100 !important',
    boxShadow: 'inset 4px 0px 3px 0px rgb(0 0 0 / 17%)',
    borderRight: `solid 4px ${customApp('color')}`,
    padding: '7px',
    overflowX: 'auto'
  },
  moduleTitle: {
    width: '100%',
    height: '30px',
    fontSize: '18px',
    color: customApp('menuButtonColor'),
    fontWeight: 'bold',
    paddingLeft: '7px',
    marginTop: '15px'
  },
  sidebarWrapperWithPerfectScrollbar: {
    // overflow: "hidden !important"
  },
  separator: {
    position: 'relative',
    float: 'left',
    width: '100%',
    padding: '7px',
    borderTop: `1px solid ${customApp('medium', 0.5)}`,
    marginTop: '15px',
    marginBottom: '0px',
    height: '1px'
  },
  logoImg: {
    width: "66%",
    marginTop: '15px',
    justifyContent: 'center'
  },
  user: {
    zIndex: '150 !important'
  }
});

export default sidebarStyle;

import React from 'react';
import { v4 as uuid } from "uuid";

//@MATERIAL
import { withStyles } from '@material-ui/core/styles';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import Button from "components/CustomButtons/Custom";
import Navigation from "components/Navigation";


//FUNCTIONS
import { translate, appAlert } from "functions/";
import { loadCards } from "functions/loadData";
import { cardMoveTo } from "functions/cards";
import { timelineComment } from "functions/chat";

import api from "api"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/SpeedDial";

function Component(props) {
    const { classes } = props;
    const [typeFilter, setTypeFilter] = React.useState({ name: 'plan' });
    const [selectCard, setSelectCard] = React.useState(null);
    const { db, session } = reduxStore.getState();
    const { cards } = db;
    const { data } = reduxStore.getState().sideModule;

    React.useEffect(() => {
        (async () => {
            await loadCards(props, null, false, null, 'plans', false)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const myRef = React.useRef(null)
    const dataTypeOptions = []

    db.dataType.filter(fil => fil.sendTo).forEach(a => {
        dataTypeOptions.push({ name: a.name })
    })

    const AppAccess = db.users[Object.keys(db.users).find(a => a.indexOf(session._id) > -1)]._access

    let CardAccess = 0

    if (session._id
        && db.cards[data._id]
        && db.cards[data._id]._users
        && db.cards[data._id]._users[Object.keys(db.cards[data._id]._users).find(a => a.indexOf(session._id) > -1)])
        CardAccess = db.cards[data._id]._users[Object.keys(db.cards[data._id]._users).find(a => a.indexOf(session._id) > -1)]

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let cardsResults = cards

    cardsResults = Object.keys(cardsResults).filter(fil => {
        if (
            !cardsResults[fil].archived
            && !cardsResults[fil].deleted
            &&
            (
                (
                    cardsResults[fil]._users
                    && cardsResults[fil]._id !== data._id
                    && cardsResults[fil]._users[session._id]
                    && parseInt(cardsResults[fil]._users[session._id].access) > 2
                )
                ||
                ((
                    AppAccess
                    &&
                    (
                        AppAccess.owner
                        || AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                ) ||
                    (
                        CardAccess
                        && CardAccess.access
                        && parseInt(CardAccess.access) > 2
                    )
                )
            )
        )
            return true
        return false
    }).map(a => cardsResults[a])
    //}

    if (typeFilter)
        cardsResults = Object.keys(cardsResults).filter(fil => {
            if (cardsResults[fil] && cardsResults[fil].type && cardsResults[fil].type === typeFilter.name)
                return true
            return false
        }).map(a => cardsResults[a])

    cardsResults = Object.keys(cardsResults).filter(fil =>
        data.type
        && cardsResults[fil].type
        && db.dataType.filter(a => a.name === data.type)
        && db.dataType.filter(a => a.name === data.type).length > 0
        && db.dataType.filter(a => a.name === data.type)[0].sharedIn
        && (
            db.dataType.filter(a => a.name === data.type)[0].sharedIn.copy.indexOf(cardsResults[fil].type) > -1
            || db.dataType.filter(a => a.name === data.type)[0].sharedIn.attach.indexOf(cardsResults[fil].type) > -1
            || db.dataType.filter(a => a.name === data.type)[0].sharedIn.shared.indexOf(cardsResults[fil].type) > -1
        )
    ).map(a => cardsResults[a])

    const shareThis = async () => {
        let dataSave = {
            _id: uuid(),
            cardId: data._id,
            idRel: selectCard,
            _parent: selectCard,
            type: 'comment',
            message: '',
            shared: true
        }
        timelineComment(props, {
            ...dataSave,
            user: {
                ...db.users[session._id]
            },
            files: {}
        })

        api.post("timeline/add", dataSave)
        setSelectCard(null)
        setTypeFilter({ name: 'plan' })
    }

    const moveTo = async () => {
        appAlert({
            message: translate('$__confirmMoveCard', 1),
            variant: 'warning',
            persist: false,
            horizontal: 'right',
            confirm: () => {
                let dataSave = {
                    cardId: data._id,
                    newRel: selectCard,
                    planId: db.cards[selectCard]._planId ? db.cards[selectCard]._planId : db.cards[selectCard].type === 'plan' ? selectCard : null,
                    oldRel: data._parent
                }
                if (cardMoveTo(props, dataSave)) {
                    setSelectCard(null)
                    setTypeFilter({ name: 'plan' })
                }
            }
        })
    }

    return (
        <div className={classes.root}>
            <div style={{ width: '100%', padding: '15px', position: "relative", clear: "both" }} ref={myRef}>
                {selectCard &&
                    <React.Fragment>
                        <fieldset style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <legend>{translate('$__action')}</legend>
                            <Button
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                    margin: '3px'
                                }}
                                onClick={() => shareThis()}
                            >
                                {translate('$__share', '*')}
                            </Button>
                        </fieldset>
                        <fieldset style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <legend>{translate('$__AttachInCard')}</legend>
                            <Button
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                    margin: '3px'
                                }}
                                onClick={() => moveTo()}
                            >
                                {translate('$__Attach', '*')}
                            </Button>
                        </fieldset>
                        <fieldset style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                style={{ position: 'relative', float: 'left', margin: '3px' }}
                                color={'red'}
                                onClick={() => {
                                    setSelectCard(null)
                                }}
                            >
                                {translate('$__cancel', '*')}
                            </Button>
                        </fieldset>
                    </React.Fragment>
                }
            </div>
            <div style={{ width: '100%', padding: '15px' }} >
                <Navigation
                    styles={{
                        height: "auto",
                        maxHeight: selectCard ? "calc(100vh - 400px)" : "none"
                    }}
                    onSelect={(e) => {
                        setSelectCard(e._id)
                    }}
                    ignoreTypes={['chatGroup']}
                />
            </div>

        </div>
    );
}

//REACT
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Component));

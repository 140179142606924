import React, { useState } from 'react'
import { GetHeader, GetButton } from './getElements'
import GridContainer from '../Grid/GridContainer';
import Griditem from '../Grid/GridItem'

import { translate } from "functions"

const Intro = ({ handleNextScreen, handleName, handleClose }) => {

    const [disabled, setDisabled] = useState(true);


    const handleChange = (event) => {
        const novoValor = event.target.value.slice(-1); // Pega apenas o último caractere digitado
        novoValor ? setDisabled(false) : setDisabled(true)
    };

    return (
        <>
            <GetHeader prev={false} name={translate("$__insertThePlanName")} handleClose={handleClose} />
            <GridContainer container spacing={0} style={{ width: '92%', marginLeft: '4%' }}>
                <Griditem item xs={12} style={{ marginTop: "30px" }}>
                    <p style={{ outline: 'none', alignSelf: 'center', fontWeight: 'bold', width: '99.3%', backgroundColor: 'transparent', border: 'none', fontSize: '12px', marginBottom: '4px', marginLeft: '2px', marginTop: '6px' }} >
                        {translate("$__planName")}
                    </p>
                    <input
                        onChange={handleChange}
                        onBlur={(e) => handleName(e.target.value)}
                        placeholder={translate("$__insertThePlanNamePlaceholder")}
                        style={{
                            height: '35px',
                            color: '#7c828d',
                            marginBottom: '5px',
                            outline: 'none',
                            alignSelf: 'center',
                            width: '99.3%',
                            backgroundColor: 'transparent',
                            border: 'none',
                            borderBottom: '1px solid #d1d1cf',
                            fontSize: '16px'
                        }} >
                    </input>
                </Griditem>
                <Griditem item xs={12} style={{ marginTop: "45px" }}>
                    <GetButton
                        handleEvent={handleNextScreen}
                        //onMouseEnter={() => { setButtonHover(false) }}
                        //onMouseLeave={() => { setButtonHover(true) }}
                        id="intro_45"
                        name={translate("$__next")}
                        disabled={disabled}
                        style={{
                            width: '40%',
                            marginLeft: '30%',
                            marginBottom: '45px',
                            // backgroundColor: buttonBackground,
                            border: "2px solid #ebebeb",
                        }}>
                    </GetButton>
                </Griditem>
            </GridContainer>
        </>
    )
}

export default Intro
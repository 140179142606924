import { takeLatest, takeEvery, put, call, all } from 'redux-saga/effects'; //, select, delay

function callBackFn(fn) {
    if (fn.next) {
        fn.next()
    }
    return true
}

function* generate(data) {
    yield put({ type: data.action, value: data.value })
    yield call(callBackFn, data)
}


export default function* saga() {
    yield all([
        takeLatest("ASYNC", generate),
        takeEvery("IMMEDIATE", generate),
    ])
}
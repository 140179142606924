import React, { useState } from "react";

//COMPONENTES
import Icon from "components/Icon";
import { MessageEditor } from "components/MessageEditor/MessageEditor";

//FUNCTIONS
import { customApp, translate } from "functions";

const Overview = () => {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const changeData = (message, messageD, id) => {
    setData({
      ...data,
      message: message,
      messageD: messageD,
    })
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginBottom: "50px",
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          flexGrow: "1",
          height: "auto",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          backgroundColor: "#fff",
          borderRadius: "3px",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          width: '100%',
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "space-between",
            borderBottom: "#e8e8e8 1px solid",
            padding: "7px 15px",
            color: customApp('colorText'),
            background:
              customApp('ColumnTitleColor'),
          }}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {translate('$__about')}
          </span>
          <Icon
            onClick={() => setEdit(!edit)}
            style={{ cursor: "pointer", color: customApp('color') }}
          >
            {edit ? 'cancel' : 'edit'}
          </Icon>
        </div>
        <div style={{ padding: "10px 20px 20px 20px" }} >
          <MessageEditor edit={edit} data={data} changeData={changeData} title={null} />
        </div>
      </div>
    </div>
  );
}
export default Overview;
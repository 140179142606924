import React from "react";


//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Icon from "components/Icon";

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';


//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Card-SWOT";

//FUNCTIONS
import { translate, customApp } from "functions/";


const useStyles = makeStyles(styles);

function Component(props) {
    const { db } = reduxStore.getState()
    const classes = useStyles();
    const [aspects, setAspects] = React.useState([])

    React.useEffect(() => {
        let newAspects = []
        if (props.data && props.data.length > 0)
            props.data.forEach(a => {
                newAspects.push(db.cards[a])
            })
        setAspects(newAspects)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    let internal = props.type === 'guidelineD' || props.type === 'guidelineR' ? aspects.filter(a => a && a.type === 'strength') : aspects.filter(a => a && a.type === 'weakness')
    let external = props.type === 'guidelineD' || props.type === 'guidelineE' ? aspects.filter(a => a && a.type === 'threat') : aspects.filter(a => a && a.type === 'opportunity')
    let internalType = props.type === 'guidelineD' || props.type === 'guidelineR' ? 'strength' : 'weakness'
    let externalType = props.type === 'guidelineD' || props.type === 'guidelineE' ? 'threat' : 'opportunity'
    let internalTypeMult = props.type === 'guidelineD' || props.type === 'guidelineR' ? 'forces' : 'weaknesses'
    let externalTypeMult = props.type === 'guidelineD' || props.type === 'guidelineE' ? 'threats' : 'opportunities'

    return (
        <div className={classes.root} >
            <div className={classes.aspectsTitle}>
                <Icon
                    icon={props.type === 'guidelineD' || props.type === 'guidelineR' ?
                        db.dataType.filter(a => a.name === 'strength')[0].icon
                        : db.dataType.filter(a => a.name === 'weakness')[0].icon
                    }
                    color={customApp('color')}
                    title={translate(internalType, 1)}
                />
                <span style={{ marginLeft: '5px' }}>{translate('$__internalAspects', 1)} - {translate(`$__${internalTypeMult}`, 1)}</span>
            </div>
            {internal && internal.length > 0 && internal.sort((a, b) => a.name - b.name).map((a, i) => (
                <Chip
                    size="small"
                    label={a.name}
                    title={a.name}
                    style={{ margin: '3px', maxWidth: '100%', overflow: 'hidden' }}
                    key={a._id} />
            )
            )
            }


            <div className={classes.aspectsTitle}>
                <Icon
                    icon={props.type === 'guidelineD' || props.type === 'guidelineE' ?
                        db.dataType.filter(a => a.name === 'threat')[0].icon
                        : db.dataType.filter(a => a.name === 'opportunity')[0].icon
                    }
                    color={customApp('color')}
                    title={translate(externalType, 1)}
                />
                <span style={{ marginLeft: '5px' }}>{translate('$__externalAspects', 1)} - {translate(`$__${externalTypeMult}`, 1)}</span>
            </div>
            {external && external.length > 0 && external.sort((a, b) => a.name - b.name).map((a, i) => (
                <Chip size="small"
                    label={a.name}
                    title={a.name}
                    style={{ margin: '3px', maxWidth: '100%', overflow: 'hidden' }}
                    key={a._id} />
            ))
            }
        </div >
    );
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(null,
    mapDispatchToProps
)(Component);

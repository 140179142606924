import {
    defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const buttonStyle = {
    header: {
        ...defaultFont,
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    content: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    info: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    headerIcon: {
        display: 'contents',
        alignItems: 'center',
    },
    headerTitle: {
        width: 'calc(100% - 33px)',
        // marginLeft: '7px',
        overflowX: 'hidden',
        overflowY: 'hidden',
        fontWeight: 'bold',
        paddingTop: '3px',
    },
    actionButtons: {
        // position: 'absolute',
        // top: "-9px",
        right: 0,
        display: 'flex',
    },
    cancelButton: {
        right: '4px',
        position: 'absolute',
        cursor: 'pointer',
    },
    saveButton: {
        right: '33px',
        position: 'absolute',
        cursor: 'pointer',
    },
    button: {
        padding: '3px auto',
        cursor: 'pointer',
        alignItems: 'center',
        display: 'flex',
        opacity: 1,
        // backgroundColor: '#FFFFFF'
        //transition: 'visibility 0.1s, opacity 0.5s linear'
    },
    parent: {
        width: '100%',
        padding: '0 5px',
        '& .title': {
            color: customApp('medium'),
            fontSize: '10px',
            textTransform: 'uppercase'
        },
        '& .info': {
            marginTop: '-8px',
            color: customApp('color'),
            fontWeight: '500',
            //display: 'flex',
            alignItems: 'center',
        }
    },
};

export default buttonStyle;

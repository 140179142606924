const style = () => ({
    card: {
        width: 'calc(20% - 20px)',
        minWidth: '250px',
        maxWidth: '400px',
        margin: '10px',
        placeSelf: 'flex-end',
    }
})

export default style;
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { ResponsiveBullet } from '@nivo/bullet'

//COMPONENTS
import Autocomplete from "components/AutoComplete"
import Card from "components/Card"
import CustomButton from "components/Buttons/custom"
import KPIChange from "components/KPI/change"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TextField from "components/TextField"
import Timeline from "componentsV3/Timeline"

//@MATERIAL
import Icon from "components/Icon"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Tooltip from "@material-ui/core/Tooltip"
import {
    withStyles
} from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card-KPI.js"

//FUNCTIONS
import {
    translate,
    customApp,
    appAlert,
    formatNumber,
    getAppAccess,
} from "functions/"

import {
    saveCard
} from "functions/cards"

import {
    getUserAccess
} from "functions/cards"


function Component(props) {
    const {
        classes
    } = props

    const {
        timeline,
        db,
        session,
        sideModule,
    } = reduxStore.getState()

    const data = sideModule.data || {}

    const [view, setView] = React.useState(data.kpi_type ? "timeline" : "settings")
    const [reqSave, setReqSave] = React.useState(data._id && data._id === "new" && data.name ? true : false)

    React.useEffect(() => {
        if (reqSave && props.reqSave)
            props.reqSave()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reqSave])


    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(sideModule.id)

    let permission = false

    if (parseInt(CardAccess) >= 4 || (AppAccess.plan && AppAccess.planAdmin))
        permission = true

    if (session._id
        && db.cards[data._id]
        && db.cards[data._id]._users
        && db.cards[data._id]._users[Object.keys(db.cards[data._id]._users).find(a => a.indexOf(session._id) > -1)])
        CardAccess = db.cards[data._id]._users[Object.keys(db.cards[data._id]._users).find(a => a.indexOf(session._id) > -1)]

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let kpi_flow = timeline[data._id] || {}
    kpi_flow = Object.keys(kpi_flow).filter(a => kpi_flow[a].type === "kpi").map(a => kpi_flow[a])

    let parentData = null
    let notAllocated = 0
    if (data._parent
        && reduxStore.getState().db.cards[data._parent]
        && reduxStore.getState().db.cards[data._parent].kpi_type
        && reduxStore.getState().db.cards[data._parent].kpi_type !== 'task'
    ) {
        parentData = reduxStore.getState().db.cards[data._parent]
        if (parseInt(parentData.kpi_ini) > parseInt(parentData.kpi_fin)) {
            notAllocated = parseInt(parentData.kpi_ini) - parseInt(parentData.kpi_fin)
        } else {
            notAllocated = parseInt(parentData.kpi_fin) - parseInt(parentData.kpi_ini)
        }
        Object.keys(reduxStore.getState().db.cards)
            .filter(pp =>
                reduxStore.getState().db.cards[pp]._parent
                && reduxStore.getState().db.cards[pp]._parent === parentData._id
                && reduxStore.getState().db.cards[pp].kpi_type === parentData.kpi_type
                && reduxStore.getState().db.cards[pp].kpi_useParent
            )
            .forEach(pp => {
                if (parseInt(parentData.kpi_ini) > parseInt(parentData.kpi_fin && parseInt(reduxStore.getState().db.cards[pp].kpi_ini))) {
                    notAllocated = notAllocated - parseInt(reduxStore.getState().db.cards[pp].kpi_ini)
                } else if (parseInt(reduxStore.getState().db.cards[pp].kpi_fin)) {
                    notAllocated = notAllocated - parseInt(reduxStore.getState().db.cards[pp].kpi_fin)
                }
            })
    }



    const changeData = (d) => {
        if ((d.value && !sideModule.data[d.name]) || (sideModule.data[d.name] && sideModule.data[d.name] !== d.value))
            setReqSave(true)

        let newData = {
            ...sideModule.data,
            [d.name]: d.value,
        }
        if (!newData.kpi_ini)
            newData = {
                ...newData,
                kpi_ini: 0,
            }
        if (!newData.kpi_fin)
            newData = {
                ...newData,
                kpi_fin: 0,
            }
        if (!newData.kpi_useParent && (!String(newData.kpi_useParent) !== 'false') && (parentData && parentData.kpi_type === d.value))
            newData = {
                ...newData,
                kpi_useParent: true,
            }

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: newData
        })
    }

    const save = async (close = false) => {
        setReqSave(false)
        if (
            sideModule.data.kpi_type === "time"
            && (
                (
                    parseInt(sideModule.data.kpi_fin) === 0
                    && parseInt(sideModule.data.kpi_ini) === 0
                )
                || (
                    parseInt(sideModule.data.kpi_fin) > 0
                    && parseInt(sideModule.data.kpi_fin) < parseInt(sideModule.data.kpi_ini)
                )

            )
        ) {
            appAlert({
                message: translate("$__invalidMaximumMinutes", 1),
                variant: "warning",
                persist: false,
                horizontal: "right",
                confirm: null
            })
        } else {
            const rSave = await saveCard(props, true, sideModule.data)
            if (rSave) {
                appAlert({
                    message: translate("$__kpiDataSaved", 1),
                    variant: null,
                    persist: false,
                    horizontal: "right",
                    confirm: null
                })
            }
            if (close)
                props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }

    const setParentKpi = async (d) => {
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                kpi_ini: null,
                kpi_fin: null,
                kpi_useParent: d.value ? true : null
            }
        })
    }

    const changeKPI = async (d, b) => {
        let newValue = String(data[`kpi_${b}`] ? data[`kpi_${b}`] : 0)
        if (d.key === '-' && newValue.substring(0, 1) !== "-") {
            newValue = `-${newValue}`
        } else if (d.key.toLowerCase() === 'backspace') {
            newValue = newValue.substr(0, newValue.length - 1)
        } else {
            newValue = `${newValue ? newValue : ``}${!isNaN(d.key) || ((d.key === ',' || d.key === '.') && newValue.indexOf(',') === -1 && newValue.indexOf('.') === -1) ? d.key : ``}`
        }
        if (newValue && newValue.indexOf(',') > -1)
            newValue = newValue.substr(0, newValue.indexOf(',') + 3)

        changeData({
            name: `kpi_${b}`,
            value: newValue
        })
    }

    let totalAllocated = 0

    Object.keys(db.cards).filter(ch => db.cards[ch] && db.cards[ch]._parent && db.cards[ch]._parent === data._id && db.cards[ch].kpi_useParent).forEach((ch, chi) => {
        if (parseInt(data.kpi_ini) > parseInt(data.kpi_fin)) {
            totalAllocated = totalAllocated + parseInt(db.cards[ch].kpi_ini) - parseInt(db.cards[ch].kpi_fin)
        } else {
            totalAllocated = totalAllocated + parseInt(db.cards[ch].kpi_fin) - parseInt(db.cards[ch].kpi_ini)
        }
    })

    return (
        <div>
            <SideModuleCloseAndSave
                save={reqSave && permission ? () => {
                    save()
                } : null}
                saveClose={reqSave && permission ? () => {
                    save(true)
                } : null}
                close={() => {
                    if (props.onClose)
                        props.onClose(reqSave)
                }}
            />
            <div style={{
                posision: "absolute",
                top: 5,
                left: 0,
                right: 0
            }}>
                {permission ?
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row-reverse'
                    }}>
                        <CustomButton
                            title={translate(`$__changeKpiType`, 1)}
                            color={customApp(view === 'settings' ? "menu" : "color")}
                            icon={'tune'}
                            onClick={() => setView('settings')}
                            size={'25px'}
                            transparent
                        />

                        <CustomButton
                            title={translate(`$__chart`, 1)}
                            color={customApp(view === 'chart' ? "menu" : "color")}
                            icon={'analytics'}
                            onClick={() => setView('chart')}
                            size={'25px'}
                            transparent
                        />
                        <CustomButton
                            title={translate(`$__statement`, 1)}
                            color={customApp(view === 'timeline' ? "menu" : "color")}
                            icon={'receipt'}
                            onClick={() => setView('timeline')}
                            size={'25px'}
                            transparent
                        />
                    </div>
                    : <div>

                    </div>
                }
                {view === 'chart' ?
                    <KPIChange data={data} chartShow={true} />
                    : view === 'settings' ?
                        <fieldset style={{ textAlign: "center" }}>
                            <legend>{translate("$__measurementeType", 1)}</legend>
                            {Object.keys(db.cards).filter(c => db.cards[c]._parent === data._id && db.cards[c].kpi_useParent).length === 0 ?
                                <ToggleButtonGroup
                                    value={data && data.kpi_type ? data.kpi_type : "task"}
                                    exclusive
                                    onChange={(e, a) => {
                                        changeData({
                                            name: "kpi_type",
                                            value: a
                                        })
                                    }}
                                >
                                    <ToggleButton value="decimal">
                                        <Icon title={translate("$__decimal", 1)} color="color">filter_9_plus</Icon>
                                    </ToggleButton>
                                    <ToggleButton value="currency">
                                        <Icon title={translate("$__currency", 1)} color="color">attach_money</Icon>
                                    </ToggleButton>
                                    <ToggleButton value="percent">
                                        <Tooltip title={translate("$__percentage", 1)}>
                                            <span style={{ color: `${customApp("color")}`, fontSize: "22px" }}>%</span>
                                        </Tooltip>
                                    </ToggleButton>
                                    <ToggleButton value="time">
                                        <Icon title={translate("$__time", 1)} color="color">hourglass_top</Icon>
                                    </ToggleButton>
                                    <ToggleButton value="task">
                                        <Icon title={translate("$__task", 1)} color="color">task</Icon>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                : data.kpi_type === 'decimal' ?
                                    <Icon title={translate("$__decimal", 1)} color="color">filter_9_plus</Icon>
                                    : data.kpi_type === 'currency' ?
                                        <Icon title={translate("$__currency", 1)} color="color">attach_money</Icon>
                                        : data.kpi_type === 'percent' ?
                                            <Icon title={translate("$__percentage", 1)} color="color">percent</Icon>
                                            :
                                            <Icon title={translate("$__task", 1)} color="color">task</Icon>
                            }
                            {Object.keys(db.cards).filter(c => db.cards[c]._parent === data._id && db.cards[c].kpi_useParent).length === 0 ?
                                <React.Fragment>

                                </React.Fragment>
                                :
                                <React.Fragment>

                                </React.Fragment>
                            }
                            {parentData && parentData.kpi_type && parentData.kpi_type === data.kpi_type ?
                                <React.Fragment>
                                    <fieldset style={{ marginTop: 7 }}>
                                        <legend style={{ color: "gray", fontSize: 12 }}>{translate("$__parentKpi", 1)}</legend>
                                        {data.kpi_useParent ?
                                            <React.Fragment>
                                                <fieldset style={{ marginTop: 3 }}>
                                                    <legend style={{ color: "blue", fontSize: 12 }}>{parentData && parseInt(parentData.kpi_ini) > parseInt(parentData.kpi_fin) ? translate("$__notAllocatedBudget", 1) : translate("$__notAllocatedGoal", 1)}</legend>
                                                    {formatNumber(notAllocated, parentData.kpi_type === "time" ? "number" : parentData.kpi_type, parentData.kpi_currency ? parentData.kpi_currency : null)}
                                                </fieldset>
                                            </React.Fragment>
                                            : <React.Fragment></React.Fragment>}
                                        <div className={classes.switch}>
                                            <Switch
                                                checked={data.kpi_useParent ? true : false}
                                                onChange={(d) => {
                                                    setParentKpi({
                                                        name: "kpi_useParent",
                                                        value: data.kpi_useParent ? false : true
                                                    })
                                                }}
                                                name="kpi_useParent"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__useParentKpi", 1)}
                                        </div>
                                        {!data.kpi_useParent ?
                                            <div style={{ color: 'red' }}>
                                                {translate("$__independentKPIDescription")}
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                        }
                                        <div>
                                        </div>
                                    </fieldset>
                                </React.Fragment>
                                : <React.Fragment></React.Fragment>
                            }
                            {data && data.kpi_type
                                && (data.kpi_type !== "task") &&
                                <React.Fragment>
                                    {data.kpi_type === "currency" && !data.kpi_useParent && Object.keys(db.cards).filter(c => db.cards[c]._parent === data._id && db.cards[c].kpi_useParent).length === 0 ?
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={db.currency}
                                            getOptionLabel={(option) => translate(option.label, 1)}
                                            style={{ width: "100%" }}
                                            name="currency"
                                            disableClearable
                                            value={
                                                data
                                                    && data.kpi_currency
                                                    && db.currency
                                                    ? db.currency.filter(a =>
                                                        String(a.value) === String(data.kpi_currency)
                                                    )[0]
                                                    : db.currency[0]
                                            }
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label={translate("$__currency", 1)}
                                                    variant={customApp("fieldVariant")}
                                                    value={
                                                        data
                                                            && data.kpi_currency
                                                            && db.currency
                                                            ? db.currency.filter(a =>
                                                                String(a.value) === String(data.kpi_currency)
                                                            )[0]
                                                            : db.currency[0]
                                                    }
                                                />
                                            }
                                            onChange={
                                                (d, v) => {
                                                    changeData({ name: "kpi_currency", value: v["value"] })
                                                }
                                            }
                                        />
                                        : Object.keys(db.cards).filter(ch => db.cards[ch]._parent === data._id && db.cards[ch].kpi_useParent).length > 0 ?
                                            <fieldset style={{ marginTop: 7 }}>
                                                <legend style={{ color: "gray", fontSize: 12 }}>{translate("$__allocatedKpiActivities", 1)}</legend>
                                                <ul style={{ listStyle: "none", padding: 0 }}>
                                                    {Object.keys(db.cards).filter(ch => db.cards[ch]._parent === data._id && db.cards[ch].kpi_useParent).map((ch, chi) => {
                                                        let cardAllocated = 0
                                                        if (parseInt(data.kpi_ini) > parseInt(data.kpi_fin)) {
                                                            cardAllocated = parseInt(db.cards[ch].kpi_ini) - parseInt(db.cards[ch].kpi_fin)
                                                        } else {
                                                            cardAllocated = parseInt(db.cards[ch].kpi_fin) - parseInt(db.cards[ch].kpi_ini)
                                                        }
                                                        let kpiAccomplished = 0
                                                        if (timeline && timeline[ch] && Object.keys(timeline[ch]).filter(k => timeline[ch][k] && timeline[ch][k].value).length > 0)
                                                            Object.keys(timeline[ch]).filter(k => timeline[ch][k] && timeline[ch][k].value).forEach(k => {
                                                                kpiAccomplished = kpiAccomplished + parseFloat(timeline[ch][k].value)
                                                            })
                                                        return (
                                                            <li key={`ca-${ch}`}>
                                                                <Card db="cards" data={reduxStore.getState().db.cards[ch]} notGlobalExpanded ignoreList />
                                                                <div style={{
                                                                    width: "100%",
                                                                    height: 22,
                                                                }}>
                                                                    <ResponsiveBullet
                                                                        data={
                                                                            [
                                                                                {
                                                                                    // "id": "temp.",
                                                                                    "ranges": [
                                                                                        0,
                                                                                        parseInt(data.kpi_ini) > parseInt(data.kpi_fin) ? parseInt(data.kpi_ini) - parseInt(data.kpi_fin) : parseInt(data.kpi_fin) - parseInt(data.kpi_ini)
                                                                                    ],
                                                                                    "measures": [
                                                                                        cardAllocated
                                                                                    ],
                                                                                    "markers": [
                                                                                        kpiAccomplished
                                                                                    ]
                                                                                }
                                                                            ]
                                                                        }
                                                                        minValue={parseInt(data.kpi_ini) > parseInt(data.kpi_fin) ? parseInt(data.kpi_fin) : parseInt(data.kpi_ini)}
                                                                        maxValue={parseInt(data.kpi_ini) < parseInt(data.kpi_fin) ? parseInt(data.kpi_fin) : parseInt(data.kpi_ini)}
                                                                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                                                                        spacing={50}
                                                                        titlePosition="after"
                                                                        titleAlign="end"
                                                                        titleOffsetX={-70}
                                                                        rangeBorderColor={{ from: 'color', modifiers: [] }}
                                                                        measureBorderColor={{ from: 'color', modifiers: [] }}
                                                                        measureSize={100000}
                                                                        markerSize={1.2}
                                                                        rangeColors="purple_orange"
                                                                        measureColors="seq:blue_purple"
                                                                        markerColors="seq:yellow_orange_red"
                                                                        motionConfig="stiff"
                                                                    />
                                                                </div>
                                                                <div style={{ marginTop: 0, fontSize: 12, color: 'black', width: "100%", textAlign: "left" }}>
                                                                    {formatNumber(cardAllocated, data.kpi_type === "time" ? "number" : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}
                                                                </div>
                                                            </li>)
                                                    })
                                                    }
                                                </ul>
                                            </fieldset>
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <div style={{ display: "flex", marginTop: "14px", justifyContent: !data.kpi_useParent ? "space-between" : "center" }}>
                                        {
                                            (
                                                !data.kpi_useParent
                                                ||
                                                (
                                                    data.kpi_useParent
                                                    && parentData
                                                    && parseInt(parentData.kpi_ini) > parseInt(parentData.kpi_fin)
                                                )
                                            ) ?
                                                <div style={{ width: "calc(50% - 7px)" }}>
                                                    <TextField
                                                        label={data.kpi_useParent ? translate("$__budget") : data.kpi_type === "time" ? translate("$__minimumMinutes", 1) : translate("$__initialValue", 1)}
                                                        variant={"outlined"}
                                                        name="kpi_ini"
                                                        placeholder={translate('$__value', 1)}
                                                        value={formatNumber(data.kpi_ini ? data.kpi_ini : 0, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            onKeyDown: (d) => {
                                                                changeKPI(d, 'ini')
                                                            },
                                                        }}
                                                    />

                                                </div>
                                                : <React.Fragment></React.Fragment>
                                        }
                                        {
                                            (
                                                !data.kpi_useParent
                                                ||
                                                (
                                                    data.kpi_useParent
                                                    && parentData
                                                    && parseInt(parentData.kpi_ini) < parseInt(parentData.kpi_fin)
                                                )
                                            ) ?
                                                <div style={{ width: "calc(50% - 7px)" }}>
                                                    <TextField
                                                        label={data.kpi_useParent ? translate("$__goal") : data.kpi_type === "time" ? translate("$__maximumMinutes", 1) : translate("$__finalValue", 1)}
                                                        variant={"outlined"}
                                                        name="kpi_fin"
                                                        value={formatNumber(data.kpi_fin ? data.kpi_fin : 0, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            onKeyDown: (d) => {
                                                                console.log(d.key)
                                                                if (d.key === '-') {
                                                                    changeKPI(d, 'fin')
                                                                } else {
                                                                    changeKPI(d, 'fin')
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                    <div className={classes.switch}>
                                        <Switch
                                            checked={data.kpi_reqMessage ? true : false}
                                            onChange={(d) => {
                                                changeData({
                                                    name: "kpi_reqMessage",
                                                    value: data.kpi_reqMessage ? false : true
                                                })
                                            }}
                                            name="kpi_reqMessage"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                        {translate("$__reqDescription", 1)}
                                    </div>

                                    {/* {data.kpi_type === "time" &&
                                    <div className={classes.switch}>
                                        <Switch
                                            checked={data.kpi_canExceed ? true : false}
                                            onChange={(d) => {
                                                changeData({
                                                    name: "kpi_canExceed",
                                                    value: data.kpi_canExceed ? false : true
                                                })
                                            }}
                                            name="kpi_canExceed"
                                            inputProps={{ "aria-label": "secondary checkbox" }}
                                        />
                                        {translate("$__canExceedLimit", 1)}
                                    </div>
                                        }
                                } */}
                                </React.Fragment>
                            }
                        </fieldset>
                        :
                        <div
                            style={{
                                position: "absolute",
                                top: 30,
                                bottom: 0,
                                left: 0,
                                right: 0,
                            }}
                        >
                            <KPIChange
                                data={data}
                                chartShow={false}
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: 103,
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <Timeline
                                    noSearch={true}
                                    nodeId={data._id}
                                    type={"kpi"}
                                    db={'cards'}
                                    disabledSend
                                    loadTimeline
                                    kpi_type={data.kpi_type}
                                />
                            </div>
                        </div>
                }
            </div>
        </div >
    )
}
const mapStateToProps = (store) => ({
    store: {
        sideModule: store.sideModule,
    }
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import {
  defaultFont,
} from "assets/jss/material-dashboard-pro-react";

import textField from "./TextField.js"

import { customApp } from "functions/";

const headerStyle = () => ({
  ...textField,
  appBar: {
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "fixed",
    width: "100%",
    zIndex: "100",
    color: '#FFFFFF',
    border: "0",
    minHeight: "0px",
    display: "flex",
    height: "50px",
    justifyContent: "center",
    userSelect: 'none',
  },
  container: {
    //minHeight: "30px"
  },
  separator: {
    position: "absolute",
    left: "0px",
    right: "0px",
    bottom: "-2px",
    height: "2px",
    backgroundColor: customApp('line')
  },
  search: {
    position: "absolute",
    left: "62px",
    right: "0px",
    //paddingLeft: '70px',
    bottom: "-35px",
    height: "33px",
    boxShadow: "0 3px 3px rgba(0,0,0,0.33)",
    display: "block",
    padding: "0 15px",
    zIndex: 1,
    backgroundColor: customApp('menu'),
    "& .selectedCard": {
      position: 'relative',
      float: 'left',
      width: '250px',
      height: '33px',
      display: 'flex !important',
      alignItems: 'center'
    }
  },
  searchMobile: {
    position: "absolute",
    left: "0px",
    right: "0px",
    paddingLeft: '15px',
    bottom: "-35px",
    height: "33px",
    boxShadow: "0 3px 3px rgba(0,0,0,0.33)",
    display: "block",
    padding: "0 15px",
    backgroundColor: customApp('menu'),
    "& .selectedCard": {
      position: 'relative',
      float: 'left',
      width: '250px',
      height: '33px',
      display: 'flex !important',
      alignItems: 'center'
    }
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    paddingTop: "0.625rem",
    paddingBottom: "0.625rem",
    margin: "0 !important",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  logo: {
    position: "fixed",
    padding: "7px 0px",
    margin: "0",
    display: "flex",
    zIndex: "4",
    alignItems: "center",
    justifyContent: "center"
  },
  logoMini: {
    transition: "all 300ms linear",
    opacity: 1,
    textAlign: "center",
    width: "40px",
    maxHeight: "40px",
    marginLeft: "10px",
    marginRight: "8px",
    color: "inherit",
  },
  logoImg: {
    width: "100%",
  },
  logoNormal: {
    ...defaultFont,
    margin: "auto",
    transition: "all 300ms linear",
    opacity: "1",
    transform: "translate3d(0px, 0, 0)",
    textTransform: "uppercase",
    fontSize: "18px",
    whiteSpace: "nowrap",
    fontWeight: "400",
    overflow: "hidden",
    color: `${customApp('light')} !important`,
    "&,&:hover,&:focus": {
      color: "inherit"
    }
  },
  page: {
    position: 'relative',
    color: customApp('line'), fontSize: '16px',
    textTransform: 'uppercase',
    cursor: 'pointer',
    '& a': {
      color: '#FFFFFF'
    }
  },
  pageLink: {
    position: 'relative',
    float: 'left',
    color: customApp('light'),
    margin: '7px',
    fontSize: '14px',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover': {
      fontWeight: 'bold'
    },
    '& a': {
      color: '#FFFFFF'
    }
  },
  pageLinkActive: {
    color: customApp('line'),
    margin: '7px',
    fontSize: '14px',
    cursor: 'pointer',
    userSelect: 'none',
    fontWeight: 'bold',
  },
  activeArrow: {
    position: 'absolute',
    left: 'calc(50% - 11px)',
    bottom: '-23px',
    transform: 'rotate(-90deg)'
  }
});

export default headerStyle;

//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import Card from "./card"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import IconButton from "components/CustomButtons/IconButton"
import SkeletonCard from "./skeletonCard"

//FUNCTIONS
import api from "api/"
import {
    translate,
    appAlert
} from "functions/"
import apims from "apims"


function ListLinks(props) {
    const { data } = props
    const { db } = reduxStore.getState()
    const [ended, setEnded] = React.useState(false)
    const [hashs, setHashs] = React.useState(window.location.hash.split("/"))
    const [links, setLinks] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const refs = React.useRef({})
    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        refs.current = {
            loading: false
        }
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")
        if (JSON.stringify(Nhashs) !== JSON.stringify(hashs)) {
            setHashs(Nhashs)
        }
        setLoading(true)
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (hashs.length > 0 && mounted.current) {
            loadLinks()
            if (hashs[2] && hashs[2].length !== 36) {
                setLoading(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hashs])

    React.useEffect(() => {
        if (mounted.current)
            setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links])

    const loadLinks = async () => {
        if (!ended && !refs.current.loading) {
            refs.current = {
                loading: true
            }
            if (mounted.current)
                setLoading(true)
            const response = await apims.post("/Feed_Links_V2", {
                ids: hashs[2] && hashs[2].length ? hashs[2] : null,
                skip: Object.keys(links).length,
                limit: 21
            })

            let loadFeeds = Array.from(links)
            if (response && response.data) {
                response.data.forEach(lk => {
                    if (loadFeeds.filter(fil => fil.link === lk.link).length === 0)
                        loadFeeds.push(lk)
                })
                if (mounted.current)
                    setLinks(loadFeeds)
            }
            if (response.data && response.data.length === 0) {
                if (mounted.current)
                    setEnded(true)
            }
            refs.current = {
                loading: false
            }
        }
    }

    const setTitle = async (title) => {
        api.put("/rel/update", {
            _id: data.idRel,
            customTitle: title
        })
        let feeds = db.cards[hashs[0].replace("#", "")]._feeds
        feeds[data._id] = {
            ...feeds[data._id],
            customTitle: title,
        }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...db.cards,
                [hashs[0].replace("#", "")]: {
                    ...db.cards[hashs[0].replace("#", "")],
                    _feeds: feeds,
                }
            }
        });
    }
    const deleteChannel = async (parent) => {
        window.history.back()
        let idPlan = parent.replace("#", "")
        api.delete(`feed/unfollow/${idPlan}__${data._id}`)
        let feeds = db.cards[idPlan]._feeds
        delete feeds[data._id]
        props.reduxFunction("ASYNC", "SET_DB", {
            ...db,
            cards: {
                ...db.cards,
                [idPlan]: {
                    ...db.cards[idPlan],
                    _feeds: feeds,
                }
            }
        });
    }

    const scrollFunction = (e) => {

        var scrollDiv = document.getElementById(`linkList`);

        if (!loading && scrollDiv.scrollTop + window.innerHeight + 400 > scrollDiv.scrollHeight && !props.horizontal)
            loadLinks()
    }

    const RenderSkeleton = () => {
        let rows = []
        for (var i = 0; i < 21; i++) {
            rows.push(<GridItem
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                key={`sk_${i}`}
            >
                <SkeletonCard />
            </GridItem>)
        }
        return <React.Fragment>{rows}</React.Fragment>
    }

    return (
        <div>
            {data && (data.customTitle || data.name) &&
                <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    height: 33
                }}>
                    <div style={{ position: "relative", width: "100%", float: "left", marginBottom: "15px", display: "flex" }}>
                        <IconButton icon="undo" text={translate("$__back", "*")} onClick={() => window.history.back()} />
                        {hashs[0] && hashs[1] === "bright-and-connected" && hashs[2] &&
                            <IconButton icon="delete_sweep" text={translate("$__removeChannel", "*")}
                                onClick={
                                    () => {
                                        appAlert({
                                            message: translate("$__confirmRemoveChannel", 1),
                                            variant: "warning",
                                            persist: false,
                                            horizontal: "right",
                                            confirm: () => {
                                                deleteChannel(hashs[0])
                                            }
                                        })
                                    }
                                }
                            />
                        }
                    </div>
                    <HeaderWithIcon
                        title={`${data.customTitle ? data.customTitle : data.name}`}
                        icon={"chrome_reader_mode"}
                        fontSize="20px"
                        onEdit={(text, id) => setTitle(text)}
                        editable
                    />
                </div>
            }

            <div style={{
                ...!props.horizontal ? {
                    position: "absolute",
                    top: data && (data.customTitle || data.name) ? 50 : 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                } : {
                    position: "relative",
                    width: "100%",
                },
                padding: "0px 15px",
                overflow: "auto"
            }}
                id={"linkList"}
                onScroll={(e) => {
                    scrollFunction(e)
                }}
            >
                <GridContainer
                    style={{
                        ...props.horizontal ? {
                            overflowX: "auto",
                            flexFlow: "row",
                            display: "-webkit-inline-box"
                        } : {}
                    }}
                >
                    {links.filter((l, li) => !props.max || (props.max && (li < props.max))).sort((a, b) => {
                        if (a.isoDate && b.isoDate && a.isoDate < b.isoDate)
                            return 1
                        if (a.isoDate && b.isoDate && a.isoDate > b.isoDate)
                            return -1
                        return 0
                    }).map(lk => {
                        return (
                            <GridItem
                                key={lk._id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                <Card db="cards" data={lk} />
                            </GridItem>
                        )
                    })}
                    {!ended && loading ?
                        <RenderSkeleton />
                        : <React.Fragment></React.Fragment>}
                </GridContainer>
            </div>
        </div >
    )
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(ListLinks)

import React from 'react';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//@MATERIAL
import Paper from "@material-ui/core/Paper";
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from "@material-ui/core/styles";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Backoffice-languages";
import { translate, customApp } from 'functions';

function LanguagesView(props) {
    const { classes } = props
    const { languages } = reduxStore.getState()
    const { socket } = reduxStore.getState().functions
    const [onlyEmpty, setOnlyEmpty] = React.useState(false)
    const [page, setPage] = React.useState(0)
    const [limit, setLimit] = React.useState(25)
    const [filter, setFilter] = React.useState("")

    //AQUI TEM QUE ATUALIZAR O DB LOCAL 
    const updateLanguage = async (id, alias, locale, text) => {
        // console.log(id, alias, locale, text)
        if (!id) {
            socket.emit("data", {
                module: "language",
                method: "post",
                action: "addLocale"
            }, {
                alias,
                locale,
                text
            })
        } else {
            socket.emit('language.UPD', {
                alias,
                locale,
                text
            })
        }
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{
                position: "relative",
                float: 'left',
                width: '100%',
                left: '0px',
                right: '0px',
                padding: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                {/* PAGINATION */}
                {translate('$__page')} {page + 1} {translate('$__of')} {Math.ceil(Object.keys(languages).length / limit)}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TablePagination
                        component="div"
                        count={Object.keys(languages).length}
                        page={page}
                        onChangePage={(event, newPage) => {
                            setPage(newPage)
                        }}
                        rowsPerPage={limit}
                        onChangeRowsPerPage={(event) => {
                            setLimit(parseInt(event.target.value, 10))
                        }}
                        labelRowsPerPage={translate('$__cardsPerPage')}
                    />
                </div>
            </div>
            <div style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
                <TextField
                    label={translate('$__search', 1)}
                    variant={customApp('fieldVariant')}
                    value={filter}
                    onChange={(e) => {
                        setFilter(e.target.value)
                    }}
                />
                <IconButton
                    text={translate("$__showOnlyEmpty", '*')}
                    icon={onlyEmpty ? "subtitles_off" : "subtitles"}
                    iconColor={customApp('medium')}
                    onClick={() => {
                        setOnlyEmpty(!onlyEmpty);
                    }}
                />
            </div>
            <Paper className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.column}>
                        {translate(`alias`, 1)}
                    </div>
                    <div className={classes.column}>
                        Portugues
                    </div>
                    <div className={classes.column}>
                        Ingles
                    </div>
                </div>
                <div className={classes.body}>
                    <ul>
                        {languages
                            && Object.keys(languages)
                            && Object.keys(languages).length > 0
                            && Object.keys(languages)
                                .filter(alias => {
                                    if (
                                        (
                                            !filter
                                            ||
                                            (
                                                filter
                                                && (
                                                    alias.indexOf(filter) > -1
                                                    ||
                                                    (
                                                        languages[alias]
                                                        && languages[alias].locales
                                                        && Object.keys(languages[alias].locales).filter(a => languages[alias].locales[a].name.indexOf(filter) > -1).length > 0
                                                    )
                                                )
                                            )
                                        )
                                        &&
                                        (
                                            (onlyEmpty
                                                && languages[alias]
                                                && (
                                                    !languages[alias].locales
                                                    || !languages[alias].locales['pt-BR']
                                                    || !languages[alias].locales['pt-BR'].name
                                                ))
                                            ||
                                            !onlyEmpty
                                        )
                                    )
                                        return true
                                    return false
                                }
                                )
                                .sort((a, b) => {
                                    if (a < bindActionCreators) {
                                        return -1
                                    } else if (a > b) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .filter((alias, i) => i <= ((page + 1) * limit) && i >= page * limit)
                                .map((alias, i) => {
                                    return (<li key={alias.trim()}>
                                        <div className={classes.column}>
                                            {alias}
                                        </div>
                                        <div className={classes.column}>
                                            <div style={{ position: 'relative' }}>
                                                <HeaderWithIcon
                                                    key={`${alias}_pt-BR`}
                                                    title={languages[alias].locales && languages[alias].locales['pt-BR'] && languages[alias].locales['pt-BR'].name && languages[alias].locales['pt-BR'].name}
                                                    editable
                                                    _id={languages[alias].locales && languages[alias].locales['pt-BR'] && languages[alias].locales['pt-BR'].id && languages[alias].locales['pt-BR'].id}
                                                    onEdit={(text, id) => {
                                                        console.log(text)
                                                        // updateLanguage(id, alias, 'pt-BR', text)
                                                    }
                                                    }
                                                    color={customApp('menu')}
                                                    noAutoFocus={true}
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.column}>
                                            <div style={{ position: 'relative' }}>
                                                <HeaderWithIcon
                                                    key={`${alias}_en-US`}
                                                    title={languages[alias].locales && languages[alias].locales['en-US'] && languages[alias].locales['en-US'].name && languages[alias].locales['en-US'].name}
                                                    editable
                                                    _id={languages[alias].locales && languages[alias].locales['en-US'] && languages[alias].locales['en-US'].id && languages[alias].locales['en-US'].id}
                                                    onEdit={(text, id) => {
                                                        updateLanguage(id, alias, 'en-US', text)
                                                    }
                                                    }
                                                    color={customApp('menu')}
                                                    noAutoFocus={true}
                                                    multiline
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    )
                                })}
                    </ul>
                </div>
            </Paper >
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    languages: store.languages
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(LanguagesView));
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles";
import { customApp } from "functions";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
    const classes = useStyles();
    return (
        <div className={classes.contentCenter}>
            <GridContainer>
                <GridItem md={12}>
                    <h1 className={classes.title} style={{ color: customApp("color") }}>\o/</h1>
                    <h2 className={classes.subTitle} style={{ color: customApp("menu") }}>Você já utilizou o número de usuários contratados</h2>
                    <h4 className={classes.description} style={{ color: customApp("menu") }}>
                        Entre em contato com nossos consultores para solicitar um upgrade do seu plano.
                    </h4>
                </GridItem>
            </GridContainer>
        </div>
    );
}

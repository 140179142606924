//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import Password from "components/Password"
import Switch from "components/Switch"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users.js"

//FUNCTIONS
import api from "api/"
import {
    customApp,
    appAlert,
    translate,
    getAppAccess
} from "functions/"
import { saveAccess } from "functions/users.js"

function Component(props) {
    const { classes } = props
    const { sideModule, session, functions, db } = reduxStore.getState()
    const { socket } = functions
    const { data } = sideModule
    const [iniData] = React.useState(data)
    const [reqSave, setReqSave] = React.useState(false)
    const [newPass, setNewPass] = React.useState(null)
    const [passClear, setPassClear] = React.useState(0)

    const AppAccess = getAppAccess()

    const changeData = (d) => {
        setReqSave(true)
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                _access: {
                    ...sideModule.data._access,
                    [d.name]: d.value,
                }
            }
        })
        return true
    }

    const saveAdmin = (admin) => {
        let newData = {
            _id: data._id,
            isAdmin: admin
        }
        try {
            const { socket } = reduxStore.getState().functions
            socket.emit("data", {
                module: "user",
                method: "post",
                action: "save"
            }, newData, response => {
                if (response.error) {
                    console.log("saveAdminError", response.error)
                } else {
                    props.reduxFunction("ASYNC", "SET_SESSION", {
                        ...reduxStore.getState().session,
                        GlobalData: {
                            ...reduxStore.getState().session.GlobalData,
                            ...newData
                        }
                    })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    const close = (force) => {
        if (!force && reqSave) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }
    const saveEdit = async (cl) => {
        setReqSave(false)
        const reqSave = await saveAccess(props)
        if (reqSave) {
            appAlert({
                message: translate('$__userDataSaved', 1),
                variant: null,
                persist: false,
                horizontal: 'right',
                confirm: null
            })
            saveAdmin(!data._access.admin ? true : null)
            if (cl)
                close(true)
        }
    }

    const inviteUser = async () => {
        socket.emit("data", {
            module: "user",
            method: "post",
            action: "accessSendInvite"
        }, {
            _id: reduxStore.getState().sideModule.data._id,
            name: reduxStore.getState().sideModule.data.name,
            email: reduxStore.getState().sideModule.data.email
        }, () => {
            appAlert({
                message: translate('$__invitedEmailSended', 1),
                variant: 'success',
                persist: false,
                horizontal: 'center',
            })
        })
    }
    const savePassword = async () => {
        try {
            const res = await api.post('/user/new-password', {
                userId: data._id,
                password: newPass,
            })
            if (res) {
                setNewPass(null)
                appAlert({
                    message: translate('$__passwordChanged', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
            }
        } catch (err) {
            appAlert({
                message: `${translate('$__invalidRequest', 1)} | ${translate('$__contactSupport', 1)}`,
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        }
        setPassClear(passClear + 1)
    }

    let subscriptionPlan = db.plans[0]
    if (
        session
        && session.GlobalData
        && String(session.GlobalData.plan)
        && db.plans.filter(a => a.value === session.GlobalData.plan).length > 0
        && db.plans.filter(a => a.value === session.GlobalData.plan)[0].settings
    ) {
        subscriptionPlan = db.plans.filter(a => a.value === session.GlobalData.plan)[0]
    }

    let adminLimit = 1
    if (subscriptionPlan && subscriptionPlan.settings && subscriptionPlan.settings.admins)
        adminLimit = parseInt(subscriptionPlan.settings.admins)

    if (session && session.GlobalData && session.GlobalData.customAdmins)
        adminLimit = parseInt(session.GlobalData.customAdmins)

    let adminUsers = Object.keys(db.users).filter(uid =>
        db.users[uid]
        && uid !== data._id
        && db.users[uid]._access
        && db.users[uid]._access.admin
        && (
            !db.users[uid]._parent
            || (
                db.users[uid]._parent
                && db.users[db.users[uid]._parent]
                && !db.users[db.users[uid]._parent].independentAccount
            )
        )
    ).length || 0

    if ((data._access && data._access.owner && session._id !== data._id) || session._id === data._id)
        return (
            <div>
                <SideModuleCloseAndSave
                    save={reqSave ? () => saveEdit() : null}
                    saveClose={reqSave ? () => saveEdit(true) : null}
                    close={() => close(true)}
                />
                <div style={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    padding: '15px',
                }}>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        <Password onConfirmed={(v) => { setNewPass(v) }} clear={passClear} />
                    </div>
                    {newPass &&
                        <div
                            style={{
                                padding: '7px 15px',
                                margin: 'auto',
                                cursor: 'pointer',
                                backgroundColor: 'green',
                                marginTop: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '7px'
                            }}
                            onClick={() => {
                                savePassword()
                            }}
                        >
                            <IconButton
                                icon="save"
                                text={translate("$__save", '*')}
                                title={!translate("$__save", '*')}
                                iconColor={customApp('lightText')}
                                textColor={customApp('lightText')}

                            />
                        </div>
                    }
                </div>
            </div>
        )

    return (
        <div>
            <SideModuleCloseAndSave
                save={reqSave ? () => saveEdit() : null}
                saveClose={reqSave ? () => saveEdit(true) : null}
                close={() => close(true)}
            />
            <div className={classes.data}>
                <fieldset>
                    <div className={classes.switch}>
                        <Switch
                            checked={data._access && data._access.enabled ? true : false}
                            onChange={(d) => {
                                changeData({
                                    name: 'enabled',
                                    value: data._access && data._access.enabled ? false : true
                                })
                            }}
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                        {translate('$__permitPlataformAccess', 1)}
                    </div>
                    <div className={classes.accessDescription}>
                        <i>{translate('$__userAccess_PlataformAccessDescription', 1)}</i>
                    </div>
                </fieldset>
                {data._access && data._access === 'owner' ?
                    <fieldset>
                        <div className={classes.accessDescription}>
                            <i> {translate('$__youAreAccountOwner', 1)}</i>
                        </div>
                    </fieldset>
                    : (adminUsers < adminLimit || (data && data._access && data._access.admin)) ?
                        <React.Fragment>
                            <fieldset
                                style={{
                                    // position: "relative",
                                    // padding: 7,
                                    // border: "solid 1px rgba(0,0,0,0.5)",
                                    display: "flex"
                                }}
                            >
                                <div style={{
                                    // backgroundColor: "rgba(0,0,0,0.1)",
                                    padding: 7,
                                    width: "calc(50% - 4px)",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor: "pointer",
                                    backgroundColor: data && data._access && data._access.admin ? "rgba(0,0,0,0.07)" : customApp("color"),
                                    color: data && data._access && data._access.admin ? "gray" : "#FFFFFF"
                                }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                        onClick={() => {
                                            changeData({
                                                name: 'admin',
                                                value: false
                                            })
                                        }}
                                    >
                                        <Icon
                                            icon={"supervised_user_circle"}
                                            size={"33px"}
                                            color={data && data._access && data._access.admin ? null : "#FFFFFF"}
                                        />
                                        {translate("$__guest")}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        padding: 7,
                                        marginLeft: 7,
                                        width: "calc(50% - 4px)",
                                        cursor: "pointer",
                                        backgroundColor: data && data._access && data._access.admin ? customApp("color") : "rgba(0,0,0,0.07)",
                                        color: data && data._access && data._access.admin ? "#FFFFFF" : "gray"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",

                                        }}
                                        onClick={() => {
                                            changeData({
                                                name: 'admin',
                                                value: true
                                            })
                                        }}
                                    >
                                        <Icon
                                            icon={"supervisor_account"}
                                            size={"33px"}
                                            color={data && data._access && data._access.admin ? "#FFFFFF" : null}

                                        />
                                        {translate("$__supervisorAccount")}
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset>
                                <div className={classes.accessDescription}>
                                    <i>{translate(data && data._access && data._access.admin ? '$__adminAccess_PlataformDescription' : '$__guestAccess_PlataformDescription', 1)}</i>
                                </div>
                            </fieldset>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <fieldset>
                                <legend>{translate("$__adminLimitReached")}</legend>
                                <div className={classes.accessDescription}>
                                    <i>{translate('$__adminLimitReachedDescription', 1)}</i>
                                    <div
                                        style={{
                                            position: "relative",
                                            marginTop: 7,
                                            color: "red",
                                            fontWeight: "bolder"
                                        }}>{translate("$__usedUsers", "*")} {adminUsers} {translate("$__of", "*")} {adminLimit}<span style={{ marginLeft: 15, color: "red", fontSize: 11, cursor: "pointer" }} onClick={() => {
                                            history.push("acc#tab/subscriptionUpgrade")
                                        }}>{translate("$__upgradeSubscription")}</span>
                                    </div>
                                </div>
                            </fieldset>
                        </React.Fragment>
                }
                {data._access && data._access.enabled && data._access.admin &&
                    <React.Fragment>
                        <React.Fragment>
                            {data._token &&
                                < fieldset style={{ backgroundColor: 'rgba(255,0,0,0.1)' }}>
                                    <i>{translate('$__unregisteredUserAlert', 1)}</i>
                                    <IconButton text={translate("$__sendInviteEmail", 1)} icon="contact_mail"
                                        onClick={() => {
                                            inviteUser()
                                        }}
                                    />
                                </fieldset>
                            }
                            {data._token && data._token._id &&
                                <fieldset style={{ backgroundColor: 'rgba(255,0,0,0.1)' }}>
                                    <i>{translate('$__urlToActivate', 1)}</i>
                                    <input
                                        type="text"
                                        value={`${process.env.REACT_APP_PROD === "true" ? 'https://software.machen.ai' : 'http://localhost:3000'}/activate?token=${data._token._id}`}
                                        onClick={(e) => {
                                            e.target.select()
                                        }}
                                        onChange={(e) => {
                                            console.log('a')
                                        }}
                                    />
                                </fieldset>
                            }
                        </React.Fragment>
                        <fieldset>
                            <legend>
                                <Switch
                                    checked={data._access && data._access.user ? true : false}
                                    onChange={(d) => {
                                        changeData({
                                            name: 'user',
                                            value: data._access && data._access.user ? false : true
                                        })
                                    }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                {translate('$__users', 1)}
                            </legend>
                            {!data._access || (data._access && (!data._access.user || data._access.user === false)) ?
                                <div className={classes.accessDescription}>
                                    <i>{translate('$__userAccess_UserAccessDescription', 1)}</i>
                                </div>
                                :
                                <React.Fragment>
                                    {!data._access.userAdmin &&
                                        <React.Fragment>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && (data._access.userRead) ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'userRead',
                                                            value: data._access && data._access.userRead ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__read', 1)}
                                            </div>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.userInteract ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'userInteract',
                                                            value: data._access && data._access.userInteract ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__interact', 1)}
                                            </div>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.userEdit ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'userEdit',
                                                            value: data._access && data._access.userEdit ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__edit', 1)}
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className={classes.switch}>
                                        <Switch
                                            checked={data._access && data._access.userAdmin ? true : false}
                                            onChange={(d) => {
                                                changeData({
                                                    name: 'userAdmin',
                                                    value: data._access && data._access.userAdmin ? false : true
                                                })
                                            }}
                                            name="checkeduserAdmin"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        {translate('$__admin', 1)}
                                    </div>
                                </React.Fragment>
                            }
                        </fieldset>

                        <fieldset>
                            <legend>
                                <Switch
                                    checked={data._access && data._access.plan ? true : false}
                                    onChange={(d) => {
                                        changeData({
                                            name: 'plan',
                                            value: data._access && data._access.plan ? false : true
                                        })
                                    }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                />
                                {translate('$__plan', 1)}
                            </legend>
                            {!data._access || (data._access && (!data._access.plan || data._access.plan === false)) ?
                                <div className={classes.accessDescription}>
                                    <i>{translate('$__userAccess_StrategyAndPlansAccessDescription')}</i>
                                </div>
                                :
                                <React.Fragment>
                                    {!data._access.planAdmin &&
                                        <React.Fragment>

                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.planRead ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'planRead',
                                                            value: data._access && data._access.planRead ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__read', 1)}
                                            </div>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.planEdit ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'planEdit',
                                                            value: data._access && data._access.planEdit ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__edit', 1)}
                                            </div>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.createTemplate ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'createTemplate',
                                                            value: data._access && data._access.createTemplate ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__createTemplate', 1)}
                                            </div>
                                            <div className={classes.switch}>
                                                <Switch
                                                    checked={data._access && data._access.planModerate ? true : false}
                                                    onChange={(d) => {
                                                        changeData({
                                                            name: 'planModerate',
                                                            value: data._access && data._access.planModerate ? false : true
                                                        })
                                                    }}
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                {translate('$__moderate', 1)}
                                            </div>
                                        </React.Fragment>
                                    }
                                    <div className={classes.switch}>
                                        <Switch
                                            checked={data._access && data._access.planAdmin ? true : false}
                                            onChange={(d) => {
                                                changeData({
                                                    name: 'planAdmin',
                                                    value: data._access && data._access.planAdmin ? false : true
                                                })
                                            }}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        {translate('$__admin', 1)}
                                    </div>
                                </React.Fragment>
                            }
                        </fieldset>

                        {AppAccess && AppAccess.master && data._isAdmin ?
                            <fieldset>
                                <legend>
                                    <Switch
                                        checked={data._access && data._access.feed ? true : false}
                                        onChange={(d) => {
                                            changeData({
                                                name: 'feed',
                                                value: data._access && data._access.feed ? false : true
                                            })
                                        }}
                                        name="checkedA"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    {translate('$__feed', 1)}
                                </legend>
                                {!data._access || (data._access && (!data._access.feed || data._access.feed === false)) ?
                                    <div className={classes.accessDescription}>
                                        <i>{translate('$__feedAccess_StrategyAndPlansAccessDescription')}</i>
                                    </div>
                                    :
                                    <React.Fragment>
                                        {!data._access.feedAdmin &&
                                            <React.Fragment>
                                                <div className={classes.switch}>
                                                    <Switch
                                                        checked={data._access && data._access.feedEdit ? true : false}
                                                        onChange={(d) => {
                                                            changeData({
                                                                name: 'feedEdit',
                                                                value: data._access && data._access.feedEdit ? false : true
                                                            })
                                                        }}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                    {translate('$__edit', 1)}
                                                </div>
                                                <div className={classes.switch}>
                                                    <Switch
                                                        checked={data._access && data._access.feedModerate ? true : false}
                                                        onChange={(d) => {
                                                            changeData({
                                                                name: 'feedModerate',
                                                                value: data._access && data._access.feedModerate ? false : true
                                                            })
                                                        }}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                    />
                                                    {translate('$__moderate', 1)}
                                                </div>
                                            </React.Fragment>
                                        }
                                        <div className={classes.switch}>
                                            <Switch
                                                checked={data._access && data._access.feedAdmin ? true : false}
                                                onChange={(d) => {
                                                    changeData({
                                                        name: 'feedAdmin',
                                                        value: data._access && data._access.feedAdmin ? false : true
                                                    })
                                                }}
                                                name="checkedA"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            {translate('$__admin', 1)}
                                        </div>
                                    </React.Fragment>
                                }
                            </fieldset>
                            :
                            <React.Fragment></React.Fragment>
                        }
                        {AppAccess && AppAccess.masterAdmin ?
                            <React.Fragment>
                                <fieldset>
                                    <legend style={{
                                        color: "red"
                                    }}>
                                        <Switch
                                            checked={data._access && data._access.masterAdmin ? true : false}
                                            onChange={(d) => {
                                                changeData({
                                                    name: 'masterAdmin',
                                                    value: data._access && data._access.masterAdmin ? false : true
                                                })
                                            }}
                                            name="checkedA"
                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                        {translate('$__accountAdministrator', 1)}
                                    </legend>
                                    <div className={classes.accessDescription}>
                                        <i>{translate('$__accountAdministratorDescription')}</i>
                                    </div>
                                </fieldset>
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                        }
                        {
                            AppAccess
                                && AppAccess.masterAdmin
                                && session
                                && (
                                    session.email.indexOf("@sonne.global") > -1
                                    ||
                                    session.email.indexOf("@machen.ai") > -1
                                )
                                &&
                                (
                                    data.email.indexOf("@sonne.global") > -1
                                    ||
                                    data.email.indexOf("@machen.ai") > -1
                                ) ?
                                <React.Fragment>
                                    <fieldset>
                                        <legend style={{
                                            color: "red"
                                        }}>
                                            <Switch
                                                checked={data._access && data._access.machenAdmin ? true : false}
                                                onChange={(d) => {
                                                    changeData({
                                                        name: 'machenAdmin',
                                                        value: data._access && data._access.machenAdmin ? false : true
                                                    })
                                                }}
                                                name="checkedA"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            {translate('$__machenAdministrator', 1)}
                                        </legend>
                                        <div className={classes.accessDescription}>
                                            <i>{translate('$__machenAdministratorDescription')}</i>
                                        </div>
                                    </fieldset>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                        }

                    </React.Fragment>
                }
                {iniData && iniData._access && iniData._access.admin && data && data._access && !data._access.admin ?
                    <React.Fragment>
                        <div style={{ color: "red", fontSize: 12 }}>
                            Ao remover a permissão de administrador do usuário, o mesmo perderá acesso à todas as atividades que administra atualmente e passará a ter acesso restrito às atividades da plataforma.
                        </div>
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
            </div>
        </div >
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import React from "react";
import styled from "styled-components";

//@MATERIALS
import CircularProgress from '@material-ui/core/CircularProgress';

const PreLoaderContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Escurecer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffeb;
  z-index: 7000;
  display:flex;
  align-items:center;
  justify-content:center;
`;

export default function CircularIndeterminate(props) {

  return (
    <div id={`preloaderComponent`}>
      {!props.fullScreen ? (
        <PreLoaderContent>
          <CircularProgress />
        </PreLoaderContent>
      ) : (
        <Escurecer>
          <CircularProgress size={props.size | 50} />
        </Escurecer>
      )}
    </div>
  );
}

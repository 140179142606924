import {
    defaultFont,
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = theme => ({
    root: {
        ...defaultFont,
        width: '100%',
        marginBottom: theme.spacing(2),
        padding: '7px',
        height: 'calc(100vh - 220px)',
        overflowX: 'auto',
    },
    header: {
        position: 'relative',
        float: 'left',
        width: 'auto',
        minWidth: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: customApp('dark'),
        color: customApp('light'),
    },
    column: {
        padding: '7px 15px',
        fontWeight: 'bold',
        borderRight: `solid 1px ${customApp('light', 0.4)}`,
        width: '300px',
    },
    column2: {
        padding: '7px 15px',
        fontWeight: 'bold',
        borderRight: `solid 1px ${customApp('light', 0.4)}`,
        // minWidth: '200px',
        width: 'calc(100% - 300px)',
    },
    body: {
        color: customApp('medium'),
        position: 'relative',
        clear: 'both',
        display: 'flex',
        alignItems: 'center',
        borderBottom: `solid 1px ${customApp('medium', 0.2)}`,
        '& ul': {
            position: 'relative',
            padding: '0px',
            margin: '0px',
            float: 'left',
            minWidth: '100%',
            '& li': {
                minWidth: '100%',
                width: 'fit-content',
                display: 'flex',
                listStyle: 'none',
                padding: '15px 0px',
                borderBottom: `1px solid ${customApp('medium', 0.2)}`
            }
        }
    },
    chip: {
        margin: '3px'
    }
})

export default style;
//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
  fill: "none",
  d: "M20.45,5.43,19,4l-1.79,1.8,1.41,1.41ZM13,1.52H11v3h2Zm7,12h3v-2H20ZM9,17.67v4.81h6V17.67a6,6,0,1,0-6,0ZM4,13.48v-2H1v2ZM6.76,5.82,5,4,3.56,5.43l1.8,1.79Z"
}),_react.default.createElement("path", {
  d: "M20.45,5.43,19,4l-1.79,1.8,1.41,1.41ZM13,1.52H11v3h2Zm7,12h3v-2H20ZM9,17.67v4.81h6V17.67a6,6,0,1,0-6,0ZM4,13.48v-2H1v2ZM6.76,5.82,5,4,3.56,5.43l1.8,1.79Z"
})), 'Ideas');

exports.default = _default;
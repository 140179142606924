import { ResponsiveLine } from '@nivo/line'
import React from 'react'
import Header from 'components/Dashboards/Header'

const TaskDoneByTimePreview = (props) => {


    const data = [
        {
            "id": "japan",
            "data": [
                {
                    "x": "",
                    "y": 89
                },
                {
                    "x": "seg",
                    "y": 89
                },
                {
                    "x": "ter",
                    "y": 289
                },
                {
                    "x": "quar",
                    "y": 145
                },
                {
                    "x": "quin",
                    "y": 268
                },
                {
                    "x": "sexta",
                    "y": 285
                },
                {
                    "x": "sab",
                    "y": 241
                },
                {
                    "x": "dom",
                    "y": 86
                },
                {
                    "x": " ",
                    "y": 86
                },

            ]
        },
        {
            "id": "france",
            "data": [
                {
                    "x": "",
                    "y": 89
                },
                {
                    "x": "seg",
                    "y": 224
                },
                {
                    "x": "ter",
                    "y": 153
                },
                {
                    "x": "quar",
                    "y": 200
                },
                {
                    "x": "quin",
                    "y": 94
                },
                {
                    "x": "sexta",
                    "y": 285
                },
                {
                    "x": "sab",
                    "y": 275
                },
                {
                    "x": "dom",
                    "y": 287
                },
                {
                    "x": " ",
                    "y": 127
                },
            ]
        },

    ]

    return (

        <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
            <Header styles={{}} tittle={props.title} icon={props.icon} />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%', }}></div>
            <div style={{ position: 'relative', flex: '1 1 auto' }}>
                <div style={{ position: 'absolute', width: '100%', height: '100%', marginBottom: '25px' }}>
                    <ResponsiveLine
                        data={data}
                        responsive={true}
                        margin={{ top: 0, right: -25, bottom: 0, left: -25 }}
                        xScale={{ type: 'point' }}
                        yScale={{
                            type: 'linear',
                            min: 'auto',
                            max: 500,
                            stacked: false,
                            reverse: false
                        }}
                        yFormat=" >-.2f"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        enableGridX={true}
                        enableGridY={true}
                        lineWidth={0.5}
                        pointSize={1}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={0}
                        isInteractive={false}
                        enableArea={true}
                        areaBaselineValue={0}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        curve={'cardinal'}
                        colors={['#525252', '#24acdc']}
                        theme={{
                            fontSize: '14px',
                            color: 'white',
                            background: '#f5f5f5',
                            grid: {
                                "line": {
                                    "stroke": "#e8e6e6",
                                    "strokeWidth": 1
                                }
                            },
                            axis: {
                                "ticks": {
                                    "text": {
                                        "fontSize": 14,
                                        //cor dos numeros
                                    },

                                }
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default TaskDoneByTimePreview;

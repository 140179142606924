//CORE
import React from 'react';

//@MATERIAL
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    sm: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    md: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    lg: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function Component(props) {
    const classes = useStyles();
    let AvatarProps = { ...props }
    if (AvatarProps.title)
        delete AvatarProps.title
    if (props.title)
        return (
            <Tooltip
                title={props.title}
            >
                <Avatar {...AvatarProps} className={classes[props.size]} >
                    {props.children}
                </Avatar>
            </Tooltip>
        )
    return (
        <Avatar {...AvatarProps} className={classes[props.size]} >
            {props.children}
        </Avatar>
    )
}
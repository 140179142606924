//CORE
import React, { useEffect } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

//COMPONENTS
import Icon from "components/Icon";
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Users";

//FUNCTIONS
import apims from "apims/";

import {
    customApp,
    appAlert,
    translate,
} from "functions/";

function Component(props) {
    const [reqSave] = React.useState(false)
    const [tokenData, setTokenData] = React.useState({
        image: null,
        status: false,
    })
    const [token, setToken] = React.useState(null)

    useEffect(() => {
        ini()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ini = async () => {
        const res = await apims.post('/M_User_TwoFactor', {
            action: 'has',
        })
        if (res && res.data && res.data.result) {
            setTokenData({
                ...tokenData,
                ...res.data.result,
                status: true,
            })
        } else {
            const newToken = await apims.post('/M_User_TwoFactor', {
                action: 'create',
            })
            if (newToken) {
                setTokenData({
                    ...tokenData,
                    ...newToken.data.result,
                    status: false,
                })
            }
        }
    }

    const verify = async () => {
        const res = await apims.post('/M_User_TwoFactor', {
            action: 'verify',
            code: token
        })
        if (res && res.data && res.data.result) {
            setTokenData({
                ...tokenData,
                status: true
            })
        } else {
            appAlert({
                message: translate('$__invalidToken', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
            })
            setTokenData({
                ...tokenData,
                status: false
            })
        }
        setToken(null)
    }

    const disableToken = async () => {
        const res = await apims.post('/M_User_TwoFactor', {
            action: 'disable',
            code: token
        })
        if (res && res.data && res.data.result) {
            setTokenData({
                image: null,
                status: false,
            })
            ini()
        } else {
            appAlert({
                message: translate('$__invalidToken', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
            })
        }
        setToken(null)
    }

    const close = () => {
        if (reqSave) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE");
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE");
        }
    }

    return (
        <div>
            <SideModuleCloseAndSave
                close={() => close(true)}
            />
            {!tokenData.status ?
                <div style={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    padding: '15px',
                }}>
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                        }}
                    >
                        {(tokenData.image) &&
                            <img alt="MACHEN_IMAGE" src={tokenData.image} />
                        }

                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <p>{translate('$__tokenTip_1')}</p>
                        <p>{translate('$__tokenTip_2')}</p>
                        <ul>
                            <li>{translate('$__tokenTip_3')}</li>
                            <li>{translate('$__tokenTip_4')}</li>
                            <li>{translate('$__tokenTip_5')}</li>
                            <li>{translate('$__tokenTip_6')}</li>
                            <li>{translate('$__tokenTip_7')}</li>
                        </ul>
                    </div>
                    <fieldset>
                        <legend>{translate('$__token', 1)}</legend>

                        <TextField
                            label={translate('$__token', 1)}
                            variant={'standard'}
                            name={'token'}
                            type={'password'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                value: token,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon>lock_outline</Icon>
                                    </InputAdornment>
                                ),
                                autoComplete: 'new-token',
                                onChange: e => {
                                    setToken(e.target.value)
                                },
                            }}
                            placeholder={translate("$__token", '*')}
                            autoComplete={'new-token'}
                            value={token}
                            max={6}
                        />
                    </fieldset>
                    {(!tokenData.status && token && token.length === 6) &&
                        <div
                            style={{
                                padding: '7px 15px',
                                margin: 'auto',
                                cursor: 'pointer',
                                backgroundColor: 'green',
                                marginTop: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '7px'
                            }}
                            onClick={() => {
                                verify()
                            }}
                        >
                            <IconButton
                                icon="check"
                                text={translate("$__activeToken", '*')}
                                title={!translate("$__activeToken", '*')}
                                iconColor={customApp('lightText')}
                                textColor={customApp('lightText')}
                            />
                        </div>
                    }
                </div>
                :
                <div style={{
                    position: "relative",
                    width: "100%",
                    textAlign: "center",
                    padding: '15px',
                }}>
                    <div style={{ textAlign: 'left' }}>
                        <p>{translate('$__disableTokenTip_1')}</p>
                        <p>{translate('$__disableTokenTip_2')}</p>
                        <ul>
                            <li>{translate('$__disableTokenTip_3')}</li>
                            <li>{translate('$__disableTokenTip_4')}</li>
                            <li>{translate('$__disableTokenTip_5')}</li>
                            <li>{translate('$__disableTokenTip_6')}</li>
                            <li>{translate('$__disableTokenTip_7')}</li>
                        </ul>
                    </div>
                    <TextField
                        label={translate('$__token', 1)}
                        variant={'standard'}
                        name={'token'}
                        type={'password'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            value: token,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon>lock_outline</Icon>
                                </InputAdornment>
                            ),
                            autoComplete: 'new-password',
                            onChange: e => {
                                setToken(e.target.value)
                            },
                        }}
                        autoComplete={'new-password'}
                        value={token}
                        max={6}
                    />
                    <div
                        style={{
                            display: 'flex',
                            padding: '7px 15px',
                            margin: 'auto',
                            cursor: 'pointer',
                            backgroundColor: 'red',
                            marginTop: '15px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '7px'
                        }}
                        onClick={() => {
                            disableToken()
                        }}
                    >
                        <IconButton
                            icon="toggle_on"
                            text={translate("$__disableToken", '*')}
                            title={translate("$__disableToken", '*')}
                            iconColor={customApp('lightText')}
                            textColor={customApp('lightText')}
                        />
                    </div>
                </div>
            }
        </div>
    )
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
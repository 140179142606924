import React from "react"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import LinearProgress from "components/Progress/linear"
import TextField from "components/TextField"

//DEPENDENCIES
import SearchMessages from "./messages"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/search.js"

//FUNCTIONS
import { removeSeats, translate, customApp } from "functions/"


function SearchComponent(props) {
  const [searchValue, setSearchValue] = React.useState("")
  const [showText, setShowText] = React.useState(false)
  const [loader, setLoader] = React.useState(false)

  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  // return (<React.Fragment></React.Fragment>)


  return (
    <div style={{
      position: 'relative',
      zIndex: 10,
      padding: 10,
      backgroundColor: 'rgba(250,250,250,0.9)',
      borderRadius: "25px"
    }}>
      {!showText ?
        <CustomButton
          title={translate(`$__search`, 1)}
          color='grey'
          icon={'search'}
          onClick={(e) => {
            setShowText(prev => !prev)
          }}
          transparent
        />
        :
        <React.Fragment>
          <TextField
            label={translate("$__searchInChat")}
            variant={customApp("fieldVariant")}
            name={"searchInChat"}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              autoComplete: "new-password",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon={"close"} color="lightgray" onClick={() => {
                    setSearchValue("")
                    setLoader(false)
                    setShowText(false)
                  }
                  } />
                </InputAdornment>
              ),
              onChange: (d, v) => {
                setSearchValue(d.target.value)
                setLoader(true)
              },
              onBlur: () => {
                if (searchValue === "") {
                  setSearchValue("")
                  setLoader(false)
                  setShowText(false)
                }
              }
            }}
            placeholder={translate("$__searchInChat")}
            value={removeSeats(searchValue).toLowerCase()}
            autoFocus
          />

        </React.Fragment>
      }
      {(searchValue !== "" && searchValue.length > 1 && loader) &&
        <div style={{ position: "absolute", bottom: 0, left: "7px", right: "7px" }}>
          <LinearProgress />
        </div>
      }
      {searchValue !== "" && searchValue.length > 1 &&
        <div style={{
          position: "absolute",
          top: "50px",
          right: "15px",
          backgroundColor: "#FFFFFF",
          height: "auto",
          overflow: "hidden",
          overflowY: "auto",
          boxShadow: "0 3px 3px 3px rgba(0,0,0,0.1)",
          maxHeight: "90vh",
          maxWidth: "666px",
          zIndex: 2001,
        }}>
          <SearchMessages
            nodeId={props.nodeId}
            db={props.db}
            text={searchValue}
            onFinish={(e) => {
              setLoader(false)
            }}
            onAction={(e) => {
              if (props.onAction)
                props.onAction(e)
              setSearchValue("")
              setLoader(false)
              setShowText(false)
            }}
          />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (store, ownProps) => {
  return {
    store,
    ownProps
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchComponent))
//FUNCTIONS
import { customApp } from "functions";

const headerStyle = theme => ({
  btClose: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    right: '0',
    top: '-64px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
  },
  data: {
    marginTop: '0px',
    padding: '0 10px',
    '& fieldset': {
      border: 'solid 1px rgba(0,0,0,0.1)',
      '& legend': {
        marginBottom: '0px',
        fontSize: '16px',
        color: customApp('color')
      }
    }
  },
  timeline: {
    position: 'absolute',
    width: '100%',
    top: '300px',
    bottom: '0px',
  },
  timelineKpiParent: {
    position: 'absolute',
    width: '100%',
    top: '350px',
    bottom: '0px',
  },
  avatar: {
    width: '22px',
    height: '22px'
  },
  switch: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: customApp('medium')
  }
});

export default headerStyle;

//REDUX
import reduxStore from "store"

//FUNCTIONS
import api from "api"
import apims from "apims"

const saveFeed = async (props) => {
    let newData = reduxStore.getState().sideModule.data

    if (newData.status)
        delete newData.status

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    try {
        const response = await api.post('feed/save', {
            ...newData,
            feed: JSON.stringify(newData.feed),
            _parent: hashs[0] || null,
        })
        if (response) {
            if (!newData._id)
                newData._id = response.data._id
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...reduxStore.getState().sideModule,
                id: response.data._id,
                data: {
                    ...newData,
                }
            })
            console.log(response)
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                feeds: {
                    ...reduxStore.getState().db.feeds,
                    [newData._id]: {
                        ...newData,
                        ...reduxStore.getState().db.feeds && reduxStore.getState().db.feeds[newData._id] ? reduxStore.getState().db.feeds[newData._id] : {},
                        _parent: {
                            ...reduxStore.getState().db.feeds && reduxStore.getState().db.feeds[newData._id] && reduxStore.getState().db.feeds[newData._id]._parent ? reduxStore.getState().db.feeds[newData._id]._parent : {},
                            [hashs[0] || null]: {
                                _id: response.data._id,
                                idRel: response.data.idRel
                            }
                        }
                    }
                },
            })
            return true
        }
    } catch (e) {
        return false
    }
}

const dbUpdateFeed = (props, data) => {
    let newData = {
        [data._id]: {
            ...reduxStore.getState().db.feeds && reduxStore.getState().db.feeds[data._id] && reduxStore.getState().db.feeds[data._id].feed ? { feed: reduxStore.getState().db.feeds[data._id].feed } : {},
            ...data.data,
            _parent: {
                ...reduxStore.getState().db.feeds && reduxStore.getState().db.feeds[data._id] && reduxStore.getState().db.feeds[data._id]._parent ? reduxStore.getState().db.feeds[data._id]._parent : {},
                [data._parent]: {
                    ...data.rel
                }
            },
        }
    }

    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        feeds: {
            ...reduxStore.getState().db.feeds,
            ...newData
        }
    });
}

const loadFeeds = async (props, options = {}) => {
    const load = new Promise(async (resolve, reject) => {
        try {
            let feeds = await apims.post(`/Feed_List_V2`, options)
            if (feeds && feeds.data) {
                let mountFeeds = {}
                feeds.data.forEach(f => {
                    let parents = {}
                    if (f.parents && f.parents.length > 0)
                        f.parents.forEach(p => {
                            parents = {
                                ...parents,
                                [p._id]: {
                                    idRel: p.idRel,
                                    ...p.i
                                }
                            }
                        })
                    mountFeeds = {
                        ...mountFeeds,
                        [f.data._id]: {
                            ...f.data,
                            ...f.hide ? { hide: true } : {},
                            feed: [{ ...f.cover }],
                            _parent: parents,
                            ...f.tags && f.tags.filter(a => a.value).length > 0 ? { _tags: f.tags } : {},
                        }
                    }
                })

                props.reduxFunction("ASYNC", "SET_DB", {
                    ...reduxStore.getState().db,
                    feeds: {
                        ...reduxStore.getState().db.feeds,
                        ...mountFeeds,
                    }
                });
                resolve(true)
            }

        } catch (e) {
            console.group('loadFeeds::ERR::')
            console.log(e)
            console.groupEnd()
            resolve(true)
        }
    })
    const result = await load
    return result
}

export {
    saveFeed,
    dbUpdateFeed,
    loadFeeds
}
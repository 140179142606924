//CORE
import React from 'react'

//REDUX
import * as reduxActions from 'store/actions'
import reduxStore from "store/"
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//COMPONENTS
import TextField from "components/TextField"
import Buttom from "components/CustomButtons/Custom"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"

//@MATERIAL
import Autocomplete from "components/AutoComplete"
import Icon from "components/Icon"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS
import { saveCard, unlinkChildrens } from "functions/cards"
import { translate, customApp, appAlert } from "functions/"

import MatrixIcon from "assets/icons/Matrix.js"

function Component(props) {
    const { classes } = props
    const { db, sideModule } = reduxStore.getState()
    const { planType } = db
    const data = sideModule.data || {}
    const [reqSave, setReqSave] = React.useState(false)


    React.useEffect(() => {
        if (data.type === 'link' && data._id === 'new')
            setReqSave(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sideModule.data._id])

    const changeData = (d, d2) => {
        if ((d.value && !sideModule.data[d.name]) || (sideModule.data[d.name] && sideModule.data[d.name] !== d.value))
            setReqSave(true)
        let newData = {
            ...sideModule.data,
            [d.name]: d.value,
        }
        if (d2 && d2.name && d2.value) {
            newData = {
                ...newData,
                [d2.name]: d2.value
            }
        }

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...sideModule,
            data: {
                ...sideModule.data,
                ...newData
            }
        })
    }

    const close = (cl) => {
        if (reqSave && !cl) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }
    const save = async (cl = false) => {
        setReqSave(false)
        if (!sideModule.data.name) {
            appAlert({
                message: translate('$__fillName', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: null
            })
        } else {
            const request = await saveCard(props, true, sideModule.data)
            if (request) {
                appAlert({
                    message: translate('$__userDataSaved', 1),
                    variant: null,
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
            }
            if (cl)
                close(true)
        }
    }

    const unlinkCards = async () => {
        appAlert({
            message: translate('$__unlinkCardsConfirm', 1),
            variant: 'warning',
            persist: false,
            horizontal: 'right',
            confirm: async () => {
                await unlinkChildrens(props, { cardId: data._id, _planId: data._planId })
            }
        })
    }

    const dataType = db.dataType.filter(a => a.name === sideModule.data.type)[0]

    return (
        <React.Fragment>
            <SideModuleCloseAndSave
                save={reqSave ? () => {
                    // setSaveLoader(true)
                    save()
                } : null}
                saveClose={reqSave ? () => {
                    // setSaveLoader(true)
                    save(true)
                } : null}
                // saveLoader={saveLoader}
                close={() => {
                    close(true)
                }}
            />
            <div className={classes.root}>
                {data.type !== 'plan' ?
                    <React.Fragment>
                        {Object.keys(db.cards).filter(card => db.cards[card]._parent === data._id && !db.cards[card].checklist).length > 0 ?
                            <fieldset style={{ textAlign: "center" }}>
                                <legend style={{ color: customApp("color") }}>{translate("$__unLinkCards", 1)}</legend>
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    {translate("$__unLinkCardsDescription", 1)}
                                </div>
                                <Buttom onClick={() => { unlinkCards() }} >{translate("$__unLinkCards", 1)}</Buttom>
                            </fieldset>
                            :
                            <fieldset style={{ marginBottom: '14px' }}>
                                {(!dataType.hideFields || (dataType.hideFields && dataType.hideFields.indexOf('type') === -1)) &&
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={db.type}
                                        getOptionLabel={(option) => translate(option.label, 1)}
                                        style={{ width: '100%' }}
                                        name="type"
                                        disableClearable
                                        value={data && data.type && db && db.type ? db.type.filter(a => String(a.value) === String(data.type))[0] : db.type[0]}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={translate('$__type', 1)}
                                                variant={customApp('fieldVariant')}
                                                value={data && data.type && db && db.type ? db.type.filter(a => String(a.value) === String(data.type))[0] : db.type[0]}
                                            />
                                        }
                                        onChange={
                                            (d, v) => {
                                                changeData({ name: 'type', value: v['value'] })
                                            }
                                        }
                                    />
                                }
                            </fieldset>
                        }
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
                {data.type === 'plan' ?
                    <>
                        <fieldset style={{ marginBottom: '14px', marginTop: 30 }}>
                            <Autocomplete
                                id="combo-box-demo"
                                options={planType}
                                getOptionLabel={(option) => translate(option.label, 1)}
                                style={{ width: '100%' }}
                                name="type"
                                disableClearable
                                value={data && data.planType ? planType.filter(a => a.value === data.planType)[0] : planType[0]}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={translate('$__planType', 1)}
                                        variant={customApp('fieldVariant')}
                                        value={data && data.planType ? planType.filter(a => a.value === data.planType)[0] : planType[0]}
                                    />
                                }
                                onChange={
                                    (d, v) => {
                                        changeData({ name: 'planType', value: v['value'] })
                                    }
                                }
                            />
                            {
                                planType.filter(a =>
                                    !data.planType
                                    || (data.planType
                                        && a.value === data.planType
                                        && a.swot === true
                                    )
                                ).length > 0 ?
                                    <div style={{
                                        position: "relative",
                                        width: "100%",
                                    }}>
                                        <div style={{
                                            width: "fit-content",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                            color: customApp("color")
                                        }}>
                                            <Icon icon={MatrixIcon} color={customApp('color')} size={25} /><span style={{ marginLeft: 15 }}>MATRIX SWOT</span>
                                        </div>

                                    </div>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                            <div>{translate(planType.filter(a =>
                                !data.planType
                                || (data.planType
                                    && a.value === data.planType
                                )
                            )[0].description, 1)}</div>
                        </fieldset>
                        <fieldset style={{ marginBottom: '14px', marginTop: 30 }}>
                            <legend>{translate("$__referencesLabels")}</legend>
                            <div
                                style={{
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}>
                                {translate("$__on", 1)}
                                <Switch
                                    checked={data.refs ? true : false}
                                    onChange={(d) => {
                                        changeData({
                                            name: "refs",
                                            value: data.refs ? false : true
                                        })
                                    }}
                                    name="refs"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            </div>
                            {
                                data.refs ?
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ width: "46%" }}>
                                            <TextField
                                                label={translate("$__ref1Title", 1)}
                                                variant={customApp("fieldVariant")}
                                                name={"ref1Title"}
                                                id={"edit_ref_1"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                    onChange: (d) => {
                                                        changeData(d.target)
                                                    },
                                                }}
                                                value={data.ref1Title || ""}
                                                placeholder={translate("$__ref1Title_placeholder", 1)}
                                            />
                                        </div>
                                        <div style={{ width: "8%", textAlign: "center", justifyContent: "center", alignItems: "center", fontSize: "24px", display: "flex", color: customApp("menu") }}>|</div>
                                        <div style={{ width: "46%" }}>
                                            <TextField
                                                label={translate("$__ref2Title", 1)}
                                                variant={customApp("fieldVariant")}
                                                name={"ref2Title"}
                                                id={"edit_ref_2"}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                    onChange: (d) => {
                                                        changeData(d.target)
                                                    },
                                                }}
                                                value={data.ref2Title || ""}
                                                placeholder={translate("$__ref2Title_placeholder", 1)}
                                            />
                                        </div>
                                    </div> : <></>
                            }
                        </fieldset>
                        <fieldset>
                            <legend>{translate("$__defaultCurrency")} </legend>
                            <Autocomplete
                                id="combo-box-demo"
                                options={db.currency}
                                getOptionLabel={(option) => translate(option.label, 1)}
                                style={{ width: "100%" }}
                                name="currency"
                                disableClearable
                                value={
                                    data
                                        && data.default_currency
                                        && db.currency
                                        ? db.currency.filter(a =>
                                            String(a.value) === String(data.default_currency)
                                        )[0]
                                        : db.currency[0]
                                }
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={translate("$__currency", 1)}
                                        variant={customApp("fieldVariant")}
                                        value={
                                            data
                                                && data.kpi_currency
                                                && db.currency
                                                ? db.currency.filter(a =>
                                                    String(a.value) === String(data.kpi_currency)
                                                )[0]
                                                : db.currency[0]
                                        }
                                    />
                                }
                                onChange={
                                    (d, v) => {
                                        changeData({ name: "default_currency", value: v["value"] })
                                    }
                                }
                            />
                        </fieldset>
                    </>
                    :
                    <React.Fragment></React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
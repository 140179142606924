import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import { routerMiddleware } from "react-router-redux"
import history from "store/history"

//REDUCERS
import alert from "./alert"
import card from "./card"
import cards from "./cards"
import chat from "./chat"
import chats from "./chats"
import controls from "./controls"
import db from "./db"
import functions from "./functions"
import forms from "./forms"
import languages from "./languages"
import lightBox from "./lightBox"
import notifications from "./notifications"
import preLoader from "./preLoader"
import search from "./search"
import session from "./session"
import sessionLoads from "./sessionLoads"
import sideModule from "./sideModule"
import timeline from "./timeline"
import timelineLoader from "./timelinesLoader"
import toLoad from "./toLoad"
import usersStatus from "./usersStatus"
import chatTyping from "./chatTyping"
import searchs from "./searchs"
import overviewNav from "./overviewNavigations"

//SAGA
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  combineReducers({
    alert,
    card,
    cards,
    chat,
    chatTyping,
    chats,
    controls,
    db,
    functions,
    languages,
    lightBox,
    notifications,
    overviewNav,
    preLoader,
    search,
    searchs,
    session,
    sessionLoads,
    sideModule,
    timeline,
    timelineLoader,
    toLoad,
    usersStatus,
    forms,
  }),
  applyMiddleware(sagaMiddleware, routerMiddleware(history))
)

sagaMiddleware.run(rootSaga)

export default store

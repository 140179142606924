
const dashboardStyle = {
    widgetsContent: {
        backgroundColor: '#FFFFFF',
        // boxShadow: "0 3px 3px 3px rgba(0,0,0,0.05)",
        // padding: '7px',
        height: 'calc(100vh - 200px)',
        maxHeight: 'calc(100vh - 350px)',
        position: 'relative',
        overflowX: 'auto'
    }
};

export default dashboardStyle;


import React, { useState, useEffect, useRef } from 'react'

//FUNCTIONS
import { translate } from "functions"
import moment from "moment-timezone"

//COMPONENTS
import Header from '../Header'
import Card from "components/Card"
import Pagination from '@material-ui/lab/Pagination'
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import Icon from 'components/Icon'
import ButtonOptions from 'componentsV3/Buttons';
import Filter from 'componentsV3/Filter';

//REDUX
import reduxStore from "store/"


const LateTask = (props) => {

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    const [dueActivities_page, setDueActivities_page] = useState(1)
    const [filter, setFilter] = useState("")
    const [hover, setHover] = useState(false)
    const taskRef = useRef()
    const [height, setHeight] = useState(0)
    const [dashFilterChart, setDashFilterChart] = useState({})


    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);

    let filterStructure = props.planCards && Object.keys(props.planCards).filter(card => {
        let lastDateCard =
            db.cards[card].completed_at?.low
            || db.cards[card].completed_at
            || db.cards[card].updated_at?.low
            || db.cards[card].updated_at
            || db.cards[card].created_at?.low
            || db.cards[card].created_at

        const dateCard = moment(lastDateCard * 1000);
        const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
        const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

        return !db.cards[card].deleted
            && db.cards[card].status !== 'completed'
            && dateCard.isBetween(startDate, endDate, null, '[]')
            && db.cards[card].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
            && db.cards[card].endDate
            && moment(new Date(db.cards[card].endDate * 1000)).tz('America/Sao_Paulo').format() <= moment(new Date(today)).tz('America/Sao_Paulo').format()
    }).map(card => card)


    useEffect(() => {
        if (!taskRef.current) return;
        const resizeObserver = new ResizeObserver(() => {
            let containerItemCount = taskRef.current.clientHeight / 42

            setHeight((containerItemCount / 2) - 1)
        });
        resizeObserver.observe(taskRef.current);
        return () => resizeObserver.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ height: '100%' }}>
            <UiCard style={{ marginTop: '0px', height: '100%' }} >
                <div>
                    {props.edit ?
                        <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                            <Icon size={24} style={{ alignSelf: 'center', marginLeft: '12.5px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>pending_actions</Icon>
                            <p style={{ margin: '0px', marginLeft: '7.5px', alignSelf: 'center', fontWeight: 'bold', marginTop: '1px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>{translate(`$__lateActivities`, 1)}</p>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    close
                                </Icon>
                            </div>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    open_with
                                </Icon>
                            </div>
                        </div> : <Header
                            hover={hover}
                            tittle={translate(`$__lateActivities`, 1)}
                            icon={'pending_actions'}
                            datafilter={false}
                            styles={props.styles}
                        />
                    }
                    {(hover && !props.edit) && <ButtonOptions
                        text={"Buscar"}
                        icon={"search"}
                        popper={
                            <div style={{ padding: '15px' }}>
                                <Filter
                                    fields={['text']}
                                    showFilters
                                    style={{ position: 'absolute', zIndex: '999' }}
                                    defaultValues={{
                                        text: ''
                                    }}
                                    onChange={(e) => e.text && setFilter(e?.text)}
                                />
                            </div>
                        }
                        style={{
                            alignSelf: 'center',
                            position: 'absolute',
                            right: '40px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            top: '7px'
                        }}
                    />}
                </div>
                {props.planCards &&

                    <UiCardBody style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        {/* <input onChange={(e) => setFilter(e.target.value)}></input> */}
                        <div ref={taskRef} style={{ overflowY: 'scroll', height: `calc(100% - 70px)`, overflowX: 'hidden' }}>
                            {filterStructure.sort((a, b) => {
                                let completeA = db.cards[a].endDate
                                let completeB = db.cards[b].endDate
                                if (completeA > completeB) {
                                    return -1
                                } else if (completeA < completeB) {
                                    return 1
                                } else {
                                    return 0
                                }
                            })
                                .filter((card, i) => i >= (dueActivities_page - 1) * height && i < dueActivities_page * height)
                                .map((card, i) => {
                                    return (<Card
                                        db="cards"
                                        key={card}
                                        data={db.cards[card]}
                                        inLine={true}
                                    />)
                                }
                                )}
                        </div>
                        {Math.ceil(filterStructure.length / height) > 1 &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30px' }} >
                                <Pagination
                                    page={dueActivities_page}
                                    count={Math.ceil(filterStructure.length / height)}
                                    onChange={(e, p) => {
                                        setDueActivities_page(p)
                                    }}
                                />
                            </div>
                        }
                    </UiCardBody>
                }
            </UiCard >
        </div >
    )
}

export default LateTask
//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import AgileStatus from "components/AgileV2/status";
import Filter from "componentsV3/Filter/menuFilter"


//FUNCTIONS
import { customApp } from "functions/";

function StrategyAndPlansActionsView(props) {
    const { cards, orderByTitle, orderByTitlePosition, dataType } = props
    const { session } = reduxStore.getState()

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    if (!cards) {
        return (<div></div>)
    }

    let myCards = Object.keys(cards).filter(a => {
        if (
            !cards[a].checklist
            && cards[a]._planId === hashs[0]
            && !(cards[a] && cards[a].private && (
                !cards[a]._users
                || (
                    cards[a]._users
                    && !cards[a]._users[session._id]
                )
            ))
            &&
            (
                (
                    props.type === 'actions'
                    && cards[a]
                    && cards[a].type
                    && cards[a].type !== 'objective'
                    && cards[a].type !== 'goal'
                    && cards[a].type !== 'plan'
                    && cards[a].type !== 'link'
                    && cards[a].type !== 'insight'
                    && cards[a].type !== 'chatGroup'
                    && cards[a].type.indexOf('guideline') === -1
                    && dataType.filter(f => f.name === cards[a].type)[0].module !== 'swot'
                )
                ||
                (
                    props.type === 'guidelines'
                    && cards[a].type.indexOf('guideline') > -1
                )
                ||
                (
                    props.type === 'objectives'
                    && cards[a].type.indexOf('objective') > -1
                )
                ||
                (
                    props.type === 'goals'
                    && cards[a].type.indexOf('goal') > -1
                )
            )
        )
            return true
        return false
    }).map(c => cards[c])

    return (
        <div>
            <div style={{
                padding: "0px 10px"
            }}>
                <Filter style={{
                    with: "100%"
                }}
                    fields={['text', 'date', 'tags', 'priority', 'unit', 'usersMode', 'users']}
                    showFilters
                />
                <HeaderWithIcon
                    title={null}
                    icon={null}
                    color={customApp('menu')}
                    style={{
                        background: 'transparent',
                        padding: '7px'
                    }}

                    customButtons={[
                        {
                            title: orderByTitle ? "$__orderByName" : "$__orderByManualOrder",
                            icon: orderByTitle ? "sort" : "format_list_numbered",
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_DB", {
                                    ...reduxStore.getState().db,
                                    orderByTitle: !orderByTitle
                                });
                            },
                        },
                        orderByTitle &&
                        {
                            title: orderByTitlePosition === 'ASC' ? "$__asc" : "$__desc",
                            icon: orderByTitlePosition === 'ASC' ? "text_rotation_down" : "text_rotate_up",
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_DB", {
                                    ...reduxStore.getState().db,
                                    orderByTitlePosition: orderByTitlePosition === 'ASC' ? 'DESC' : 'ASC'
                                });
                            },
                        },
                    ]}
                />
            </div>
            <AgileStatus cards={myCards.map(a => a._id)}
                columnStyle={{
                    maxHeight: "calc(100vh - 220px)", width: "25%"
                }}
            />
        </div>
    )
}

const mapStateToProps = (store, ownProps) => ({
    cards: store.db.cards,
    orderByTitle: store.db.orderByTitle,
    orderByTitlePosition: store.db.orderByTitlePosition,
    dataType: store.db.dataType
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StrategyAndPlansActionsView)

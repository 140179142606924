// 
import React from "react"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import KanbanBoard from "components/AgileKanban"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//FUNCTIONS
import ErrorBoundary from "components/ErrorBoundary"
import { translate, customApp } from "functions"
import { loadCardsV2 } from "functions/loadData"


const ModalComponent = (props) => {
    const { db, session } = reduxStore.getState()

    React.useEffect(() => {
        ini()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.board])

    const ini = async () => {
        loadCardsV2(props, {
            cardLoad: 'childrens',
            idRel: props.board,
        })
        loadCardsV2(props, {
            cardLoad: 'childrens',
            idRel: props.board,
            completed: true,
            completedDays: 7
        })
    }

    if (!props.board)
        return (<React.Fragment></React.Fragment>)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    const data = db.cards[props.board]
    const step = data._parent ? db.cards[data._parent] : null
    const parent = step && step._parent !== hashs[0] && db.cards[step._parent] && db.cards[step._parent]._users && db.cards[step._parent]._users[session._id] ? db.cards[step._parent] : null

    return (
        <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backdropFilter: "blur(3px)",
        }}>
            <div
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    right: '0px',
                    bottom: '0px',
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >

                <div style={{
                    position: "relative",
                    width: 'calc(100% - 100px)',
                    height: "83vh",
                    backgroundColor: '#FFFFFF',
                    margin: 'auto',
                    boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
                }}>
                    <div style={{
                        position: "relative",
                        width: '100%',
                        backgroundColor: '#FFFFFF',
                        padding: '9px',
                        height: "40px",
                        margin: 'auto',
                        background: customApp('ColumnTitleColor'),
                    }}>
                        <div
                            style={{
                                fontSize: "14px",
                                color: customApp('colorText'),
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center"
                            }}
                        >
                            {parent && parent._id &&
                                <div style={{
                                    marginRight: 15,
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center"
                                }}>
                                    <HeaderWithIcon
                                        title={`${parent?.name || translate("$__undefinedName", 1)} `}
                                        searchText={props?.searchText || null}
                                        icon={parent?.icon || (parent.type && db && db.dataType && db.dataType.filter(a => a.name === parent.type)[0] && db.dataType.filter(a => a.name === parent.type)[0].icon) || "$__topic"}
                                        iconTitle={`$__${parent.type}`}
                                        color={customApp('colorText')}
                                        backClick={(e) => {
                                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                ...reduxStore.getState().control,
                                                board: parent._id,
                                            })
                                        }}
                                    />
                                    <div style={{
                                        marginTop: -5
                                    }}>
                                        <Icon icon="navigate_next" color={customApp('textColor')} />
                                    </div>
                                </div>
                            }
                            <div>
                                <HeaderWithIcon
                                    title={`${data?.name || translate("$__undefinedName", 1)} `}
                                    searchText={props?.searchText || null}
                                    icon={data?.icon || (data.type && db && db.dataType && db.dataType.filter(a => a.name === data.type)[0] && db.dataType.filter(a => a.name === data.type)[0].icon) || "$__topic"}
                                    iconTitle={`$__${data.type}`}
                                    color={customApp('textColor')}
                                />
                            </div>
                        </div>
                        <div style={{
                            position: "absolute",
                            right: 5,
                            top: 5
                        }}>
                            <CustomButton
                                id={`close_kanban`}
                                title={translate(`$__close`, "*")}
                                color={customApp("color")}
                                icon={"close"}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                                        ...reduxStore.getState().control,
                                        board: null,
                                    })
                                }}
                                size={"25px"}
                                transparent
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            padding: '9px',
                            height: "calc(100% - 50px)"
                        }}
                    >
                        <ErrorBoundary>
                            <KanbanBoard
                                nodeId={props.board}
                            />
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            {/* </ClickAwayListener> */}
        </div>
    )
}

const mapStateToProps = (store) => ({
    board: store.controls.board
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent)
//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

//COMPONENTS
//@MATERIAL
import Autocomplete from "components/AutoComplete/multiple";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Tags-input";

function Component(props) {
    const [values, setValues] = React.useState(props?.data?.values || [])

    React.useEffect(() => {
        if (props?.data?.values) {
            let newValues = []
            props.data.values.filter(d => d.value).forEach(d => {
                newValues.push(d)
            })
            setValues(newValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data])

    const changeTags = async (v) => {
        if (props.callBack)
            props.callBack(v)
        setValues(v)
    }

    return (
        <div>
            <Autocomplete
                dbNode={props.dbNode ? props.dbNode : 'tag'}
                secondaryParent={props.secondaryParent ? props.secondaryParent : null}
                options={props.options ? props.options : []}
                style={{ width: '100%' }}
                name={props.name ? props.name : 'multInput'}
                ws={!props.noAdd ? true : false}
                add
                colorPicker
                multiple
                freeSolo
                disableClearable
                disabled={props.disabled ? true : false}
                value={values}
                placeholder={props.placeholder || null}
                onChange={
                    (v) => {
                        changeTags(v)
                    }
                }
                colorPickerCallBack={(res) => {
                    if (props.colorPickerCallBack)
                        props.colorPickerCallBack(res)
                }}
            />
        </div>
    )
}

//REACT
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Component));
import React from "react";
import CryptoJS from "crypto-js"
import { v4 as uuid } from "uuid";

import { translate } from "functions/"

import api from "api"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo
        })

        let ErrorData = {
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            hash: window.location.hash,
            message: this.props?.message ? `${this.props.message} : ${error.message}` : error.message,
            stack: errorInfo.componentStack,
            component: errorInfo.componentStack,
        }

        console.log({
            SUPPORT_ID: CryptoJS.MD5(JSON.stringify(error)).toString(),
            error: {
                hostname: window.location.hostname,
                pathname: window.location.pathname,
                hash: window.location.hash,
                message: this.props?.message ? `${this.props.message} : ${error.message}` : error.message,
                stack: errorInfo.componentStack,
                component: errorInfo.componentStack,
            }
        })

        api.post("/ErrorBoundary/add", {
            _id: uuid(),
            uid: CryptoJS.MD5(JSON.stringify(ErrorData)).toString(),
            origin: "web", //web, app, sck, api, tgr
            error: ErrorData
        })


    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    {translate("$__ComponentErrorRefreshOrSupportMessage")}
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
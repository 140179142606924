//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null ,_react.default.createElement("path", {
  fill: "none",
   d: "M11.6,17.8h5.8c.1,0,.1,0,.1-.1h-.1L.4,0V1.6L15.7,16.9H12.2L.4,5.1V6.7L11.6,17.8Z"
}),_react.default.createElement("path", {
    d: "M11.6,17.8h5.8c.1,0,.1,0,.1-.1h-.1L.4,0V1.6L15.7,16.9H12.2L.4,5.1V6.7L11.6,17.8Z"
}),_react.default.createElement("path", {
    d: "M1,18H7.3c0-.1,0-.1.1-.1h.1a.1.1,0,0,0,.1-.1h0a.1.1,0,0,0,.1-.1H7.6a.1.1,0,0,1-.1-.1h0L1.4,10.9h0l-1-1v7.5A.6.6,0,0,0,1,18Z"
})), 'GuidelineR');

exports.default = _default;
import {
  defaultFont,
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const headerStyle = () => ({
  lock: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    height: '100vh',
    zIndex: '3000 !important',
  },
  backdrop: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '440px',
    bottom: '0px',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  module: {
    ...defaultFont,
    position: `absolute`,
    right: `0px`,
    top: `0px`,
    bottom: `0px`,
    // width: `440px`,
    minWidth: "500px",
    width: "30%",
    zIndex: `1100`,
    backgroundColor: `rgba(255,255,255,1)`
  },
  modulePopup: {
    ...defaultFont,
    width: '70vw',
    position: 'absolute',
    zIndex: '1100',
    left: 'calc(50% - 35vw)',
    backgroundColor: 'rgba(255,255,255,1)',
    height: '80vh',
    top: 'calc(50% - 40vh)',
    boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
    padding: '0px 5px',
    border: 'solid 2px #ffffff',
  },
  moduleExpanded: {
    ...defaultFont,
    width: '90vw',
    position: 'absolute',
    zIndex: '1100',
    left: 'calc(50% - 45vw)',
    backgroundColor: 'rgba(255,255,255,1)',
    height: '80vh',
    bottom: '0px',
    boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
    padding: '0px 5px',
    border: 'solid 2px #ffffff',
  },
  moduleFullScreen: {
    ...defaultFont,
    width: '100vw',
    position: 'absolute',
    zIndex: '1100',
    left: '0px',
    backgroundColor: 'rgba(255,255,255,1)',
    height: '100vh',
    bottom: '0px',
    boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
    padding: '0px 5px',
    border: 'solid 2px #ffffff',
  },
  menu: {
    position: `absolute`,
    left: `0px`,
    width: `45px`,
    top: `0px`,
    bottom: `0px`,
    textAlign: `center`,
  },
  content: {
    position: 'absolute',
    top: '80px',
    left: '45px',
    bottom: '0px',
    right: '0px',
    padding: '0px',
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 1
  },
  menuButtons_resume: {
    margin: '0px auto 27px auto',
    height: '40px'
  },
  MenuButton: {
    margin: 'auto auto',
    // padding: '7px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  MenuButton_arrow: {
    position: 'absolute',
    right: '-8px',
    fontSize: '16px',
    transform: 'rotate(180deg)'
  },
  subTitle: {
    position: `absolute`,
    left: `45px`,
    right: `0px`,
    padding: `4px 10px`,
    top: `47px`,
    backgroundColor: customApp('color'),
    color: customApp('light'),
    // zIndex: 1
  },
});

export default headerStyle;

//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null ,_react.default.createElement("path", {
  fill: "none",
   d: "M6.4.2H.6l17,17V16.4L2.3,1.1H5.8L17.6,12.9V11.3L6.4.2Z"
}),_react.default.createElement("path", {
    d: "M6.4.2H.6l17,17V16.4L2.3,1.1H5.8L17.6,12.9V11.3L6.4.2Z"
}),_react.default.createElement("path", {
    d: "M17,0H10.5l6.1,6.1h0l1,1V.6A.6.6,0,0,0,17,0Z"
})), 'GuidelineE');

exports.default = _default;
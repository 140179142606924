import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import Channel from "components/BrightAndConnected/channel"
import ListLinks from "components/BrightAndConnected/listLinks"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import LinearProgress from "components/Progress/linear"


//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected.js"

//FUNCTIONS
import { loadFeeds } from "functions/feeds"

function BrightAndConnectedFeed(props) {
    const { search, session } = reduxStore.getState()
    const [loading, setLoading] = React.useState(true)
    const [feeds, setFeeds] = React.useState({})

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    React.useEffect(() => {
        iniFeeds()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.store.feeds])

    React.useEffect(() => {
        ini()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ini = async () => {
        setLoading(true)
        if (hashs[1] === 'hideFeeds'
            //  && Object.keys(reduxStore.getState().db.feeds).filter(a => reduxStore.getState().db.feeds[a].hide).length === 0
        ) {
            const loadData = await loadFeeds({ ...props, fnIdentification: 'Views/BrightAndConnected/Feeds -- iniFeeds()' }, { showHidden: true })
            if (loadData) {
                iniFeeds()
            }
        } else if (Object.keys(reduxStore.getState().db.feeds).filter(a => !reduxStore.getState().db.feeds[a].hide).length === 0) {
            const loadData = await loadFeeds({ ...props, fnIdentification: 'Views/BrightAndConnected/Feeds -- iniFeeds()' })
            if (loadData)
                iniFeeds()
        } else {
            iniFeeds()
        }
    }

    const iniFeeds = () => {
        let aFeeds = {}

        if (Object.keys(reduxStore.getState().db.feeds).length > 0)
            aFeeds = {
                ...aFeeds,
                ...reduxStore.getState().db.feeds,
            }

        let nfeeds = {}
        Object.keys(aFeeds).filter(a =>
            (
                aFeeds[a] && aFeeds[a].name && aFeeds[a].name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            || (aFeeds[a].customTitle && aFeeds[a].customTitle.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            || (aFeeds[a].customDescription && aFeeds[a].customDescription.toLowerCase().indexOf(search.text.toLowerCase()) > -1
            )
        ).forEach(a => {
            if (Object.keys(search.plans.selected).length > 0 && Object.keys(search.plans.selected).map(sp => search.plans.selected[sp] && aFeeds[a]._parent[sp]).length > 0) {
                nfeeds = {
                    ...nfeeds,
                    [a]: aFeeds[a]
                }
            } else if (Object.keys(search.plans.selected).length === 0) {
                nfeeds = {
                    ...nfeeds,
                    [a]: aFeeds[a]
                }
            }
        })
        setFeeds(nfeeds)
        setLoading(false)
    }



    if (loading)
        return (
            <div style={{ position: "absolute", top: '0px', bottom: 0, left: 0, right: 0 }}>
                <LinearProgress />
            </div>
        )

    if (hashs[2] && hashs[2].length === 36)
        return (
            <div>
                <ListLinks follow />
            </div>
        )

    return (
        <div style={{
            width: 'calc(100% - 20px)',
            position: 'relative',
            display: 'flex'
        }}>
            <GridContainer>
                {feeds
                    && Object.keys(feeds).length > 0
                    && Object.keys(feeds)
                        .filter(a => {
                            if (
                                hashs[0].length === 36
                                && reduxStore.getState().db.cards[hashs[0]]
                                && feeds[a]._parent
                                && Object.keys(feeds[a]._parent).length > 0
                                && feeds[a]._parent[hashs[0]]
                            ) {
                                return true
                            }
                            if (hashs[0] === 'tab') {
                                let hide = false
                                if (feeds[a].hide) {
                                    hide = true
                                }
                                if (!props.showHidden && !hide)
                                    return true
                                if (props.showHidden && hide)
                                    return true
                            }
                            return false
                        })
                        .sort((a, b) => {
                            let nameA = feeds[a].customTitle ? feeds[a].customTitle.toLowerCase().trim() : feeds[a].name.toLowerCase().trim()
                            let nameB = feeds[b].customTitle ? feeds[b].customTitle.toLowerCase().trim() : feeds[b].name.toLowerCase().trim()

                            if (hashs[0] && hashs[0].length !== 36) {
                                if (session && session.account && feeds[a]._parent && feeds[a]._parent[session.account] && feeds[a]._parent[session.account].customTitle)
                                    nameA = feeds[a]._parent[session.account].customTitle.toLowerCase().trim()

                                if (session && session.account && feeds[b]._parent && feeds[b]._parent[session.account] && feeds[b]._parent[session.account].customTitle)
                                    nameB = feeds[b]._parent[session.account].customTitle.toLowerCase().trim()
                            }

                            if (nameA < nameB) {
                                return -1
                            } else if (nameA > nameB) {
                                return 1
                            } else {
                                return 0
                            }
                        }
                        ).map((f, i) => {
                            let feed = feeds[f]
                            return (
                                <GridItem
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    key={f}
                                >
                                    <Channel data={feed} follow onChange={() => { iniFeeds() }} />
                                </GridItem>
                            )
                        })
                }

            </GridContainer>
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        store: {
            feeds: store.db.feeds
        }
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BrightAndConnectedFeed))
const initialState = {
    total: 0,
    lastNotification: 0,
    firstNotification: 0,
}

const session = (state = initialState, data) => {
    if (data.action === 'SET_NOTIFICATIONS' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    }
    else {
        return state
    }
};

export default session;
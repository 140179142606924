//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import Card from "components/BrightAndConnected/socialCard"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import LinearProgress from "components/Progress/linear"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card.js"

//FUNCTIONS
import apims from "apims"


function BrightAndConnectHomeView(props) {
    const { data } = props
    const limit = 7
    const [hashs, setHashs] = React.useState([])
    const [links, setLinks] = React.useState([])
    const [loading, setLoading] = React.useState(true)

    const mounted = React.useRef(true)
    const execRefs = React.useRef({
        endLinks: false
    })

    React.useEffect(() => {
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const loadLinks = async () => {
        let p = Object.keys(links).length > 0 ? Math.ceil(Object.keys(links).length / limit) + 1 : 0
        let l = limit
        if (!execRefs.current.endLinks) {
            setLoading(true)
            try {
                const response = await apims.post("/Feed_Social_V2", {
                    ids: null,
                    skip: p * l,
                    limit: l
                })

                let loadFeeds = {}
                let total = 0

                if (response && response.data && response.data.length > 0) {
                    response.data.forEach(lk => {
                        let reactions = {}
                        if (lk.reactions && lk.reactions.filter(a => a && a.user && a.user._id).length > 0)
                            lk.reactions.forEach(r => {
                                reactions = {
                                    ...reactions,
                                    [r.react.type]: {
                                        ...reactions && reactions[r.react.type] ? reactions && reactions[r.react.type] : {},
                                        [r.user._id]: {
                                            ...r.user,
                                            reactDate: r.react.created_at
                                        }
                                    }
                                }
                            })
                        loadFeeds = {
                            ...loadFeeds,
                            [lk.link._id]: {
                                ...lk.link,
                                _reactions: reactions
                            }
                        }
                        if (lk.total > total)
                            total = lk.total
                    })
                } else if (response && response.data && response.data.length === 0) {
                    execRefs.current = {
                        ...execRefs.current,
                        endLinks: true
                    }
                }
                // setTotal(total)
                setLinks({
                    ...links,
                    ...loadFeeds,
                })
                setLoading(false)
            } catch (e) {
                console.log(e)
                setLoading(false)
            }
        }
        return true
    }

    React.useEffect(() => {
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")
        setHashs(Nhashs)
        setLoading(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    React.useEffect(() => {
        if (hashs.length > 0 && mounted.current) {
            loadLinks()
            if (hashs[2] && hashs[2].length !== 36) {
                setLoading(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hashs])

    React.useEffect(() => {
        if (mounted.current)
            setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links])// eslint-disable-next-line react-hooks/exhaustive-deps



    const scrollFunction = (e) => {
        var scrollDiv = document.getElementById(`linkList`);
        if (!loading && scrollDiv.scrollHeight - scrollDiv.scrollTop < 2500 && mounted.current) {
            loadLinks()
        }
    }

    return (
        <div style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
        }}>
            <div style={{
                position: "absolute",
                top: data && (data.customTitle || data.name) ? 50 : 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: "0px 15px",
                overflow: "auto"
            }}
                id={"linkList"}
                onScroll={(e) => {
                    scrollFunction(e)
                }}
            >
                <GridContainer>
                    {Object.keys(links).sort((a, b) => {
                        if (links[a].isoDate && links[b].isoDate && links[a].isoDate < links[b].isoDate)
                            return 1
                        if (links[a].isoDate && links[b].isoDate && links[a].isoDate > links[b].isoDate)
                            return -1
                        return 0
                    }).map(lk => {
                        return (
                            <GridContainer
                                key={lk}
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <GridItem
                                    xs={12}
                                    sm={10}
                                    md={8}
                                    lg={6}
                                    xl={6}
                                >
                                    <Card
                                        db="cards"
                                        data={links[lk]}
                                        onClick={() => { window.open(links[lk].link, "_blank") }}
                                    />
                                </GridItem>
                            </GridContainer>
                        )
                    })}
                </GridContainer>
                {(loading) &&
                    <div style={{ position: "relative", width: "100%" }}>
                        <LinearProgress />
                    </div>
                }
            </div>
        </div>
    )
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(BrightAndConnectHomeView))

//CORE
import React from "react"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Card from "components/Card"
import CustomButton from "components/Buttons/custom"
import Message from "componentsV3/Timeline/message"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"


//FUNCTIONS
import {
    translate,
    customApp
} from "functions/"

import {
    countNotifications
} from "functions/cards"
import {
    chatReaded,
} from "functions/chat"

const useStyles = makeStyles(() => ({
    unReaded: {
        padding: "7px",
        borderBottom: "solid 1px rgba(0,0,0,0.1)",
        width: "100%",
        maxWidth: "100%",
        opacity: 1,
        borderLeft: `solid red 2px`
    },
    Readed: {
        padding: "7px",
        borderBottom: "solid 1px rgba(0,0,0,0.1)",
        width: "100%",
        opacity: 0.5,
        maxWidth: "100%",
    }
}))

function CardNotification(props) {
    const { timeline, functions, session } = reduxStore.getState()
    const { socket } = functions;
    const { data, type } = props

    const [showPrivate, setShowPrivate] = React.useState(false)
    const classes = useStyles()

    React.useEffect(() => {
        // setMounted(true)
        return () => {
            // setMounted(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let dbNotify = "cards"
    if (data && data.cardId && reduxStore.getState().db.users[data.cardId]) {
        dbNotify = "users"
    }

    const markAsRead = () => {
        let notifications = countNotifications(data.cardId)
        props.reduxFunction("IMMEDIATE", "SET_NOTIFICATIONS", {
            ...reduxStore.getState().notifications,
            total: reduxStore.getState().notifications.total - notifications.total,
        })
        chatReaded(props, {
            readedBy: session._id,
            id: props.cardId,
            db: 'cards',
            type: type === "all" || type === "mentions" || type === "completed" || type === "deleted" ? "all" : type
        })

        if (reduxStore.getState().db && reduxStore.getState().db.cards && reduxStore.getState().db.cards[data.cardId])
            props.reduxFunction("IMMEDIATE", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    [data.cardId]: {
                        ...reduxStore.getState().db.cards[data.cardId],
                        _notifications: {
                            comment: 0,
                            kpi: 0,
                            timer: 0,
                            log: 0
                        }
                    }

                }
            })


        socket.emit("data", {
            module: "chat",
            method: "put",
            action: "read"
        }, {
            readedBy: session._id,
            id: data.cardId,
            db: 'cards',
            type: type === "all" || type === "mentions" || type === "completed" || type === "deleted" ? "all" : type
        }, () => {
        })



        if (props.onRead) {
            setTimeout(() => {
                props.onRead()
            }, 500)
        }

    }

    let cardTimeline = Object.keys(timeline[data.cardId])
        .filter(n =>
            type === "all"
            || (
                (
                    type === "deleted"
                    && reduxStore.getState().db.cards[data.cardId]?.deleted
                )
                || (
                    type !== "deleted"
                    && reduxStore.getState().db.cards[data.cardId]
                    && !reduxStore.getState().db.cards[data.cardId].deleted
                    && (
                        (
                            type === "comment"
                            && Object.keys(timeline[data.cardId]).filter(a =>
                                timeline[data.cardId][n]?.type === "comment"
                                && !timeline[data.cardId][n].deleted
                            ).length > 0
                        )
                        ||
                        (
                            type === "log"
                            && Object.keys(timeline[data.cardId]).filter(a =>
                                timeline[data.cardId][n]?.type === "log"
                                && !timeline[data.cardId][n].deleted
                            ).length > 0
                        )
                        ||
                        (
                            type === "timer"
                            && Object.keys(timeline[data.cardId]).filter(a =>
                                timeline[data.cardId][n]?.type === "timer"
                                && !timeline[data.cardId][n].deleted
                            ).length > 0
                        )
                        ||
                        (
                            type === "kpi"
                            && Object.keys(timeline[data.cardId]).filter(a =>
                                timeline[data.cardId][n]?.type === "kpi"
                                && !timeline[data.cardId][n].deleted
                            ).length > 0
                        )
                        ||
                        (
                            type === "mentions"
                            && Object.keys(timeline[data.cardId]).filter(a =>
                                timeline[data.cardId][n]?.message?.indexOf(session._id) > -1
                                && !timeline[data.cardId][n].deleted
                            ).length > 0
                        )
                        ||
                        (
                            type === "completed"
                            && reduxStore.getState().db.cards[data.cardId].status === "completed"
                        )
                    )
                )
            )
        )
        .filter(a =>
            session
            && session._id
            && timeline[data.cardId]
            && timeline[data.cardId][a]
            && !timeline[data.cardId][a].deleted
            && timeline[data.cardId][a].user
            && timeline[data.cardId][a].user._id
            && timeline[data.cardId][a].user._id !== session._id
            && (
                !timeline[data.cardId][a].readedBy
                ||
                (
                    timeline[data.cardId][a].readedBy
                    && !timeline[data.cardId][a].readedBy[session._id]
                )
            )
        )
    return (
        <div className={data.readed ? classes.Readed : classes.unReaded}
            style={{ width: "100%", overflow: "auto" }}
        >
            {(
                data.cardId && reduxStore.getState().db.cards[data.cardId]
            )
                &&
                <div
                    style={reduxStore.getState().db.cards[data.cardId] && reduxStore.getState().db.cards[data.cardId].type === "chatGroup" ? { cursor: "pointer" } : {}}
                >
                    <Card
                        setPrivate={(a) => {
                            setShowPrivate(a)
                        }}
                        db="cards"
                        data={reduxStore.getState().db.cards[data.cardId]}
                        onlyOption={reduxStore.getState().db.cards[data.cardId] && reduxStore.getState().db.cards[data.cardId].type === "chatGroup" ? true : false}
                        onOpen={props.onClick || null}
                    />

                </div>
            }

            {cardTimeline.length > 0
                && (
                    (
                        !reduxStore.getState().db.cards[data.cardId].private
                    )
                    ||
                    (
                        reduxStore.getState().db.cards[data.cardId].private
                        &&
                        (
                            reduxStore.getState().controls.lockPrivate
                            ||
                            showPrivate
                        )
                    )
                ) ?
                <React.Fragment>
                    {cardTimeline

                        .sort((a, b) => {
                            if (parseInt(timeline[data.cardId][a].created_at) > parseInt(timeline[data.cardId][b].created_at))
                                return -1
                            if (parseInt(timeline[data.cardId][a].created_at) < parseInt(timeline[data.cardId][b].created_at))
                                return 1
                            return 0
                        }).map((tm, tmi) => {
                            // console.log(tm)
                            return (
                                <div key={`${tm}`} style={{ position: "relative", marginBottom: "33px", paddingBottom: 15, display: "-webkit-box" }}>
                                    <Message
                                        storie={{
                                            ...reduxStore.getState().timeline && reduxStore.getState().timeline[data.cardId] && reduxStore.getState().timeline[data.cardId][tm] ? reduxStore.getState().timeline[data.cardId][tm] : {},
                                            idRel: tm,
                                        }}
                                        boxStyle={{ width: "calc(100% - 10px) !important", maxWidth: "285px" }}
                                        db={dbNotify}
                                        noReactions
                                        noReply
                                        openLightBox={(f) => {
                                            props.reduxFunction("ASYNC", "LIGHTBOX", {
                                                open: true,
                                                files: reduxStore.getState().timeline[data.cardId][tm].files ? reduxStore.getState().timeline[data.cardId][tm].files : [],
                                                file: f
                                            })
                                        }}
                                    />
                                    {(tmi + 1 === cardTimeline.length) ?
                                        <div style={{
                                            position: "absolute",
                                            height: "93px",
                                            left: 0, bottom: "-33px", right: 8,
                                            background: 'linear-gradient(0deg, rgba(255,255,255,1) 34%, rgba(255,255,255,0) 100%)',
                                        }}>
                                            <div style={{ position: "absolute", bottom: "0px", display: "flex", justifyContent: "space-between", width: "100%" }}>
                                                <CustomButton
                                                    title={translate(`$__open`, 1)}
                                                    text={translate(`$__open`, 1)}
                                                    color={customApp('color')}
                                                    icon={'launch'}
                                                    onClick={() => {
                                                        if (props.onClick)
                                                            props.onClick()
                                                        props.reduxFunction("ASYNC", "SET_MODULE", {
                                                            ...reduxStore.getState().sideModule,
                                                            db: 'cards',
                                                            id: data.cardId,
                                                            module: "cardEdit",
                                                            activeModule: "cardEdit",
                                                            data: reduxStore.getState().db.cards[data.cardId],
                                                        })
                                                        markAsRead()
                                                    }
                                                    }
                                                    size={'13px'}
                                                    textSize={'13px'}
                                                    style={{ padding: "0px !important" }}
                                                />
                                                <CustomButton
                                                    title={translate(`$__markAsRead`, 1)}
                                                    text={translate(`$__markAsRead`, 1)}
                                                    color={customApp('color')}
                                                    icon={'mark_chat_read'}
                                                    onClick={() => {
                                                        markAsRead()
                                                    }}
                                                    size={'13px'}
                                                    textSize={'13px'}
                                                    style={{ padding: "0px !important" }}
                                                />
                                            </div>
                                        </div> : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            )
                        })
                    }
                </React.Fragment>
                : <React.Fragment></React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (store) => ({
    cards: store.db.cards,
    users: store.db.users,
    timeline: store.timeline
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CardNotification)

import React, { useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ButtonOptions from 'componentsV3/Buttons'
import { translate } from 'functions';


const PalleteButton = (props) => {

    const { handleColorSet } = props
    const [mostrarLista, setMostrarLista] = useState(false);
    const [backgroundCor, setBackgroundCor] = useState('white')

    const toggleLista = () => {
        setMostrarLista(!mostrarLista);
    };

    const itemStyle = {
        height: '50px',
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginLeft: '0px',
        padding: '0px',
        width: '200px',
        display: 'flex',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        borderBottom: '2px solid #eeeeee'
    }

    const coresArray1 = ['#3498db', '#85c1e9', '#2980b9', '#aed6f1', '#1f618d', '#7fb3d5'];
    const coresArray2 = ['#1b6b99', '#1c5681', '#0e4063', '#0a2e4d', '#1f3a4b', '#0c2c3a'];
    const coresArray3 = ['#849AA4', '#2E5E90', '#9ED3D8', '#F07857', '#008AC4', '#2C2C2C'];
    const coresArray4 = ["#6AB187", "#CED2CC", "#23282D", "#4CB5F5", "#1F3F49", "#D32D41"];
    const coresArray5 = ["#4B49AC", "#98BDFF", "#23282D", "#4CB5F5", "#1F3F49", "#D32D41"];
    const coresArray6 = ["#01a0a8", "#de572f", "#fdbd3f", "#642201", "#eb7f58", "#e9d5a8"];

    const colorArr = [coresArray1, coresArray2, coresArray3, coresArray4, coresArray5, coresArray6];


    return (
        <div
            onMouseEnter={() => setBackgroundCor('rgba(0, 0, 0, 0.12)')}
            onMouseLeave={() => setBackgroundCor('rgba(0, 0, 0, 0)')}
            style={{ position: 'relative', backgroundColor: backgroundCor }}>

            <ButtonOptions
                text={translate("$__colorPalette")}
                icon={"palette"}
                onClick={toggleLista}
                onBlur={toggleLista}
            >
            </ButtonOptions>
            {mostrarLista && (
                <ul style={{ position: 'absolute', zIndex: '999999999', padding: '0px', top: '25px', backgroundColor: 'white', border: '2px solid #eeeeee' }}>
                    {colorArr.map((item) => {
                        let values = item.map((value, index) => {
                            let label = '';
                            switch (index) {
                                case 0:
                                    label = 'completed';
                                    break;
                                case 1:
                                    label = 'awaitAction';
                                    break;
                                case 2:
                                    label = 'inProgress';
                                    break;
                                case 3:
                                    label = 'paused';
                                    break;
                                case 4:
                                    label = 'notStarted';
                                    break;
                                case 5:
                                    label = 'late';
                                    break;
                                default:
                                    label = '';
                                    break;
                            }
                            return {
                                color: value,
                                label: label
                            }
                        })
                        return <li
                            style={itemStyle}
                            onClick={() => handleColorSet(values)}
                            onMouseEnter={(e) => {
                                if (e.target.style.backgroundColor === 'white') {
                                    e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)';
                                }
                            }}
                            onMouseLeave={(e) => {
                                console.log(e.target.style.backgroundColor)
                                if (e.target.style.backgroundColor === 'rgba(0, 0, 0, 0.12)') {
                                    e.target.style.backgroundColor = 'white';
                                }
                            }}
                        >
                            {item.map((color) => {
                                return (
                                    <span
                                        style={{
                                            width: '15px',
                                            height: '15px',
                                            backgroundColor: color,
                                            alignSelf: 'center',
                                        }}
                                    >
                                    </span>
                                );
                            })}
                        </li>
                    })}
                </ul>
            )}
        </div>

    )
}

export default PalleteButton
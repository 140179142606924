//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Radio from "components/Radio"
import Icon from "components/Icon"
import CustomButton from "components/Buttons/custom"
import MoreMenu from "components/MoreMenu/"
import TextField from "components/TextField";


//@MATERIAL
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import { withStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card-Users.js"

//IMAGES
import defaultAvatar from "assets/img/default-avatar.png"

//FUNCTIONS
import { translate, customApp, appAlert, getAppAccess } from "functions"
import { cardsAccess, groupAccess, groupAccessRemove, isResponsible } from "functions/users"
import api from "api/"

import {
    planMyAccountVerify,
} from "functions/cards"

function Component(props) {
    const { classes } = props
    const { db, sideModule, session } = reduxStore.getState()
    const [userEditing, setUserEditing] = React.useState({ id: null })
    const [reqSave, setReqSave] = React.useState(false)
    const [view, setView] = React.useState("users")
    const [searchValue, setSearchValue] = React.useState("")
    const [search, setSearch] = React.useState(false)

    const card = db.cards[sideModule.data._id]

    let client = card?.client || db.cards[card._planId]?.client || null

    let users = { ...db.users }
    let unSelectedUsers = {
        ...card && card._users ? { ...card._users } : {},
        ...card._parent && db.cards[card._parent] && db.cards[card._parent]._users ? { ...db.cards[card._parent]._users } : {},
        ...card._planId && db.cards[card._planId] && db.cards[card._planId]._users ? { ...db.cards[card._planId]._users } : {},
        ...users,
    }

    let unSelectedGroups = Array.from(reduxStore.getState().db.userGroup)

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let AppAccess = getAppAccess()
    let CardAccess = card && card._id && db.cards[card._id] && db.cards[card._id]._users && Object.keys(db.cards[card._id]._users).length > 0 ? db.cards[card._id]._users[Object.keys(db.cards[card._id]._users).find(a => a.indexOf(session._id) > -1)] : 0

    if (!sideModule || !sideModule.data)
        return null

    const close = () => {
        if (reqSave) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    setUserEditing({ _id: null })
                    setReqSave(false)
                }
            })
        } else {
            setUserEditing({ _id: null })
            setReqSave(false)
        }

    }
    const saveUser = async (id, acc, userData) => {
        let access = 1
        if (card && card.type === 'plan')
            access = 3
        if (!acc) {
            if (
                card._planId
                && db.cards[card._planId]
                && db.cards[card._planId]._users
                && db.cards[card._planId]._users[id]
                && db.cards[card._planId]._users[id].access
            ) {
                if (card._parent
                    && db.cards[card._parent]
                    && db.cards[card._parent]._users
                    && db.cards[card._parent]._users[id]
                    && db.cards[card._parent]._users[id].access
                    && parseInt(db.cards[card._parent]._users[id].access) > parseInt(db.cards[card._planId]._users[id].access)
                ) {
                    access = parseInt(db.cards[card._parent]._users[id].access)
                } else {
                    access = parseInt(db.cards[card._planId]._users[id].access)
                }
            }
        } else {
            access = acc
        }
        const noNotification = db.cards[sideModule.data._id] && db.cards[sideModule.data._id]._users && db.cards[sideModule.data._id]._users[id] ? true : false
        const saved = await cardsAccess(props, id, access, sideModule.data._id, noNotification, userData)
        if (saved) {
            setReqSave(false)
            setUserEditing({ _id: null })
            return true
        }
    }

    const saveGroup = async (id) => {
        const saved = await groupAccess(props, id, sideModule.data._id)
        if (saved) {
            setReqSave(false)
            setUserEditing({ _id: null })
            return true
        }
    }

    const deleteAccessUser = async (id, close) => {
        const response = await api.put(`cards/removeAccess/`, {
            cardId: sideModule.data._id,
            userIds: [id]
        })
        if (response) {
            if (close)
                props.reduxFunction("ASYNC", "CLEAR_MODULE");
            // delete newUsers[id]
            setReqSave(false)
            setUserEditing({ _id: null })
        }
    }

    if (!card)
        return null
    users = users && Object.keys(users).length > 0 && Object.keys(users)
        .filter(a =>
            a &&
            (
                card
                && card._users
                && card._users[users[a]._id]
                && !card._users[users[a]._id].deleted
            )
        )
        .sort((a, b) => {
            if (a.name < b.name)
                return -1
            return 0
        })
        .map(a => users[a])

    unSelectedUsers = Object.keys(unSelectedUsers)
        .filter(a =>
            unSelectedUsers[a].type === 'user'
            && !unSelectedUsers[a].deleted
            && (
                (
                    !client
                    &&
                    (
                        !unSelectedUsers[a]._parent
                        || (
                            unSelectedUsers[a]._parent
                            && unSelectedUsers[a]._parent === session.account
                        )
                    )
                )
                ||
                (
                    client
                    &&
                    (
                        (
                            unSelectedUsers[a]._parent
                            && (
                                unSelectedUsers[a]._parent === client
                                || unSelectedUsers[a]._parent === session.account

                            )
                        )
                        || !unSelectedUsers[a]._parent
                    )
                )
            )
        )
        .map(a => unSelectedUsers[a])

    // unSelectedUsers = Object.keys(unSelectedUsers)
    //     .filter(a => {
    //         if (
    //             a
    //             && (
    //                 !unSelectedUsers[a].type
    //                 || (
    //                     unSelectedUsers[a].type
    //                     && unSelectedUsers[a].type !== "client"
    //                     && unSelectedUsers[a].type !== "unity"
    //                     && unSelectedUsers[a].type !== "group"
    //                 )
    //             )
    //             && (
    //                 !unSelectedUsers[a].deleted
    //                 && (
    //                     (
    //                         !client
    //                         &&
    //                         (
    //                             !unSelectedUsers[a]._parent
    //                             || (
    //                                 unSelectedUsers[a]._parent
    //                                 && unSelectedUsers[a]._parent === session.account
    //                             )
    //                         )
    //                     )
    //                     ||
    //                     (
    //                         client
    //                         &&
    //                         (
    //                             (
    //                                 unSelectedUsers[a]._parent
    //                                 && (
    //                                     unSelectedUsers[a]._parent === client
    //                                     || unSelectedUsers[a]._parent === session.account

    //                                 )
    //                             )
    //                             || !unSelectedUsers[a]._parent
    //                         )
    //                     )
    //                 )
    //             )
    //         )
    //             return true
    //         return false
    //     }
    //     )
    //     .map(a => unSelectedUsers[a])

    // unSelectedUsers = unSelectedUsers.filter(a =>
    //     a && (
    //         (
    //             !client
    //             || (
    //                 client
    //                 && (
    //                     a._parent === client
    //                     || !a._parent
    //                 )
    //             )
    //         )
    //         && (!card._users
    //             || !card._users[a._id]
    //         )
    //         && (
    //             !searchValue
    //             || (
    //                 searchValue
    //                 && (
    //                     (
    //                         a.name
    //                         && a.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    //                     )
    //                     ||
    //                     (
    //                         searchValue.indexOf('>') > -1
    //                         && a._parent
    //                         && reduxStore.getState().db.users
    //                         && reduxStore.getState().db.users[a._parent]
    //                         && reduxStore.getState().db.users[a._parent].name
    //                         && reduxStore.getState().db.users[a._parent].name.toLowerCase().indexOf(searchValue.toLowerCase().replace('>', "").trim()) > -1
    //                     )
    //                 )
    //             )
    //         )
    //     )
    // ).map(a => a)


    const addAllUsers = async (adus) => {
        setSearchValue("")
        adus.forEach(u => {
            saveUser(u._id, null, u)
        })
    }

    const addAllGroups = async (adus) => {
        setSearchValue("")
    }

    const renderGroup = (gr) => {
        return (
            <div
                className={classes.user}
                style={{ height: 37 }}
            >
                <div
                    className={classes.name}
                    style={{ left: 0 }}
                >
                    {gr.label}
                    <CustomButton
                        transparent
                        title={card._groups && card._groups[gr.value] ? translate("$__removeTeam") : translate("$__addTeam")}
                        icon={card._groups && card._groups[gr.value] ? "group_remove" : "group_add"}
                        size={"20px"}
                        onClick={() => {
                            if (card && (!card._groups || (card._groups && !card._groups[gr.value]))) {
                                saveGroup(gr.value)
                            } else {
                                groupAccessRemove(props, gr.value, card._id)
                            }

                        }}
                    />
                </div>

            </div>
        )
    }

    const renderUser = (us, add = false) => {
        let permission = true
        let u = us
        if (us._id && db.users[us._id])
            u = db.users[us._id]
        if (
            (
                !us.type
                || us.type === 'external'
            )
            && (
                !u._parent
                || (
                    u._parent
                    && (
                        !db.users[u._parent]
                    )
                )
            )
        ) {
            permission = false
        }

        if (card && card._planId && planMyAccountVerify(card._planId))
            permission = true

        let access = 1
        if (u && u._id && card._users && card._users[u._id] && card._users[u._id].access)
            access = String(card._users[u._id].access)
        return (
            <div className={classes.user}>
                <div className={classes.name}>
                    {u.name}
                    {card._users[u._id] && card._users[u._id].isResponsible ? <span style={{ fontSize: "9px", fontWeight: 'normal', color: '#CCCCCC' }}>{translate("$__responsible", 1)}</span>
                        :
                        ``
                    }
                    {add && !userEditing._id ?
                        <div>
                            <CustomButton
                                transparent
                                title={translate("$__add")}
                                icon={"person_add"}
                                size={"20px"}
                                onClick={() => {
                                    saveUser(u._id, null, u)
                                }}
                            />
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                    {!add ?
                        <div
                            style={{
                                zIndex: 10
                            }}
                        >
                            <MoreMenu
                                horizontalButton
                                transparent
                                size="14px"
                                btColor={customApp("color")}
                                options={[
                                    {
                                        name: "$__setAsResponsible",
                                        icon: "admin_panel_settings",
                                        onClick: () => {
                                            appAlert({
                                                message: translate('$__confirmSetAsResponsibleCard', 1),
                                                variant: 'warning',
                                                persist: false,
                                                horizontal: 'right',
                                                confirm: () => {
                                                    isResponsible(props, {
                                                        cardId: sideModule.data._id,
                                                        userId: u._id
                                                    })
                                                }
                                            })
                                        },
                                    },
                                    ...permission && (
                                        (
                                            u
                                            &&
                                            (
                                                (
                                                    !u._access
                                                    ||
                                                    (
                                                        u._access
                                                        && u._access !== 'owner'
                                                    )
                                                )
                                                &&
                                                (
                                                    (
                                                        CardAccess
                                                        && String(CardAccess.access)
                                                        && parseInt(CardAccess.access) > 2
                                                        && parseInt(CardAccess.access) >= parseInt(access)
                                                    )
                                                    ||
                                                    (
                                                        AppAccess
                                                        && AppAccess.plan
                                                        &&
                                                        (
                                                            AppAccess.planAdmin
                                                            || AppAccess.planModerate
                                                        )
                                                    )
                                                )
                                            )

                                        )
                                        ||
                                        u._id === session._id
                                    ) ? [
                                        {
                                            name: "$__editAccess",
                                            icon: "manage_accounts",
                                            onClick: () => {
                                                setUserEditing({
                                                    _id: u._id,
                                                    access: access
                                                })
                                            },
                                        },
                                        {
                                            name: "$__removeAccess",
                                            icon: "delete_forever",
                                            color: 'red',
                                            onClick: () => {
                                                appAlert({
                                                    message: u._id !== session._id ? translate('$__confirmRemoveUser', 1) : translate('$__confirmGetOut', 1),
                                                    variant: 'warning',
                                                    persist: false,
                                                    horizontal: 'right',
                                                    confirm: () => {
                                                        deleteAccessUser(u._id, u._id !== session._id ? false : true)
                                                    }
                                                })
                                            },
                                        },
                                    ] : []
                                ]}
                            />
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </div>
                <div className={classes.avatar}
                    style={{
                        backgroundImage: `url("${u.image ? u.image : defaultAvatar}")`
                    }}>
                    {u._parent &&
                        db.users[u._parent] &&
                        db.users[u._parent].image ?
                        <div
                            style={{
                                position: "absolute",
                                bottom: -4,
                                right: -7,
                                width: 22,
                                height: 22,
                                borderRadius: "50%",
                                backgroundImage: `url(${db.users[u._parent].image})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat"
                            }}></div>
                        : <React.Fragment></React.Fragment>}
                </div>
                {!add ?
                    <div className={classes.access}
                        style={{
                            cursor: 'pointer',
                            paddingLeft: 15,
                            paddingRight: 15,
                            paddingTop: 3,
                            display: "block"
                        }}
                        onClick={permission ? () => {
                            if (
                                (
                                    u
                                    &&
                                    (
                                        (
                                            !u._access
                                            ||
                                            (
                                                u._access
                                                && u._access !== 'owner'
                                            )
                                        )
                                        &&
                                        (
                                            (
                                                CardAccess
                                                && String(CardAccess.access)
                                                && parseInt(CardAccess.access) > 2
                                                && parseInt(CardAccess.access) >= parseInt(access)
                                            )
                                            ||
                                            (
                                                AppAccess
                                                && AppAccess.plan
                                                &&
                                                (
                                                    AppAccess.planAdmin
                                                    || AppAccess.planModerate
                                                )
                                            )
                                        )
                                    )

                                )
                                ||
                                u._id === session._id
                            ) {
                                setUserEditing({
                                    _id: u._id,
                                    access: access
                                })
                            }
                        }
                            : null
                        }>
                        {u._parent &&
                            db.users[u._parent] &&
                            db.users[u._parent].name ?
                            <div style={{ position: "relative", float: "left" }}>
                                {db.users[u._parent].name}
                            </div>
                            : <React.Fragment></React.Fragment>}
                        <div style={{ position: "relative", float: "right" }}>
                            ({u._access === 'owner' ? translate('$__owner', 1)
                                : access === '0' ? translate('$__reader', 1)
                                    : access === '1' ? translate('$__executioner', 1)
                                        : access === '2' ? translate('$__collaborator', 1)
                                            : access === '3' ? translate('$__leadership', 1)
                                                : access === '4' ? translate('$__supervisor', 1)
                                                    : access === '5' ? translate('$__admin', 1)
                                                        : translate('$__reader', 1)

                            })
                        </div>
                    </div>
                    : u._parent &&
                        db.users[u._parent] &&
                        db.users[u._parent].name ?
                        <div className={classes.access}>
                            <div>
                                {db.users[u._parent].name}
                            </div>
                        </div>
                        : <React.Fragment></React.Fragment>}
            </div>
        )
    }

    if (userEditing && userEditing._id) {
        let access = 1
        if (card._users && card._users[userEditing._id] && card._users[userEditing._id].access)
            access = String(card._users[userEditing._id].access)
        let u = card._users[userEditing._id]
        let permission = false
        if (card && card._planId && planMyAccountVerify(card._planId))
            permission = true

        return (
            <React.Fragment>
                {renderUser(u, true)}
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        float: "left",
                        justifyContent: "flex-end"
                    }}
                >
                    {reqSave &&
                        <CustomButton
                            transparent
                            title={translate("$__save")}
                            icon={"save"}
                            color={customApp('color')}
                            onClick={() => {
                                saveUser(userEditing._id, userEditing.access, u)
                            }}
                        />
                    }
                    {users.length > 1 &&
                        <CustomButton
                            transparent
                            title={userEditing._id !== session._id ? translate('$__removeAccess', 1) : translate('$__getOut', 1)}
                            icon={userEditing._id !== session._id ? 'delete_forever' : 'logout'}
                            color={'red'}
                            onClick={() => {
                                appAlert({
                                    message: userEditing._id !== session._id ? translate('$__confirmRemoveUser', 1) : translate('$__confirmGetOut', 1),
                                    variant: 'warning',
                                    persist: false,
                                    horizontal: 'right',
                                    confirm: () => {
                                        deleteAccessUser(userEditing._id, userEditing._id !== session._id ? false : true)
                                    }
                                })
                            }}
                        />
                    }
                    <CustomButton
                        transparent
                        title={translate('$__close', '*')}
                        icon={'undo'}
                        color={customApp('medium')}
                        onClick={() => close()}
                    />
                </div>
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                        padding: 15,
                        float: "left"
                    }}
                >
                    {(userEditing._id !== session._id || (AppAccess.plan && AppAccess.planAdmin)) &&
                        <div style={{
                            position: "relative",
                            float: "left",
                            width: "100%",
                        }}>
                            <RadioGroup
                                defaultValue={String(access)}
                                aria-label="gender"
                                name="customized-radios"
                                onChange={(e) => {
                                    setUserEditing({ ...userEditing, access: e.target.value })
                                    setReqSave(true)
                                }}
                            >
                                {card.type !== 'plan' && ((CardAccess && parseInt(CardAccess.access) >= 0) || (permission && (AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="0" control={<Radio />} label={translate('$__reader', 1)} />
                                }
                                {card.type !== 'plan' && ((CardAccess && parseInt(CardAccess.access) >= 1) || (permission && (AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="1" control={<Radio />} label={translate('$__executioner', 1)} />
                                }
                                {card.type !== 'plan' && card.type !== 'chatGroup' && ((CardAccess && parseInt(CardAccess.access) >= 2) || (permission && (AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="2" control={<Radio />} label={translate('$__collaborator', 1)} />
                                }
                                {((CardAccess && parseInt(CardAccess.access) >= 3) || (permission && (AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="3" control={<Radio />} label={translate('$__leadership', 1)} />
                                }
                                {card.type !== 'chatGroup' && ((CardAccess && parseInt(CardAccess.access) >= 4) || (permission && (AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="4" control={<Radio />} label={translate('$__supervisor', 1)} />
                                }
                                {((CardAccess && parseInt(CardAccess.access) >= 5) || (AppAccess && AppAccess.plan && (permission && (AppAccess.planAdmin || AppAccess.planModerate))))
                                    &&
                                    <FormControlLabel value="5" control={<Radio />} label={translate('$__admin', 1)} />
                                }
                            </RadioGroup>
                        </div>
                    }
                    {card.type !== 'chatGroup' ?
                        <ul id="ACTIVE_USERS_LIST" style={{ position: 'relative', float: 'left', width: '100%' }}>
                            {userEditing.access === "0" &&
                                <React.Fragment>
                                    <li>{translate('$__readOnlyTodo', 1)}</li>
                                    <li>{translate('$__readTimeline', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) >= 1 &&
                                <React.Fragment>
                                    <li>{translate('$__executioner', 1)}</li>
                                    <li>{translate('$__timeSheetRegister', 1)}</li>
                                    <li>{translate('$__interactonTheTimeline', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) > 1 &&
                                <li>{translate('$__ExecuteAndCreateSubTasks', 1)}</li>
                            }
                            {parseInt(userEditing.access) > 2 &&
                                <React.Fragment>
                                    {sideModule.data.type === 'plan' &&
                                        <React.Fragment>
                                            <li>{translate('$__access', 1)} BRIGHT & CONNECTED</li>
                                            <li>{translate('$__access', 1)} INSIGHTS</li>
                                        </React.Fragment>
                                    }
                                    <li>{translate('$__updateDueDate', 1)}</li>
                                    <li>{translate('$__updateTags', 1)}</li>
                                    <li>{translate('$__updateIndicators', 1)}</li>
                                    <li>{translate('$__addAndRemoveUsers', 1)}</li>
                                    <li>{translate('$__defineResponsible', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) > 3 &&
                                <React.Fragment>
                                    <li>{translate('$__updateData', 1)}</li>
                                    <li>{translate('$__usersManager', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) >= 4 &&
                                <React.Fragment>
                                    {sideModule.data.type === 'plan' &&
                                        <React.Fragment>
                                            <li>{translate('$__access', 1)} MATRIX SWOT</li>
                                        </React.Fragment>
                                    }
                                    <li>{translate('$__settings', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) === 5 &&
                                <li>{translate('$__deleteActivity', 1)}</li>
                            }
                        </ul>
                        :
                        <ul id="ACTIVE_USERS_LIST" style={{ position: 'relative', float: 'left', width: '100%' }}>
                            {parseInt(userEditing.access) > 1 &&
                                <li>{translate('$__viewAndInteract', 1)}</li>
                            }
                            {parseInt(userEditing.access) > 2 &&
                                <React.Fragment>
                                    <li>{translate('$__addAndRemoveUsers', 1)}</li>
                                    <li>{translate('$__update', 1)}</li>
                                </React.Fragment>
                            }
                            {parseInt(userEditing.access) === 5 &&
                                <li>{translate('$__deleteGroup', 1)}</li>
                            }
                        </ul>
                    }

                    {card._users[userEditing._id] && !card._users[userEditing._id].isResponsible &&
                        <div className={classes.switch}
                            style={{
                                position: 'relative',
                                width: '100%',
                                float: 'left',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}>
                            <CustomButton
                                transparent
                                title={translate("$__setAsResponsible")}
                                text={translate("$__setAsResponsible")}
                                icon={"admin_panel_settings"}
                                size={"25px"}
                                onClick={() => {
                                    appAlert({
                                        message: translate('$__confirmSetAsResponsibleCard', 1),
                                        variant: 'warning',
                                        persist: false,
                                        horizontal: 'right',
                                        confirm: () => {
                                            isResponsible(props, {
                                                cardId: sideModule.data._id,
                                                userId: userEditing._id
                                            })
                                        }
                                    })

                                }}
                            />
                        </div>
                    }
                </div>

            </React.Fragment>
        )
    }



    return (
        <div className={classes.root}>
            {search &&
                <div style={{
                    marginTop: 15,
                    width: "100%",
                    padding: "4px 15px",
                    fontSize: "16px",
                    color: "lightGray"
                }}>
                    <TextField
                        id={`quickUser_Search`}
                        label={translate('$__searchByName', 1)}
                        variant={customApp('fieldVariant')}
                        name={'fullName'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setSearchValue("")
                                        setSearch(false)
                                    }}>
                                    <Icon icon='close' />
                                </InputAdornment>
                            ),
                            onChange: (d) => {
                                setSearchValue(d.target.value)
                            },
                            onBlur: (d) => {

                            }
                        }}
                        value={searchValue}
                        placeholder={translate('$__searchByName', 1)}
                        autoFocus
                    />
                </div>
            }
            {(searchValue === "" || !searchValue) ?
                <React.Fragment>
                    <fieldset>
                        <legend style={{ color: customApp('color') }}>{translate("$__permittedUsers", 1)}</legend>
                        {users && users.map((u, i) => <React.Fragment key={u._id}>{renderUser(u)}</React.Fragment>)}
                    </fieldset>
                    {card && card._groups && Object.keys(card._groups).length > 0 ?
                        <fieldset>
                            <legend style={{ color: customApp('color') }}>{translate("$__permittedTeams", 1)}</legend>
                            {unSelectedGroups.filter(group => card._groups[group.value]).map((u, i) => <React.Fragment key={u}>{renderGroup(u)}</React.Fragment>)}
                        </fieldset>
                        : <React.Fragment></React.Fragment>}
                </React.Fragment>
                :
                <React.Fragment></React.Fragment>
            }
            {/* {parentUsers && parentUsers.length > 0 &&
                <fieldset
                    style={{
                        marginTop: '7px'
                    }}
                >
                    <legend style={{ color: customApp('color') }}>{translate("$__activeExternalUsers", 1)}</legend>
                    {parentUsers.map((u, i) => <React.Fragment key={u._id}>{renderUser(u)}</React.Fragment>)}
                </fieldset>
            } */}
            <fieldset
                style={{
                    // position: "relative",
                    // padding: 7,
                    // border: "solid 1px rgba(0,0,0,0.5)",
                    display: "flex"
                }}
            >
                <div style={{
                    // backgroundColor: "rgba(0,0,0,0.1)",
                    padding: 7,
                    width: "calc(50% - 4px)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    backgroundColor: view !== 'users' ? "rgba(0,0,0,0.07)" : customApp("color"),
                    color: view !== 'users' ? "gray" : "#FFFFFF"
                }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        onClick={() => {
                            setView("users")
                        }}
                    >
                        <Icon
                            icon={"supervised_user_circle"}
                            size={"33px"}
                            color={view !== 'users' ? null : "#FFFFFF"}
                        />
                        {translate("$__showUsers")}
                    </div>
                </div>
                <div
                    style={{
                        padding: 7,
                        marginLeft: 7,
                        width: "calc(50% - 4px)",
                        cursor: "pointer",
                        backgroundColor: view !== 'users' ? customApp("color") : "rgba(0,0,0,0.07)",
                        color: view !== 'users' ? "#FFFFFF" : "gray"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",

                        }}
                        onClick={() => {
                            setView("teams")
                        }}
                    >
                        <Icon
                            icon={"group_work"}
                            size={"33px"}
                            color={view !== 'users' ? "#FFFFFF" : null}

                        />
                        {translate("$__showTeams")}
                    </div>
                </div>
            </fieldset>
            {view === 'users' && unSelectedUsers && unSelectedUsers.length > 0 && !userEditing._id &&
                <fieldset
                    style={{
                        marginTop: '7px'
                    }}
                >
                    <legend style={{ color: customApp('color') }}>{translate("$__avaiableUsers", 1)}</legend>
                    <div>
                        <CustomButton
                            transparent
                            title={translate("$__add")}
                            icon={"person_add"}
                            text={translate("$__addAllUsers")}
                            size={"20px"}
                            onClick={() => {
                                addAllUsers(unSelectedUsers)
                            }}
                        />
                    </div>
                    {unSelectedUsers
                        .filter(a => !searchValue || a.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
                        .sort((a, b) => {
                            if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim())
                                return -1
                            if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim())
                                return 1
                            return 0
                        }).map((u, i) => <React.Fragment key={u._id}>{renderUser(u, true)}</React.Fragment>)}
                </fieldset>
            }
            {view !== 'users' && unSelectedGroups && unSelectedGroups.length > 0 && !userEditing._id &&
                <fieldset
                    style={{
                        marginTop: '7px'
                    }}
                >
                    <legend style={{ color: customApp('color') }}>{translate("$__avaiableTeams", 1)}</legend>
                    <div>
                        <CustomButton
                            transparent
                            title={translate("$__add")}
                            icon={"person_add"}
                            text={translate("$__addAllTeams")}
                            size={"20px"}
                            onClick={() => {
                                addAllGroups(unSelectedGroups)
                            }}
                        />
                    </div>
                    {unSelectedGroups.filter(group =>
                        (
                            !searchValue
                            || (
                                searchValue
                                && group.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
                            )
                        )
                        &&
                        (!card._groups
                            || (card._groups && !card._groups[group.value])
                        )
                    ).sort((a, b) => {
                        if (a.label.toLowerCase().trim() < b.label.toLowerCase().trim())
                            return -1
                        if (a.label.toLowerCase().trim() < b.label.toLowerCase().trim())
                            return 1
                        return 0
                    }).map((u, i) => <div style={{ width: "100%", padding: 7 }} key={u._id}>
                        {renderGroup(u, true)}
                    </div>)
                    }
                </fieldset>
            }
            {!search ?
                <div
                    style={{
                        position: "absolute",
                        right: 15,
                        top: 0,
                    }}
                >
                    <CustomButton
                        title={translate(`$__search`, 1)}
                        color={customApp("menu")}
                        icon={"search"}
                        onClick={() => { setSearch(true) }}
                        size={"25px"}
                    />
                </div>
                :
                <React.Fragment></React.Fragment>
            }
        </div>
    )
}
const mapStateToProps = (store, props) => ({
    users: store.db.users,
    cardUsers: store.db.cards[store.sideModule.data._id]
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))

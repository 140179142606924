const initialState = {}

const socket = (state = initialState, data) => {
    if (data.action === 'SET_FUNCTIONS' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    }
    else {
        return state
    }
};

export default socket;
import React, { useEffect, useRef, useState } from 'react'
import { translate } from 'functions'
import HtmlTooltip from 'components/Tooltip/html'
import Icon from 'components/Icon'



const Legend = ({ legend, customColors }) => {

  const containerRef = useRef()
  const [legendX, setLegendX] = useState([])
  const [tooltip, setTooltip] = useState([])
  let legendReverse = legend

  useEffect(() => {
    if (!containerRef.current) return;

    if (customColors) {
      legendReverse = legendReverse.map((item) => {
        const foundColorObj = customColors.find((colorObj) => {
          return translate(colorObj.label) === item.label
        });
        if (foundColorObj) {
          return {
            ...item,
            color: foundColorObj.color
          };
        } else {
          return item;
        }
      })
    }
    const resizeObserver = new ResizeObserver(() => {
      let width = containerRef.current.clientWidth
      let valuesArr = [510, 420, 350]
      let data = [...legendReverse];
      let tooltipData = []
      valuesArr.forEach((item, i) => {
        if (width < item) {
          let index
          switch (i) {
            case 0: index = 4; break;
            case 1: index = 3; break;
            case 2: index = 2; break;
            default: index = 5
          }

          data = [...legendReverse];
          tooltipData = data.splice(index, 5);
        }
      })
      setLegendX(data);
      setTooltip(tooltipData);
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect(); // clean up 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legend]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div ref={containerRef} style={{ marginLeft: '20px', display: 'block', alignSelf: 'center', width: '100%', overflow: 'hidden' }}>
      <div style={{ display: 'flex', width: '500px' }}>
        {legendX.map(({ id, color, name, label, key }) => {
          const texto = translate(name ? name : label ? label : id)
          const primeiraLetra = texto.charAt(0);
          const restoTexto = texto.slice(1).toLowerCase();
          const textoConvertido = primeiraLetra + restoTexto;
          return (
            <div key={key} style={{ display: 'flex', marginRight: '16px', whiteSpace: 'nowrap', alignSelf: 'center' }}>
              <div style={{ width: '15px', height: '15px', borderRadius: '10px', backgroundColor: color, marginTop: '2px', }}></div>
              <p style={{ margin: '0px', marginTop: '-0.8px', marginLeft: '6px', fontSize: '14px', display: 'block' }}>
                {
                  ['paused', 'notstarted', 'completed', 'inprogress', 'awaitaction'].includes(textoConvertido) ?
                    translate(`$__${textoConvertido}`) : textoConvertido
                }
              </p>
            </div>
          )
        })}
        {tooltip.length > 0 && <HtmlTooltip
          title={(
            <React.Fragment>
              <div style={{ marginBottom: '10px', fontSize: '16px', marginTop: '10px', justifyContent: 'center' }}>
                {tooltip.map(({ id, color, name, label, key }) => {
                  const texto = translate(name ? name : label ? label : id)
                  const primeiraLetra = texto.charAt(0);
                  const restoTexto = texto.slice(1).toLowerCase();
                  const textoConvertido = primeiraLetra + restoTexto;
                  return (
                    <div key={key} style={{ display: 'flex', marginTop: '4px', marginRight: '16px', whiteSpace: 'nowrap', alignSelf: 'center', width: '100%' }}>
                      <div style={{ width: '15px', height: '15px', borderRadius: '10px', backgroundColor: color, alignSelf: 'center' }}></div>
                      <p style={{ margin: '0px', marginLeft: '6px', display: 'block', fontSize: '16px' }}>
                        {
                          ['paused', 'notstarted', 'completed', 'inprogress', 'awaitaction'].includes(textoConvertido) ?
                            translate(`$__${textoConvertido}`) : textoConvertido
                        }
                      </p>
                    </div>
                  )
                })
                }
              </div>
            </React.Fragment>
          )} >
          <div style={{ alignSelf: 'center', border: '1px solid #D3D3D3 ', fontSize: '16px', borderRadius: '5px', display: 'flex', backgroundColor: '#f5f5f5', cursor: 'pointer' }}>
            <Icon style={{ alignSelf: 'center' }} size={16}>add</Icon>
            <p style={{ margin: '0px' }}>{tooltip.length}</p>
            <Icon style={{ alignSelf: 'center' }} size={16}>expand_more</Icon>
          </div>
        </HtmlTooltip>}
      </div>
    </div >
  )
}


export default Legend
const initialState = {
    cards: {
        load: {},
    },
    timeline: {
        load: {}
    },
};

const sessionLoads = (state = initialState, data) => {
    if (data.action === 'SET_SESSION_LOADS' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_SESSION_LOADS') {
        return {
            ...state,
        }

    } else {
        return state
    }
};

export default sessionLoads;
const initialState = {
    typing: {}
}

const sideModule = (state = initialState, data) => {
    if (data.action === 'SET_TYPING' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    }
    if (data.action === 'RESET_TYPING') {
        return {
            ...initialState
        }
    }
    return state
};

export default sideModule;
const initialState = {
    users: {},
};

const session = (state = initialState, data) => {
    if (data.action === 'SET_USERS_STATUS' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_USERS_STATUS') {
        return {
            ...state,
        }

    } else {
        return state
    }
};

export default session;
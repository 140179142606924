import React, { useEffect, useState, useRef } from 'react'
//COMPONENTS
import Legend from '../Legend';
import { ResponsiveBar } from '@nivo/bar'
import Header from 'components/Dashboards/Header'
import './Loader.css';

//REDUX
import reduxStore from "store/"
import moment from "moment-timezone"
import { translate } from 'functions';


//https://codesandbox.io/s/m4ro13jjn8?file=/src/index.js


const TaskDoneBarMonth = (props) => {

    const { db } = reduxStore.getState()
    const [filter, setFilter] = useState({
        index: 0,
        showQuantity: 12,
        number: 12,
        finalIndex: 12,
        dataStructure: 'month',
    })
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [data, setData] = useState({
        'total': {
            "total": 0,
            "month": moment().startOf('month').format('MMMM'),
        }
    })
    const keysRef = useRef(['total'])
    const keysColors = useRef([])
    const keysLabel = useRef([{ label: 'Concluidas', color: '#5A7F71' }])
    const filterRef = useRef([])


    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    // number: filter?.users ? filter?.users?.length : 10,

    const generateColor = (name) => {
        let hash = 0;
        for (let i = 0; i < name.length; i++) {
            hash += name.charCodeAt(i);
        }
        const hexColor = "#" + ((hash % 0xFFFFFF) + 0x1000000).toString(16).slice(1, 7);
        return hexColor;
    }


    useEffect(() => {
        if (Object.keys(props.filter).length > 0) {
            setFilter({ ...filter, ...props.filter })
        }
        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...filter,
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter, props.dashboardConfig]);


    useEffect(() => {
        if (filter.tags) {
            filterRef.current = []
            if (filter.tags.length === 0) {
                keysRef.current = []
                keysColors.current = []
                keysLabel.current = []
            }
            keysRef.current = [...keysRef.current.filter(key => key !== 'total')];
            filter.tags.forEach((item) => {
                filterRef.current = [...filterRef.current, item.label];
            })
        }
    }, [filter]);

    useEffect(() => {
        let timeTasks = {};
        let keysColorsArr = []
        let keysRefArr = []
        let keysLabelArr = []

        const updateTaskInfo = (dateCard, item) => {
            const monthKey = dateCard.date.format('MMMM');
            const label = item.label
            timeTasks = {
                ...timeTasks,
                [monthKey]: {
                    ...timeTasks[monthKey] || {},
                    total: (timeTasks[monthKey]?.total || 0) + 1,
                    month: dateCard.date.format('MMMM'),
                    [label]: (timeTasks[monthKey]?.[label] || 0) + 1,
                }
            };

            if (!keysRef.current.includes(label)) {
                keysColorsArr = keysColorsArr.filter((color) => color !== "#5A7F71")
                keysColorsArr = [...keysColorsArr, item?.color || generateColor(label)]
                keysRefArr = [...keysRefArr, label];
                keysLabelArr = keysLabelArr.filter((item) => item.label !== "Concluidas")
                keysLabelArr = [...keysLabelArr,
                {
                    label: label,
                    color: item?.color || generateColor(label)
                }
                ]
            }
        };

        if (props.planCards) {
            for (let i = 0; i < 12; i++) {
                const month = moment().startOf('year').add(i, 'month').format('MMMM');
                timeTasks = {
                    ...timeTasks,
                    [month]: {}
                }
            }
            Object.keys(props.planCards).forEach(planCard => {
                let result = filter?.users?.length > 0 ? false : true
                if (filter.users && Object.keys(db.cards[planCard]?._users).length > 0) {
                    Object.values(db.cards[planCard]?._users).forEach((user) => {
                        filter.users.forEach((filterUser) => {
                            if (user._id === filterUser)
                                result = true
                        })
                    })
                }
                if (result) {
                    const dateCard = {
                        date: moment(db.cards[planCard].completed_at?.low * 1000),
                        tags: db.cards[planCard]._tags
                    };

                    let dateFilterStart = dashFilterChart.startDate ? moment.unix(dashFilterChart.startDate) : (filter.startDate ? filter.startDate : moment().startOf('year'))
                    let dateFilterEnd = dashFilterChart.endDate ? moment.unix(dashFilterChart.endDate) : (filter.endDate ? filter.endDate : moment().endOf('year'))
                    if (dateCard.date.isBetween(dateFilterStart, dateFilterEnd)) {
                        const monthKey = dateCard.date.format('MMMM');
                        timeTasks = {
                            ...timeTasks,
                            [monthKey]: {
                                ...timeTasks[monthKey] || {},
                                "total": timeTasks && timeTasks[monthKey] && timeTasks[monthKey]["total"] ? timeTasks[monthKey]["total"] + 1 : 1,
                                "month": translate(`$__${monthKey}`),
                            }
                        }
                        if (filter?.tags?.length > 0 || filter.tagsByText) {
                            dateCard.tags
                                .filter(item =>
                                    (
                                        filter.tags
                                        && filter.tags.length > 0
                                        && item.label
                                        && filterRef.current.includes(item.label)
                                    )
                                    ||
                                    (
                                        filter.tagsByText
                                        && item.label
                                        && item.label.toLowerCase().indexOf(filter.tagsByText.toLowerCase()) > -1
                                    )
                                )
                                .forEach(item => {
                                    updateTaskInfo(dateCard, item);
                                });
                            keysRef.current = keysRefArr
                            keysColors.current = keysColorsArr
                            keysLabel.current = keysLabelArr

                        } else {
                            keysLabel.current = [{ label: 'completed', color: '#5A7F71' }]
                            keysRef.current = ["total"];
                            keysColors.current = ["#5A7F71"]
                            if (filter.colorsArr) {
                                keysLabel.current = filter.colorsArr.filter((item) => item.label === 'completed');
                                keysColors.current = filter.colorsArr.map((item) => item.color);
                            }
                        }

                    }
                }
            })
            let finalArr = Object.values(timeTasks).filter((item) => {
                return Object.keys(item).length !== 0;
            });
            setData(finalArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.planCards, props.load, filter, dashFilterChart]);

    return (
        <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'visible' }}>
            <Header
                handleSetSelected={(value) => {
                    setFilter({ ...filter, ...value })
                    props.handleSetFilter({ ...filter, ...value, id: props.id })
                }}
                tittle={translate("$__" + props.tittle)}
                icon={props.icon}
                styles={props.styles}
                filterConfig={{ filterItems: ['users', 'tags', 'tagsByText'] }}
                datafilter={true}
                indicators={filter ? filter : null}
                handleRemoveObject={() => props.handleRemoveObject(props.id)}
                edit={props.edit}
                handleSetoption={(value) => {
                    setFilter({ ...value })
                    props.handleSetFilter({ ...filter, ...value, id: props.id })
                }}
            />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{}} >
            </div>
            <div style={{ position: 'relative', flex: '1 1 auto', fontSize: '16px' }}>
                <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter', backgroundColor: '#f5f5f5' }}>
                    {(!props.load) ?
                        <div className='component' style={{ width: '100%', height: '100%' }}>
                            <ResponsiveBar
                                data={data}
                                // layers={["grid", "axes", "bars", Line, "markers", "legends"]}
                                keys={keysRef.current} // data
                                colors={keysColors.current}
                                indexBy={"month"} //data
                                margin={{ top: 40, right: 20, bottom: 30, left: 40 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: false }}
                                enableLabel={false}
                                yScale={{
                                    max: 100,
                                }}
                                minValue={0}
                                theme={{
                                    fontSize: '14px',
                                    color: 'white',
                                    background: '#f5f5f5',
                                    axis: {
                                        "ticks": {
                                            "text": {
                                                fill: 'rgba(0, 0, 0, 0.50)',
                                                "fontSize": 14,
                                                //cor dos numeros
                                            },
                                        }
                                    },
                                }}

                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={!props.preview ? {
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: 32,
                                } : null}
                                axisLeft={!props.preview ? {
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legendPosition: 'middle',
                                    legendOffset: -40
                                } : null}
                                role="application"
                            /> </div> : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                        </div>}
                </div>
            </div>
            <div style={{ height: '15%', maxHeight: '60px', display: 'flex', backgroundColor: 'white' }}>
                <Legend
                    legend={[...keysLabel.current.map((a, i) => {
                        return {
                            key: i,
                            color: a.color,
                            label: a.label
                        }
                    })]} />
            </div>
        </div >
    )
}

export default TaskDoneBarMonth

import React from "react"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card"
import CustomButton from "components/Buttons/custom"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import Icon from "components/Icon"
import LinearProgress from "components/Progress/linear"
import TagsMenu from "componentsV3/Tags/menu"

import Bright from "assets/icons/BrigthAndConnected"

//FUNCTIONS
import { translate, customApp, getAppAccess, textColor } from "functions/"
import { loadCardsV2 } from "functions/loadData"

function TagsView(props) {
    const [lastChange, setLastChange] = React.useState(null)
    const [loader, setLoader] = React.useState(false)
    const [filteredItems, setfilteredItems] = React.useState([])
    const [hashs, setHashs] = React.useState([])
    const [adminMode, setAdminMode] = React.useState(false)
    const { session } = reduxStore.getState()
    const { db } = props.store
    const { dataType } = reduxStore.getState().db

    let AppAccess = getAppAccess()

    React.useEffect(() => {
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")
        if (JSON.stringify(Nhashs) !== JSON.stringify(hashs)) {
            setHashs(Nhashs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history])

    React.useEffect(() => {
        const timer = setTimeout(() => searchCards(), 400)
        if (hashs.length > 0 && hashs[0] !== lastChange) {
            setAdminMode(false)
            setLastChange(hashs[0])
        }
        if (adminMode)
            loadAdmin()
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db.cards, db.tag, adminMode, hashs])

    React.useEffect(() => {
        let Nhashs = window.location.hash.split("/")
        Nhashs[0] = Nhashs[0].replace("#", "")
        if (JSON.stringify(Nhashs) !== JSON.stringify(hashs)) {
            setHashs(Nhashs)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history])

    const loadAdmin = () => {
        loadCardsV2(props, {
            cardLoad: 'tags',
            idRel: hashs[0],
            adminView: true,
        })
    }

    const searchCards = async () => {
        let searchResult = new Promise((resolve, reject) => {
            let res = Object.keys(db.cards).filter(card =>
            (
                db.cards
                && db.cards[card]
                && !db.cards[card].deleted
                && (
                    (
                        !adminMode
                        && (
                            session
                            && session._id
                            && db.cards[card]
                            && db.cards[card]._users
                            && db.cards[card]._users[session._id]
                        )
                    )
                    || (
                        adminMode
                        && (
                            AppAccess.owner
                            || (
                                AppAccess
                                && AppAccess.plan
                                && AppAccess.planAdmin
                            )
                        )
                    )
                )
                &&
                (
                    hashs
                    && hashs.length > 0
                    && hashs[0]
                    && db.tag
                    && db.tag.filter(tag =>
                        tag.value === hashs[0]
                        || tag.parent === hashs[0]
                    ).length > 0
                    && db.cards
                    && db.cards[card]._tags
                    && (
                        db.cards[card]._tags.filter(tag =>
                            tag.value === hashs[0]
                            || db.tag.filter(dbt =>
                                dbt.parent === hashs[0]
                                && tag.value === dbt.value
                            ).length > 0
                        ).length > 0
                    )
                )
                &&
                (
                    !db.cards[card].private
                    ||
                    (
                        session
                        && session._id
                        && db.cards[card].private
                        && db.cards[card]._users
                        && db.cards[card]._users[session._id]
                    )
                )
            )
            )
            resolve(res)
        })

        let response = await searchResult.then(a => {
            return a
        })
        if (response) {
            setfilteredItems(response)
            setLoader(false)
            if (props.onFinish)
                props.onFinish()
        }
    }
    let filterItems = filteredItems.map(x => db.cards[x])
    if (props.searchs && props.searchs["tagsView"]) {
        const searchValues = props.searchs["tagsView"]
        if (searchValues.text && searchValues.text !== undefined && searchValues.text !== null && searchValues.text !== '')
            filterItems = Array.from(filterItems.filter(a =>
                a.name.toLowerCase().indexOf(searchValues.text.toLowerCase()) > -1
                || a.description?.toLowerCase().indexOf(searchValues.text.toLowerCase()) > -1
            ))
        if (searchValues.startDate && searchValues.startDate !== undefined && searchValues.startDate !== null && searchValues.startDate !== '')
            filterItems = Array.from(filterItems.filter(a =>
                parseInt(a.startDate) >= parseInt(searchValues.startDate)
            ))

        if (searchValues.endDate && searchValues.endDate !== undefined && searchValues.endDate !== null && searchValues.endDate !== '')
            filterItems = Array.from(filterItems.filter(a =>
                parseInt(a.endDate) <= parseInt(searchValues.endDate)
            ))
        if (searchValues.tags && searchValues.tags && searchValues.tags.filter(a => a.value).length > 0)
            // console.log(searchValues.tags)
            filterItems = Array.from(filterItems.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                    let result = false
                    searchValues.tags.forEach(tagFilter => {
                        if (tagFilter.value === tag.value)
                            result = true
                    })
                    return result
                }
                ).length > 0
            ))
        filterItems = filterItems.filter(fil => {
            if (!fil.status || (fil && fil.status && searchValues.status[fil.status]))
                return true
            return false
        }).map(res => res)

        filterItems = filterItems.filter(fil => {
            if ((fil && fil.priority && searchValues.priority[fil.priority]) || (searchValues.priority['null'] && !fil.priority))
                return true
            return false
        }).map(res => res)

        filterItems = filterItems.filter(fil => {
            if ((fil && fil.complexity && searchValues.complexity[fil.complexity]) || (searchValues.complexity['null'] && !fil.complexity))
                return true
            return false
        }).map(res => res)

        filterItems = filterItems.filter(fil => {
            if ((fil && fil.risk && searchValues.risk[fil.risk]) || (searchValues.risk['null'] && !fil.risk))
                return true
            return false
        }).map(res => res)

        filterItems = filterItems.filter(fil => {
            if ((fil && fil.impact && searchValues.impact[fil.impact]) || (searchValues.impact['null'] && !fil.impact))
                return true
            return false
        }).map(res => res)

        if (searchValues.users && searchValues.users.length > 0)
            filterItems = filterItems.filter(fil => searchValues.users.filter(uid => fil && fil._users && fil._users[uid]).length > 0).map(res => res)
    }
    const items = filterItems.map(x => x._id)

    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                height: "89vh",
                overflow: "hidden"
            }}
        >
            <GridContainer style={{
                overflow: "hidden"
            }}>
                <GridItem
                    md={3}
                >
                    <TagsMenu />
                </GridItem>
                <GridItem
                    md={9}
                >
                    {loader &&
                        <div style={{ position: "absolute", bottom: 0, left: "7px", right: "7px" }}>
                            <LinearProgress />
                        </div>
                    }
                    {db.tag.filter(tag => tag.value === hashs[0]).length > 0 ?
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <div style={{
                                position: 'relative',
                                padding: "5px 10px",
                                borderRadius: "8px",
                                width: "fit-content",
                                display: "flex",
                                alignItems: "center",
                                ...db.tag.filter(tag => tag.value === hashs[0]).length > 0 && db.tag.filter(tag => tag.value === hashs[0])[0].color ? {
                                    backgroundColor: db.tag.filter(tag => tag.value === hashs[0])[0].color,
                                    color: db.tag.filter(tag => tag.value === hashs[0])[0].color
                                        ? textColor(db.tag.filter(tag => tag.value === hashs[0])[0].color, true)
                                        : customApp("menu")
                                } : {}
                            }}>
                                <Icon
                                    size={"22px"}
                                    color={db.tag.filter(tag => tag.value === hashs[0])[0].color
                                        ? textColor(db.tag.filter(tag => tag.value === hashs[0])[0].color, true)
                                        : customApp("menu")}
                                    icon={'tag'}
                                />
                                <span style={{ marginLeft: "7px" }}>{db.tag.filter(tag => tag.value === hashs[0])[0].label}</span>
                            </div>
                            {AppAccess
                                && AppAccess.plan
                                && AppAccess.planAdmin
                                && AppAccess.user
                                && AppAccess.userAdmin ?
                                <div>
                                    <CustomButton
                                        title={translate("$__adminMode")}
                                        text={translate("$__adminMode")}
                                        color={adminMode ? customApp('menu') : "lightgrey"}
                                        textColor={adminMode ? customApp('menu') : "lightgrey"}
                                        icon={"manage_accounts"}
                                        onClick={() => {
                                            setAdminMode(prev => !prev)
                                        }}
                                        style={{ marginLeft: 15 }}
                                        size={"25px"}
                                    />
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <div style={{ positin: 'relative', width: '100%', height: "90vh", overflow: "auto" }}>
                        <GridContainer>
                            {items
                                .filter(card =>
                                    db.cards[card].type.indexOf('plan') > -1
                                    && (
                                        !props.hidePrivate
                                        || (
                                            props.hidePrivate
                                            && !db.cards[card].private
                                        )
                                    )
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={translate('$__plans', 1)}
                                        icon={dataType.filter(a => a.name === 'plan')[0].icon}
                                        color={customApp('menu')}
                                        filterText={props.text}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                db.cards[card].type === 'plan'
                                                && (
                                                    !props.hidePrivate
                                                    || (
                                                        props.hidePrivate
                                                        && !db.cards[card].private
                                                    )
                                                )
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name < db.cards[b].name)
                                                    return -1
                                                if (db.cards[a].name > db.cards[b].name)
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card
                                                            db="cards"
                                                            cardId={card}
                                                            showConnectedIn
                                                            showTags
                                                            searchText={props.text}
                                                            expanded
                                                            onAction={props.onAction ? () => { props.onAction() } : null}
                                                        />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }
                            {items
                                .filter(card =>
                                    db.cards[card].type.indexOf('guideline') > -1
                                    && (
                                        !props.hidePrivate
                                        || (
                                            props.hidePrivate
                                            && !db.cards[card].private
                                        )
                                    )
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={translate('$__guidelines', 1)}
                                        icon={dataType.filter(a => a.name === 'guideline')[0].icon}
                                        color={customApp('menu')}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                db.cards[card].type.indexOf('guideline') > -1
                                                && (
                                                    !props.hidePrivate
                                                    || (
                                                        props.hidePrivate
                                                        && !db.cards[card].private
                                                    )
                                                )
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return -1
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card db="cards" cardId={card} showConnectedIn searchText={props.text} expanded onAction={props.onAction ? () => { props.onAction() } : null} />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }

                            {items
                                .filter(card =>
                                    db.cards[card].type.indexOf('objective') > -1
                                    && (
                                        !props.hidePrivate
                                        || (
                                            props.hidePrivate
                                            && !db.cards[card].private
                                        )
                                    )
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={translate('$__objectives', 1)}
                                        icon={dataType.filter(a => a.name === 'objective')[0].icon}
                                        color={customApp('menu')}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                db.cards[card].type.indexOf('objective') > -1
                                                && (
                                                    !props.hidePrivate
                                                    || (
                                                        props.hidePrivate
                                                        && !db.cards[card].private
                                                    )
                                                )
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return -1
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card db="cards" cardId={card} showConnectedIn searchText={props.text} expanded onAction={props.onAction ? () => { props.onAction() } : null} />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }

                            {items
                                .filter(card =>
                                    db.cards[card].type.indexOf('goal') > -1
                                    && (
                                        !props.hidePrivate
                                        || (
                                            props.hidePrivate
                                            && !db.cards[card].private
                                        )
                                    )
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={translate('$__goals', 1)}
                                        icon={dataType.filter(a => a.name === 'goal')[0].icon}
                                        color={customApp('menu')}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                db.cards[card].type.indexOf('goal') > -1
                                                && (
                                                    !props.hidePrivate
                                                    || (
                                                        props.hidePrivate
                                                        && !db.cards[card].private
                                                    )
                                                )
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return -1
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card db="cards" cardId={card} showConnectedIn searchText={props.text} expanded onAction={props.onAction ? () => { props.onAction() } : null} />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }

                            {items
                                .filter(card =>
                                    dataType.filter(dt => dt.name === db.cards[card].type).length > 0
                                    && dataType.filter(dt => dt.name === db.cards[card].type)[0]
                                    && db.cards[card].type !== 'objective'
                                    && db.cards[card].type !== 'goal'
                                    && db.cards[card].type !== 'plan'
                                    && db.cards[card].type !== 'chatGroup'
                                    && db.cards[card].type !== 'link'
                                    && db.cards[card].type !== 'insight'
                                    && db.cards[card].type.indexOf('guideline') === -1
                                    && (
                                        !props.hidePrivate
                                        || (
                                            props.hidePrivate
                                            && !db.cards[card].private
                                        )
                                    )
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={translate('$__actions', 1)}
                                        icon={'double_arrow'}
                                        color={customApp('menu')}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                dataType.filter(dt => dt.name === db.cards[card].type).length > 0
                                                && dataType.filter(dt => dt.name === db.cards[card].type)[0]
                                                && db.cards[card].type !== 'objective'
                                                && db.cards[card].type !== 'goal'
                                                && db.cards[card].type !== 'plan'
                                                && db.cards[card].type !== 'chatGroup'
                                                && db.cards[card].type !== 'link'
                                                && db.cards[card].type !== 'insight'
                                                && db.cards[card].type.indexOf('guideline') === -1
                                                && (
                                                    !props.hidePrivate
                                                    || (
                                                        props.hidePrivate
                                                        && !db.cards[card].private
                                                    )
                                                )
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return -1
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card
                                                            db="cards"
                                                            cardId={card}
                                                            showConnectedIn
                                                            searchText={props.text}
                                                            expanded
                                                            onAction={props.onAction ? () => { props.onAction() } : null}
                                                        />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }

                            {items
                                .filter(card =>
                                    db.cards[card].type === 'insight'
                                    || db.cards[card].type === 'link'
                                ).length > 0 &&
                                <GridItem
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <HeaderWithIcon
                                        title={'BRIGHT & CONNECTED'}
                                        icon={Bright}
                                        color={customApp('menu')}
                                        style={{
                                            padding: '7px'
                                        }}
                                    />
                                    <GridContainer>
                                        {items
                                            .filter(card =>
                                                db.cards[card].type === 'insight'
                                                || db.cards[card].type === 'link'
                                            )
                                            .sort((a, b) => {
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return -1
                                                if (db.cards[a].name.toLowerCase() < db.cards[b].name.toLowerCase())
                                                    return 1
                                                return 0
                                            })
                                            .map((card, i) => {
                                                return (
                                                    <GridItem
                                                        key={card}
                                                        xs={12}
                                                        sm={6}
                                                        md={4}
                                                        lg={4}
                                                        xl={3}
                                                    >
                                                        <Card
                                                            db="cards"
                                                            cardId={card}
                                                            showConnectedIn
                                                            expanded
                                                            Avatar
                                                            Resume
                                                            onAction={props.onAction ? () => { props.onAction() } : null}
                                                        />
                                                    </GridItem>
                                                )
                                            })}
                                    </GridContainer>
                                </GridItem>
                            }
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
            <div></div>


        </div >
    )
}

const mapStateToProps = (store) => {
    return {
        store: {
            db: {
                cards: store.db.cards,
                tag: store.db.tag
            }
        },
        history: store.db.history,
        hidePrivate: store.controls.hidePrivate,
        searchs: store.searchs
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(TagsView)
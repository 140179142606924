//VIEWS
import ActivateUserPage from "views/Pages/ActivateUser"
import BackOffice from "views/BackOffice"
import BrightAndConnected from "views/BrightAndConnected"
import Calendar from "views/Calendar/Calendar"
import Dashboard from "views/Dashboard"
import ErrorPage from "views/Pages/ErrorPage"
import Library from "views/Library"
import LoginPage from "views/Pages/LoginPage"
import MaintenancePage from "views/Pages/MaintenancePage"
import OfflinePage from "views/Pages/OffLinePage"
import MyTasks from "views/MyTasks"
import MasterAdmin from "views/MasterAdmin"
import Notifications from "views/Notifications"
import RegisterPage from "views/Pages/RegisterPage"
import StrategyAndPlans from "views/StrategyAndPlans"
import Tags from "views/Tags"
import Users from "views/Users"
import Forms from "views/Forms"
import Account from "views/Account/"
import SubscriptionUpgrade from "views/Pages/subscriptionPlan"
import DeniedAccess from "views/Pages/deniedAccess"
import Profile from "views/ProfileUser/Profile"
import ProfileEnterprise from "views/ProfileEnterprise/ProfileEnterprise"

//ICONS
import Matrix from "assets/icons/Matrix"
import Bright from "assets/icons/BrigthAndConnected"
import Ideas from "assets/icons/Ideas"


var dashRoutes = [
  {
    path: "/d",
    name: "DASHBOARD",
    alias: 'Dashboard',
    icon: 'dashboard',
    component: Dashboard,
    show: true,
    auth: true,
    search: false,
    tabs: [
      {
        path: "resume",
        name: "RESUME",
        alias: "home",
        icon: 'space_dashboard',
        show: true,
        auth: true,
      },
      {
        path: "dayTasks",
        name: "DAYTASKS",
        alias: "dayTasks",
        icon: 'hail',
        show: true,
        auth: true,
        AppAccess: ['planAdmin', 'planModerate'],
      },
    ]
  },
  {
    path: "/bright-and-connected",
    name: "BRIGHTANDCONNECTED",
    alias: "brightandconnected",
    icon: Bright,
    component: BrightAndConnected,
    show: true,
    auth: true,
    subMenu: true,
    searchFields: ['tags', 'text'],
    tabs: [
      {
        path: "home",
        name: "home",
        alias: "home",
        icon: "home",
        show: true,
        auth: true,
      },
      {
        path: "feeds",
        name: "FEEDS",
        alias: "feeds",
        icon: "menu_book",
        show: true,
        auth: true,
        search: true,
        searchFields: ['tags', 'text'],
      },

      {
        path: "radar",
        name: "radar",
        alias: "radar",
        icon: "settings_input_antenna",
        show: true,
        auth: true,
        search: true,
        searchFields: ['tags', 'text', 'users', 'priority'],
      },
      {
        path: "insight-boards",
        name: "INSIGHTS",
        alias: "insights",
        icon: Ideas,
        show: true,
        auth: true,
        search: true,
        searchFields: ['tags', 'text', 'users', 'priority'],
      },
      {
        path: "allNews",
        name: "allNews",
        alias: "allNews",
        icon: "feed",
        show: true,
        auth: true,
        search: true,
        searchFields: ['tags', 'text'],
      }, {
        path: "hideFeeds",
        name: "HIDEFEEDS",
        alias: "hideFeeds",
        icon: "visibility_off",
        show: true,
        auth: true,
        search: true,
        searchFields: ['tags', 'text'],
      },
    ]
  },
  {
    path: "/c",
    name: "Users",
    alias: 'users',
    icon: 'business',
    component: Users,
    show: true,
    auth: true,
    search: true,
    subMenu: true,
    searchFields: ['text', 'tags', 'users', 'userGroup'],
    tabs: [
      {
        path: "users",
        name: "USERS",
        alias: "users",
        icon: "supervised_user_circle",
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'userGroup'],
      },
      {
        path: "units",
        name: "units",
        alias: "customersCompaniesAndDivisions",
        icon: "business",
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'userGroup'],
      },
      {
        path: "departments",
        name: "groupsAndDepartments",
        alias: "groupsAndDepartments",
        icon: "group_work",
        show: true,
        auth: true,
        permission: 1,
      }
    ]
  },
  {
    path: "/forms",
    name: "Forms",
    alias: 'forms',
    icon: 'ballot',
    component: Forms,
    show: true,
    auth: true,
    search: true,
    subMenu: true,
    tabs: [
      {
        path: "resume",
        name: "resume",
        alias: "resume",
        icon: "ballot",
        show: true,
        auth: true,
      }
    ]
  },
  {
    path: "/sp",
    name: "Strategy & Plans",
    alias: "plan",
    icon: 'view_comfy',
    component: StrategyAndPlans,
    show: true,
    auth: true,
    search: true,
    subMenu: true,
    searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
    tabs: [
      {
        path: "home",
        name: "home",
        alias: "home",
        icon: "home",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "StrategyAndPlans",
        name: "StrategyAndPlans",
        alias: "StrategyAndPlans",
        icon: "view_comfy",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "archived",
        name: "archivedPlans",
        alias: "archivedPlans",
        icon: "archive",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "admin",
        name: "admin",
        alias: "admin",
        icon: "admin_panel_settings",
        show: true,
        auth: true,
        AppAccess: ['planAdmin'],
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "templates",
        name: "templates",
        alias: "templates",
        icon: "smart_button",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "completed",
        name: "completed",
        alias: "completed",
        icon: "assignment_turned_in",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "trash",
        name: "trash",
        alias: "trash",
        icon: "recycling",
        show: true,
        auth: true,
        search: true,
        searchFields: ['text', 'tags', 'users', 'priority', 'units', 'status'],
        hashId: false,
      },
      {
        path: "dashboard",
        name: "dashboard",
        alias: "home",
        icon: "dashboard",
        show: true,
        auth: true,
        permission: 4,
        search: false,
        searchFields: [],
        hashId: true,
        templates: true,
      },
      {
        path: "overview",
        name: "OVERVIEW",
        alias: "overview",
        icon: "select_all",
        show: true,
        auth: true,
        permission: 4,
        search: false,
        hashId: true,
        templates: true,
      },
      {
        path: "board",
        name: "board",
        alias: "board",
        icon: "view_week",
        show: true,
        auth: true,
        permission: 3,
        search: false,
        hashId: true,
        templates: true,
      },
      {
        path: "matrix",
        name: "MATRIX SWOT",
        alias: "matrix",
        icon: Matrix,
        show: true,
        auth: true,
        permission: 3,
        hashId: true,
        templates: true,
      },
      {
        path: "guidelines",
        name: "guidelines",
        alias: "guidelines",
        icon: 'alt_route',
        show: true,
        auth: true,
        permission: 1,
        search: false,
        hashId: true,
      },
      {
        path: "objectives",
        name: "objectives",
        alias: "objectives",
        icon: 'gps_fixed',
        show: true,
        auth: true,
        permission: 1,
        search: false,
        hashId: true,
      },
      {
        path: "goals",
        name: "goals",
        alias: "goals",
        icon: 'flag',
        show: true,
        auth: true,
        permission: 1,
        search: false,
        hashId: true,
      },
      {
        path: "actions",
        name: "ACTIONS",
        alias: "actions",
        icon: "playlist_play",
        show: true,
        auth: true,
        permission: 1,
        search: false,
        hashId: true,
      },
      {
        path: "bright-and-connected",
        name: "feeds",
        alias: "feeds",
        icon: 'menu_book',
        show: true,
        auth: true,
        permission: 3,
        hashId: true,
        templates: true,
      },
      {
        path: "radar",
        name: "radar",
        alias: "radar",
        icon: "settings_input_antenna",
        show: true,
        auth: true,
        permission: 3,
        hashId: true,
        templates: true,
      },
      {
        path: "insight-boards",
        name: "INSIGHTS",
        alias: "insights",
        icon: Ideas,
        show: true,
        auth: true,
        permission: 3,
        // search: true,
        // searchFields: ['tags', 'text'],
        hashId: true,
        templates: true,
      },
      {
        path: "library",
        name: "library",
        alias: "library",
        icon: "folder",
        show: true,
        auth: true,
        permission: 3,
        // search: true,
        // searchFields: ['text'],
        hashId: true,
      },
    ]
  },
  {
    path: "/t",
    name: "Tasks",
    alias: "tasks",
    component: MyTasks,
    icon: 'recent_actors',
    show: true,
    auth: true,
    permission: 1,
    hashId: false,
    search: true,
    subMenu: true,
    searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
    tabs: [
      {
        path: "myDay",
        name: "myDay",
        alias: "myDay",
        icon: 'today',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "ourDay",
        name: "ourDay",
        alias: "ourDay",
        icon: 'reduce_capacity',
        show: true,
        auth: true,
        permission: 3,
        AppAccess: ['planAdmin', 'planModerate'],
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "allTasks",
        name: "allTasks",
        alias: "allTasks",
        icon: 'how_to_reg',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "myTasks",
        name: "myTasks",
        alias: "myTasks",
        icon: 'admin_panel_settings',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "unreaded",
        name: "unreaded",
        alias: "unreaded",
        icon: 'notifications_active',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "private",
        name: "private",
        alias: "private",
        icon: 'private_connectivity',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "unlinkedCards",
        name: "unlinkedCards",
        alias: "unlinkedCards",
        icon: 'link_off',
        show: true,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "lateTasks",
        name: "lateTasks",
        alias: "lateTasks",
        icon: 'playlist_play',
        show: false,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
      {
        path: "nextTasks",
        name: "nextTasks",
        alias: "nextTasks",
        icon: 'playlist_play',
        show: false,
        auth: true,
        permission: 1,
        search: true,
        searchFields: ['text', 'tags', 'users', 'plans', 'priority', 'units', 'groups', 'status', 'date'],
      },
    ]
  },
  {
    path: "/tags",
    name: "Tags",
    alias: "Tags",
    component: Tags,
    icon: 'turned_in',
    show: true,
    auth: true,
    // search: true,
    // subMenu: true,
    permission: 1,
  },
  {
    path: "/plataformAdministrator",
    name: "BackOffice",
    alias: 'BackOffice',
    icon: 'business',
    component: BackOffice,
    access: ['_isDeveloper'],
    show: true,
    auth: true,
    search: true,
    tabs: [
      {
        path: "versions",
        name: "versions",
        alias: "versions",
        icon: "app_registration",
        show: true
      },
      {
        path: "accounts",
        name: "accounts",
        alias: "accounts",
        icon: "account_box",
        show: true
      },
      {
        path: "languages",
        name: "languages",
        alias: "languages",
        icon: 'translate',
        show: true
      },
      {
        path: "feeds",
        name: "feeds",
        alias: "feeds",
        icon: "rss_feed",
        show: true
      }
    ]
  },
  {
    path: "/masterAdmin",
    name: "masterAdmin",
    alias: 'masterAdmin',
    icon: 'admin_panel_settings',
    component: MasterAdmin,
    show: false,
    access: ['_isAdmin'],
    auth: true,
    search: false,
    tabs: [
      {
        path: "accounts",
        name: "accounts",
        alias: "accounts",
        icon: "account_box",
        show: true
      },
      {
        path: "languages",
        name: "languages",
        alias: "languages",
        icon: 'translate',
        show: true
      },
      {
        path: "feeds",
        name: "feeds",
        alias: "feeds",
        icon: "rss_feed",
        show: true
      }
    ]
  },
  {
    path: "/notifications",
    name: "Notifications",
    alias: 'Notifications',
    icon: 'notifications',
    component: Notifications,
    show: false,
    auth: true,
  },
  {
    collapse: true,
    name: "Pages",
    icon: 'image',
    state: "pageCollapse",
    show: false,
    views: [
      {
        path: "/activate",
        name: "Activate User",
        mini: "AU",
        component: ActivateUserPage,
        show: true,
        auth: false
      },
      {
        path: "/login",
        name: "Login Page",
        mini: "L",
        component: LoginPage,
        show: true,
        auth: false
      },
      {
        path: "/register",
        name: "Register Page",
        mini: "R",
        component: RegisterPage,
        show: true,
        auth: false
      },
      {
        path: "/error-page",
        name: "Error Page",
        mini: "E",
        component: ErrorPage,
        show: true,
        auth: true
      },
      {
        path: "/maintenance-mode",
        name: "Maintenance Mode",
        mini: "E",
        component: MaintenancePage,
        show: false,
        auth: false
      },
      {
        path: "/offline-detect",
        name: "Offline detect",
        mini: "O",
        component: OfflinePage,
        show: false,
        auth: false
      }
    ]
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: 'date_range',
    component: Calendar,
    show: true,
    auth: true,
    search: true,
    subMenu: true,
    permission: 1,
    searchFields: ['text', 'tags', 'users', 'priority', 'risk', 'complexity', 'impact', 'groups', 'status'],
  },
  {
    path: "/profile",
    name: "Profile",
    icon: 'date_range',
    component: Profile,
    show: true,
    auth: true,
    // search: true,
    // subMenu: true,
    permission: 0,
    // searchFields: ['text', 'tags', 'users', 'priority', 'risk', 'complexity', 'impact', 'groups', 'status'],
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    icon: 'date_range',
    component: ProfileEnterprise,
    show: true,
    auth: true,
    search: true,
    subMenu: true,
    permission: 1,
    searchFields: ['text', 'tags', 'users', 'priority', 'risk', 'complexity', 'impact', 'groups', 'status'],
  },
  {
    path: "/acc",
    name: "Account",
    icon: 'business',
    alias: "account",
    component: Account,
    show: true,
    auth: true,
    subMenu: true,
    permission: 1,
    AppAccess: ['planAdmin'],
    tabs: [
      {
        path: "resume",
        name: "resume",
        alias: "resume",
        icon: 'home',
        show: true,
        auth: true,
        AppAccess: ['planAdmin'],
      },
      {
        path: "profile",
        name: "profile",
        alias: "profile",
        icon: 'badge',
        show: true,
        auth: true,
        AppAccess: ['masterAdmin'],
      },
      {
        path: "whiteLabel",
        name: "whiteLabel",
        alias: "whiteLabel",
        icon: 'palette',
        show: true,
        auth: true,
        AppAccess: ['masterAdmin'],
        accountHas: ['whiteLabel']
      },
      {
        path: "integrations",
        name: "integrations",
        alias: "plataformsIntegrations",
        icon: 'integration_instructions',
        show: true,
        auth: true,
        AppAccess: ['masterAdmin'],
      },
      // {
      //   path: "invoicing",
      //   name: "invoicing",
      //   alias: "invoicing",
      //   icon: 'payment',
      //   show: true,
      //   auth: true,
      //   AppAccess: ['masterAdmin'],
      // },
    ]
  },
  {
    path: "/library",
    name: "library",
    alias: "library",
    component: Library,
    icon: 'folder',
    show: true,
    auth: true,
    permission: 1,
    hashId: false,
    // search: true,
    // subMenu: true,
    // searchFields: ['text'],
  },
  {
    path: "/subscriptionUpgrade",
    name: "subscriptionUpgrade",
    alias: "subscriptionUpgrade",
    component: SubscriptionUpgrade,
    icon: 'upgrade',
    show: true,
    auth: true,
    permission: 1,
    hashId: false,
  },
  {
    path: "/deniedAccess",
    name: "deniedAccess",
    alias: "deniedAccess",
    component: DeniedAccess,
    icon: 'upgrade',
    show: true,
    auth: true,
    permission: 1,
    hashId: false,
  }
]

export default dashRoutes
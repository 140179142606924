import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
  container: {
    ...defaultFont,
    position: 'relative',
    border: `0.5px solid ${customApp('light')}`,
    borderRadius: '2px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    padding: '3px',
    minWidth: '150px',
    display: 'flow-root',
  },
  cardContainer: {
    ...defaultFont,
    position: 'relative',
    minWidth: '150px',
    display: 'flow-root',
  },
  parent: {
    width: '100%',
    padding: '0 5px',
    '& .title': {
      color: customApp('medium'),
      fontSize: '10px',
      textTransform: 'uppercase'
    },
    '& .info': {
      marginTop: '-8px',
      color: customApp('color'),
      fontWeight: '500',
      //display: 'flex',
      alignItems: 'center',
    }
  },
  infos: {
    position: 'absolute',
    float: 'left',
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  description: {
    position: 'relative',
    float: 'left',
    padding: '5px',
    width: '100%',
    overflow: 'hidden'
  },
  dates: {
    position: 'relative',
    float: 'left',
    width: '100%',
    marginTop: '7px',
    padding: '0 5px',
    marginRight: '15px',
    color: customApp('medium'),
    fontSize: '10px',
    display: 'flex',
  },
  dateInfo: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center'
  },
  usersContent: {
    position: 'relative',
    minHeight: '20px',
    minWidth: '40px',
    float: 'right'
  },
  users: {
    position: 'absolute',
    bottom: '7px',
    right: '2px',
    fontSize: '10px',
    '& .MuiAvatarGroup-root': {
      justifyContent: 'flex-end',
    },
    '& .MuiAvatar-root': {
      width: '25px',
      height: '25px',
      fontSize: '10px',
    }
  }
};

export default style;

//CORE
import React from "react"
import ReactAudioPlayer from "react-audio-player"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import Card from "components/Card";
import Icon from "components/Icon";
import Message from "componentsV3/Timeline/message";
import HeaderWithIcon from "components/Header/card";

//@MATERIAL
import Avatar from "components/Avatar";

//FUNCTIONS
import { translate, customApp, getDate } from "functions";

function Component(props) {
    const { timeline, functions, session } = reduxStore.getState()
    const { socket } = functions
    const { db } = reduxStore.getState()
    const [file, setFile] = React.useState(props.data)
    const [showPrivate, setShowPrivate] = React.useState(false)

    React.useEffect(() => {
        if (props.unlockedPrivates === 0)
            setShowPrivate(false)

    }, [props.unlockedPrivates])
    const fileName = file.name.split(".")
    let ext = fileName[fileName.length - 1]

    if (file.name === "blob") {
        file.name = `${translate("$__coverImage", "*")}.jpg`
        ext = "jpg"
    }

    let img = `attachment`
    if (reduxStore.getState().db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0)
        img = reduxStore.getState().db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].icon
            || `https://machen.azureedge.net/${file.file}`


    const update = async (dataUpdate) => {
        try {
            socket.emit("data", {
                module: "node",
                method: "put",
                action: "update"
            }, {
                _id: file._id,
                ...dataUpdate
            }, async () => {
                setFile({ ...file, ...dataUpdate })
            })
        } catch (e) {
            console.log(e)
        }
    }

    let viewer = "img"
    if (db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0 && db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer)
        viewer = db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].viewer
    return (
        <div>
            <div
                style={{
                    position: "relative",
                    float: "left",
                    width: "100%",
                    marginBottom: "5px",
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                    }}

                >
                    {/* {file.parents && file.parents.length > 0 ?
                        <div style={{ position: "relative", width: "100%", display: "flex", fontSize: 10, color: "gray" }}>
                            {file.parents.reverse().map(p =>
                                <div key={p._id}>
                                    {p.name} /
                                </div>
                            )}
                        </div>
                        : <React.Fragment></React.Fragment>} */}
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        {file.in && file.in.private && !props.showPrivate && !showPrivate
                            ?
                            <div
                                style={{
                                    position: "relative",
                                    float: "left",
                                    width: "33px",
                                    height: "33px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "lightgray",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    borderRadius: 7,
                                    display: "flex"
                                }}
                            >
                                <Icon icon={'lock'} size="17px" />
                            </div>
                            :
                            <React.Fragment>
                                {typeof img === "string" && img.indexOf("https") === -1 && img.indexOf("svg") === -1 ?
                                    <div
                                        style={{
                                            position: "relative",
                                            float: "left",
                                            width: "33px",
                                            height: "33px",
                                            alignItems: "center",
                                            display: "flex",
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => {

                                            if (props.openLightBox)
                                                props.openLightBox()
                                        }}
                                    >
                                        <Icon icon={img} size="33px" />
                                    </div>
                                    :
                                    <div
                                        style={{
                                            position: "relative",
                                            float: "left",
                                            width: "33px",
                                            height: "33px",
                                            alignItems: "center",
                                            backgroundImage: `url("${img}")`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            borderRadius: 7,
                                            cursor: "pointer"
                                        }}
                                        onClick={(e) => {
                                            if (props.openLightBox)
                                                props.openLightBox()
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                    ></div>
                                }
                            </React.Fragment>
                        }
                        <div
                            style={{
                                display: "flex",
                                paddingLeft: 15,
                                alignItems: "center",
                                maxWidth: "calc(100% - 40px)",
                                width: "100%"
                            }}
                        // onClick={(e) => {
                        //     let isPrivate = file.in && file.in.private && !props.showPrivate && !showPrivate ? true : false
                        //     if (props.openLightBox && !isPrivate)
                        //         props.openLightBox()
                        //     e.stopPropagation()
                        //     e.preventDefault()
                        // }}
                        >
                            {file.in && file.in.name &&
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {file.in.db === "User" && file.in._id === session._id ?
                                        <div>
                                            <Avatar
                                                alt={file.createdBy.name}
                                                src={file.createdBy.image}
                                                style={{ width: "21px", height: "21px" }}
                                            >
                                                {file.createdBy.name}
                                            </Avatar>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }

                                    {file.in.db === "User" && file.in._id !== session._id ?
                                        <div>
                                            <Avatar
                                                alt={file.in.name}
                                                src={file.in.image}
                                                style={{ width: "21px", height: "21px" }}
                                            >
                                                {file.in.name}
                                            </Avatar>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                    <HeaderWithIcon
                                        private={file.in && file.in.private && !reduxStore.getState().controls.lockPrivate ? true : false}
                                        unlockedPrivates={props.unlockedPrivates}
                                        setPrivate={(a) => {
                                            let value = a ? 1 : -1
                                            if (!a && props.unlockedPrivates === 0)
                                                value = 0
                                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                                ...reduxStore.getState().controls,
                                                unlockedPrivates: reduxStore.getState().controls.unlockedPrivates + value,
                                            })
                                        }}
                                        // moreButton={true}
                                        title={`${file.in.db === "User"
                                            ? `${translate('$__chatWithUser', '*')} - ${file.in._id !== session._id ? `${file.in.name}` : `${file.createdBy.name}`}`
                                            : file.in.type === "chatGroup"
                                                ? `${translate('$__chatGroup', '*')} - ${file.in.name}`
                                                : file.in.db !== "User" && file.in.type !== "chatGroup" ? `${file.in.name}` : ``}`
                                        }
                                        icon={
                                            file.in.db === "User" || file.in.type === "chatGroup" ? null
                                                :
                                                (
                                                    file.in.type
                                                    && db
                                                    && db.dataType
                                                    && db.dataType.filter(a =>
                                                        a.name === file.in.type
                                                    )[0]
                                                    && db.dataType.filter(a =>
                                                        a.name === file.in.type
                                                    )[0].icon
                                                )
                                                || "$__topic"
                                        }
                                        color={customApp("medium")}
                                        style={{
                                            padding: "7px",
                                            minHeight: "21px !important",
                                            textAlign: "left"
                                        }}
                                        textStyle
                                    />
                                    <span style={{ marginRight: 7 }}>/</span>
                                </div>
                            }
                            <HeaderWithIcon
                                private={file.in && file.in.private && !reduxStore.getState().controls.lockPrivate ? true : false}
                                unlockedPrivates={props.unlockedPrivates}
                                setPrivate={(a) => {
                                    console.log(a, a)
                                    let value = a ? 1 : -1
                                    if (!a && props.unlockedPrivates === 0)
                                        value = 0
                                    setShowPrivate(a ? true : false)
                                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                                        ...reduxStore.getState().controls,
                                        unlockedPrivates: reduxStore.getState().controls.unlockedPrivates + value,
                                    })
                                }}
                                moreButton={true}


                                // private={file.in && file.in.private ? true : false}
                                privateTitle={translate("$__privateFile")}
                                title={file.name}
                                value={file.name.replace(`.${ext}`, "")}
                                searchText={props.searchText ? props.searchText : null}
                                color={props.dark ? customApp("lightgray") : customApp("gray")}
                                editable={props.admin ? true : false}
                                // _id={file._id}
                                dark={props.dark ? true : false}
                                inputStyle={props.dark ? {
                                    backgroundColor: "rgba(250,250,250,0.8)",
                                    borderRadius: 7,
                                } : null}
                                textStyle={{
                                    fontSize: 12,
                                    fontWeight: "normal",
                                }}
                                searchTextStyle={{
                                    fontSize: 12,
                                    ...props.dark ? { color: "#FFFFFF" } : {}
                                }}
                                style={{
                                    minHeight: "21px !important",
                                }}
                                onEdit={
                                    props.admin ? (res) => {
                                        update({
                                            name: `${res}.${ext}`
                                        })
                                    } : null}
                            />
                        </div>
                    </div>
                    {viewer === "audio"
                        &&
                        <div style={{
                            position: "relative",
                            width: "100%",
                            float: "left"
                        }}>
                            <ReactAudioPlayer
                                src={`https://machen.azureedge.net/${file.file}`}
                                controls
                            />
                        </div>
                    }
                    {file.createdBy &&
                        <div style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            color: props.dark ? "lightgray" : "gray",
                            fontSize: 10,
                            marginLeft: "57px",
                            clear: "both"
                        }}>
                            {((file.in && !file.in.private)) ?
                                <React.Fragment>
                                    <span style={{ color: "lightgray", fontSize: 10, marginRight: 7 }}>{translate("$__sendedBy")}:</span>
                                    <Avatar
                                        alt={file.createdBy.name}
                                        src={file.createdBy.image}
                                        style={{ width: "15px", height: "15px" }}
                                    >
                                        {file.createdBy.name}
                                    </Avatar>
                                    <span style={{ marginLeft: 3 }}>
                                        {file.createdBy.name}
                                    </span>
                                </React.Fragment>
                                : <React.Fragment></React.Fragment>
                            }
                            <span style={{ marginLeft: 3 }}>
                                {getDate(file.created_at)}
                            </span>
                        </div>

                    }
                </div>
                {props.showParent &&
                    (
                        file.timelineId
                        || file.message
                    )
                    &&
                    (
                        (
                            db.cards[file.cardId]
                            && timeline[file.cardId]
                            && timeline[file.cardId][file.timelineId]
                        )
                        ||
                        (
                            file.message
                        )
                    )
                    &&
                    <div style={{ width: "100%", marginBottom: "33px" }} >
                        <Message
                            openLightBox={(e) => {
                                if (props.openLightBox)
                                    props.openLightBox(e)
                            }}
                            storie={db.cards[file.cardId] && timeline[file.cardId] && timeline[file.cardId][file.timelineId] ? timeline[file.cardId][file.timelineId] : file.message}
                            db={"cards"} nodeId={file.cardId}
                        />
                        <div style={{ clear: "both" }}></div>
                    </div>
                }
                {props.showParent && file.cardId && db.cards[file.cardId] &&
                    <div style={{ width: "100%", top: "33px" }}>
                        <Card db="cards" cardId={file.cardId} showConnectedIn />
                    </div>
                }
                <div style={{ width: "100%", height: "1px", backgroundColor: "rgba(0,0,0,0.1)", marginTop: "15px" }}></div>
            </div >
            {/* {
                (file.timelineId || file.message)
                && viewMessage
                &&
                (
                    (
                        db.cards[file.cardId]
                        && timeline[file.cardId]
                        && timeline[file.cardId][file.timelineId]
                    )
                    ||
                    (
                        file.message
                    )
                )
                &&
                <div style={{ width: "100%" }} >
                    <Message
                        openLightBox={(e) => {
                            if (props.openLightBox)
                                props.openLightBox(e)
                        }}
                        storie={db.cards[file.cardId] && timeline[file.cardId] && timeline[file.cardId][file.timelineId] ? timeline[file.cardId][file.timelineId] : file.message}
                        db={"cards"} nodeId={file.cardId}
                    />
                </div>
            } */}
        </div >
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history,
    showPrivate: store.controls.lockPrivate,
    unlockedPrivates: store.controls.unlockedPrivates,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(Component)

//CORE
import React from 'react';
import moment from "moment-timezone";

//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Avatar from "components/Avatar";
import Card from "components/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Icon from "components/Icon";
import Message from "componentsV3/Timeline/message";
import MiniTimeline from "componentsV3/Timeline/Mini";

//@MATERIAL
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { translate, customApp } from 'functions/';
import { loadTimeline } from "functions/chat";

const useStyles = makeStyles(() => ({
    unReaded: {
        background: 'linear-gradient(33deg, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0))',
        padding: '7px',
        borderBottom: 'solid 1px rgba(0,0,0,0.1)',
        width: '100%',
        maxWidth: '100%',
        opacity: 1
    },
    Readed: {
        padding: '7px',
        borderBottom: 'solid 1px rgba(0,0,0,0.1)',
        width: '100%',
        opacity: 0.6,
        maxWidth: '100%',
        "&:hover": {
            opacity: 1,
        }
    }
}));

function Component(props) {
    const { db, chat, timeline } = reduxStore.getState()
    const { data } = props
    const classes = useStyles();

    let dbNotify = 'cards'
    if (data.nodeId && reduxStore.getState().db.users[data.nodeId]) {
        dbNotify = 'users'
    }

    const openChat = async (nodeId, dbProps) => {
        if (!reduxStore.getState().db[dbProps][nodeId]._loadTimeline) {
            loadTimeline(
                {
                    ...props,
                    fnIdentification: "Notifications/Index/openChat()"
                },
                {
                    id: [nodeId],
                    db: dbProps
                })
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: true,
                db: dbProps,
                nodeId: nodeId,
                loading: true,
            })
        } else {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...chat,
                open: true,
                db: dbProps,
                nodeId: nodeId,
                loading: false,
            })
        }
        props.openChat(nodeId, dbProps)

    }

    if (data.module === 'timeline'
        && (!data.inNode
            || !data.inNode.type
            || !reduxStore.getState().db
            || !reduxStore.getState().db[dbNotify]))
        return (<React.Fragment></React.Fragment>)

    if (
        (
            !data.byUser
            ||
            (
                data.byUser &&
                !db.users[data.byUser]
            )
        )
        || (
            data.inNode
            && data.inNode.type
            && data.inNode._id
            && !reduxStore.getState().db[dbNotify][data.inNode._id]
        )
    ) {
        return (<React.Fragment></React.Fragment>)
    }

    const Header = () => {
        let time = ``
        if (moment().tz('America/Sao_Paulo').format('DD/MM/YYYY') === moment(data.created_at * 1000).tz('America/Sao_Paulo').format('DD/MM/YYYY')) {
            time = `${translate('$__today', 1)} ${moment(data.created_at * 1000).tz('America/Sao_Paulo').format('HH:MM')}`
        } else if (moment().tz('America/Sao_Paulo').format('YYYY') === moment(data.created_at * 1000).tz('America/Sao_Paulo').format('YYYY')) {
            time = `${moment(data.created_at * 1000).tz('America/Sao_Paulo').format('DD/MM HH:MM')}`
        } else {
            time = `${moment(data.created_at * 1000).tz('America/Sao_Paulo').format('YYYY/DD/MM HH:MM')}`
        }
        return (
            <div style={{
                position: 'relative',
            }}>
                <div style={{
                    position: 'relative',
                    alignItems: 'center',
                    display: 'flex',
                    textSize: '10px',
                    width: '100%',
                    color: customApp("medium"),
                    justifyContent: 'space-between'
                }}>
                    <Avatar alt={db.users[data.byUser].name} src={db.users[data.byUser].image || null} size={'sm'} >
                        {db.users[data.byUser].name.substr(0, 1)}
                    </Avatar>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {translate(data.description)} - {time}
                        {data.readed &&
                            <Icon
                                icon={'done_all'}
                                color={customApp('medium')}
                                title="$__readed"
                            />
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={data.readed ? classes.Readed : classes.unReaded} >
            <div style={{
                textAlign: "center",
                width: '100%',
                padding: "7px",
                maxWidth: '100%',
            }}>

            </div>
            {data.module === 'timeline' && !props.showTimeline
                && data.inNode
                && data.inNode.type
                && data.inNode._id
                && reduxStore.getState().db[dbNotify]
                && reduxStore.getState().db[dbNotify][data.inNode._id]
                && timeline[data.inNode._id]
                && timeline[data.inNode._id][data.nodeId]
                &&
                <div
                    style={{ display: 'flex', cursor: 'pointer' }}
                    onClick={() => {
                        if (data.inNode.type === 'User') {
                            let userId
                            if (data && data.inNode && data.inNode._id
                                && reduxStore.getState().db
                                && reduxStore.getState().db.users
                                && reduxStore.getState().db.users[data.inNode._id]
                                && timeline[data.inNode._id]
                                && Object.keys(timeline[data.inNode._id]).length > 0
                                && timeline[data.inNode._id][data.nodeId])
                                userId = timeline[data.inNode._id][data.nodeId].user._id
                            openChat(userId, 'users')
                        }
                        if (data.inNode.type === 'Card') {
                            if (reduxStore.getState().db.cards[data.inNode._id].type !== 'chatGroup') {
                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                    ...reduxStore.getState().sideMudule,
                                    db: 'cards',
                                    id: data.inNode._id,
                                    module: "cardEdit",
                                    activeModule: 'timeline',
                                    data: reduxStore.getState().db.cards[data.inNode._id],
                                });
                            } else {
                                openChat(data.inNode._id, 'cards')
                            }
                        }
                    }}
                >
                    <Message
                        storie={data && data.inNode && data.inNode._id
                            && reduxStore.getState().db
                            && reduxStore.getState().db[dbNotify]
                            && reduxStore.getState().db[dbNotify][data.inNode._id]
                            && timeline[data.inNode._id]
                            && Object.keys(timeline[data.inNode._id]).length > 0
                            && timeline[data.inNode._id][data.nodeId]
                            ? timeline[data.inNode._id][data.nodeId]
                            : {}
                        } boxStyle={{ width: "calc(100% - 10px) !important" }} db={dbNotify} />
                </div>
            }
            {(
                data.module === 'card'
                && db[dbNotify] && db[dbNotify][data.nodeId]
            )
                &&
                <div
                    style={reduxStore.getState().db.cards[data.nodeId].type === 'chatGroup' ? { cursor: 'pointer' } : {}}
                    onClick={(e) => {
                        if (reduxStore.getState().db.cards[data.nodeId].type === 'chatGroup') {
                            openChat(data.nodeId, 'cards')
                        }
                    }}
                >
                    <Header />
                    <Card db="cards" data={reduxStore.getState().db.cards[data.nodeId]} expanded
                        onlyOption={reduxStore.getState().db.cards[data.nodeId].type === 'chatGroup' ? true : false}
                        onClick={(e) => {
                            if (reduxStore.getState().db.cards[data.nodeId].type === 'chatGroup') {
                                openChat(data.nodeId, 'cards')
                            }
                        }}
                    />

                </div>
            }


            {
                (
                    props.showTimeline && db[dbNotify] && db[dbNotify][data.inNode._id]
                )
                &&
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <div
                            style={reduxStore.getState().db.cards[data.inNode._id].type === 'chatGroup' ? { cursor: 'pointer' } : {}}
                            onClick={(e) => {
                                if (reduxStore.getState().db.cards[data.inNode._id].type === 'chatGroup') {
                                    openChat(data.inNode._id, 'cards')
                                }
                            }}
                        >
                            <Header />
                            <Card db="cards" data={reduxStore.getState().db.cards[data.inNode._id]} expanded
                                onlyOption={reduxStore.getState().db.cards[data.inNode._id].type === 'chatGroup' ? true : false}
                                onClick={(e) => {
                                    if (reduxStore.getState().db.cards[data.inNode._id].type === 'chatGroup') {
                                        openChat(data.inNode._id, 'cards')
                                    }
                                }}
                            />
                        </div>
                    </GridItem>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={8}
                        lg={8}
                        xl={8}
                    >
                        <div style={{ position: 'relative', width: '100%', minHeight: '250px' }}>
                            <MiniTimeline
                                nodeId={data.inNode._id}
                                stories={
                                    data
                                        && data.inNode
                                        && data.inNode._id
                                        && reduxStore.getState().db.cards[data.inNode._id]
                                        && timeline[data.inNode._id]
                                        ? timeline[data.inNode._id]
                                        : {}
                                }
                                db={'cards'}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            }
        </div>
    )
}

const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

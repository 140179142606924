//CORE
import React from 'react'

//@MATERIAL
import Icon from "@material-ui/core/Icon"

//COMPONENTS
import HtmlTooltip from "components/Tooltip/html"

//FUNCTIONS
import { translate, customApp } from "functions/"

export default function IconComponent(props) {
    return (
        <HtmlTooltip
            title={props.title ? translate(props.title, 1) : ''}
        >
            {(typeof props.icon === 'string') || (typeof props.children === 'string') ?
                <Icon
                    className={props.className ? props.className : ''}
                    style={{
                        position: `${props.static ? 'static' : ''}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: props.size || '22px',
                        width: props.size || '22px',
                        fontSize: props.size || '22px',
                        color: props.color || customApp('medium'),
                        opacity: !props.disabled ? '1' : '0.5',
                        cursor: props.onClick ? 'pointer' : '',
                        ...props.style
                    }}
                    onClick={props.onClick ? (e) => {
                        props.onClick()
                        e.preventDefault();
                        e.stopPropagation();
                    } : null
                    }
                >
                    {props.icon || props.children}
                </Icon>
                :
                <props.icon
                    className={props.className ? props.className : ''}
                    style={{
                        width: props.size || '22px',
                        height: props.size || '22px',
                        fontSize: props.size || '22px',
                        color: props.color || customApp('medium'),
                        opacity: !props.disabled ? '1' : '0.5',
                        cursor: props.onClick ? 'pointer' : '',
                        margin: 'auto',
                        ...props.style
                    }}
                    onClick={props.onClick ? (e) => {
                        props.onClick()
                        e.preventDefault();
                        e.stopPropagation();
                    } : null
                    }
                />
            }
        </HtmlTooltip>
    )
}
//CORE
import React from "react";
import ReactExport from "react-export-excel";
import moment from "moment-timezone";

//@MATERIAL
import CustomButton from "components/Buttons/custom"

//FUNCTIONS
import { translate } from "functions/";

function ExportAll(props) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    const data = props.data
    let allUsers = []
    let deletedUsers = []

    data.forEach(acc => {
        acc.users.filter(u => !u.deleted).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase()).forEach(u => {
            if (allUsers.filter(au => au.id === u.id).length === 0)
                allUsers.push(u)
        })
        acc.users.filter(u => u.deleted).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase()).forEach(u => {
            if (deletedUsers.filter(au => au.id === u.id).length === 0)
                deletedUsers.push(u)
        })
    })
    return (
        <ExcelFile
            filename={`${moment().tz('America/Sao_Paulo').format('YYYY-MM-DDTHHMM')}_${translate("$__companies")}_${translate("$__users")}`}
            element={
                <CustomButton
                    title={translate("$__export")}
                    text={translate("$__export")}
                    icon={"file_download"}
                    size={"25px"}
                />
            }
        >
            <ExcelSheet data={data.sort((a, b) => a.name < b.name)} name={translate("$__accounts")}>
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__id', 1)}`} value="id" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__company', 1)}`} value="name" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__createdAt', 1)}`} value="created_at" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__signaturePlan', 1)}`} value="plan" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__activeUsersCount', 1)}`} value="usersCount" />
                {/* <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__adminLimitUsers', 1)}`} value="adminsLimit" /> */}
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__usedDisk', 1)}`} value="diskUsed" />
            </ExcelSheet>
            <ExcelSheet data={allUsers.sort((a, b) => a.name < b.name)} name={"USUARIOS ATIVOS"}>
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__id', 1)}`} value="id" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__name', 1)}`} value="name" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__unity', 1)}`} value="unity" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__email', 1)}`} value="email" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__createdAt', 1)}`} value="created_at" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__lastSessionDate', 1)}`} value="lastSession" />
            </ExcelSheet>
            <ExcelSheet data={deletedUsers.sort((a, b) => a.name < b.name)} name={"USUÁRIOS REMOVIDOS"}>
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__id', 1)}`} value="id" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__name', 1)}`} value="name" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__unity', 1)}`} value="unity" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__email', 1)}`} value="email" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__createdAt', 1)}`} value="created_at" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__lastSessionDate', 1)}`} value="lastSession" />
                <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__removed', 1)}`} value="deleted" />
            </ExcelSheet>
            {data.map((acc, i) => {
                return (
                    <ExcelSheet data={acc.users} name={acc.name} key={i}>
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__id', 1)}`} value="id" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__name', 1)}`} value="name" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__unity', 1)}`} value="unity" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__email', 1)}`} value="email" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__createdAt', 1)}`} value="created_at" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__lastSessionDate', 1)}`} value="lastSession" />
                        <ExcelColumn width={350} style={{ alignment: { wrapText: true } }} label={`${translate('$__removed', 1)}`} value="deleted" />
                    </ExcelSheet>
                )
            })}

        </ExcelFile>
    )
}
export default ExportAll
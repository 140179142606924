import React from "react"
import moment from "moment-timezone"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

//COMPONENTS
import Filter from "componentsV3/Filter/menuFilter"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import ChartTagsStatistics from "./TagsStatistics/chart"
import UiCard from "components/Card/Card"
import TopTags from "./TagsStatistics/topTags"
import ErrorBoundary from "components/ErrorBoundary"

//DEPENDENCIES
import RenderStatisticsTagMap from "./TagsStatistics/tagsMap"

import { getDate, getTime } from "functions"
import { getChildrens } from "functions/cards";
import { loadTimeline, } from "functions/chat"


const TagsStatistics = (props) => {
    const { db } = reduxStore.getState()

    React.useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const init = async () => {
        loadTimeline(
            {
                ...props,
                fnIdentification: "StrategyAndPlans/Dashboard/Widgers/timeSheet/init()"
            },
            {
                ids: [hashs[0]],
                db: "cards",
                type: "timer",
                reqChildrens: true
            })
    }

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let planCards = getChildrens(hashs[0])

    let tags = {
        guidelines: {},
        objectives: {},
        goals: {},
        actions: {}
    }
    let tagsCards = {}

    //GUIDELINE
    Object.keys(db.cards).filter(card =>
        (
            planCards[card]
            || db.cards[card]._parent === hashs[0]
        )
        && db.cards[card].type.indexOf("guideline") > -1
        && !db.cards[card].deleted
    ).forEach(card => {
        if (db.cards[card]._tags)
            db.cards[card]._tags.filter(a => a.value).forEach(tag => {
                tags = {
                    ...tags,
                    guidelines: {
                        ...tags.guidelines || {},
                        [tag.value]: {
                            ...tag
                        }
                    }
                }
                tagsCards = {
                    ...tagsCards,
                    guidelines: {
                        ...tagsCards?.guidelines || {},
                        [card]: true
                    }
                }
            })
    })

    //OBJECTIVE
    Object.keys(db.cards).filter(card =>
        (
            planCards[card]
            || db.cards[card]._parent === hashs[0]
        )
        && db.cards[card].type === "objective"
        && !db.cards[card].deleted
    ).forEach(card => {
        if (db.cards[card]._tags)
            db.cards[card]._tags.filter(a => a.value).forEach(tag => {
                tags = {
                    ...tags,
                    objectives: {
                        ...tags.objectives || {},
                        [tag.value]: {
                            ...tag
                        }
                    }
                }
                tagsCards = {
                    ...tagsCards,
                    objectives: {
                        ...tagsCards?.objectives || {},
                        [card]: true
                    }
                }
            })
    })

    //GOAL
    Object.keys(db.cards).filter(card =>
        (
            planCards[card]
            || db.cards[card]._parent === hashs[0]
        )
        && db.cards[card].type.indexOf("goal") > -1
        && !db.cards[card].deleted


    ).forEach(card => {
        if (db.cards[card]._tags)
            db.cards[card]._tags.filter(a => a.value).forEach(tag => {
                tags = {
                    ...tags,
                    goals: {
                        ...tags.goals || {},
                        [tag.value]: {
                            ...tag
                        }
                    }
                }
                tagsCards = {
                    ...tagsCards,
                    goals: {
                        ...tagsCards?.goals || {},
                        [card]: true
                    }
                }
            })
    })

    //ACTIONS
    Object.keys(db.cards).filter(card =>
        (
            planCards[card]
            || db.cards[card]._parent === hashs[0]
        )
        && !db.cards[card].deleted
        && (
            db.cards[card].type === 'iniciative'
            || db.cards[card].type === 'task'
            || db.cards[card].type === 'meeting'
            || db.cards[card].type === 'iniciative'
            || db.cards[card].type === 'myTask'
        )
        && (
            !db.cards[card].checklist
            || (
                db.cards[card].checklist
                && db.cards[card].showCard
            )
        )
    ).forEach(card => {
        if (db.cards[card]._tags)
            db.cards[card]._tags.filter(a => a.value).forEach(tag => {
                tags = {
                    ...tags,
                    actions: {
                        ...tags.actions || {},
                        [tag.value]: {
                            ...tag,
                        }
                    }
                }
                tagsCards = {
                    ...tagsCards,
                    actions: {
                        ...tagsCards?.actions || {},
                        [card]: true
                    }
                }
            })
    })



    let tagsChartMount = {}
    let tagsChartData = []
    let tagsChartData2 = []
    let tagsChartKeys = []

    let allTagsCards = {}
    Object.keys(tagsCards).forEach(x => {
        allTagsCards = {
            ...allTagsCards,
            ...tagsCards[x]
        }
    })

    let finalDate = new Date()
    let initialDate = new Date()


    let endDate = moment(finalDate).unix("X")
    finalDate = finalDate.toISOString().split('T')[0]
    initialDate.setDate(initialDate.getDate() - 30)
    let startDate = moment(initialDate).unix("X")


    initialDate = initialDate.toISOString().split('T')[0]

    const search = props.searchs[`tagsStatistics_${hashs[0]}`] || {
        text: "#4",
        startDate,
        endDate,
    }

    var loopDate = new Date(search.startDate * 1000 || search.startDate);
    var loopFinalDate = new Date(search.endDate * 1000 || search.endDate)

    let sf = "completed"
    if (search && search.status && Object.keys(search.status).length > 0)
        Object.keys(search.status).forEach(x => {
            if (search.status[x])
                sf = x
        })
    const statusFilter = sf

    while (loopDate <= loopFinalDate) {
        let dateSearch = loopDate.toISOString().split('T')[0]
        let tagsMount = {}
        Object.keys(planCards)
            .filter(c =>
                db.cards[c].status === statusFilter
                && getDate(db.cards[c][statusFilter === "completed" ? "completed_at" : "updated_at"], "YYYY-MM-DD") === dateSearch
            )
            .forEach(card => {
                let parentCard = db.cards[card]
                let cards = getChildrens(card)
                Object.keys(cards)
                    .filter(x =>
                    (
                        (
                            statusFilter === "completed"
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                        ||
                        (
                            statusFilter !== "completed"
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                    )
                    )
                    .forEach(c => {
                        if (db.cards[c]._tags && db.cards[c]._tags.filter(tc => tc.value).length > 0)
                            db.cards[c]._tags.filter(tc => tc.value
                                && (
                                    !search.text
                                    || (
                                        search.text
                                        && tc.label.indexOf(search.text) > -1
                                    )

                                )

                            ).forEach(tc => {
                                tagsMount = {
                                    ...tagsMount,
                                    [`${tc.label}`]: tagsMount[`${tc.label}`] + 1 || 1,
                                    [`${tc.label}Color`]: tc.color || null,
                                }
                                if (tagsChartKeys.indexOf(tc.label) === -1)
                                    tagsChartKeys.push(tc.label)
                            })
                        if (parentCard._tags && parentCard._tags.filter(tc => tc.value).length > 0)
                            parentCard._tags.filter(tc => tc.value
                                && (
                                    !search.text
                                    || (
                                        search.text
                                        && tc.label.indexOf(search.text) > -1
                                    )

                                )
                            ).forEach(tc => {
                                tagsMount = {
                                    ...tagsMount,
                                    [`${tc.label}`]: tagsMount[`${tc.label}`] + 1 || 1,
                                    [`${tc.label}Color`]: tc.color || null,
                                }
                                if (tagsChartKeys.indexOf(tc.label) === -1)
                                    tagsChartKeys.push(tc.label)
                            })
                    })
            })

        if (Object.keys(tagsMount).length > 0)
            tagsChartData2.push({
                date: dateSearch,
                ...tagsMount
            })

        var newDate = loopDate.setDate(loopDate.getDate() + 1);
        loopDate = new Date(newDate);
    }
    Object.keys(planCards)
        .filter(c =>
            db.cards[c].status === statusFilter
            && (
                (
                    statusFilter === "completed"
                    && getDate(db.cards[c].completed_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                    && getDate(db.cards[c].completed_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                )
                ||
                (
                    statusFilter !== "completed"
                    && getDate(db.cards[c].updated_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                    && getDate(db.cards[c].updated_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                )
            )
        ).forEach(card => {
            let cards = getChildrens(card)
            let parentCard = db.cards[card]
            Object.keys(cards)
                .filter(x =>
                    (
                        !db.cards[x].checklist
                        || (
                            db.cards[x].checklist
                            && db.cards[x].showCard
                        )
                    )
                    &&
                    (
                        (
                            statusFilter === "completed"
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                        ||
                        (
                            statusFilter !== "completed"
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                    )
                )
                .forEach(c => {
                    db.cards[card]._tags
                        .forEach(tc => {
                            tagsChartMount = {
                                ...tagsChartMount,
                                [tc.value]: {
                                    ...tagsChartMount[tc.value] || {},
                                    [c]: {
                                        date: statusFilter === "completed" ? getDate(db.cards[c].completed_at, "YYYY-MM-DD") : getDate(db.cards[c].updated_at, "YYYY-MM-DD"),
                                        dateStamp: statusFilter === "completed" ? db.cards[c]?.completed_at?.low || db.cards[c].completed_at || null : db.cards[c]?.updated_at?.low || db.cards[c].updated_at || null
                                    }
                                }
                            }
                        })
                    if (parentCard._tags && parentCard._tags.filter(tc => tc.value).length > 0)
                        parentCard._tags
                            .forEach(tc => {
                                tagsChartMount = {
                                    ...tagsChartMount,
                                    [tc.value]: {
                                        ...tagsChartMount[tc.value] || {},
                                        [c]: {
                                            date: statusFilter === "completed" ? getDate(db.cards[c].completed_at, "YYYY-MM-DD") : getDate(db.cards[c].updated_at, "YYYY-MM-DD"),
                                            dateStamp: statusFilter === "completed" ? db.cards[c]?.completed_at?.low || db.cards[c].completed_at || null : db.cards[c]?.updated_at?.low || db.cards[c].updated_at || null
                                        }
                                    }
                                }
                            })
                })
        })

    Object.keys(tagsChartMount).forEach(x => {
        let newData = []
        let dateSum = {}
        let tagsSum = {}
        Object.keys(tagsChartMount[x])
            .forEach(cd => {
                dateSum = {
                    ...dateSum,
                    [`${tagsChartMount[x][cd].date} `]: dateSum[`${tagsChartMount[x][cd].date} `] + 1 || 1
                }
                tagsSum = {
                    ...tagsSum,
                    [x]: tagsSum[x] + 1 || 1
                }
            })
        Object.keys(dateSum).forEach(x => {
            newData.push({
                x: x,
                y: dateSum[x]
            })
        })
        var loop = new Date(initialDate);
        while (loop <= new Date(finalDate)) {
            let dateSearch = loop.toISOString().split('T')[0]
            if (newData.filter(a => a.x === dateSearch).length === 0)
                newData.push({
                    x: dateSearch,
                    y: 0
                })
            var newDate = loop.setDate(loop.getDate() + 1);
            loop = new Date(newDate);
        }
        if (db.tag.filter(a => a.value === x).length > 0) {
            tagsChartData = [...tagsChartData, {
                "id": db.tag.filter(a => a.value === x).map(x => x)[0].label,
                data: Array.from([...newData.sort((a, b) => {
                    if (Date.parse(a.x) < Date.parse(b.x))
                        return -1
                    if (Date.parse(a.x) > Date.parse(b.x))
                        return 1
                    return 0
                })])
            }]
        }
    })

    let totalTimes = 0

    Object.keys(tagsChartMount)
        .filter(tag => db.tag.filter(a => a.value === tag).length > 0)
        .sort((a, b) => {
            if (Object.keys(tagsChartMount[a]).length > Object.keys(tagsChartMount[b]).length)
                return -1
            if (Object.keys(tagsChartMount[a]).length < Object.keys(tagsChartMount[b]).length)
                return 1
            return 0
        })
        .forEach((tg, i) => {
            let times = 0
            let timesByUser = {}

            let TotalCards = tagsChartMount[tg].total || 0
            Object.keys(tagsChartMount[tg]).forEach(cd => {
                if (reduxStore.getState().timeline && reduxStore.getState().timeline[cd]) {
                    let timeline = reduxStore.getState().timeline[cd]
                    Object.keys(timeline).filter(tm =>
                        timeline[tm].type === "timer"
                        && timeline[tm].ini >= search.startDate
                        && timeline[tm].fin <= search.endDate
                    ).forEach(tm => {
                        let multi = 1
                        if (timeline[tm] && timeline[tm].users && timeline[tm].users.filter(user => user._id).length > 0) {
                            multi = timeline[tm].users.filter(us => us._id).length
                        } else {
                            timesByUser = {
                                ...timesByUser,
                                [timeline[tm].user._id]: timesByUser[timeline[tm].user._id] + 1 || 1,
                            }
                        }
                        let total = ((timeline[tm].fin - timeline[tm].ini) * multi)
                        times = times + total

                        if (timeline[tm] && timeline[tm].users && timeline[tm].users.filter(user => user._id).length > 0) {
                            timeline[tm].users.filter(user => user._id).forEach(user => {
                                timesByUser = {
                                    ...timesByUser,
                                    [user._id]: timesByUser[user._id] + total || total,
                                }
                            })
                        } else {
                            timesByUser = {
                                ...timesByUser,
                                [timeline[tm].user._id]: timesByUser[timeline[tm].user._id] + total || total,
                            }
                        }

                    })
                }
                //COUNT CHILDREN CARDS
                let childrensCards = getChildrens(cd)
                TotalCards = TotalCards + Object.keys(childrensCards).filter(x =>
                    db.cards[x]
                    && !db.cards[x].deleted
                    && db.cards[x].status === statusFilter
                    && (
                        !db.cards[x].checklist
                        || (
                            db.cards[x].checklist
                            && db.cards[x].showCard
                        )
                    )
                    && (
                        (
                            statusFilter === "completed"
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].completed_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                        ||
                        (
                            statusFilter !== "completed"
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                            && getDate(db.cards[x].updated_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                        )
                    )
                ).length
            })
            totalTimes = totalTimes + times
            tagsChartMount[tg] = {
                ...tagsChartMount[tg],
                times: getTime(times),
                timesInt: times,
                TotalCards,
                timesByUser,
            }
        })




    return (
        <ErrorBoundary>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <Filter
                        nodeId={`tagsStatistics_${hashs[0]}`}
                        defaultValues={{
                            startDate,
                            endDate,
                            text: "#4",
                            status: {
                                all: false,
                                notStarted: false,
                                awaitAction: false,
                                paused: false,
                                inProgress: false,
                                completed: true,
                            }
                        }}
                        preffers={{
                            searchButton: true,
                            unique: {
                                status: true
                            }
                        }}
                        fields={['text', 'date', 'status']}
                        // fields={['text', 'priority', 'impact', 'risk', 'complexity', 'users', 'tags', 'date']}
                        showFilters

                    />
                </GridItem>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                        minHeight: 300,
                        height: tagsChartData2.length * 35,
                    }}
                >
                    <ChartTagsStatistics
                        data={tagsChartData2}
                        keys={tagsChartKeys}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                >
                    <UiCard>
                        <TopTags data={tagsChartMount} statusFilter={statusFilter} startDate={startDate} search={search} totalTimes={totalTimes} />
                    </UiCard>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <RenderStatisticsTagMap tagsChartMount={tagsChartMount} tags={tags["guidelines"]} cards={tagsCards["guidelines"]} type={"guidelines"} text={"#1"} />
                <RenderStatisticsTagMap tagsChartMount={tagsChartMount} tags={tags["objectives"]} cards={tagsCards["objectives"]} type={"objectives"} text={"#2"} />
                <RenderStatisticsTagMap tagsChartMount={tagsChartMount} tags={tags["goals"]} cards={tagsCards["goals"]} type={"goals"} text={"#3"} />
                <RenderStatisticsTagMap tagsChartMount={tagsChartMount} tags={tags["actions"]} cards={tagsCards["actions"]} type={"actions"} text={"#4"} />
            </GridContainer>
        </ErrorBoundary>
    )
}

const mapStateToProps = (store) => ({
    cards: store.db.cards,
    tags: store.db.tags,
    searchs: store.searchs,
    timelines: store.timelines,
}
)

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(TagsStatistics);
import React from "react";
import { FileDrop } from "react-file-drop";
import { MentionsInput, Mention } from 'react-mentions';
import { v4 as uuid } from "uuid";
import Picker from 'emoji-picker-react';
import moment from "moment-timezone"

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Icon from "components/Icon";
import CustomIconButton from "components/CustomButtons/icon";
import CustomButton from "components/Buttons/custom";

//DEPENDENCIES
import Message from "./message"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar";
import LinearProgress from "@material-ui/core/LinearProgress";
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from "@material-ui/core/styles";

//STYLES
import mentionInputStyle from "assets/jss/material-dashboard-pro-react/components/Mentions-Input";
import styles from "assets/jss/material-dashboard-pro-react/components/timelineSending";

//FUNCTIONS
import { cardsAccess } from "functions/users";
import { translate, customApp, getAppAccess, appAlert } from "functions/";
import { upload } from "functions/upload";
import { timelineComment } from "functions/chat.js"
import apims from "apims"
import api from "api"

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ErrorBoundary from "components/ErrorBoundary";

const useStyles = makeStyles(styles)

const Timeline = (props) => {
    const { db, session } = reduxStore.getState()
    const [message, setMessage] = React.useState('')
    const [sending, setSending] = React.useState(false);
    const [fileDropDrag, setFileDropDrag] = React.useState(false);
    const [fileList, setFileList] = React.useState([]);
    const [fileSend, setFileSend] = React.useState([]);
    const [fileProgress, setFileProgress] = React.useState({})
    const [fileError, setFileError] = React.useState(null)
    const [urlPreview, setUrlPreview] = React.useState(null)
    const [urlPreviewLoad, setUrlPreviewLoad] = React.useState(false)
    const [urlErrors, setUrlErros] = React.useState([])
    const [onEmojiPicker, setOnEmojiPicker] = React.useState(false)
    const [inputHeight, setHeight] = React.useState(58)

    const classes = useStyles()
    const refFileDrop = React.createRef()
    const sendingInput = React.createRef()

    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let AppAccess = getAppAccess()

    let CardAccess = 0

    if (session._id
        && db.cards[props.nodeId]
        && db.cards[props.nodeId]._users
        && db.cards[props.nodeId]._users[Object.keys(db.cards[props.nodeId]._users).find(a => a.indexOf(session._id) > -1)])
        CardAccess = db.cards[props.nodeId]._users[Object.keys(db.cards[props.nodeId]._users).find(a => a.indexOf(session._id) > -1)]

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    React.useEffect(() => {
        if (mounted.current)
            if (props.draft && props.draft.message) {
                setMessage(props.draft.message)
            } else {
                setMessage("")
            }
        if (props?.draft?.files) {
            setFileList(props.draft.files)
        } else {
            setFileList([])
        }

        return () => {
            setMessage("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.nodeId])

    const textInput = React.createRef()

    React.useEffect(() => {
        const inputId = document.getElementById(`sendingInput_${props.nodeId}`)
        if (props.draft?.reply && inputId)
            inputId.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.draft])

    // const sendTyping = async () => {
    //     let ids = []
    //     let group = null
    //     if (db.cards[props.nodeId] && db.cards[props.nodeId].type === 'chatGroup') {
    //         group = props.nodeId
    //         Object.keys(db.cards[props.nodeId]._users).map(id => {
    //             if (ids.indexOf(id) === -1)
    //                 ids.push(id)
    //         })
    //         socket.emit("timeline.TYPING", {
    //             to: ids,
    //             group: group
    //         })
    //     } else {
    //         ids.push(props.nodeId)
    //         socket.emit("timeline.TYPING", {
    //             to: ids
    //         })
    //     }
    // }

    const mentionedUsers = () => {
        let newText
        newText = message
        let usersMentioned = []
        if (newText) {
            const test1 = newText.split('@[')
            if (test1.length > 0)
                test1.forEach(a => {
                    if (a) {
                        let id = a.split("](")[1]
                        if (id) {
                            id = id.split(")")[0]
                            if (id && id.length === 36) {
                                usersMentioned.push(id)
                            }
                        }
                    }
                })
        }
        return usersMentioned
    }

    const sendMessage = async () => {
        let notifyUsers = []
        let dataMessage = {
            _id: uuid(),
            idRel: props.nodeId,
            replyData: props.draft && props.draft.reply && reduxStore.getState().timeline[props.nodeId][props.draft.reply] ? reduxStore.getState().timeline[props.nodeId][props.draft.reply] : {},
            reply: props.draft && props.draft.reply ? props.draft.reply : null,
            message: message,
            type: 'comment',
            mentions: mentionedUsers(),
            notifyUsers: notifyUsers,
            filesCount: fileList.length > 0 ? fileList.length : 0,
            _parent: props.nodeId,
            _client: db.cards[props.nodeId] && db.cards[props.nodeId].client ? db.cards[props.nodeId].client : null,
            db: props.db ? props.db : 'cards'
        }
        if (urlPreview) {
            dataMessage = { ...dataMessage, urlPreview: JSON.stringify(urlPreview) }
            setUrlPreview(null)
        }

        setMessage("")
        setOnEmojiPicker(false)
        if (fileList.length > 0) setSending(true)


        let mentions = mentionedUsers();
        if (db.cards[props.nodeId]
            && db.cards[props.nodeId].type !== 'chatGroup'
            && db.cards[props.nodeId]._users
            && Object.keys(db.cards[props.nodeId]._users).length > 0
        )
            Object.keys(db.cards[props.nodeId]._users).filter(a => a !== session._id && mentions.indexOf(a) === -1).forEach(a => {
                notifyUsers.push(a)
            })

        if (props.onSending)
            props.onSending({
                ...dataMessage,
                user: {
                    _id: session._id,
                    name: db.users[session._id].name,
                    image: db.users[session._id].image || null,
                },
            })
        let comment = {
            ...dataMessage,
            created_at: `${moment().unix('x')}`,
            user: {
                _id: session._id,
                name: db.users[session._id].name,
                image: db.users[session._id].image || null,
            },
        }
        if (comment.mentions)
            delete comment.mentions

        if (comment.notifyUsers)
            delete comment.notifyUsers

        if (comment.db)
            delete comment.db

        timelineComment({
            reduxFunction: props.reduxFunction
        }, {
            ...dataMessage,
            comment
        })


        props.reduxFunction("ASYNC", "SET_CHATS", {
            ...reduxStore.getState().chats,
            drafts: {
                ...reduxStore.getState().chats.drafts,
                [props.nodeId]: {
                    message: null,
                    reply: null,
                    files: null
                }
            }
        })
        let sendMessage = await api.post("timeline/add", dataMessage)

        if (sendMessage && sendMessage.data) {
            const response = sendMessage.data
            if (response._id) {
                let comment = {
                    ...response.comment,
                    _parent: props.nodeId,
                    _client: db.cards[props.nodeId] && db.cards[props.nodeId].client ? db.cards[props.nodeId].client : null
                }
                let userAccessNew = 2
                if (CardAccess < 2)
                    userAccessNew = CardAccess

                mentions.map(async a => {
                    if (db.cards[props.nodeId]
                        && (
                            !db.cards[props.nodeId]._users
                            || (
                                db.cards[props.nodeId]._users
                                && !db.cards[props.nodeId]._users[a]
                            )
                        )
                    )
                        cardsAccess(props, a, userAccessNew, props.nodeId, true)
                })
                if (fileList.length > 0) {
                    sendFiles({ comment, dataMessage });
                } else {
                    if (props.onSending)
                        props.onSending()

                    setFileSend([]);
                    setFileList([]);
                    setUrlErros([]);
                    setUrlPreview(null);
                    setUrlPreviewLoad(false);
                    setSending(false)
                    if (props.onSending) {
                        props.onSending()
                    }
                }
            }
        }
        return false
    }

    const onUploadProgress = async (event, fi) => {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        setFileProgress({
            ...fileProgress,
            [fi]: percentCompleted
        })
    };

    const onUploadError = async (options, fi) => {
        setFileError({
            options,
            fi
        })
        setFileProgress({})
    };

    const sendFiles = async (options, n = 0, sendedList = []) => {
        const { comment, dataMessage } = options
        let files = Array.from(fileList)
        files.map(async (f, fi) => {
            if (fi === n) {
                const fileUpload = await upload(comment._id, f, true, {
                    onUploadProgress: (e) => onUploadProgress(e, fi),
                    onUploadError: (e) => {
                        onUploadError(options, fi)
                    }
                })
                if (fileUpload) {
                    let nFilesSend = Array.from(sendedList)
                    nFilesSend.push(fileUpload)
                    setFileSend(nFilesSend)

                    if (files.length > n + 1) {
                        sendFiles(options, n + 1, nFilesSend)
                    } else if (files.length === n + 1) {
                        dataMessage.files = nFilesSend
                        setTimeout(() => {
                            api.post("timeline/add", dataMessage)
                            // socket.emit("data", {
                            //     module: "timeline",
                            //     method: "post",
                            //     action: "add"
                            // },
                            //     dataMessage,
                            // )
                        }, 1000)

                        setFileSend([]);
                        setFileList([]);
                        setUrlPreview(null);
                        setUrlPreviewLoad(false);
                        setSending(false)
                        if (props.onSending) {
                            props.onSending()
                        }
                    }
                }
            }
        })
    }

    const onDropFile = async event => {
        let fileSizeAlert = false
        setFileList([...fileList, ...Object.keys(event)
            .filter(file => {
                if (event[file].size > 100663296) { //96MB
                    fileSizeAlert = true
                    return false
                }
                return true
            })
            .map(file => event[file])])
        if (fileSizeAlert)
            appAlert({
                message: translate("$__filesSentCannotExceed100mbEach", 1),
                variant: "warning",
                persist: false,
                horizontal: "center",
            })
        props.reduxFunction("ASYNC", "SET_CHATS", {
            ...reduxStore.getState().chats,
            drafts: {
                ...reduxStore.getState().chats.drafts,
                [props.nodeId]: {
                    ...reduxStore.getState().chats.drafts?.[props.nodeId] || {},
                    files: [...fileList, ...Object.keys(event)
                        .filter(file => {
                            if (event[file].size > 100663296) { //96MB
                                return false
                            }
                            return true
                        })
                        .map(file =>
                            event[file]
                        )]
                }
            }
        })
        setFileDropDrag(false)
    }

    const deleteFile = i => {
        let files = Array.from(fileList)
        files.splice(i, 1)
        setFileList(files)
    }

    const verifyText = async (f) => {
        setMessage(f)
        if (f

            && (
                f.indexOf('http://') > -1
                ||
                f.indexOf('https://') > -1
            )
        ) {
            let url = ''
            let text = []
            if (f.indexOf(' ') > -1) {
                text = f.split(' ')
            } else if (f.indexOf('\n') > -1) {
                text = f.split('\n')
            } else {
                text.push(f)
            }
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

            await text.forEach(a => {
                let test = a.replace(':', "")
                if (
                    (
                        test.indexOf('http://') > -1
                        ||
                        test.indexOf('https://') > -1
                    )
                    &&
                    !!pattern.test(test)) {
                    url = a
                }
            })

            if (
                (
                    !urlPreview
                    && url
                    && urlErrors.indexOf(url) === -1
                )
                ||
                (
                    url
                    && urlPreview
                    && urlErrors.indexOf(url) === -1
                    && urlPreview.link !== url
                )
            ) {
                setUrlPreviewLoad(true)
                if (f.indexOf('youtube.com/watch?') === -1) {
                    try {
                        let dataUrl = await apims.post("/URL_DATA", {
                            url
                        })
                        if (dataUrl && dataUrl.data) {
                            let result = dataUrl.data

                            if (result && result.title && result.img && result.contentSnippet) {
                                setUrlPreview(result)
                            } else {
                                setUrlPreviewLoad(false)
                                setUrlPreview(null)
                            }
                        } else {
                            setUrlPreviewLoad(false)
                            setUrlPreview(null)
                        }
                    } catch (e) {
                        setUrlPreviewLoad(false)
                        setUrlPreview(null)
                        let urlErrorsArray = urlErrors
                        urlErrorsArray.push(url)
                        setUrlErros(urlErrorsArray)
                        appAlert({
                            message: translate("$__couldNotLoadDataFromURL", 1),
                            variant: "warning",
                            persist: false,
                            horizontal: "center",
                        })
                        console.log(e)
                    }
                }

                if (f.indexOf('youtube.com/watch?') > -1)
                    setUrlPreviewLoad(false)
            }
        }
        if (
            f.indexOf('youtube.com/watch?') > -1
        ) {
            setUrlPreviewLoad(false)
        }
    }

    const onEmojiClick = (event, emojiObject) => {
        setMessage(`${message} ${emojiObject.emoji}`)
    };

    let usersSuggestion = []

    if (db.cards[props.nodeId] && db.cards[props.nodeId]._users)
        Object.keys(db.cards[props.nodeId]._users).forEach(a => {
            if (a
                && a !== null
                && a !== undefined
                && a !== session._id
                && db.cards[props.nodeId]._users[a].name
                && (
                    !db.users[a]
                    || (
                        db.users[a]
                        && !db.users[a].deleted
                    )
                )
            ) {
                let id = db.cards[props.nodeId]._users[a]._id
                let display = db.cards[props.nodeId]._users[a].name ? db.cards[props.nodeId]._users[a].name : db.cards[props.nodeId]._users[a].name.split(' ').length > 0 ? db.cards[props.nodeId]._users[a].name.split(' ')[0] : db.cards[props.nodeId]._users[a].name
                if (id && Object.keys(usersSuggestion).filter(a => usersSuggestion[a].id === id).length === 0)
                    usersSuggestion.push({ id: id, display: display })
            }
        })

    if (
        (
            db.cards[props.nodeId]
            && !db.cards[props.nodeId].private
        )
        &&
        (
            (
                AppAccess
                && (
                    AppAccess.owner
                    || AppAccess.planAdmin
                    || AppAccess.planModerate
                )
            ) ||
            (
                CardAccess
                && CardAccess.access
                && parseInt(CardAccess.access) > 3
            )
        )
    ) {
        Object.keys(db.users)
            .filter(fil => {
                if (!db.users[fil].deleted && db.users[fil].type === 'user') {
                    if (
                        (
                            db.cards[props.nodeId]
                            && db.cards[props.nodeId].client
                            && (
                                !db.users[fil]._parent
                                || db.users[fil]._parent === db.cards[props.nodeId].client
                            )
                        )
                        ||
                        (
                            db.cards[props.nodeId]
                            && !db.cards[props.nodeId].client
                            && db.cards[props.nodeId]._planId
                            && db.cards[db.cards[props.nodeId]._planId]
                            && db.cards[db.cards[props.nodeId]._planId].client
                            && (
                                !db.users[fil]._parent
                                || db.users[fil]._parent === db.cards[db.cards[props.nodeId]._planId].client
                            )
                        )
                        ||
                        (
                            db.cards[props.nodeId]
                            && !db.cards[props.nodeId].client
                            && !db.users[fil]._parent
                        )
                    )
                        return true
                }
                return false
            })
            .forEach(a => {
                if (a
                    && a !== null
                    && a !== undefined
                    && a !== session._id
                    && Object.keys(usersSuggestion).filter(fs => {
                        if (usersSuggestion[fs].id === a)
                            return true
                        return false
                    }).length === 0
                ) {
                    usersSuggestion.push({
                        id: a,
                        display: props.nodeId && db.cards[props.nodeId] && db.cards[props.nodeId]._users && db.cards[props.nodeId]._users[a] && db.cards[props.nodeId]._users[a].name ? db.cards[props.nodeId]._users[a].name : db.users[a].displayName ? db.users[a].displayName : db.users[a].name
                    })
                }
            })
    }

    if (db.cards[props.nodeId] &&
        (
            db.cards[props.nodeId]._parent
            && db.cards[db.cards[props.nodeId]._parent]
            && db.cards[db.cards[props.nodeId]._parent]._users
        )
    )
        Object.keys(db.cards[db.cards[props.nodeId]._parent]._users).forEach(a => {
            if (a
                && a !== null
                && a !== undefined
                && a !== session._id
                && Object.keys(usersSuggestion).filter(fs => {
                    if (usersSuggestion[fs].id === a)
                        return true
                    return false
                }).length === 0
            ) {
                usersSuggestion.push({
                    id: a,
                    display: db.cards[db.cards[props.nodeId]._parent]._users[a].name,
                    image: db.cards[db.cards[props.nodeId]._parent]._users[a].image || null
                })
            }
        })

    if (db.cards[props.nodeId] &&
        (
            db.cards[props.nodeId]._planId
            && db.cards[db.cards[props.nodeId]._planId]
            && db.cards[db.cards[props.nodeId]._planId]._users
        )
    )
        Object.keys(db.cards[db.cards[props.nodeId]._planId]._users).forEach(a => {
            if (a
                && a !== null
                && a !== undefined
                && a !== session._id
                && Object.keys(usersSuggestion).filter(fs => {
                    if (usersSuggestion[fs].id === a)
                        return true
                    return false
                }).length === 0
            ) {
                usersSuggestion.push({
                    id: a,
                    display: db.cards[db.cards[props.nodeId]._planId]._users[a].name,
                    image: db.cards[db.cards[props.nodeId]._planId]._users[a].image || null
                })
            }
        })

    return (
        <FileDrop
            ref={refFileDrop}
            className={props.relative ? classes.rootRelative : classes.root}
            onDragOver={
                () => {
                    !props.disabledSend &&
                        setFileDropDrag(true)
                }
            }
            onDrop={onDropFile}
            accept={".png, .jpg, .jpeg, .bpm, .svg, .gif, .pdf, .mp4, .3gp, .ts, .mkv, .avi, .mov, .mts,  m2ts, .mxf, .aac,  aiff, .caf, .ogg, .mp3, .wav, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .rar"}
        >
            <React.Fragment>
                {props.nodeId && !props.disabledSend ?
                    <div className={props.relative ? classes.editorRelative : classes.editor}
                        style={{
                            ...props.editorStyle ? props.editorStyle : {},
                            backgroundColor: "#F0F0F0"
                        }}>
                        <div className={classes.textEditor}>
                            {urlPreviewLoad && !urlPreview &&
                                <div className={classes.urlPreview} style={{ width: '100%', height: '150px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginLeft: '7px', width: '200px', height: '110px', maxWidth: '50%' }}>
                                            <Skeleton variant="rect" style={{ width: '200px', height: '110px', maxWidth: '100%' }} />
                                        </div>
                                        <div style={{ marginLeft: '7px', width: '100%', height: '25px' }}>
                                            <Skeleton variant="text" height={25} style={{ marginBottom: '7px' }} />
                                            <Skeleton variant="text" height={10} />
                                            <Skeleton variant="text" height={10} />
                                            <Skeleton variant="text" height={10} />
                                            <Skeleton variant="text" height={10} />
                                            <Skeleton variant="text" height={10} />
                                            <Skeleton variant="text" height={10} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {(
                                (props.draft && props.draft.reply)
                                || (fileList && fileList.length > 0)
                            ) ?
                                <div style={{
                                    position: "absolute",
                                    right: 0,
                                    left: 0,
                                    height: "auto",
                                    padding: 7,
                                    bottom: inputHeight + 4,
                                    backgroundColor: "#f0f0f0",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    {props.draft && props.draft.reply ?
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <div style={{
                                                position: "relative",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}>

                                                <div style={{ color: customApp("menu"), display: "flex", alignItems: "center" }}>
                                                    <Icon
                                                        icon='reply'
                                                        color={customApp('menu')}
                                                        size={'22px'}
                                                    /><span style={{ marginLeft: 10 }}>{translate("$__reply")}</span></div>


                                            </div>

                                            <div style={{
                                                display: "flex",
                                                margin: "auto",
                                                padding: 15,
                                                alignItems: "center",
                                                width: "77%",
                                                backgroundColor: "rgba(250,250,250,0.33)",
                                                borderRadius: 5,
                                                borderLeft: `5px solid ${customApp("menu")}`
                                            }}>
                                                <ErrorBoundary>
                                                    <Message
                                                        storie={reduxStore.getState().timeline[props.nodeId][props.draft.reply]}
                                                        hideCards={true}
                                                        db={props.db}
                                                        nodeId={props.nodeId}
                                                        showUserName={true}
                                                        noReactions={true}
                                                    />
                                                    <div style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: 0
                                                    }}>
                                                        <CustomButton
                                                            title={translate("$__cancelReply")}
                                                            color="red"
                                                            icon={"cancel"}
                                                            size={"17px"}
                                                            onClick={() => {
                                                                props.reduxFunction("ASYNC", "SET_CHATS", {
                                                                    ...reduxStore.getState().chats,
                                                                    drafts: {
                                                                        ...reduxStore.getState().chats.drafts,
                                                                        [props.nodeId]: {
                                                                            ...reduxStore.getState().chats.drafts[props.nodeId] || {},
                                                                            reply: null,
                                                                        }
                                                                    }
                                                                })
                                                            }}
                                                            transparent
                                                        />
                                                    </div>
                                                </ErrorBoundary>
                                            </div>
                                        </div> : <React.Fragment></React.Fragment>}
                                    {fileList && fileList.length > 0 ?
                                        <div style={{
                                            position: "relative",
                                            maxHeight: "100%",
                                            width: "100%"
                                        }}>
                                            <div style={{
                                                position: "relative",
                                                width: "100%",
                                                float: "left",
                                                padding: 3,
                                                display: "flex",
                                                justifyContent: "flex-end"
                                            }}
                                            >
                                                <CustomButton
                                                    title={translate("$__clearFiles", 1)}
                                                    text={translate("$__clearFiles")}
                                                    color={"gray"}
                                                    icon='clear_all'
                                                    onClick={() => {
                                                        appAlert({
                                                            message: translate("$__confirmClearFiles", 1),
                                                            variant: "warning",
                                                            persist: false,
                                                            horizontal: "right",
                                                            confirm: () => {
                                                                setFileList([])
                                                                props.reduxFunction("ASYNC", "SET_CHATS", {
                                                                    ...reduxStore.getState().chats,
                                                                    drafts: {
                                                                        ...reduxStore.getState().chats.drafts,
                                                                        [props.nodeId]: {
                                                                            ...reduxStore.getState().chats.drafts?.[props.nodeId] || {},
                                                                            files: null
                                                                        }
                                                                    }
                                                                })
                                                            }
                                                        })

                                                    }
                                                    }
                                                    size={"25px"}
                                                />

                                            </div>
                                            <div style={{
                                                position: "relative",
                                                width: "100%",
                                                float: "left",
                                                padding: 3,
                                                overflow: "auto",
                                                display: "flex"
                                            }}
                                            >
                                                {fileList.map((file, i) => {
                                                    let ext = file.name.split('.')
                                                    let img = `attachment`
                                                    if (db.fileExtensions.filter(a => a.ext.indexOf(ext[ext.length - 1].toLowerCase()) > -1).length > 0)
                                                        img = db.fileExtensions.filter(a => a.ext.indexOf(ext[ext.length - 1].toLowerCase()) > -1)[0].icon || URL.createObjectURL(file)
                                                    return (
                                                        <div
                                                            key={i}
                                                            title={file.name}
                                                            style={{
                                                                position: 'relative',
                                                                padding: 7,
                                                                width: "100px",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: 'relative',
                                                                    width: '50px',
                                                                    height: '50px',
                                                                    margin: 'auto',
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center"
                                                                }}
                                                            >
                                                                {typeof img === 'string' && img.indexOf('blob:') === -1 && img.indexOf('svg') === -1 ?
                                                                    <Icon icon={img} size='50px' />
                                                                    :
                                                                    <img alt="MACHEN_IMAGE" src={img} style={{ width: '100%', height: '100%' }} />
                                                                }
                                                            </div>
                                                            <div

                                                                style={{
                                                                    position: "relative",
                                                                    margin: "auto",
                                                                    whiteSpace: "nowrap",
                                                                    textOverflow: "ellipsis",
                                                                    width: "calc(100% - 30px)",
                                                                    overflow: "hidden"
                                                                }}>{file.name}</div>
                                                            <div style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 3,
                                                                display: "flex"
                                                            }}>

                                                                {fileSend && fileSend.length > 0 && fileSend[i] ?
                                                                    <Icon icon="cloud_done" color="green" />
                                                                    : !sending ? <Icon icon="delete_forever" color="red" onClick={() => deleteFile(i)} /> : <React.Fragment></React.Fragment>
                                                                }
                                                            </div>
                                                            {
                                                                fileError && fileError.fi === i ?
                                                                    <CustomButton
                                                                        title={translate("$__uploadFiles", 1)}
                                                                        text={translate("$__fileSendErrorClickToReSend")}
                                                                        color={"red"}
                                                                        icon='upload'
                                                                        onClick={() => {
                                                                            sendFiles({
                                                                                comment: fileError.options.comment
                                                                            }, fileError.fi, fileSend)
                                                                            setFileError(null)
                                                                        }
                                                                        }
                                                                        size={"25px"}
                                                                    />
                                                                    : fileProgress && String(fileProgress[i]) && fileProgress[i] < 100 ?
                                                                        <div style={{ display: "flex", alignItems: "center" }}>

                                                                            <React.Fragment>
                                                                                <div style={{ minWidth: 177 }}>
                                                                                    <LinearProgress variant="buffer" className={classes.progressBar} value={fileProgress[i] < 90 ? fileProgress[i] : 90} valueBuffer={fileProgress[i]} />
                                                                                </div>
                                                                                <div style={{ color: customApp("color"), fontSize: 10, marginLeft: 15 }}>{fileProgress[i] < 90 ? fileProgress[i] : 90}%</div>
                                                                            </React.Fragment>
                                                                        </div>
                                                                        : <React.Fragment></React.Fragment>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </div>
                                : <React.Fragment></React.Fragment>}
                            {urlPreview &&
                                <GridContainer style={{ marginBottom: 15, backgroundColor: "#f0f0f0", padding: 7 }}>
                                    <div style={{ position: "absolute", right: "20px" }}>
                                        <CustomButton
                                            title={translate("$__removePreview")}
                                            color="red"
                                            icon={"delete_forever"}
                                            size={"14px"}
                                            onClick={() => {
                                                setUrlPreview(null);
                                                setUrlPreviewLoad(false);
                                            }}
                                        />
                                    </div>
                                    {urlPreview.img &&
                                        <GridItem
                                            xs={12}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            xl={2}
                                        >
                                            <img
                                                style={{
                                                    width: "-webkit-fill-available"
                                                }}
                                                src={`${urlPreview.img ? urlPreview.img : defaultAvatar}`}
                                                alt={urlPreview.title || urlPreview.link}
                                            />

                                        </GridItem>
                                    }

                                    <GridItem
                                        xs={12}
                                        sm={6}
                                        md={8}
                                        lg={9}
                                        xl={10}
                                    >

                                        <div style={{
                                            fontSize: "14px",
                                            color: customApp("menu"),
                                            fontWeight: "bold"
                                        }}>{urlPreview.title}</div>
                                        {urlPreview.site && <div
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >{urlPreview.site}</div>}
                                        {urlPreview.contentSnippet && <div
                                            style={{
                                                fontSize: "12px",
                                                color: "gray",
                                            }}>{urlPreview.contentSnippet}</div>}
                                        <a style={{
                                            marginTop: "15px",
                                            fontWeight: "bold",
                                            fontSize: "12px",
                                        }
                                        } href={urlPreview.link} target="_blank" rel="noopener noreferrer">{translate("$__openLink", "*")}</a>

                                    </GridItem>
                                </GridContainer>
                            }
                            {fileError ?
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '60px',
                                        backgroundColor: '#ffffff',
                                        width: '100%',
                                        padding: 15,
                                        textAlign: "center",
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                >
                                    <CustomButton
                                        title={translate("$__fileSendErrorClickToReSend", 1)}
                                        text={translate("$__fileSendErrorClickToReSend")}
                                        color={"red"}
                                        icon='upload'
                                        onClick={() => {
                                            sendFiles({
                                                comment: fileError.options.comment
                                            }, fileError.fi, fileSend)
                                            setFileError(null)
                                        }
                                        }
                                        size={"25px"}
                                    />
                                </div>
                                : sending ?
                                    <div
                                        style={{
                                            position: 'relative',
                                            height: '60px',
                                            backgroundColor: '#ffffff',
                                            width: '100%',
                                            padding: 15
                                        }}
                                    >{translate('$__sending', 1)}
                                        <LinearProgress className={classes.progressBar} /></div>
                                    :
                                    <div
                                        ref={sendingInput}
                                        style={{
                                            position: 'relative',
                                            height: 'auto',
                                            borderRadius: '7px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '3px 0px 4px 0px',
                                        }}>
                                        <MentionsInput
                                            id={`sendingInput_${props.nodeId}`}
                                            value={message.trim().length > 0 ? message : ""}
                                            inputRef={textInput}
                                            onChange={(e) => {
                                                verifyText(e.target.value)
                                                // sendTyping()
                                                if (sendingInput.current && sendingInput.current.clientHeight)
                                                    setHeight(sendingInput.current.clientHeight)
                                            }}
                                            onBlur={(e) => {
                                                if (e.target.value)
                                                    props.reduxFunction("ASYNC", "SET_CHATS", {
                                                        ...reduxStore.getState().chats,
                                                        drafts: {
                                                            ...reduxStore.getState().chats.drafts,
                                                            [props.nodeId]: {
                                                                ...reduxStore.getState().chats.drafts[props.nodeId] || {},
                                                                message: e.target.value
                                                            }
                                                        }
                                                    })
                                            }}
                                            onPaste={(e) => {
                                                onDropFile(e.clipboardData.files)
                                            }}
                                            placeholder={translate('$__writeMessage')}
                                            style={{
                                                ...mentionInputStyle,
                                                position: 'relative',
                                                width: 'calc(100% - 130px)',
                                                margin: '7px',
                                                maxHeight: '150px',
                                                paddingRight: '7px',
                                                background: 'none',
                                                border: 'none',
                                                overflow: 'auto !important'
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.keyCode === 13) {
                                                    if (event.shiftKey) {
                                                        event.stopPropagation();
                                                    } else if (message.length > 0 && message.trim() && message.trim().length > 0) {
                                                        sendMessage()
                                                    } else {
                                                        setMessage("")
                                                    }
                                                }
                                            }
                                            }
                                            autoFocus
                                            placement="top"
                                            forceSuggestionsAboveCursor={true}
                                            autosize={{ minRows: 1, maxRows: 6 }}
                                        >
                                            <Mention
                                                trigger="@"
                                                data={usersSuggestion}
                                                renderSuggestion={(suggestion, search, highlightedDisplay) => {
                                                    let u = db.users[suggestion.id] ? db.users[suggestion.id] : suggestion
                                                    if (suggestion && suggestion.id)
                                                        return (
                                                            <div style={{ display: 'flex', alignItems: 'center', position: "relative" }}>
                                                                <Avatar style={{
                                                                    position: "relative",
                                                                    width: '25px', height: '25px', marginRight: '7px'
                                                                }}
                                                                    // alt={highlightedDisplay}
                                                                    src={suggestion.image ? suggestion.image :
                                                                        db.cards &&
                                                                            props.nodeId &&
                                                                            db.cards[props.nodeId] &&
                                                                            db.cards[props.nodeId]._users &&
                                                                            db.cards[props.nodeId]._users[suggestion.id] &&
                                                                            db.cards[props.nodeId]._users[suggestion.id].image
                                                                            ? db.cards[props.nodeId]._users[suggestion.id].image
                                                                            : db.users[suggestion.id]
                                                                                && db.users[suggestion.id].image
                                                                                ? db.users[suggestion.id].image
                                                                                : null} />
                                                                <div>
                                                                    <div
                                                                    >
                                                                        {highlightedDisplay}
                                                                    </div>
                                                                    {(u && u._parent &&
                                                                        reduxStore.getState().db.users[u._parent] &&
                                                                        reduxStore.getState().db.users[u._parent].name) ?
                                                                        <div
                                                                            style={{ fontSize: 11, color: "lightgray", marginTop: -3 }}
                                                                        >
                                                                            {reduxStore.getState().db.users[u._parent].name}
                                                                        </div>
                                                                        : <React.Fragment></React.Fragment>
                                                                    }
                                                                </div>
                                                                {(u && u._parent &&
                                                                    reduxStore.getState().db.users[u._parent] &&
                                                                    reduxStore.getState().db.users[u._parent].image) ?
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            bottom: 4,
                                                                            right: -7,
                                                                            width: 15,
                                                                            height: 15,
                                                                            borderRadius: "50%",
                                                                            backgroundImage: `url(${reduxStore.getState().db.users[u._parent].image})`,
                                                                            backgroundSize: "cover",
                                                                            backgroundRepeat: "no-repeat"
                                                                        }}></div>
                                                                    : <React.Fragment></React.Fragment>}
                                                            </div>
                                                        )
                                                }
                                                }
                                                style={{
                                                    backgroundColor: customApp('color', 0.5),
                                                    lineHeight: "17px",
                                                    borderRadius: "7px",
                                                    padding: '3px 2px',
                                                    marginLeft: '-4px',
                                                    fontWeight: 'bold',
                                                    maxHeight: 250,
                                                    overflow: "auto"
                                                }}
                                            />
                                        </MentionsInput>

                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                zIndex: 100,
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <CustomIconButton
                                                title={translate('$__emojis', 1)}
                                                icon={onEmojiPicker ? `highlight_off` : `sentiment_very_satisfied`}
                                                color={customApp('medium')}
                                                onClick={() => {
                                                    setOnEmojiPicker(!onEmojiPicker)
                                                }}
                                            />
                                            {!sending &&
                                                <div className={classes.actionButton}>
                                                    <input
                                                        className={"input-text-timeline"}
                                                        style={{
                                                            position: 'absolute',
                                                            marginRight: "3em",
                                                            height: "24px",
                                                            fontSize: "0",
                                                            left: '0px',
                                                            width: "24px",
                                                            cursor: 'pointer',
                                                            border: 'none',
                                                            opacity: '0',
                                                        }} onChange={e =>
                                                            onDropFile(e.target.files)
                                                        }
                                                        multiple
                                                        type="file"
                                                        accept={".png, .jpg, .jpeg, .bpm, .svg, .gif, .pdf, .mp4, .3gp, .ts, .mkv, .avi, .mov, .mts,  m2ts, .mxf, .aac,  aiff, .caf, .ogg, .mp3, .wav, .doc, .docx, .xls, .xlsx, .ppt, .pptx"} />
                                                    <Icon
                                                        icon='attachment'
                                                        color={customApp('medium')}
                                                        size={'22px'}
                                                    />
                                                </div>
                                            }
                                            {(!sending && (message.trim().length > 0 || fileList.length > 0)) &&
                                                <CustomIconButton
                                                    title={translate('$__pressENTERtoSendorClickHere', 1)}
                                                    icon="send"
                                                    color={customApp('menu')}
                                                    onClick={() => sendMessage()}
                                                />
                                            }
                                            {props.closeOption &&
                                                <Icon title={translate('$__pressCRTL+ENTERtoSend', 1)} icon="close" color={customApp('color')} onClick={() => props.onClose()} />
                                            }
                                            {onEmojiPicker &&
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '44px',
                                                    right: '40px',
                                                    zIndex: 100
                                                }}>

                                                    <Picker
                                                        onEmojiClick={onEmojiClick}
                                                        groupVisibility={{
                                                            recently_used: false
                                                        }}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    :
                    <div
                        className={props.relative ? classes.editorRelative : classes.editor}
                        style={{
                            backgroundColor: '#f0f0f0'
                        }}

                    >
                        {translate('$__avaiableForViewingOnly')}
                    </div>
                }
                <div className={fileDropDrag ? classes.onDropFile : classes.dropFile}
                    onDragLeave={() => {
                        !props.disabledSend &&
                            setFileDropDrag(false)
                    }
                    }
                >
                    {translate("$__dropTheFileToAttach", '*')}
                </div>
            </React.Fragment>
        </FileDrop >
    );
}
//REACT
const mapStateToProps = (storie, props) => ({
    draft: storie.chats && storie.chats.drafts ? storie.chats.drafts[props.nodeId] : {}
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Timeline)
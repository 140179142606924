//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import AgileStatus from "components/Agile/status"
import Card from "components/Card"
import DialogButtons from "components/DialogButtons"
import DragDropCards from "components/DragDrop/cards"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import Swot from "components/Agile/swot"

//@MATERIAL
import Hidden from "@material-ui/core/Hidden"

//FUNCTIONS
import { translate, customApp } from "functions/"

//ICONS
import MatrixIcon from "assets/icons/Matrix.js"

function StrategyAndPlansMatrixView(props) {
    const { db } = reduxStore.getState()
    const { dataType } = db
    let cards = db.cards

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0] && hashs[0].length !== 36) {
        hashs[0] = null
    }

    React.useEffect(() => {
        backToMatrix()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let activeCards = {}

    Object.keys(db.cards).filter(a =>
        db.cards[a]
        && !db.cards[a].deleted
        && (
            db.cards[a]._planId === hashs[0]
            || db.cards[a]._parent === hashs[0]
        )
    ).forEach(a => {
        activeCards = {
            ...activeCards,
            [a]: db.cards[a]
        }
    })

    const selectedGuideline = Object.keys(activeCards).filter(a => activeCards[a]._selected === true && activeCards[a].type && activeCards[a].type.indexOf('guideline') > -1 && activeCards[a]._parent === hashs[0])[0] || null
    const selectedObjective = Object.keys(activeCards).filter(a => activeCards[a]._selected === true && activeCards[a].type && activeCards[a].type === 'objective')[0] || null
    const selectedGoal = Object.keys(activeCards).filter(a => activeCards[a]._selected === true && activeCards[a].type && activeCards[a].type === 'goal')[0] || null

    const cardsGuidelineR = Object.keys(activeCards).filter(a => activeCards[a].type === 'guidelineR' && activeCards[a]._parent === hashs[0] && !activeCards[a].deleted).sort((a, b) => {
        if (activeCards[a].order
            && String(activeCards[a].order.low)
            && activeCards[b].order
            && String(activeCards[b].order.low) &&
            parseInt(activeCards[a].order.low) > parseInt(activeCards[b].order.low)
        )
            return 1
        return -1
    })
    const cardsGuidelineC = Object.keys(activeCards).filter(a => activeCards[a].type === 'guidelineC' && activeCards[a]._parent === hashs[0] && !activeCards[a].deleted).sort((a, b) => {
        if (activeCards[a].order
            && String(activeCards[a].order.low)
            && activeCards[b].order
            && String(activeCards[b].order.low) &&
            parseInt(activeCards[a].order.low) > parseInt(activeCards[b].order.low)
        )
            return 1
        return -1
    })
    const cardsGuidelineE = Object.keys(activeCards).filter(a => activeCards[a].type === 'guidelineE' && activeCards[a]._parent === hashs[0] && !activeCards[a].deleted).sort((a, b) => {
        if (activeCards[a].order
            && String(activeCards[a].order.low)
            && activeCards[b].order
            && String(activeCards[b].order.low) &&
            parseInt(activeCards[a].order.low) > parseInt(activeCards[b].order.low)
        )
            return 1
        return -1
    })
    const cardsGuidelineD = Object.keys(activeCards).filter(a => activeCards[a].type === 'guidelineD' && activeCards[a]._parent === hashs[0] && !activeCards[a].deleted).sort((a, b) => {
        if (activeCards[a].order
            && String(activeCards[a].order.low)
            && activeCards[b].order
            && String(activeCards[b].order.low) &&
            parseInt(activeCards[a].order.low) > parseInt(activeCards[b].order.low)
        )
            return 1
        return -1
    })

    const backToMatrix = () => {
        let cards = { ...reduxStore.getState().db.cards }

        Object.keys(activeCards).forEach(a => {
            if (activeCards[a] && activeCards[a]._selected) {
                activeCards[a]._selected = false
            }
        })

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...cards,
                ...activeCards
            }
        })
    }

    let actions
    if (selectedGoal) {
        actions = Object.keys(activeCards).filter(a => activeCards[a]._parent === selectedGoal && !activeCards[a].checklist)
    } else if (selectedObjective) {
        actions = Object.keys(activeCards).filter(a => activeCards[a]._parent === selectedObjective && !activeCards[a].checklist)
    } else if (selectedGuideline) {
        actions = Object.keys(activeCards).filter(a => activeCards[a]._parent === selectedGuideline && !activeCards[a].checklist)
    }

    let swotCards = Object.keys(activeCards).filter(a => activeCards[a]._parent === hashs[0])
    const selectedCard = activeCards
        && Object.keys(activeCards).filter(a => activeCards[a]
            && activeCards[a]._selected && activeCards[a].type
            && activeCards[a].type.indexOf('guideline') > -1)

    return (
        <div style={{
            width: 'inherit',
            padding: 15
        }}>
            {!selectedObjective &&
                <React.Fragment>
                    <Hidden smDown>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                                width: 'calc(50% - 7px)',
                                marginRight: '14px',
                                padding: '7px',
                                background: customApp('ColumnTitleColor'),
                                color: customApp('colorText'),
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginBottom: '15px',
                                textAlign: 'center',
                            }}>{translate('$__internalAspects', 1)}</div>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                                width: 'calc(50% - 7px)',
                                padding: '7px',
                                background: customApp('ColumnTitleColor'),
                                color: customApp('colorText'),
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginBottom: '15px',
                                textAlign: 'center',
                            }}>{translate('$__externalAspects', 1)}</div>
                    </Hidden>
                    <Hidden smUp>
                        <div
                            style={{
                                position: 'relative',
                                float: 'left',
                                width: 'calc(100% - 7px)',
                                padding: '7px',
                                background: customApp('ColumnTitleColor'),
                                color: customApp('colorText'),
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginBottom: '15px',
                                textAlign: 'center',
                            }}>{translate('$__s.W.O.T', '*')}</div>
                    </Hidden>
                    <Swot
                        cards={swotCards.map(a => activeCards[a])}
                        showSelected={Object.keys(activeCards).filter(card => activeCards[card]._selected === true).length > 0 ? true : false}
                        lockColumns={
                            selectedCard.length > 0 && activeCards[selectedCard[0]].type === 'guidelineD' ? ['strength', 'threat']
                                : selectedCard.length > 0 && activeCards[selectedCard[0]].type === 'guidelineR' ? ['strength', 'opportunity']
                                    : selectedCard.length > 0 && activeCards[selectedCard[0]].type === 'guidelineE' ? ['weakness', 'threat']
                                        : selectedCard.length > 0 && activeCards[selectedCard[0]].type === 'guidelineC' ? ['weakness', 'opportunity']
                                            : null
                        }
                        columnStyle={{
                            minWidth: 'calc(25% - 14px)',
                        }}
                        showOrder={true}
                        inLineAdd={true}
                    />
                </React.Fragment>
            }
            <GridContainer >
                <div
                    style={{
                        position: 'relative',
                        float: 'left',
                        width: 'calc(100% - 28px)',
                        marginRight: '14px',
                        marginLeft: '14px',
                        padding: '7px',
                        background: customApp('ColumnTitleColor'),
                        color: customApp('colorText'),
                        fontSize: '16px',
                        fontWeight: 'bold',
                        marginBottom: '15px',
                        textAlign: 'center',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Icon icon={MatrixIcon} color={customApp('textColor')} size={25} /><span style={{ marginLeft: 15 }}>{selectedGuideline ? translate('$__planning', 1) : 'MATRIX SWOT'}</span>
                    </div>
                </div>
                {!selectedGuideline ?
                    <GridItem md={12} >
                        <GridContainer style={{ paddingBottom: '33px' }}>
                            <GridItem md={3} sm={12} xs={12}>
                                <HeaderWithIcon
                                    title={translate('$__guidelineR', 1)}
                                    icon={dataType.filter(a => a.name === 'guidelineR')[0].icon}
                                    color={customApp('colorText')}
                                    style={{
                                        background: customApp('ColumnTitleColor'),
                                        padding: '7px'
                                    }}
                                />
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "7px",
                                    minHeight: "130px",
                                    backgroundColor: '#F9F9F9',
                                    borderRadius: '0 0 7px 7px'
                                }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('menu'),
                                            fontWeight: 'bold',
                                            paddingBottom: '7px',
                                            borderBottom: 'solid 1px #e4e4e4',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineRtypes', 1)}
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('medium'),
                                            fontSize: '14px',
                                            padding: '7px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineRdetails', 1)}
                                    </div>
                                </div>
                                <DragDropCards cards={cardsGuidelineR} cardType={'guidelineR'} />
                            </GridItem>
                            <GridItem md={3} sm={12} xs={12}>
                                <HeaderWithIcon
                                    title={translate('$__guidelineD', 1)}
                                    icon={dataType.filter(a => a.name === 'guidelineD')[0].icon}
                                    color={customApp('colorText')}
                                    style={{
                                        background: customApp('ColumnTitleColor'),
                                        padding: '7px'
                                    }}
                                />
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "7px",
                                    minHeight: "130px",
                                    backgroundColor: '#F9F9F9',
                                    borderRadius: '0 0 7px 7px'
                                }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('menu'),
                                            fontWeight: 'bold',
                                            paddingBottom: '7px',
                                            borderBottom: 'solid 1px #e4e4e4',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineDtypes', 1)}
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('medium'),
                                            fontSize: '14px',
                                            padding: '7px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineDdetails', 1)}
                                    </div>
                                </div>
                                <DragDropCards cards={cardsGuidelineD} cardType={'guidelineD'} />
                            </GridItem>
                            <GridItem md={3} sm={12} xs={12}>
                                <HeaderWithIcon
                                    title={translate('$__guidelineC', 1)}
                                    icon={dataType.filter(a => a.name === 'guidelineC')[0].icon}
                                    color={customApp('colorText')}
                                    style={{
                                        background: customApp('ColumnTitleColor'),
                                        padding: '7px'
                                    }}
                                />
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "7px",
                                    minHeight: "130px",
                                    backgroundColor: '#F9F9F9',
                                    borderRadius: '0 0 7px 7px'
                                }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('menu'),
                                            fontWeight: 'bold',
                                            paddingBottom: '7px',
                                            borderBottom: 'solid 1px #e4e4e4',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineCtypes', 1)}
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('medium'),
                                            fontSize: '14px',
                                            padding: '7px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineCdetails', 1)}
                                    </div>
                                </div>
                                <DragDropCards cards={cardsGuidelineC} cardType={'guidelineC'} />
                            </GridItem>
                            <GridItem md={3} sm={12} xs={12}>
                                <HeaderWithIcon
                                    title={translate('$__guidelineE', 1)}
                                    icon={dataType.filter(a => a.name === 'guidelineE')[0].icon}
                                    color={customApp('colorText')}
                                    style={{
                                        background: customApp('ColumnTitleColor'),
                                        padding: '7px'
                                    }}
                                />
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "7px",
                                    minHeight: "130px",
                                    backgroundColor: '#F9F9F9',
                                    borderRadius: '0 0 7px 7px'
                                }}>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('menu'),
                                            fontWeight: 'bold',
                                            paddingBottom: '7px',
                                            borderBottom: 'solid 1px #e4e4e4',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineEtypes', 1)}
                                    </div>
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '100%',
                                            color: customApp('medium'),
                                            fontSize: '14px',
                                            padding: '7px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {translate('$__guidelineEdetails', 1)}
                                    </div>
                                </div>
                                <DragDropCards cards={cardsGuidelineE} cardType={'guidelineE'} />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    :
                    <React.Fragment>
                        <GridItem md={3} sm={12} xs={12}>
                            <div>
                                <HeaderWithIcon
                                    title={'MATRIX SWOT'}
                                    icon={dataType.filter(a => a.name === 'matrix')[0].icon}
                                    color={customApp('colorText')}
                                    style={{
                                        background: customApp('ColumnTitleColor'),
                                        padding: '7px'
                                    }}
                                />
                                {props.hidePrivate && cards[selectedGuideline] && cards[selectedGuideline].private ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    <Card data={cards[selectedGuideline]} db='cards' selectOption expanded />
                                }
                                <IconButton icon={'undo'} text={translate("$__backToMatrix", "*")}
                                    onClick={() => {
                                        backToMatrix('guideline')
                                    }} />
                            </div>
                            {selectedObjective &&
                                <div>
                                    <HeaderWithIcon
                                        title={translate('$__objective', 1)}
                                        icon={dataType.filter(a => a.name === 'objective')[0].icon}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}

                                    />
                                    {props.hidePrivate && cards[selectedObjective] && cards[selectedObjective].private ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <Card data={cards[selectedObjective]} db='cards' selectOption expanded />
                                    }

                                </div>
                            }
                            {selectedGoal &&
                                <div>
                                    <HeaderWithIcon
                                        title={translate('$__goal', 1)}
                                        icon={dataType.filter(a => a.name === 'goal')[0].icon}
                                        color={customApp('colorText')}
                                        style={{
                                            background: customApp('ColumnTitleColor'),
                                            padding: '7px'
                                        }}
                                        customButtons={[
                                            {
                                                title: "$__new",
                                                icon: "add_box",
                                                color: customApp('menu'),
                                                onClick: () => {
                                                    props.reduxFunction("ASYNC", "SET_MODULE", {
                                                        ...reduxStore.getState().sideModule,
                                                        id: 'new',
                                                        activeModule: dataType.filter(a => a.name === 'goal')[0].iniModule,
                                                        db: 'cards',
                                                        data: {
                                                            type: 'goal',
                                                            _parent: selectedObjective,
                                                            status: 'notStarted'
                                                        }
                                                    })
                                                },
                                            }
                                        ]}
                                    />
                                    {props.hidePrivate && cards[selectedGoal] && cards[selectedGoal].private ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <Card data={cards[selectedGoal]} db='cards' selectOption expanded />
                                    }
                                </div>
                            }
                        </GridItem>
                        <GridItem md={9} sm={12} xs={12}>
                            <HeaderWithIcon
                                title={
                                    selectedObjective && !selectedGoal ?
                                        translate('$__goals', 1)
                                        : !selectedObjective ?
                                            translate('$__objectives', 1)
                                            : translate('$__actions', 1)
                                }
                                icon={
                                    selectedObjective && !selectedGoal ?
                                        dataType.filter(a => a.name === 'goal')[0].icon
                                        : !selectedObjective ?
                                            dataType.filter(a => a.name === 'objective')[0].icon
                                            : 'view_column'
                                }
                                color={customApp('colorText')}
                                style={{
                                    background: customApp('ColumnTitleColor'),
                                    padding: '7px'
                                }}
                                actionButtonsStyle={{
                                    position: "absolute",
                                    top: 0,
                                    bottom: 0,
                                    display: "flex",
                                    itemAligns: "center"
                                }}
                                customButtons={[
                                    selectedGoal ? {
                                        component: (
                                            <DialogButtons
                                                iconColor={customApp("color")}
                                                options={
                                                    dataType.filter(a => a.module === 'agile').map(a => (
                                                        {
                                                            icon: a.icon,
                                                            name: translate(`$__${a.name}`, 1),
                                                            onClick: () => (
                                                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                                                    ...reduxStore.getState().sideModule,
                                                                    id: 'new',
                                                                    db: 'cards',
                                                                    data: {
                                                                        type: a.name,
                                                                        _parent: selectedGoal,
                                                                        status: 'notStarted'
                                                                    }
                                                                })
                                                            )
                                                        }
                                                    )
                                                    )
                                                } />
                                        )
                                    } :
                                        {
                                            title: "$__new",
                                            icon: "add_box",
                                            color: customApp('color'),
                                            actionsSize: "22px",
                                            show: true,
                                            onClick: () => {
                                                props.reduxFunction("ASYNC", "SET_MODULE", {
                                                    ...reduxStore.getState().sideModule,
                                                    id: 'new',
                                                    activeModule: dataType.filter(a => a.name === 'objective')[0].iniModule,
                                                    db: 'cards',
                                                    data: selectedObjective ? {
                                                        type: 'goal',
                                                        _parent: selectedObjective,
                                                        status: 'notStarted'
                                                    } : {
                                                        type: 'objective',
                                                        _parent: selectedGuideline,
                                                        status: 'notStarted'
                                                    }
                                                })
                                            },
                                        }
                                ]}
                            />
                            <AgileStatus cards={actions?.map(a => activeCards[a]) || []} viewButton />
                        </GridItem>
                    </React.Fragment>
                }
            </GridContainer>
        </div>

    )

}
const mapStateToProps = (store, props) => ({
    cards: store.db.cards,
    hidePrivate: store.controls.hidePrivate,
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StrategyAndPlansMatrixView)

import React from 'react'
import Header from 'components/Dashboards/Header'

//REDUX
import { ResponsiveFunnel } from '@nivo/funnel'


const funnelPreview = (props) => {

    const data = [
        {
            "id": "step_sent",
            "value": 6,
            "label": "Sent"
        },
        {
            "id": "step_viewed",
            "value": 5,
            "label": "Viewed"
        },
        {
            "id": "step_clicked",
            "value": 4,
            "label": "Clicked"
        },
        {
            "id": "step_add_to_card",
            "value": 3,
            "label": "Add To Card"
        },
        {
            "id": "step_purchased",
            "value": 2,
            "label": "Purchased"
        },
        {
            "id": "step_purchased2",
            "value": 1,
            "label": "Purchased"
        }
    ]


    return (
        <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
            <Header styles={{}} tittle={props.title} icon={props.icon} datafilter={true} />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{}} >
            </div>
            <div style={{ position: 'relative', flex: '1 1 auto', fontSize: '16px' }}>
                <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter' }}>
                    <ResponsiveFunnel
                        data={data}
                        direction="horizontal"
                        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                        shapeBlending={0.46}
                        spacing={3}
                        valueFormat=" > .0s"
                        colors={{ scheme: 'reds' }}
                        borderWidth={0}
                        labelColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    3
                                ]
                            ]
                        }}
                        beforeSeparatorLength={10}
                        beforeSeparatorOffset={20}
                        afterSeparatorLength={10}
                        afterSeparatorOffset={20}
                        currentPartSizeExtension={10}
                        enableLabel={false}
                        motionConfig="wobbly"
                    />
                </div>
            </div>
        </div >
    )
}

export default funnelPreview
import React from 'react'

//COMPONENTS 
import CustomButton from "components/Buttons/custom"
import HeaderWithMenu from "components/Header/card"
import Icon from 'components/Icon'
import QuickUsers from "components/User/quick"
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"


//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"
import history from 'store/history'

//FUNCTIONS
import { translate, customApp, getAppAccess } from "functions"
import { getUserAccess } from "functions/cards"

const AboutPlan = ({
    reduxFunction,
    cards,
    styles,
    preview,
    edit,
    handleRemoveObject,
    id
}) => {
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { session } = reduxStore.getState()
    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(hashs[0])

    return (
        <div style={{ width: "100%", height: '100%', overflow: 'hidden' }}>
            {!preview && <UiCard style={{ marginTop: '0', height: '100%' }}>
                {edit ?
                    <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', ...styles }}>
                        <Icon size={24} style={{ alignSelf: 'center', marginLeft: '12.5px', color: styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)', ...styles }}>blur_on</Icon>
                        <p style={{ margin: '0px', marginLeft: '7.5px', alignSelf: 'center', fontWeight: 'bold', marginTop: '1px', color: styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)', ...styles }}>{translate(`$__planResume`, 1)}</p>
                        <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                            <Icon onClick={() => handleRemoveObject(id)} style={{ height: '25px', color: styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                close
                            </Icon>
                        </div>
                        <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                            onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                            <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                open_with
                            </Icon>
                        </div>
                    </div> : <HeaderWithMenu
                        title={translate(`$__planResume`, 1)}
                        icon={'blur_on'}
                        color={styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)'}
                        style={{
                            background: styles.backgroundColor ? styles.backgroundColor : 'white',
                            padding: '12.8px',
                            borderRadius: '5px 5px 0px 0px ',
                            fontWeight: 'normal',
                            borderBottom: '1px solid #D3D3D3'
                        }}
                        moreButton
                        optionsProps={{ transparent: true, btColor: styles.color ? styles.color : 'rgba(0, 0, 0, 0.67)' }}
                        options={((CardAccess && parseInt(CardAccess) > 3) || AppAccess.owner || AppAccess.planAdmin || AppAccess.planEdit || AppAccess.planModerate) ? [
                            {
                                name: "$__edit",
                                icon: "edit",
                                onClick: () => {
                                    reduxFunction("ASYNC", "SET_MODULE", {
                                        ...reduxStore.getState().sideModule,
                                        db: 'cards',
                                        id: hashs[0],
                                        module: "cardEdit",
                                        activeModule: 'cardEdit',
                                        data: cards[hashs[0]],
                                    });
                                },
                            }
                        ] : []}
                        actionButtonsStyle={{
                            top: '-9px !important'
                        }}

                    />
                }
                <UiCardBody>
                    <div >
                        <div style={{ width: '100%', color: customApp('menu') }}><span style={{ fontWeight: 'bold' }}>{translate('$__name', 1)}:</span> {cards[hashs[0]]?.name}</div>
                        <div style={{ width: '100%', color: customApp('menu') }}><span style={{ fontWeight: 'bold' }}>{translate('$__description', 1)}:</span> {cards[hashs[0]]?.description}</div>
                        {!cards[hashs[0]]?.isTemplate && cards[hashs[0]] && cards[hashs[0]]?._users &&
                            <React.Fragment>
                                <div style={{ width: '100%', marginTop: '15px', color: customApp('menu'), fontWeight: 'bold' }}>{translate('$__users', '*')}:</div>
                                <QuickUsers _id={hashs[0]} noLimit avatarSize="33px"
                                    permission={true}
                                />
                            </React.Fragment>
                        }
                    </div>
                    {(session.account === "7806912b-4c7d-4f4f-b9e0-b93fc8f08776") ?
                        <CustomButton
                            color={customApp('menu')}
                            icon={'monitoring'}
                            onClick={() => {
                                history.push(`sp#${hashs[0]}/statistics`)
                            }}
                            size={'15px'}
                            title={`${translate("$__tagsStatistics")}`}
                            text={`${translate("$__tagsStatistics")}`}
                        />
                        : <></>}
                </UiCardBody>
            </UiCard>}
        </div>
    )
}

const mapStateToProps = (store) => ({
    cards: store.db.cards
}
)

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(AboutPlan);

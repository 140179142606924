//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import Files from "components/Files/list"
import Menu from "./menu"
import Timeline from "componentsV3/Timeline"
import Typing from "./Typing"
import UserBadge from "components/Badge/user"
import Message from "componentsV3/Timeline/message"
// import Call from "componentsV3/Call"


//@MATERIAL
import { withStyles } from "@material-ui/core/styles"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import Tooltip from "@material-ui/core/Tooltip"

//FUNCTIONS
import {
    customApp,
    getAppAccess,
    getDate,
    translate,
    appAlert,
} from "functions/"

import {
    loadUsers
} from "functions/users"

import {
    loadTimeline,
    timelineComment,
} from "functions/chat"

import NotificationAudio from "assets/sound/notification.mp3"
import MentionNotificationAudio from "assets/sound/mentionNotification.mp3"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/chat.js"
import ButtonViewOnline from "./ButtonViewOnline"

const audio = new Audio(NotificationAudio)
const audioTask = new Audio(MentionNotificationAudio)



function ChatIndex(props) {
    const { classes, nodeId, chatOpen } = props
    const { socket } = reduxStore.getState().functions
    const [expanded, setExpanded] = React.useState(false)
    const [showFiles, setShowFiles] = React.useState(false)
    const [onLine, setOnLine] = React.useState(false)


    const mounted = React.useRef(true)
    const socketOn = React.useRef(false)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const AppAccess = getAppAccess()

    React.useEffect(() => {
        const ini = async () => {
            const { session } = reduxStore.getState()
            socket.on(`timeline.COMMENT`, async data => {
                if (
                    data.db === "cards"
                    && data.comment.message.indexOf(session._id) > -1
                    && data.comment.user._id !== session._id
                ) audioTask.play()

                if (
                    (
                        data.db === 'users'
                        && data.idRel === reduxStore.getState().session._id
                        && data.comment
                        && data.comment.type === "comment"
                        && data.comment.user
                        && data.comment.user._id
                        && !reduxStore.getState().db.users[data.comment.user._id]
                    )
                ) loadUsers(props, data.comment.user._id)

                if (
                    (
                        data.db === 'users'
                        || (
                            data.db !== 'users'
                            && data.comment.type === "comment"
                            && reduxStore.getState().db.cards[data.idRel]
                            && reduxStore.getState().db.cards[data.idRel].type === 'chatGroup'
                        )
                    )
                ) {
                    let lastDate = null
                    if (data.filesCount && data.files && data.files.length === data.filesCount)
                        lastDate = parseInt(data.comment.created_at) - 30

                    if (data.comment.user._id !== reduxStore.getState().session._id)
                        verifyMessages(lastDate)
                }

                timelineComment(props, data)

                if (
                    reduxStore.getState().chat.notifications
                    && (
                        data.comment.idRel === reduxStore.getState().session._id
                        ||
                        reduxStore.getState().db.users[data.comment.idRel]
                        ||
                        (
                            reduxStore.getState().db.cards[data.comment.idRel]
                            && reduxStore.getState().db.cards[data.comment.idRel].type === 'chatGroup'
                            && reduxStore.getState().db.cards[data.comment.idRel]._users[reduxStore.getState().session._id]
                        )
                    )
                    && reduxStore.getState().db.users[data.comment.user._id]
                    && data.comment.type === "comment"
                ) {
                    if (data?.comment?.user?._id !== reduxStore.getState().session._id) {
                        audio.play()
                    }
                    data.comment.nodeId = data.node
                    let comment = {
                        ...data.comment,
                        nodeId: data.comment._parent,
                        idRel: data.node
                    }

                    if (data.comment.idRel === reduxStore.getState().session._id && !reduxStore.getState().chat.open) {
                        const notification = new Notification(`${data.comment.user.name} ${translate(`$__sendedANewMessage`, 1)}`, {
                            body: data.comment.message ? data.comment.message : translate(`$__${data.comment.type}`, 1)
                        })

                        notification.onclick = (e) => {
                            e.preventDefault()
                            window.focus()
                            notification.close()
                        }
                        appAlert({
                            message: translate(`$__newMessage`, 1),
                            content: (
                                <div
                                    style={{ display: 'flex', cursor: 'pointer', backgroundColor: "#none" }}
                                    onClick={(e) => {
                                        openChat(comment.user._id, 'users')
                                        e.stopPropagation()
                                    }
                                    }
                                >
                                    <Message
                                        storie={comment}
                                        noReactions
                                        inverted
                                    />
                                </div>
                            ),
                            hideiconvariant: true,
                            variant: 'info',
                            persist: false,
                            horizontal: 'right',
                            confirm: null
                        })
                    }
                }
            })
            //TIMELINE
            let timelineTyping = {}

            socket.on(`timeline.TYPING`, data => {
                // console.log('timeline.TYPING::710', data)
                if (timelineTyping[data.typingUser])
                    clearTimeout(timelineTyping[data.typingUser])


                // if (
                //   reduxStore.getState().chat
                //   && reduxStore.getState().chat.typing
                //   && (
                //     !reduxStore.getState().chat.typing[data.group ? data.group : "me"]
                //     || (
                //       reduxStore.getState().chat.typing[data.group ? data.group : "me"]
                //       &&
                //       !reduxStore.getState().chat.typing[data.group ? data.group : "me"][data.typingUser]
                //     )
                //   )
                // )
                // props.reduxFunction("ASYNC", "SET_CHAT", {
                //   ...reduxStore.getState().chat,
                //   // typing: {
                //   //   ...reduxStore.getState().chat.typing,
                //   //   [data.group ? data.group : "me"]: {
                //   //     ...reduxStore.getState().chat.typing[data.group ? data.group : "me"],
                //   //     [data.typingUser]: true
                //   //   }
                //   // }
                // })

                timelineTyping = {
                    ...timelineTyping,
                    [data.typingUser]: setTimeout(() => {
                        // props.reduxFunction("ASYNC", "SET_CHAT", {
                        //   ...reduxStore.getState().chat,
                        //   // typing: {
                        //   //   ...reduxStore.getState().chat.typing,
                        //   //   [data.group ? data.group : "me"]: {
                        //   //     ...reduxStore.getState().chat.typing[data.group ? data.group : "me"],
                        //   //     [data.typingUser]: false
                        //   //   }
                        //   // }
                        // })
                    }, 3000)
                }
            })

            socket.on('stream', (v) => {
                if (document.getElementById("#streamVideo")) {
                    let streamVideo = document.getElementById("#streamVideo")
                    streamVideo.attr('src', v);
                }
            })

            socket.on(`timeline.DEL`, data => {
                const { session } = reduxStore.getState()
                let idRel = reduxStore.getState().timeline[data.idRel] &&
                    reduxStore.getState().timeline[data.idRel][data.id] ?
                    data.idRel :
                    session._id

                if (
                    reduxStore.getState().timeline
                    && reduxStore.getState().timeline[idRel]
                    && reduxStore.getState().timeline[idRel][data.id]
                )
                    props.reduxFunction("ASYNC", "SET_TIMELINE", {
                        ...reduxStore.getState().timeline,
                        [idRel]: {
                            ...reduxStore.getState().timeline[idRel],
                            [data.id]: {
                                ...reduxStore.getState().timeline[idRel][data.id],
                                deleted: true,
                                ...data.deletedReason ? { deletedReason: data.deletedReason } : {}
                            }
                        }
                    })
            })
        }
        if (!socketOn.current && socket && socket.connected) {
            socketOn.current = true
            ini()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    const verifyMessages = (lastDate = null) => {
        loadTimeline(
            {
                ...props,
                fnIdentification: "Layouts/Index/loadData()-Users"
            },
            {
                ids: [reduxStore.getState().session._id],
                db: "users",
                type: "comment",
                newUpdates: true,
                lastDate
            }
        )
    }

    const openChat = async (nodeId, dbProps) => {
        props.reduxFunction("ASYNC", "SET_CHAT", {
            ...reduxStore.getState().chat,
            open: true,
            db: dbProps,
            nodeId: nodeId,
            loading: false,
        })
    }

    if (!chatOpen)
        return (<React.Fragment></React.Fragment>)

    const closeChat = (force = false) => {
        if (force || !(reduxStore.getState() &&
            (
                (
                    reduxStore.getState().lightBox &&
                    reduxStore.getState().lightBox.open
                )
                || (
                    reduxStore.getState().sideModule &&
                    reduxStore.getState().sideModule.id
                )
            )
        )) {
            let toastFy = document.getElementsByClassName('Toastify__toast').length
            let forward = document.getElementsByClassName('forwardMessage').length
            if (toastFy === 0 && forward === 0)
                props.reduxFunction("ASYNC", "SET_CHAT", {
                    ...reduxStore.getState().chat,
                    open: false
                })
        }
    }


    if (!chatOpen)
        return (<React.Fragment></React.Fragment>)

    const chatDb = nodeId && reduxStore.getState().db.cards && reduxStore.getState().db.cards[nodeId] ? 'cards' : 'users'
    let chatData = reduxStore.getState().db[chatDb][nodeId] ? reduxStore.getState().db[chatDb][nodeId] : null

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3000,
                backgroundColor: "rgba(250,250,250,0.7)",
                backdropFilter: "blur(3px)",
            }}
        >
            <ClickAwayListener
                onClickAway={() => {
                    closeChat()
                }}
            >
                <div
                    style={{
                        position: "fixed",
                        ...window.innerWidth > 600 ? {
                            top: 77,
                            left: 77,
                            right: 77,
                            bottom: 77,
                        } : {
                            top: 50,
                            left: 7,
                            right: 7,
                            bottom: 7,
                        },
                        ...window.innerWidth > 600 ? {
                            zIndex: 3,
                        } : {
                            zIndex: 6000,
                        },
                        boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
                        borderRadius: "15px",
                        overflow: 'hidden'
                    }}
                >
                    <React.Fragment>
                        <div style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            height: 40,
                            background: customApp('ColumnTitleColor'),
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <div style={{
                                position: "absolute",
                                left: 13
                            }}>
                                <CustomButton
                                    id={`chat_menu`}
                                    title={translate(`$__menu`, "*")}
                                    color={customApp("colorText")}
                                    icon={"menu"}
                                    onClick={() => {
                                        setExpanded(a => !a)
                                        if (expanded)
                                            setOnLine(false)
                                    }}
                                    size={"25px"}
                                    transparent
                                />
                            </div>
                            {expanded ?
                                <div style={{
                                    position: "absolute",
                                    left: 45,
                                    color: customApp("colorText")
                                }}>
                                    <CustomButton
                                        id={`addGroup`}
                                        title={translate(`$__createChatGroup`, "*")}
                                        icon={"add_circle_outline"}
                                        onClick={() => {
                                            closeChat(true)
                                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                                ...reduxStore.getState().sideModule,
                                                id: "new",
                                                activeModule: "cardEdit",
                                                db: "cards",
                                                data: {
                                                    type: "chatGroup",
                                                    status: "inProgress",
                                                }
                                            })
                                        }}
                                        size={"25px"}
                                        transparent
                                        color={customApp("colorText")}
                                    />
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                            {expanded ?
                                <div style={{
                                    position: "absolute",
                                    left: 80
                                }}>
                                    <ButtonViewOnline
                                        onClick={(e) => {
                                            setOnLine(e)
                                        }}
                                    />
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                            <div style={{
                                position: "absolute",
                                left: !expanded ? 53 : 320,
                                right: 80,
                                color: customApp('colorText'),
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>

                                {nodeId && chatData ?
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <UserBadge
                                            overlap="circular"
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "right",
                                            }}
                                            userId={nodeId}
                                        >
                                            <Avatar
                                                alt={chatData.displayName ? chatData.displayName : chatData.name}
                                                src={chatData.image ? `${chatData.image}` : null}
                                                style={{
                                                    width: "30px",
                                                    height: "30px"
                                                }} />
                                        </UserBadge>
                                        <div
                                            style={{ fontSize: "14px" }}
                                        >
                                            {chatData.name} <span style={{ fontSize: 10, fontWeight: "normal" }}>{reduxStore.getState().usersStatus && reduxStore.getState().usersStatus.users && reduxStore.getState().usersStatus.users[nodeId] && reduxStore.getState().usersStatus.users[nodeId].status === "away" && reduxStore.getState().usersStatus.users[nodeId].updatedAt ? `(${translate("$__lastSeeTime")}: ${getDate(reduxStore.getState().usersStatus.users[nodeId].updatedAt)})` : ``}</span>
                                        </div>
                                        {
                                            nodeId &&
                                            reduxStore.getState().db.cards[nodeId] &&
                                            reduxStore.getState().db.cards[nodeId].type === 'chatGroup' &&
                                            reduxStore.getState().db.cards[nodeId]._users &&
                                            Object.keys(reduxStore.getState().db.cards[nodeId]._users).length > 0 &&
                                            <div
                                                classes={classes.avatarGroup}
                                                style={{
                                                    marginLeft: 15
                                                }}>
                                                <AvatarGroup
                                                    max={150}
                                                >
                                                    {Object.keys(reduxStore.getState().db.cards[nodeId]._users).length > 0
                                                        && Object.keys(reduxStore.getState().db.cards[nodeId]._users)
                                                            .filter((a, i) => i < 18)
                                                            .map((a, i) => {
                                                                let name = ``
                                                                if (reduxStore.getState().db.cards[nodeId]._users[a].name) {
                                                                    name = reduxStore.getState().db.cards[nodeId]._users[a].name
                                                                } else if (reduxStore.getState().db.cards[nodeId]._users[a].name) {
                                                                    let splitName = reduxStore.getState().db.cards[nodeId]._users[a].name.split(' ')
                                                                    name = `${splitName[0]}${splitName.length > 1 ? ` ${splitName[splitName.length - 1]}` : ``}`
                                                                }
                                                                if (i < 17) {
                                                                    return (
                                                                        <Tooltip
                                                                            title={name}
                                                                            key={i}
                                                                        >
                                                                            <Avatar style={{
                                                                                width: 22,
                                                                                height: 22,
                                                                                border: 'none'
                                                                            }} alt={name} src={reduxStore.getState().db.cards[nodeId]._users[a].image || null} >
                                                                                {name.substr(0, 1)}
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    )
                                                                } else if (i === 17) {
                                                                    let count = Object.keys(reduxStore.getState().db.cards[nodeId]._users).length - 17
                                                                    return (
                                                                        <Tooltip
                                                                            title={(
                                                                                <ul style={{ listingStyle: "none", padding: 0 }}>
                                                                                    {Object.keys(reduxStore.getState().db.cards[nodeId]._users)
                                                                                        .filter((aa, aai) => aai > 16 &&
                                                                                            reduxStore.getState().db.cards[nodeId]._users[aa] &&
                                                                                            reduxStore.getState().db.cards[nodeId]._users[aa].name)
                                                                                        .map((aa, aai) => <li>{reduxStore.getState().db.cards[nodeId]._users[aa].name}</li>)
                                                                                    }
                                                                                </ul>
                                                                            )}
                                                                            key={i}
                                                                        >
                                                                            <Avatar style={{
                                                                                width: 22,
                                                                                height: 22,
                                                                                border: 'none'
                                                                            }} alt={`+${count}`} >
                                                                                <span style={{ fontSize: 10 }}>{`+${count}`}</span>
                                                                            </Avatar>
                                                                        </Tooltip>
                                                                    )
                                                                }
                                                                return <></>
                                                            }
                                                            )}
                                                </AvatarGroup>
                                            </div>
                                        }
                                        <Typing nodeId={nodeId} />
                                    </div>
                                    :
                                    <div>
                                        {translate("$__chats", 1)}
                                    </div>
                                }
                            </div>
                            <div style={{
                                position: "absolute",
                                right: 5,
                                display: "flex"
                            }}>
                                {nodeId ?
                                    <React.Fragment>
                                        {/* {reduxStore.getState().session._id === "701bbca1-e1e4-4ea0-ada8-30f74ae74894" ?
                                            <Call nodeId={nodeId} />
                                            : <></>} */}
                                        <CustomButton
                                            id={`chat_files`}
                                            title={translate(`$__files`, "*")}
                                            color={customApp("colorText")}
                                            icon={"folder_special"}
                                            onClick={() => {
                                                setShowFiles(prev => !prev)
                                            }}
                                            size={"25px"}
                                            transparent
                                        />
                                        {/* <CustomButton
                                            id={`chat_search`}
                                            title={translate(`$__search`, "*")}
                                            color={customApp("lightgray")}
                                            icon={"search"}
                                            onClick={() => {
                                                // setShowFiles(prev => !prev)
                                            }}
                                            size={"25px"}
                                            transparent
                                        /> */}
                                        {/* <Search
                                            db={reduxStore.getState().db.cards[nodeId] ? "cards" : "users"}
                                            nodeId={nodeId}
                                            onAction={(e) => {
                                                // goToMessage(e._id, true)
                                            }}
                                        /> */}
                                    </React.Fragment>
                                    : <React.Fragment></React.Fragment>
                                }
                                <CustomButton
                                    id={`chat_settings`}
                                    title={translate(!reduxStore.getState().chat.notifications ? `$__chatNotificationsOff` : `$__chatNotificationsActive`, 1)}
                                    color={customApp("colorText")}
                                    icon={!reduxStore.getState().chat.notifications ? `notifications_off` : `notifications_active`}
                                    onClick={() => {
                                        props.reduxFunction("ASYNC", "SET_CHAT", {
                                            ...reduxStore.getState().chat,
                                            notifications: !reduxStore.getState().chat.notifications,
                                        })
                                    }}
                                    size={"25px"}
                                    transparent
                                />
                                {nodeId &&
                                    reduxStore.getState().db.cards[nodeId] &&
                                    reduxStore.getState().db.cards[nodeId].type === 'chatGroup' &&
                                    reduxStore.getState().db.cards[nodeId]._users &&
                                    reduxStore.getState().db.cards[nodeId]._users[reduxStore.getState().session._id] &&
                                    (
                                        parseInt(reduxStore.getState().db.cards[nodeId]._users[reduxStore.getState().session._id].access) > 4
                                        ||
                                        (
                                            AppAccess &&
                                            (
                                                AppAccess.user &&
                                                AppAccess.plan &&
                                                AppAccess.userAdmin &&
                                                AppAccess.planAdmin
                                            )
                                        )
                                    ) &&
                                    <CustomButton
                                        id={`chat_settings`}
                                        title={translate(`$__preffers`, "*")}
                                        color={customApp("colorText")}
                                        icon={"settings"}
                                        onClick={() => {
                                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                                ...reduxStore.getState().sideModule,
                                                id: nodeId,
                                                activeModule: 'cardEdit',
                                                db: 'cards',
                                                data: {
                                                    ...reduxStore.getState().db.cards[nodeId] ? reduxStore.getState().db.cards[nodeId] : {}
                                                }
                                            })
                                            closeChat(true)
                                        }}
                                        size={"25px"}
                                        transparent
                                    />
                                }
                                <CustomButton
                                    id={`chat615_closeButton`}
                                    title={translate(`$__close`, "*")}
                                    color={customApp("colorText")}
                                    icon={"close"}
                                    onClick={() => {
                                        closeChat(true)
                                        setOnLine(false)
                                    }}
                                    size={"25px"}
                                    transparent
                                />
                            </div>
                        </div>
                        <div
                            style={
                                {
                                    position: "absolute",
                                    top: 40,
                                    left: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(246,246,246,1)",
                                    borderRight: `solid 4px ${customApp("color")}`,
                                    padding: "7px",
                                    ...!expanded ? {
                                        width: "60px",
                                    } : {
                                        width: "311px",
                                    }
                                }
                            }
                        >
                            <Menu
                                closeButton={() => {
                                    props.reduxFunction("ASYNC", "SET_CHAT", {
                                        ...reduxStore.getState().chat,
                                        open: false
                                    })
                                    setOnLine(false)
                                }}
                                chat
                                subMenuExpanded={(a) => {
                                    setExpanded(a)
                                    setOnLine(false)
                                }}
                                expanded={expanded}
                                onLine={onLine}
                            />
                        </div>

                        <div style={{
                            position: "absolute",
                            left: expanded ? 312 : 55,
                            top: 40,
                            right: showFiles && nodeId ? 333 : 0,
                            bottom: 0,
                            backgroundColor: "white"
                        }}
                        >
                            {
                                nodeId ?
                                    <Timeline
                                        types={["comment"]}
                                        nodeId={nodeId}
                                        db={reduxStore.getState().db.cards[nodeId] ? 'cards' : 'users'}
                                        confirmRead={true}
                                        // loading={chat.loading ? true : false}
                                        hideCards
                                        chat={true}
                                    />
                                    : <React.Fragment></React.Fragment>
                            }
                        </div>
                        {showFiles && nodeId ?
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 40,
                                    right: 0,
                                    bottom: 0,
                                    width: '333px',
                                    backgroundColor: '#f1f3f4',
                                    zIndex: 100,
                                    boxShadow: "-4px 0px 7px 3px rgba(0,0,0,0.1)"
                                }}
                            >
                                <Files nodeId={nodeId} dark={false} />
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </React.Fragment>
                    {/* <div style={{
                        position: "absolute",
                        top: "100px",
                        left: "30%",
                        right: "30%",
                        bottom: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <WebcamCapture />
                    </div> */}
                </div>
            </ClickAwayListener >
        </div >
    )
}
const mapStateToProps = ({ chat }) => ({
    nodeId: chat.nodeId,
    chatOpen: chat.open,
    notifications: chat.notifications
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChatIndex))
import React, { useState } from "react";

//DEPENDENCIES
import CardNotes from "./CardNotes";
import ListNotes from "./ListNotes";

//COMPONENTS
import MoreVert from "components/MoreMenu/"
import {
  Icon,
  Badge,
  ClickAwayListener,
} from "@material-ui/core";

//FUNCTIONS
import { translate } from "functions"

const Notes = () => {
  const [notes, setNotes] = useState([
    {
      id: 1,
      data: null,
      color: 'white',
      edit: false,
      cursor: "auto",
      changeColor: false,
      hidden: false,
      date: null,
      tags: [],
      x: 0,
      y: 70,
    }
  ]);
  const [menuNotes, setMenuNotes] = useState({
    options: false,
    view: false,
    list: false,
  })

  const addNote = (index) => {
    const nextNumber = notes.length + 1;
    const newNumber = findNextAvailableNumber(nextNumber);


    const newNote = {
      id: newNumber,
      data: null,
      color: 'white',
      edit: false,
      cursor: 'auto',
      changeColor: false,
      hidden: false,
      date: null,
      tags: [],
      x: 0,
      y: 70,
    };

    setNotes(prevNotes => [...prevNotes, newNote]);
  };

  const isAvailable = (id, sumNumber) => !notes.some(({ id: noteId }) => noteId === id || noteId === sumNumber);

  const findNextAvailableNumber = (startNumber) => {
    let sumNumber = startNumber;

    while (!isAvailable(startNumber, sumNumber)) {
      sumNumber++;
    }

    return sumNumber;
  };

  const handleChangeHidden = (hidden, index) => {
    updateNoteProperty('hidden', hidden, index);
  };
  const updateNoteProperty = (property, value, index) => {
    const updatedNotes = notes.map((note, noteIndex) =>
      noteIndex === index ? { ...note, [property]: value } : note
    );
    setNotes(updatedNotes);
  };
  const viewAll = (hidden) => {
    const updatedNotes = notes.map((note) =>
      ({ ...note, hidden: hidden })
    );
    setNotes(updatedNotes);
  };
  const handleClickView = () => {
    let view;
    if (notes.some((note) => note.hidden === true)) {
      view = true;
    } else {
      view = false;
    }
    setMenuNotes({ ...menuNotes, options: !menuNotes.options, view: view })
  }

  return (
    <div
      id="notesBt"
      style={{
        zIndex: '100 !important'
      }}
    >
      <div id="top_moreOptions">
        <Badge
          overlap="rectangular"
          // badgeContent={reduxStore.getState().controls.unlockedPrivates}
          color="secondary"
        >
          <MoreVert
            transparent
            count={50}
            size={"27px"}
            icon={"sticky_note_2"}
            title={translate("$__notes")}
            btColor={"orange"}
            options={[
              {
                name: "$__addNote",
                icon: "note_add",
                onClick: () => {
                  setMenuNotes({ ...menuNotes, view: true })
                  addNote()

                }
              },
              {
                name: "$__showNotes",
                icon: "preview",
                onClick: () => {
                  setMenuNotes({ ...menuNotes, view: true })
                  viewAll(true)
                }
              },
              {
                name: '$__openNotesList',
                icon: 'stick_note',
                onClick: () => {
                  setMenuNotes({ ...menuNotes, view: true, list: true });
                }
              }
            ]
            }
          />
        </Badge>
      </div>
      {menuNotes.view ?
        <ClickAwayListener
          onClickAway={() => {
            setMenuNotes({ ...menuNotes, list: false });
          }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "20px",
              backgroundColor: "rgba(250,250,250,0.7)",
              backdropFilter: "blur(3px)",
              zIndex: 1000
            }}
          >
            <ListNotes
              notes={notes}
              setNotes={setNotes}
              addNote={addNote}
              handleChangeHidden={handleChangeHidden}
              menuNotes={menuNotes}
            />
            {notes.filter((note) => note.hidden === false).map((note, index) => (
              <CardNotes addNote={addNote} notes={notes} setNotes={setNotes} note={note} id={note.id} key={index} />
            ))}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              position: 'absolute',
              bottom: '0',
              right: '0',
              margin: '20px',
              width: '100px',
              height: '100px',
              zIndex: '999',
            }}>
              {menuNotes.options ? <div style={{
                display: 'flex',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <div style={{
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                  color: 'rgb(0, 49, 112)',
                  padding: '10px',
                  display: 'flex',
                  height: 'fit-content',
                  boxShadow: "0 0px 5px 0 rgba(0, 0, 0, 0.15)",
                  cursor: 'pointer',
                }}
                  onClick={() => { setMenuNotes({ ...menuNotes, view: !menuNotes.view }); viewAll(!menuNotes.view) }}>
                  <Icon>{menuNotes.view ? 'visibility' : 'visibility_off'}</Icon>
                </div>
                <div style={{
                  backgroundColor: '#fff',
                  borderRadius: '50%',
                  color: 'rgb(0, 49, 112)',
                  padding: '10px',
                  display: 'flex',
                  height: 'fit-content',
                  boxShadow: "0 0px 5px 0 rgba(0, 0, 0, 0.15)",
                  cursor: 'pointer'
                }}
                  onClick={() => setMenuNotes({ ...menuNotes, list: !menuNotes.list })}>
                  <Icon>{!menuNotes.list ? 'reorder' : 'playlist_remove'}</Icon>
                </div>
              </div> : null}
              <div style={{
                backgroundColor: 'rgb(0, 49, 112)',
                borderRadius: '50%',
                color: '#fff',
                padding: '10px',
                display: 'flex',
                height: 'fit-content',
                boxShadow: "0 0px 5px 0 rgba(0, 0, 0, 0.15)",
                cursor: 'pointer',
              }}
                onClick={handleClickView}>
                <Icon>note</Icon>
              </div>
            </div>
          </div>
        </ClickAwayListener>
        : <></>
      }
    </div>

  );
};

export default Notes;

import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//DEPENDENCIES
import SendingMessage from "./sending"
import Message from "./message.jsx";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/timelineMiniStyle";

const Timeline = (props) => {
  const { classes } = props;
  const { db, session, functions, timeline } = reduxStore.getState();
  const { socket } = functions;
  const [stories, setStories] = React.useState(props.stories || {})

  let files = []; //USED IN LIGHTBOX;

  React.useEffect(() => {
    ini()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.stories, props.nodeId, JSON.stringify(timeline)])

  const ini = () => {
    let userStories = {}
    if (timeline[session._id])
      Object.keys(timeline[session._id])
        .filter(a =>
          timeline[session._id][a]
          && timeline[session._id][a].user
          && timeline[session._id][a].user._id
          && timeline[session._id][a].user._id === props.nodeId
        ).forEach(a => {
          userStories = {
            ...userStories,
            [a]: timeline[session._id][a]
          }
        })
    if (timeline[props.nodeId]) {
      Object.keys(timeline[props.nodeId])
        .forEach(a => {
          userStories = {
            ...userStories,
            [a]: timeline[props.nodeId][a]
          }
        })
    }
    setStories(userStories)
  }


  const openLightBox = (f) => {
    props.reduxFunction("ASYNC", "LIGHTBOX", {
      open: true,
      files: files,
      file: f
    })
  }

  const readMessages = async () => {
    socket.emit("data", {
      module: "chat",
      method: "put",
      action: "read"
    }, {
      readedBy: session._id,
      id: props.nodeId,
      db: db.cards[props.nodeId] ? 'cards' : 'users',
      type: props.type ? props.type : "comment"
    })
  }

  return (
    <div style={{ position: "relative", float: "left", width: "100%", marginBottom: '15px' }}>
      <ul className={classes.timelineSimple}
        id={`tms_${props.nodeId}`}
        style={{ zIndex: 1 }}
      >
        {stories
          && Object.keys(stories).length > 0
          && Object.keys(stories).sort((a, b) => {
            if (parseInt(stories[a].created_at) < parseInt(stories[b].created_at)) {
              return -1
            } else if (parseInt(stories[a].created_at) > parseInt(stories[b].created_at)) {
              return 1
            } else {
              return 0
            }
          }
          ).forEach((id, key) => {
            let storie = stories[id]
            if (!props.showUnReaded && key > Object.keys(stories).length - 4) {
              if (storie.files && Object.keys(storie.files).length > 0)
                Object.keys(storie.files).forEach((id, i) => {
                  let file = storie.files[id]
                  files.push(file)
                })
              return (
                <li
                  className={classes.item}
                  key={id}
                  style={{
                  }}>
                  <Message
                    openLightBox={(e) => { openLightBox(e) }}
                    storie={storie}
                    reply={storie.reply ? stories[storie.reply] : null}
                    hideCards={props.hideCards ? true : false}
                    db={props.db}
                    nodeId={props.nodeId}
                  />
                </li>
              );
            } else if (
              props.showUnReaded
              && storie
              && storie.user
              && storie.user._id !== session._id
              && (
                !storie.readedBy
                ||
                (
                  storie.readedBy
                  && !storie.readedBy[session._id]
                )
              )
            ) {
              if (storie.files && Object.keys(storie.files).length > 0)
                Object.keys(storie.files).forEach((id, i) => {
                  let file = storie.files[id]
                  files.push(file)
                })
              return (
                <li
                  className={classes.item}
                  key={id}
                  style={{
                  }}>
                  <Message
                    openLightBox={(e) => { openLightBox(e) }}
                    storie={storie}
                    reply={storie.reply ? stories[storie.reply] : null}
                    hideCards={props.hideCards ? true : false}
                    db={props.db}
                    nodeId={props.nodeId}
                  />
                </li>
              );
            }
          })}
        <div style={{ marginBottom: '15px', clear: 'both' }}></div>
      </ul>
      {/* {Object.keys(stories).filter(a =>
        stories[a]
        && stories[a].user
        && stories[a].user._id
        && stories[a].user._id !== session._id
        && (
          !stories[a].readedBy
          ||
          (
            stories[a].readedBy
            && !stories[a].readedBy[session._id]
          )
        )
      ).length > 0 &&
        <div
          style={{
            // position: 'absolute',
            // bottom: '6px',
            // left: '-36px',
            // zIndex: 100
          }}>
          <Badge
            badgeContent={Object.keys(stories).filter(a =>
              stories[a]
              && stories[a].user
              && stories[a].user._id
              && stories[a].user._id !== session._id
              && (
                !stories[a].readedBy
                ||
                (
                  stories[a].readedBy
                  && !stories[a].readedBy[session._id]
                )
              )
            ).length}
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant={"standard"}
          >
            <Icon onClick={
              () => {
                readMessages()
              }
            }>mark_chat_read</Icon>
          </Badge>
        </div>
      } */}
      <SendingMessage nodeId={props.nodeId} onSending={() => { readMessages() }} />
    </div >
  );
}
//REACT
const mapStateToProps = (store, ownProps) => ({
  store, ownProps
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Timeline));
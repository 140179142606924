const initialState = {
    module: null,
    activeModule: 'cardEdit',
    reqSave: false,
    window: false, //popup || expanded || false
    id: null,
    data: {},
}

const sideModule = (state = initialState, data) => {
    if (data.action === 'SET_MODULE' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_MODULE') {
        return {
            ...initialState
        }
    }
    else {
        return state
    }
};

export default sideModule;
import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

const buttonStyle = {
  button: {
    ...defaultFont,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    // width: 'fit-content',
    userSelect: "none",
    "&$reverse": {},
  },
  buttonText: {
    ...defaultFont,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: "7px",
    cursor: "pointer",
    width: 'fit-content',
    userSelect: "none",
    "&$reverse": {},
    "& :hover": {
      //backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  reverse: {
    flexDirection: "row-reverse",
  },
  iconButton: {
    width: "21px",
    height: "21px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10%",
  },
  icon: {
    fontSize: "21px"
  },
  onlyIcon: {
    fontSize: "21px"
  },
  text: {
    marginLeft: "7px",
    marginRight: "7px"
  }
};

export default buttonStyle;

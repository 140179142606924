//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import Buttom from "components/CustomButtons/Custom"
import CircularProgress from "components/Progress/circular"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import TextField from "components/TextField/"
import Icon from "components/Icon"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import InputAdornment from '@material-ui/core/InputAdornment'

//FUNCTIONS
import { translate, customApp, appAlert, deleteNode } from "functions/"
import { deleteUser } from "functions/users"

import apims from "apims"
import api from "api"

function Component(props) {
    const { sideModule, db } = reduxStore.getState()
    const [migrateComplete, setMigrateComplete] = React.useState(false)
    const [Load, setLoading] = React.useState(false)
    const [SelectedUser, setSelectedUser] = React.useState(null)
    const [countCards, setCountCards] = React.useState(0)
    const [findNameUser, setFindNameUser] = React.useState("")
    const data = sideModule.data || {}

    React.useEffect(() => {
        if (data.type === 'user') {
            loadUserCards()
        } else {
            setLoading(false)
        }
        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const deleteThis = async () => {
        appAlert({
            message: translate("$__confirmDeleteThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                deleteUser(data._id)
                deleteNode(props, { _id: data._id }, false)
                props.reduxFunction("ASYNC", "CLEAR_MODULE");
            }
        })
    }


    const loadUserCards = async () => {
        setLoading(true)
        const countChildrens = await apims.post("/Card_Count", { id: data._id, all: true, rel: "INN", node: "User" })
        if (countChildrens) {
            setLoading(false)
            setCountCards(countChildrens.data)
        }

    }

    const migrate = async () => {
        setLoading(true)

        try {
            let response = await api.post("/user/migrateActivities", {
                from: data._id,
                fromData: data,
                to: [SelectedUser],
                toData: db.users[SelectedUser]
            })
            if (response) {
                setMigrateComplete(true)
                let cards = { ...db.cards }
                Object.keys(cards).filter(a =>
                    cards[a]._users && cards[a]._users[data._id]
                ).forEach(a => {
                    if (!cards[a]._users[SelectedUser])
                        cards[a]._users = {
                            ...cards[a]._users,
                            [SelectedUser]: {
                                access: cards[a]._users[data._id].access,
                                _id: SelectedUser
                            }
                        }
                    delete cards[a]._users[data._id]
                })
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setTimeout(() => {
                migrate()
            }, 3000)
        }

    }

    return (
        <div style={{ width: '100%' }}>
            <SideModuleCloseAndSave
                close={() => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE");
                }}
            />

            {/* DELETE CLIENT */}
            {data._id && db.users[data._id] &&
                (
                    db.users[data._id].type === 'client' ||
                    db.users[data._id].type === 'group' ||
                    db.users[data._id].type === 'sic' ||
                    db.users[data._id].type === 'unity'
                ) &&
                <fieldset style={{ textAlign: "center" }}>
                    <legend style={{ color: customApp("color") }}>{translate("$__deleteOptions", 1)}</legend>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {Object.keys(db.users).filter(a => db.users[a]._parent === data._id).length > 0 ?
                            translate(`$__delete${db.users[data._id].type}WithUsersDescription`, 1)
                            :
                            translate(`$__delete${db.users[data._id].type}NoUsersDescription`, 1)
                        }
                    </div>
                    <div style={{ textAlign: "center", width: "100%" }}>
                        {translate("$__deleteThisDescription", 1)}
                    </div>
                    <Buttom onClick={() => { deleteThis() }} >{translate("$__deleteThis", 1)}</Buttom>
                </fieldset>
            }

            {/* DELETE USER */}
            {data._id && db.users[data._id] &&
                db.users[data._id].type === 'user' &&
                <React.Fragment>
                    {!Load ?
                        <React.Fragment>
                            {countCards > 0 && !migrateComplete ?
                                <React.Fragment>
                                    <fieldset>
                                        <legend style={{ color: customApp("color") }}>{translate("$__thisUserHas", 1)}</legend>
                                        <div style={{
                                            position: "relative",
                                            marginTop: "22px",
                                            bottom: "7px",
                                            width: "100%",
                                            fontSize: "12px",
                                            color: customApp('mediumText'),
                                            display: "flex",
                                            justifyContent: "center",
                                            textAlign: "center"
                                        }}>
                                            {countCards} {translate("$__items")}
                                            <br></br>
                                            {translate("$__deleteCountCardsExplain")}
                                        </div>
                                    </fieldset>
                                    {SelectedUser ?
                                        <fieldset>
                                            <legend>{translate('$__selectedUserToMigrationCards')}</legend>
                                            <div
                                                style={{
                                                    cursor: 'pointer',
                                                    marginBottom: '7px',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                onClick={() => {
                                                    setSelectedUser(null)
                                                }}
                                            >
                                                <Avatar alt={db.users[SelectedUser].name} src={db.users[SelectedUser].image || null}
                                                    style={{ marginRight: '15px' }}
                                                >
                                                    {db.users[SelectedUser].name.substr(0, 1)}
                                                </Avatar>
                                                <span>{db.users[SelectedUser].name}</span>
                                            </div>
                                            <Buttom onClick={() => {
                                                appAlert({
                                                    message: translate('$__confirmUserTransferData', 1),
                                                    autoClose: 50000,
                                                    buttons: [
                                                        {
                                                            shadow: true,
                                                            title: translate("$__confirm"),
                                                            text: translate("$__confirm"),
                                                            color: "green",
                                                            icon: "done_all",
                                                            size: "25px",
                                                            style: { marginLeft: 15 },
                                                            onClick: () => {
                                                                migrate()
                                                            },
                                                        },
                                                        {
                                                            shadow: true,
                                                            title: translate("$__cancel"),
                                                            text: translate("$__cancel"),
                                                            color: "red",
                                                            icon: "close",
                                                            size: "25px",
                                                            style: { marginLeft: 15 },
                                                            onClick: () => {

                                                            },
                                                        }],
                                                    variant: 'warning',
                                                    persist: false,
                                                    horizontal: 'right',
                                                })

                                            }} >{translate("$__migrate", 1)}</Buttom>
                                        </fieldset>
                                        :
                                        <fieldset
                                            style={{ marginTop: 7, marginBottom: 7 }}
                                        >
                                            <legend>{translate('$__chooseAUserToTransferCardsBeforeDeletingUser')}</legend>
                                            <TextField
                                                label={translate('$__search', '*')}
                                                variant={'standard'}
                                                autoFocus
                                                value={findNameUser}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <Icon color={customApp('color')}>search</Icon>
                                                        </InputAdornment>
                                                    ),
                                                    onChange: e => {
                                                        setFindNameUser(e.target.value)
                                                    },
                                                }}
                                            />
                                            {Object.keys(db.users)
                                                .filter(a =>
                                                    // a === a
                                                    a !== data._id
                                                    && db.users[a].type === 'user'
                                                    && !db.users[a].deleted
                                                    && (
                                                        (!data._parent
                                                            &&
                                                            !db.users[a]._parent
                                                        )
                                                        || (
                                                            data._parent
                                                            && db.users[a]._parent === data._parent
                                                        )
                                                    )
                                                    && (
                                                        !findNameUser || (
                                                            findNameUser && db.users[a].name.toLowerCase().indexOf(findNameUser.toLowerCase().trim()) > -1
                                                        )
                                                    )
                                                )
                                                .sort((a, b) => {
                                                    var nameA = db.users[a].name.toLowerCase()
                                                    var nameB = db.users[b].name.toLowerCase()
                                                    if (nameA < nameB)
                                                        return -1
                                                    if (nameA > nameB)
                                                        return 1
                                                    return 0
                                                })
                                                .map(pr =>
                                                    <div
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginBottom: '7px',
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}
                                                        onClick={() => {
                                                            setSelectedUser(pr)
                                                        }}
                                                        key={pr}
                                                    >
                                                        <Avatar alt={db.users[pr].name} src={db.users[pr].image || null}
                                                            style={{ marginRight: '15px' }}
                                                        >
                                                            {db.users[pr].name.substr(0, 1)}
                                                        </Avatar>
                                                        <span>{db.users[pr].name}</span>
                                                    </div>
                                                )}
                                        </fieldset>
                                    }
                                    <fieldset style={{ textAlign: "center" }} >
                                        <legend style={{ color: customApp("color") }}>{translate("$__deactivateThis", 1)}</legend>
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            {translate("$__deactivateWhithoutMigration", 1)}
                                        </div>
                                        <Buttom onClick={() => { deleteThis() }} >{translate("$__deactivateThis", 1)}</Buttom>
                                    </fieldset>
                                </React.Fragment>
                                :
                                <fieldset style={{ textAlign: "center" }} >
                                    <legend style={{ color: customApp("color") }}>{translate("$__deactivateThis", 1)}</legend>
                                    <div style={{ textAlign: "center", width: "100%" }}>
                                        {translate("$__deactivateUserDescription", 1)}
                                    </div>
                                    <Buttom onClick={() => { deleteThis() }} >{translate("$__deactivateThis", 1)}</Buttom>
                                </fieldset>
                            }

                        </React.Fragment>
                        :
                        <React.Fragment></React.Fragment>
                    }
                    {Load ?
                        <div style={{
                            position: 'relative',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '33px'
                        }}>
                            <CircularProgress />
                        </div>
                        : <React.Fragment></React.Fragment>
                    }
                </React.Fragment>
            }
        </div >
    )
}
const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

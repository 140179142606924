//FUNCTIONS
import { customApp } from "functions";

const headerStyle = theme => ({
  btClose: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    right: '0',
    top: '-64px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
  },
  image: {
    width: '100%',
    backgroundColor: 'rgb(0,88,0)',
    paddingTop: '45%'
  },
  data: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    overflowY: 'auto',
    marginTop: '10px',
    padding: '10px'
  },
  switch: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: customApp('medium')
  }
});

export default headerStyle;

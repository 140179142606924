import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle";

import { customApp } from "functions";

const useStyles = makeStyles(styles);

export default function AuthNavbar(props) {
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <Toolbar >
        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <div style={{ maxWidth: "222px" }}>
            <img alt="MACHEN_IMAGE" src={customApp("customLogo")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};

import React, { useEffect, useState, useRef } from 'react';

//COMPONENTS
import Header from 'components/Dashboards/Header'
import Legend from '../Legend';
import { ResponsivePie } from '@nivo/pie'
import "./Loader.css"
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck';

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import moment from "moment-timezone"
import { translate } from "functions"


const ObjetiveDough = (props) => {

  const { tittle, preview, icon } = props
  const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
  let hashs = window.location.hash.split("/")
  hashs[0] = hashs[0].replace('#', '')
  const { db } = reduxStore.getState()
  const [data, setData] = useState([])
  const keysLabel = useRef([])
  const [dashFilterChart, setDashFilterChart] = useState({})


  useEffect(() => {

    if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
      setDashFilterChart({
        ...props?.dashboardConfig?.filter,
        colorsArr: props.dashboardConfig?.colorsArr
      })
    }
  }, [props.dashboardConfig]);

  useEffect(() => {
    let newData = [
      ...db.status.map((st) => {
        return {
          id: st.label.substring(3) === 'await' ? translate('$__awaitAction') : translate(st.label),
          label: st.label.substring(3) === 'await' ? '$__awaitAction' : st.label,
          value: Object.keys(db.cards).filter(fil => {
            let lastDateCard =
              db.cards[fil].completed_at?.low
              || db.cards[fil].completed_at
              || db.cards[fil].updated_at?.low
              || db.cards[fil].updated_at
              || db.cards[fil].created_at?.low
              || db.cards[fil].created_at

            const dateCard = moment(lastDateCard * 1000);
            const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
            const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

            return db.cards[fil]._planId === hashs[0]
              && !db.cards[fil].checklist
              && !db.cards[fil].deleted
              && dateCard.isBetween(startDate, endDate, null, '[]')
              && db.cards[fil].type === 'objective'
              && db.cards[fil].status && db.cards[fil].status === st.value
          }).length,
          color: st.color
        }
      }),
      {
        id: translate('$__late'),
        label: '$__late',
        value: Object.keys(db.cards).filter(fil => {
          let lastDateCard =
            db.cards[fil].completed_at?.low
            || db.cards[fil].completed_at
            || db.cards[fil].updated_at?.low
            || db.cards[fil].updated_at
            || db.cards[fil].created_at?.low
            || db.cards[fil].created_at

          const dateCard = moment(lastDateCard * 1000);
          const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
          const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

          return db.cards[fil]._planId === hashs[0]
            && !db.cards[fil].checklist
            && dateCard.isBetween(startDate, endDate, null, '[]')
            && !db.cards[fil].deleted
            && db.cards[fil].status !== 'completed'
            && db.cards[fil].type === 'objective'
            && db.cards[fil].endDate && moment(new Date(db.cards[fil].endDate * 1000)).tz('America/Sao_Paulo').format() <= moment(new Date(today)).tz('America/Sao_Paulo').format()
        }).length,
        color: 'red'
      }
    ]
    newData = newData.map((item) => {
      if (props.filter.colorsArr) {
        let newColor = ''
        props.filter.colorsArr.forEach((colorObj) => {
          if (item.label.substring(3) === colorObj.label)
            newColor = colorObj.color
        })
        return {
          ...item,
          color: newColor
        }
      }
      else return item
    })
    newData.forEach((item) => {
      const newLabel = {
        label: item.label.substring(3),
        color: item.color
      };
      if (!keysLabel.current.some(item => item.label === newLabel.label)) {
        keysLabel.current = [...keysLabel.current, newLabel];
      }
    })

    setData(newData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.planCards, props.filter, dashFilterChart]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <ViewportChecker>
        {(inViewport) => (
          <div className='box' style={{ width: '100%', height: '100%', overflow: 'hidden', alignSelf: 'center', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexFlow: 'column' }}>
            <Header styles={props.styles} tittle={translate(`$__${tittle}`)} icon={icon} edit={props.edit} id={props.id} handleRemoveObject={(id) => props.handleRemoveObject(id)} />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{ position: 'relative', flex: '1 1 auto', backgroundColor: '#f5f5f5' }}>
              <div className='componentCircle' style={{ width: '100%', height: '100%', position: 'absolute', }}>
                {(!props.load && inViewport) ? <ResponsivePie
                  data={data}
                  colors={{ datum: 'data.color' }}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  innerRadius={0}
                  padAngle={1.7}
                  cornerRadius={3}
                  activeOuterRadiusOffset={8}
                  borderWidth={1}
                  theme={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    legend: false,
                    background: 'transparent'
                  }}
                  arcLinkLabelsStraightLength={0}
                  borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                  enableArcLinkLabels={false}
                  arcLinkLabelsSkipAngle={10}
                  arcLinkLabelsTextColor="#333333"
                  arcLinkLabelsThickness={0}
                  arcLinkLabelsColor={{ from: 'color' }}
                  arcLabelsSkipAngle={10}
                  arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                      [
                        'brighter',
                        3
                      ]
                    ]
                  }}
                /> : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                  <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                </div>}
              </div>
            </div>
            {!preview &&
              <div style={{ height: '15%', maxHeight: '60px', display: 'flex', backgroundColor: 'white' }}>
                <Legend
                  legend={[...keysLabel.current.map((a, i) => {
                    return {
                      key: i,
                      color: a.color,
                      label: a.label
                    }
                  })]}
                  customColors={props.filter.colorsArr}
                />
              </div>
            }
          </div>
        )}
      </ViewportChecker>
    </div>
  )
}

export default ObjetiveDough;

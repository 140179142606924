// "use strict";

const _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

const _react = _interopRequireDefault(require("react"));

const _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

const _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
  fill: "none",
  d: `M5.9,12.6C5.9,12.6,5.8,12.6,5.9,12.6l-0.1-0.1c0,0,0,0,0,0l0,0c0,0-0.1,0-0.1,0H3.1c0,0-0.1,0-0.1,0l-0.1,0
			c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0.1l8.8,8.8v-1.2l-7.4-7.4h1.3l6.1,6.1v-1.2L5.9,12.6L5.9,12.6z M2.9,12.7
			L2.9,12.7L2.9,12.7L2.9,12.7z M2.8,13L2.8,13L2.8,13L2.8,13z M2.9,13.1L2.9,13.1L2.9,13.1L2.9,13.1z`
}), _react.default.createElement("path", {
  d: `M5.9,12.6C5.9,12.6,5.8,12.6,5.9,12.6l-0.1-0.1c0,0,0,0,0,0l0,0c0,0-0.1,0-0.1,0H3.1c0,0-0.1,0-0.1,0l-0.1,0
			c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0.1l8.8,8.8v-1.2l-7.4-7.4h1.3l6.1,6.1v-1.2L5.9,12.6L5.9,12.6z M2.9,12.7
			L2.9,12.7L2.9,12.7L2.9,12.7z M2.8,13L2.8,13L2.8,13L2.8,13z M2.9,13.1L2.9,13.1L2.9,13.1L2.9,13.1z`
}), _react.default.createElement("path", {
  d: `M18.9,13C18.9,13,18.9,13,18.9,13c0-0.1,0-0.1,0-0.2l-0.1-0.2c0,0,0,0,0,0v0l-0.1,0c0,0,0,0-0.1-0.1l0,0
			c0,0,0,0,0,0l0,0c0,0-0.1,0-0.1,0H16c0,0-0.1,0-0.1,0l0,0c0,0,0,0,0,0l-0.1,0c0,0,0,0,0,0l-3.2,3.2l0,0v1.2l3.7-3.7h1.2l-4.8,4.8
			l0,0v1.2l6.2-6.2l0,0v0c0,0,0,0,0,0l0,0l-0.1-0.1L18.9,13L18.9,13z M18.7,12.8L18.7,12.8L18.7,12.8L18.7,12.8z`
}), _react.default.createElement("path", {
  d: `M18.1,11.3L18.1,11.3c0.1,0,0.1,0.1,0.1,0.1l0,0c0,0,0,0,0,0l0,0c0,0,0.1,0,0.1,0h2.5c0,0,0.1,0,0.1,0
			l0.2-0.1c0,0,0,0,0.1,0l0,0c0,0,0,0,0-0.1l0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0-0.1c0,0,0,0,0-0.1c0,0,0,0,0-0.1L12.5,2v1.2
			l7.4,7.4h-1.3l-6.1-6.1v1.2L18.1,11.3L18.1,11.3z M18.3,11.3L18.3,11.3L18.3,11.3L18.3,11.3z`
}), _react.default.createElement("path", {
  d: `M12.9,11.5H16c0,0,0.1,0,0.1,0l0.1-0.1c0,0,0,0,0,0l0.1,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0
			c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0-0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l-3.8-3.9l0,4.1C12.5,11.3,12.7,11.5,12.9,11.5z`
}), _react.default.createElement("path", {
  d: `M5.2,10.8C5.2,10.8,5.2,10.8,5.2,10.8C5.2,10.8,5.2,10.9,5.2,10.8l-0.1,0.2L5.3,11l-0.2,0c0,0,0,0.1,0,0.1
			c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0.1l0,0c0,0,0,0,0,0l0.1-0.1h0l0,0.1l0.1-0.1l0,0.1c0,0,0,0,0.1,0
			c0,0,0.1,0,0.1,0H8c0,0,0.1,0,0.1,0l0,0c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l3.2-3.2V6.9l-3.7,3.7H6.6l4.9-4.9V4.5L5.2,10.8L5.2,10.8z
			 M5.3,11.1L5.3,11.1L5.3,11.1L5.3,11.1z`
}), _react.default.createElement("path", {
  d: `M11.1,12.5H8c0,0-0.1,0-0.1,0l-0.1,0c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0.1l0,0.1l0.1,0.1l-0.2,0
			l0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0.1,0.1c0,0,0,0,0.1,0.1l3.8,3.8v-4.1C11.5,12.7,11.3,12.5,11.1,12.5z M7.8,12.8
			C7.8,12.8,7.8,12.8,7.8,12.8L7.8,12.8L7.8,12.8z M7.7,12.9L7.7,12.9L7.7,12.9L7.7,12.9z`
}), _react.default.createElement("path", {
  d: `M21.3,12.6L21.3,12.6c-0.2-0.2-0.4-0.2-0.6,0l-8.1,8.1l0,0V22l8.8-8.8c0.1-0.1,0.1-0.2,0.1-0.3
			C21.4,12.8,21.3,12.7,21.3,12.6z`
}), _react.default.createElement("path", {
  d: `M2.7,11.4L2.7,11.4c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l8.2-8.2V2l-8.8,8.8
			C2.6,10.9,2.6,11.2,2.7,11.4z`
})), 'Matrix');

exports.default = _default;

//@MATERIAL 
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from "@material-ui/core/styles";

//FUNCTIONS
import { customApp } from "functions/";

const Component = withStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        color: customApp('color'),
    }
})(Checkbox);

export default Component
import React from 'react';

//@MATERIAL
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

//COMPONENTS
import IconButton from "components/CustomButtons/IconButton";
import Icon from "components/Icon";

//FUNCTIONS
import { translate, customApp } from "functions/";

function SimpleDialog(props) {
    const { open, onClose } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            {props && props.title &&
                <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
            }
            <List>
                {props.options.map((option, index) => {
                    return (
                        <ListItem
                            button
                            onClick={() => {
                                if (option.onClick)
                                    option.onClick()
                                onClose()
                            }}
                            key={index}>
                            <ListItemAvatar>
                                <Icon icon={option.icon} />
                            </ListItemAvatar>
                            <ListItemText primary={translate(option.name, 1)} />
                        </ListItem>
                    )
                })
                }
            </List>
        </Dialog>
    );
}

export default function Component(props) {
    const [open, setOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
        setSelectedValue(value);
    };

    return (
        <React.Fragment>
            <IconButton
                text={props.text ? props.text : null}
                icon={props.icon ? props.icon : "add_box"}
                size={props.iconSize ? props.iconSize : null}
                iconColor={props.iconColor ? props.iconColor : customApp('medium')}
                onClick={handleClickOpen}
            />
            <SimpleDialog selectedValue={selectedValue} open={open} onClose={() => { handleClose() }} options={props.options} />
        </React.Fragment>
    );
}
//CORE
import React from 'react'

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';

function Component(props) {
    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 115px)'
        }}>
            {props.provisorio || 'Não Autorizado'}
        </div>
    )
}
const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip"

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        // maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: '7px',
        boxShadow: '0 0 3px 3px rgba(0,0,0,0.03)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))(Tooltip);

export default HtmlTooltip
import React from "react";

//FUNTIONS
import { customApp } from "functions/";


export default function PercentualBar(props) {
    return (
        <div style={{
            position: "relative",
            height: 5,
            backgroundColor: "lightgray",
            width: "100%",
            overflow: "hidden",
            borderRadius: 3,
            boxShadow: "0 0 0 2px rgba(0,0,0,0.05)"
        }}>
            <div style={{
                position: "absolute",
                top: 1,
                left: 1,
                bottom: 1,
                width: `calc(${props.value ? props.value : 0}% - 2px)`,
                overflow: "hidden",
                background: `${props.background ? props.background : customApp('ColumnTitleColor')}`,
                borderRadius: 3,
                boxShadow: "0 0 0 2px rgba(0,0,0,0.05)"
            }}>
            </div>
        </div>
    );
}

//CORE
import React from "react"

import CustomButton from "components/Buttons/custom"

//REDUX
import * as reduxActions from "store/actions"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"

//FUNCTIONS
import { customApp } from "functions/"
import { countNotifications } from "functions/cards"

const useStyles = makeStyles((theme) => ({
    iconButtonStyle: {
        margin: 0,
        marginTop: "-8px",
        backgroundColor: "#FFFFFF",
        "&:hover": {
            backgroundColor: "rgba(250,250,250,0.9)"
        }
    },
}))

function Component(props) {
    const classes = useStyles()

    let notifications = countNotifications(props.nodeId)

    if (notifications.total < 1)
        return (<React.Fragment></React.Fragment>)

    return (
        <div id="notificationsBt"
            style={{
                top: 0,
                zIndex: 0
            }}
        >
            <CustomButton
                className={classes.iconButtonStyle}
                badgeContent={notifications.total}
                color={customApp("menu")}
                icon={notifications.total === 0 ? "notifications_none" : "notifications_active"}
                size={"17px"}
                transparent
            />
        </div>
    )
}

//REACT
const mapStateToProps = (store, props) => {
    return ({
        cards: store.db.cards,
        timeline: store.timeline
    })
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Component)

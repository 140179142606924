import React from "react";
import PropTypes from "prop-types"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"


//COMPONENTS
import CustomButton from "components/Buttons/custom"
import RenderOptions from "./RenderOptions"

//STYLES
import "assets/css/components/react-datepicker.css"

//FUNCTIONS
import { customApp, translate } from "functions/"


function FilterComponent(props) {
    const [viewSearch, setviewSearch] = React.useState(false);
    const [searchValues, setSearch] = React.useState({ ...props.indicators })


    const mounted = React.useRef(true)
    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        if (props.onChange) {
            props.onChange({
                ...searchValues || {}
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValues]);


    return (
        <React.Fragment>
            <div
                style={{
                    position: 'relative', width: '100%', padding: '0px', marginBottom: '15px',
                }}
            >
                {props.fields?.map((ff, ffi) => <div key={`${ff}_${ffi}`} style={{ position: "relative" }}>
                    <RenderOptions
                        field={ff}
                        indicators={searchValues}
                        onChange={props.onChange ? (appliedFilters) => {
                            if (mounted.current)
                                setSearch(appliedFilters)
                        } : null} /></div>)}

                {/* FINAL FILTER PARAMETERS */}
                {JSON.stringify(searchValues) !== initialIndicators ?
                    <div
                        style={{
                            padding: '5px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                        onClick={() => { setviewSearch(!viewSearch) }}
                    >

                    </div> : <></>}
                {props?.preffers?.searchButton ?
                    <CustomButton
                        color={customApp('menu')}
                        icon={'search'}
                        onClick={() => {
                            if (props.onSearch)
                                props.onSearch()
                        }}
                        size={'15px'}
                        title={`${translate("$__search")}`}
                        text={`${translate("$__search")}`}
                    />
                    : <></>
                }
            </div>
        </React.Fragment>
    );
}

const initialIndicators = JSON.stringify({
    status: {
        all: true,
        notStarted: true,
        awaitAction: true,
        paused: true,
        inProgress: true,
        completed: true,
    },
    priority: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    risk: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    complexity: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
    impact: {
        all: true,
        'null': true,
        normal: true,
        low: true,
        medium: true,
        high: true,
    },
})

const mapStateToProps = (store) => ({
    searchs: store.searchs
})

FilterComponent.propTypes = {
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
import React from 'react'

//REDUX
import reduxStore from "store/"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import { translate } from 'functions';

const endTour = (props) => {

    const modalStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'block',
        zIndex: 9999
    };

    const contentStyle = {
        backgroundColor: '#fff',
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        margin: '15% auto',
        padding: '20px',
        border: '1px solid #888',
        width: '80%',
        TextAlign: "center",
        borderRadius: "5px",
        maxWidth: '400px'
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#2ea44f',
        color: '#fff',
        borderRadius: '5px',
        border: 'none',
        marginTop: '30px',
        cursor: 'pointer',
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    };

    const headingStyle = {
        display: "flex",
        fontSize: '23px',
        fontWeight: 'bold',
        justifyContent: "center"
    };


    const handleEndtour = () => {
        props.reduxFunction("ASYNC", "SET_SESSION", {
            ...reduxStore.getState().session,
            disabledTour: true
        });
    }

    return (
        <div style={modalStyle}>
            <div style={contentStyle}>
                <h2 style={headingStyle}>{translate("$__tourTitleIntro")}</h2>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <p style={{ width: "320px", fontWeight: "bolder", textAlign: "center", fontSize: "17px" }}>
                        {translate("$__endTourDesc")}
                    </p>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        onClick={() => {
                            handleEndtour();
                            props.handleEnd();
                        }}
                        style={buttonStyle}
                    >{translate("$__letsStart")}
                    </button>
                </div>
            </div>
        </div >
    );
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(endTour) 
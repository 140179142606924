import React, { useEffect, useState } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { MessageEditor } from "components/MessageEditor/MessageEditor";
import Icon from "components/Icon";
import Icons from "components/Icons";
import CustomButton from "components/Buttons/custom";

//FUNCTIONS
import api from "api/";
import { translate } from "functions";

const Message = (props) => {
  const [editorData, setEditorData] = useState([]);
  const [data, setData] = useState({});
  const [icon, setIcon] = useState("add_reaction");
  const { session } = reduxStore.getState();
  const [iniData, setIniData] = useState();

  useEffect(() => {
    if (!session.GlobalData.messages) {
      let history = EditorState.createEmpty(),
        vocation = EditorState.createEmpty(),
        legacy = EditorState.createEmpty(),
        message = EditorState.createEmpty(),
        strategicStatement = EditorState.createEmpty(),
        values = EditorState.createEmpty();

      if (session.GlobalData.history) {
        history = htmlToDraft(`<div>${session.GlobalData.history}</div>`);
        history = ContentState.createFromBlockArray(history.contentBlocks);
        history = EditorState.createWithContent(history);
      }

      if (session.GlobalData.strategicStatement) {
        strategicStatement = htmlToDraft(
          `<div>${session.GlobalData.strategicStatement}</div>`
        );
        strategicStatement = ContentState.createFromBlockArray(
          strategicStatement.contentBlocks
        );
        strategicStatement = EditorState.createWithContent(strategicStatement);
      }

      if (session.GlobalData.values) {
        values = htmlToDraft(`<div>${session.GlobalData.values}</div>`);
        values = ContentState.createFromBlockArray(values.contentBlocks);
        values = EditorState.createWithContent(values);
      }

      if (session.GlobalData.vocation) {
        vocation = htmlToDraft(`<div>${session.GlobalData.vocation}</div>`);
        vocation = ContentState.createFromBlockArray(vocation.contentBlocks);
        vocation = EditorState.createWithContent(vocation);
      }

      if (session.GlobalData.legacy) {
        legacy = htmlToDraft(`<div>${session.GlobalData.legacy}</div>`);
        legacy = ContentState.createFromBlockArray(legacy.contentBlocks);
        legacy = EditorState.createWithContent(legacy);
      }
      if (session.GlobalData.message) {
        message = htmlToDraft(`<div>${session.GlobalData.message}</div>`);
        message = ContentState.createFromBlockArray(message.contentBlocks);
        message = EditorState.createWithContent(message);
      }

      const init = [
        {
          messageD: history,
          id: 1,
          title: translate("$__history"),
          icon: "menu_book",
          message: session.GlobalData.history,
        },
        {
          messageD: vocation,
          id: 2,
          title: translate("$__vocation"),
          icon: "spoke",
          message: session.GlobalData.vocation,
        },
        {
          messageD: legacy,
          id: 3,
          title: translate("$__legacy"),
          icon: "subtitles",
          message: session.GlobalData.legacy,
        },
        {
          messageD: message,
          id: 4,
          title: translate("$__presidentMessage"),
          icon: "account_box",
          message: session.GlobalData.message,
        },
        {
          messageD: values,
          id: 5,
          title: translate("$__values"),
          icon: "join_inner",
          message: session.GlobalData.values,
        },
        {
          messageD: strategicStatement,
          id: 6,
          title: translate("$__strategicStatement"),
          icon: "history_edu",
          message: session.GlobalData.strategicStatement,
        },
      ];
      setIniData(init)
      setEditorData(init);
    } else {
      setEditorData([...session.GlobalData.messages]);
      setIniData([...session.GlobalData.messages]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUniqueNumber = () => {
    const nextNumber = editorData.length + 1;
    if (editorData.some((data) => data.id === nextNumber)) {
      let newNumber = nextNumber + 1;
      while (editorData.some((data) => data.id === nextNumber)) {
        newNumber++;
      }
      setEditorData([
        {
          messageD: null,
          id: newNumber,
          title: null,
          message: null,
          icon: "add_reaction",
        },
        ...editorData,
      ]);
    } else {
      setEditorData([
        {
          messageD: null,
          id: nextNumber,
          title: null,
          message: null,
          icon: "add_reaction",
        },
        ...editorData,
      ]);
    }
  };
  const changeData = (message, messageD, id) => {
    const updatedData = editorData.map((data) =>
      data.id === id ? { ...data, messageD: messageD, message: message } : data
    );
    setEditorData(updatedData);
  };
  const changeTitle = (title, id) => {
    const updatedData = editorData.map((data) =>
      data.id === id ? { ...data, title: title } : data
    );
    setEditorData(updatedData);
  };
  const changeIcon = (icon, id) => {
    const updatedData = editorData.map((data) =>
      data.id === id ? { ...data, icon: icon } : data
    );
    setEditorData(updatedData);
  };
  const deleteData = (message) => {
    const updatedData = editorData
      .filter((data) => data !== message)
      .map((data) => data);
    setEditorData(updatedData);
  };

  const saveData = async () => {
    const messages = { messages: editorData };
    setIniData(editorData)
    props.reduxFunction("ASYNC", "SET_SESSION", {
      ...reduxStore.getState().session,
      GlobalData: {
        ...reduxStore.getState().session.GlobalData,
        ...messages,
      },
    });
  };
  return (
    <>
      <div style={{ width: "fit-content", marginBottom: "10px" }}>
        <div
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            backgroundColor: "white",
            color: "#b0b0b0",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            padding: "5px 10px",
            borderRadius: "5px",
            cursor: "pointer",
            margin: "0 auto",
          }}
          onClick={addUniqueNumber}
        >
          <div
            style={{
              padding: "2px",
              border: "1px dotted #b0b0b0",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon style={{ fontSize: "1rem" }}>add</Icon>
          </div>
          <p style={{ margin: "0" }}>{translate(`$__newMessage`)}</p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "20px",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
      >
        {editorData?.map((message) => (
          <div
            style={{
              width: "45%",
              padding: "5px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            }}
            key={message?.id}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <input
                type="text"
                placeholder={translate('$__addTitle')}
                style={{
                  border: "none",
                  borderBottom: "1px solid #eee",
                  outline: "none",
                  padding: "5px",
                  margin: "5px",
                  fontSize: "0.9rem",
                  width: "85%",
                }}
                defaultValue={message?.title}
                onChange={(e) => changeTitle(e.target.value, message?.id)}
              />
              <Icons
                icon={message?.icon}
                onChange={(e) => {
                  changeIcon(e, message?.id);
                }}
              />
              <Icon
                style={{ color: "#d6d6d6", cursor: "pointer" }}
                onClick={() => deleteData(message)}
              >
                delete
              </Icon>
            </div>
            <MessageEditor
              data={message}
              edit={true}
              id={message?.id}
              changeData={changeData}
            />
          </div>
        ))}
        {JSON.stringify(editorData) !== JSON.stringify(iniData) ? (
          <div
            style={{
              position: "fixed",
              bottom: 50,
              right: 50,
              display: "flex",
              justifyContent: "flex-end",
              zIndex: 999,
            }}
          >
            <div style={{ marginLeft: 15 }}>
              <CustomButton
                // shadow
                title={translate("$__save")}
                text={translate("$__save")}
                color="green"
                // tr
                icon={"save"}
                size={"25px"}
                onClick={() => {
                  saveData();
                  // setEditor(false)
                }}
              />
            </div>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (store) => ({
  history: store.db.history,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Message);

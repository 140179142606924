import React, { useState } from "react";

//COMPONENTES
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Icon from 'components/Icon';
import Enterprise from "./Enterprise";
import Notifications from "./Notifications";
import Overview from "./Overview";
import Department from "./Department";
import WhiteLabel from "./WhiteLabel";
import Message from "./Message";
import Integrations from "./Integrations";

//FUNCTIONS
import { translate } from "functions";

const ProfileEnterprise = () => {
  const [check, setCheck] = useState('Overview');

  const handleChange = (name) => {
    setCheck(name);
  };
  return (
    <div
      style={{
        width: "calc(100% - 15px)",
        position: "absolute",
        zIndex: "9",
        borderRadius: "5px",
        backgroundColor: "#eee",
        margin: "0px",
        fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
        minHeight: '100vh',
        height: "auto",
      }}
    >
      <GridContainer
        style={{
          height: "100%",
          justifyContent: "flex-end",
          margin: "0px",
          width: "100%",
        }}
      >
        <GridItem
          item
          xs={12}
          style={{
            backgroundColor: "#eee",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: 'column',
            gap: '5px',
            marginBottom: '30px'
          }}
        >
          <Enterprise />
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            padding: '10px',
            borderBottom: '1px solid #b9b9b9',
            width: '100%',
            justifyContent: 'flex-start',
            marginTop: '0px',
            marginBottom: '20px',
            color: 'rgb(83 83 83)',
            overflowX: 'auto',
          }}>
            <LinkMenu value={'Overview'} check={check} onClick={() => handleChange('Overview')}>
              <Icon>grid_view</Icon> {translate('$__overview')}
            </LinkMenu>
            <LinkMenu value={'Notifications'} check={check} onClick={() => handleChange('Notifications')}>
              <Icon>notifications_active</Icon> {translate('$__notificationManagement')}
            </LinkMenu>
            <LinkMenu value={'Department'} check={check} onClick={() => handleChange('Department')}>
              <Icon>group</Icon> {translate('$__users')}
            </LinkMenu>
            <LinkMenu value={'Message'} check={check} onClick={() => handleChange('Message')}>
              <Icon>sticky_note_2</Icon> {translate('$__profileMessages')}
            </LinkMenu>
            <LinkMenu value={'WhiteLabel'} check={check} onClick={() => handleChange('WhiteLabel')}>
              <Icon>palette</Icon> {translate('$__whiteLabel')}
            </LinkMenu>
            <LinkMenu value={'Integrations'} check={check} onClick={() => handleChange('Integrations')}>
              <Icon>integration_instructions</Icon> {translate('$__integrations')}
            </LinkMenu>
          </div>
          {
            check === 'Overview' ? <Overview /> :
              check === 'Notifications' ? <Notifications /> :
                check === 'WhiteLabel' ? <WhiteLabel /> :
                  check === 'Message' ? <Message /> :
                    check === 'Integrations' ? <Integrations /> :
                      check === 'Department' ? <Department /> : null
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}
export default ProfileEnterprise;

function LinkMenu({ children, value, check, onClick }) {
  const [buttonHover, setButtonHover] = useState(false)
  return (
    <div
      onMouseEnter={() => setButtonHover(true)}
      onMouseLeave={() => setButtonHover(false)}
      onClick={onClick}
      style={{
        textDecoration: 'none',
        color: 'rgb(83 83 83)',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '15px',
        padding: '2px 7px',
        borderRadius: '3px',
        transition: '0.3s all',
        backgroundColor: buttonHover ? '#cfcfcf' : value === check ? '#cfcfcf' : 'transparent',
        cursor: buttonHover ? 'pointer' : 'default',
      }}>{children}</div>
  )
}
import React, { useEffect, useRef, useState } from 'react'

//COMPONENTS
import { ResponsiveLine } from '@nivo/line'
import Legend from '../Legend'
import Header from 'components/Dashboards/Header'
import './Loader.css';
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck';
import Preview from '../Preview/TasksPreview';
//REDUX
import reduxStore from "store/"
import moment from "moment-timezone"
import { translate } from 'functions'

const TaskDoneByTime = (props) => {

    const { db } = reduxStore.getState()
    const [data, setData] = useState({})
    const [dashFilterChart, setDashFilterChart] = useState({})
    const higherValue = useRef(0)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    const fakeDataWall = [
        {
            "x": "",
            "y": 0
        },
        {
            "x": " ",
            "y": 0
        },
    ]
    useEffect(() => {
        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);

    useEffect(() => {
        let timeTasks = {}
        let lateTasks = {}
        const getWeek = () => {
            let weekStart = moment().startOf('week');
            for (let i = 1; i < 7; i++) {
                let dateCard = moment(weekStart).add(i, 'days').format("YYYYMMDD");
                timeTasks = {
                    ...timeTasks,
                    "id": "now",
                    "data": {
                        ...timeTasks["data"],
                        [dateCard]: {
                            "x": translate('$__' + moment(dateCard).format('dddd')),
                            "y": 0
                        }
                    }
                }
                lateTasks = {
                    ...lateTasks,
                    "id": "past",
                    "data": {
                        ...lateTasks["data"],
                        [dateCard]: {
                            "x": translate('$__' + moment(dateCard).format('dddd')),
                            "y": 0
                        },

                    }
                }
            }
        }

        getWeek()
        if (props.planCards) {
            Object.keys(props.planCards).length > 0 && Object.keys(props.planCards).forEach(cardId => {
                let lastDateCard =
                    db.cards[cardId].completed_at?.low
                    || db.cards[cardId].completed_at
                    || db.cards[cardId].updated_at?.low
                    || db.cards[cardId].updated_at
                    || db.cards[cardId].created_at?.low
                    || db.cards[cardId].created_at
                let dateCard = moment(lastDateCard * 1000).format("YYYYMMDD")
                let startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate).format("YYYYMMDD") : moment().startOf('week').add(1, 'day').format("YYYYMMDD");
                let endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate).format("YYYYMMDD") : moment().endOf('week').format("YYYYMMDD");
                let startDateLast = moment().subtract(1, 'weeks').startOf('week').add(1, 'day').format("YYYYMMDD");
                let endDateLast = moment().subtract(1, 'weeks').endOf('week').format("YYYYMMDD");
                if ((dateCard >= startDate) && (dateCard <= endDate)) {
                    timeTasks = {
                        ...timeTasks,
                        "id": "now",
                        "data": {
                            ...timeTasks["data"],
                            [dateCard]: {
                                "x": translate('$__' + moment(dateCard).format('dddd')),
                                "y": timeTasks?.["data"]?.[dateCard]?.["y"] ? timeTasks?.["data"]?.[dateCard]?.["y"] + 1 : 1
                            }
                        }
                    }
                }
                if ((dateCard >= startDateLast) && (dateCard <= endDateLast)) {
                    let lastDateCard = moment(dateCard).add(1, 'week').format("YYYYMMDD");

                    lateTasks = {
                        ...lateTasks,
                        "id": "past",
                        "data": {
                            ...lateTasks["data"],
                            [lastDateCard]: {
                                "x": translate('$__' + moment(dateCard).format('dddd')),
                                "y": lateTasks?.["data"]?.[lastDateCard]?.["y"] ? lateTasks?.["data"]?.[lastDateCard]?.["y"] + 1 : 1
                            },

                        }
                    }
                }

            })



            if (timeTasks["data"]) {
                timeTasks = {
                    ...timeTasks,
                    "data": [fakeDataWall[0], ...Object.values(timeTasks["data"]), fakeDataWall[1]]
                }
                if (Object.keys(timeTasks).length > 0) {
                    timeTasks = [timeTasks]
                }

                timeTasks.forEach((item) => {

                    item["data"].forEach(date => {
                        if (date["y"] > higherValue.current) {
                            higherValue.current = date["y"]
                        }
                    })
                })
            }


            if (lateTasks["data"]) {
                lateTasks = {
                    ...lateTasks,
                    "data": [fakeDataWall[0], ...Object.values(lateTasks["data"]), fakeDataWall[1]]
                }
                if (Object.keys(lateTasks).length > 0) {
                    timeTasks = [...timeTasks, lateTasks]
                }

                timeTasks.forEach((item) => {
                    item["data"].forEach(date => {
                        if (date["y"] > higherValue.current) {
                            higherValue.current = date["y"]
                        }
                    })
                })
            }
            setData(timeTasks)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.planCards, dashFilterChart]);

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
                        <Header styles={props.styles} tittle={translate("$__" + props.tittle)} icon={props.icon} edit={props.edit} id={props.id} handleRemoveObject={(id) => props.handleRemoveObject(id)} />
                        <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%', }}></div>
                        <div style={{ position: 'relative', flex: '1 1 auto' }}>
                            {!dashFilterChart?.startDate ? <div className='component' style={{ position: 'absolute', width: '100%', height: '100%', marginBottom: '25px', backgroundColor: '#f5f5f5' }}>
                                {(data.length > 0 && (!props.load && inViewport)) ?
                                    <ResponsiveLine
                                        data={data}
                                        responsive={true}
                                        margin={{ top: 20, right: -15, bottom: 50, left: 30 }}
                                        xScale={{ type: 'point' }}
                                        yScale={{
                                            type: 'linear',
                                            min: 'auto',
                                            max: higherValue.current + (higherValue.current / 2),
                                            stacked: false,
                                            reverse: false
                                        }}
                                        yFormat=" >-.2f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            tickSize: 5,
                                            tickPadding: 15,
                                            tickRotation: 0,

                                        }}
                                        axisLeft={{
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            format: (v) => v % 1 === 0 ? v : ''
                                        }}
                                        enableGridX={true}
                                        enableGridY={true}
                                        lineWidth={0.5}
                                        pointSize={1}
                                        pointColor={{ theme: 'background' }}
                                        pointBorderWidth={0}
                                        enableArea={true}
                                        areaBaselineValue={0}
                                        pointBorderColor={{ from: 'serieColor' }}
                                        pointLabelYOffset={0}
                                        useMesh={true}
                                        curve={'catmullRom'}
                                        colors={['rgba(36, 170, 219, 0.8)', 'rgba(82, 82, 82, 0.4)']}
                                        theme={{
                                            fontSize: '14px',
                                            color: 'white',
                                            background: '#f5f5f5',
                                            grid: {
                                                "line": {
                                                    "stroke": "#e8e6e6",
                                                    "strokeWidth": 1
                                                }
                                            },
                                            axis: {
                                                "ticks": {
                                                    "text": {
                                                        fill: 'rgba(0, 0, 0, 0.50)',
                                                        "fontSize": 14,
                                                        //cor dos numeros
                                                    },
                                                }

                                            },
                                        }}
                                    /> : <div style={{ wdith: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                                    </div>}
                                <div style={{ width: '98%', backgroundColor: '#f5f5f5', height: '18px', position: 'absolute', bottom: '30px', left: '40px', }}></div>
                            </div> : <Preview />}
                        </div>
                        <div style={{ height: '15%', maxHeight: '60px', display: 'flex', backgroundColor: 'white' }}>
                            <Legend legend={[
                                {
                                    id: translate('$__lastWeek'),
                                    color: '#525252',
                                    number: '',
                                    key: 1
                                },
                                {
                                    id: translate('$__thisWeek'),
                                    color: '#24acdc',
                                    number: '',
                                    key: 2
                                },

                            ]} />
                        </div>
                    </div>
                )
                }
            </ViewportChecker >
        </div >
    )
}

export default TaskDoneByTime
//CORE
import React from "react"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Autocomplete from "components/AutoComplete"
import ConnectedIn from "components/ConnectedIn"
import CustomButton from "components/Buttons/custom"
import Icon from "components/Icon"
import QuickUsers from "components/User/quick"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"
import Switch from "components/Switch"
import TagsInput from "components/Tags/input"
import TextField from "components/TextField"
import Navigation from "components/Navigation"
import MultInput from "components/MultInput/input";
import Icons from "components/Icons"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS
import api from "api/"

import {
    cardStatus,
    getUserAccess,
    planMyAccountVerify,
    saveCard,
    updateCardStatus,
} from "functions/cards"

import {
    loadCardsV2
} from "functions/loadData"

import {
    appAlert,
    customApp,
    getAppAccess,
    getDate,
    translate,
} from "functions/"

function Component(props) {
    const { classes } = props;
    const { db, sideModule } = reduxStore.getState();
    const { planType } = db;
    const [data, setData] = React.useState({})
    const [newData, setNewData] = React.useState({})
    const [reqSave, setReqSave] = React.useState(false)
    const [saveLoader, setSaveLoader] = React.useState(false)
    const [onConcludedStepMoveTo, setOnConcludedStepMoveTo] = React.useState(sideModule.data?.onConcludedStepMoveTo || null)

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(sideModule.id)

    React.useEffect(() => {
        console.log("MONTANDO")
        if (data._id !== sideModule.data._id) {
            setData(sideModule.data)
            setReqSave(false)
        }
        setData(sideModule.data)
        setReqSave(false)

        if (data.type === "link" && !data.name)
            loadUrlContent()
        reqFullData()
        if (sideModule.data.name && sideModule.data.name.trim() !== "" && sideModule.data.type === "link" && sideModule.data._id === "new") {
            setReqSave(true)
            if (props.reqSave)
                props.reqSave(true)
        }

        return () => {
            console.log("DESMONTADO")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // React.useEffect(() => {
    //     if (data._id !== sideModule.data._id) {
    //         setData(sideModule.data)
    //         setReqSave(false)
    //     }
    //     setData(sideModule.data)
    //     setReqSave(false)

    //     if (data.type === "link" && !data.name)
    //         loadUrlContent()
    //     reqFullData()
    //     if (sideModule.data.name && sideModule.data.name.trim() !== "" && sideModule.data.type === "link" && sideModule.data._id === "new") {
    //         setReqSave(true)
    //         if (props.reqSave)
    //             props.reqSave(true)
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [sideModule.data, data])

    React.useEffect(() => {
        if (!data.name || (data.name && data.name.trim === "")) {
            setReqSave(false)
        } else {
            setReqSave(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newData])

    const reqFullData = async () => {
        loadCardsV2({ ...props, ignoreLoader: true }, {
            idRel: [sideModule.data._id],
            cardLoad: "parents"
        })
    }

    const changeData = (d, d2, clear = false) => {
        if (
            (
                d.value
                &&
                (
                    !sideModule.data[d.name]
                    &&
                    !data[d.name]
                )
            )
            ||
            (
                sideModule.data[d.name]
                && sideModule.data[d.name] !== d.value
            )
            ||
            clear
        ) {
            // setReqSave(true)
            if (props.reqSave)
                props.reqSave(true)
        }


        let newDataCD = {
            ...data,
            [d.name]: d.value,
        }
        if (d2 && d2.name && d2.value) {
            newDataCD = {
                ...data,
                ...newDataCD,
                [d2.name]: d2.value
            }
        }
        setData(newDataCD)
        setNewData({
            ...newData,
            [d.name]: d.value,
            ...d2 && d2.name && d2.value ? {
                [d2.name]: d2.value
            } : {}
        })
    }

    const close = (cl) => {
        if (reqSave && !cl) {
            appAlert({
                message: translate("$__confirmCloseWithUnsavedData", 1),
                variant: "warning",
                persist: false,
                horizontal: "right",
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
        }
    }
    const save = async (cl = false) => {
        if (!saveLoader) {
            setReqSave(false)
            if (props.reqSave)
                props.reqSave(false)
            if (!data.status)
                data.status = "notStarted"
            if (sideModule.id === "new" && sideModule.data) {
                setData({
                    ...data,
                    ...sideModule.data
                })
            }
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...sideModule,
                data: data
            })

            if (!data.name || (data.name && data.name.trim() === "")) {
                setSaveLoader(false)
                appAlert({
                    message: translate("$__fillName", 1),
                    variant: "warning",
                    persist: false,
                    horizontal: "right",
                    confirm: null
                })
            } else {
                const request = await saveCard({
                    ...props,
                    store: {
                        ...props.store,
                        db: {
                            ...reduxStore.getState().db
                        }
                    }
                }, cl, data)
                if (request) {
                    if (newData && newData.status) {
                        updateCardStatus(
                            {
                                ...props,
                                data: { _id: data._id }
                            }, { status: newData.status })
                        cardStatus(props, data._id, newData.status)
                    }
                    setSaveLoader(false)
                    appAlert({
                        message: translate("$__DataSaved", 1),
                        variant: null,
                        persist: false,
                        horizontal: "right",
                        confirm: null
                    })
                }
                setReqSave(false)
                if (props.reqSave)
                    props.reqSave(false)
                if (cl)
                    close(true)
            }
        }
    }

    const loadUrlContent = async () => {
        const result = await api.post(`/link/content`, {
            url: data.url
        })
        setData({
            ...data,
            content: result.data.article,
        })
        setReqSave(true)
        if (props.reqSave)
            props.reqSave(true)
    }

    const dataType = sideModule.data && sideModule.data.type ? db.dataType.filter(a => a.name === sideModule.data.type)[0] : {}

    if (!data)
        return null

    let permission = props.permission ? props.permission : false

    if (data && data._planId && (!db.cards[data._planId] || !planMyAccountVerify(data._planId)))
        permission = false

    if (!permission && parseInt(CardAccess) >= 4)
        permission = true

    if (!permission && planMyAccountVerify(data._planId) && AppAccess && AppAccess.admin && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))
        permission = true

    return (
        <React.Fragment>
            <SideModuleCloseAndSave
                save={reqSave ? () => {
                    setSaveLoader(true)
                    save()
                } : null}
                saveClose={reqSave ? () => {
                    setSaveLoader(true)
                    save(true)
                } : null}
                saveLoader={saveLoader}
                close={() => {
                    if (props.onClose)
                        props.onClose()
                }}
            />
            <div className={classes.root}>
                <div className={classes.data}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                        <div
                            style={{
                                display: "flex", alignItems: "center", justifyContent: "flex-end"
                            }}
                        >
                            {
                                data.type === "step" && data._id && permission ?
                                    <CustomButton
                                        title={translate("$__openBoard", 1)}
                                        icon={"launch"}
                                        color={"lightgray"}
                                        onClick={() => {
                                            // //console.log("openBoard", data._id)
                                        }
                                        }
                                        size={"25px"}
                                    />
                                    : <React.Fragment></React.Fragment>
                            }
                        </div>
                    </div>
                    <fieldset style={{ marginBottom: "14px" }}>
                        <legend>{data.type === 'plan' && data._id ? translate(`$__plan ${data.planType ? planType[1].label : planType[0].label}`, 1) : translate("$__data", 1)}</legend>
                        {data.created_at ?
                            <div
                                style={{
                                    position: "relative",
                                    fontSize: 12,
                                    color: "#bfbfbf",
                                    padding: "3px 0px",
                                    marginTop: -8,
                                    textAlign: "right",
                                }}>
                                {translate("$__createdAt")}: {getDate(data.created_at)}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        <TextField
                            label={translate(dataType && dataType.fieldsTitle && dataType.fieldsTitle.name ? dataType.fieldsTitle.name : "$__titleOfCard", 1)}
                            variant={customApp("fieldVariant")}
                            name={"name"}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: "new-password",
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon="view_headline" />
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    changeData(d.target)
                                },
                            }}
                            value={data && data.name ? data.name : ""}
                            placeholder={translate(dataType && dataType.fieldsTitle && dataType.fieldsTitle.placeholder && dataType.fieldsTitle.placeholder.name ? dataType.fieldsTitle.placeholder.name : "$__titleOfCard_placeholder", 1)}
                            disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("name") > -1) ? true : false}
                        />
                        {(CardAccess > 3 || data.description || permission)
                            ?
                            <React.Fragment>
                                <TextField
                                    label={translate("$__description", 1)}
                                    variant={customApp("fieldVariant")}
                                    name="description"

                                    InputProps={{
                                        autoComplete: "new-password",
                                        multiline: true,
                                        minRows: 3,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon icon="toc" />
                                            </InputAdornment>
                                        ),
                                        onChange: (d) => {
                                            changeData(d.target)
                                        },
                                    }}
                                    value={data && data.description ? data.description : ""}
                                    placeholder={translate("$__descriptionOfCard_placeholder", 1)}
                                    disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("description") > -1) ? true : false}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment>
                        }
                        <div className={classes.switch}>
                            <Switch
                                checked={
                                    data.showSumValues
                                        ? true
                                        : false
                                }
                                onChange={(d) => {
                                    changeData({
                                        name: "showSumValues",
                                        value: data.showSumValues ? false : true
                                    })
                                }}
                                name="showSumValues"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                            {translate("$__showSumValues", 1)}
                        </div>
                        <div className={classes.switch}>
                            <Switch
                                checked={
                                    data.requireValue
                                        ? true
                                        : false
                                }
                                onChange={(d) => {
                                    changeData({
                                        name: "requireValue",
                                        value: data.requireValue ? false : true
                                    })
                                }}
                                name="requireValue"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />
                            {translate("$__requireValue", 1)}
                        </div>
                    </fieldset>


                    <fieldset style={{ marginBottom: "14px" }}>
                        <legend>{translate(`$__automation`, 1)}</legend>
                        <fieldset style={{ marginBottom: "14px" }}>
                            <legend>{translate(`$__users`, 1)}</legend>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    color: "gray",
                                    fontSize: 10
                                }}
                            >{translate('$__automationUsersExplain', 1)}</div>
                            <AutomationAction
                                value={
                                    data.automationUsers
                                    || null
                                }
                                onChange={(e) => {
                                    changeData({
                                        name: "automationUsers",
                                        value: e
                                    })
                                }}
                                {...data.step === "0" ? { ignoreValues: ['remove', 'removeAll'] } : {}}
                            />
                            {
                                data._id
                                && db.cards[data._id]
                                && db.cards[data._id]._users
                                && data.automationUsers !== "removeAll"
                                &&
                                <QuickUsers
                                    _id={data._id}
                                    noLimit
                                    avatarSize="33px"
                                    permission={permission}
                                />
                            }
                            {(data.automationUsers && (
                                data.automationUsers === "remove"
                                || data.automationUsers === "replace"
                            )) ?
                                <div className={classes.switch}>
                                    <Switch
                                        checked={
                                            data.keepUserWhoCreatedActivity
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            changeData({
                                                name: "keepUserWhoCreatedActivity",
                                                value: data.keepUserWhoCreatedActivity ? false : true
                                            })
                                        }}
                                        name="keepUserWhoCreatedActivity"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__keepUserWhoCreatedActivity", 1)}
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate(`$__execAsResponsible`, 1)}</legend>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Switch
                                        checked={
                                            data.execAsResponsible
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            changeData({
                                                name: "execAsResponsible",
                                                value: !data.execAsResponsible ? true : null
                                            })
                                        }}
                                        name="changeIcon"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__yes", 1)}
                                </div>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        color: "gray",
                                        fontSize: 10
                                    }}
                                >{translate('$__execAsResponsibleExplain', 1)}</div>
                            </fieldset>
                        </fieldset>
                        <fieldset
                            style={{
                                marginBottom: "14px"
                            }}
                        >
                            <legend>{translate(`$__dueDate`, 1)}</legend>
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate("$__due", 1)}</legend>
                                <AutomationAction
                                    value={data.automationDueDateType || null}
                                    onChange={(e) => {
                                        changeData({ name: "automationDueDateType", value: e })
                                    }}
                                    ignoreValues={['replace', 'removeAll']}
                                />
                                {(
                                    data.automationDueDateType && data.automationDueDateType === 'add'
                                ) ?
                                    <TextField
                                        label={translate("$__automationDueDate", 1)}
                                        variant={customApp("fieldVariant")}
                                        name="automationDueDate"
                                        min={1}
                                        InputProps={{
                                            type: "number",
                                            min: 1,
                                            autoComplete: "new-password",
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {translate("$__days", '*')}
                                                </InputAdornment>
                                            ),
                                            onChange: (d) => {
                                                let value = d.target.value || 1
                                                if (parseInt(value) < 1) value = 1
                                                changeData({ name: "automationDueDate", value: value })
                                            },
                                        }}
                                        value={data && data.automationDueDate ? data.automationDueDate : 1}
                                    />
                                    : <React.Fragment></React.Fragment>
                                }
                            </fieldset>
                        </fieldset>
                        <fieldset style={{ marginBottom: "14px" }}>
                            <legend>{translate(`$__tags`, 1)}</legend>
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate("$__tags", 1)}</legend>
                                <AutomationAction
                                    value={data.automationTags || null}
                                    onChange={(e) => {
                                        changeData({ name: "automationTags", value: e })
                                    }}
                                    ignoreValues={['removeAll']}
                                />
                                {data.automationTags && data.automationTags !== "doNotChange" ?
                                    <TagsInput
                                        data={{
                                            values: data._tags ? data._tags : [],
                                        }}
                                        callBack={(v) => {
                                            if (permission)
                                                changeData({ name: "_tags", value: v })
                                        }}
                                        disabled={!permission || data.status === "completed" || (dataType && dataType.disabledFields && dataType.disabledFields.indexOf("_tags") > -1) ? true : false}
                                        placeholder={translate("$__selectTheOptions", 1)}
                                    />
                                    : <React.Fragment></React.Fragment>
                                }
                            </fieldset>
                            <fieldset
                                style={{
                                    marginBottom: "14px"
                                }}
                            >
                                <legend>{translate(`$__icon`, 1)}</legend>
                                <fieldset style={{ marginBottom: "14px" }}>
                                    <legend>{translate("$__setThisIconWhenAdd", 1)}</legend>
                                    <div className={classes.switch} style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Switch
                                                checked={
                                                    data.setIcon
                                                        ? true
                                                        : false
                                                }
                                                onChange={(d) => {
                                                    changeData({
                                                        name: "setIcon",
                                                        value: !data.setIcon ? data.icon ? data.icon : "fiber_new" : null
                                                    })
                                                }}
                                                name="changeIcon"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__changeIconWhenReceiveCardTo", 1)}
                                        </div>
                                        {data.setIcon ?
                                            <Icons
                                                icon={data.setIcon ? data.setIcon : data.icon ? data.icon : "fiber_new"}
                                                color={customApp('colorText')}
                                                onChange={(e) => {
                                                    changeData({ name: "setIcon", value: e })
                                                }}
                                                size={'25px'}
                                                style={{ marginRight: 7 }}
                                            /> : <></>}
                                    </div>

                                </fieldset>
                            </fieldset>
                        </fieldset>
                        {Object.keys(reduxStore.getState().db.cards).filter(card => reduxStore.getState().db.cards[card]._parent === data._parent && reduxStore.getState().db.cards[card].type === 'step' && !reduxStore.getState().db.cards[card].deleted).length === parseInt(data.step) + 1 ?
                            <fieldset style={{ marginBottom: "14px" }}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        color: "gray",
                                        fontSize: 10
                                    }}
                                >{translate('$__onConcludedStepMoveTo', 1)}</div>
                                <div className={classes.switch}>
                                    {onConcludedStepMoveTo && onConcludedStepMoveTo === data.onConcludedStepMoveTo ?
                                        <CustomButton
                                            title={translate(`$__selectTarget`, 1)}
                                            text={translate(`$__selectTarget`, 1)}
                                            color={customApp("menu")}
                                            icon={"assistant_navigation"}
                                            onClick={() => {
                                                setOnConcludedStepMoveTo(null)
                                            }}
                                            size={"14px"}
                                            textSize={"14px"}
                                        />
                                        :
                                        <div>
                                            <Navigation
                                                onSelect={(e) => {
                                                    // //console.log(e)
                                                    if (!reduxStore.getState().db.cards[e._id])
                                                        loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'parents', idRel: e._id })
                                                    setOnConcludedStepMoveTo(e._id)
                                                }}
                                                ignoreTypes={['chatGroup']}
                                                types={['plan', 'guideline', 'guidelineC', 'guidelineD', 'guidelineE', 'guidelineR', 'objective', 'goal', 'step']}
                                            />
                                            {onConcludedStepMoveTo && onConcludedStepMoveTo !== data.onConcludedStepMoveTo ?
                                                <div
                                                    style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <CustomButton
                                                        title={translate(`$__cancel`, 1)}
                                                        text={translate(`$__cancel`, 1)}
                                                        color={"lightgray"}
                                                        icon={"cancel"}
                                                        onClick={() => {
                                                            changeData({ name: "onConcludedStepMoveTo", value: data?.onConcludedStepMoveTo || null })
                                                        }}
                                                    />
                                                    <CustomButton
                                                        title={translate(`$__save`, 1)}
                                                        text={translate(`$__save`, 1)}
                                                        color={customApp("menu")}
                                                        icon={"check"}
                                                        onClick={() => {
                                                            changeData({ name: "onConcludedStepMoveTo", value: onConcludedStepMoveTo })
                                                        }}
                                                    />
                                                </div>
                                                : <React.Fragment></React.Fragment>
                                            }
                                        </div>

                                    }
                                    {data.onConcludedStepMoveTo && onConcludedStepMoveTo === data.onConcludedStepMoveTo && reduxStore.getState().db.cards[data.onConcludedStepMoveTo] ?
                                        <React.Fragment>
                                            <ConnectedIn
                                                id={data.onConcludedStepMoveTo}
                                                // loading={loadingData}
                                                showLegend={true}
                                                legend={translate("$__target")}
                                            />
                                        </React.Fragment>
                                        : <React.Fragment></React.Fragment>
                                    }
                                </div>
                            </fieldset>
                            : <React.Fragment></React.Fragment>
                        }
                        <fieldset style={{ marginBottom: "14px" }}>
                            <legend>{translate(`$__status`, 1)}</legend>
                            <fieldset style={{ marginBottom: "14px" }}>
                                <legend>{translate("$__changeStatusTo", 1)}</legend>
                                <AutomationAction
                                    value={data.automationStatusType || null}
                                    onChange={(e) => {
                                        changeData({ name: "automationStatusType", value: e })
                                    }}
                                    ignoreValues={['add', 'remove', 'removeAll']}
                                />
                                {data.automationStatusType && data.automationStatusType !== "doNotChange" ?
                                    <Autocomplete
                                        id="combo-box-demo"
                                        options={db.status}
                                        getOptionLabel={(option) => translate(option.label, 1)}
                                        style={{ width: "100%" }}
                                        name="automationStatus"
                                        disableClearable
                                        value={
                                            data
                                                && data.automationStatus
                                                && db.status
                                                ? db.status.filter(a =>
                                                    String(a.value) === String(data.automationStatus)
                                                )[0]
                                                : db.status[0]
                                        }
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label={translate("$__status", 1)}
                                                variant={customApp("fieldVariant")}
                                                value={
                                                    data
                                                        && data.automationStatus
                                                        && db.status
                                                        ? db.status.filter(a =>
                                                            String(a.value) === String(data.automationStatus)
                                                        )[0]
                                                        : db.status[0]
                                                }
                                            />
                                        }
                                        onChange={
                                            (d, v) => {
                                                if (permission || String(CardAccess) !== "0")
                                                    changeData({ name: "automationStatus", value: v["value"] })
                                            }
                                        }
                                    /> : <React.Fragment></React.Fragment>
                                }
                            </fieldset>
                        </fieldset>
                        <fieldset style={{ marginBottom: "14px" }}>
                            <div
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    color: "gray",
                                    fontSize: 10
                                }}
                            >{translate('$__automationApprovalExplain', 1)}</div>
                            <div className={classes.switch}>
                                <Switch
                                    checked={
                                        data.requireApproval
                                            ? true
                                            : false
                                    }
                                    onChange={(d) => {
                                        changeData({
                                            name: "requireApproval",
                                            value: data.requireApproval ? false : true
                                        })
                                    }}
                                    name="requireApproval"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                {translate("$__requireApproval", 1)}
                            </div>
                            {
                                data.requireApproval ?
                                    <QuickUsers
                                        _id={data._id}
                                        selected={""}
                                        noLimit
                                        avatarSize="33px"
                                        permission={permission}
                                        customDefine={{
                                            name: "approvalResponsible",
                                            icon: "gavel"
                                        }}
                                    />
                                    : <React.Fragment></React.Fragment>
                            }
                        </fieldset>
                        {data.step === "0" &&
                            <fieldset style={{ marginBottom: "14px" }}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        color: "gray",
                                        fontSize: 10
                                    }}
                                >{translate('$__showInPanel', 1)}</div>
                                <div className={classes.switch}>
                                    <Switch
                                        checked={
                                            data.showInPanel
                                                ? true
                                                : false
                                        }
                                        onChange={(d) => {
                                            changeData({
                                                name: "showInPanel",
                                                value: data.showInPanel ? false : true
                                            })
                                        }}
                                        name="showInPanel"
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                    {translate("$__permitOpenTicketInPanel", 1)}
                                </div>
                                {data.showInPanel ?
                                    <React.Fragment>
                                        <fieldset
                                            style={{ marginBottom: '14px' }}
                                            id={`sideModule_user_group`}
                                        >
                                            <MultInput
                                                data={{
                                                    values: data.showGroups || [],
                                                    _id: data._id,
                                                }}
                                                options={reduxStore.getState().db.userGroup ? reduxStore.getState().db.userGroup : []}
                                                placeholder={"$__userGroupPlaceholder"}
                                                dbNode={"userGroup"}
                                                callBack={(v) => {
                                                    changeData({ name: 'showGroups', value: v })
                                                }}
                                            />
                                        </fieldset>
                                        <fieldset
                                            style={{ marginBottom: '14px' }}
                                            id={`sideModule_user_group`}
                                        >
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={Object.keys(reduxStore.getState().forms)
                                                    .sort((a, b) => {
                                                        if (reduxStore.getState().forms[a].name < reduxStore.getState().forms[b].name) {
                                                            return -1
                                                        } else if (reduxStore.getState().forms[a].name > reduxStore.getState().forms[b].name) {
                                                            return 1
                                                        } else {
                                                            return 0
                                                        }
                                                    }).map(a => {
                                                        return { label: reduxStore.getState().forms[a].name, value: String(reduxStore.getState().forms[a]._id) }
                                                    })
                                                }
                                                getOptionLabel={(option) => {
                                                    return translate(option.label, 1)
                                                }
                                                }
                                                style={{ width: "100%" }}
                                                name="form"
                                                value={
                                                    (
                                                        data
                                                        &&
                                                        data.form
                                                    )
                                                        ? Object.keys(reduxStore.getState().forms)
                                                            .filter(a =>
                                                                data.form
                                                                && reduxStore.getState().forms[a]._id === data.form
                                                            ).map(a => {
                                                                return {
                                                                    label: reduxStore.getState().forms[a].name,
                                                                    value: String(reduxStore.getState().forms[a]._id)
                                                                }
                                                            })[0]
                                                        : null
                                                }
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label={translate("$__form", 1)}
                                                        variant={customApp("fieldVariant")}
                                                        value={
                                                            (
                                                                data
                                                                &&
                                                                data.form
                                                            )
                                                                ? Object.keys(reduxStore.getState().forms)
                                                                    .filter(a =>
                                                                        data.form
                                                                        && reduxStore.getState().forms[a]._id === data.form
                                                                    ).map(a => {
                                                                        return {
                                                                            label: reduxStore.getState().forms[a].name,
                                                                            value: String(reduxStore.getState().forms[a]._id)
                                                                        }
                                                                    })[0]
                                                                : null
                                                        }
                                                    />
                                                }
                                                onChange={
                                                    (d, v) => {
                                                        changeData({ name: "form", value: v && v["value"] ? String(v["value"]) : null })
                                                    }
                                                }
                                            />
                                        </fieldset>
                                        <TextField
                                            label={translate("$__shortCutTitle", 1)}
                                            variant={customApp("fieldVariant")}
                                            name={"shortCutTitle"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon icon="view_headline" />
                                                    </InputAdornment>
                                                ),
                                                onChange: (d) => {
                                                    changeData(d.target)
                                                },
                                            }}
                                            value={data && data.shortCutTitle ? data.shortCutTitle : ""}
                                            placeholder={translate("$__shortCutTitlePlaceholder")}
                                        />
                                        <TextField
                                            label={translate("$__shortCutDescription", 1)}
                                            variant={customApp("fieldVariant")}
                                            name="shortCutDescription"

                                            InputProps={{
                                                autoComplete: "new-password",
                                                multiline: true,
                                                minRows: 3,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon icon="toc" />
                                                    </InputAdornment>
                                                ),
                                                onChange: (d) => {
                                                    changeData(d.target)
                                                },
                                            }}
                                            value={data && data.shortCutDescription ? data.shortCutDescription : ""}
                                            placeholder={translate("$__shortCutDescriptionPlaceholder", 1)}
                                        />
                                        <TextField
                                            label={translate("$__shortCutButtonToAdd", 1)}
                                            variant={customApp("fieldVariant")}
                                            name={"shortCutButtonToAdd"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                autoComplete: "new-password",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon icon="view_headline" />
                                                    </InputAdornment>
                                                ),
                                                onChange: (d) => {
                                                    changeData(d.target)
                                                },
                                            }}
                                            value={data && data.shortCutButtonToAdd ? data.shortCutButtonToAdd : ""}
                                            placeholder={translate("$__shortCutButtonToAddPlaceholder")}
                                        />
                                        <div className={classes.switch}>
                                            <Switch
                                                checked={
                                                    data.requireDescription
                                                        ? true
                                                        : false
                                                }
                                                onChange={(d) => {
                                                    changeData({
                                                        name: "requireDescription",
                                                        value: data.requireDescription ? false : true
                                                    })
                                                }}
                                                name="requireDescription"
                                                inputProps={{ "aria-label": "secondary checkbox" }}
                                            />
                                            {translate("$__requireDescription", 1)}
                                        </div>

                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </fieldset>
                        }
                    </fieldset>
                </div>
            </div>
        </React.Fragment>
    )
}

const AutomationAction = (props) => {
    const { db } = reduxStore.getState()
    return (
        <Autocomplete
            id="combo-box-demo"
            options={props.ignoreValues ? db.automation.filter(r => props.ignoreValues.indexOf(r.value) === -1) : db.automation}
            getOptionLabel={(option) => translate(option.label, 1)}
            style={{ width: "100%" }}
            name={props.name || "AutomationAction"}
            disableClearable
            value={props.value ? db.automation.filter(a =>
                String(a.value) === props.value
            )[0]
                : db.automation[0]
            }
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={translate("$__action", 1)}
                    variant={customApp("fieldVariant")}
                    inputProps={{
                        ...params && params.inputProps ? params.inputProps : {},
                        style: {
                            ...params && params.inputProps && params.inputProps.style ? params.inputProps.style : {},
                            paddingLeft: 7,
                            marginTop: 3,
                            marginLeft: -5,
                            width: "calc(100% - 7px)",
                        }
                    }}
                    value={props.value ? db.automation.filter(a =>
                        String(a.value) === props.value
                    )[0]
                        : db.automation[0]
                    }
                />
            }
            renderOption={(option, { inputValue }) => {
                return (
                    <span>
                        {translate(option.label, 1)}
                    </span>
                )
            }}
            onChange={
                (d, v) => {
                    props.onChange(v["value"])
                }
            }
        />
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(Component))
//CORE
import React from "react"

//REDUX
//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import history from "store/history"
import reduxStore from "store/"

//COMPONENTS
import MoreMenu from "components/MoreMenu/"


import {
    tagDelete,
    tagUnlink,
} from "functions/tags";

import {
    translate,
    customApp,
    getAppAccess,
    appAlert
} from "functions/"

//STYLES
import "./styles.css"

function RenderTag(props) {
    const ns = props.data
    const [showMoreMenuOpen, setShowMoreMenuOpen] = React.useState(false)

    let AppAccess = getAppAccess()

    const openTag = (e) => {
        history.push(`tags#${e.value}`)
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            history: `tags#${e.value}`
        })
    }

    const deleteTag = async (e) => {
        appAlert({
            message: translate("$__confirmDeleteTag", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                tagDelete(props, { _id: e.value })
            }
        })
    }

    const unlinkTag = async (e) => {
        appAlert({
            message: translate("$__confirmUnlinkTag", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                tagUnlink(props, { _id: e.value })
            }
        })
    }

    if (!ns.item.value) {
        return <div
            style={{
                fontWeight: "bold",
                color: customApp("color"),
                fontSize: "12px"
            }}>{ns.item.text}</div>
    } else {
        return <div
            style={{
                width: "100%",
                cursor: "pointer",
                display: "flex"
            }}
            onMouseOver={() => {
                if (AppAccess && AppAccess.plan && AppAccess.planAdmin)
                    setShowMoreMenuOpen(true)
            }}
            onMouseLeave={(e) => {
                setShowMoreMenuOpen(false)

            }}
        >
            <div
                style={{
                    zIndex: 0,
                    maxWidth: "90%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    ...ns.item.color ? { borderLeft: `solid 6px ${ns.item.color}`, paddingLeft: "7px" } : {}
                }}
                onClick={
                    () => {
                        openTag(ns.item)
                    }
                }
            >
                {ns.item.text} {ns.item._count ? `(${ns.item._count})` : ``}
            </div>
            {showMoreMenuOpen &&
                <div style={{ zIndex: 1 }}>
                    <MoreMenu
                        horizontalButton
                        transparent
                        size="14px"
                        btColor={customApp("color")}
                        options={[
                            ...AppAccess && AppAccess.plan && (
                                AppAccess.planAdmin
                            ) ? [{
                                name: "$__edit",
                                icon: "edit",
                                onClick: () => {
                                    props.editTag(ns.item)
                                },
                            }] : [],
                            (
                                (parseInt(ns.item._count) === 0 && parseInt(ns.item._countOthers) === 0)
                                || (parseInt(ns.item._count) > 0 && parseInt(ns.item._countOthers) === 0)
                            ) &&
                            {
                                name: "$__delete",
                                icon: "delete_forever",
                                onClick: () => {
                                    deleteTag(ns.item)
                                },
                            },
                            (
                                (parseInt(ns.item._count) > 0 && parseInt(ns.item._countOthers) > 0)
                            ) &&
                            {
                                name: "$__unlinkMyActivities",
                                icon: "label_off",
                                onClick: () => {
                                    unlinkTag(ns.item)
                                },
                            },
                        ]}
                    />
                </div>
            }
        </div>
    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(null, mapDispatchToProps)(RenderTag)
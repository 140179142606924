//FUNCTIONS
import { customApp } from "functions";

const headerStyle = theme => ({
  btClose: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    right: '0',
    top: '-64px',
    // backgroundColor: '#FFFFFF',
    display: 'flex',
  },
  root: {
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    overflowX: 'auto'
  },
  image: {
    width: '100%',
    paddingTop: '48%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  data: {
    marginTop: '10px',
    padding: '10px',
    overflowX: 'auto',
    '& fieldset': {
      border: 'solid 1px rgba(0,0,0,0.1)',
      '& legend': {
        marginBottom: '0px',
        fontSize: '16px',
        color: customApp('color')
      }
    }
  },
  switch: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: customApp('medium')
  },
  accessDescription: {
    fontSize: '14px',
    color: customApp('medium'),
  },
});

export default headerStyle;

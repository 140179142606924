import React from "react"
import moment from "moment"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import ExportAll from "components/Account/exportAll"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Backoffice-languages.js"

//APIMS
import apims from "apims"

//FUNCTIONS
import {
    customApp,
    formatSizeUnits,
    getDate,
    translate,
} from "functions/"

function MasterAdminAccountView(props) {
    const [Accounts, SetAccounts] = React.useState([])
    const [AccountsExport, SetAccountsExport] = React.useState([])
    const [viewAccountDetail, setViewAccountDetail] = React.useState(null)
    const [userOrderBy, setUserOrderBy] = React.useState("name")
    const [userOrderASC, setUserOrderASC] = React.useState(true)
    const { plans } = reduxStore.getState().db


    React.useEffect(() => {
        ini()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ini = async () => {
        const ReqAccounts = await apims.post("Adm_ReqAccounts")

        let AE = []
        if (ReqAccounts) {
            ReqAccounts.data.forEach(acc => {
                let diskUsed = 0
                if (AE.filter(aa => aa.id === acc.id).length === 0) {
                    acc.users.filter(a => a.filesSize).forEach(a => {
                        diskUsed = diskUsed + parseInt(a.filesSize)
                    })
                    if (diskUsed > 0)
                        diskUsed = formatSizeUnits(diskUsed)

                    let cd = acc.account.created_at && acc.account.created_at.low ? String(acc.account.created_at.low) : acc.account.created_at
                    let plan = acc && acc.account && acc.account.plan && plans.filter(pl => pl.value.indexOf(acc.account.plan)).length > 0 ? acc.account.plan : "p0"

                    let users = Array.from(acc.users)

                    users.flatMap(us => {
                        if (us.deleted || us.unityDeleted)
                            us.deleted = "SIM"
                        us.created_at = moment(us.created_at * 1000).format("DD/MM/YYYY HH:mm:ss")
                        us.lastSession = us.lastSession ? moment(us.lastSession * 1000).format("DD/MM/YYYY HH:mm:ss") : ``
                    })

                    AE.push({
                        id: acc.id,
                        ...acc.account,
                        created_at: moment(cd * 1000).format("DD/MM/YYYY HH:mm:ss"),
                        plan: translate(plans.filter(pl => pl.value.indexOf(plan) > -1)[0].label, '*'),
                        diskUsed: diskUsed,
                        usersCount: acc.users.length,
                        users: users
                    })
                    if (acc.account.name === "ENEVA") {
                        console.log(acc, {
                            id: acc.id,
                            ...acc.account,
                            created_at: moment(cd * 1000).format("DD/MM/YYYY HH:mm:ss"),
                            plan: translate(plans.filter(pl => pl.value.indexOf(plan) > -1)[0].label, '*'),
                            diskUsed: diskUsed,
                            usersCount: acc.users.length,
                            users: users
                        })
                    }
                }
            })

            SetAccounts(ReqAccounts.data)
            SetAccountsExport(AE)
        }

    }

    const u_orderBy = (x) => {
        if (userOrderBy === x) {
            setUserOrderASC(prev => !prev)
        } else {
            setUserOrderBy(x)
        }
    }
    const User = ({ data, index }) => {
        let diskUsed = data.filesSize ? parseInt(data.filesSize) : 0
        if (diskUsed > 0)
            diskUsed = formatSizeUnits(diskUsed)
        return (
            <div>
                <div
                    style={{
                        border: "solid 1px #f6f6f6",
                        width: "100%",
                        padding: 7,
                        overflowY: "auto",
                        display: "flex",
                        ...data.deleted ? { color: "red" } : {},
                        marginBottom: 7,
                        ...index % 2 ? { background: "linear-gradient(33deg, rgba(255, 255, 255, 1), rgba(247,247,247, 1))" }
                            : { background: "linear-gradient(77deg, rgba(247,247,247, 1), rgba(255, 255, 255, 1))" },
                    }}
                >
                    <div style={{ position: "relative", float: "left", width: "100px" }}>#{data.id}</div>
                    <div style={{ position: "relative", float: "left", width: "150px", overflow: "hidden", textOverflow: "ellipsis" }}><div style={{ width: "100%", position: "relative", float: "left" }}>{data.name}</div> {data.unity ? <div style={{ fontSize: 10, width: "100%", position: "relative", float: "left" }}>({data.unity})</div> : ``}</div>
                    <div style={{ position: "relative", float: "left", width: "300px" }}>{data.email}</div>
                    <div style={{ position: "relative", float: "left", width: "150px" }}>{data.created_at}</div>
                    <div style={{ position: "relative", float: "left", width: "150px" }}>{data.lastSession}</div>
                    {/* <div style={{ position: "relative", float: "left", width: "100px" }}>
                        <fieldset>
                            <legend>{translate(`$__userInformations`)}</legend>
                        </fieldset>
                    </div> */}
                </div>
            </div>)
    }

    const Item = ({ data, index }) => {
        let plan = data && data.account && data.account.plan && plans.filter(pl => pl.value.indexOf(data.account.plan) > -1).length > 0 ? data.account.plan : "p0"
        let users = data.users.filter(a => !a.deleted).length
        let diskUsed = 0
        data.users.filter(a => a.filesSize).forEach(a => {
            diskUsed = diskUsed + parseInt(a.filesSize)
        })
        if (diskUsed > 0)
            diskUsed = formatSizeUnits(diskUsed)
        return (
            <div
                style={{
                    position: "relative",
                    width: "100%",
                    padding: 7,
                    marginBottom: 3,
                    ...data.account.deleted ? {
                        ...index % 2 ? { background: "linear-gradient(33deg, rgba(237,20,61,0.1), rgba(237,20,61,0.2))" }
                            : { background: "linear-gradient(33deg, rgba(237,20,61,0.2), rgba(237,20,61,0.1))" },
                    } : {
                        ...index % 2 ? { background: "linear-gradient(33deg, rgba(255, 255, 255, 1), rgba(247,247,247, 1))" }
                            : { background: "linear-gradient(77deg, rgba(247,247,247, 1), rgba(255, 255, 255, 1))" },
                    },
                }}
            >
                <div
                    style={{
                        width: "100%",
                        padding: 7,
                        overflowY: "auto",
                        display: "flex",
                        marginBottom: 3,
                    }}
                >
                    <div style={{ position: "relative", float: "left", width: "100px" }}>#{data.id}</div>
                    <div style={{ position: "relative", float: "left", width: "300px" }}>{data.account.name}</div>
                    <div style={{ position: "relative", float: "left", width: "200px" }}>{getDate(data.account.created_at)}</div>
                    <div style={{ position: "relative", float: "left", width: "200px" }}>{plans.filter(pl => pl.value.indexOf(plan) > -1)[0] && plans.filter(pl => pl.value.indexOf(plan) > -1)[0].label ? translate(`${plans.filter(pl => pl.value.indexOf(plan) > -1)[0].label}`) : ``}</div>
                    <div style={{ position: "relative", float: "left", width: "100px", cursor: "pointer", display: "flex" }}
                        onClick={() => {
                            if (viewAccountDetail === data.id) {
                                setViewAccountDetail(null)
                            } else {
                                setViewAccountDetail(data.id)
                            }
                        }}
                    ><CustomButton
                            transparent
                            title={translate(`$__showUsers`, "*")}
                            color={customApp("lightgrey")}
                            icon={viewAccountDetail === data.id ? "expand_less" : "expand_more"}
                            onClick={() => {
                                setViewAccountDetail(viewAccountDetail === data.id ? null : data.id)
                            }}
                            size={"25px"}
                        />
                        {users}
                        {/* {data.account.maxUsers && data.account.maxUsers.low ? ` ${translate("$__of")} ${data.account.maxUsers.low}` : ``} */}
                    </div>
                    <div style={{ position: "relative", float: "left", width: "100px" }}>{diskUsed}</div>
                </div>
                {
                    viewAccountDetail === data.id
                        ?
                        <fieldset
                            style={{
                                borderRadius: 5,
                                // padding: 7,
                                width: "100%",
                                overflow: "auto",
                                position: "relative",
                            }}
                        >
                            <legend style={{ display: "flex" }}>{translate(`$__users`)}<CustomButton
                                transparent
                                title={translate(`$__hide`, "*")}
                                color={customApp("lightgrey")}
                                icon={"expand_less"}
                                onClick={() => {
                                    setViewAccountDetail(null)
                                }}
                                size={"25px"}
                            /></legend>

                            <div
                                style={{
                                    border: "solid 1px #f6f6f6",
                                    width: "100%",
                                    padding: 3,
                                    overflowY: "auto",
                                    display: "flex",
                                    fontWeight: "bold",
                                    color: customApp("menu"),
                                    fontSize: "11px"
                                }}
                            >
                                <div style={{ position: "relative", float: "left", width: "100px" }}
                                    onClick={() => {
                                        u_orderBy("id")
                                    }}
                                >#{translate("$__id")}</div>
                                <div style={{ position: "relative", float: "left", width: "150px" }}
                                    onClick={() => {
                                        u_orderBy("name")
                                    }}
                                >{translate("$__name")}</div>
                                <div style={{ position: "relative", float: "left", width: "300px" }}
                                    onClick={() => {
                                        u_orderBy("email")
                                    }}
                                >{translate("$__email")}</div>
                                <div style={{ position: "relative", float: "left", width: "150px" }}
                                    onClick={() => {
                                        u_orderBy("createdAt")
                                    }}
                                >{translate("$__createdDate")}</div>
                                <div style={{ position: "relative", float: "left", width: "150px" }}
                                    onClick={() => {
                                        u_orderBy("lastSession")
                                    }}
                                >{translate("$__lastSession")}</div>
                                {/* <div style={{ position: "relative", float: "left", width: "100px" }}>{translate("$__infos")}</div> */}
                            </div>
                            {data.users
                                .sort((a, b) => {
                                    let A = ""
                                    let B = ""
                                    if (userOrderBy === "id") {
                                        A = a.id
                                        B = b.id
                                    }
                                    if (userOrderBy === "name") {
                                        A = a.name.toLowerCase()
                                        B = b.name.toLowerCase()
                                    }
                                    if (userOrderBy === "email") {
                                        A = a.email.toLowerCase()
                                        B = b.email.toLowerCase()
                                    }
                                    if (userOrderBy === "createdAt") {
                                        A = a.created_at
                                        B = b.created_at
                                    }
                                    if (userOrderBy === "lastSession") {
                                        A = a.lastSession
                                        B = b.lastSession
                                    }
                                    if (A < B)
                                        return userOrderASC ? -1 : 1
                                    if (A > B)
                                        return userOrderASC ? 1 : -1
                                    return 0
                                })
                                .map((us, usi) => (
                                    <User data={us} index={usi} key={usi} />
                                ))}
                        </fieldset>
                        :
                        <React.Fragment></React.Fragment>
                }
            </div>)
    }
    return (
        <div style={{ width: "100%" }}>
            <ExportAll data={AccountsExport} />
            <div
                style={{
                    border: "solid 1px #f6f6f6",
                    width: "100%",
                    padding: 3,
                    overflowY: "auto",
                    display: "flex",
                    fontWeight: "bold",
                    color: customApp("menu")
                }}
            >
                <div style={{ position: "relative", float: "left", width: "100px" }}>#{translate("$__id")}</div>
                <div style={{ position: "relative", float: "left", width: "300px" }}>{translate("$__company")}</div>
                <div style={{ position: "relative", float: "left", width: "200px" }}>{translate("$__createdDate")}</div>
                <div style={{ position: "relative", float: "left", width: "200px" }}>{translate("$__plan")}</div>
                <div style={{ position: "relative", float: "left", width: "100px" }}>{translate("$__users")}</div>
                <div style={{ position: "relative", float: "left", width: "100px" }}>{translate("$__diskUsed")}</div>
            </div>
            <div>

                {Accounts
                    .filter(x => !viewAccountDetail || viewAccountDetail === x.id)
                    .sort((a, b) => {
                        let nameA = a.account.name.toLowerCase()
                        let nameB = b.account.name.toLowerCase()
                        if (nameA < nameB)
                            return -1
                        if (nameA > nameB)
                            return 1
                        return 0
                    })
                    .map((acc, i) => <Item index={i} data={acc} key={acc.account._id} />)}
            </div>
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    store: {
        languages: store.languages
    }
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MasterAdminAccountView));
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import Icon from "components/Icon"
// import TagsMenu from "components/Tags/menu"
import ChatCountIcon from "components/Chat/countIcon"

//DEPENDENCIES
import SubMenu from "./subMenu"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//IMAGES
import Bright from "assets/icons/BrigthAndConnected"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sidebarInteractive.js"

//FUNCTIONS
import { translate, customApp } from "functions/"

import packageJson from './../../../package.json';
import ErrorBoundary from "components/ErrorBoundary"

require("dotenv").config()

function Component(props) {
    const { classes } = props
    const [viewModule, setViewModule] = React.useState(null);

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    const openModule = async (route, vm) => {
        if (props.subMenuExpanded)
            props.setSubMenuExpanded(false)
        if (route && (window.location.pathname !== route || vm === viewModule)) {
            history.push(route)
        }

        if (vm === "chat") {
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...reduxStore.getState().chat,
                open: true,
            })
        } else {
            setViewModule(vm)
            props.reduxFunction("ASYNC", "SET_CHAT", {
                ...reduxStore.getState().chat,
                open: false,
            })
        }
    }

    return (
        <ErrorBoundary
            info={{
                path: "components/Sidebar/Index",
            }}
        >
            <div
                className={classes.root}
            >
                <div
                    className={classes.bar}
                    style={{
                        background: customApp("menuColor")
                    }}
                    id="sideBar"
                >
                    <div className={classes.logoMini}
                        id="sideBar_logoMini"
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            display: "flex",
                            cursor: "pointer",
                            color: customApp("menuButtonColor"),
                            alignItems: "center"
                        }}

                    >
                        <div
                            style={{
                                backgroundImage: `url("${customApp("customLogo")}")`,
                                width: "calc(100% - 70px)",
                                height: "auto",
                                marginLeft: "15px",
                                minHeight: "min-content",
                                cursor: "pointer",
                                paddingTop: "33%",
                                backgroundPosition: "center center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat"
                            }}
                            onClick={() => {
                                openModule("/d#tab/resume", "dashboard")
                            }}
                        ></div>
                        <div
                            style={{
                                padding: "7px",
                                marginRight: "7px",
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                props.menuExpand()
                            }}
                            id={"sideBar_button_hideMenu"}
                        >
                            <Icon
                                icon={"menu"}
                                color="parent"
                                title="$__hideMenu"
                                size={"22px"}
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            top: "100px",
                            bottom: "0px",
                            overflowX: "auto",
                            width: "100%"
                        }}
                    >
                        <div className={classes.item} id="sideBar_button_dashboard">
                            <div
                                className={window.location.pathname === "/d" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/d#tab/resume", "dashboard")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon="dashboard"
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title="$__dashboard"
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate(`$__dashboard`, "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_brightAndConnected">
                            <div className={window.location.pathname === "/bright-and-connected" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/bright-and-connected#tab/home", "bright-and-connected")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon={Bright}
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={"BRIGHT & CONNECTED"}
                                    size={"21px"}
                                    style={{
                                        marginRight: "7px"
                                    }}
                                />
                                BRIGHT & CONNECTED
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_StrategyAndPlans">
                            <div className={window.location.pathname === "/sp" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/sp#tab/home", "StrategyAndPlans")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon="view_comfy"
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__StrategyAndPlans")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate(`$__StrategyAndPlans`, "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_myTasks">
                            <div className={window.location.pathname === "/t" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/t#tab/myTasks", "myTasks")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon="task"
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__activities")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate(`$__activities`, "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_library">
                            <div className={window.location.pathname === "/library" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/library", "library")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon="folder"
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__library")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate(`$__library`, "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_tags">
                            <div
                                className={window.location.pathname === "/tags" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/tags", "tags")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon={"turned_in"}
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__tags")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate("$__tags", "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_calendar">
                            <div className={window.location.pathname === "/calendar" ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    openModule("/calendar", "calendar")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon={"event"}
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__calendar")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate("$__calendar", "*")}
                            </div>
                        </div>
                        <div className={classes.separator}></div>
                        <div className={classes.item} id="sideBar_button_chat">
                            <div className={reduxStore.getState().chat.open ? classes.itemButtomActive : classes.itemButtom}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_CHAT", {
                                        ...reduxStore.getState().chat,
                                        open: !reduxStore.getState().chat.open,
                                    })

                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <ChatCountIcon>
                                    <Icon
                                        icon={"chat"}
                                        color={"parent"}
                                        title="$__chat"
                                        size={"21px"}
                                        style={{
                                            marginBottom: "0px",
                                            marginRight: "7px"
                                        }}
                                    />
                                </ChatCountIcon>
                                {translate(`$__chat`, "*")}
                            </div>
                        </div>
                        <div className={classes.item} id="sideBar_button_calendar">
                            <div className={classes.itemButtom}
                                onClick={() => {
                                    window.open("https://support.machen.ai", "_blank")
                                }
                                }
                                style={{
                                    color: customApp("menuButtonColor")
                                }}
                            >
                                <Icon
                                    icon={"help_outline"}
                                    badgeColor={"primary"}
                                    color={"parent"}
                                    badgeVisible
                                    title={translate("$__support")}
                                    size={"21px"}
                                    style={{
                                        marginBottom: "0px",
                                        marginRight: "7px"
                                    }}
                                />
                                {translate("$__support", "*")}
                            </div>
                        </div>

                    </div>
                    {
                        props.subMenu
                        &&
                        <div
                            className={props.subMenuExpanded ? classes.moduleExpanded : classes.module}
                            id="sideBar_subMenu"
                            style={{
                                background: customApp("subMenuColor"),
                                borderRight: `4px solid ${customApp('color')}`
                            }}
                        >
                            <SubMenu
                                hashs={hashs}
                                subMenuExpanded={(a) => { props.setSubMenuExpanded(a) }}
                                expanded={props.subMenuExpanded}
                            />
                        </div>
                    }
                    {/* {(
                        viewModule !== "myDay"
                        && viewModule !== "timer"
                        && viewModule !== "chat"
                    )
                        &&
                        (
                            window.location.pathname === "/tags"
                        )
                        &&
                        <div
                            className={classes.moduleExpanded}
                            id="sideBar_session_tags"
                        >
                           
                        </div>
                    } */}
                    <div style={{ position: "absolute", bottom: 0, left: 15, color: "#c0b283", fontSize: "10px" }}>V:{packageJson.version}</div>
                </div>
            </div>
        </ErrorBoundary>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(Component))
import React from 'react'
import PropTypes from "prop-types";

//COMPONENTES
import Icon from 'components/Icon';

//@MATERIAL
import { Avatar, Tooltip } from '@material-ui/core'

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//FUNCTIONS
import { stringLength, stringToColor, stringAvatar, customApp, translate } from 'functions';

function User(props) {
  const { selectUser } = props;
  const { db, session } = reduxStore.getState();

  return (
    <>
      <div
        style={{
          height: "fit-content",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          backgroundColor: "#fff",
          borderRadius: "3px",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingBottom:'20px',
          width: '40vw',
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "space-between",
            borderBottom: "#e8e8e8 1px solid",
            padding: "7px 15px",
            color: customApp('colorText'),
            background:
              customApp('ColumnTitleColor'),
            width:'100%',
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <Icon style={{color: customApp('color')}}>notes</Icon>
            <p
              style={{
                fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
                fontWeight: "600",
                margin:'0px',
              }}
            >
              {translate('$__userData')}
            </p>
          </div>
          <Icon
            style={{cursor:'pointer', color: customApp('color')}}  
            onClick={() => {
              props.reduxFunction("ASYNC", "SET_MODULE", {
                  ...db.sideModule,
                  id: session?._id,
                  activeModule: 'userEdit',
                  db: 'users',
                  data: {
                      type: 'user',
                      ...reduxStore.getState().db.users[selectUser?._id],
                  }
              })
            }}
          >edit</Icon>
        </div>
        <Avatar 
          src={selectUser?.image} 
          {...stringAvatar(selectUser?.nomecompleto)} 
          style={{
            width: "80px", 
            height: "80px", 
            marginTop:'20px'
          }}
        />
        <fieldset
          style={{
            borderColor: "rgb(224 224 224)",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            width: "80%",
            marginBottom: "10px",
          }}
        >
          <legend
            style={{
              padding: "0px 5px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
              color: "rgb(192,178,131)",
              margin: "0px",
            }}
          >
            {translate("$__completeName")}
          </legend>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip title={selectUser?.nomecompleto}>
              <p>{stringLength(selectUser?.nomecompleto, 32)}</p>
            </Tooltip>
          </div>
        </fieldset>
        <fieldset
          style={{
            borderColor: "rgb(224 224 224)",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            width: "80%",
            marginBottom: "10px",
          }}
        >
          <legend
            style={{
              padding: "0px 5px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
              color: "rgb(192,178,131)",
              margin: "0px",
            }}
          >
            {translate("$__email")}
          </legend>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip title={selectUser?.email}>
              <p>{stringLength(selectUser?.email, 32)}</p>
            </Tooltip>
          </div>
        </fieldset>
        <fieldset
          style={{
            borderColor: "rgb(224 224 224)",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            width: "80%",
            marginBottom: "10px",
          }}
        >
          <legend
            style={{
              padding: "0px 5px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
              color: "rgb(192,178,131)",
              margin: "0px",
            }}
          >
            {translate("$__company")}
          </legend>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <p>{session?.GlobalData.fullName}</p>
          </div>
        </fieldset>
        <fieldset
          style={{
            borderColor: "rgb(224 224 224)",
            borderStyle: "solid",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            width: "80%",
            marginBottom: "10px",
          }}
        >
          <legend
            style={{
              padding: "0px 5px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
              color: "rgb(192,178,131)",
              margin: "0px",
            }}
          >
            {translate("$__department/group")}
          </legend>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip title={selectUser?.['departamento/grupo']
            ?.filter(a => a.value)
            ?.sort((a, b) => {
              if (a.label < b.label)
                return -1
              return 1
             }).map((item, i) => `${i > 0 ? ` / ` : ``}${item.label}`)}>
              <p>{selectUser?.['departamento/grupo']
                ?.filter(a => a.value)
                ?.sort((a, b) => {
                  if (a.label < b.label)
                    return -1
                  return 1
                }).map((item, i) => `${i > 0 ? ` / ` : ``}${item.label}`)
                .map((group) => stringLength(group, 32))}
              </p>
            </Tooltip>
          </div>
        </fieldset>
      </div>
    </>
  );
}
User.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(User);

import React from 'react'

//COMPONENTS
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import HeaderWithIcon from "components/Header/withIcon"
import Timeline from "componentsV3/Timeline"
import Icon from 'components/Icon'
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck'

//FUNCTIONS
import { translate } from "functions"
import { countNotifications } from "functions/cards"


const TimeLine = (props) => {

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const [timelineParams, setTimelineParams] = React.useState({
        type: 'comment',
        disabledSend: false
    })
    let notifications = countNotifications(hashs[0])


    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div style={{ height: '100%', overflow: 'hidden' }}>
                        <UiCard style={{ marginTop: '0', height: '100%' }}>
                            {props.edit ? <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                                <Icon size={24} style={{ alignSelf: 'center', marginLeft: '17px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', marginTop: '1px' }}>blur_on</Icon>
                                <p style={{ margin: '0px', marginLeft: '7px', alignSelf: 'center', fontWeight: 'bold', marginTop: '2px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', fontSize: '16px' }}>{translate(`$__timeline`, 1)}</p>
                                <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                    <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                        close
                                    </Icon>
                                </div>
                                <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                    <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', position: 'absolute', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                        open_with
                                    </Icon>
                                </div>
                            </div> :
                                <HeaderWithIcon
                                    title={translate(`$__timeline`, 1)}
                                    icon={'blur_on'}
                                    color={props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)'}
                                    style={{
                                        background: props.styles.backgroundColor ? props.styles.backgroundColor : 'white',
                                        padding: '10px',
                                        borderRadius: '5px 5px 0px 0px ',
                                        fontWeight: 'normal',
                                        borderBottom: '1px solid #D3D3D3'
                                    }}

                                    // moreButton
                                    // optionsProps={{ transparent: true, btColor: customApp('color') }}
                                    customButtons={[
                                        {
                                            name: "$__timeline",
                                            icon: "timeline",
                                            actionsSize: '22px',
                                            count: notifications.comment,
                                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                                            onClick: () => {
                                                setTimelineParams({
                                                    type: 'comment',
                                                    disabledSend: false
                                                })
                                            },
                                        },
                                        {
                                            name: "$__log",
                                            icon: "history",
                                            actionsSize: '22px',
                                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                                            count: notifications.log,
                                            onClick: () => {
                                                setTimelineParams({
                                                    type: 'log',
                                                    disabledSend: true
                                                })
                                            },
                                        },
                                        {
                                            name: "$__timer",
                                            icon: "timer",
                                            actionsSize: '22px',
                                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                                            count: notifications.timer,
                                            onClick: () => {
                                                setTimelineParams({
                                                    type: 'timer',
                                                    disabledSend: true
                                                })
                                            },
                                        },
                                        {
                                            name: "$__kpi",
                                            icon: "analytics",
                                            actionsSize: '22px',
                                            color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)',
                                            count: notifications.kpi,
                                            onClick: () => {
                                                setTimelineParams({
                                                    type: 'kpi',
                                                    disabledSend: true
                                                })
                                            },
                                        }
                                    ]}
                                    actionButtonsStyle={{
                                        top: '-9px !important'
                                    }}

                                />}
                            {inViewport && <UiCardBody>
                                <Timeline {...{
                                    nodeId: hashs[0],
                                    db: 'cards',
                                    loadTimeline: true,
                                    ...timelineParams,
                                }} />
                            </UiCardBody>}
                        </UiCard>
                    </div>
                )}
            </ViewportChecker>
        </div>
    )
}

export default TimeLine
import React from "react";

//COMPONENTS
import Icon from "components/Icon";

//@MATERIAL
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { translate, customApp } from "functions/";


const useStyles = makeStyles((props) => ({
    iconButtonStyleTransaparent: {
        margin: 0,
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        }
    },
    iconButtonStyleWhite: {
        margin: 0,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        }
    },
}));

function Comp(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation()
            }}
            style={{ zIndex: 100 }}
        >
            <Tooltip
                title={translate(`$__options`, 1)}
            >
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    aria-label="menu"
                    onClick={(e) => {
                        setOpen((prevOpen) => !prevOpen);
                        if (props.onClick)
                            props.onClick()
                        e.stopPropagation()
                    }}
                    className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                >
                    <Icon
                        usePropagation
                        size={!props.size ? "20px" : props.size}
                        color={props.iconColor ? props.iconColor : customApp('menu')}
                        icon={props.icon ? props.icon : 'design_services'}
                        title={props.title ? props.title : "NO_ALIAS"}
                    />
                </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                    }}
                                >
                                    {React.cloneElement(props.children, {
                                        callBack: (e) => {
                                            setOpen(false);
                                        }
                                    })}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div >
    )
}
export default Comp;

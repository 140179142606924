import React from "react";
import { v4 as uuid } from "uuid";
import moment from "moment-timezone"

//COMPONENTS
import TextField from "components/TextField";
import CustomButton from "components/Buttons/custom";


//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store"

//FUNCTIONS
import { translate, customApp, appAlert, formatNumber } from "functions/";
import { kpiTimerDelete } from "functions/cards";
import { timelineComment } from "functions/chat"

import api from "api"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/KPI_change";

import "assets/css/components/chart.css";
require("chartist-plugin-tooltips");

const Component = (props) => {
    const { classes } = props
    const { session, db } = reduxStore.getState()
    const [description, setDescription] = React.useState('')
    const [value, setValue] = React.useState(0)

    const timer = props.timer || {}
    const data = props.data || {}

    const changeKPI = async (d, b) => {
        let newValue = String(value)
        if (d.key.toLowerCase() === 'backspace') {
            newValue = newValue.substr(0, newValue.length - 1)
        } else {
            newValue = `${newValue ? newValue : ``}${!isNaN(d.key) || ((d.key === ',' || d.key === '.') && newValue.indexOf(',') === -1 && newValue.indexOf('.') === -1) ? d.key : ``}`
        }
        if (newValue && newValue.indexOf(',') > -1)
            newValue = newValue.substr(0, newValue.indexOf(',') + 3)

        setValue(newValue)
    }

    if (!data)
        return (null)

    const cancel = () => {
        if (props.callBack)
            props.callBack()
    }
    const save = async () => {
        if (!props.timer && !description) {
            appAlert({
                message: translate('$__fillDescription', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'center',
            })
        } else {
            let newValue = isNaN(value) ? value.replace(',', '.') : value

            let dataSave = {
                _id: uuid(),
                cardId: data._id,
                idRel: data._id,
                value: !props.PositiveValue ? `-${newValue}` : newValue,
                type: 'kpi',
                ...props.manualEntry ? { manualEntry: true } : {},
                kpi_type: data.kpi_type ? data.kpi_type : null,
                message: description || ''
            }
            if (props.timer)
                dataSave = {
                    ...dataSave,
                    value: timer.diff,
                    ini: timer.created_at,
                    fin: timer.updated_at,
                    type: 'timer',
                    kpi_type: 'timer',
                    to: timer.to
                }

            let comment = {
                ...dataSave,
                created_at: `${moment().unix('x')}`,
                db: "cards",
                node: data._id,
                user: {
                    _id: session._id,
                    name: db.users[session._id].name,
                    image: db.users[session._id].image || null,
                },
            }

            timelineComment({
                reduxFunction: props.reduxFunction
            }, {
                ...dataSave,
                comment
            })

            api.post("timeline/add", {
                ...dataSave
            })



            if (props.callBack)
                props.callBack()
            if (props.timer && !props.manualAdd)
                kpiTimerDelete(props, data._id, timer._id)

            setValue(0)
            setDescription('')
        }
    }

    if (!props.data)
        return (<div>NO DATA RECEIVED</div>)

    return (
        <div className={classes.dataSave}>
            <fieldset>
                <legend
                    style={{ color: customApp('color') }}>
                    {translate('$__new', 1)}
                </legend>
                {!props.timer &&
                    <TextField
                        placeholder={translate('$__value', 1)}
                        value={formatNumber(value ? value : 0, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            onKeyDown: (d) => {
                                changeKPI(d, 'ini')
                            },
                        }}
                    />
                }
                <TextField
                    multiline
                    placeholder={translate('$__description', 1)}
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                    InputProps={{
                        autoComplete: 'new-password',
                    }}
                />
                <div style={{ display: 'flex', textAlign: 'right' }}>
                    <CustomButton
                        title={translate(`$__save`, 1)}
                        color='green'
                        icon={'save'}
                        onClick={() => save()}
                        size={'25px'}
                        transparent
                    />
                    {(!props.timer || props.manualAdd) &&
                        <CustomButton
                            title={translate(`$__cancel`, 1)}
                            color='lightgrey'
                            icon={'cancel'}
                            onClick={() => cancel()}
                            size={'25px'}
                            transparent
                        />
                    }
                </div>
            </fieldset>
        </div>
    );
}
//REACT
const mapStateToProps = (store) => ({});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Component));
//CORE
import React from "react"
import moment from "moment-timezone"
import MD5 from "crypto-js/md5"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import history from "store/history"

//COMPONENTS
import AgileStatus from "components/AgileV2/status"
import Filter from "componentsV3/Filter/menuFilter"
import HeaderWithIcon from "components/Header/withIcon"
import CardsLoader from "components/Card/loader"
import SelectCardTypes from "componentsV3/SelectCardTypes"

//FUNCTIONS
import { customApp } from "functions/"
import { countNotifications } from "functions/cards"

function MyTasksView(props) {
    const { db, session } = reduxStore.getState()
    const { dataType } = db
    const [disabledTypes, setDisabledTypes] = React.useState([])

    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD')

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()

    if (!window.location.hash.split('/')[0].replace('#', '')) {
        history.push('t#tab/myTasks')
    }

    if (!db.cards)
        return (null)

    let myCards = Object.keys(props.cards).filter(a =>
        props.cards[a]
        && props.cards[a].type !== 'chatGroup'
        && props.cards[a].type !== 'plan'
        && props.cards[a].type !== 'step'
        && dataType.filter(dt => dt.name === props.cards[a].type
            && dt.module !== "swot"
            && dt.module !== "step"
        ).length > 0
        &&
        (
            !props.cards[a].checklist
            ||
            (
                props.cards[a].checklist
                && props.cards[a].showCard
            )
        )
        && !props.cards[a].deleted
    ).map(c => props.cards[c])

    if (!reduxStore.getState().session.cards.adminView) {
        myCards = myCards.filter(c =>
            c._users
            && Object.keys(c._users).filter(u => u === session._id).length > 0
            && (
                (c._createdBy
                    && !c._createdBy.channel
                )
                || (
                    c._createdBy
                    && c._createdBy.channel
                    && c._createdBy._id === session._id
                )
            )
        )
    } else {
        myCards = myCards.filter(c => {
            if (
                !c.private
                || (
                    c.private
                    && c._users
                    && Object.keys(c._users).filter(u => u === session._id).length > 0

                )
            )
                return true
            return false
        })
    }


    if (
        (
            hashs[0] === 'tab'
            && hashs[1] !== 'myDay'
        )
        && disabledTypes
        && disabledTypes.length > 0
    )
        myCards = myCards.filter(c =>
            disabledTypes.indexOf(c.type) === -1
        )


    if (hashs[0] === 'tab' && hashs[1] === 'unreaded') {
        myCards = myCards.filter(c => {
            let notifications = countNotifications(c._id)
            if (notifications.total > 0) return true
            return false
        }
        )
    }
    if (hashs[0] === 'tab' && hashs[1] === 'myTasks') {
        myCards = myCards.filter(a =>
            a._users
            && a._users[session._id]
            && (
                a._users[session._id].isResponsible
                ||
                (
                    !a._users[session._id].isResponsible
                    && Object.keys(a._users).length === 1
                )
            )
        ).map(a => a)
    }


    if (hashs[0] === 'tab' && hashs[1] === 'myDay') {
        myCards = myCards.filter(a =>
            a._users
            && a._users[session._id]
            && a._users[session._id].myDay
            && (moment(new Date(a._users[session._id].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
            )
        ).map(a => a)
    }
    if (hashs[0] === 'tab' && hashs[1] === 'ourDay') {
        myCards = myCards.filter(a => {
            if (a && a._users) {
                let result = Object.keys(a._users).filter(us =>
                    us && a._users[us] && a._users[us].myDay && (moment(new Date(a._users[us].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today)
                )
                if (result.length > 0)
                    return true
                return false
            } else {
                return false
            }
        }
        ).map(a => a)
    }

    if (hashs[0] === 'tab' && hashs[1] === 'private') {
        myCards = myCards.filter(c =>
            c.private
        ).map(a => a)
    }

    if (hashs[0] === 'tab' && hashs[1] === 'nextTasks') {
        myCards = myCards.filter(a =>
        (
            (a.endDate
                && moment(new Date(a.endDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
                && moment(new Date(a.endDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") <= moment(new Date(today)).add(10, "days").format("YYYY-MM-DD")
            )
            ||
            (a.startDate
                && moment(new Date(a.startDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")
                && moment(new Date(a.startDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") <= moment(new Date(today)).add(10, "days").format("YYYY-MM-DD")
            )
        )
        ).map(a => a)
    }

    if (hashs[0] === 'tab' && hashs[1] === 'lateTasks') {
        myCards = myCards.filter(c =>
            c.endDate && moment(new Date(c.endDate * 1000)).tz('America/Sao_Paulo').format() <= moment(new Date(today)).tz('America/Sao_Paulo').format() && c.status !== 'completed'
        ).map(a => a)
    }

    if (hashs[0] === 'tab' && hashs[1] === 'unlinkedCards') {
        myCards = myCards.filter(c =>
            !c.parent && !c._parent
        ).map(a => a)
    }

    return (
        <div style={props.expanded ? { width: 'calc(100% - 220px)', marginLeft: '220px' } : { width: 'calc(100% - 0px)', marginLeft: '0px' }}>

            <div style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    {(
                        hashs[0] === 'tab'
                        && hashs[1] !== 'myDay'
                    ) ?
                        <SelectCardTypes options={disabledTypes} onChange={(d) => { setDisabledTypes(d) }} />
                        : <></>
                    }
                    <Filter
                        fields={['text', 'date', 'users', 'responsible', 'tags', 'tagsByText', 'priority', 'complexity', 'risk', 'impact']}
                        showFilters
                        nodeId={searchID}
                        style={{ marginLeft: 7 }}
                        onChange={(e) => {
                            console.log(e)
                        }}
                    />
                </div>
                <HeaderWithIcon
                    title={null}
                    icon={null}
                    color={customApp('menu')}
                    style={{
                        background: 'transparent',
                        padding: '7px'
                    }}
                    customButtons={[
                        {
                            title: "$__orderByStartDate",
                            icon: "today",
                            color: props.agileOrderBy === "startDate" ? customApp('color') : customApp('menu'),
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().db,
                                    agileOrderBy: 'startDate'
                                })
                            },
                        },
                        {
                            title: "$__orderByEndDate",
                            icon: "event",
                            color: props.agileOrderBy === "endDate" ? customApp('color') : customApp('menu'),
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().db,
                                    agileOrderBy: 'endDate'
                                })
                            },
                        },
                        {
                            title: "$__orderByName",
                            icon: "sort",
                            color: props.agileOrderBy === "name" ? customApp('color') : customApp('menu'),
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().controls,
                                    agileOrderBy: 'name'
                                })
                            },
                        },
                        {
                            title: props.agileOrderDesc === 'DESC' ? "$__asc" : "$__desc",
                            icon: props.agileOrderDesc === 'DESC' ? "text_rotation_down" : "text_rotate_up",
                            color: customApp('menu'),
                            onClick: () => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().controls,
                                    agileOrderDesc: props.agileOrderDesc === 'DESC' ? 'ASC' : 'DESC'
                                })
                            },
                        },
                    ]}
                />
            </div>
            <div>
                <CardsLoader />
            </div>
            <div
                style={{
                    position: 'relative',
                    minHeight: 'calc(100vh - 150px)',
                    marginTop: '7px',
                    width: '100%',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
            >
                <AgileStatus cards={myCards.map(a => a._id)} inLine={db.viewList} showConnectedIn
                    columnStyle={{
                        ...db.viewList ? { width: "100%" } : { maxHeight: "calc(100vh - 190px)", width: "25%" },
                    }}
                />
                <div style={{ clear: 'both' }}></div>
            </div>
        </div >
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history,
    cards: store.db.cards,
    viewList: store.db.viewList,
    agileOrderBy: store.controls.agileOrderBy,
    agileOrderDesc: store.controls.agileOrderDesc,
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyTasksView)

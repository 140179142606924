import React from "react";
import { SketchPicker } from "react-color";

//@MATERIAL
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CustomButton from "components/Buttons/custom";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

//FUNCTIONS
import {
    translate,
    textColor,
} from "functions/";

import api from "api/";

function Comp(props) {
    const [open, setOpen] = React.useState(false);
    const [color, setColor] = React.useState(props.color ? props.color : "rgba(200,200,200,1)")

    const anchorRef = React.useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
        if (props.onClose)
            props.onClose()
    };

    const saveColor = async (color) => {
        if (props.onChange)
            props.onChange(color)
        setOpen(!open)

        if (props._id)
            await api.put("node/update", {
                _id: props._id,
                color: color
            })
    }

    return (
        <div className={"cardColorPicker"} style={{
            ...props.style ? props.style : {},
        }}
            onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
            }}
            onDrag={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onDragStart={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onDragEnter={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onDragCapture={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onDragEnterCapture={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onDragOver={(e) => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            {props.children ?
                <div
                    ref={anchorRef}
                    style={{
                        margin: 3,
                        cursor: 'pointer',
                    }}
                    onClick={(e) => {
                        setOpen(op => !op)
                        // e.preventDefault()
                        // e.stopPropagation()
                    }}
                >
                    <div
                        onClick={(e) => {
                            // e.preventDefault()
                            // e.stopPropagation()
                        }}
                    >
                        {props.children}
                    </div>
                </div>
                :
                <div
                    ref={anchorRef}
                    style={{
                        ...props.label ? {
                            borderRadius: 7,
                            padding: 7,
                            color: color ? textColor(color, true) : '#FFFFFF',
                            fontSize: 12
                        } : {
                            width: props.size ? props.size : 21,
                            height: props.size ? props.size : 21,
                        },
                        margin: 3,
                        backgroundColor: color,
                        borderRadius: props.size ? props.size : 21,
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        setOpen(op => !op)
                    }}
                >
                    {props.label ? props.label : ``}
                </div>
            }
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal={props.disablePortal ? true : false}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right center',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <div style={{
                                    position: "relative",
                                    backgroundColor: "#FFFFFF",
                                    boxShadow: "0px 0px 10px 5px rgba(0,0,0,0.17)",
                                    borderRadius: 7,
                                }} onClick={(e) => {
                                    e.stopPropagation()
                                }}>
                                    <div style={{ position: "relative", width: "100%", display: "flex", marginTop: "-5px", justifyContent: "end" }}>
                                        <CustomButton
                                            title={translate("$__cancel", 1)}
                                            color={'lightgrey'}
                                            icon={'close'}
                                            onClick={() => {
                                                setColor(props.color ? props.color : 'rgba(200,200,200,1)')
                                                setOpen(!open)
                                            }}
                                            size={'30px'}
                                        />
                                        {props.color ?
                                            <CustomButton
                                                title={translate("$__removeColor", 1)}
                                                color={'red'}
                                                icon={'format_color_reset'}
                                                onClick={() => { saveColor(null) }}
                                                size={'30px'}
                                            />
                                            : <React.Fragment></React.Fragment>
                                        }
                                        <CustomButton
                                            title={translate("$__saveColor", 1)}
                                            color={'green'}
                                            icon={'save'}
                                            onClick={() => { saveColor(color) }}
                                            size={'30px'}
                                        />
                                    </div>
                                    <SketchPicker
                                        color={color}
                                        onChange={(newColor, event) => {
                                            setColor(`rgba(${newColor.rgb.r}, ${newColor.rgb.g}, ${newColor.rgb.b}, ${newColor.rgb.a})`)
                                            event.stopPropagation()
                                        }}
                                        disableAlpha
                                    />
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
export default Comp;

import React from "react";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import File from "components/Files/file";
import LinearProgress from "components/Progress/linear";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import HeaderWithIcon from "components/Header/withIcon";

// @MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/search";


//FUNCTIONS
import { translate, customApp } from "functions/";
import apims from "apims"

function SearchComponent(props) {
  const [lastChange, setLastChange] = React.useState("")
  const [loader, setLoader] = React.useState(false)
  const [filteredItems, setfilteredItems] = React.useState([]);
  const { db } = reduxStore.getState()
  const { dataType } = db

  let lightBoxfiles = [];
  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  React.useEffect(() => {
    if (props.text.length > 1 && props.text !== lastChange) {
      setLastChange(props.text)
      const timer = setTimeout(() => searchFiles(), 1000);
      setLoader(true)
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text])

  const searchFiles = async () => {
    const res = await apims.get(`/Files_Search/?text=${props.text}`)
    if (res && res.data) {
      let newFiles = {
        ...filteredItems
      }
      res.data.forEach(file => {
        newFiles = {
          ...newFiles,
          [file.file._id]: {
            ...newFiles[file.file._id] || {},
            ...file.file,
            cardId: file.cardId || null,
            createdBy: file.createdBy || null,
            user: file.user,
            message: file.message,
            timelineId: file.timelineId
          }
        }
      })
      if (props.onFinish)
        props.onFinish(res.data.length)
      setfilteredItems(newFiles)
      setLoader(false)
    }
  }

  const openLightBox = (f) => {
    props.reduxFunction("ASYNC", "LIGHTBOX", {
      open: true,
      files: lightBoxfiles,
      file: f
    })
  }

  return (
    <div
      style={{ width: "100%" }}
    >
      <div style={{ positin: 'relative', width: '100%' }}>
        {loader &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <LinearProgress />
          </div>
        }
        <GridContainer>
          {Object.keys(filteredItems).length > 0 &&
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <HeaderWithIcon
                title={translate('$__files', 1)}
                icon={dataType.filter(a => a.name === 'plan')[0].icon}
                color={customApp('menu')}
                filterText={props.text}
                style={{
                  padding: '7px'
                }}
              />
              {Object.keys(filteredItems)
                .map((file, i) => {
                  lightBoxfiles.push(filteredItems[file])
                  return (
                    <GridItem
                      key={file}
                      md={12}
                    >
                      <File
                        data={filteredItems[file]}
                        searchText={props.text}
                        resume
                        showParent
                        openLightBox={() => {
                          openLightBox(filteredItems[file]._id)
                          if (props.onAction)
                            props.onAction()
                        }
                        }
                      />
                    </GridItem>
                  )
                })}
            </GridItem>
          }
        </GridContainer>
      </div>
    </div >
  );
}

const mapStateToProps = (store, ownProps) => {
  return {
    store,
    ownProps
  }
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchComponent))
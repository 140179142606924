import React, { useState } from 'react'
import * as XLSX from "xlsx";

//COMPONENTS
import PreLoader from "components/PreLoader"

//FUNCTIONS
import { translate } from 'functions';

const DragDrop = ({ handleData }) => {


    const [dragActive, setDragActive] = useState(false);
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    const styles = {
        formFileUpload: {
            height: "88%",
            width: "90%",
            marginTop: '5%',
            marginBottom: "5%",
            maxWidth: "100%",
            textAlign: "center",
            position: "relative",
        },
        inputFileUpload: {
            display: "none",
        },
        labelFileUpload: {
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: "2px",
            borderRadius: "1rem",
            borderStyle: "dashed",
            borderColor: "#cbd5e1",
            backgroundColor: "#f8fafc",
        },
        labelFileUploadDragActive: {
            backgroundColor: "#ffffff",
        },
        uploadButton: {
            cursor: "pointer",
            padding: "0.25rem",
            fontSize: "1rem",
            border: "none",
            fontFamily: "'Oswald', sans-serif",
            backgroundColor: "transparent",
        },
        uploadButtonHover: {
            textDecorationLine: "underline",
        },
        dragFileElement: {
            position: "absolute",
            width: "100%",
            height: "100%",
            borderRadius: "1rem",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
        },
    };

    const inputRef = React.useRef(null);

    const handleFileUpload = (file) => {
        setLoading(true)
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const parsedData = XLSX.utils.sheet_to_json(sheet);
            setTimeout(() => {
                setLoading(false);
                handleData(parsedData);
            }, 0);
        };
        if (file instanceof File) {
            reader.readAsBinaryString(file);
            setSelectedFile(file)
        } else {
            reader.readAsBinaryString(file.target.files[0]);
            setSelectedFile(file.target.files[0]);
        }
    };


    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            console.log(e.dataTransfer.files[0])
            handleFileUpload(e.dataTransfer.files[0]);
        }
    };


    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
        }
    };
    const handleBrowseClick = () => {
        document.getElementById('selectedFile').click();
    };


    return (
        <form
            style={styles.formFileUpload}
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
        >
            <input
                ref={inputRef}
                type="file"
                style={styles.inputFileUpload}
                multiple={true}
                onChange={handleChange}
            />
            <label
                className={dragActive ? "label-file-upload drag-active" : "label-file-upload"}
                style={dragActive ? { ...styles.labelFileUpload, ...styles.labelFileUploadDragActive } : styles.labelFileUpload}
            >
                <div>
                    {selectedFile ?
                        <p>{translate("$__youSendedArchive")} "{selectedFile.name}"</p>
                        : <p>{translate("$__putAnArchive")}</p>
                    }

                    {loading && <PreLoader />}
                    <input
                        type="file"
                        style={{ alignSelf: "center", display: "none" }}
                        accept=".xlsx, .xls"
                        id="selectedFile"
                        onChange={handleFileUpload}
                    />
                    <input
                        style={{
                            marginTop: '10px',
                            backgroundColor: '#0095ff',
                            color: 'white',
                            cursor: 'pointer',
                            border: 'none',
                            borderRadius: '5px',
                            padding: ' 10px 20px'
                        }}
                        type="button"
                        value={translate("$__search...")}
                        onClick={handleBrowseClick}
                    />
                </div>
            </label>
            {dragActive && (
                <div
                    style={styles.dragFileElement}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}
        </form>
    )
}

export default DragDrop
//CORE
import React from 'react'

//@Material
import Icon from "@material-ui/core/Icon"
import Tooltip from "@material-ui/core/Tooltip"

//@MATERIAL
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Badges from '@material-ui/core/Badge';

//FUNCTIONS
import { translate, customApp } from "functions/";

const useStyles = makeStyles(() => ({
    customColor: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiBadge-badge': {
            backgroundColor: customApp('color'),
            color: customApp('light'),
            display: 'flex',
            alignItems: 'center',
            zIndex: '1500 !important',
        },
    },
    root: {
        '& .MuiBadge-badge': {
            display: 'flex',
            alignItems: 'center',
            zIndex: 20
        },
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: '7px',
        boxShadow: '0 0 3px 3px rgba(0,0,0,0.03)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1000 !important'
    },
}))(Tooltip);

export default function Component(props) {
    const classes = useStyles();
    const [badgeVisible, setBadgeVisible] = React.useState(props.badgeVisible || false)
    const toolTipOpen = props.tooltipprops
        && props.tooltipprops.open
        ? props.tooltipprops.open
        : props.tooltipprops
            && String(props.tooltipprops.open) === 'false'
            ? false
            : undefined

    return (
        <div
            className={classes.root}
        >
            <HtmlTooltip
                title={props.title && typeof props.title === 'string'
                    ? (
                        <React.Fragment>
                            <div style={{ color: customApp('darkText'), display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', height: 'auto' }}>
                                {translate(props.title, 1)}
                            </div>
                        </React.Fragment>
                    )
                    : props.title ? (
                        <React.Fragment>
                            <div className={classes.customColor}>
                                <div style={{ position: 'relative' }}>
                                    {props.title}
                                    {props.onClose &&
                                        <Icon
                                            style={{
                                                position: 'absolute',
                                                left: '5px',
                                                top: '0px',
                                                zIndex: 10,
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => props.onClose()}
                                            title="close"
                                        >close</Icon>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    )
                        : ''
                }
                className={null}
                open={toolTipOpen}
                disableFocusListener={props.tooltipprops && props.tooltipprops.disableFocusListener ? props.tooltipprops.disableFocusListener : false}
                disableHoverListener={props.tooltipprops && props.tooltipprops.disableHoverListener ? props.tooltipprops.disableHoverListener : false}
                disableTouchListener={props.tooltipprops && props.tooltipprops.disableTouchListene ? props.tooltipprops.disableTouchListene : false}
                onClose={() => {
                    if (props.tooltipprops && props.tooltipprops.onClose)
                        props.tooltipprops.onClose()
                }}
                interactive={typeof props.title !== 'string' && props.tooltipprops && props.tooltipprops.interactive ? props.tooltipprops.interactive : false}
            >
                <Badges
                    overlap="rectangular"
                    badgeContent={props.count}
                    style={{ opacity: 1, fontSize: '5px' }}
                    max={props.max || 9}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    invisible={props.count && !badgeVisible}
                >
                    {typeof props.icon === 'string' ?
                        <Icon
                            onMouseEnter={() => !props.badgeVisible && setBadgeVisible(true)}
                            onMouseLeave={() => !props.badgeVisible && setBadgeVisible(false)}
                            onClick={props.onClick ? (e) => {
                                props.onClick()
                                e.stopPropagation();
                            } : null
                            }
                            className={props.className}
                            style={{
                                width: props.size ? props.size : 'fit-content',
                                height: props.size ? props.size : 'fit-content',
                                fontSize: props.size || '22px',
                                color: props.color || customApp('medium'),
                                opacity: !props.disabled || badgeVisible ? '1' : '0.5',
                                cursor: props.onClick ? 'pointer' : '',
                                ...props.style
                            }}>
                            {props.icon}
                        </Icon>
                        :
                        <props.icon
                            onMouseEnter={() => !props.badgeVisible && setBadgeVisible(true)}
                            onMouseLeave={() => !props.badgeVisible && setBadgeVisible(false)}
                            onClick={props.onClick ? (e) => {
                                props.onClick()
                                e.stopPropagation();
                            } : null
                            }
                            className={props.className}
                            style={{
                                width: props.size || '22px',
                                height: props.size || '22px',
                                fontSize: props.size || '22px',
                                color: props.color || customApp('medium'),
                                opacity: !props.disabled || badgeVisible ? '1' : '0.5',
                                cursor: props.onClick ? 'pointer' : '',
                                ...props.style
                            }}
                        />
                    }
                </Badges>
            </HtmlTooltip >
        </div>
    )
}
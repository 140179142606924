import React, { useState } from "react";
//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history";

//@MATERIAL
import { Avatar, Select, MenuItem } from "@material-ui/core";

//COMPONENTES
import Icon from "components/Icon";
import PercentualBar from "components/Percentual/bar";

//DEPENDENCIES
import AccountData from "components/Account/edit";
import AccountPreffers from "components/Account/preffers";
import SubscriptionDetails from "components/Account/SubscriptionDetails";

//FUNCTIONS
import apims from "apims/";
import { translate, customApp, BytesConvert } from "functions";

require("dotenv").config();

const Overview = () => {
  const { session, db } = reduxStore.getState();
  const [usedSpace, setUsedSpace] = React.useState(0);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    try {
      const res = await apims.post("/Account_Space");
      if (res) setUsedSpace(parseInt(res.data));
    } catch (e) {
      console.log(e);
    }
  };
  let space = db.plans[0].settings.space;
  let subscriptionPlan = db.plans[0];
  if (
    session &&
    session.GlobalData &&
    String(session.GlobalData.plan) &&
    db.plans[session.GlobalData.plan] &&
    db.plans[session.GlobalData.plan].settings
  ) {
    space = db.plans[session.GlobalData.plan].settings.space;
    subscriptionPlan = db.plans[session.GlobalData.plan];
  }

  let percent = usedSpace * (100 / parseInt(space));

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        marginBottom: "50px",
      }}
    >
      <div
        style={{
          marginBottom: 15,
          backgroundColor: "#FFFFFF",
          borderRadius: 2,
          boxShadow: "0px 0px 7px 5px rgba(0,0,0,0.03)",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                background: customApp("AvatarBackground"),
              }}
            >
              <Icon icon="card_membership" size={30} color={"#FFFFFF"} />
            </div>
            <div style={{ marginLeft: 7, width: "calc(100% - 40px)" }}>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  color: customApp("menu"),
                  fontWeight: "bolder",
                  marginTop: 7,
                }}
              >
                {translate("$__subscriptionPlan", "*")}:{" "}
                {translate(subscriptionPlan.label, "*")}
              </div>
              <div
                style={{
                  width: "100%",
                  color: "mediumgray",
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {translate("$__adminLimit", 1)}:{" "}
                {subscriptionPlan.settings.admins}
              </div>
              {/* <a
                                href="#"
                                style={{
                                    position: "relative",
                                    top: '-7px',
                                    color: customApp("color"),
                                    fontSize: 10,
                                    fontWeight: 'bold'
                                }}>{translate("$__viewContract")}</a> */}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: 10,
                background: customApp("AvatarBackground"),
              }}
            >
              <Icon icon="storage" size={30} color={"#FFFFFF"} />
            </div>
            <div style={{ marginLeft: 7, width: "calc(100% - 40px)" }}>
              <div
                style={{
                  position: "relative",
                  marginTop: 7,
                  color: customApp("menu"),
                  fontWeight: "bolder",
                }}
              >
                {translate("$__usedStorage", "*")}
                <span
                  style={{
                    marginLeft: 15,
                    color: "red",
                    fontSize: 11,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("#tab/subscriptionUpgrade");
                  }}
                >
                  {translate("$__upgradeSubscription")}
                </span>
              </div>
              <PercentualBar
                value={Math.ceil(percent)}
                background={percent > 100 ? "red" : null}
              />
              <div>
                <span
                  style={{
                    color: percent > 100 ? "red" : "mediumgray",
                    fontSize: 12,
                    fontWeight: "bold",
                  }}
                >
                  {translate("$__used", 1)}: {BytesConvert(usedSpace)}
                </span>{" "}
                <span style={{ color: "lightgray", fontSize: 11 }}>
                  {translate("$__of")} {translate("$__accountSpace", 1)}:{" "}
                  {BytesConvert(space)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: "20px",
        }}
      >
        <div
          style={{
            width: "50%",
            height: "auto",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            backgroundColor: "#fff",
            borderRadius: "3px",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
              borderBottom: "#e8e8e8 1px solid",
              padding: "7px",
              color: customApp("colorText"),
              background: customApp("ColumnTitleColor"),
            }}
          >
            <Icon style={{color: customApp('color')}}>group</Icon>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {translate('$__accountData')}
            </span>
          </div>
          <div style={{ padding: "20px" }}>
            <AccountData
              data={session && session.GlobalData ? session.GlobalData : {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "50%",
            height: "auto",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            backgroundColor: "#fff",
            borderRadius: "3px",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
              borderBottom: "#e8e8e8 1px solid",
              padding: "7px",
              color: customApp("colorText"),
              background: customApp("ColumnTitleColor"),
            }}
          >
            <Icon style={{color: customApp('color')}}>manage_accounts</Icon>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {translate('$__preffers')}
            </span>
          </div>
          <div style={{ padding: "20px" }}>
            <AccountPreffers
              data={session && session.GlobalData ? session.GlobalData : {}}
            />
          </div>
        </div>
        <div
          style={{
            width: "50%",
            height: "auto",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            backgroundColor: "#fff",
            borderRadius: "3px",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
              borderBottom: "#e8e8e8 1px solid",
              padding: "7px",
              color: customApp("colorText"),
              background: customApp("ColumnTitleColor"),
            }}
          >
            <Icon style={{color: customApp('color')}}>draw</Icon>
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {translate('$__subscriptionDetails')}
            </span>
          </div>
          <div style={{ padding: "20px" }}>
            <SubscriptionDetails
              data={session && session.GlobalData ? session.GlobalData : {}}
              planDetails={subscriptionPlan}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Overview;

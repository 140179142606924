//CORE
import React from 'react'
import Moment from "moment-timezone"
import utf8 from "utf8"
import isValidUTF8 from "utf-8-validate"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


//COMPONENTS
import CardTags from "components/Tags/Card"
import CustomButton from "components/Buttons/custom"
import HtmlTooltip from "components/Tooltip/html"
import Icon from "components/Icon"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card.js"
import { translate, customApp } from 'functions'

import BrightAndConnectedIco from "assets/icons/BrigthAndConnected"


function Component(props) {
    const { data, classes } = props
    const [image, setImage] = React.useState(data.img)
    const [backSize, setBackSize] = React.useState({
        backgroundSize: "contain",
    })

    let cardRef = React.useRef({})

    React.useEffect(() => {
        if (!cardRef.current) return; // wait for the elementRef to be available
        if (props.onResize) {
            const resizeObserver = new ResizeObserver(() => {
                let newSize = cardRef.current.clientHeight
                props.onResize(newSize)
            });
            resizeObserver.observe(cardRef.current);
            return () => resizeObserver.disconnect(); // clean up 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendToInsight = () => {
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...reduxStore.getState().sideModule,
            id: 'new',
            activeModule: 'cardEdit',
            db: 'cards',
            data: {
                _id: 'new',
                type: 'link',
                name: data.title,
                description: data.contentSnippet,
                pubDate: data.isoDate ? data.isoDate : data.pubDate,
                image: data.img,
                url: data.link,
            }
        })
    }

    const onImgLoad = ({ target: img }) => {
        if (img && img.width && img.height) {
            if (img.width > img.height) {
                if (img.width / img.height > 1.75) {
                    setBackSize({
                        backgroundSize: "contain"
                    })

                } else {
                    setBackSize({
                        backgroundSize: "cover"
                    })
                }
            } else {
                setBackSize({
                    backgroundSize: "cover"
                })
            }
        }
    }

    return (
        <div className={classes.feed}
            ref={cardRef}
            onClick={props.clickChannel ? () => props.clickChannel() : null}
            style={{
                ...props.clickChannel ? { cursor: "pointer" } : {},
                position: 'relative'
            }}
        >
            <div style={{ display: "none" }}>
                <img alt="MACHEN_IMAGE" src={image} onError={() => {
                    setImage(null)
                }}
                    onLoad={onImgLoad}
                />
            </div>
            <div
                className={classes.image}
                style={
                    {
                        position: "relative",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        ...image ?
                            {
                                backgroundImage: `url("${image}")`,
                                ...backSize,
                            } : {
                                background: customApp('AvatarBackground'),
                            },
                    }
                }
                onClick={() => {
                    if (!props.clickChannel)
                        window.open(data.link, "_blank")
                }}
            >
                {!image &&
                    <div style={{ marginTop: "calc(-48% - 10px)", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon icon={BrightAndConnectedIco} size="39px" color={customApp("textColor")} />
                    </div>
                }
                {!props.clickChannel &&
                    <div
                        style={{
                            position: "absolute",
                            top: 7,
                            right: 7
                        }}
                    >
                        <CustomButton
                            title={translate(`$__sendToRadar`, '*')}
                            color={customApp('menu')}
                            icon={'settings_input_antenna'}
                            onClick={() => { sendToInsight() }}
                            size={'25px'}
                        />
                    </div>
                }
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        backgroundColor: "rgba(0,0,0,0.5)",
                        padding: "3px 7px",
                        color: "white",
                        fontSize: 11
                    }}
                >
                    {Moment(data.isoDate ? data.isoDate : data.pubDate).format('DD/MM/YYYY HH:MM')}
                </div>
            </div>

            <HtmlTooltip
                // arrow
                title={(
                    <React.Fragment>
                        {!props.clickChannel &&
                            <a href={data.link} target="_blank" rel="noopener noreferrer"
                                style={{ fontSize: "14px", fontWeight: "bold" }}>
                                {translate("$__clickHereToReadNews", 1)}
                            </a>
                        }
                    </React.Fragment>
                )
                } >
                <div className={classes.infos}
                    onClick={() => {
                        if (!props.clickChannel)
                            window.open(data.link, "_blank")
                    }}
                >
                    <div className={classes.linkTitle}>
                        <div
                            dangerouslySetInnerHTML={{ __html: data && data.title && isValidUTF8(data.title) ? data.title : data.title ? utf8(data.title) : `` }}>
                        </div>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: data.contentSnippet && isValidUTF8(data.contentSnippet) ? data.contentSnippet : data.contentSnippet ? utf8(data.contentSnippet) : `` }}
                        className={classes.linkContent}
                    />

                    <div className={classes.feedFooter}>
                        <div className={classes.feedLink} >
                            {!props.clickChannel &&
                                <a href={data.link} target="_blank" rel="noopener noreferrer" style={{ fontSize: "14px", fontWeight: "bold" }}>
                                    {translate("$__readMore", 1)}
                                </a>
                            }
                        </div>
                    </div>
                </div>
            </HtmlTooltip>
            <div style={{ top: -30 }}>
                <CardTags
                    options={props._tags}
                // expanded={}
                />
            </div>
        </div>
    )
}

const mapStateToProps = store => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
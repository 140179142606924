import React from "react"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//COMPONENTS
import Icon from "components/Icon"


import {
    customApp,
} from "functions/"

//STYLES
import styles from "./Card.css.js"


function FakeCardExpanded(props) {
    const { classes } = props
    return (
        <div>
            <div
                className={classes.infos}
            >
                <div
                    className={classes.imagePreview}
                    style={{
                        background: props.backgroundColor ? props.backgroundColor : customApp('AvatarBackground'),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <div style={{ marginTop: `calc(-48% - 10px)`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon icon={"lock"} size="39px" color={props.avatarIconColor ? props.avatarIconColor : customApp('AvatarIconColor')} />
                    </div>
                </div>
            </div>
        </div >
    )
}

//REACT
export default withStyles(styles)(FakeCardExpanded)
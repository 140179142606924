import React from "react";
import moment from "moment-timezone";
import { ResponsivePie } from "@nivo/pie";

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Checkbox from "components/Checkbox"
import CustomButton from "components/Buttons/custom"
import ExportTimer from "components/Timer/exportAll"
import GridContainer from "components/Grid/GridContainer"
import HeaderWithIcon from "components/Header/withIcon"
import Icon from "components/Icon"
import TextField from "components/TextField"
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import UiCardHeader from "components/Card/CardHeader"
import PreLoader from "components/PreLoader"

//@MATERIAL
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Avatar from "@material-ui/core/Avatar"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Tooltip from "@material-ui/core/Tooltip"

import { translate, customApp } from "functions"

import { loadTimeline } from "functions/chat"

import { getChildrens } from "functions/cards"

function Timesheet(props) {
    const { db, timeline } = reduxStore.getState()
    const [startDate, setStartDate] = React.useState(moment(new Date()).tz("America/Sao_Paulo").subtract(720, "d").format("YYYY-MM-DD"))
    const [endDate, setEndDate] = React.useState(moment(new Date()).tz("America/Sao_Paulo").format("YYYY-MM-DD"))
    const [exportTimers, setExport] = React.useState(false)
    const [selectedUsers, setSelectedUsers] = React.useState({})
    const [cards, setCards] = React.useState({})
    const [loading, setLoading] = React.useState(true)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    React.useEffect(() => {
        init()
        return () => {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        iniCards()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    const iniCards = async () => {
        let ncards = await getChildrens(props.nodeId)
        setCards({
            ...ncards,
            [props.nodeId]: true
        })
    }

    console.log(cards)

    const init = async () => {
        try {
            let lt = await loadTimeline(
                {
                    ...props,
                    fnIdentification: "StrategyAndPlans/Dashboard/Widgers/timeSheet/init()"
                },
                {
                    ids: [props.nodeId],
                    db: "cards",
                    type: "timer",
                    reqChildrens: true
                })
            if (lt) {
                setLoading(false)
            }
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    let timeSheet = {}
    let users = {}
    let timeSheetTotal = 0
    let totalSeconds = 0

    const convertSecondsToText = (diff, mini = false) => {
        let diffText = ``
        let diffTextMini = ``
        if (diff < 60) {
            diffText = `${diff} ${translate("$__seconds", 1)}`
            diffText = `${diff}s`
        } else if (diff > 3600) {
            let hours = Math.trunc(diff / 3600)
            diff = diff - (hours * 3600)
            let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
            diff = diff - (minutes * 60)
            let seconds = diff > 0 ? diff : 0
            diffText = `${hours} ${translate("$__hour", 1)}${hours > 1 ? "s" : ""} ${minutes} ${translate("$__minutes", 1)} ${seconds > 0 ? `${seconds} ${translate("$__seconds", 1)}` : ``}`
            diffTextMini = `${hours}H ${minutes}M ${seconds} S`
        } else {
            let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
            diff = diff - (minutes * 60)
            let seconds = diff > 0 ? diff : 0
            diffText = `${minutes} ${translate("$__minutes", 1)} ${seconds > 0 ? `${seconds} ${translate("$__seconds", 1)}` : ``}`
            diffTextMini = `${minutes}M ${seconds} S`
        }
        if (mini) return diffTextMini
        return diffText
    }


    Object.keys(cards).filter(a =>
        timeline[a]
        && (
            Object.keys(timeline[a]).filter(tm => timeline[a][tm].type === "timer").length > 0
        )
    ).forEach(a => {
        Object.keys(timeline[a]).filter(tm =>
            timeline[a][tm].type === "timer"
            && !timeline[a][tm].deleted
        ).forEach(ts => {
            if (timeline[a][ts]._toUser && Object.keys(timeline[a][ts]._toUser).length > 0) {
                Object.keys(timeline[a][ts]._toUser).forEach(userId => {
                    users = {
                        ...users,
                        [userId]: {
                            ...timeline[a][ts]._toUser[userId] ? timeline[a][ts]._toUser[userId] : {},
                        }
                    }
                })
            } else {
                users = {
                    ...users,
                    [timeline[a][ts].user._id]: {
                        ...timeline[a][ts].user,
                    }
                }
            }

        })
        Object.keys(timeline[a]).filter(tm =>
            timeline[a]
            && timeline[a][tm]
            && timeline[a][tm].type === "timer"
            && !timeline[a][tm].deleted
            &&
            (
                Object.keys(selectedUsers).length === 0
                ||
                (
                    Object.keys(selectedUsers).length > 0
                    && Object.keys(selectedUsers).filter(su =>
                        timeline[a]
                        && timeline[a][tm]
                        && timeline[a][tm].type
                        && timeline[a][tm].type === "timer"
                        && selectedUsers[su]
                        && timeline[a][tm].user
                        &&
                        (
                            (
                                timeline[a]
                                && timeline[a][tm]
                                && timeline[a][tm].user
                                && (
                                    !timeline[a][tm]._toUser
                                    || (
                                        timeline[a][tm]._toUser
                                        && !timeline[a][tm]._toUser[su]
                                    )
                                )
                                && timeline[a][tm].user._id === su
                            )
                            ||
                            (
                                timeline[a]
                                && timeline[a][tm]
                                && timeline[a][tm]._toUser
                                && timeline[a][tm]._toUser[su]
                                && timeline[a][tm]._toUser[su] !== timeline[a][tm].user._id
                            )
                        )
                    ).length > 0
                )
            )
        ).forEach(ts => {
            Object.keys(users).forEach(userId => {
                if (
                    userId
                    && timeline[a]
                    && timeline[a][ts]
                    && timeline[a][ts].type === "timer"
                    && !timeline[a][ts].deleted
                    && (
                        parseInt(timeline[a][ts].ini) >= moment(`${startDate} 00:00:01`).unix("X")
                        || parseInt(timeline[a][ts].ini) === 0
                    )
                    &&
                    (
                        parseInt(timeline[a][ts].fin) <= moment(`${endDate} 23:59:59`).unix("X")
                    )
                    && (
                        (
                            timeline[a][ts].user
                            && (
                                toString(timeline[a][ts].user._id) === toString(userId)
                                && (
                                    !timeline[a][ts]._toUser
                                    ||
                                    (
                                        timeline[a][ts]._toUser
                                        && Object.keys(timeline[a][ts]._toUser).length === 0
                                    )
                                )
                            )
                        )
                        || (
                            timeline[a][ts]._toUser
                            && Object.keys(timeline[a][ts]._toUser).length > 0
                            && timeline[a][ts]._toUser[toString(userId)]
                        )
                    )
                ) {
                    totalSeconds = totalSeconds + (parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini))
                    let time = (parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini))

                    if (timeSheet && timeSheet[userId] && timeSheet[userId].totalTimer) {
                        time = parseInt(timeSheet[userId].totalTimer) + (parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini))
                    } else {
                        time = parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini)
                    }

                    let diff = time
                    let diffText = ``
                    let diffTextMini = ``
                    if (time < 60) {
                        diffText = `${diff} ${translate("$__seconds", 1)}`
                        diffText = `${diff}s`
                    } else if (time > 3600) {
                        let hours = Math.trunc(diff / 3600)
                        diff = diff - (hours * 3600)
                        let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                        diff = diff - (minutes * 60)
                        let seconds = diff > 0 ? diff : 0
                        diffText = `${hours} ${translate("$__hour", 1)}${hours > 1 ? "s" : ""} ${minutes} ${translate("$__minutes", 1)} ${seconds > 0 ? `${seconds} ${translate("$__seconds", 1)}` : ``}`
                        diffTextMini = `${hours}H ${minutes}M ${seconds} S`
                    } else {
                        let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
                        diff = diff - (minutes * 60)
                        let seconds = diff > 0 ? diff : 0
                        diffText = `${minutes} ${translate("$__minutes", 1)} ${seconds > 0 ? `${seconds} ${translate("$__seconds", 1)}` : ``}`
                        diffTextMini = `${minutes}M ${seconds} S`
                    }

                    timeSheet = {
                        ...timeSheet,
                        [userId]: {
                            ...timeSheet[userId] ? timeSheet[userId] : {},
                            ...users[userId] ? users[userId] : {},
                            ...timeSheet && timeSheet[userId] && timeSheet[userId].totalTimer ?
                                { totalTimer: parseInt(timeSheet[userId].totalTimer) + (parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini)) }
                                : { totalTimer: parseInt(timeline[a][ts].fin) - parseInt(timeline[a][ts].ini) },
                            timerText: diffText,
                            timerTextMini: diffTextMini,
                            _times: {
                                ...timeSheet[userId] && timeSheet[userId]._times ? timeSheet[userId]._times : {},
                                [ts]: {
                                    ...timeline[a][ts],
                                    cardId: a
                                }
                            }
                        }
                        // }
                    }
                }
                timeSheetTotal = timeSheetTotal + 1
            })
        })
    })


    const TimeSheetData = (props) => {
        return (
            <div style={{ width: "100%", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                {props.data && props.data.name &&
                    <React.Fragment>
                        <div
                            style={{ width: "33px" }}
                        >
                            <Tooltip
                                title={props.data.name}
                            >
                                <Avatar alt={props.data.name} src={props.data.image || null} style={{ width: "33px", height: "33px" }}>
                                    {props.data.name.substr(0, 1)}
                                </Avatar>
                            </Tooltip>
                        </div>
                        <div style={{ marginLeft: "10px", fontWeight: "bold", color: customApp("menu"), width: "33%", minWidth: "150px" }}>{props.data.name}</div>
                    </React.Fragment>
                }
                <div style={{ marginLeft: "10px", fontWeight: "bold", color: customApp("menu") }}>{props.data.timerText}</div>
            </div>
        )
    }

    const selectUser = (pr) => {
        if (selectedUsers[pr] && Object.keys(selectedUsers).filter(a => selectedUsers[a]).length <= 1) {
            setSelectedUsers({})
        } else {
            setSelectedUsers({
                ...selectedUsers,
                [pr]: !selectedUsers[pr],
            })
        }
    }

    return (
        <UiCard>
            <HeaderWithIcon
                title={translate(`$__timeSheet`, 1)}
                icon={"update"}
                color={customApp("colorText")}
                style={{
                    background: customApp("ColumnTitleColor"),
                    padding: "7px"
                }}
                customButtons={[
                    !exportTimers &&
                    {
                        title: "$__timeSheetExport",
                        icon: "file_download",
                        color: customApp("colorText"),
                        onClick: () => {
                            setExport(!exportTimers)
                        },
                    },
                ]}
            />
            {loading ?
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: "rgba(250,250,250,0.7)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 10
                    }}
                >
                    <PreLoader />
                </div>
                : <React.Fragment></React.Fragment>
            }
            {exportTimers &&
                <div style={{ padding: "15px" }}>
                    <fieldset style={{ position: "relative", width: "100%", marginLeft: "7px", display: "flex", justifyContent: "space-between", marginBottom: "14px", marginTop: "7px" }}>
                        <legend>{translate("$__timeCourse", 1)}</legend>
                        <TextField
                            label={translate("$__initialDate", 1)}
                            variant={customApp("fieldVariant", 1)}
                            name="startDate"
                            style={{ marginRight: "15px" }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type="date"
                            inputProps={{
                                max: endDate ? endDate : null,
                            }}
                            InputProps={{
                                autoComplete: "new-password",
                                onChange: (d) => {
                                    setStartDate(d.target.value)
                                },
                            }}
                            value={startDate}
                        />
                        <TextField
                            label={translate("$__endDate", 1)}
                            variant={customApp("fieldVariant")}
                            name="endDate"
                            type="date"
                            min={startDate ? startDate : null}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: "new-password",
                                onChange: (d) => {
                                    setEndDate(d.target.value)
                                },
                            }}
                            value={endDate}
                        />

                    </fieldset>
                    {/* FILTER BY USER */}
                    {db.users && Object.keys(db.users).length > 0 &&
                        <Accordion style={{ width: "100%" }}>
                            <AccordionSummary
                                style={{ minWidth: "100%" }}
                                expandIcon={
                                    <Icon>expand_more</Icon>
                                }
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            ><div style={{ color: customApp("color"), fontSize: "12px" }}>
                                    {translate("$__users")}
                                    <AvatarGroup max={6}>
                                        {Object.keys(selectedUsers).length > 0 && Object.keys(selectedUsers).map((a, i) => (
                                            <Tooltip
                                                title={db.users[a].name}
                                                key={i}
                                            >
                                                <Avatar alt={db.users[a].name} src={db.users[a].image || null} >
                                                    {db.users[a].name.substr(0, 1)}
                                                </Avatar>
                                            </Tooltip>
                                        ))}
                                    </AvatarGroup>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails
                                style={{ minWidth: "100%" }}
                            >
                                <FormGroup>
                                    {Object.keys(users).map(pr => {
                                        return (
                                            db.users && db.users[pr] && db.users[pr]._id &&
                                            <React.Fragment key={pr}>
                                                <FormControlLabel
                                                    key={pr}
                                                    style={{ minWidth: "100%" }}
                                                    control={
                                                        <React.Fragment>
                                                            <Avatar alt={db.users[pr].name.substr(0, 1)} src={db.users[pr].image || null} >
                                                                {db.users[pr].name.substr(0, 1)}
                                                            </Avatar>
                                                            <Checkbox
                                                                checked={selectedUsers && selectedUsers[pr] ? true : false}
                                                                onChange={() => {
                                                                    selectUser(pr)
                                                                }}
                                                                color="default"
                                                            />
                                                        </React.Fragment>
                                                    }
                                                    label={db.users[pr].name || db.users[pr].name}
                                                />
                                            </React.Fragment>
                                        )
                                    }
                                    )}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    }
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "7px" }}>
                        <ExportTimer data={timeSheet} />
                        <CustomButton
                            title={translate("$__closeFilter")}
                            text={translate("$__closeFilter")}
                            color={"lightgrey"}
                            textColor={"lightgrey"}
                            icon={"cancel"}
                            onClick={() => { setExport(!exportTimers) }}
                            size={"25px"}
                        />
                    </div>
                </div>
            }
            <UiCardHeader style={{ height: "333px" }}>
                <ResponsivePie
                    data={[
                        ...Object.keys(timeSheet).sort((a, b) => {
                            if (parseInt(timeSheet[a].totalTimer) > parseInt(timeSheet[b].totalTimer)) {
                                return -1
                            } else if (parseInt(timeSheet[a].totalTimer) < parseInt(timeSheet[b].totalTimer)) {
                                return 1
                            } else {
                                return 0
                            }
                        }).map((ts, i) => {
                            return {
                                id: timeSheet[ts].name,
                                label: timeSheet[ts].name,
                                value: timeSheet[ts].totalTimer,
                                color: "#929497",
                                textMini: timeSheet[ts].timerText,
                            }
                        }
                        )
                    ]}
                    valueFormat={value => {
                        convertSecondsToText(value, true)
                        return convertSecondsToText(value)
                    }}
                    enableArcLabels={false}
                    colors={{ scheme: "purpleRed_green" }}
                    activeOuterRadiusOffset={8}
                    arcLabelsTextColor={{ from: "color", modifiers: [["brighter", 3]] }}
                    borderWidth={1}
                    cornerRadius={3}
                    enableArcLinkLabels={true}
                    innerRadius={0.5}
                    leavesOnly={true}
                    margin={{ top: 10, right: 0, bottom: 10, left: 0 }}
                    padAngle={1.3}
                    sortByValue={true}
                />
                <div style={{
                    width: "100px",
                    wordWrap: "break-word",
                    position: "absolute",
                    top: "33%",
                    left: "calc(50% - 50px)",
                    fontWeight: "bold",
                    color: customApp("menu"),
                    textAlign: "center",
                }}>
                    {convertSecondsToText(totalSeconds, true)}
                </div>
            </UiCardHeader>
            <UiCardBody>
                <GridContainer>
                    {Object.keys(timeSheet).sort((a, b) => {
                        if (parseInt(timeSheet[a].totalTimer) > parseInt(timeSheet[b].totalTimer)) {
                            return -1
                        } else if (parseInt(timeSheet[a].totalTimer) < parseInt(timeSheet[b].totalTimer)) {
                            return 1
                        } else {
                            return 0
                        }
                    }).map((ts, i) =>
                        <TimeSheetData data={timeSheet[ts]} key={ts} />
                    )}
                    <TimeSheetData data={{ name: translate("$__total"), timerText: convertSecondsToText(totalSeconds) }} />
                </GridContainer>
            </UiCardBody>
            <div style={{
                color: "#CCCCCC",
                fontSize: "12px",
                padding: "15px",
                textAlign: "center"
            }}>
                {db.cards[props.nodeId] && db.cards[props.nodeId].type === 'plan' ?
                    translate("$__timeSheetShowingAllTimesRegisteredInAllActivitiesOfPlan")
                    :
                    translate("$__timeSheetShowingAllTimesRegisteredInActivityAndLinkedActivities")
                }
            </div>
        </UiCard>
    );
}
const mapStateToProps = (store) => ({
    cards: store.db.cards,
    timeline: store.timeline
}
)

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(Timesheet);
import React from "react";
import { toast } from 'react-toastify'
import MD5 from "crypto-js/md5"
import moment from "moment-timezone"

//REDUX
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"

//FUNCTIONS
import api from "api/";
require('dotenv').config()

const languageADD =
    (
        window.location.hostname.indexOf('a1b2c3d4') > -1
        || window.location.hostname.indexOf('localhost') > -1
    )
        && process.env.REACT_APP_PROD === "true"
        ? true
        : false
// const languageADD = true

const translate = (al, style) => {
    if (al) {
        const words = reduxStore.getState().languages || {}
        const { session } = reduxStore.getState()

        let language = session && session.language ? session.language : 'pt-BR'
        // let language = 'pt-BR'

        let newText = al

        if (al && al.indexOf('$__') > -1) {
            al.split(' ').forEach((a, i) => {
                if (a && a.indexOf('$__') > -1) {
                    let word = a.replace('$__', '').trim().toLowerCase()
                    if (languageADD && !words[word]) {
                        try {
                            api.post("language/add", {
                                name: word
                            })
                        } catch (e) {
                            console.log(e)
                        }
                    }
                    if (words[word] && words[word].locales.filter(a => a.locale === language).length > 0 && words[word].locales.filter(a => a.locale === language)[0].name) {
                        newText = newText.replace(`${a}`, `${i > 0 ? ` ` : ``}${words[word].locales.filter(a => a.locale === language)[0].name}`)
                    }
                }
            })
        } else {
            return al
        }

        let result = newText.replace(`$__`, ``)

        if (!result)
            return ''

        if (!style)
            return result

        if (style === '*')//ALL Upper Case
            return result.toUpperCase()

        if (style === 1) //First Letter Upper Case
            return result.substring(0, 1).toUpperCase().concat(result.substring(1))
    }
}

function nl2br(str, is_xhtml, break1 = "<br/>", break2 = "<br>") {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? break1 : break2;
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}


function hexToRgba(str, opacity) {
    if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/ig.test(str)) {
        var hex = str.substr(1);
        hex = hex.length === 3 ? hex.replace(/(.)/g, '$1$1') : hex;
        var rgb = parseInt(hex, 16);
        return `rgba(${[(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255]}, ${opacity || 1})`;
    }
    if (opacity && str && str.indexOf('rgb') > -1 && str.indexOf('rgba') === -1) {
        return str.replace(')', `, ${opacity})`).replace('rgb(', 'rgba(');
    } else {
        return str;
    }
}

export const stripHtml = (html) => {
    let tmp = document.createElement("DIV")
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ""
}

function rgbaToHex(color) {
    let colorSplit = color.replace('rgba(', '').replace(')', '').split(',')
    return colorSplit
}

function customApp(prop, opacity) {
    if (reduxStore) {
        const props = reduxStore.getState().session.custom
        return hexToRgba(props[prop], opacity) || prop
    }
}

function validateEmail(email) {
    var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    if (!filter.test(email)) {
        return false
    } else {
        return true
    }
}

/**
 * 
 * @param {message} string
 * @param {variant} string //warning, danger, info, success or null 
 * @param {confirm} function //TRUE-FALSE
 * @param {closed} boolean //TRUE-FALSE
 * @param {persist} boolean // TRUE-FALSE
 * @param {content} //HTML
 * @param {vertical} options // Vertical position // top, center, bottom
 * @param {horizontal} options // Horizontal position // left, center, right
 * @param {time} seconds // time to auto hide -> Default 3000
 */



function appAlert(options = {}) {
    let key = MD5(JSON.stringify(options)).toString()

    let content = options.content ? options.content : options.message
    if (options.confirm)
        content = (
            <div style={{ minWidth: "300px" }}>
                {content}
                <div style={{ display: "flex", width: "100%", marginTop: 15, justifyContent: "center" }}>
                    <CustomButton
                        shadow
                        title={translate("$__confirm")}
                        text={translate("$__confirm")}
                        color="green"
                        icon={"check"}
                        size={"25px"}
                        onClick={() => {
                            options.confirm()
                            toast.dismiss(key)
                        }}

                    />
                    <CustomButton
                        shadow
                        title={translate("$__cancel")}
                        text={translate("$__cancel")}
                        color="red"
                        icon={"close"}
                        size={"25px"}
                        onClick={() => {
                            toast.dismiss(key)
                        }}
                        style={{ marginLeft: 15 }}
                    />
                </div>
            </div>
        )

    if (options.buttons && options.buttons.length > 0)
        content = (
            <div style={{ minWidth: "300px" }}>
                {content}
                <div style={{ width: "100%", marginTop: 15, justifyContent: "center" }}>
                    {options.buttons.map((b, bi) =>
                        <CustomButton
                            key={bi}
                            {...b}
                            style={{
                                ...options && options.style ? options.style : {},
                                marginBottom: 10
                            }}
                            onClick={() => {
                                if (b.onClick)
                                    b.onClick()
                                toast.dismiss(key)
                            }}

                        />
                    )}
                </div>
            </div>
        )

    let toastOptions = {
        toastId: key,
        position: toast.POSITION.BOTTOM_RIGHT,
        ...options.options ? options.options : {},
        ...!options.persist ? { autoClose: options.autoClose ? options.autoClose : 2000 } : {},
        style: { minWidth: "300px" },
    }

    if (options.horizontal === 'left')
        toastOptions = {
            ...toastOptions,
            position: toast.POSITION.BOTTOM_LEFT
        }
    if (options.horizontal === 'right')
        toastOptions = {
            ...toastOptions,
            position: toast.POSITION.BOTTOM_RIGHT
        }
    if (options.horizontal === 'center')
        toastOptions = {
            ...toastOptions,
            position: toast.POSITION.BOTTOM_CENTER
        }


    if (!toast.isActive(key)) {
        if (options.variant === 'info' || !options.variant)
            toast.info(content, toastOptions)

        if (options.variant === 'success')
            toast.success(content, toastOptions)

        if (options.variant === 'warning')
            toast.warn(content, toastOptions)

        if (options.variant === 'error')
            toast.error(content, toastOptions)
    }
    return true
}

const decimal = (val, digits = 2) => {
    if (!val)
        return ``

    let value = val.replace(/\D/g, '')
    if ((value.match(/./g) || []).length > 1) {
        value = val.replace(',', '.')
        let nv = value.split('.')
        if (nv.length === 1)
            return parseFloat(value)
        value = value.replaceAll(`.${nv[nv.length - 1]}`, ``)
        value = String(value).replaceAll('.', '')
        let decimal = nv[nv.length - 1].substring(0, digits)
        value = `${value}.${decimal}`
    }
    return value
}

const formatNumber = (a, s = 'decimal', currency) => {
    if (!a)
        return ''
    const { session } = reduxStore.getState()

    let value = String(a).replace(',', '.')
    let style = s


    if (style === 'number' || style === 'time' || style === 'decimal') {
        style = 'decimal'
    } else if (style === 'percent') {
        return `${value}%`
        // value = value / 100
    } else if (style !== 'currency') {
        return String(a)
    }

    let result = new Intl.NumberFormat(session.custom.locale, { style: style, currency: currency ? currency : session.custom.currency ? session.custom.currency : 'BRL' })

    result.setMaximumFractionDigits = s === 'number' ? 0 : 2
    return result.format(value)
}

const languageUPD = (props, data) => {

    let newLocales = reduxStore.getState().languages[data.alias] && reduxStore.getState().languages[data.alias].locales ? Array.from(reduxStore.getState().languages[data.alias].locales) : []

    newLocales.flatMap(a => {
        if (a.locale === data.locale)
            return [a.name = data.text]
    })
    if (newLocales.filter(lc => lc.locale === data.locale).length === 0)
        newLocales.push({
            _id: data._id,
            locale: data.locale,
            name: data.text
        })

    props.reduxFunction("ASYNC", "SET_LANGUAGE", {
        ...reduxStore.getState().languages,
        [data.alias]: {
            ...reduxStore.getState().languages[data.alias] && reduxStore.getState().languages[data.alias],
            locales: newLocales
        }
    });
}

const deleteNode = async (props, data) => {
    let { db } = reduxStore.getState()
    let nodeType = 'cards'
    if (db.cards[data._id]) {
        nodeType = 'cards'
    } else if (db.users[data._id]) {
        nodeType = 'users'
    }

    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        [nodeType]: {
            ...reduxStore.getState().db[nodeType],
            [data._id]: {
                ...reduxStore.getState().db[nodeType][data._id],
                deleted: true
            }
        }
    });
}

const removeSeats = (str) => {
    let seats = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let cleanStr = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";

    let newStr = "";
    for (let i = 0; i < str.length; i++) {
        let change = false;
        for (let a = 0; a < seats.length; a++) {
            if (str.substr(i, 1) === seats.substr(a, 1)) {
                newStr += cleanStr.substr(a, 1);
                change = true;
                break;
            }
        }
        if (change === false) {
            newStr += str.substr(i, 1);
        }
    }
    return newStr;
}

const Highlighted = (text, highlight, dark) => {
    if (!highlight || (highlight && !highlight.trim())) {
        return <span style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{
            __html: nl2br(text)
        }} />
    }
    let newText = nl2br(text)
    const parts = newText.split(new RegExp(`(${highlight.replace('*.', '.')})`, 'gi'));
    return (
        <span> {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold', paddingTop: '3px', paddingBottom: '3px', backgroundColor: '#ffff00b3', color: dark ? '#000000 !important' : '' } : {}}
                dangerouslySetInnerHTML={{
                    __html: nl2br(part)
                }}
            >
            </span>)
        } </span>
    )
}

const links = (text) => {
    let newText = nl2br(text)
    const lines = newText.split("\n")

    let result = ""
    lines.forEach((ln) => {
        const parts = ln.split(" ")
        parts.forEach(ppar => {
            let lln = ppar.split("\n")
            if (lln.length > 0) {
                lln.forEach((par) => {
                    if (par.indexOf(".youtube.com/watch?") > -1) {
                        let ybQuery = par.split("?")[1].split("v=")[1].split("&")[0]
                        if (ybQuery)
                            result += `<iframe title="outModule" width="560" height="315" style="width:100%; max-width:500px" src="https://www.youtube.com/embed/${ybQuery}" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                    } else if (par.indexOf("//youtu.be/") > -1) {
                        let ybQuery = par.split(".be/")[1]
                        if (ybQuery)
                            result += `<iframe title="outModule" width="560" height="315" style="width:100%; max-width:500px" src="https://www.youtube.com/embed/${ybQuery}" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
                    } else if (par.indexOf("http://") > -1 || par.indexOf("https://") > -1) {
                        result += `<a style="word-break:break-all" href="${par.replace('<br/>', '')}" target="_blank" rel="noopener noreferrer">${par}</a>`
                    } else {
                        result += par
                    }
                })
                result += " "
            } else if (lln[0].indexOf(".youtube.com/watch?") > -1
                && lln[0].split("?").length > 1
                && lln[0].split("?")[1].split("v=").length > 1
            ) {
                let ybQuery = lln[0].split("?")[1].split("v=")[1].split("&")[0]
                if (ybQuery)
                    result += `<iframe title="outModule" width="560" height="315" style="width:100%; max-width:500px" src="https://www.youtube.com/embed/${ybQuery}" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            } else if (lln[0].indexOf("http://") > -1 || lln[0].indexOf("https://") > -1) {
                result += `<a href="${lln[0].replace('<br/>', '')}" target="_blank" rel="noopener noreferrer">${lln[0]} </a>`
            } else {
                result += `${ppar} `
            }
        })
        result += ""
    })
    return result
}

const readedNotifications = (props, nodes) => {
    let socket
    if (props && props.store && reduxStore.getState().functions && reduxStore.getState().functions.socket) {
        socket = reduxStore.getState().functions.socket
    } else {
        socket = reduxStore.getState().functions.socket
    }
    let notification = reduxStore.getState().notifications
    Object.keys(notification).forEach(a => {
        if (nodes && nodes.indexOf(a) > -1)
            notification[a] = {
                ...notification[a],
                readed: true
            }
    })
    socket.emit("data", {
        module: "notifications",
        method: "put",
        action: "readed",
    }, {
        nodes
    })
    props.reduxFunction("ASYNC", "SET_NOTIFICATIONS", {
        ...reduxStore.getState().notifications,
        ...notification
    });
}

const getAppAccess = () => {
    const { session } = reduxStore.getState()
    let AppAccess = {}
    if (session && session._access)
        AppAccess = session._access
    return AppAccess
}

const favoriteNode = async (props, _id, del = false) => {
    if (!del) {
        try {
            api.put('/node/favorite', { _id })
        } catch (e) {
            console.log(e)
        }
    } else {
        try {
            api.delete(`/node/favorite/${_id}`)
        } catch (e) {
            console.log(e)
        }
    }
}

const padZero = (a) => {
    var s = a;
    while (s.length === 1) {
        s = '0' + s;
    }
    return s;
};

const textColor = (reqColor, bw, light = '#FFFFFF', dark = '#000000') => {
    let color = reqColor
    var r,
        g,
        b

    if (color.indexOf('rgba') > -1) {
        color = color.replace('rgba(', '')
        let colors = color.split(',')
        r = colors[0]
        g = colors[1]
        b = colors[2]
    } else {
        if (color.indexOf('#') === 0) {
            color = color.slice(1);
        }
        if (color.length === 3) {
            color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
        }
        if (color.length !== 6) {
            // throw new Error('Invalid HEX color.');
        }
        r = parseInt(color.slice(0, 2), 16)
        g = parseInt(color.slice(2, 4), 16)
        b = parseInt(color.slice(4, 6), 16)
    }

    if (bw) {
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? dark
            : light
    }
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

export function stringLength(str, car) {
    if (str !== undefined) {
        if (str.length <= car) {
            return str;
        } else {
            return str.slice(0, car - 3) + "...";
        }
    } else {
        return str;
    }
}
export function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string?.length; i += 1) {
        hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name?.slice(0, 2)}`,
    };
}

const getDate = (d, format = false) => {
    let date = 0
    if (d?.low) {
        date = parseInt(d.low)
    } else {
        date = parseInt(d)
    }
    if (format)
        return moment(date * 1000).tz("America/Sao_Paulo").format(format)
    if (moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(date * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY"))
        return moment(date * 1000).tz("America/Sao_Paulo").format("H:mm")
    if (moment(new Date()).tz("America/Sao_Paulo").format("YYYY") === moment(date * 1000).tz("America/Sao_Paulo").format("YYYY"))
        return moment(date * 1000).tz("America/Sao_Paulo").format("DD/MM H:mm")
    return moment(date * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")
}

export function getTime(s) {
    function xx(numero) {
        if (numero <= 9) {
            numero = "0" + numero;
        }
        return numero;
    }
    let hora = xx(Math.round(s / 3600));
    let minuto = xx(Math.round((s % 3600) / 60));
    let segundo = xx((s % 3600) % 60);
    let formatado = hora + ":" + minuto + ":" + segundo;
    return formatado;
}

function formatSizeUnits(bytes) {
    if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1) { bytes = bytes + " bytes"; }
    else if (bytes === 1) { bytes = bytes + " byte"; }
    else { bytes = "0 bytes"; }
    return bytes;
}

function BytesConvert(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function totalTime(time) {
    let diff = time
    let diffText = ``
    let hours = 0
    let minutes = 0
    let seconds = 0
    if (time < 60) {
        diffText = `${diff} ${translate("$__seconds", 1)}`
    } else if (time > 3600) {
        hours = Math.ceil(Math.trunc(diff / 3600))
        diff = diff - (hours * 3600)
        minutes = Math.ceil(Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0)
        diff = diff - (minutes * 60)
        seconds = Math.ceil(diff > 0 ? diff : 0)
        diffText = `${hours} hora${hours > 1 ? "s" : ""} ${minutes} ${translate("$__minutes", 1)} ${seconds} ${translate("$__seconds", 1)}`
    } else {
        minutes = Math.ceil(Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0)
        diff = diff - (minutes * 60)
        seconds = Math.ceil(diff > 0 ? diff : 0)
        diffText = `${minutes} ${translate("$__minutes", 1)} ${seconds} ${translate("$__seconds", 1)}`
    }
    return ({
        total: diffText,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    })
}

export {
    BytesConvert,
    Highlighted,
    appAlert,
    customApp,
    decimal,
    deleteNode,
    favoriteNode,
    formatNumber,
    formatSizeUnits,
    getAppAccess,
    getDate,
    hexToRgba,
    languageUPD,
    links,
    nl2br,
    readedNotifications,
    removeSeats,
    rgbaToHex,
    textColor,
    translate,
    validateEmail,
}
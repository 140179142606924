import React, { useRef } from 'react';
import { ResponsivePie } from '@nivo/pie'
import Header from 'components/Dashboards/Header'



const GoalsDoughPreview = (props) => {

    const values = useRef([Math.random() * 400, Math.random() * 400, Math.random() * 400, Math.random() * 400, Math.random() * 400])

    const data = [
        {
            "id": "Atrasadas",
            "label": "Plano A",
            "value": values.current[0],
            "color": '#f48c7c'
        },
        {
            "id": "Concluidas",
            "label": "Plano B",
            "value": values.current[1],
            "color": '#44f474'
        },
        {
            "id": "Executando",
            "label": "Plano C",
            "value": values.current[2],
            "color": '#24acdc'
        },
        {
            "id": "Atrasadas2",
            "label": "Plano A",
            "value": values.current[3],
            "color": '#f48c7c'
        },
        {
            "id": "Concluidas2",
            "label": "Plano B",
            "value": values.current[4],
            "color": '#44f474'
        },
    ]


    return (

        <div className='box' style={{ width: '100%', height: '100%', overflow: 'hidden', alignSelf: 'center', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexFlow: 'column' }}>
            <Header styles={{}} tittle={props.title} icon={props.icon} />
            <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
            <div style={{ position: 'relative', flex: '1 1 auto' }}>
                <div style={{ width: '100%', height: '100%', position: 'absolute', }}>
                    <ResponsivePie
                        data={data}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        innerRadius={0}
                        padAngle={1.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={0}
                        borderWidth={0}
                        colors={["#929497", "#011E41", "pink", "#EBA900", "#5A7F71"]}
                        theme={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                            legend: false
                        }}
                        animate={false}
                        arcLinkLabelsStraightLength={0}
                        isInteractive={false}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.8]] }}
                        enableArcLinkLabels={false}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsThickness={0}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={false}
                        arcLabel={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default GoalsDoughPreview
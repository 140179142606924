import React, { useEffect, useRef } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import PreLoader from "components/PreLoader";

//DEPENDENCIES
import Messages from "./newMessages";
import Search from "./Search";

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/timelineStyle";

//FUNCTIONS
import {
  loadTimeline,
} from "functions/chat";

const useStyles = makeStyles(styles)

const MessagesList = (props) => {
  const classes = useStyles()
  const { session } = reduxStore.getState()
  const [preLoader, setPreLoader] = React.useState(false)

  const { timeline } = props

  const mounted = React.useRef(true)

  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const type = props.type || 'comment'


  const componentRef = useRef()

  const data = props.nodeId ? reduxStore.getState().db[`${!props.db ? 'cards' : props.db}`][props.nodeId] : {};
  const timelineDb = reduxStore.getState().db.users[props.nodeId] ? `users` : `cards`

  useEffect(() => {
    componentRef.current = {
      mounted: true
    }

    return () => {
      if (componentRef && componentRef.current && componentRef.current.mounted)
        componentRef.current.mounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let files = [];

  let stories = []
  if (reduxStore.getState().db.cards[props.nodeId] && timeline[props.nodeId])
    Object.keys(timeline[props.nodeId]).forEach(a => stories.push(timeline[props.nodeId][a]))

  const reqStories = (parent) => {
    Object.keys(reduxStore.getState().db.cards).filter(kc =>
      reduxStore.getState().db.cards[kc]._parent === parent
      && reduxStore.getState().db.cards[kc].kpi_useParent
      && reduxStore.getState().db.cards[kc].kpi_type === reduxStore.getState().db.cards[parent].kpi_type)
      .forEach(f => {
        if (reduxStore.getState().timeline[f])
          Object.keys(reduxStore.getState().timeline[f]).filter(x => reduxStore.getState().timeline[f][x].type === 'kpi' && !reduxStore.getState().timeline[f][x].deleted).map(x =>
            stories.push(reduxStore.getState().timeline[f][x])
          )
        reqStories(f)
      })
  }


  if (type === 'kpi')
    reqStories(props.nodeId)

  if (reduxStore.getState().db.users[props.nodeId]) {
    if (timeline && timeline[session._id])
      Object.keys(timeline[session._id])
        .filter(a =>
          timeline[session._id]
          && timeline[session._id][a]
          && timeline[session._id][a].user
          && timeline[session._id][a].user._id === props.nodeId
          && stories.filter(s => s._id === a).length === 0
        )
        .forEach(a => {
          stories.push(timeline[session._id][a])
        })



    if (timeline && timeline[props.nodeId])
      Object.keys(timeline[props.nodeId])
        .filter(a =>
          timeline[props.nodeId]
        )
        .forEach(a => {
          stories.push(timeline[props.nodeId][a])
        })

    stories = Object.keys(stories)
      .sort((a, b) => {
        if (parseInt(stories[a].created_at) < parseInt(stories[b].created_at)) {
          return -1
        } else if (parseInt(stories[a].created_at) > parseInt(stories[b].created_at)) {
          return 1
        } else {
          return 0
        }
      })
      .filter(a =>
        stories[a].user
        && stories[a].user._id
        && (
          stories[a].user._id === session._id
          ||
          stories[a].user._id === props.nodeId
        )
      ).map(a => {
        return stories[a]
      })
  }



  if (props.kpi_type)
    stories = Object.keys(stories).filter(a =>
      (
        stories[a].kpi_type === props.kpi_type
      )
      ||
      (
        stories[a].reply
      )
    ).map(a => {
      return stories[a]
    })


  let balance = data && data.kpi_ini ? parseFloat(data.kpi_ini) : 0

  React.useEffect(() => {
    if (mounted.current) {
      const init = async () => {
        let type = props.type ? props.type : 'comment'
        await loadTimeline(
          {
            ...props,
            fnIdentification: "Timeline/Index/init()",
            force: true
          },
          {
            ids: props.nodeId,
            db: timelineDb,
            type: type,
            ...props.type === "kpi" ? {
              limit: false,
              skip: false,
              reqChildrens: true
            } : {
              limit: true,
              skip: stories.filter(fil => fil.type === type).length > 0 ? stories.filter(fil => fil.type === type).length - 1 : 0,
              reqChildrens: false
            }
          }
        )
        if (mounted.current)
          setPreLoader(false)
      }
      if (mounted.current) {
        if (stories.filter(a => a.type === type).length < 10) {
          setPreLoader(true)
          init()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, props.nodeId])

  if (preLoader)
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(250,250,250,0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10
        }}
      >
        <PreLoader />
      </div>
    )



  const openLightBox = (f) => {
    props.reduxFunction("ASYNC", "LIGHTBOX", {
      open: true,
      files: files,
      file: f
    })
  }


  const renderMessages = Object.keys(stories).length > 0
    && Object.keys(stories)
      .filter(a => {
        if (
          (
            !stories[a].deleted ||
            (
              stories[a].deleted &&
              stories[a].user &&
              stories[a].user._id &&
              stories[a].user._id === session._id
            )
          ) &&
          (
            (
              stories[a].type === type
            )
            ||
            (
              stories[a].reply
              && Object.keys(stories).filter(fil => stories[fil] && stories[fil]._id === stories[a].reply && stories[fil].type === type).length > 0
            )
          )
        )
          return true
        return false
      })
      .sort((a, b) => {
        if (stories[a].created_at < stories[b].created_at) {
          return -1
        } else if (stories[a].created_at > stories[b].created_at) {
          return 1
        } else {
          return 0
        }
      }
      ).map((id) => {
        let storie = stories[id]
        balance = parseFloat(parseFloat(balance) + parseFloat(storie.value))
        storie.balance = balance

        if (storie.files && Object.keys(storie.files).length > 0)
          Object.keys(storie.files).forEach((id, i) => {
            let file = storie.files[id]
            files.push(file)
          })

        return storie
      })


  return (
    <div>
      <div
        className={classes.timeline}
      >
        <div
          className={props.showHeader ? classes.timelineSimpleWithHeader : classes.timelineSimple
          }
          style={{ zIndex: 1 }}
        >
          {renderMessages && renderMessages.length > 0 ?
            <Messages
              messages={renderMessages}
              hideCards={props.hideCards ? true : false}
              db={props.db}
              type={type}
              nodeId={props.nodeId}
              openLightBox={(e) => { openLightBox(e) }}
            />
            : <React.Fragment></React.Fragment>}
          <div style={{ clear: 'both' }}></div>
        </div>

        {!props.noSearch ?
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <Search
              db={timelineDb}
              nodeId={props.nodeId}
              onAction={(e) => {
                // goToMessage(e._id, true)
              }}
            />
          </div>
          : <React.Fragment></React.Fragment>
        }
      </div>
    </div>
  );
}
//REACT
const mapStateToProps = ({ timeline }, { nodeId }) => {
  return {
    timeline
  }
}
const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList)
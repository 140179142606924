//CORE
import React from "react"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Icon from "components/Icon"
import Notification from "componentsV3/Notifications/card"
import CustomButton from "components/Buttons/custom"

//@MATERIAL
import Badge from "@material-ui/core/Badge"
import Button from "@material-ui/core/Button"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"

//FUNCTIONS
import {
    translate,
    customApp,
    appAlert
} from "functions/"

function Notifications(props) {
    const { session, timeline, functions } = reduxStore.getState()
    const { socket } = functions
    const [open, setOpen] = React.useState(false)
    const [actualNotificationType, setNotificationType] = React.useState("comment")
    const anchorRef = React.useRef()

    const clearNotifications = async () => {
        appAlert({
            message: translate("$__confirmClearAllNotifications", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                setOpen(false)
                let timeline = { ...reduxStore.getState().timeline }
                Object.keys(timeline).forEach(t => {
                    if (timeline && timeline[t])
                        Object.keys(timeline[t])
                            .filter(fil =>
                                reduxStore.getState().db.cards
                                && reduxStore.getState().db.cards[t]
                                && reduxStore.getState().db.cards[t].type !== 'chatGroup'
                                && (
                                    !timeline[t][fil].readedBy
                                    ||
                                    (timeline[t][fil].readedBy
                                        && !timeline[t][fil].readedBy[session._id])
                                )
                            )
                            .forEach(ms => {
                                delete timeline[t][ms]
                            })
                })
                props.reduxFunction("ASYNC", "SET_TIMELINE", {
                    timeline: timeline
                });
                props.reduxFunction("ASYNC", "SET_NOTIFICATIONS", {
                    total: 0,
                    lastNotification: 0, //Aqui vai a data da notificação mais nova sendo exibida
                    firstNotification: 0, // Aqui vai a data da notificação mais antiga carregada
                })
                try {
                    socket.emit("data", {
                        module: "notifications",
                        method: "put",
                        action: "readedAll"
                    }, {

                    })
                } catch (e) {
                    console.log(e)
                }
            }
        })
    }

    const handleToggle = (h) => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event) => {
        if (event?.target?.className?.indexOf("ignoreClickAway") > -1)
            return
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setTimeout(() => {
            setOpen(false);
        }, 100)
    }

    let ntCount = 0
    let xCount = {
        comment: 0,
        log: 0,
        mentions: 0,
        deleted: 0,
        kpi: 0,
        timer: 0,
        completed: 0
    }
    const userData = session._id && reduxStore.getState().db.users && reduxStore.getState().db.users[session._id] || {}
    Object.keys(reduxStore.getState().db.cards)
        .filter(n => {
            let card = reduxStore.getState().db.cards[n]
            console.log()
            if (
                card.type !== 'chatGroup'
                && card.type !== 'step'
                && session
                && session._id
                &&
                (
                    (
                        card._users
                        && card._users[session._id]
                    )
                    || (
                        (
                            !card._users
                            || (
                                card._users
                                && !card._users[session._id]
                            )
                        )
                        && card._groups
                        && userData
                        && userData.groups
                        && userData.groups.length > 0
                        && Object.keys(card._groups).length > 0
                        && Object.keys(card._groups).filter(gr =>
                            userData
                            && userData.groups
                            && userData.groups.filter(xg => xg.value === gr).length > 0
                        ).length > 0
                    )
                )
                && timeline[n]
                && Object.keys(timeline[n]).filter(a =>
                    session
                    && session._id
                    && timeline
                    && timeline[n]
                    && timeline[n][a]
                    && !timeline[n][a].deleted
                    && timeline[n][a].user
                    && timeline[n][a].user._id
                    && timeline[n][a].user._id !== session._id
                    && (
                        !timeline[n][a].readedBy
                        ||
                        (
                            timeline[n][a].readedBy
                            && !timeline[n][a].readedBy[session._id]
                        )
                    )
                ).length > 0)
                return true
            return false
        })
        .filter(n => timeline[n])
        .forEach((n, ci) => {
            Object.keys(timeline[n]).filter(a =>
                session
                && session._id
                && timeline[n]
                && timeline[n][a]
                && !timeline[n][a].deleted
                && timeline[n][a].user
                && timeline[n][a].user._id
                && timeline[n][a].user._id !== session._id
                && (
                    !timeline[n][a].readedBy
                    ||
                    (
                        timeline[n][a].readedBy
                        && !timeline[n][a].readedBy[session._id]
                    )
                )
            ).forEach((a) => {
                if (reduxStore.getState().db.cards[n]?.deleted)
                    xCount["deleted"] = xCount.deleted + 1

                if (reduxStore.getState().db.cards[n]?.status === "completed")
                    xCount["completed"] = xCount.completed + 1

                if (timeline[n][a]?.message?.indexOf(session._id) > -1 && reduxStore.getState().db.cards[n] && !reduxStore.getState().db.cards[n].deleted) {
                    xCount["mentions"] = xCount.mentions + 1
                }

                xCount[timeline[n][a].type] = parseInt(xCount[timeline[n][a].type]) + 1

                ntCount = ntCount + 1
            })
        })

    if (ntCount === 0 && open)
        setOpen(false)

    if (ntCount === 0)
        return (<React.Fragment></React.Fragment>)


    let notificationType = actualNotificationType
    if (notificationType === "comment" && !xCount.comment)
        notificationType = "mentions"
    if (notificationType === "mentions" && !xCount.mentions)
        notificationType = "timers"
    if (notificationType === "timers" && !xCount.mentions)
        notificationType = "kpi"
    if (notificationType === "kpi" && !xCount.mentions)
        notificationType = "log"
    if (notificationType === "log" && !xCount.log)
        notificationType = "completed"
    if (notificationType === "completed" && !xCount.completed)
        notificationType = "deleted"
    if (notificationType === "deleted" && !xCount.deleted)
        notificationType = "all"


    return (
        <div id="notificationsBt"
            style={{
                zIndex: "100 !important",
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => {
                    handleToggle(ntCount > 0)
                }}
            >
                <Badge overlap="rectangular"
                    badgeContent={ntCount} color="secondary">
                    <Icon
                        icon={ntCount === 0 ? "notifications_none" : "notifications_active"}
                        color={"parent"}
                        title="$__notifications"
                    />
                </Badge>
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                // role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={handleClose}
                            >
                                <div style={{
                                    width: "800px",
                                    overflowY: "auto"
                                }}>
                                    <div
                                        style={{
                                            position: "relative",
                                            display: "flex"
                                        }}
                                    >
                                        <div style={{
                                            height: "auto",
                                            width: "300px",
                                            overflowY: "auto",
                                            display: "table"

                                        }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    padding: "7px",
                                                    width: "-webkit-fill-available",
                                                    fontSize: 18,
                                                    color: customApp("color"),
                                                    fontWeight: "bold"
                                                }}
                                            >
                                                <div>{translate("$__notifications")}</div>
                                            </div>
                                            <ul
                                                style={{
                                                    listStyle: "none",
                                                    padding: 0,
                                                }}>
                                                {xCount.comment ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__timeline`, '*')}
                                                            text={translate(`$__timeline`, '*')}
                                                            color={notificationType === "comment" ? customApp("menu") : 'lightGray'}
                                                            icon={'timeline'}
                                                            onClick={() => {
                                                                setNotificationType("comment")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.comment}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.mentions ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__mentioned`, '*')}
                                                            text={translate(`$__mentioned`, '*')}
                                                            color={notificationType === "mentions" ? customApp("menu") : 'lightGray'}
                                                            icon={'person_pin_circle'}
                                                            onClick={() => {
                                                                setNotificationType("mentions")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.mentions}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.kpi ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__kpi`, '*')}
                                                            text={translate(`$__kpi`, '*')}
                                                            color={notificationType === "kpi" ? customApp("menu") : 'lightGray'}
                                                            icon={'analytics'}
                                                            onClick={() => {
                                                                setNotificationType("kpi")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.kpi}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.timer ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__timeSheet`, '*')}
                                                            text={translate(`$__timeSheet`, '*')}
                                                            color={notificationType === "timer" ? customApp("menu") : 'lightGray'}
                                                            icon={'timer'}
                                                            onClick={() => {
                                                                setNotificationType("timer")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.timer}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.log ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__update`, '*')}
                                                            text={translate(`$__update`, '*')}
                                                            color={notificationType === "log" ? customApp("menu") : 'lightGray'}
                                                            icon={'update'}
                                                            onClick={() => {
                                                                setNotificationType("log")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.log}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.completed ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__completed`, '*')}
                                                            text={translate(`$__completed`, '*')}
                                                            color={notificationType === "completed" ? customApp("menu") : 'lightGray'}
                                                            icon={'verified'}
                                                            onClick={() => {
                                                                setNotificationType("completed")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.completed}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                {xCount.deleted ?
                                                    <li
                                                        style={{
                                                            padding: "15px 0px",
                                                        }}
                                                    >
                                                        <CustomButton
                                                            title={translate(`$__deletedItems`, '*')}
                                                            text={translate(`$__deletedItems`, '*')}
                                                            color={notificationType === "deleted" ? customApp("menu") : 'lightGray'}
                                                            icon={'delete_sweep'}
                                                            onClick={() => {
                                                                setNotificationType("deleted")
                                                            }}
                                                            size={'22px'}
                                                            style={{ padding: "0px 3px !important", width: "100%" }}
                                                            transparent
                                                            badgeContent={xCount.deleted}
                                                        />
                                                    </li>
                                                    : <React.Fragment></React.Fragment>
                                                }
                                                <li
                                                    style={{
                                                        padding: "15px 0px",
                                                    }}
                                                >
                                                    <CustomButton
                                                        title={translate(`$__all`, '*')}
                                                        text={translate(`$__all`, '*')}
                                                        color={notificationType === "all" ? customApp("menu") : 'lightGray'}
                                                        icon={'all_inbox'}
                                                        onClick={() => {
                                                            setNotificationType("all")
                                                        }}
                                                        size={'22px'}
                                                        style={{ padding: "0px 3px !important", width: "100%" }}
                                                        transparent
                                                    />
                                                </li>
                                            </ul>
                                            <div>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: 0,
                                                        left: 0,
                                                        right: 0
                                                    }}
                                                >
                                                    <CustomButton
                                                        title={translate(`$__clearNotifications`, 1)}
                                                        text={translate(`$__clearNotifications`, 1)}
                                                        color={customApp('color')}
                                                        icon={'clear_all'}
                                                        onClick={() => {
                                                            clearNotifications()
                                                        }}
                                                        size={'21px'}
                                                        style={{ padding: "0px !important" }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: "auto",
                                            maxHeight: "calc(90vh - 80px)",
                                            width: "600px",
                                            backgroundColor: "rgba(247,247,247,1)",
                                            overflowY: "auto"
                                        }}>
                                            {Object.keys(reduxStore.getState().db.cards)
                                                .filter(n => {
                                                    let card = reduxStore.getState().db.cards[n]
                                                    const userData = reduxStore.getState().db.users[session._id]
                                                    if (card.type !== 'chatGroup'
                                                        && session
                                                        && session._id
                                                        && (
                                                            card._users
                                                            && (
                                                                card._users[session._id]
                                                                ||
                                                                (
                                                                    card
                                                                    && card._groups
                                                                    && Object.keys(card._groups).length > 0
                                                                    && Object.keys(card._groups).filter(gr =>
                                                                        userData
                                                                        && userData.groups
                                                                        && userData.groups.filter(xg => xg.value === gr).length > 0
                                                                    )
                                                                )

                                                            )
                                                        )
                                                        && timeline[n]
                                                        && Object.keys(timeline[n]).filter(a =>
                                                            timeline
                                                            && timeline[n]
                                                            && timeline[n][a]
                                                            && !timeline[n][a].deleted
                                                            && timeline[n][a].user
                                                            && timeline[n][a].user._id
                                                            && timeline[n][a].user._id !== session._id
                                                            && (
                                                                !timeline[n][a].readedBy
                                                                ||
                                                                (
                                                                    timeline[n][a].readedBy
                                                                    && !timeline[n][a].readedBy[session._id]
                                                                )
                                                            )
                                                        ).length > 0)
                                                        return true
                                                    return false
                                                })
                                                .filter(n =>
                                                    notificationType === "all"
                                                    || (
                                                        (
                                                            notificationType === "deleted"
                                                            && reduxStore.getState().db.cards[n]?.deleted
                                                        )
                                                        || (
                                                            notificationType !== "deleted"
                                                            && reduxStore.getState().db.cards[n]
                                                            && !reduxStore.getState().db.cards[n].deleted
                                                            && (
                                                                (
                                                                    notificationType === "comment"
                                                                    && Object.keys(timeline[n]).filter(a =>
                                                                        timeline[n][a]?.type === "comment"
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (
                                                                    notificationType === "log"
                                                                    && Object.keys(timeline[n]).filter(a =>
                                                                        timeline[n][a]?.type === "log"
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (
                                                                    notificationType === "kpi"
                                                                    && Object.keys(timeline[n]).filter(a =>
                                                                        timeline[n][a]?.type === "kpi"
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (
                                                                    notificationType === "timer"
                                                                    && Object.keys(timeline[n]).filter(a =>
                                                                        timeline[n][a]?.type === "timer"
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (
                                                                    notificationType === "mentions"
                                                                    && Object.keys(timeline[n]).filter(a =>
                                                                        timeline[n][a]?.message?.indexOf(session._id) > -1
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (
                                                                    notificationType === "completed"
                                                                    && reduxStore.getState().db.cards[n].status === "completed"
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                                .sort((na, nb) => {
                                                    let lna = 0
                                                    let lnb = 0
                                                    //LAST LNA MESSAGE
                                                    if (timeline[na])
                                                        Object.keys(timeline[na]).filter(a =>
                                                            session
                                                            && session._id
                                                            && timeline
                                                            && timeline[na]
                                                            && timeline[na][a]
                                                            && timeline[na][a].user
                                                            && timeline[na][a].user._id
                                                            && timeline[na][a].user._id !== session._id
                                                            && (
                                                                !timeline[na][a].readedBy
                                                                ||
                                                                (
                                                                    timeline[na][a].readedBy
                                                                    && !timeline[na][a].readedBy[session._id]
                                                                )
                                                            )
                                                        ).forEach(a => {
                                                            if (parseInt(timeline[na][a].created_at) > lna)
                                                                lna = parseInt(timeline[na][a].created_at)
                                                        })

                                                    //LAST LNB MESSAGE
                                                    if (timeline[nb])
                                                        Object.keys(timeline[nb]).filter(a =>
                                                            timeline[nb][a].user._id !== session._id
                                                            && (
                                                                !timeline[nb][a].readedBy
                                                                ||
                                                                (
                                                                    timeline[nb][a].readedBy
                                                                    && !timeline[nb][a].readedBy[session._id]
                                                                )
                                                            )
                                                        ).forEach(a => {
                                                            if (parseInt(timeline[nb][a].created_at) > lna)
                                                                lnb = parseInt(timeline[nb][a].created_at)
                                                        })
                                                    if (lna > lnb) {
                                                        return -1
                                                    } else if (lna < lnb) {
                                                        return 1
                                                    }
                                                    return 0
                                                })
                                                .map((n) => {
                                                    return (
                                                        <div key={`${n}`}>
                                                            <Notification
                                                                type={notificationType}
                                                                data={{
                                                                    cardId: n
                                                                }}
                                                                onClick={() => {
                                                                    setOpen(false)
                                                                }} />
                                                        </div>)
                                                })}
                                        </div>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}

const mapStateToProps = (store) => ({
    notifications: store.notifications,
    timeline: store.timeline,
    session: store.session,
    cards: store.db.cards
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)

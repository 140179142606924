import React from "react";

//@MATERIAL
import Icon from "components/Icon";
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//FUNCTIONS
import { translate, customApp, getAppAccess } from "functions/";


//COMPONENTS 
import Plantype from "components/PlanType/Plantype";

function Comp(props) {
    const [newOpen, setNewOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null)
    const { db } = reduxStore.getState();

    let AppAccess = getAppAccess()

    let hashs = window.location.hash.split("/");
    hashs[0] = hashs[0].replace("#", "");

    let idRel = null
    if (hashs[0].length !== 36 && (!hashs[2] || hashs[2].length !== 36)) {
        idRel = null;
    } else if (hashs[0].length === 36) {
        idRel = hashs[0];
    } else if (hashs[2].length === 36) {
        idRel = hashs[2];
    }

    const pathName = window.location.pathname.replace("/", "").toLowerCase();
    let types = db.dataType.filter(a => {
        if (
            (
                pathName === "sp"
                ||
                (
                    (
                        !a.hash &&
                        !idRel
                    ) ||
                    (
                        a.hash &&
                        idRel
                    )
                )
            ) &&
            a.view &&
            Object.keys(a.view).filter(a => a === pathName).length > 0 &&
            (!a.view[pathName].pages ||
                (hashs[1] && a.view[pathName].pages.indexOf(hashs[1]) > -1) ||
                (!hashs[1] && a.view[pathName].pages.indexOf("none") > -1))
        )
            return true
        return false
    });

    const handleToggle = () => {
        // console.log(types)
        if (types.length === 1 && types[0].name === 'plan') {
            setNewOpen(true)
            return
        }
        if (types.length === 1) {
            openModule(types[0], hashs[1] && hashs[1] === 'template' ? true : false)
        } else if (types.length > 1) {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const openModule = (i, template = false) => {

        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...reduxStore.getState().sideModule,
            id: 'new',
            activeModule: i.iniModule,
            db: i.db,
            data: {
                type: i.name,
                _parent: hashs[0] && db.cards[hashs[0]] ? hashs[0] : null,
                ...i.data.type === "user" ? {
                }
                    : {
                        status: 'notStarted',
                        ...template ? { isTemplate: true } : {},
                    }
            }
        })
        // }
    }

    const handleClose = (event) => {
        // console.log("event")
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    if (hashs && hashs[1] && hashs[1] === "users" && AppAccess.user && AppAccess.userAdmin)
        types.push({
            name: "importUsers",
            icon: "import_contacts",
            onClick: () => {
                props.reduxFunction("ASYNC", "SET_CONTROL", {
                    ...reduxStore.getState().control,
                    importUsers: true,
                })
            }
        })




    if (types.length === 0)
        return (<React.Fragment></React.Fragment>)




    return (
        <div id="QuickAddBt">
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Icon usePropagation size={"27px"} color={customApp('menu')}>add_box</Icon>
            </Button>
            {newOpen && <Plantype handleClose={() => setNewOpen(false)} />}
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={(e) => { handleClose(e) }}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    {types
                                        .sort((a, b) => {
                                            if (a.name > b.name) return -1;
                                            return 0;
                                        })
                                        .map((a, i) => (
                                            <MenuItem
                                                key={i}
                                                style={{
                                                    color: customApp('menu')
                                                }}
                                                onClick={() => {
                                                    if (a.onClick) {
                                                        a.onClick()
                                                    } else {
                                                        openModule(a)
                                                    }
                                                    setOpen(false);
                                                }}>
                                                <Icon icon={a.icon} style={{ margin: 'none !important' }} color={customApp('menu')} /><span style={{ marginLeft: '7px' }}>{translate(`$__${a.name}`, 1)}</span></MenuItem>
                                        ))
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div >
    )
}

const mapStateToProps = (store) => ({
    users: store.users
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);

import {
  defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
  root: {
    ...defaultFont,
  },
  aspectsTitle: {
    width: '100%',
    fontWeight: 'bold',
    padding: '3px',
    color: customApp('color'),
    display: 'flex',
    alignItems: 'center'
  }
};

export default style;

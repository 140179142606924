//CORE
import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import IconButton from "components/CustomButtons/IconButton";
import Icons from "components/Icons";
import TextField from "components/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//FUNCTIONS
import api from "api/";
import { translate } from "functions/";

function Component(props) {
    const [id, setId] = React.useState(null);
    const [name, setName] = React.useState('');
    const [icon, setIcon] = React.useState('');
    const [editor, setEditor] = React.useState({});
    const [board, setBoard] = React.useState({
        open: false,
        name: '',
    })

    const { db } = reduxStore.getState()
    const { socket } = reduxStore.getState().functions

    const onDragEnd = async result => {
        const { destination, source, draggableId } = result

        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const itemOrderIds = Array.from(db.cards['boardModels'].boards[source.droppableId].columnOrder)
        const board = source.droppableId
        itemOrderIds.splice(source.index, 1)
        itemOrderIds.splice(destination.index, 0, draggableId)

        api.put('column/reorder', {
            columns: itemOrderIds
        })

        let socketData = {
            _parent: 'boardModels',
            _board: board,
            _order: itemOrderIds
        }
        socket.emit('column.REORDER', socketData)
        return

    }

    const saveColumn = async (boardId) => {
        let itemOrderIds = Array.from(db.cards['boardModels'].boards[boardId].columnOrder)
        try {
            let response
            let columnData = {}
            if (!id) {
                response = await api.post('column/add', {
                    board: boardId,
                    name: name,
                    icon: icon
                })
                columnData = {
                    cards: [],
                    name: name,
                    _id: response.data.id,
                    icon: icon,
                }
            } else {
                response = await api.put('column/update', {
                    _id: id,
                    board: boardId,
                    name: name,
                    icon: icon
                })
                columnData = {
                    name: name,
                    _id: response.data.id,
                    icon: icon,
                }
            }
            if (response) {
                if (!id)
                    itemOrderIds.push(response.data.id)

                let socketData = {
                    _parent: 'boardModels',
                    _columnId: response.data.id,
                    _board: boardId,
                    _cardColumn: 'boardModels',
                    _columnData: columnData,
                    _columnsOrder: itemOrderIds
                }
                socket.emit('column.UPD', socketData)

                setEditor(false)
                setName('')
                setIcon('')
                setId(null)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const deleteColumn = async (board, column) => {
        const { socket } = reduxStore.getState().functions

        try {
            const response = await api.delete(`column/run/${column}__${board}`, {
                board: board,
                name: name,
                icon: icon
            })
            if (response) {
                let socketData = {
                    _parent: 'boardModels',
                    _board: board,
                    _deleted: column
                }
                socket.emit('column.DELETE', socketData)
            }
        } catch (e) {
        }
    }

    const saveBoard = async () => {
        try {
            const response = await api.post('boards/add', {
                name: board.name
            })

            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                cards: {
                    ...reduxStore.getState().db.cards,
                    boardModels: {
                        ...reduxStore.getState().db.cards.boardModels,
                        boards: {
                            ...reduxStore.getState().db.cards.boardModels.boards,
                            ...response.data,
                        }
                    },
                }
            });
            setBoard({
                name: '',
                open: false
            })

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <GridContainer>
            {db.cards
                && db.cards['boardModels']
                && db.cards['boardModels'].boards
                && Object.keys(db.cards['boardModels'].boards).map(boardId => (
                    <DragDropContext
                        onDragEnd={onDragEnd}
                        key={boardId}
                    >
                        <GridItem md={3} >
                            <fieldset style={{
                                width: '100%'
                            }}>
                                <legend>{db.cards['boardModels'].boards[boardId].name}</legend>
                                <Droppable
                                    droppableId={boardId} //ID OF BOARD
                                    type="column"
                                    direction={'vertical'}
                                >
                                    {(provided, snapshot) => (
                                        <Container
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            isDraggingOver={snapshot.isDraggingOver}
                                        >
                                            {!props.noList
                                                && db.cards
                                                && db.cards['boardModels']
                                                && db.cards['boardModels'].boards
                                                && db.cards['boardModels'].boards[boardId]
                                                && db.cards['boardModels'].boards[boardId].columnOrder
                                                && db.cards['boardModels'].boards[boardId].columnOrder.map((col, index) => (
                                                    <Draggable draggableId={col} index={index} key={`${col}-${index}`}>
                                                        {provided => (
                                                            <ItemList
                                                                {...provided}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                isDragging={snapshot.isDragging}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {db.cards['boardModels'].boards[boardId].columns[col].name &&
                                                                    <HeaderWithIcon
                                                                        title={db.cards['boardModels'].boards[boardId].columns[col].name || ''}
                                                                        icon={db.cards['boardModels'].boards[boardId].columns[col].icon || null}
                                                                        iconTitle={'A'}
                                                                        fontSize={'16px'}
                                                                        customButtons={[
                                                                            {
                                                                                name: "delete",
                                                                                icon: "delete_forever",
                                                                                onClick: () => {
                                                                                    deleteColumn(boardId, col)
                                                                                },
                                                                                show: 'hover'
                                                                            },
                                                                            {
                                                                                name: "edit",
                                                                                icon: "edit",
                                                                                onClick: () => {
                                                                                    setName(db.cards['boardModels'].boards[boardId].columns[col].name)
                                                                                    setIcon(db.cards['boardModels'].boards[boardId].columns[col].icon || null)
                                                                                    setId(col)
                                                                                    setEditor({ [boardId]: { open: true } })
                                                                                },
                                                                                show: 'hover'
                                                                            }
                                                                        ]}
                                                                    />
                                                                }
                                                            </ItemList>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            {editor && editor[boardId] && editor[boardId].open ?
                                                <React.Fragment>
                                                    <ItemList>
                                                        <div style={{ position: 'relative', float: 'left', width: '40px', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <Icons callBack={(icon) => { setIcon(icon) }} />
                                                        </div>
                                                        <div style={{ position: 'relative', float: 'left', width: 'calc(100% - 40px)' }}>
                                                            <TextField
                                                                label={translate('$__nameOfColumn', 1)}
                                                                variant={'outlined'}
                                                                name="NewColumn"
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                                InputProps={{
                                                                    autoComplete: 'new-password',
                                                                    onChange: (d) => {
                                                                        setName(d.target.value)
                                                                    },
                                                                    onKeyPress: (d) => {
                                                                        if (d.key === 'Enter' && d.target.value.length > 0)
                                                                            saveColumn(boardId)
                                                                    }
                                                                }}
                                                                value={name}
                                                                placeholder={translate('$__name', 1)}
                                                                styles={{ marginBotton: '0px !important' }}
                                                                autoFocus
                                                            />
                                                        </div>
                                                    </ItemList>
                                                    <div style={{ display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                                                        <IconButton icon={'save'} text={translate("$__save", "*")} onClick={() => { saveColumn(boardId) }} />
                                                        <IconButton
                                                            icon={translate("$__cancel", "*")}
                                                            iconColor='red'
                                                            text={translate("$__cancel", "*")}
                                                            onClick={() => {
                                                                setName('')
                                                                setEditor({})
                                                            }} />
                                                    </div>
                                                </React.Fragment>
                                                :
                                                <IconButton icon={'add_box'} text={translate("$__addColumn", 1)} onClick={() => {
                                                    setEditor({
                                                        [boardId]: {
                                                            open: true
                                                        }
                                                    })
                                                }} />
                                            }
                                            {provided.placeholder}
                                        </Container>
                                    )}
                                </Droppable>
                            </fieldset>
                        </GridItem>
                    </DragDropContext>
                ))}
            <div>
                {!board.open ?
                    <IconButton icon={'add_box'} text={translate("$__addBoard", 1)} onClick={() => { setBoard({ ...board, open: true }) }} />
                    :
                    <div style={{ position: 'relative', float: 'left', width: 'calc(100% - 40px)' }}>
                        <TextField
                            label={translate('$__boardName', 1)}
                            variant={'outlined'}
                            name="boardName"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                onChange: (d) => {
                                    setBoard({ ...board, name: d.target.value })
                                },
                                onKeyPress: (d) => {
                                    if (d.key === 'Enter' && d.target.value.length > 0)
                                        saveBoard()
                                }
                            }}
                            value={board && board.name}
                            placeholder={translate('$__name', 1)}
                            styles={{ marginBotton: '0px !important' }}
                            autoFocus
                        />
                        <div style={{ display: 'inline-flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                            <IconButton icon={'save'} text={translate("$__save", "*")} onClick={() => { saveBoard() }} />
                            <IconButton
                                icon={'cancel'}
                                iconColor='red'
                                text={translate("$__cancel", "*")}
                                onClick={() => {
                                    setName('')
                                    setBoard({ open: false, name: '' })
                                }} />
                        </div>
                    </div>
                }
            </div>
        </GridContainer >
    )
}

const Container = styled.div`
            background-color:none;

            `
const ItemList = styled.div`
            min-width:300px;
            background-color: rgba(255,255,255,0.9);
            padding: 7px;
            margin-bottom:10px;
            box-shadow: 0 0 7px rgba(0,0,0,0.2);
            display:flex;
            `
const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

import React from "react";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/";

//COMPONENTS
import Card from "components/Card";

//IMAGES
import userCover from "assets/img/userCover.jpg"

//FUNCTIONS
import {
    customApp
} from "functions/";

function Dashboard(props) {
    const { session } = reduxStore.getState()
    const { data } = props
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    const [ImagePreview, setImagePreview] = React.useState(null)

    let myCards = Object.keys(reduxStore.getState().db.cards).filter(a => reduxStore.getState().db.cards[a]).map(c => reduxStore.getState().db.cards[c])

    let myDayTasksContent = myCards.filter(a =>
        a._users
        && !a.checklist
        && !a.deleted
        && a._users[data._id]
        && a._users[data._id].myDay
        && (
            !a.private
            ||
            (
                a.private
                && a._users[session._id]
            )
        )
        && (
            moment(new Date(parseInt(a._users[data._id].myDay) * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
        )
    ).sort((a, b) => {
        if (reduxStore.getState().db.orderByTitle
            && reduxStore.getState().db.orderByTitlePosition === 'ASC'
            && a.name.toLowerCase().trim() < b.name.toLowerCase().trim()
        ) {
            return -1
        } else if (reduxStore.getState().db.orderByTitle
            && reduxStore.getState().db.orderByTitlePosition === 'DESC'
            && a.name.toLowerCase().trim() > b.name.toLowerCase().trim()
        ) {
            return -1
        } else if (reduxStore.getState().db.orderByTitle) {
            return 0
        } else if (!reduxStore.getState().db.orderByTitle && String(a.order) && String(b.order) && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low)) {
            return -1
        } else {
            return 1
        }
    }
    ).map((a, i) => <Card db="cards" key={i} data={a} showStatus onlyOption />)

    return (
        <div style={{
            width: "100%",
            minHeight: "120px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 0 9px 3px rgba(0,0,0,0.2)",
            position: "relative",
        }}
        >
            <div
                style={{
                    backgroundColor: customApp('menu'),
                    height: "43px",
                    paddingLeft: "45px",
                    fontSize: "12px",
                    color: "#FFFFFF",
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <div style={{
                    maxWidth: 'calc(100% - 10px)',
                    textOverflow: "ellipsis ",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}>
                    {data.name ? data.name : data.name} ({myDayTasksContent.length})
                </div>
            </div>
            {(!ImagePreview || (data.image && data.image !== ImagePreview)) ?
                <img
                    style={{
                        width: "37px",
                        height: "37px",
                        top: "3x",
                        left: "3px",
                        borderRadius: '50%',
                        border: `solid 2px ${customApp('color')}`,
                        zIndex: '5 !important',
                        position: 'absolute',
                        backgroundColor: `${customApp('color')}`,
                    }}
                    src={`${data.image ? data.image : userCover}`}
                    onError={(e) => {
                        e.target.src = `${userCover}`
                    }}
                    onLoad={(e) => {
                        setImagePreview(`${data.image ? data.image : userCover}`)
                    }}
                    width="100%"
                    alt="MACHEN_IMG"
                />
                :
                <div
                    style={{
                        width: "37px",
                        height: "37px",
                        top: "3px",
                        left: "3px",
                        borderRadius: '50%',
                        border: `solid 2px ${customApp('color')}`,
                        zIndex: '5 !important',
                        position: 'absolute',
                        backgroundColor: `${customApp('color')}`,
                        backgroundImage: `url("${ImagePreview}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center"
                    }}
                >
                </div>
            }
            <React.Fragment>
                {/* <div style={{
                    width: '100%',
                    height: '333px',
                    overflowX: 'hide'
                }}
                >
                    <ResponsivePie
                        data={[
                            ...db.status.map((st, index) => {
                                return {
                                    id: translate(st.label, 1),
                                    label: translate(st.label, 1),
                                    value: Object.keys(db.cards).filter(fil =>
                                        db.cards[fil]
                                        && !db.cards[fil].deleted
                                        && db.cards[fil]._users
                                        && db.cards[fil]._users[data._id]
                                        && db.cards[fil]._users[data._id].myDay
                                        && (moment(new Date(parseInt(db.cards[fil]._users[data._id].myDay) * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today)
                                        && db.cards[fil].status
                                        && db.cards[fil].status === st.value
                                    ).length,
                                    color: st.color
                                }
                            })
                        ]}
                        activeOuterRadiusOffset={8}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={"#FFFFFF"}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={7}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        borderWidth={1}
                        colors={['#929497', '#011E41', '#EBA900', '#5A7F71']}
                        cornerRadius={3}
                        enableArcLinkLabels={false}
                        innerRadius={0.5}
                        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                        padAngle={1.3}
                    />
                </div> */}
                <div style={{
                    width: '100%',
                    maxHeight: 'calc(50vh - 90px)',
                    overflowX: 'auto'
                }}>
                    {myDayTasksContent}
                </div>
            </React.Fragment>
        </div >
    );
}

//EXPORT
export default Dashboard
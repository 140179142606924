import React from "react"

//
import history from "store/history"

//COMPONENTS
import Password from "components/Password/"
import Button from "components/CustomButtons/Button"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import GridItem from "components/Grid/GridItem"
import Icon from "components/Icon"
import TextField from "components/TextField"
import IconButton from "components/CustomButtons/IconButton"
import Preloader from "components/PreLoader/linear"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"
import Modal from '@material-ui/core/Modal'
import InputAdornment from "@material-ui/core/InputAdornment"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/registerPageStyle"

//FUNCTIONS
import api from "api/"
import { translate, customApp, validateEmail } from "functions/"
import { emailExistDB, validateEmailToken, emailTokenGenerate } from "functions/users"

const useStyles = makeStyles(styles)

export default function RegisterPage(props) {
  const [checked, setChecked] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  // const [name, setName] = React.useState("Cristofer Odair")
  // const [email, setEmail] = React.useState("cristofer.odair@outlook.com.br")
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [validatingEmail, setValidatingEmail] = React.useState(false)
  const [emailError, setEmailError] = React.useState('')
  const [token, setToken] = React.useState("")
  const [tokenError, setTokenError] = React.useState(false)
  const [validatingToken, setValidatingToken] = React.useState(false)
  const [termsOpen, setTermsOpen] = React.useState(0)
  const [password, setPassword] = React.useState(null)

  const handleToggle = (value, num) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }
  const classes = useStyles()

  const emailValidate = async (e) => {
    setLoading(true)
    if (validateEmail(e)) {
      const exist = await emailExistDB(e)
      if (exist) {
        setEmailError(translate('$__emailAlreadyExists', 1))
        setEmail("")
        setValidatingEmail(false)
      } else {
        setEmailError(null)
        setValidatingEmail(true)
        emailTokenGenerate(name, e)
      }
    } else {
      setEmailError(translate('$__invalidEmail', 1))
      setEmail("")
    }
    setLoading(false)
  }

  const validateToken = async () => {
    if (token.length >= 6) {
      setLoading(true)
      const exist = await validateEmailToken(token, email)
      if (exist) {
        setValidatingToken(true)
        setTokenError(false)
      } else {
        setTokenError(true)
      }
      setLoading(false)
    } else {
      setTokenError(false)
    }
  }

  const register = async () => {
    setLoading(true)
    try {
      const res = await api.post('user/register-final-step/', {
        name,
        email,
        token,
        password,
        login: true,
      })
      if (res) {
        localStorage.setItem('TOKEN', res.data.token)
        if (props.callback) {
          setTimeout(() => {
            history.push('/login')
            // props.reduxFunction("ASYNC", "SET_DB", {
            //   ...reduxStore.getState().db,
            //   history: 'login'
            // })
          }, 3000)
        }
      }
    } catch (e) {
      setLoading(false)
    }
  }
  return (
    <div
      className={classes.container}
      style={{
        overflow: "auto"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          overflow: "auto"
        }}
      >
        <GridItem xs={12} sm={10} md={6}>
          <Card db="cards" className={classes.cardSignup}>
            <h2 className={classes.cardTitle}>{translate('$__register', 1)}</h2>
            <CardBody>
              <div>
                <TextField
                  className={classes.textFields}
                  label={translate('$__fullName', 1)}
                  variant={'standard'}
                  name={translate('$__fullName', 1)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>account_box</Icon>
                      </InputAdornment>
                    ),
                    autoComplete: 'new-password',
                    onChange: (d) => {
                      setName(d.target.value)
                    },
                  }}
                  value={name || "Cristofer Odair"}
                  // value={name}
                  disabled={validatingEmail}
                />
                <TextField
                  label={translate('$__email', 1)}
                  variant={customApp('fieldVariant')}
                  name="email"
                  type="email"
                  // helperText={emailError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    className: ((emailError || !email) || !validateEmail(email)) ? "invalid" : "",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon icon='alternate_email' />
                      </InputAdornment>
                    ),
                    onChange: (d) => {
                      setValidatingEmail(false)
                      setEmailError(null)
                      setEmail(d.target.value)
                    },
                    onBlur: async (e) => {
                      emailValidate(e.target.value)
                    }
                  }}
                  value={email}
                  placeholder={'email@domain.com'}
                  disabled={validatingEmail}
                />
                {!validatingEmail &&
                  <IconButton
                    icon="navigate_next"
                    text={translate('$__continue', '*')}
                    reverse
                    style={{ float: 'right' }}
                    onClick={() => {
                      emailValidate(email)
                    }}
                  />
                }
                {validatingEmail && email &&
                  <React.Fragment>
                    <TextField
                      label={translate('$__emailToken', 1)}
                      variant={customApp('fieldVariant')}
                      // helperText={translate(!tokenError ? 'registerEmailTokenDescription' : 'registerEmailTokenError')}
                      type="password"
                      name="emailToken"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        autoComplete: 'new-password',
                        className: tokenError ? "invalid" : "",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon icon='keyboard' />
                          </InputAdornment>
                        ),
                        onChange: (d) => {
                          setEmailError(null)
                          setToken(d.target.value)
                        }
                      }}
                      value={token}
                      placeholder={'****'}
                    />
                    <IconButton
                      icon="reply_all"
                      text={translate('$__resendCode', '*')}
                      reverse
                      style={{ float: 'right' }}
                      onClick={() => {
                        emailTokenGenerate(name, email)
                      }}
                    />
                    {!validatingToken &&
                      <IconButton
                        icon="navigate_next"
                        text={translate('$__continue', '*')}
                        reverse
                        style={{ float: 'right' }}
                        onClick={() => {
                          validateToken()
                        }}
                      />
                    }
                  </React.Fragment>
                }
                {validatingEmail && validatingToken &&
                  <React.Fragment>
                    <Password onConfirmed={(v) => { setPassword(v) }} />
                    <Modal
                      open={termsOpen}
                      onClose={() => setTermsOpen(0)}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '0px',
                          left: '0px',
                          right: '0px',
                          bottom: '0px',
                          display: 'flex',
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <div style={{
                          position: "relative",
                          width: '80vw',
                          height: '80vh',
                          overflowY: "auto",
                          backgroundColor: '#FFFFFF',
                          padding: '15px',
                          margin: 'auto',
                        }}>
                          <iframe title="outModule"
                            id="policy"
                            style={{
                              position: "relative",
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 40,
                              width: "100%",
                              height: "calc(100% - 40px)",
                            }}
                            width={"100%"}
                            height={"100%"}
                            onScroll={() => {
                              console.log("scroll")
                            }}
                            src={termsOpen === 1 ? "https://sonne.global/SONNE-Politica-de-Privacidade-2022-01-07.pdf" : "https://sonne.global/SONNE-Termos-de-Servicos-2022-01-07.pdf"}
                          />
                          <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center"
                          }}>

                            <Button round color="custom_color" onClick={() => setTermsOpen(0)}>
                              Já li
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(1)}
                          checkedIcon={
                            <div className={classes.checkedIcon}><Icon size='16px' >check</Icon></div>
                          }
                          icon={<div className={classes.uncheckedIcon}><Icon size='16px' color="red">remove</Icon></div>}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={
                        <span>
                          {translate('$__iAgreeToThe', 1)} <span onClick={() => setTermsOpen(1)} style={{ color: 'blue' }}>{translate("$__privacyPolicy")}</span>.
                        </span>
                      }
                    />
                    <FormControlLabel
                      classes={{
                        root: classes.checkboxLabelControl,
                        label: classes.checkboxLabel
                      }}
                      control={
                        <Checkbox
                          tabIndex={-1}
                          onClick={() => handleToggle(2)}
                          checkedIcon={
                            <div className={classes.checkedIcon}><Icon size='16px' >check</Icon></div>
                          }
                          icon={<div className={classes.uncheckedIcon}><Icon size='16px' color="red">remove</Icon></div>}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                          }}
                        />
                      }
                      label={
                        <span>
                          {translate('$__iAgreeToThe', 1)} <span onClick={() => setTermsOpen(2)} style={{ color: 'blue' }}>{translate("$__servicesTerms")}</span>.
                        </span>
                      }
                    />
                    <div className={classes.center}>
                      {checked.indexOf(1) > -1 && password &&
                        <Button round color="custom_color" onClick={() => register()}>
                          {translate("$__completeRegistration")}
                        </Button>
                      }
                    </div>
                  </React.Fragment>
                }
              </div>
            </CardBody>
            {loading &&
              <div style={{ position: 'absolute', bottom: '0px', width: '100%' }}>
                <Preloader />
              </div>
            }
          </Card>
        </GridItem>
      </div>
    </div>
  )
}

import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//COMPONENTS
import Card from "components/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//@MATERIAL
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/InsightBoards";

//FUNCTIONS
import {
    translate
} from "functions/";

function RadarView(props) {
    const { db, search } = reduxStore.getState()
    const [page, setPage] = React.useState(0)
    const [limit, setLimit] = React.useState(25)

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let radarCards = {}

    if (hashs[0].length === 36) {
        radarCards = Object.keys(db.cards)
            .filter(a => db.cards[a]._parent && db.cards[a]._parent === hashs[0] && db.cards[a].type === 'link' && !db.cards[a].deleted)
            .sort((a, b) => {
                if (db.cards[a].created_at > db.cards[b].created_at)
                    return -1
                return 0
            })
            .map(a => db.cards[a])
        radarCards = radarCards.filter(a =>
        ((a.description && a.description.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            || (a.name && a.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
        )).map(a => a)
    } else {
        radarCards = Object.keys(db.cards)
            .filter(a => !db.cards[a]._parent && db.cards[a].type === 'link')
            .sort((a, b) => {
                if (db.cards[a].created_at > db.cards[b].created_at)
                    return -1
                return 0
            })
            .map(a => db.cards[a])
        radarCards = radarCards.filter(a =>
        ((a.description && a.description.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            || (a.name && a.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
        )).map(a => a)
    }



    return (
        <div style={{ position: 'relative', justifyContent: 'space-between', margin: 'auto', width: '100%' }}>
            {/* <HeaderWithIcon
                title={`radar`}
                icon={'settings_input_antenna'}
                fontSize='20px'
                color={customApp('menu')}
            /> */}
            {Object.keys(radarCards).length > 25 &&
                <div style={{
                    position: "relative",
                    float: 'left',
                    width: '100%',
                    left: '0px',
                    right: '0px',
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {/* PAGINATION */}
                    {translate('$__page')} {page + 1} {translate('$__of')} {Math.ceil(Object.keys(radarCards).length / limit)}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TablePagination
                            component="div"
                            count={Object.keys(radarCards).length}
                            page={page}
                            onChangePage={(event, newPage) => {
                                setPage(newPage)
                            }}
                            rowsPerPage={limit}
                            onChangeRowsPerPage={(event) => {
                                setLimit(parseInt(event.target.value, 10))
                            }}
                            labelRowsPerPage={translate('$__cardsPerPage')}
                        />
                    </div>
                </div>
            }
            <GridContainer>
                {Object.keys(radarCards)
                    .filter((card, i) => i <= ((page + 1) * limit) && i >= page * limit)
                    .map((card, i) => {
                        return (
                            <GridItem
                                key={radarCards[card]._id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={2}
                            >
                                <Card db="cards" data={radarCards[card]} expanded Avatar Resume />
                            </GridItem>
                        )
                    })}
            </GridContainer>
            {Object.keys(radarCards).length > 25 &&
                <div style={{
                    position: "relative",
                    float: 'left',
                    width: '100%',
                    left: '0px',
                    right: '0px',
                    padding: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {/* PAGINATION */}
                    {translate('$__page')} {page + 1} {translate('$__of')} {Math.ceil(Object.keys(radarCards).length / limit)}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TablePagination
                            component="div"
                            count={Object.keys(radarCards).length}
                            page={page}
                            onChangePage={(event, newPage) => {
                                setPage(newPage)
                            }}
                            rowsPerPage={limit}
                            onChangeRowsPerPage={(event) => {
                                setLimit(parseInt(event.target.value, 10))
                            }}
                            labelRowsPerPage={translate('$__cardsPerPage')}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = (store, ownProps) => {
    return {
        store: store,
        ownProps: ownProps
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(RadarView));
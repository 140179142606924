import React, { useRef, memo, useState } from 'react';
import MD5 from "crypto-js/md5"
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon";
import Card from "components/Card";
import IconButton from "components/CustomButtons/IconButton";


//FUNCTIONS
import { translate, customApp } from "functions/";
import { updateCardData, getCardColor, filterCards } from "functions/cards";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";


//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Agile-Column";


function Column(props) {

    const { session } = reduxStore.getState()
    const [showSelectbox, setShowSelectbox] = React.useState({
        allSelected: false,
        show: false,
        selectedCards: []
    })

    let searchID = MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const search = props.searchs && props.searchs[searchID] ? props.searchs[searchID] : null

    // References
    const listRef = React.createRef();
    const rowHeights = useRef({});
    const rowExpandeds = useRef({});
    const separators = useRef({});
    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        if (!props.groupByColor)
            separators.current = {}
    }, [props.groupByColor, props.cards])

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    const trackingTreeParents = (id) => {
        let name = reduxStore.getState().db.cards[id]?.name?.trim()?.toLowerCase() || ``
        if (reduxStore.getState().db.cards[id] && reduxStore.getState().db.cards[id].type !== "plan" && reduxStore.getState().db.cards[id]._parent)
            name = `${trackingTreeParents(reduxStore.getState().db.cards[id]._parent)}-${name}`
        return name
    }

    const resolveOrder = (a, b) => {
        let orderA = a?.order?.low || !isNaN(a?.order) ? parseInt(a.order) : String(a.order) ? parseInt(a.order) : 0
        let orderB = b?.order?.low || !isNaN(b?.order) ? parseInt(b.order) : String(b.order) ? parseInt(b.order) : 0

        if (props.agileOrderBy === 'name') {
            orderA = a.name && a.name.trim() ? `${a.name?.trim()?.toLowerCase()}` : ``
            orderB = b.name && b.name.trim() ? `${b.name?.trim()?.toLowerCase()}` : ``
        }

        if (props.agileOrderBy === 'startDate') {
            orderA = a.startDate ? `${props.agileOrderDesc === 'ASC' ? `Z` : `A`}${String(a.startDate)}` : `${props.agileOrderDesc === 'ASC' ? `A` : `Z`}${a.name}`
            orderB = b.startDate ? `${props.agileOrderDesc === 'ASC' ? `Z` : `A`}${String(b.startDate)}` : `${props.agileOrderDesc === 'ASC' ? `A` : `Z`}${b.name}`
        }

        if (props.agileOrderBy === 'endDate') {
            orderA = a.endDate ? `${props.agileOrderDesc === 'ASC' ? `Z` : `A`}${String(a.endDate)}` : `${props.agileOrderDesc === 'ASC' ? `A` : `Z`}${a.name}`
            orderB = b.endDate ? `${props.agileOrderDesc === 'ASC' ? `Z` : `A`}${String(b.endDate)}` : `${props.agileOrderDesc === 'ASC' ? `A` : `Z`}${b.name}`
        }

        // console.log(hashs.indexOf('overview'))

        if (hashs.indexOf('overview') > -1) {
            orderA = `${trackingTreeParents(a._parent)}-${orderA}`
            orderB = `${trackingTreeParents(b._parent)}-${orderB}`
        }

        if (props.agileOrderDesc === 'DESC') {
            if (orderA < orderB)
                return -1
            if (orderA > orderB)
                return 1
        }
        if (props.agileOrderDesc === 'ASC') {
            if (orderA > orderB)
                return -1
            if (orderA < orderB)
                return 1
        }
        return 0
    }

    let filtered = props.cards.map(a => reduxStore.getState().db.cards[a])

    if (!props.showCatenation) {
        filtered = filtered.filter(ai => {
            if (filtered.filter(xc => xc._id === ai._parent).length === 0)
                return true
            return false
        }
        )
    }

    if (search)
        filtered = filterCards(filtered, search)

    if (!props.showDeleted && !reduxStore.getState().controls.deleted) {
        filtered = filtered.filter(fil => !fil.deleted).map(res => res)
    }



    // console.log(props.agileOrderBy)



    filtered = filtered.sort((a, b) => resolveOrder(a, b)).map(res => res)

    let columnName = translate(props.column.name, 1)
    let columnIcon = null
    let customColumns = {}
    if (reduxStore.getState().db.cards[hashs[0]] && reduxStore.getState().db.cards[hashs[0]].customColumns)
        customColumns = JSON.parse(reduxStore.getState().db.cards[hashs[0]].customColumns)

    if (
        customColumns
        && customColumns[props.column.name.replace("$__", "")]
        && customColumns[props.column.name.replace("$__", "")].name
    )
        columnName = translate(customColumns[props.column.name.replace("$__", "")].name, 1)

    if (
        customColumns
        && customColumns[props.column.name.replace("$__", "")]
        && customColumns[props.column.name.replace("$__", "")].icon
    )
        columnIcon = translate(customColumns[props.column.name.replace("$__", "")].icon, 1)


    let cardbyGroup = []


    //INI VIRTUALIZED
    function Row({ index, style }) {
        const card = filtered[index]
        const childrens = filtered.filter(fil => fil._parent === card._id).length || 0
        let separatorTitle = ``
        let separatorColor = ``
        if (props.groupByColor && hashs.indexOf("overview") > -1) {
            let a = getCardColor(card)
            if (cardbyGroup.indexOf(a.parentId) === -1 && reduxStore.getState().db.cards[a.parentId] && reduxStore.getState().db.cards[a.parentId].type !== "plan") {
                separatorTitle = reduxStore.getState().db.cards[a.parentId].name
                separatorColor = a.color
                cardbyGroup.push(a.parentId)
                separators.current = {
                    ...separators.current,
                    [card._id]: {
                        title: reduxStore.getState().db.cards[a.parentId].name,
                        color: a.color || "#FFFFFF"
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <Draggable
                    draggableId={card._id}
                    index={index}
                    key={card._id}
                    isDragDisable={props.isDragDisable || false}
                >
                    {(provided, snapshot) => (
                        <div style={{
                            ...style,
                            ...filtered.filter(fil => fil._id === card._parent).length > 0 ? {
                                paddingLeft: 10,
                                borderLeft: "solid 2px rgba(0,0,0,0.3)",
                                marginLeft: 10,
                                width: "calc(100% - 20px)"
                            } : {}
                        }}>
                            {props.groupByColor && separators.current[card._id] && hashs.indexOf("overview") > -1 ?
                                <div style={{
                                    width: "100%",
                                    padding: 3,
                                    color: "gray",
                                    marginTop: 17,
                                    borderBottom: `solid 3px ${separators.current[card._id]?.color || ``}`
                                }}>{separators.current[card._id].title}</div>
                                : <React.Fragment></React.Fragment>
                            }
                            <Item
                                separator={props.groupByColor && hashs.indexOf("overview") > -1 && separatorTitle ? { title: separatorTitle, color: separatorColor } : null}
                                provided={provided}
                                isDragging={snapshot?.isDragging}
                                index={index}
                                snapshot={snapshot}
                                clone="CL"
                                childrens={childrens}
                            />
                        </div>
                    )}
                </Draggable>
            </React.Fragment>
        )
    }

    function Item({ provided, index, snapshot, clone, childrens = 0 }) {
        let card = filtered[index]
        return (
            <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
            >
                <Card
                    cardId={card._id}
                    separator={props.groupByColor && hashs.indexOf("overview") > -1 && separators?.current[card._id]?.title ? separators?.current[card._id] : null}
                    db={props.db}
                    showOrder={props.showOrder || false}
                    showSelected={props.showSelected || false}
                    showSelectBox={props?.showCheck}
                    selectedCard={props?.selectedCards && props.selectedCards.includes(card._id)}
                    // || props.selectedCards.includes(card._parent)
                    locked={props.locked || false}
                    viewButton={props.viewButton || false}
                    showConnectedIn={props.showConnectedIn ? true : false}
                    isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                    snapshot={snapshot}
                    cardColorPicker={props.cardColorPicker ? true : false}
                    manualExpanded={rowExpandeds?.current[card._id] || false}
                    onSelect={(id) => {
                        props.setSelectedCards(id)
                    }}
                    onResize={(size) => {
                        // if (reduxStore.getState().db.cards[card._id].name === 'Coffe')
                        // console.log(size)
                        setRowHeight(card._id, size)
                    }}
                    onExpand={(expanded) => {
                        if (mounted.current)
                            if ((!rowExpandeds.current[card._id] && expanded) || (String(expanded) === "false" && rowExpandeds.current[card._id]))
                                setRowExpanded(card._id, expanded)
                    }}
                    hasChildrens={childrens}
                />
            </div>
        );
    }

    function getRowHeight(index) {
        let card = filtered[index]
        return rowHeights.current[card._id] + 7 || 67;
    }

    function setRowHeight(index, size) {
        rowHeights.current = {
            ...rowHeights.current, [index]: size
        }
        if (listRef?.current?.resetAfterIndex)
            listRef.current.resetAfterIndex(0);
    }

    function setRowExpanded(index, expanded) {
        if (listRef?.current?.resetAfterIndex)
            listRef.current.resetAfterIndex(0);

        rowExpandeds.current = {
            ...rowExpandeds.current, [index]: expanded
        }
    }


    return (
        <Draggable draggableId={props.columnId ? String(props.columnId) : 'NOT_ID_SENDED'} index={props.index} >
            {provided => (
                <Container
                    {...!props.isDropDisabled && provided.draggableProps}
                    {...props}
                    ref={provided.innerRef}
                >
                    <div {...provided.draggableProps}{...provided.dragHandleProps} style={{ display: 'none' }}></div>
                    <div {...!props.isDropDisabled && provided.dragHandleProps}>
                        <HeaderWithIcon
                            title={columnName}
                            icon={columnIcon || props.column.icon || "topic"}
                            color={customApp('menu')}
                            style={{
                                padding: '7px'
                            }}
                            {...hashs.length > 0
                                && hashs[1]
                                && hashs[1] === 'overview'
                                && reduxStore.getState().db.cards[hashs[0]]
                                && reduxStore.getState().db.cards[hashs[0]].planType
                                && reduxStore.getState().db.cards[hashs[0]].planType === 'flow'
                                && reduxStore.getState().db.cards[hashs[0]]._users
                                && reduxStore.getState().db.cards[hashs[0]]._users[session._id]
                                && reduxStore.getState().db.cards[hashs[0]]._users[session._id].access
                                && parseInt(reduxStore.getState().db.cards[hashs[0]]._users[session._id].access) > 3
                                ? {
                                    permitIcon: true,
                                    editable: true,
                                    onEdit: (x) => {
                                        updateCardData(
                                            {
                                                ...props,
                                                data: { _id: hashs[0] }
                                            }, {
                                            customColumns: JSON.stringify({
                                                ...reduxStore.getState().db.cards[hashs[0]] && reduxStore.getState().db.cards[hashs[0]].customColumns ? JSON.parse(reduxStore.getState().db.cards[hashs[0]].customColumns) : {},
                                                [props.column.name.replace("$__", "")]: x
                                            })
                                        })
                                    },
                                    placeholder: translate("$__columnName")
                                } : {}}
                            customButtons={[
                                props.columnsSettings &&
                                {
                                    name: "settings",
                                    icon: "more_horiz",
                                    color: customApp('color'),
                                    onClick: () => console.log('s'),
                                    show: 'hover'
                                },
                            ]}

                            periodFilter={props && props.periodFilter ? true : false}
                        />
                        <div style={{ position: 'absolute', display: 'flex', right: 0, top: 15, fontWeight: 'bold' }}>{props.columnId && props.columnId === "completed" ? filtered.sort((a, b) => {
                            let ca = a.completed_at && String(a.completed_at.low) ? String(a.completed_at.low) : a.completed_at
                            let cb = b.completed_at && String(b.completed_at.low) ? String(b.completed_at.low) : b.completed_at
                            if (ca && cb && ca > cb)
                                return -1
                            if (ca && cb && ca < cb)
                                return 1
                            return 0
                        }).length : filtered.length}
                        </div>
                    </div>
                    <Droppable
                        droppableId={props.columnId ? String(props.columnId) : 'NOT_ID_SENDED'}
                        type="card"
                        isDropDisabled={props.column.isDropDisabled}
                        direction={`${props.inLine ? 'horizontal' : 'vertical'}`}
                        isCombineEnabled={props.isCombineEnabled}
                        mode={"virtual"}
                        renderClone={(provided, snapshot, rubric) => {
                            return (
                                <Item
                                    provided={provided}
                                    isDragging={snapshot?.isDragging}
                                    index={rubric.source.index}
                                    snapshot={snapshot}
                                    style={{ margin: 0 }}
                                    clone={true}
                                />
                            )
                        }}
                    >
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                style={{
                                    width: "100%",
                                    overflowX: "hidden",
                                    height: props.inLineAdd ? "calc(100% - 60px)" : "calc(100% - 10px)"
                                }}
                            >
                                <AutoSizer style={{
                                    width: "100%",
                                    height: "100%",
                                    overflowX: "hidden",
                                }}
                                >
                                    {({ height, width }) => (
                                        <List
                                            className="List"
                                            height={height}
                                            width={width}
                                            ref={listRef}
                                            itemCount={filtered.length}
                                            itemSize={getRowHeight}
                                            outerRef={provided.innerRef}
                                            itemData={props.columnId && props.columnId === "completed" ? filtered.sort((a, b) => {
                                                let ca = a.completed_at && String(a.completed_at.low) ? String(a.completed_at.low) : a.completed_at
                                                let cb = b.completed_at && String(b.completed_at.low) ? String(b.completed_at.low) : b.completed_at
                                                if (ca && cb && ca > cb)
                                                    return -1
                                                if (ca && cb && ca < cb)
                                                    return 1
                                                return 0
                                            }) : filtered}
                                            style={{
                                                height: "100%",
                                                overflowX: "hidden"
                                            }}
                                        >
                                            {Row}
                                        </List>
                                    )}
                                </AutoSizer>
                            </div>
                        )}
                    </Droppable>
                    <div id="Agilev2_Column_552" style={{ position: 'relative', float: 'left', width: '100%', padding: '7px' }}>
                        <IconButton
                            text={translate("$__add", '*')}
                            icon="add_box"
                            iconColor={customApp('medium')}
                            onClick={() => {
                                const { dataType } = reduxStore.getState().db
                                let addType = props.typeAdd || props.columnId
                                // console.log(props.addInitialData)
                                if (dataType.filter(a => a.name.indexOf(addType) > -1).length === 0)
                                    addType = "task"
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    addCard: {
                                        _parent: props.selectedParent ? props.selectedParent : hashs[0] ? hashs[0] : null,
                                        // _planId: hashs[0] && hashs[0].length === 36 ? hashs[0] : null,
                                        rows: filtered.length,
                                        initialData: {
                                            type: addType,
                                            ...props.addInitialData || {},
                                        }
                                    },
                                })
                            }}
                        />
                    </div>
                </Container>
            )
            }
        </Draggable>
    )
}

const Container = styled.div`
    margin:0 7px;
    min-width:180px;
    ${props => props.columnStyle && props.columnStyle.width ? `width:calc(${props.columnStyle.width} - 14px);` : `width:calc(100% - 14px);`}
    @media(min-width: 960px) {
        position:relative;
        max-height:${props => props.columnStyle && props.columnStyle.maxHeight ? props.columnStyle.maxHeight : 'calc(100% - 0px)'};
       
        ${props => props.inLine
        ? `display:block;
           `
        : `margin-bottom:0px !important;
            ${props => props.columnStyle && props.columnStyle.width ? `width:${props.columnStyle.width};` : `width:100%;`}
                `
    }
      }
    @media(max-width: 959px) {
        float:left;
        position: relative;
      }
      @media(max-width: 599px) {
        float:left;
        position: relative;
      }
    `

//REACT
const mapStateToProps = (store) => ({
    searchs: store.searchs,
    agileOrderBy: store.controls.agileOrderBy,
    agileOrderDesc: store.controls.agileOrderDesc,
    groupByColor: store.db.groupByColor,
    orderByTitle: store.db.orderByTitle,
    orderByTitlePosition: store.db.orderByTitlePosition,
    cardsExpanded: store.db.cardsExpanded,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(memo(Column)));
import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import moment from "moment-timezone";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//DEPENDENCIES
import Column from "./Column";

function App(props) {
    const { db } = reduxStore.getState()

    React.useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onDragEnd = async result => {
        const { socket } = reduxStore.getState().functions
        const { destination, source, draggableId } = result
        let newCardsOrder = db.cards
        if (result.combine) {
            return;
        }


        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const sourceColumn = source.droppableId
        const destinationColumn = destination.droppableId

        const sourceCardIds = Array.from(props.cards.filter(c => c.status === sourceColumn).sort((a, b) => {
            if (a.order && b.order && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low))
                return -1
            return 1
        }
        ).map(a => a._id))

        //When move Card in single Column

        if (sourceColumn === destinationColumn && !reduxStore.getState().db.orderByTitle) {
            const newCardsIds = Array.from(props.cards.filter(c => c.status === sourceColumn).sort((a, b) => {
                if (a.order && b.order && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low))
                    return -1
                return 1
            }
            ).map(a => a._id))

            newCardsIds.splice(source.index, 1)
            newCardsIds.splice(destination.index, 0, draggableId)
            newCardsIds.forEach((card, i) => {
                newCardsOrder[card] = {
                    ...newCardsOrder[card],
                    order: {
                        low: i
                    }
                }
            })

            const newState = {
                ...reduxStore.getState().db,
                cards: {
                    ...db.cards,
                    ...newCardsOrder,
                }
            }
            props.reduxFunction("ASYNC", "SET_DB", {
                ...newState,
            });

            socket.emit("data", {
                module: "cards",
                method: "put",
                action: "inColumn",
            }, {
                node: draggableId,
                source: source.droppableId,
                destination: destination.droppableId,
                destinationCards: newCardsIds,
                sourceCards: [],
                columnName: 'status',
            })
            return
        }

        //When move Card in mult Column
        sourceCardIds.splice(source.index, 1)

        const destinationCardIds = Array.from(props.cards.filter(c => c.status === destinationColumn).sort((a, b) => {
            if (a.order && b.order && String(a.order.low) && String(b.order.low) && parseInt(a.order.low) < parseInt(b.order.low))
                return -1
            return 1
        }
        ).map(a => a._id))
        destinationCardIds.splice(destination.index, 0, draggableId)

        sourceCardIds.forEach((a, i) => {
            if (!db.cards[a].order || !db.cards[a].order.low) {
                db.cards[a] = {
                    ...db.cards[a],
                    order: {
                        low: parseInt(i)
                    }
                }
            } else {
                db.cards[a].order.low = parseInt(i)
            }
        })

        destinationCardIds.forEach((a, i) => {
            if (!db.cards[a].order || !db.cards[a].order.low) {
                db.cards[a] = {
                    ...db.cards[a],
                    order: {
                        low: parseInt(i)
                    }
                }
            } else {
                db.cards[a].order.low = parseInt(i)
            }
        })

        const newState = {
            ...db,
            cards: {
                ...db.cards,
                [draggableId]: {
                    ...db.cards[draggableId],
                    status: destinationColumn,
                    completed_at: destinationColumn === 'completed' ? {
                        low: moment().tz('America/Sao_Paulo').unix('x'),
                    } : null,
                    updated_at: {
                        low: moment().tz('America/Sao_Paulo').unix('x'),
                    },
                    order: {
                        low: destination.index
                    }
                }
            }
        }
        props.reduxFunction("ASYNC", "SET_DB", {
            ...newState,
        });

        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "inColumn",
        }, {
            node: draggableId,
            source: source.droppableId,
            destination: destination.droppableId,
            destinationCards: destinationCardIds,
            columnName: 'status',
            sourceCards: sourceCardIds,
        })

    }
    const onDragStart = async result => {
    }
    const onDragUpdate = async result => {
    }

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onDragUpdate={onDragUpdate}
        >
            <Droppable
                droppableId="ALL-COLUMNS-STATUS"
                type="column"
                direction={`${props.inLine ? 'vertical' : 'horizontal'}`}
                isDropDisabled={true}
            >
                {provided => (
                    <Container
                        {...props}
                        ref={provided.innerRef}
                    >
                        {reduxStore.getState().db.status.map((st, index) => {
                            const theColumn = {
                                name: st.label,
                                icon: st.icon,
                                cards: [],
                                emptyHide: false,
                            }
                            // console.log(st.value)
                            return <Column
                                {...props}
                                key={`${st.value}`}
                                columnId={`${st.value}`}
                                isDropDisabled
                                column={theColumn}
                                cards={props.cards.filter(c => c.status === st.value || (st.value === 'notStarted' && !c.status)).map(a => {
                                    return {
                                        ...a,
                                        id: a._id
                                    }
                                })}
                                index={index}
                                columnsSettings={false}
                                locked={false}
                                columnStyle={{
                                    flex: index,
                                    width: "25%",
                                    ...props.columnStyle ? props.columnStyle : {}

                                }}
                                viewButton={props.viewButton || false}
                                periodFilter={st.props && st.props.periodFilter ? true : false}
                            />
                        })}
                        {provided.placeholder}
                        <div style={{ clear: 'both' }}></div>
                    </Container>
                )}
            </Droppable>
        </DragDropContext>
    )
}

//STYLEDS
const Container = styled.div`
height:100%;
${props =>
        props.inLine ?
            `display: block;
                width: -webkit-fill-available;
                position:relative;
                ${props.style}`
            :
            `   display: flex;
                width: 100%;
                ${props.style}
                @media(min-width: 960px) {
                    display: flex;
                }
                `
    }
    @media(max-width: 959px) {
        display: contents !important;
        width: -webkit-fill-available;
        height:auto;
      }
    `

//REACT
const mapStateToProps = (store, props) => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
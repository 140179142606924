//CORE
import React from 'react'
import Moment from "moment-timezone";

//COMPONENTS
import Icon from "components/Icon";

//@MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card";
import { translate, customApp } from 'functions';

import BrightAndConnectedIco from "assets/icons/BrigthAndConnected";

function Component(props) {
    const { data, classes } = props;

    return (
        <div className={classes.feed}
            onClick={props.clickChannel ? () => props.clickChannel() : null}
        >
            <div className={classes.image}
                style={
                    {
                        background: customApp('AvatarBackground'),
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                }
            >
                <div style={{ marginTop: "calc(-48% + 0px)", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Icon icon={BrightAndConnectedIco} size="47px" color="rgba(250,250,250,0.77)" />

                    {/* <img alt="MACHEN_IMAGE"src={BrightAndConnectedIco} style={{ width: '25%' }} /> */}
                </div>
            </div>
            <div className={classes.infos}>
                <div className={classes.linkTitle}>
                    {translate('$__attention')}
                </div>
                <div
                    className={classes.linkContent}
                >
                    {translate('$__weHaveNotFoundYetLink')}
                </div>
                <div className={classes.feedFooter}>
                    <div className={classes.feedDate}>
                        {Moment(data.created_at && data.created_at.low ? data.created_at.low : data.created_at).format('DD/MM/YYYY HH:MM')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Component)
//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null ,_react.default.createElement("path", {
  fill: "none",
   d: "M17.8,6.4H18V1H17L0,17.6H1.6L16.9,2.3V5.8L5.1,17.6H6.7L17.8,6.4Z"
}),_react.default.createElement("path", {
    d: "M17.8,6.4H18V1H17L0,17.6H1.6L16.9,2.3V5.8L5.1,17.6H6.7L17.8,6.4Z"
}),_react.default.createElement("path", {
    d: "M18,17V10.9H17l-6.1,6.1h0l-1,1h7.5A.6.6,0,0,0,18,17Z"
})), 'GuidelineD');

exports.default = _default;
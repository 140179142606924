import React from 'react'

//COMPONENTS
import Header from 'components/Dashboards/Header'
import "./Loader.css"
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck'

//FUNCTIONS
import { translate } from 'functions'

const Meta = (props) => {

    const { tittle, icon, data } = props

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', backgroundColor: 'white', borderRadius: '5px', display: 'flex', flexFlow: 'column', overflow: 'hidden' }}>
                        <Header styles={props.styles ? props.styles : {}} tittle={translate(`$__${tittle}`)} icon={icon} edit={props.edit} id={props.id} handleRemoveObject={(id) => props.handleRemoveObject(id)} />
                        <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
                        {(!props.load && inViewport) ? <div style={{ width: '100%', height: 'fitContent', fontWeight: 'bolder', flex: '1 1 auto', display: 'flex', justifyContent: 'center', fontSize: '200%' }}>
                            {<p style={{
                                textAlign: "center",
                                fontSize: `calc(3.5em + (2 - 14) * ((100vh - 200px) / (1400 - 200)))`,
                                margin: 0,
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>{data}</p>}
                        </div> :
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                            </div>
                        }
                    </div>
                )}
            </ViewportChecker>
        </div>
    )
}

export default Meta
import React from "react"
import moment from "moment-timezone"

//COMPONENTS
import Avatar from "@material-ui/core/Avatar"
import CustomButton from "components/Buttons/custom"
import Card from "components/Card/v2"
import HeaderWithMenu from "components/Header/withIcon"
import UiCardBody from "components/Card/CardBody"


//REDUX
import reduxStore from "store/"

import { translate, customApp, getDate, getTime } from "functions"
import { getChildrens } from "functions/cards";

export default function TopTags(props) {
    const tagsChartMount = props.data
    const { db } = reduxStore.getState()
    const { statusFilter, search, totalTimes } = props
    const [options, setOptions] = React.useState({})
    const [orderBy, setOrderBy] = React.useState("time")

    console.log(search)

    return (
        <>
            <HeaderWithMenu
                title={`TOP TAGS ${totalTimes ? `- (${getTime(totalTimes)})` : ``}`}
                color={customApp('colorText')}
                style={{
                    background: customApp('ColumnTitleColor'),
                    padding: '7px'
                }}
                customButtons={[
                    {
                        title: `$__orderBy${orderBy}`,
                        icon: "sort",
                        color: "#FFFFFF",
                        onClick: () => {
                            setOrderBy(prev => prev === "time" ? "count" : "time")
                        },
                    }
                ]}
            />
            <UiCardBody>
                {Object.keys(tagsChartMount)
                    .filter(tag =>
                        db.tag.filter(a =>
                            a.value === tag
                            && (
                                !search.text
                                || (
                                    search.text
                                    && a.label.indexOf(search.text) > -1
                                )
                            )
                        ).length > 0
                    )
                    .sort((a, b) => {
                        if (orderBy === "time") {
                            if (tagsChartMount[a].timesInt > tagsChartMount[b].timesInt)
                                return -1
                            if (tagsChartMount[a].timesInt < tagsChartMount[b].timesInt)
                                return 1
                        }
                        if (tagsChartMount[a].TotalCards > tagsChartMount[b].TotalCards)
                            return -1
                        if (tagsChartMount[a].TotalCards < tagsChartMount[b].TotalCards)
                            return 1
                        return 0
                    })
                    .map((tg, i) => {
                        let tag = db.tag.filter(a => a.value === tg)[0]

                        let times = 0
                        let timesByUser = tagsChartMount[tg].timesByUser

                        let TotalCards = tagsChartMount[tg].TotalCards || 0

                        times = tagsChartMount[tg].times || 0


                        return (
                            <div
                                style={{
                                    width: "100%",
                                    marginBottom: 33
                                }}
                                key={`${tg}_${i}`}
                            >
                                <div style={{
                                    border: "solid 1px #d6d6d6",
                                    borderLeft: `solid 10px ${tag?.color || ""}`,
                                    borderBottom: "none",
                                    borderRadius: 5,
                                    padding: "3px 5px",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    display: "flex",
                                }}>
                                    <div
                                        style={{
                                        }}>{tag.label} ({TotalCards})
                                    </div>
                                    <div style={{
                                        display: "flex"
                                    }}>
                                        {times !== "00:00:00" ?
                                            <CustomButton
                                                color={options.toptags && options.toptags[tg] && options.toptags[tg]?.view !== "timesheet" ? customApp('lightgray') : customApp('menu')}
                                                icon={'work_history'}
                                                onClick={() => {
                                                    setOptions({
                                                        ...options,
                                                        toptags: {
                                                            ...options.toptags || {},
                                                            [tg]: {
                                                                ...(options.toptags && options.toptags[tg]) || {},
                                                                view: "timesheet"
                                                            }
                                                        }
                                                    })
                                                }}
                                                size={'15px'}
                                                title={`${translate("$__workedHours")}: ${times}`}
                                                text={`${translate("$__workedHours")}: ${times}`}
                                            /> : <></>
                                        }
                                        <CustomButton
                                            color={options.toptags && options.toptags[tg] && options.toptags[tg]?.view === "activities" ? customApp('menu') : customApp('lightgray')}
                                            icon={'list_alt'}
                                            onClick={() => {
                                                setOptions({
                                                    ...options,
                                                    toptags: {
                                                        ...options.toptags || {},
                                                        [tg]: {
                                                            ...(options.toptags && options.toptags[tg]) || {},
                                                            view: "activities"
                                                        }
                                                    }
                                                })
                                            }}
                                            size={'15px'}
                                            title={translate(`$__viewActivities`, '*')}
                                            text={translate(`$__viewActivities`, '*')}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        border: "solid 1px #d6d6d6",
                                        padding: 5,
                                        marginTop: -10,
                                        paddingTop: 15,
                                        borderTop: "none",
                                        marginLeft: 10,
                                    }}
                                >
                                    {times !== "00:00:00" && (
                                        (
                                            options.toptags
                                            && options.toptags[tg]
                                            && options.toptags[tg].view
                                            && options.toptags[tg].view === "timesheet"
                                        )
                                        || (
                                            !options.toptags
                                            || (
                                                options.toptags
                                                && !options.toptags[tg]
                                            )
                                            || (
                                                options.toptags
                                                && options.toptags[tg]
                                                && !options.toptags[tg].view
                                            )
                                        )
                                    ) ?
                                        <>
                                            {Object.keys(timesByUser)
                                                .filter(x => timesByUser[x])
                                                .sort((a, b) => {
                                                    if (timesByUser[a] > timesByUser[b])
                                                        return -1
                                                    if (timesByUser[a] < timesByUser[b])
                                                        return 1
                                                    return 0
                                                })
                                                .map(x => {
                                                    let userData = reduxStore.getState().db.users[x] || {}
                                                    return (
                                                        <div key={x} style={{ width: "100%", marginBottom: "10px", display: "flex", alignItems: "center" }}>
                                                            <React.Fragment>
                                                                <div
                                                                    style={{ width: "33px" }}
                                                                >
                                                                    <Avatar alt={userData.name} src={userData.image || null} style={{ width: "18px", height: "18px" }}>
                                                                        {userData.name.substr(0, 1)}
                                                                    </Avatar>
                                                                </div>
                                                                <div style={{ marginLeft: "10px", fontWeight: "bold", color: customApp("menu"), width: "33%", minWidth: "150px" }}>{userData.name}</div>
                                                            </React.Fragment>
                                                            <div style={{ marginLeft: "10px", fontWeight: "bold", color: customApp("menu") }}>{getTime(timesByUser[x])}</div>
                                                        </div>
                                                    )
                                                })}
                                        </> : <></>}
                                    {(options.toptags
                                        && options.toptags[tg]
                                        && options.toptags[tg]?.view === "activities"
                                    ) ? <div>
                                        {Object.keys(tagsChartMount[tg])
                                            .map(card => {
                                                let childrensCards = getChildrens(card)
                                                return Object.keys(childrensCards)
                                                    .filter(x =>
                                                        db.cards[x]
                                                        && !db.cards[x].deleted
                                                        && db.cards[x].status === statusFilter
                                                        && (
                                                            !db.cards[x].checklist
                                                            || (
                                                                db.cards[x].checklist
                                                                && db.cards[x].showCard
                                                            )
                                                        )
                                                        && (
                                                            (
                                                                statusFilter === "completed"
                                                                && getDate(db.cards[x].completed_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                                                                && getDate(db.cards[x].completed_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                                                            )
                                                            ||
                                                            (
                                                                statusFilter !== "completed"
                                                                && getDate(db.cards[x].updated_at, "YYYY-MM-DD") >= moment(search.startDate * 1000).format("YYYY-MM-DD")
                                                                && getDate(db.cards[x].updated_at, "YYYY-MM-DD") <= moment(search.endDate * 1000).format("YYYY-MM-DD")
                                                            )
                                                        )
                                                    )
                                                    .sort((ca, cb) => {
                                                        let dateA = statusFilter === "completed" ? getDate(db.cards[ca].completed_at, "YYYY-MM-DD HH:MM:ss") : getDate(db.cards[ca].updated_at, "YYYY-MM-DD HH:MM:ss")
                                                        let dateB = statusFilter === "completed" ? getDate(db.cards[cb].completed_at, "YYYY-MM-DD HH:MM:ss") : getDate(db.cards[cb].updated_at, "YYYY-MM-DD HH:MM:ss")
                                                        if (dateA > dateB)
                                                            return -1
                                                        if (dateA < dateB)
                                                            return 1
                                                        return 0
                                                    })
                                                    .map(cd => {
                                                        return (
                                                            <React.Fragment key={`card-${cd}`}><Card db="cards" data={reduxStore.getState().db.cards[cd]} noShadow resume /></React.Fragment>
                                                        )
                                                    })


                                            })}
                                    </div> : <></>}
                                </div>
                            </div>
                        )
                    })}
            </UiCardBody>
        </>
    )
}
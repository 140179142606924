import React from "react"
import styled from "styled-components"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Card from "components/Card"
import Icon from "components/Icon"
import TextField from "components/TextField"
import CustomButton from "components/Buttons/custom"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/CardSimple.js"

//FUNCTIONS
import { translate, customApp, appAlert, getDate } from "functions/"

//FUNCTIONS
import { updateCardData, setCompleted } from "functions/cards"

import { action } from "functions/kanban"





function Component(props) {
    const { classes } = props
    const { db } = reduxStore.getState()

    let data = props.data && props.data._id ? reduxStore.getState().db.cards[props.data._id] : {}

    const [name, setName] = React.useState(data.name ? data.name : "")
    const [edit, setEdit] = React.useState(false)
    const [over, setOver] = React.useState(false)

    const update = async (d) => {
        updateCardData(
            {
                ...props,
                data: { _id: data._id }
            }, d)
        setEdit(false)
    }

    const deleteThis = async () => {
        appAlert({
            message: translate("$__confirmDeleteThisFullDescription", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                update({ deleted: true })
            }
        })
    }

    return (
        <Container
            {...props.provided && props.provided.draggableProps}
            {...props.key ? { key: props.key } : {}}
            ref={props.provided && props.provided.innerRef}
            isDragging={props.snapshot && props.snapshot.isDragging}
            isDragDisable={data.isDragDisable || props.isDragDisable || false}
            inLine={props.inLine}
            selectable={props.showSelected}
            selected={data._selected}
            priority={data.priority ? db.priority.filter(a => String(a.value) === String(data.priority))[0].color : null}
            locked={props.locked || false}
            className={!props.showCard ? classes.container : classes.cardContainer}
        >
            <div
                {...props.provided && props.provided.dragHandleProps}
            >
                {edit ?
                    <React.Fragment>
                        <div
                            style={{
                                position: "relative",
                                float: "left",
                                width: "100%",
                                padding: "7px",
                                display: "flex",
                                alignItems: "center"
                            }}>
                            <div style={{
                                width: "calc(100% - 50px)",
                                display: "flex"
                            }}
                            >
                                <TextField
                                    label={translate("$__taskTitle")}
                                    variant={customApp("fieldVariant")}
                                    name={"name"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        autoComplete: "new-password",
                                        onChange: (d) => {
                                            setName(d.target.value)
                                        },
                                        onBlur: (d) => {
                                            if (d.target.value === "")
                                                setEdit(!edit)
                                        },
                                        onKeyDown: (e) => {
                                            if (e.keyCode === 13 && name) {
                                                update({
                                                    name
                                                })
                                            }
                                            if (e.keyCode === 27) {
                                                setEdit(!edit)
                                                setName()
                                            }
                                        }
                                    }}
                                    autoFocus
                                    value={name}
                                    placeholder={translate("$__taskTitlePlaceholder")}
                                />
                            </div>
                            <div style={{ display: "flex" }}>
                                <div
                                    onClick={() => update({
                                        name
                                    })}
                                >
                                    <Icon color="green" icon={"save"} title={translate("$__save", "*")} size={props.actionsSize || "25px"} />
                                </div>
                                <div
                                    onClick={() => {
                                        setEdit(!edit)
                                        setName("")
                                    }}
                                >
                                    <Icon color={customApp("medium")} icon={"cancel"} title={translate("$__cancel", "*")} size={props.actionsSize || "25px"} />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onMouseOver={() => {
                            setOver(true)
                        }}
                        onMouseLeave={() => {
                            setOver(false)
                        }}
                    >

                        <div style={{ width: "100%", padding: "0px 7px", wordBreak: "break-word" }}
                            onDoubleClick={() => { setEdit(!edit) }}
                            {...props.provided ? { provided: props.provided } : {}}
                            {...props.snapshot ? { snapshot: props.snapshot } : {}}
                        >
                            {props.showCard ?
                                <Card db="cards"
                                    cardId={props.data._id}
                                    viewTimer
                                    viewAllName
                                />
                                :
                                data.name ? data.name : translate("$__new", 1)
                            }
                        </div >
                        {data.status === "completed" && !props.showCard ?
                            <div
                                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,0.05)", display: "flex", alignItems: "center" }}
                            >
                                <div style={{ position: "absolute", top: "50%", marginLeft: 15, height: 1, width: "calc(100% - 50px)", backgroundColor: "rgba(0,0,0,0.1)" }}>
                                </div>
                                {data.completed_at ?
                                    <div
                                        style={{
                                            position: "absolute",
                                            right: 33,
                                            top: "calc(50% - 15px)",
                                            fontSize: 10,
                                            textAlign: "right",
                                            backgroundColor: "#f1f1f1",
                                            paddingLeft: 7
                                        }}
                                    >{getDate(data.completed_at)}</div>
                                    : <React.Fragment></React.Fragment>}

                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        {(over && props.permission && !props.noDelete && (!props.showCard || (!props.dragStatus && props.showCard))) &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <CustomButton
                                    title={translate("$__undoConclude", 1)}
                                    color={"lightgrey"}
                                    icon={"delete_forever"}
                                    onClick={() => deleteThis()}
                                    transparent
                                />
                                {data.checklist ?
                                    <CustomButton
                                        title={translate("$__turnIntoActivity", 1)}
                                        color={"lightgrey"}
                                        icon={"change_circle"}
                                        onClick={() => {
                                            appAlert({
                                                message: translate("$__confirmTurnIntoActivity", 1),
                                                variant: "warning",
                                                persist: false,
                                                horizontal: "right",
                                                confirm: () => {
                                                    update({
                                                        checklist: null
                                                    })
                                                }
                                            })

                                        }

                                        }
                                        transparent
                                    />
                                    : <React.Fragment></React.Fragment>
                                }
                            </div>
                        }
                        {(!props.showCard || (!props.dragStatus && props.showCard)) &&
                            <CustomButton
                                title={data.status !== "completed" ? translate("$__conclude", 1) : translate("$__undoConclude", 1)}
                                color={data.status !== "completed" ? "lightgrey" : "grey"}
                                icon={data.status !== "completed" ? "radio_button_unchecked" : "check_circle"}
                                onClick={() => {
                                    // console.log(data)
                                    if (data._hasStep) {
                                        action({
                                            ...props,
                                            nodeId: data._id,
                                        })
                                    } else {
                                        // console.log('setCompleted')
                                        setCompleted(props, data)
                                    }
                                }}
                                transparent
                            />
                        }
                    </div>
                }
            </div>
            {props.locked ?
                <div style={{
                    position: "absolute",
                    backgroundColor: "rgba(233,233,233,0.88)",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div style={{
                        display: "flex"
                    }}>
                        <Icon color="lightgray" icon="lock" size={"22px"} />
                    </div>

                </div>
                :
                <React.Fragment></React.Fragment>
            }
        </Container >
    )
}

const Container = styled.div`
${props =>
        props.selectable && props.selected ? `
    opacity:1`
            : props.selectable ? `
    opacity:0.5`
                : ``
    }
${props =>
        props.locked ?
            `cursor:no-drop !important`
            : `cursor:pointer`
    }
${props =>
        props.priority && !props.showCard ? ``
            // `border-left:5px solid ${props.priority}`
            : ``
    }
${props =>
        !props.selectable && props.selected ?
            `border-bottom:solid 5px ${customApp("color")}`
            : ``
    }
${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `
                opacity:0.88
                max-width:320px`
                : ``
    }
${props =>
        props.inLine ?
            `position:relative
            float:left
            margin:1px
            width:calc(20% - 7px)
            // maxWidth:320px
            `
            : `
            width:calc(100%) !important
            margin:1px 0px 1px 0px
            `
    }
    width:calc(100%) !important
`

//REACT
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Component))
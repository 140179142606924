import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

import DecimalInput from "./input"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Backoffice-languages.js"

function DataView(props) {
    const { db } = reduxStore.getState()
    const expanded = db.myTasksMenuExpanded ? true : false

    return (
        <div style={expanded ? { width: "calc(100% - 400x)", marginLeft: "388px" } : { width: "calc(100% - 138px)", marginLeft: "138px" }}>

            <DecimalInput />
        </div>
    )
}

//REACT
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(DataView))
import React, { memo } from "react";
import Chartist from "chartist";
import ChartistGraph from "react-chartist";
import MD5 from "crypto-js/md5";
// import { ResponsiveLine } from '@nivo/line'

//COMPONENTS
import LinearProgress from "components/Progress/linear";
import KpiDescription from "components/KPI/dataSave";
import PopperMenu from "components/PopperMenu";

//@MATERIAL
import {
    withStyles
} from "@material-ui/core/styles";

//REDUX
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//FUNCTIONS
import {
    translate,
    customApp,
    formatNumber
} from "functions/";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/KPI_change";

import {
    model
} from "variables/charts";
import {
    loadTimeline,
} from "functions/chat";

import "assets/css/components/chart.css";
require("chartist-plugin-tooltips");

const KpiChange = (props) => {
    const { classes, data } = props
    const { db, timeline } = reduxStore.getState()
    const { cards } = db
    const [mounted, setMounted] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [cardsWithKpi, setCardsWithKpi] = React.useState([])

    let kpi_ini = Number(data.kpi_ini)
    let kpi_fin = Number(data.kpi_fin)

    React.useEffect(() => {
        if (!mounted) {
            setMounted(true)
            childrenCards(props.data._id)
            iniKpi()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    React.useEffect(() => {
        childrenCards(props.data._id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cards])

    const iniKpi = async () => {
        let reqData = {
            ids: [data._id],
            db: 'cards',
            reqChildrens: true,
            type: 'kpi',
            limit: true
        }
        let reqMd5 = MD5(`${JSON.stringify(reqData)}`).toString()
        if (
            timeline
            && (
                !timeline[data._id]
                ||
                (
                    timeline[data._id]
                    && Object.keys(timeline[data._id]).filter(l => timeline[data._id][l].type === 'kpi').length === 0
                )
            )
            && (
                !reduxStore.getState().timelineLoader
                || (
                    reduxStore.getState().timelineLoader
                    && !reduxStore.getState().timelineLoader[reqMd5]
                )
            )
        ) {
            setLoading(true)
            const result = await loadTimeline(
                {
                    ...props,
                    fnIdentification: "Timeline/Index/init()"
                },
                reqData
            )

            if (result) {
                setLoading(false)
                if (props.onResize) props.onResize()
            }
        } else {
            setLoading(false)
            if (props.onResize) props.onResize()
        }
    }

    //Filter all Children Cards with KPI = kpi_useParent
    let kpiCards = [props.data._id]
    const childrenCards = async (cardId, children) => {
        let chields = Object.keys(cards)
            .filter(a => cards[a].kpi_useParent && cards[a]._parent === cardId)
        if (chields.length > 0) {
            chields.map(async a => {
                childrenCards(a, true)
                kpiCards.push(a)
                setCardsWithKpi(Array.from(new Set(kpiCards)))
            })
        } else if (!children) {
            setCardsWithKpi(kpiCards)
        }
        return chields
    }

    if (!data)
        return (null)

    let kpiChart = {
        ...model
    }

    let series = []
    let labels = []

    let kpi_flow = {}
    cardsWithKpi.forEach(a => {
        kpi_flow = {
            ...kpi_flow,
            ...timeline[a] || {}
        }
    })


    kpi_flow = Object.keys(kpi_flow).filter(a => kpi_flow[a].type === 'kpi' && kpi_flow[a].kpi_type === data.kpi_type).map(a => kpi_flow[a])

    let kpi_total = data.kpi_type !== 'time' ? Number(kpi_ini) : 0
    let percent100 = String(kpi_fin).substring(0, 1) === -1 ? Number(kpi_ini) + Number(kpi_fin) : Number(kpi_ini) - Number(kpi_fin)
    let accomplished = 0
    if (percent100 < 0) percent100 = percent100 * -1

    if (kpi_flow && Object.keys(kpi_flow).length > 0) {
        Object.keys(kpi_flow).sort((a, b) => {
            if (kpi_flow[a].created_at < kpi_flow[b].created_at)
                return -1
            return 1
        }).forEach((a, i) => {
            kpi_total = Number(kpi_total) + Number(kpi_flow[a].value)
            accomplished = accomplished + Number(kpi_flow[a].value)
            series.push({
                meta: kpi_flow[a].value,
                total: kpi_total,
                value: Number(kpi_ini) > Number(kpi_fin) ? 100 - Math.floor((kpi_total * 100) / percent100) : Math.floor((kpi_total * 100) / percent100),
            })
            labels.push(i + 1)
            if (i + 1 === Object.keys(kpi_flow).length)
                kpiChart = {
                    ...kpiChart,
                    data: {
                        labels: labels,
                        series: [[...series]]
                    },
                    options: {
                        //fullWidth: true,
                        ...kpiChart.options,
                        low: 0,
                        high: 100,
                        plugins: [Chartist.plugins.tooltip({
                            tooltipFnc: function (meta, value, name) {
                                return (
                                    `${meta > 0 ? '+' : ''}` +
                                    formatNumber(meta, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null) + '<br>' + formatNumber(value, 'percent')
                                );
                            }
                        })],
                        axisX: {
                            showGrid: false,
                        },
                        axisY: {
                            showGrid: false,
                        },
                        onlyInteger: true,
                    },
                }
        })
    }


    let sumKpi = 0

    if (Number(kpi_ini) === 0 && Number(kpi_fin) !== 0) {
        sumKpi = Number(kpi_fin)
    } else if (Number(kpi_ini) > Number(kpi_fin)) {
        sumKpi = Number(kpi_ini) - Number(kpi_fin)
    } else if (Number(kpi_ini) < Number(kpi_fin)) {
        sumKpi = Number(kpi_fin) - Number(kpi_ini)
    }

    // Number(kpi_ini) > Number(kpi_fin) ? Number(kpi_ini) - Number(kpi_fin) : Number(kpi_ini) + Number(kpi_fin)
    // console.log(sumKpi)


    if (kpi_flow && Object.keys(kpi_flow).length > 0) {
        Object.keys(kpi_flow).forEach(a => {
            sumKpi = Number(sumKpi) + Number(kpi_flow[a].value)
        })
    }

    let percentKpi = Math.ceil(kpi_ini < kpi_fin ? kpi_fin - kpi_ini : kpi_ini - kpi_fin)
    percentKpi = ((Math.ceil(sumKpi) * 100) / percentKpi) - 100
    if (percentKpi < 0)
        percentKpi = percentKpi * -1


    if (!props.data)
        return (<div>NO DATA RECEIVED</div>)

    if (loading)
        return (<div style={{ position: "absolute", bottom: 0, left: "7px", right: "7px", height: 152 }}>
            <LinearProgress />
        </div>
        )

    const kpiInfo = () => {
        let kpii = kpi_ini && parseInt(kpi_ini) ? parseInt(kpi_ini) : 0
        let kpif = kpi_fin && parseInt(kpi_fin) ? parseInt(kpi_fin) : 0
        if (kpii > kpif)
            return (
                <div style={{
                    ...sumKpi < 0 ? { color: "red", fontWeight: "bold" } : {}
                }}>
                    {translate(`$__balance`, 1)}: {sumKpi < 0 ? `- ` : ``}{formatNumber(sumKpi || 0, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}{data.kpi_type === 'time' ? <span style={{ marginLeft: 5, fontSize: 10, textTransform: "lowercase" }}>{translate("$__minutes")}</span> : ``}
                </div>
            )
        return (
            <div style={{
                ...sumKpi < 0 ? { color: "red", fontWeight: "bold" } : {}
            }}>
                {translate(`$__targetValue`, 1)}: {formatNumber(kpii > kpif ? kpii - kpif : kpif - kpii || 0, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null)}{data.kpi_type === 'time' ? <span style={{ marginLeft: 5, fontSize: 10, textTransform: "lowercase" }}>{translate("$__minutes")}</span> : ``}
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <React.Fragment>
                {props.chartShow && data.kpi_type !== 'time' && data.kpi_type !== 'task' && kpi_flow.length > 0 ?
                    <div style={{
                        backgroundColor: customApp('color'),
                        height: 152
                    }}>
                        <ChartistGraph
                            className="ct-chart-white-colors"
                            data={kpiChart.data}
                            type="Line"
                            options={kpiChart.options}
                            listener={kpiChart.animation}
                        />
                    </div> :
                    <React.Fragment></React.Fragment>
                }
                {data.kpi_type !== 'task' &&
                    <fieldset style={{ position: 'relative' }}>
                        <legend
                            style={
                                {
                                    color: customApp('color')
                                }
                            }>
                            {translate('$__progress', 1)}
                        </legend>
                        <div className={classes.progress}>
                            <LinearProgress
                                variant="determinate"
                                size={100}
                                value={percentKpi}
                                showText
                                onClick={() => {
                                    // setReqSave(true)
                                }} />
                        </div>
                        <div style={{
                            position: 'relative',
                            textSize: '11px',
                            fontWeight: 'bold',
                            color: customApp('color')
                        }}>
                            {kpiInfo()}

                        </div>
                        <div style={{
                            position: 'relative',
                            textSize: '11px',
                            fontWeight: 'bold',
                            color: accomplished > 0 ? customApp('green') : 'red'
                        }}>
                            {translate(`$__accomplished`, 1)}: {accomplished && parseInt(accomplished) > 0 ? formatNumber(accomplished, data.kpi_type === 'time' ? 'number' : data.kpi_type, data.kpi_currency ? data.kpi_currency : null) : 0}
                        </div>
                        <div style={{
                            position: 'absolute',
                            right: '0px',
                            top: '-33px',
                            display: 'flex'
                        }}>
                            <PopperMenu
                                icon="add_circle"
                                title={translate("$__increase")}
                                iconColor={customApp('blue')}
                                onClick={() => {
                                    // setPositiveValue(true)
                                    // setReqSave(true)
                                }}
                                transparent
                            >
                                <KpiDescription
                                    data={data}
                                    PositiveValue={true}
                                    callBack={() => {
                                        // setReqSave(false)
                                    }}
                                />
                            </PopperMenu>
                            <PopperMenu
                                icon="remove_circle"
                                iconColor={'orange'}
                                title={translate("$__decrease")}
                                onClick={() => {
                                    // setPositiveValue(false)
                                }}
                                transparent
                            >
                                <KpiDescription
                                    data={data}
                                    PositiveValue={false}
                                    callBack={() => {
                                        // setReqSave(false)
                                    }}
                                />
                            </PopperMenu>
                        </div>
                    </fieldset>
                }
                {data.kpi_type === 'task' &&
                    <div className={classes.time}>TASK</div>
                }
            </React.Fragment>
        </div >
    );
}
//REACT
const mapStateToProps = (store) => ({
    store: {
        timeline: store.timeline,
        cards: store.db.cards
    }
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(KpiChange));
import {
    defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const style = {
    tags: {
        ...defaultFont,
        position: 'absolute',
        width: '100%',
        right: '0px',
        bottom: '-6px',
        userSelect: "none",
        height: '6px',
        marginBottom: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        "& .tag": {
            width: '15px',
            marginRight: '3px',
            cursor: 'pointer',
            height: '6px',
            borderRadius: ' 0px 0px 3px 3px',
        }
    },

    tagsOver: {
        ...defaultFont,
        position: 'relative',
        width: '100%',
        userSelect: "none",
        marginTop: '5px',
        marginBottom: '5px',
        height: 'auto',
        overflow: 'hidden',
        // maxWidth: '77%',
        "& .tag": {
            fontSize: '12px',
            padding: '0 5px',
            margin: '3px',
            borderRadius: ' 5px',
            color: customApp('light')
        }
    }
}

export default style;
import React from "react";
import moment from "moment-timezone";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import reduxStore from "store/";

//COMPONENTS
import DayList from "components/User/dayList";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// @MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard";

//FUNCTIONS
import {
    customApp,
    getAppAccess,
    translate,
} from "functions/";


function Dashboard(props) {
    const { db } = reduxStore.getState()
    const { users } = db
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    const [mounted, setMounted] = React.useState(false)
    let AppAccess = getAppAccess()

    React.useEffect(() => {
        if (!mounted) {
            if (!reduxStore.getState().session.cards.adminViewLoader)
                setAdminView()
            setMounted(true)
        }
        return function cleanup() {
            props.reduxFunction("ASYNC", "SET_SESSION", {
                ...reduxStore.getState().session,
                cards: {
                    ...reduxStore.getState().session.cards,
                    adminView: false
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards, props.users])

    if (AppAccess && AppAccess.plan && (
        !AppAccess.planAdmin
        && !AppAccess.planModerate
    )) {
        return <React.Fragment></React.Fragment>
    }

    const setAdminView = async (enabled) => {
        props.reduxFunction("ASYNC", "SET_SESSION", {
            ...reduxStore.getState().session,
            cards: {
                ...reduxStore.getState().session.cards,
                adminView: !reduxStore.getState().session.cards.adminView
            }
        });
    }

    let myCards = Object.keys(reduxStore.getState().db.cards).filter(a =>
        reduxStore.getState().db.cards[a]
        && reduxStore.getState().db.cards[a]._users
        && Object.keys(reduxStore.getState().db.cards[a]._users).filter(ua =>
            reduxStore.getState().db.cards[a]._users[ua].myDay
            && (moment(new Date(parseInt(reduxStore.getState().db.cards[a]._users[ua].myDay) * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today)
        ).length > 0
    ).map(c => reduxStore.getState().db.cards[c])
    console.log('----------------------------------------------', myCards.length)

    let filtered = Object.keys(users).filter(fil =>
        !users[fil].deleted &&
        users[fil].type === 'user' &&
        (!users[fil]._parent || users[fil]._parent === "")
        && myCards.filter(a =>
            a._users
            && a._users[fil]
            && a._users[fil].myDay
            && (moment(new Date(parseInt(a._users[fil].myDay) * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today)
        ).length > 0
    ).sort((a, b) => {
        if (users[a].name < users[b].name) {
            return -1
        } else if (users[a].name > users[b].name) {
            return 1
        } else {
            return 0
        }
    }
    ).map(a => users[a])

    return (
        <div style={{ width: '100%' }}>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{
                        fontSize: '33px',
                        padding: '15px 0',
                        fontWeight: 'bold',
                        color: customApp('menu')
                    }}>
                        {translate('$__dayTasks', 1)}
                    </div>

                </GridItem>
                {filtered.map(a =>
                    <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        xl={3}
                        key={a._id}
                        style={{
                            marginBottom: '7px'
                        }}
                    >
                        <DayList data={a} />
                    </GridItem>
                )}

            </GridContainer>
        </div >
    );
}

const mapStateToProps = (store) => {
    return {
        cards: store.db.cards,
        users: store.db.users
    }
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
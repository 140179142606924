// ID_CREATED*************************

//CORE
import React from "react"

//REDUX
import {
    connect
} from "react-redux"
import {
    bindActionCreators
} from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import HeaderWithIcon from "components/Header/withIcon"
import SideModuleCloseAndSave from "components/SideModule/closeAndSave"

//@MATERIAL
import {
    withStyles
} from "@material-ui/core/styles"
import styles from "./sideModule.css.js"

//IMPORT GENERICAL MODULES
import Timeline from "componentsV3/Timeline"

//IMPORT MODULES TO USER
import EditUser from "components/User/edit"
import UserAccess from "components/User/access"
import TwoFactor from "components/User/TwoFactor"
import UserSettings from "components/User/Settings"
import AccountSettings from "components/Account/Settings"
import UserMobile from "components/User/Mobile"
import MenuButton from "./MenuButton.jsx"

//IMPORT MODULES TO CARDS
import CardDelete from "components/Card/Delete"
import CardEdit from "components/Card/edit"
import CardKPI from "components/Card/kpi"
import CardSettings from "components/Card/settings"
import Catenation from "components/Catenation"
import Checklist from "components/Checklist"
import ColumnEdit from "components/Columns/edit"
import ColumnSettings from "components/Columns/settings"
import Files from "components/Files/list"
import Icon from "components/Icon"
import Recurrence from "components/Recurrence/list"
import SendTo from "components/SendTo/"
import TimerLog from "components/Timer/log"
import UserDelete from "components/User/Delete"
import Users from "components/User/in"
import AnswerForm from "componentsV3/Forms/AnswerForm"


//IMPORT MODULES TO FEED
import FeedEdit from "components/Feed/edit"
import LinkEdit from "components/Link/edit"

//FUNCTIONS
import {
    appAlert,
    customApp,
    getAppAccess,
    getDate,
    translate,
    textColor
} from "functions/"

import {
    getUserAccess,
    planMyAccountVerify,
    updateCardData,
    verifyTemplate,
} from "functions/cards"

import ErrorBoundary from "components/ErrorBoundary"

function Component(props) {
    const { sideModule, db, session } = reduxStore.getState()
    const [reqSave, setReqSave] = React.useState(false)
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        if (sideModule.id)
            if (!mounted && sideModule.activeModule === 'timeline') {
                setMounted(true)
                setReqSave(false)
            }

        return () => { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sideModule])

    if (!sideModule.data || !sideModule.data.type || db.dataType.filter(a => a.name === sideModule.data.type).length === 0)
        return null


    if (sideModule && !sideModule.id)
        return null


    const { classes } = props
    const { data } = sideModule

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let type = db.cards[data._id] ? 'cards' : 'users'

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(sideModule.id)

    let permission = false

    if (data && data._planId && (!db.cards[data._planId] || !planMyAccountVerify(data._planId)))
        permission = false

    permission = (
        sideModule &&
        (
            sideModule.id === 'new'
            ||
            (
                (
                    (
                        data.type === 'user'
                        || data.type === 'client'
                    )
                    &&
                    AppAccess
                    &&
                    (AppAccess.user
                        &&
                        (
                            AppAccess.userAdmin
                            || AppAccess.userModerate
                        )
                    )
                )
                ||
                (
                    (
                        data.type !== 'user'
                        && data.type !== 'client'
                        && data.type !== 'chatGroup'
                    )
                    &&
                    (
                        AppAccess
                        &&
                        (AppAccess.owner
                            ||
                            (
                                AppAccess.plan
                                && (
                                    AppAccess.planAdmin
                                    || AppAccess.planModerate
                                )
                            )
                        )
                    )
                )
                ||
                (
                    (data.type === 'chatGroup')
                    && (
                        !data.client
                        ||
                        (
                            data.client
                            && db.users[data.client]
                        )
                    )
                    && (
                        AppAccess
                        &&
                        (AppAccess.owner
                            ||
                            (
                                AppAccess.plan
                                && (
                                    AppAccess.planAdmin
                                    || AppAccess.planModerate
                                )
                            )
                        )
                    )
                )
            )
        )
    ) ? true : false



    if (!permission && parseInt(CardAccess) >= 4)
        permission = true

    if (!permission && planMyAccountVerify(data._planId) && AppAccess && AppAccess.admin && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate))
        permission = true

    const formAsks = data.formId && reduxStore.getState().forms[data.formId] ? JSON.parse(reduxStore.getState().forms[data.formId].elements) : []
    let formAnswers = {}
    formAsks.forEach(x => {
        if (data[x.id])
            formAnswers = {
                ...formAnswers,
                [x.id]: data[x.id]
            }
    })
    const MOD = [
        // {
        //     name: 'resume',
        //     icon: 'blur_on',
        //     title: '$__resume',
        //     resume: true,
        //     reqId: true,
        //     module: CardResume,
        //     closeOption: true,
        //     permission: 2,
        //     template: true,
        // },

        //CARD
        {
            name: 'cardEdit',
            icon: 'subject',
            title: '$__edition',
            reqId: false,
            module: CardEdit,
            closeOption: false,
            permission: 0,
            buttonsCount: 3,
            template: true,
        },
        ...(data.formId && reduxStore.getState().forms[data.formId] && Object.keys(reduxStore.getState().forms[data.formId]).length > 0) ? [{
            name: 'forms',
            icon: 'ballot',
            title: '$__forms',
            reqId: true,
            module: AnswerForm,
            permission: 0,
            closeOption: true,
            // hide: true,
            template: true,
            props: {
                formId: data.formId,
                values: formAnswers,
                disabled: true,
                style: { padding: 15 }
            },
        }] : [],
        {
            name: 'feedEdit',
            icon: 'rss_feed',
            title: '$__feedEdit',
            reqId: false,
            module: FeedEdit,
            closeOption: false,
            permission: 3,
            template: true,
        },
        {
            name: 'userEdit',
            icon: 'notes',
            title: '$__userEdit',
            reqId: false,
            module: EditUser,
            closeOption: false,
            permission: 0,
        },
        {
            name: 'checklist',
            icon: 'checklist',
            title: '$__list',
            reqId: true,
            module: Checklist,
            closeOption: true,
            permission: 1,
            props: {
                nodeId: sideModule.data._id,
                db: sideModule.db ? sideModule.db : 'cards',
                permission: permission,
                showProgress: true,
            },
            template: true,
        },
        ...(
            data.type.indexOf("guideline") > -1
            || data.type.indexOf("objective") > -1
            || data.type.indexOf("goal") > -1
        ) ? [{
            name: 'catenation',
            icon: 'account_tree',
            title: '$__catenation',
            reqId: true,
            module: Catenation,
            closeOption: true,
            permission: 2,
            props: {
                nodeId: sideModule.data._id,
                db: sideModule.db ? sideModule.db : 'cards',
            },
            template: true,
        }] : [],
        {
            name: 'recurrence',
            icon: 'update',
            title: '$__recurrence',
            reqId: true,
            module: Recurrence,
            closeOption: true,
            permission: 3,
            props: {
                nodeId: sideModule.data._id,
                db: sideModule.db ? sideModule.db : 'cards',
            },
            template: true,
        },
        //USER
        // {
        //     name: 'resumeUser',
        //     icon: 'blur_on',
        //     title: 'resume',
        //     resume: true,
        //     reqId: true,
        //     module: ResumeUser,
        //     closeOption: true,
        //     permission: 2,
        // },


        //FEED
        // {
        //     name: 'resumeFeed',
        //     icon: 'blur_on',
        //     title: 'resume',
        //     resume: true,
        //     reqId: true,
        //     module: ResumeFeed,
        //     closeOption: true,
        //     permission: 3,
        // },

        {
            name: 'link',
            icon: 'link',
            title: '$__link',
            reqId: false,
            module: LinkEdit,
            permission: 3,
            closeOption: false,
            hide: true,
            template: true,
        },

        {
            name: 'timeline',
            icon: 'timeline',
            title: '$__timeline',
            reqId: true,
            module: Timeline,
            props: {
                type: 'comment',
                nodeId: sideModule.id,
                db: 'cards',
                loadTimeline: true,
                disabledSend: String(CardAccess) === "0" ? true : false
            },
            closeOption: true,
            permission: 0,
            count: 'comment'
        },
        {
            name: 'access',
            icon: session._id === data._id ? 'vpn_key' : 'security',
            title: session._id === data._id ? '$__changePassword' : '$__access',
            reqId: true,
            module: UserAccess,
            closeOption: false,
            permission: session._id === data._id ? 0 : 4,
            // ownerLock: true,
        },
        {
            name: 'users',
            icon: 'supervised_user_circle',
            title: '$__usersAndDepartments',
            reqId: true,
            module: Users,
            closeOption: true,
            permission: 3,
            template: true,
        },
        ...(
            CardAccess >= 4
            ||
            (
                CardAccess && CardAccess < 4 && data.kpi_type && data.kpi_type !== "task"
            )
            ||
            (
                AppAccess && AppAccess.plan && AppAccess.planAdmin
            )
        ) ?
            [{
                name: 'kpi',
                icon: 'analytics',
                title: '$__kpi',
                reqId: true,
                module: CardKPI,
                closeOption: false,
                permission: 3,
                count: 'kpi'
            }] : [],
        {
            name: 'timeLog',
            icon: 'timer',
            title: '$__timerLog',
            reqId: true,
            module: TimerLog,
            closeOption: true,
            permission: 1,
            count: 'timer',
        },
        {
            name: 'files',
            icon: 'folder',
            title: '$__files',
            reqId: true,
            module: Files,
            closeOption: true,
            permission: 3,
            props: {
                nodeId: sideModule.id,
                db: sideModule.db
            },
        },
        {
            name: 'log',
            icon: 'history',
            title: '$__log',
            reqId: true,
            module: Timeline,
            props: {
                type: 'log',
                nodeId: sideModule.id,
                db: 'cards',
                loadTimeline: true,
                noSearch: true,
                disabledSend: true
            },
            closeOption: true,
            permission: 0,
            count: 'log'
        },
        ...(session._id === data._id) ?
            [{
                name: 'twoFactor',
                icon: 'pin',
                title: '$__twoFactorAuthenticator',
                reqId: true,
                module: TwoFactor,
                closeOption: false,
                permission: session._id === data._id ? 0 : 6,
                ownerLock: true,
            },
            {
                name: 'mobile',
                icon: 'phone_iphone',
                title: '$__mobile',
                reqId: true,
                module: UserMobile,
                closeOption: false,
                permission: session._id === data._id ? 0 : 6,
                ownerLock: true,
            }] : [],


        //COLUMN
        {
            name: 'columnEdit',
            icon: 'tune',
            title: '$__edition',
            reqId: false,
            module: ColumnEdit,
            closeOption: false,
            permission: 0,
            buttonsCount: 3,
            template: true,
        },

        //USER

        ...type === 'users' ?
            [{
                name: 'delete',
                icon: 'delete_sweep',
                title: '$__delete',
                reqId: true,
                module: UserDelete,
                closeOption: true,
            }]
            : [{
                name: 'delete',
                icon: 'delete_sweep',
                title: '$__delete',
                reqId: true,
                module: CardDelete,
                closeOption: true,
                permission: 5,
                template: true,
            }],


        {
            name: 'sendTo',
            icon: 'share',
            title: '$__sendTo',
            reqId: true,
            module: SendTo,
            closeOption: true,
            permission: 3,
            template: true,
        },
        ...(data.type === 'client' || data.type === 'group' || data.type === 'unity') ? [{
            name: 'settings',
            icon: 'admin_panel_settings',
            title: '$__settings',
            reqId: true,
            module: AccountSettings,
            closeOption: false,
            permission: 1,
            template: true,
        }] : [],
        ...(data.type === 'user' && session._id === data._id) ? [{
            name: 'settings',
            icon: 'admin_panel_settings',
            title: '$__settings',
            reqId: true,
            module: UserSettings,
            closeOption: false,
            permission: 1,
            template: true,
        }] : [],
        ...data.type === 'step' && permission ? [{
            name: 'settings',
            icon: 'settings',
            title: '$__settings',
            reqId: true,
            module: ColumnSettings,
            closeOption: false,
            permission: 5,
            template: true,
        }] : [],
        ...data.type !== 'user' && data.type !== 'step' ? [{
            name: 'settings',
            icon: 'settings',
            title: '$__settings',
            reqId: true,
            module: CardSettings,
            closeOption: false,
            permission: 5,
            template: true,
        }] : []
    ]

    const module = MOD.filter(fil => fil.name === sideModule.activeModule)[0]


    if (!module || !module.module)
        return null

    const openModule = mod => {
        if (reqSave) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    setReqSave(false)
                    sideModule.activeModule = mod
                    props.reduxFunction("ASYNC", "SET_MODULE", {
                        ...sideModule
                    })
                }
            })
        } else {
            sideModule.activeModule = mod
            props.reduxFunction("ASYNC", "SET_MODULE", {
                ...sideModule
            })
        }
    }

    const close = (rq = false) => {
        if (reqSave || rq) {
            appAlert({
                message: translate('$__confirmCloseWithUnsavedData', 1),
                variant: 'warning',
                persist: false,
                horizontal: 'right',
                confirm: () => {
                    props.reduxFunction("ASYNC", "CLEAR_MODULE")
                    setReqSave(false)
                }
            })
        } else {
            props.reduxFunction("ASYNC", "CLEAR_MODULE")
            setReqSave(false)
        }
    }



    const changeSideModuleWindow = () => {
        let windowType = false

        if (!windowType) {
            windowType = 'popup'
        } else if (windowType === 'popup') {
            windowType = 'expanded'
        } else if (windowType === 'expanded') {
            windowType = 'fullScreen'
        } else if (windowType === 'fullScreen') {
            windowType = null
        }

        props.reduxFunction("ASYNC", "SET_SESSION", {
            ...reduxStore.getState().session,
            sideModuleWindow: windowType
        });
    }

    const isTemplate = verifyTemplate(data._id)

    const { modules } = sideModule.data.type ? db.dataType.filter(a => a.name === "forms" || a.name === sideModule.data.type)[0] : []

    return (
        sideModule && sideModule.id ?
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 3000,
                    backgroundColor: "rgba(0,0,0,0.7)",
                    backdropFilter: "blur(3px)",
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    close()
                }
                }
            >
                <div
                    className={session.sideModuleWindow && session.sideModuleWindow === 'popup'
                        ? classes.modulePopup
                        : session.sideModuleWindow && session.sideModuleWindow === 'expanded'
                            ? classes.moduleExpanded
                            : session.sideModuleWindow && session.sideModuleWindow === 'fullScreen'
                                ? classes.moduleFullScreen
                                : classes.module}
                    id={`sideModule`}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <div
                        className={classes.menu}
                        style={{
                            background: customApp('menuColor'), //customApp
                            borderRight: `solid 3px ${customApp('color')}`, //customApp
                        }}
                        id={`sideModule_menu`}
                    >
                        <div className={classes.menuButtons_resume}>
                            {/* {modules.indexOf('resumeUser') > -1 && sideModule.id.length === 36 &&
                                <MenuButton icon='blur_on' module='resumeUser' title={translate('$__resume')} size='26px' 
                                openModule={(a) => {
                                                    openModule(a)
                                                }}
                                />
                            } */}
                            {/* {modules.indexOf('resume') > -1 && sideModule.id.length === 36 &&
                                <MenuButton icon='blur_on' module='resume' title={translate('$__resume', 1)} size='26px' 
                                openModule={(a) => {
                                                    openModule(a)
                                                }}
                                />
                            } */}
                        </div>
                        {MOD.filter((a, i) =>
                            a
                            &&
                            (
                                //TEMPLATE
                                (
                                    (
                                        !isTemplate
                                        ||
                                        (
                                            sideModule.data
                                            && isTemplate
                                            && a.template
                                        )
                                    )
                                    &&
                                    (
                                        !a.reqId
                                        ||
                                        (
                                            a.reqId
                                            && sideModule.id.length === 36
                                        )
                                    )
                                )
                                //TEMPLATES
                                //USERS
                                && (
                                    (
                                        (data.type === 'user' || data.type === 'client')
                                        &&
                                        (
                                            (

                                                (
                                                    AppAccess
                                                    && AppAccess.user
                                                    && (AppAccess.userAdmin
                                                        || (
                                                            AppAccess.userModerate
                                                            && a.permission <= 4
                                                        ) || (
                                                            AppAccess.userEdit
                                                            && a.permission <= 3
                                                        )
                                                    )
                                                )
                                                ||
                                                (
                                                    data._id === session._id
                                                    && a.permission <= 3
                                                )
                                            )
                                        )

                                    )
                                    ||
                                    (
                                        (
                                            data.type !== 'user'
                                            && data.type !== 'client'
                                            && data.type !== 'account'
                                            && data.type !== 'unity'
                                        )
                                        &&
                                        (
                                            permission
                                            && (
                                                AppAccess
                                                && AppAccess.plan
                                                && (
                                                    AppAccess.planAdmin
                                                    || (
                                                        AppAccess.planModerate
                                                        && a.permission <= 4
                                                    )
                                                )
                                            )
                                        )
                                    )
                                    ||
                                    (
                                        (
                                            data.type !== 'user'
                                            || data.type !== 'client'
                                            || data.type !== 'account'
                                        )
                                        && (
                                            String(CardAccess)
                                            && parseInt(CardAccess) >= parseInt(a.permission)
                                        )
                                    )
                                )
                                //USERS
                                &&
                                (
                                    (
                                        a.name === "forms"
                                        && data.formId
                                    )
                                    || (
                                        modules.indexOf(a.name) > -1
                                    )
                                )
                            )
                            && (
                                a.name !== 'recurrence'
                                ||
                                (
                                    a.name === 'recurrence'
                                    && (
                                        Object.keys(reduxStore.getState().db.cards)
                                            .filter(fil => {
                                                if (
                                                    !reduxStore.getState().db.cards[fil].deleted
                                                    && !reduxStore.getState().db.cards[fil].checklist
                                                    && reduxStore.getState().db.cards[fil]._recurrenceId
                                                    && reduxStore.getState().db.cards[fil]._recurrenceId === sideModule.data._id
                                                )
                                                    return true
                                                return false
                                            }
                                            ).length > 0
                                    )
                                )
                            )
                        )
                            .filter(fil =>
                                !sideModule.data._requireApproval
                                || (
                                    sideModule.data._requireApproval
                                    && (
                                        fil.name === "resume"
                                        || fil.name === "cardEdit"
                                        || fil.name === "timeline"
                                        || fil.name === "kpi"
                                        || fil.name === "log"
                                        || fil.name === "timer"
                                    )
                                )
                            )
                            .map((mod, i) => {
                                return (
                                    <MenuButton
                                        key={i}
                                        icon={mod.icon}
                                        module={mod.name}
                                        title={translate(mod.title, 1)}
                                        count={mod.count ? mod.count : null}
                                        openModule={(a) => {
                                            openModule(a)
                                        }}
                                    />
                                )
                                // }
                            })}
                        {/* {modules.map((a, i) => {
                            let permission = true
                            if (data && data._planId && (!db.cards[data._planId] || !planMyAccountVerify(data._planId)))
                                permission = false
                            let mod = MOD.filter(fil => fil.name.trim() === a.trim() && !fil.resume && !fil.hide)[0]
                            let render = false
                            if (
                                mod
                                && (
                                    (
                                        mod.name === "forms"
                                        && data.formId
                                    )
                                    ||
                                    (
                                        (data.type === 'user' || data.type === 'client')
                                        &&
                                        (
                                            (

                                                (
                                                    AppAccess
                                                    && AppAccess.user
                                                    && (AppAccess.userAdmin
                                                        || (
                                                            AppAccess.userModerate
                                                            && mod.permission <= 4
                                                        ) || (
                                                            AppAccess.userEdit
                                                            && mod.permission <= 3
                                                        )
                                                    )
                                                )
                                                ||
                                                (
                                                    data._id === session._id
                                                    && mod.permission <= 3
                                                )
                                            )
                                        )

                                    )
                                    ||
                                    (
                                        (
                                            data.type !== 'user'
                                            && data.type !== 'client'
                                            && data.type !== 'account'
                                            && data.type !== 'unity'
                                        )
                                        &&
                                        (
                                            permission
                                            && (
                                                AppAccess
                                                && AppAccess.plan
                                                && (
                                                    AppAccess.planAdmin
                                                    || (
                                                        AppAccess.planModerate
                                                        && mod.permission <= 4
                                                    )
                                                )
                                            )
                                        )
                                    )
                                    ||
                                    (
                                        (
                                            data.type !== 'user'
                                            || data.type !== 'client'
                                            || data.type !== 'account'
                                        )
                                        && (
                                            String(CardAccess)
                                            && parseInt(CardAccess) >= parseInt(mod.permission)
                                        )
                                    )
                                )
                            ) {
                                if (mod.ownerLock
                                    && AppAccess
                                    && !AppAccess.owner
                                    && data._access
                                    && data._access.owner
                                    && session._id !== data._id
                                ) {
                                    return null
                                }
                                if (
                                    (
                                        !isTemplate
                                        ||
                                        (
                                            sideModule.data
                                            && isTemplate
                                            && mod.template
                                        )
                                    )
                                    &&
                                    (
                                        (
                                            mod
                                            && !mod.reqId
                                        )
                                        ||
                                        (
                                            mod
                                            &&
                                            (
                                                mod.reqId
                                                || !mod.reqId
                                            )
                                            &&
                                            sideModule.id.length === 36
                                        )
                                    )
                                ) {
                                    if (mod.name === 'recurrence'
                                        && (
                                            Object.keys(reduxStore.getState().db.cards)
                                                .filter(fil => {
                                                    if (
                                                        !reduxStore.getState().db.cards[fil].deleted
                                                        && !reduxStore.getState().db.cards[fil].checklist
                                                        && reduxStore.getState().db.cards[fil]._recurrenceId
                                                        && reduxStore.getState().db.cards[fil]._recurrenceId === sideModule.data._id
                                                    )
                                                        return true
                                                }
                                                ).length > 0
                                        )
                                    ) {
                                        return (
                                            <MenuButton
                                                key={i}
                                                icon={mod.icon}
                                                module={mod.name}
                                                title={translate(mod.title, 1)}
                                                count={mod.count ? mod.count : null}
                                                openModule={(a) => {
                                                    openModule(a)
                                                }}
                                            />
                                        )
                                    } else if (mod.name !== 'recurrence') {
                                        return (
                                            <MenuButton
                                                key={i}
                                                icon={mod.icon}
                                                module={mod.name}
                                                title={translate(mod.title, 1)}
                                                count={mod.count ? mod.count : null}
                                                openModule={(a) => {
                                                    openModule(a)
                                                }}
                                            />
                                        )
                                    }
                                }
                            }
                        })} */}

                        <div style={{
                            position: 'absolute',
                            bottom: '0px',
                            width: '100%',
                            height: '50px'
                        }} onClick={() => {
                            changeSideModuleWindow()
                        }}></div>
                    </div>
                    <div
                        id={`sideModule_header`}
                    >
                        <HeaderWithIcon
                            title={(sideModule && sideModule.data && sideModule.data.name) || `${translate('$__create')} ${translate(`$__${sideModule.data.type}`)}`}
                            icon={sideModule?.data?.icon || (sideModule && sideModule.data && sideModule.data.type && db.dataType.filter(a => a.name === sideModule.data.type)[0].icon) || "view_comfy"}
                            style={{
                                paddingLeft: '50px',
                                width: `calc(100% - 0px)`,
                                minHeight: '50px',
                                marginTop: "-4px"
                            }}
                            color={customApp('menu')}
                            {...permission && sideModule?.data?._id ? {
                                editable: true,
                                onChangeIcon: (ic) => {
                                    updateCardData(
                                        {
                                            ...props,
                                            data: { _id: sideModule.data._id }
                                        }, {
                                        icon: ic
                                    })
                                }
                            } : {}}
                        />
                        {module.closeOption &&
                            <SideModuleCloseAndSave
                                close={() => close()}
                            />
                        }
                    </div>
                    <div className={classes.content}>
                        <ErrorBoundary>
                            <module.module
                                {...module.props}
                                permission={permission}
                                onClose={(a) => {
                                    close(a)
                                }
                                } reqSave={(a) => { setReqSave(a) }} />
                        </ErrorBoundary>
                    </div>
                    <div
                        className={classes.subTitle}
                        id={`sideModule_subTitle`}
                        style={{
                            ...sideModule && sideModule.data && sideModule.data.private ? {
                                background: "gray"
                            } : {

                            },
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        {translate(module.title.toLowerCase(), 1)}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: textColor(customApp('color'), true)
                                // marginBottom: 7
                            }}
                        >
                            <div>{data._cardCode ? `#${data._cardCode} ` : ``}</div>
                            {data.created_at ?
                                <div
                                    style={{
                                        position: "relative",
                                        fontSize: 12,
                                        color: textColor(customApp('color'), true),
                                        textAlign: "right",
                                        marginLeft: 10
                                    }}>
                                    {translate("$__createdAt")}: {getDate(data.created_at)}
                                </div>
                                : <React.Fragment></React.Fragment>
                            }
                        </div>
                        {sideModule && sideModule.data && sideModule.data.private ?
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                {translate("$__isPrivate", "*")}<Icon icon={"lock"} color={"#FFFFFF"} size={20} style={{ marginLeft: 7 }} />
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                </ div >

            </div >
            : null
    )
}


const mapStateToProps = (store) => (
    {
        sideModule: store.sideModule,
    }
)
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))

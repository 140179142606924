import React, { useState } from 'react'
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "draft-js/dist/Draft.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const MessageEditor = ({edit, data, handleChangeData}) => {
/*     const [data, setData] = useState({}); */ 
    const [editorData, setEditorData] = useState({
        historyEditor: null
    })

    const editorStyle = {
        backgroundColor: '#fff',
        minWidth:'270px',
        width:'100%',
        minHeight:'150px',
        height:'100%',
        fontFamily:'Soleto, Roboto, Helvetica, Arial, sans-serif',
        border:'none',
        margin:'0px'
    }
    const toolbarStyleObject = {
        width:'270px',
        fontFamily:'Soleto, Roboto, Helvetica, Arial, sans-serif',
        border:'none',
        display: edit ? 'flex':'none',
        justifyContent:'space-between',
        borderBottom:'2px solid #eeeeee',
        borderRadius: '5px',
    }
    return (
        <>
            <Editor 
                toolbar={{
                    options:['inline','image', 'list', 'link'],
                    inline: {
                        inDropdown: false,
                        options: ['bold', 'italic', 'underline'],
                    },
                    list:{
                        inDropdown: false,
                        options: ['unordered']
                    },
                    link:{
                        inDropdown: false,
                        options: ['link']
                    },
                }}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                toolbarStyle={toolbarStyleObject}
                editorState={data?.messageD}
                onEditorStateChange={(d) => {
                    handleChangeData({
                        ...data,
                        message: draftToHtml(convertToRaw(d.getCurrentContent())),
                        messageD: d,
                    })
                    setEditorData({
                        ...editorData,
                        message: d,
                    })
                }}
                hashtag={{
                    separator: ' ',
                    trigger: '#',
                }}
                editorStyle={editorStyle}
                readOnly={!edit}
            />
        </>
    )
}

export default MessageEditor
// ID_CREATED*************************

//CORE
import React from "react";
import Routes from "routes";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";
import history from "store/history";

//COMPONENTS
import HtmlTooltip from "components/Tooltip/html";
import IconButton from "components/CustomButtons/IconButton";
import MoreMenu from "components/MoreMenu/"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

//FUNCTIONS
import {
    translate,
    customApp,
    getAppAccess,
} from "functions/";

import { getUserAccess } from "functions/cards"

import {
    loadCards
} from "functions/loadData";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sidebar-submenu";

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"

function SubMenuSideBar(props) {
    const { db, search, session } = reduxStore.getState();
    const [expandingCards, setExpandingCards] = React.useState(false);
    const [adminViewLoading, setAdminViewLoading] = React.useState(false);
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const locationPathName = window.location.pathname.split("/");
    const pathName = `/${locationPathName[1]}`;
    const expanded = props.expanded

    React.useEffect(() => {
        if (search && search.users && search.users.selected && Object.keys(search.users.selected).filter(a => search.users.selected[a] === true).length > 0) {

        } else {
            props.reduxFunction("ASYNC", "SET_SESSION", {
                ...reduxStore.getState().session,
                cards: {
                    ...reduxStore.getState().session.cards,
                    adminViewLoader: false,
                    adminView: false
                }
            });
        }
        return () => {
            props.reduxFunction("ASYNC", "SET_DB", {
                ...reduxStore.getState().db,
                viewList: false,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search.users.selected])

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")


    const pageBase = `${pathName}#${hashs[0] ? hashs[0] : "tab"}`

    let AppAccess = getAppAccess()

    const expandingAllCards = (v) => {
        setExpandingCards(v)
        let cards = reduxStore.getState().db.cards
        Object.keys(cards).forEach(a => {
            if (v && cards[a] && !cards[a]._expanded)
                cards[a]["_expanded"] = v
            if (!v && cards[a] && cards[a]._expanded)
                cards[a]._expanded = v
        })

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cardsExpanded: v,
            cards: cards
        })
    }

    const setAdminView = async (enabled) => {
        //CREAT A CONDITION USERS TO ADMINVIEW - Precisa estar ao menos com um usuário selecionado para ativar modo ADMIN ***************************************************
        setAdminViewLoading(true)
        if (!reduxStore.getState().session.cards.adminViewLoader) {
            const response = await loadCards(props, null, true)
            if (reduxStore.getState().session.cards.adminViewLoader || response) {
                setAdminViewLoading(false)
                props.reduxFunction("ASYNC", "SET_SESSION", {
                    ...reduxStore.getState().session,
                    cards: {
                        ...reduxStore.getState().session.cards,
                        adminViewLoader: true,
                        adminView: !reduxStore.getState().session.cards.adminView
                    }
                });
            }
        } else {
            setAdminViewLoading(false)
            props.reduxFunction("ASYNC", "SET_SESSION", {
                ...reduxStore.getState().session,
                cards: {
                    ...reduxStore.getState().session.cards,
                    adminViewLoader: true,
                    adminView: !reduxStore.getState().session.cards.adminView
                }
            });
        }
    }

    const openPage = (page) => {
        history.push(page)
    }

    return (
        <React.Fragment>
            {/* {console.log(xs.a.b.c.d.e.f)} //ERRORBOUNDARY TEST ERROR */}
            <div className={expanded ? classes.menuExpanded : classes.menu}            >
                <div className={expanded ? classes.buttonExpanded : classes.button}>
                    <IconButton
                        icon={expanded ? "menu_open" : "read_more"}
                        text={expanded ? translate("$__collapsingMenu", 1) : null}
                        title={!expanded ? translate("$__expandingMenu", 1) : null}
                        iconColor={customApp("subMenuTextColor")}
                        textColor={customApp("subMenuTextColor")}
                        onClick={() => {
                            props.subMenuExpanded(!expanded)
                        }}
                        size={"21px"}
                    />
                </div>
                {(window.location.pathname === "/files" || window.location.pathname === "/t" || window.location.pathname === "/d" || (window.location.pathname === "/sp" && hashs[0] && hashs[0].length === 36)) &&
                    <React.Fragment>
                        {!expandingCards ?
                            <div
                                className={expanded ? classes.buttonExpanded : classes.button}
                                id={`sideBar_subMenu_options_expandingCards`}
                            >
                                <IconButton
                                    text={expanded ? translate("$__expandingCards", 1) : null}
                                    textColor={customApp("subMenuTextColor")}
                                    title={!expanded ? translate("$__expandingCards", 1) : null}
                                    icon="open_in_full"
                                    iconColor={customApp("subMenuTextColor")}
                                    onClick={() => { expandingAllCards(true) }}
                                    size={"21px"}
                                />
                            </div>
                            :
                            <div
                                className={expanded ? classes.buttonExpanded : classes.button}
                                id={`sideBar_subMenu_options_collapseCards`}
                            >
                                <IconButton
                                    text={expanded ? translate("$__collapseCards", 1) : null}
                                    textColor={customApp("subMenuTextColor")}
                                    title={!expanded ? translate("$__collapseCards", 1) : null}
                                    icon="close_fullscreen"
                                    iconColor={customApp("subMenuTextColor")}
                                    onClick={() => { expandingAllCards(false) }}
                                    size={"21px"}
                                />
                            </div>
                        }
                    </React.Fragment>
                }
                {(
                    (
                        (
                            pathName === "/d"
                            && hashs[1] === "dayTasks"
                        )
                        ||
                        (
                            pathName === "/t"
                            && search
                            && search.users
                            && search.users.selected
                            && Object.keys(search.users.selected).filter(a => search.users.selected[a] === true).length > 0
                        )
                        ||
                        (
                            pathName === "/sp"
                            && !hashs[0]
                        )
                    )
                    && AppAccess
                    &&
                    (
                        AppAccess.owner
                        ||
                        (
                            AppAccess.plan
                            &&
                            (
                                AppAccess.planAdmin
                                || AppAccess.planModerate
                            )
                        )
                    )
                ) &&
                    <div className={expanded ? classes.buttonExpanded : classes.button}
                        style={{
                            display: "flex"
                        }}>
                        {adminViewLoading ?
                            <CircularProgress size={25} style={{ margin: "auto", display: "flex" }} />                                    // </div>
                            :
                            <IconButton
                                text={expanded ? translate("$__adminViewDescription", 1) : null}
                                title={!expanded ? translate("$__adminViewDescription", 1) : null}
                                textColor={reduxStore.getState().session.cards.adminView ? customApp("color") : customApp("subMenuTextColor")}
                                iconColor={reduxStore.getState().session.cards.adminView ? customApp("color") : customApp("subMenuTextColor")}
                                icon="groups"
                                id={`sideBar_subMenu_options_setAdminView`}
                                onClick={() => {
                                    setAdminView()
                                }}
                                size={"21px"}
                            />
                        }
                    </div>
                }

                {window.location.pathname === "/t" &&
                    <React.Fragment>
                        <div
                            className={expanded ? classes.buttonExpanded : classes.button}
                            id={`sideBar_subMenu_options_setAdminView`}
                        >
                            <IconButton
                                text={expanded ? translate("$__viewInColumns", 1) : null}
                                textColor={!db.viewList ? customApp("color") : customApp("subMenuTextColor")}
                                iconColor={!db.viewList ? customApp("color") : customApp("subMenuTextColor")}
                                title={!expanded ? translate("$__viewInColumns", 1) : null}
                                icon="view_column"
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_DB", {
                                        ...reduxStore.getState().db,
                                        viewList: false,
                                    })
                                }}
                                size={"21px"}
                            />
                        </div>
                        <div
                            className={expanded ? classes.buttonExpanded : classes.button}
                            id={`sideBar_subMenu_options_viewInList`}
                        >
                            <IconButton
                                text={expanded ? translate("$__viewInList", 1) : null}
                                title={!expanded ? translate("$__viewInList", 1) : null}
                                icon="list"
                                iconColor={db.viewList ? customApp("color") : customApp("subMenuTextColor")}
                                textColor={db.viewList ? customApp("color") : customApp("subMenuTextColor")}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_DB", {
                                        ...reduxStore.getState().db,
                                        viewList: true,
                                    })
                                }}
                                size={"21px"}
                            />
                        </div>
                    </React.Fragment>
                }
                {
                    <React.Fragment>
                        <div
                            className={classes.separator}
                        ></div>
                        {hashs[0] && hashs[0].length === 36 && db.cards[hashs[0]] &&
                            <div
                                style={{ zIndex: 1 }}
                                id={`sideBar_subMenu_plan_infos`}
                            >
                                <PlanInfo
                                    expanded={expanded}
                                    {...props}
                                />
                            </div>
                        }

                        {Routes.filter(a => a.path === pathName).length > 0
                            && Routes.filter(a => a.path === pathName)[0].tabs
                            && Routes.filter(a => a.path === pathName)[0].tabs.length > 0
                            && ((Routes.filter(a => a.path === pathName)[0].hashId && hashs[0] && hashs[0].length === 36) || !Routes.filter(a => a.path === pathName)[0].hashId) &&
                            <React.Fragment>
                                {Routes.filter(a =>
                                    a.path === pathName
                                )[0].tabs.filter(a =>
                                (
                                    (
                                        (
                                            !a.hashId
                                            && (
                                                !hashs[0]
                                                ||
                                                (
                                                    hashs[0]
                                                    && hashs[0].length < 36
                                                )
                                            )
                                        )
                                        ||
                                        (a.hashId
                                            && hashs[0]
                                            && hashs[0].length === 36
                                        )
                                    )
                                )
                                )
                                    .filter((a, i) => {
                                        let render = false
                                        let plan = hashs[0] && hashs[0].length === 36 ? db.cards[hashs[0]] : null
                                        if (a && a.AppAccess && a.AppAccess.length > 0) {
                                            a.AppAccess.forEach(acc => {
                                                if (AppAccess[acc])
                                                    render = true
                                            })
                                        } else if (
                                            a && !a.AppAccess
                                        )
                                            render = true

                                        if (plan
                                            && plan.isTemplate
                                            && !a.templates
                                        )
                                            render = false

                                        if (a
                                            && a.AppAccess
                                            && a.AppAccess.planAdmin
                                            && !(
                                                AppAccess
                                                && AppAccess.plan
                                                && AppAccess.planAdmin
                                            )
                                        )
                                            render = false

                                        if (
                                            plan
                                            && plan.planType
                                            && (
                                                a.alias === "matrix"
                                                || a.alias === "guidelines"
                                                || a.alias === "objectives"
                                                || a.alias === "goals"
                                            )
                                        )
                                            render = false

                                        if (
                                            plan
                                            && a.alias === "board"
                                            && (
                                                !plan.planType
                                                ||
                                                (plan.planType
                                                    && plan.planType !== 'operational'
                                                )
                                            )
                                        )
                                            render = false
                                        if (
                                            plan
                                            && a.alias === "matrix"
                                            && plan.planType
                                        )
                                            render = false
                                        if (
                                            plan
                                            && plan.planType === "operational"
                                            && a.alias === 'overview'
                                        )
                                            render = false

                                        if (a.accountHas && a.accountHas.filter(a => session[a]).length === 0)
                                            render = false

                                        if (
                                            (
                                                AppAccess.owner
                                                ||
                                                (
                                                    AppAccess
                                                    && AppAccess.enabled
                                                    && render
                                                )
                                            )
                                            && a.show
                                        )
                                            return true
                                        return false
                                    })
                                    .map((a, i) => {
                                        return (
                                            <div
                                                className={expanded ? classes.buttonExpanded : classes.button}
                                                key={i}
                                                id={`sideBar_subMenu_plan_${a.alias}`}
                                            >
                                                <IconButton
                                                    text={expanded ? a.alias === "matrix" ? "MATRIX SWOT" : translate(`$__${a.alias}`, 1) : null}
                                                    title={!expanded ? a.alias === "matrix" ? "MATRIX SWOT" : translate(`$__${a.alias}`, 1) : null}
                                                    icon={a.icon}
                                                    iconColor={hashs[1] === a.path ? customApp("subMenuTextColorActive") : customApp("subMenuTextColor")}
                                                    textColor={hashs[1] === a.path ? customApp("subMenuTextColorActive") : customApp("subMenuTextColor")}
                                                    onClick={() => {
                                                        openPage(`${pageBase}/${a.path}`)
                                                    }}
                                                    size={"21px"}
                                                />
                                            </div>
                                        )

                                    }
                                    )}
                            </React.Fragment>
                        }
                        {hashs[0] && hashs[0].length === 36 && db.cards[hashs[0]] && db.cards[hashs[0]].miroBoard &&
                            <div
                                className={expanded ? classes.buttonExpanded : classes.button}
                                id={`sideBar_subMenu_plan_miroBoard`}
                            >
                                <IconButton
                                    text={expanded ? translate("$__planningBoard", 1) : null}
                                    title={!expanded ? translate("$__planningBoard", 1) : null}
                                    icon={'developer_board'}
                                    iconColor={hashs[1] === 'planningBoard' ? customApp("color") : customApp("subMenuTextColor")}
                                    textColor={hashs[1] === 'planningBoard' ? customApp("color") : customApp("subMenuTextColor")}
                                    onClick={() => {
                                        openPage(`${pageBase}/${'planningBoard'}`)
                                    }}
                                    size={"21px"}
                                />
                            </div>
                        }
                        {
                            window.location.pathname === "/t" &&
                            <React.Fragment>
                                <div
                                    className={classes.separator}
                                ></div>
                                <div
                                    className={expanded ? classes.buttonExpanded : classes.button}
                                    id={"sideBar_subMenu_lastTasks"}
                                >
                                    <IconButton
                                        text={expanded ? translate("$__lateTasks", 1) : null}
                                        title={!expanded ? translate("$__lateTasks", 1) : null}
                                        icon="event_busy"
                                        iconColor={hashs[1] === "late" ? customApp("color") : customApp("subMenuTextColor")}
                                        textColor={hashs[1] === "late" ? customApp("color") : customApp("subMenuTextColor")}
                                        onClick={() => {
                                            history.push("t#tab/lateTasks")
                                        }}
                                        size={"21px"}
                                    />
                                </div>
                                <div
                                    className={expanded ? classes.buttonExpanded : classes.button}
                                    id={"sideBar_subMenu_nextTasks"}
                                >
                                    <IconButton
                                        text={expanded ? translate("$__nextTasks", 1) : null}
                                        title={!expanded ? translate("$__nextTasks", 1) : null}
                                        icon="date_range"
                                        iconColor={hashs[1] === "nextTasks" ? customApp("color") : customApp("subMenuTextColor")}
                                        textColor={hashs[1] === "nextTasks" ? customApp("color") : customApp("subMenuTextColor")}
                                        onClick={() => {
                                            history.push("t#tab/nextTasks")
                                        }}
                                        size={"21px"}
                                    />
                                </div>
                            </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

const PlanInfo = (props) => {
    const { db } = reduxStore.getState();

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let AppAccess = getAppAccess()
    let CardAccess = hashs[0] && hashs[0].length === 36 && db.cards[hashs[0]] && db.cards[hashs[0]].type === "plan" ? getUserAccess(hashs[0]) : null

    let image = hashs && hashs.length > 0 && hashs[0].length === 36 && db.cards[hashs[0]] && db.cards[hashs[0]].image ? db.cards[hashs[0]].image : defaultAvatar

    let planOptions = []
    if (db.cards[hashs[0]] && db.cards[hashs[0]].client && Object.keys(db.cards).filter(card => !db.cards[card].deleted && db.cards[card].client && db.cards[card].client === db.cards[hashs[0]].client).length > 1)
        Object
            .keys(db.cards)
            .filter(card =>
                !db.cards[card].deleted
                && db.cards[card].client === db.cards[hashs[0]].client
                && db.cards[card].type === 'plan'
            )
            .forEach(card => {
                planOptions.push({
                    name: db.cards[card].name,
                    ...db.cards[card].image ? { avatar: db.cards[card].image } : { icon: 'view_comfy' },
                    onClick: () => {
                        history.push(`sp#${db.cards[card]._id}/dashboard`)
                    }
                })
            })
    return (
        <HtmlTooltip title={
            <React.Fragment>
                {db.cards[hashs[0]].name}
            </React.Fragment>
        } >
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "left",
                    alignItems: "center",
                    ...((CardAccess && parseInt(CardAccess) > 3) || AppAccess.owner || AppAccess.planAdmin || AppAccess.planEdit || AppAccess.planModerate) ?
                        { cursor: 'pointer' } : {}
                }
                }
                id={"plan_identification"}
                onClick={() => {
                    if ((CardAccess && parseInt(CardAccess) > 3) || AppAccess.owner || AppAccess.planAdmin || AppAccess.planEdit || AppAccess.planModerate)
                        props.reduxFunction("ASYNC", "SET_MODULE", {
                            ...reduxStore.getState().sideModule,
                            db: 'cards',
                            id: hashs[0],
                            module: "cardEdit",
                            activeModule: 'cardEdit',
                            data: db.cards[hashs[0]],
                        })

                }
                }
            >
                <div
                    style={{
                        width: "30px",
                        height: "30px",
                        backgroundColor: "black",
                        marginLeft: "7px",
                        marginRight: "3px",
                        borderRadius: "11px",
                        backgroundImage: `url("${image}")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center"
                    }}></div>
                {props.expanded &&
                    <div style={{ width: "100%", zIndex: 1 }}>
                        {db.cards[hashs[0]].client
                            && db.users[db.cards[hashs[0]].client] &&
                            <div
                                style={{
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    marginLeft: "15px"
                                }}>
                                {db.users[db.cards[hashs[0]].client].name}
                            </div>
                        }
                        <div
                            style={{
                                fontSize: "12px",
                                marginLeft: "7px",
                                maxWidth: "calc(100% - 60px)",
                                display: "flex",
                                justifyContent: "space-between"
                            }}
                            id={"client_plan_switch"}
                        >
                            {planOptions.length === 0 ?
                                db.cards[hashs[0]].name
                                :
                                <MoreMenu
                                    text={db.cards[hashs[0]].name}
                                    icon={'change_circle'}
                                    transparent
                                    maxWidth="200px"
                                    options={planOptions}
                                />
                            }
                        </div>
                    </div>
                }
            </div>
        </HtmlTooltip>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(SubMenuSideBar)
import React, { useRef, useEffect } from "react"
import moment from "moment-timezone"
import styled from "styled-components"

//REDUX
import * as reduxActions from "store/actions"
import history from "store/history"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Badge from "components/Icon/Badge"
import CardTags from "components/Tags/Card"
import CustomButton from "components/Buttons/custom"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import IconButton from "components/CustomButtons/IconButton"
import QuickUsersList from "components/User/avatarList"
import Timer from "components/Timer/timer"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import Tooltip from "@material-ui/core/Tooltip"

//DEPENDENCIES
import FakeExpanded from "./fakeCardExpanded"
import CardExpanded from "./cardExpanded"
import UseTemplate from "./useTemplate"

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Card.js"

import {
    getCardColor,
    getUserAccess,
    restoreCard,
    saveMATRIX,
    selectSWOT
} from "functions/cards"


import {
    Highlighted,
    appAlert,
    customApp,
    getAppAccess,
    getDate,
    translate,
} from "functions/"

function Card(props) {
    const classes = useStyles()
    const { db, session } = reduxStore.getState()
    const data = props.data
    const [isExpanded, setExpanded] = React.useState(false)
    const [globalExpanded, setGlobalExpanded] = React.useState(props.cardsExpanded)
    const [useTemplate, setUseTemplate] = React.useState(false)
    const [showPrivateCard, setShowPrivateCard] = React.useState(false)
    const [cardColor, setCardColor] = React.useState(props.cardColorPicker ? getCardColor(data) : {})
    const [combine, setCombine] = React.useState(false)

    const cardRef = useRef({});
    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (cardRef && cardRef.current) {
            if (props.onResize) {
                let newSize = cardRef.current.clientHeight
                if (props.separator)
                    newSize = newSize + 55
                props.onResize(newSize, isExpanded)
                if (cardRef && cardRef.current) {
                    const _config = { attributes: true, childList: true, subtree: true };
                    const _observer = new MutationObserver(() => {
                        if (mounted.current) {
                            let newSize = cardRef.current.clientHeight
                            if (props.separator)
                                newSize = newSize + 55
                            props.onResize(newSize, isExpanded)
                        }
                    });
                    _observer.observe(cardRef.current, _config);

                    return () => {
                        _observer.disconnect()
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded, props.manualExpanded, combine]);

    useEffect(() => {
        if (props?.snapshot?.combineTargetFor)
            setCombine(true)
        if (!props?.snapshot?.combineTargetFor && combine)
            setCombine(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.snapshot?.combineTargetFor])


    React.useEffect(() => {
        let isMounted = true
        if (isMounted && String(props.unlockedPrivates) === "0" && showPrivateCard) {
            setShowPrivateCard(false)
        }
        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.unlockedPrivates])

    React.useEffect(() => {
        let isSubscribed = true
        if (isSubscribed)
            setGlobalExpanded(props.cardsExpanded)

        return () => {
            isSubscribed = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cardsExpanded])

    React.useEffect(() => {
        if (props.onExpand)
            props.onExpand(globalExpanded)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalExpanded])

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    React.useEffect(() => {
        let isMounted = true
        if (isMounted && props.cardColorPicker && hashs && hashs[1] === 'overview') {
            setCardColor(getCardColor(data))
            isMounted = false
        }
        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    if (data === {} || !data)
        return (<React.Fragment></React.Fragment>)

    let AppAccess = getAppAccess()
    let CardAccess = getUserAccess(data._id)

    if (!data || !props.data || !props.data._id)
        return null

    const selectThis = async () => {
        let activeCards = {}

        Object.keys(db.cards).filter(a =>
            db.cards[a]
            && !db.cards[a].deleted
            && (
                db.cards[a]._planId === hashs[0]
                || db.cards[a]._parent === hashs[0]
            )
        ).forEach(a => {
            activeCards = {
                ...activeCards,
                [a]: db.cards[a]
            }
        })

        let cards = { ...reduxStore.getState().db.cards }
        let selected = data._selected ? false : true

        if (db.dataType.filter(fil => fil.name === data.type)[0].module !== "swot") {
            Object.keys(activeCards).filter(a => activeCards[a]._id !== data._id && activeCards[a].type && activeCards[a].type === data.type).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }

        if (data.type.indexOf("guideline") > -1) {
            Object.keys(activeCards).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else if (data.type.indexOf("objective") > -1) {
            Object.keys(activeCards).filter(a => activeCards[a].type.indexOf('objective') === -1 || activeCards[a].type.indexOf('guideline') === -1).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else if (data.type.indexOf("goal") > -1) {
            Object.keys(activeCards).filter(a => activeCards[a].type.indexOf('goal') === -1 || activeCards[a].type.indexOf('objective') === -1 || activeCards[a].type.indexOf('guideline') === -1).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        } else {
            Object.keys(activeCards).forEach(a => {
                if (activeCards[a]._selected)
                    activeCards[a]._selected = false
            })
        }

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...cards,
                ...activeCards,
                [data._id]: {
                    ...reduxStore.getState().db.cards[data._id],
                    ...activeCards[data._id],
                    _selected: selected
                }
            }
        })


        if (reduxStore.getState().db.dataType.filter(a => a.name === data.type)[0].module === "swot") {
            saveMATRIX(props)
        }
        if (selected && reduxStore.getState().db.dataType.filter(a => a.name === data.type)[0].module === "matrix") {
            selectSWOT(props, data._id)
        }
    }

    const openSideModule = async (module = "cardEdit") => {
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...reduxStore.getState().sideModule,
            db: props.db,
            id: data._id,
            module: "cardEdit",
            activeModule: module,
            data: data,
        })
    }


    const openPlan = async (id) => {
        if (
            parseInt(CardAccess) > 2
            ||
            (AppAccess.owner
                ||
                (
                    AppAccess.plan
                    && (
                        AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                )
            )
        ) {
            history.push(`sp#${id}/dashboard`)//#CORRECT
        } else {
            openSideModule("timeline")
        }
    }

    const restoreThis = async () => {
        appAlert({
            message: translate("$__confirmRestoreThis", 1),
            variant: "warning",
            persist: false,
            horizontal: "right",
            confirm: () => {
                restoreCard(props, { _id: data._id })
            }
        })
    }


    const selectable = Object.keys(reduxStore.getState().db.cards)
        .filter(a =>
            reduxStore.getState().db.cards[a]
            && reduxStore.getState().db.cards[a]._selected === true
            && reduxStore.getState().db.cards[a].type
            && reduxStore.getState().db.cards[a].type.indexOf("guideline") > -1
        ).length > 0
        ? true
        : false

    const cardClick = () => {
        if (props.disabled)
            return
        if (props.selectable) {
            selectThis()
        } else if (!props.onClick && ((!props.locked && props.showSelected && selectable) || props.selectable)) {
            selectThis()
        } else if (props.onClick && !props.selectable && !props.locked) {
            props.onClick()
        } else if (!props.locked && !props.onlyOption && data.type === 'plan') {
            openPlan(data._id)
        } else if (!props.locked) {
            openSideModule()
        }
        if (props.onOpen)
            props.onOpen()
    }

    const expanded = props.noExpanded ? false : isExpanded || (!props.notGlobalExpanded && (props.manualExpanded || props.expanded || data._expanded || props.cardsExpanded) ? true : false)

    let permission = (
        (
            AppAccess
            &&
            (AppAccess.owner
                ||
                (
                    AppAccess.plan
                    && (
                        AppAccess.planAdmin
                        || AppAccess.planModerate
                    )
                )
            )
        )
        ||
        (
            CardAccess > 2
        )
    )
        ? true : false

    if (data && data.private && (
        !data._users
        || (
            data._users
            && !data._users[session._id]
        )
    ))
        return (<React.Fragment></React.Fragment>)

    if (useTemplate) {
        return (
            <UseTemplate cardId={data._id}
                toTemplate={!data.isTemplate ? true : false}
                onComplete={() => {
                    setUseTemplate(false)
                }} />
        )
    }

    let locked = false

    let headerData = {
        title: data?.name || translate("$__new", 1),
        icon: data?.icon || (data.type && db && db.dataType && db.dataType.filter(a => a.name === data.type)[0] && db.dataType.filter(a => a.name === data.type)[0].icon) || "$__topic",
        iconTitle: `$__${data.type}`,
    }

    if (
        (data._planId && reduxStore.getState().db.cards[data._planId]?.customColumns)
        && (data.type.indexOf("guideline") > -1 || data.type.indexOf("objective") || data.type.indexOf("goal"))
    ) {
        let columnData = JSON.parse(reduxStore.getState().db.cards[data._planId].customColumns)
        let filType = data.type.indexOf('guideline') > -1 ? `guideline` : data.type
        if (columnData[`${filType}s`])
            headerData = {
                ...headerData,
                ...columnData[`${filType}s`].name ? { iconTitle: columnData[`${filType}s`].name } : {},
                ...columnData[`${filType}s`].icon ? { icon: columnData[`${filType}s`].icon } : {}
            }
    }


    return (
        <Container
            ref={cardRef}
            separator={props.separator || false}
            plan={expanded && data.type === 'plan' ? true : false}
            isDragging={(props.snapshot && props.snapshot.isDragging) || null}
            isDragDisable={data.isDragDisable || props.isDragDisable || false}
            inLine={data.type !== "plan" && props.inLine ? true : false}
            className={!props.noShadow ? classes.container : classes.containerNoShadow}
            selectable={props.showSelected}
            selected={data._selected}
            priority={data.priority && db.priority.filter(a => String(a.value) === String(data.priority)).length > 0 ? db.priority.filter(a => String(a.value) === String(data.priority))[0].color : null}
            locked={props.locked || false}
            snapshot={props.snapshot || null}
            isCombining={props.snapshot && props.snapshot.combineTargetFor ? true : false}
            onClick={(e) => {
                cardClick()
            }}
        >
            {(data.status === 'completed' || (props.cardColorPicker && cardColor.color)) &&
                <div style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: "pointer"
                }}>
                    <div style={{
                        position: 'absolute',
                        backgroundColor: props.cardColorPicker && cardColor.color ? cardColor.color : 'rgba(0,0,0,1)',
                        opacity: cardColor.color ? 0.2 : 0.1,
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: "pointer"
                    }}>
                    </div>

                </div>
            }

            <React.Fragment>
                {props.snapshot && props.snapshot.combineTargetFor &&
                    <div style={{ backgroundColor: customApp('menu'), padding: '7px 3px', color: '#FFFFFF', fontWeight: 'bold', textAlign: 'center' }}>
                        {translate('$__dropHereToLink')}
                    </div>
                }


                <HeaderWithIcon
                    private={(data.private || data.parentPrivate) ? true : false}
                    unlockedPrivates={props.unlockedPrivates}
                    setPrivate={(a) => {
                        let value = a ? 1 : -1
                        if (!a && props.unlockedPrivates === 0)
                            value = 0
                        props.reduxFunction("ASYNC", "SET_CONTROL", {
                            ...reduxStore.getState().controls,
                            unlockedPrivates: reduxStore.getState().controls.unlockedPrivates + value,
                        })
                        setShowPrivateCard(a)
                        if (props.setPrivate)
                            props.setPrivate(a)
                    }}
                    viewAllName={props.viewAllName}
                    title={headerData.title}
                    tooltipText={`${data._parent && reduxStore.getState().db.cards[data._parent] ? `${reduxStore.getState().db.cards[data._parent].name} / ` : ``}${headerData.title}`}
                    searchText={props?.searchText || null}
                    icon={headerData.icon}
                    iconTitle={headerData.iconTitle}
                    color={customApp('medium')}
                    editable={!data.name && (!props.onlyOption || props.editable) ? true : false}
                    _id={data._id}
                    locked={data.type === "link" || props.locked ? true : false}
                    colorPicker={permission && props.cardColorPicker && cardColor && !cardColor.parent ? (color) => {
                        if (
                            (!cardColor.color && color)
                            || (cardColor.color && cardColor.color !== color)
                        )
                            props.reduxFunction("ASYNC", "SET_DB", {
                                ...reduxStore.getState().db,
                                cards: {
                                    ...reduxStore.getState().db.cards,
                                    [data._id]: {
                                        ...reduxStore.getState().db.cards[data._id],
                                        color
                                    },
                                }
                            })
                    } : null}
                    onEdit={
                        (
                            data.status !== 'completed'
                            && (
                                (
                                    (
                                        CardAccess
                                        && parseInt(CardAccess) > 1
                                    )
                                ) || (
                                    AppAccess
                                    && AppAccess.plan
                                    &&
                                    (
                                        AppAccess.owner
                                        || AppAccess.planAdmin
                                        || AppAccess.planEdit
                                        || AppAccess.planModerate
                                    )
                                )
                            )
                        ) ? (res) => {
                            props.reduxFunction("ASYNC", "SET_DB", {
                                ...reduxStore.getState().db,
                                cards: {
                                    ...reduxStore.getState().db.cards,
                                    [data._id]: {
                                        ...reduxStore.getState().db.cards[data._id],
                                        name: res
                                    },
                                }
                            })
                        } : null}
                    isExpanded={props.noExpanded ? false : expanded}
                    noExpanding={props.expanded || reduxStore.getState().db.cardsExpanded || false}
                    onExpanding={() => {
                        if (props.onExpand) {
                            props.onExpand(!expanded)
                        }
                        if (!props.expanded && !reduxStore.getState().db.cardsExpanded) {
                            setExpanded((prevExpanded) => !prevExpanded)
                        }
                    }
                    }
                />
                {data && (data._cardCode || data.value) ?
                    <div style={{
                        width: "100%",
                        clear: "both",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "space-between"
                    }}>
                        {data && data._cardCode ?
                            <div
                                style={{
                                    paddingLeft: 36,
                                    fontSize: 11,
                                    marginTop: -9,
                                    color: "gray",
                                    display: "flex",
                                    alignItems: "center",
                                    whiteSpace: "nowrap"
                                }}>
                                # {data._cardCode}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                        {data && data.value ?
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "right",
                                    fontSize: 11,
                                    marginTop: -9,
                                    color: "black",
                                }}>
                                $ {data.value}
                            </div>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {(((data.type === 'insight' || data.type === 'link') && data._createdBy && db.users[data._createdBy._id])
                    ||
                    (data._createdBy && data._createdBy.channel && data._createdBy._id && data._createdBy._id !== session._id)
                ) &&
                    <div style={{
                        width: 'calc(100% - 0px)',
                        alignItems: 'center',
                        padding: '7px',
                        display: 'inline-flex',
                        borderBottom: 'solid 1px rgba(0,0,0,0.03)'
                    }}>
                        <div style={{
                            position: 'relative',
                            float: 'left'
                        }}>
                            <Tooltip
                                title={data._createdBy.name || db.users[data._createdBy._id].name}
                            >
                                <Avatar
                                    alt={data._createdBy.name || db.users[data._createdBy._id].name}
                                    src={db.users[data._createdBy._id]?.image || null}
                                    size="12px"
                                    style={{ width: 16, height: 16 }}
                                >
                                    {data._createdBy?.name?.substr(0, 1) || db.users[data._createdBy._id].name.substr(0, 1)}
                                </Avatar>
                            </Tooltip>
                        </div>
                        <div style={{
                            position: 'relative',
                            float: 'right',
                            width: '100%',
                            paddingLeft: '7px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: '12px'
                        }}>
                            <div style={{
                                color: customApp('menu'),
                                fontWeight: 'bold',
                            }}>
                                <span style={{ fontSize: 10, color: "gray" }}>{translate("$__createdBy", '*')}</span>: {data._createdBy?.name?.split(' ')[0] || db.users[data._createdBy._id].name.split(' ')[0]}
                            </div>
                            <div>
                                {moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY") ?
                                    `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("H:mm")}`
                                    :
                                    moment(new Date()).tz("America/Sao_Paulo").format("YYYY") === moment(data.created_at * 1000).tz("America/Sao_Paulo").format("YYYY") ?
                                        `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM H:mm")}`
                                        :
                                        `${moment(data.created_at * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY H:mm")}`
                                }
                            </div>
                        </div>
                    </div>
                }

                {data._kpiTimer && Object.keys(data._kpiTimer).length > 0 && Object.keys(data._kpiTimer).filter(a => data._kpiTimer[a].userId === session._id).length !== 0 &&
                    <div
                        style={{
                            position: 'relative',
                            width: '100%',
                            float: 'left'
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <Timer data={data} />
                    </div>
                }
                {(
                    !props.noExpanded
                    && expanded
                    && (
                        !data.private
                        || (
                            data.private
                            && (showPrivateCard || reduxStore.getState().controls.lockPrivate)
                        )
                    )
                ) ?
                    <React.Fragment>
                        <CardExpanded
                            {...props}
                            private={data.private ? true : false}
                            expanded
                            onClick={(e) => {
                                // e.preventDefault()
                                // cardClick()
                                // e.stopPropagation()
                            }}
                            Resume={true}
                        // onExpand={() => {
                        //     if (props.onExpand)
                        //         props.onExpand()
                        // }}
                        />
                        {/* {
                            (
                                (
                                    (
                                        props.resume
                                        && props.showChildrens
                                    )
                                    ||
                                    expanded
                                )
                                &&
                                Object.keys(childrensIndicators).length > 0
                            )
                            &&
                            <AvatarGroup max={100} className={classes.indicators} style={{
                                flexFlow: 'wrap'
                            }}>
                                {Object.keys(childrensIndicators)
                                    .map((a, i) => (
                                        String(a) !== "null" && String(a) !== "undefined" ?
                                            <Badge
                                                overlap="rectangular"
                                                key={`${i}_${a}`}
                                                className={classes.indicatorsIcon}
                                                icon={db.dataType.filter(c => c.name === a)[0].icon}
                                                title={translate(`$__${a}`, 1)}
                                                size="16px"
                                                count={indicators[a]}
                                                disabled
                                                onClick={props.onClickChildrens ? () => {
                                                    props.onClickChildrens(a)
                                                } : null}
                                            />
                                            : null
                                    ))}
                            </AvatarGroup>
                        } */}
                        <div>
                            {props.searchText && data.description &&
                                <div className={classes.description}>
                                    {Highlighted(data.description, props.searchText)}
                                </div>
                            }

                        </div>
                    </React.Fragment>
                    : expanded ? <React.Fragment>
                        <FakeExpanded />
                    </React.Fragment>
                        : <React.Fragment></React.Fragment>
                }
                {!data.isTemplate ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%"
                        }}
                    >
                        {
                            (
                                data.status !== "completed"
                                &&
                                (
                                    (
                                        data.startDate
                                        && moment(data.startDate).isValid()
                                    )
                                    ||
                                    (
                                        data.endDate
                                        && moment(data.endDate).isValid()
                                    )
                                )
                            )
                                ?
                                <div style={{
                                    position: 'relative',
                                    display: 'flex',
                                    width: '100%',
                                    ...expanded ? { height: '45px' } : {}
                                }}>
                                    <div className={
                                        (
                                            data.endDate
                                            && moment(new Date(data.endDate * 1000)).tz("America/Sao_Paulo").format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")
                                            && data.status !== "completed"
                                        )
                                            ? classes.lateDates
                                            : classes.dates
                                    }>
                                        {data.startDate && moment(data.startDate).isValid() &&
                                            <div className={classes.dateInfo}>
                                                <Badge
                                                    overlap="rectangular"
                                                    className={classes.dateIco} icon="insert_invitation" title={"$__startDate"} size="14px" disabled />
                                                {(new Date(data.startDate * 1000) instanceof Date && !isNaN(new Date(data.startDate * 1000))) ?
                                                    moment(new Date(data.startDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                    :
                                                    moment(data.startDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                }
                                            </div>
                                        }
                                        {data.endDate && moment(data.endDate).isValid() ?
                                            <div className={classes.dateInfo}>
                                                <Badge
                                                    overlap="rectangular"
                                                    className={classes.dateIco} icon="event_available" title={"$__endDate"} size="14px" disabled />
                                                {(new Date(data.endDate * 1000) instanceof Date && !isNaN(new Date(data.endDate * 1000))) ?
                                                    moment(new Date(data.endDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                    :
                                                    moment(data.endDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                                }
                                            </div>
                                            : <React.Fragment></React.Fragment>
                                        }
                                    </div>
                                </div>
                                : <React.Fragment></React.Fragment>

                        }

                    </div>
                    : <React.Fragment></React.Fragment>
                }
                {data._tags && data._tags.filter(a => a.value).length > 0 ?
                    <div
                        style={props.showTags || expanded ? {
                            marginBottom: 33
                        } : {}}
                    >
                        <CardTags
                            options={data._tags}
                            expanded={props.showTags || (
                                expanded
                                && data.type !== 'plan'
                                && data.type !== 'link'
                                && data.type !== 'insight'
                            )
                                ? true
                                : false
                            }
                        />
                    </div> : <React.Fragment></React.Fragment>
                }
                {
                    data._recurrenceId &&
                    <div
                        style={{
                            position: 'absolute',
                            width: "100%",
                            bottom: '3px',
                            left: '3px',
                            display: 'flex',
                            fontSize: '11px',
                            color: 'lightgrey',
                            alignItems: 'center'
                        }}
                    >
                        <Badge
                            overlap="rectangular"
                            className={classes.indicatorsIcon}
                            icon={'history'}
                            color={'lightgrey'}
                            size={"25px"}
                            title={!props.noShowRecurrenceCard ? (
                                <div style={{ maxWidth: "280px" }}>
                                    {translate('$__recurrentActivityFrom')}:
                                    <Card db="cards" data={db.cards[data._recurrenceId]} Avatar ignoreList onlyOption />
                                </div>
                            ) : `${translate('$__recurrence')}${data.recurrenceCreatedDate ? `: ${moment(data.recurrenceCreatedDate).format("DD/MM/YYYY")}` : ``}`}
                        />
                        {data.recurrenceCreatedDate ? moment(data.recurrenceCreatedDate).format("DD/MM/YYYY") : ``}
                    </div>

                }
                {(
                    data._users
                    && Object.keys(data._users).filter(a => String(a) !== null).length > 0
                    &&
                    (
                        !data._users[session._id]
                        ||
                        (
                            data._users[session._id]
                            && Object.keys(data._users).length > 1
                        )
                    )
                )
                    ?
                    <React.Fragment>
                        <div style={{
                            width: "100%",
                            marginTop: expanded ? 37 : 7
                        }}>
                            <div className={classes.usersContent}>
                                <div className={classes.users}>
                                    <QuickUsersList
                                        _id={data._id}
                                        data={data}
                                        permission={permission}
                                        transparent
                                        myDay
                                        limit={3} />
                                </div>
                            </div>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
                }
            </React.Fragment>
            {
                data.isTemplate &&
                <div style={{
                    position: "relative",
                    width: "100%",
                    clear: "both",
                    padding: "7px",
                    backgroundColor: customApp("menu"),
                    marginTop: "7px",
                    cursor: "pointer"
                }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setUseTemplate(true)
                    }}>
                    <IconButton
                        text={translate("$__useThisTemplate", '*')}
                        icon="auto_awesome"
                        iconColor={'#FFFFFF'}
                        textColor={'#FFFFFF'}
                    />
                </div>
            }

            {
                data.status === 'completed' &&
                <div style={{
                    // backgroundColor: 'rgba(0,0,0,0.4)',
                    position: 'absolute',
                    // height: '2px',
                    left: 0,
                    right: 0,
                    top: '20px'
                }}>
                    <div
                        style={{
                            position: "absolute",
                            right: 0,
                            bottom: -12,
                            color: "#FFF",
                            fontSize: 10,
                            padding: 3,
                            backgroundColor: "rgba(0,0,0,0.66)",
                        }}
                    >
                        {translate("$__completeDate")}:
                        {getDate(data.completed_at || data.updated_at)}
                    </div>
                </div>
            }
            {
                (data.type !== 'plan' && data.deleted) ?
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: "pointer"
                    }}>
                        <div style={{
                            position: 'absolute',
                            backgroundColor: '#71389da6',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            paddingRight: 7,
                            display: 'flex',
                            alignItems: 'center',
                            cursor: "pointer",
                            justifyContent: "right"
                        }}>
                            <CustomButton
                                title={translate(`$__restoreThis`, 1)}
                                text={translate(`$__restoreThis`, 1)}
                                color="#FFFFFF"
                                icon={"restore_from_trash"}
                                onClick={() => {
                                    restoreThis()
                                }}
                                size={"17px"}
                                textSize={"17px"}
                                transparent
                            />
                        </div>

                    </div>
                    : <React.Fragment></React.Fragment>
            }
            {locked ?
                <div style={{
                    position: "absolute",
                    backgroundColor: "rgba(233,233,233,0.88)",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <div style={{
                        display: "flex"
                    }}>
                        <Icon color="lightgray" icon="lock" size={"22px"} />
                    </div>

                </div>
                :
                <React.Fragment></React.Fragment>
            }
            <div style={{ clear: "both" }}></div>
        </Container >
    )
}

const useStyles = makeStyles(styles)

const Container = styled.div`
            minWidth:200px;
            background-color:#fff;
            marginBottom:7px;
            box-shadow:rgba(0,0,0,0.1) 0px 0px 10px 3px;

            ${props =>
        props.separator ? `marging-top:30px;` : ``
    }
            ${props =>
        props.selectable && props.selected ? `
        opacity:1;`
            : props.selectable ? `
        opacity:0.5;`
                : ``
    }
            ${props =>
        props.locked ?
            `cursor:no-drop !important;`
            : `cursor:pointer;`
    }
            ${props =>
        props.selected ?
            `border-bottom:solid 5px ${customApp("color")} !important;`
            : ``
    }
            ${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `opacity:0.88;
                 max-width:320px;
                 border:solid 10px red;`
                : ``
    }
            ${props =>
        props.inLine ?
            `position:relative;
            float:left;
            margin:3.5px;
            width:calc(20% - 7px);
            // maxWidth:320px;
            `
            : `
            width:calc(100%) !important;
            margin:7px 0px 12px 0px;
            `
    }
            width:calc(100%) !important;

            ${props =>
        props.priority ?
            `border-left:5px solid ${props.priority} !important;`
            : ``
    }
            ${props => props.isCombining ? `border:solid 3px ${customApp('menu')} !important;` : ``}
            `


//REACT
const mapStateToProps = (store) => ({
    cardsExpanded: store.db.cardsExpanded,
    showPrivate: store.controls.lockPrivate,
    cards: store.db.cards,
    unlockedPrivates: store.controls.unlockedPrivates,
    controls: store.controls
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Card)
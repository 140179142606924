import React, { memo } from 'react';
import MD5 from "crypto-js/md5"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

//IMAGES
import tour1 from "assets/img/tour/strategy-pt.png"

//FUNCTIONS
import { getAppAccess } from "functions/";

function App(props) {
    const { db, session } = reduxStore.getState()
    let AppAccess = getAppAccess()


    let searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const search = props.searchs && props.searchs[searchID] ? props.searchs[searchID] : null

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let plans = []

    plans = Object.keys(db.cards).filter(a =>
        db.cards[a].type === 'plan'
        && (
            (
                hashs[1] !== "trash"
                && !db.cards[a].deleted
            )
            || (
                hashs[1] === "trash"
                && db.cards[a].deleted
            )
        )
    ).sort((a, b) => {
        if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low))
            return -1
        if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) > parseInt(db.cards[b].order.low))
            return 1
        return 0
    }).map(a => db.cards[a])

    if (
        (
            AppAccess
            &&
            !AppAccess.owner
            &&
            (
                !AppAccess.planAdmin
                && !AppAccess.planEdit
                && !AppAccess.planRead
                && !AppAccess.planModerate
            )
            &&
            db.cards
            && session
            && session._id
        )
        || !db.adminView
    ) {
        plans = Object.keys(db.cards).filter(a =>
            db.cards[a]
            && db.cards[a].type === 'plan'
            && (
                !db.cards[a].private
                || (
                    db.cards[a].private
                    && db.cards[a]._users
                    && db.cards[a]._users[session._id]
                )
            )
            && (
                (
                    hashs[1] === 'templates'
                    ||
                    (
                        hashs[1] !== 'templates'
                        && db.cards[a]._users
                        && Object.keys(db.cards[a]._users).filter(us => us === session._id).length > 0
                    )
                )
                ||
                (
                    (
                        hashs[1] === 'admin'
                        && db.cards[a]._users
                        && Object.keys(db.cards[a]._users).filter(us => us === session._id).length === 0
                    )
                )
            )
        ).sort((a, b) => {
            if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low))
                return -1
            return 1
        }).map(a => db.cards[a])
    } else {
        plans = Object.keys(db.cards).filter(a =>
            db.cards[a]
            && db.cards[a].type === 'plan'
            && db.cards[a]._users
            && (
                Object.keys(db.cards[a]._users).filter(us => us === session._id).length > 0
                || (
                    !db.cards[a].private
                    && Object.keys(db.cards[a]._users).filter(us => us !== session._id).length > 0
                )
            )
        ).sort((a, b) => {
            if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low))
                return -1
            return 1
        }).map(a => db.cards[a])
    }

    if (search) {
        if (search.text)
            plans = plans.filter(fil => {
                if (fil && fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                    return true
                return false
            }).map(res => res)
        plans = plans.filter(fil => {
            if ((fil && fil.status && search && search.status && search.status[fil.status]) || (!fil.status && (search && search.status && search.status["notStarted"])))
                return true
            return false
        }).map(res => res)

        plans = plans.filter(fil => {
            if ((fil && fil.priority && search.priority[fil.priority]) || (search.priority["null"] && !fil.priority))
                return true
            return false
        }).map(res => res)


        if (search && search.users && search.users.length > 0)
            plans = plans.filter(fil => {
                let result = false
                if (search.users.indexOf("ALL") > -1 && (!fil._users || Object.keys(fil._users).length === 0)) {
                    result = true
                } else if (fil
                    && fil._users
                    && Object.keys(fil._users).length > 0
                ) {
                    Object.keys(fil._users).forEach(a => {
                        if (search.users.indexOf(a) > -1)
                            result = true
                    })
                    return result
                }
                return false
            }).map(res => res)

        if (search && search.clients && search.clients.length > 0) {
            plans = plans.filter(fil => {
                if (search.clients.indexOf("ALL") > -1 && !fil.client)
                    return true
                if (fil.client && search.clients.indexOf(fil.client) > -1)
                    return true
                return false
            }).map(res => res)
        }
        if (search && search.tags && search.tags.length > 0) {
            plans = plans.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                    let result = false
                    search.tags.forEach(tagFilter => {
                        if (tagFilter.value === tag.value)
                            result = true
                    })
                    return result
                }
                ).length > 0
            ).map(res => res)
        }
    }

    return (
        <GridContainer style={{ marginTop: '7px' }}  >
            {
                plans && plans.length > 0 && plans
                    .filter(a => {
                        if (
                            (
                                !a._planId
                                || (
                                    a._planId
                                    && plans.filter(pl => pl._id === a._planId).length === 0
                                )
                            )
                            &&
                            (
                                (
                                    (
                                        hashs[1] !== 'templates'
                                        && (
                                            !a.isTemplate
                                            ||
                                            (a.isTemplate && hashs[1] === 'trash')
                                        )
                                    )
                                    ||
                                    (
                                        hashs[1] === 'templates'
                                        && a.isTemplate
                                    )
                                )
                                &&
                                (
                                    (
                                        hashs[1] === 'archived'
                                        && a.archived
                                    )
                                    ||
                                    (
                                        hashs[1] !== 'archived'
                                        && !a.archived
                                    )
                                )
                                &&
                                (
                                    (
                                        hashs[1] === 'trash'
                                        && a.deleted
                                    )
                                    ||
                                    (
                                        hashs[1] !== 'trash'
                                        && !a.deleted
                                    )
                                )
                            )
                        ) return true
                        return false
                    }
                    )
                    .sort((a, b) => {
                        if (a.name < b.name) {
                            return -1
                        } else if (a.name > b.name) {
                            return 1
                        } else {
                            return 0
                        }
                    }).map((a) => (
                        <GridItem
                            key={a._id}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            xl={2}
                        >
                            <Card db="cards" data={a} Avatar expanded ignoreList />
                        </GridItem>
                    ))
            }
            {db.loaded && (!plans || plans.length === 0) &&
                <div style={{ width: '100%', height: 'calc(100vh - 150px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <img alt="MACHEN_IMAGE" src={tour1} />
                </div>
            }
        </GridContainer>
    )
}


const mapStateToProps = (store) => {
    return ({
        cards: store.db.cards,
        users: store.db.users,
        searchs: store.searchs
    })
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(App))
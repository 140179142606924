//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";

//@MATERIAL
import Autocomplete from "@material-ui/lab/Autocomplete";

import api from "api/";
//FUNCTIONS
function Component(props) {
    const changeGroups = async (d, v) => {
        let newArray = v
        let data = reduxStore.getState().db[props.dbNode]
        if (props.ws) {
            const validation = await newArray.map(async (r, i) => {
                if (!r.value && props.dbNode && data.filter(a => (r && r.label && a.label === r.label) || (r && r === a.label)).length === 0) {
                    let response = await api.post(props.api ? props.api : `node/add/${props.dbNode}`,
                        { name: r.trim(), secondaryParent: props.secondaryParent ? props.secondaryParent : null })
                    if (response) {
                        // console.log(response)
                        let id = response && response.data && response.data.id ? response.data.id : response.data.value
                        if (data.filter(a => a.value === id).length === 0) {
                            data.push({ value: id, label: r })
                            props.reduxFunction("ASYNC", "SET_DB", {
                                ...reduxStore.getState().db,
                                [props.dbNode]: data
                            });
                        }
                        if (newArray.filter(a => a.value === id).length === 0) {
                            newArray.splice(i, 1)
                            newArray.push({ value: id, label: r })
                        }
                    }
                } else if (data.filter(a => (r && r.label && a.label === r.label) || (r && r === a.label)).length > 0) {
                    let valueSelect = data.filter(a => (r && r.label && a.label === r.label) || (r && r === a.label))[0]
                    if (valueSelect && newArray.filter(a => a.value === valueSelect.value).length === 0) {
                        newArray.splice(i, 1)
                        newArray.push({ value: valueSelect.value, label: valueSelect.label })
                    }
                }
                var returnArray = [];
                newArray.forEach(a => {
                    if (a && a.value)
                        returnArray.push(a)
                })
                newArray = Array.from(returnArray)
                return newArray
            })
            Promise.all(validation).then(() => {
                if (props.onChange)
                    props.onChange(d, newArray)
            })
        } else {
            if (props.onChange)
                props.onChange(d, newArray)
        }
    }
    let autoCompleteProps = { ...props }
    if (String(props.dbNode)) delete autoCompleteProps.dbNode
    if (String(props.onChange)) delete autoCompleteProps.onChange
    if (String(props.store)) delete autoCompleteProps.store
    if (String(props.ownProps)) delete autoCompleteProps.ownProps
    if (String(props.reduxFunction)) delete autoCompleteProps.reduxFunction
    if (String(props.ws)) delete autoCompleteProps.ws
    if (String(props.add)) delete autoCompleteProps.add
    if (props.inNode || String(props.inNode) === 'undefined' || String(props.inNode) === 'null') delete autoCompleteProps.inNode
    if (props.InputProps || String(props.InputProps) === 'undefined' || String(props.InputProps) === 'null') delete autoCompleteProps.InputProps
    if (props.dark || String(props.dark) === 'undefined' || String(props.dark) === 'null') delete autoCompleteProps.dark
    if (props.endAdornment || String(props.endAdornment) === 'undefined' || String(props.endAdornment) === 'null') delete autoCompleteProps.endAdornment
    if (props.startAdornment || String(props.startAdornment) === 'undefined' || String(props.startAdornment) === 'null') delete autoCompleteProps.startAdornment
    if (props.secondaryParent || String(props.secondaryParent) === 'undefined' || String(props.secondaryParent) === 'null') delete autoCompleteProps.secondaryParent

    return (
        <Autocomplete
            {...autoCompleteProps}
            onChange={
                (d, v) => {
                    changeGroups(d, v)
                }
            }
        />
    )
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(Component)
const initialState = {};

const languages = (state = initialState, data) => {
    if (data.action === 'SET_LANGUAGE' && data.value) {
        return data.value
    }
    else {
        return state
    }
};

export default languages;
import React from "react"

//@MATERIAL 
import TF from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";

//FUNCTIONS
import { customApp } from "functions/";

//STYLES
import {
    defaultFont,
} from "assets/jss/material-dashboard-pro-react";

const TextField = React.forwardRef((props, ref) => {
    const styles = {
        root: {
            ...defaultFont,
            color: customApp('mediumText'),
            padding: 0,
            width: "100%",
            marginTop: "7px",
            marginBottom: "7px",
            '& .invalid': {
                borderBottom: 'solid 2px red !important',
            },
            '& .MuiOutlinedInput-input': {
                padding: "11px 11px",
                fontSize: "12px",
            },
            '& label': {
                marginTop: "-10px",
                fontSize: "12px"
            },
            '& label.MuiFormLabel-filled': {
                color: customApp('mediumText'), //customApp
                marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : 0
            },
            '& label.Mui-focused': {
                color: customApp('mediumText'), //customApp
                marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : 0
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: (props.linecolor || customApp('line')), //customApp
            },
            '& .MuiOutlinedInput-root': {
                borderRadius: customApp('fieldRadius'),
                '& fieldset': {
                    borderColor: (props.linecolor || customApp('line')), //customApp
                },
                '&.Mui-focused fieldset': {
                    borderColor: (props.linecolor || customApp('line')), //customApp
                },
            },
            '& .MuiFilledInput-root': {
                borderRadius: customApp('fieldRadius'),
                '& fieldset': {
                    borderColor: (props.linecolor || customApp('line')), //customApp
                },
                '&::before': {
                    borderBottomColor: (props.linecolor || customApp('line'))
                },
                '&::after': {
                    borderBottomColor: (props.linecolor || customApp('line'))
                },
                '&.Mui-focused': {
                    borderBottomColor: (props.linecolor || customApp('line')), //customApp
                    '&::before': {
                        borderBottomColor: (props.linecolor || customApp('line'))
                    },
                    '&::after': {
                        borderBottomColor: (props.linecolor || customApp('line'))
                    },
                },
            },
            '& .MuiInputLabel-outlined': {
                marginTop: '0px',
                '&.Mui-focused': {
                    marginTop: '0px',
                },
                '&.MuiFormLabel-filled': {
                    marginTop: '0px',
                }
            },
            '& .MuiInputBase-root': {
                marginTop: customApp('fieldVariant') === 'standard' ? 0 : customApp('fieldVariant') === 'filled' ? "-15px" : 0,
                borderRadius: customApp('fieldRadius'),
                '& fieldset': {
                    borderColor: (props.linecolor || customApp('line')), //customApp
                },
                '&::before': {
                    borderBottomColor: (props.linecolor || customApp('line'))
                },
                '&::after': {
                    borderBottomColor: (props.linecolor || customApp('line'))
                },
                '&.Mui-focused': {
                    borderBottomColor: (props.linecolor || customApp('line')), //customApp
                    '&::before': {
                        borderBottomColor: (props.linecolor || customApp('line'))
                    },
                    '&::after': {
                        borderBottomColor: (props.linecolor || customApp('line'))
                    },
                },
            },
        },
    }
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    // if(props.helperText)
    return (<TF {...props} ref={ref} className={classes.root} />)
})

export default TextField
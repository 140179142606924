import React, { useEffect, useState, useRef } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItemV2'

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"
import HtmlTooltip from 'components/Tooltip/html'


//FUNCTIONS
import { totalTime, translate } from "functions/"

//COMPONENTS
import Icon from 'components/Icon'

const refArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


const StatisticsModal = (props) => {

    const mounted = useRef({ current: true })
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const getHeader = Array.from(
        new Set(props.data.flatMap(item => Object.keys(item).filter(key => {
            return refArray.includes(key) || key.length < 3
        }))
        ))

    getHeader?.[0]?.length < 3 ? getHeader.sort((a, b) => a - b) : getHeader.sort((a, b) => refArray.indexOf(a) - refArray.indexOf(b));

    const { timeline } = props
    const { db } = reduxStore.getState()
    const [renderedData, setRenderedData] = useState(false);
    const [loaded, setLoaded] = useState(false)
    const cardsList = Object.keys(props.planCards)
    const { cards } = db

    const getChildrens = (id) => {
        let result = [id]
        Object.keys(cards).filter(c => cards[c]._parent === id).map(async (c, ci) => {
            let child = getChildrens(c)
            if (child) {
                result = [...new Set([...result, ...child])]
            }
        })
        return result
    }

    const getHoursByTag = (tag, period) => {
        return new Promise(async (resolve) => {
            if (!period) return resolve(``)
            let calcTime = 0
            cardsList.filter(card =>
                !db.cards[card].deleted
                && db.cards[card].status === "completed"
                && db.cards[card]._tags
                && db.cards[card]._tags.filter(a => a.value === tag).length > 0
            ).forEach(async Xcard => {
                let subCards = getChildrens(Xcard)
                if (subCards.length > 0)
                    subCards.filter(card =>
                        !db.cards[card].deleted
                        && db.cards[card].status === "completed"
                        && timeline[card]
                    ).forEach(card => {
                        let cardTimer = 0
                        Object.keys(timeline[card])
                            .filter(a =>
                                a
                                && timeline[card][a]
                                && !timeline[card][a].deleted
                                && timeline[card][a].type === "timer"
                                && (
                                    !period
                                    || (
                                        period
                                        && parseInt(timeline[card][a].ini) >= parseInt(period.start)
                                        && parseInt(timeline[card][a].fin) <= parseInt(period.end)
                                    )
                                )
                            ).forEach(tm => {
                                let timerTotal = timeline[card][tm].fin - timeline[card][tm].ini
                                if (timeline[card][tm]._users && timeline[card][tm]._users.filter(a => a._id).length > 0)
                                    timerTotal = timerTotal * timeline[card][tm]._users.filter(a => a._id).length
                                cardTimer = cardTimer + timerTotal
                            })
                        calcTime = calcTime + cardTimer
                    })
            })

            if (calcTime) {
                let timeText = totalTime(calcTime)
                resolve(`${timeText.hours ? `${timeText.hours}H ` : ``}${timeText.minutes ? `${timeText.minutes}m` : ``}`)
            }
            resolve(``);
        });
    }

    const tableStyle = {
        borderCollapse: 'collapse',
        fontSize: '0.8em', // Tamanho menor da fonte para as células
        fontFamily: 'sans-serif',
        width: '100%',
        maxHeight: '44px',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    };

    const cellStyle = {
        border: '1px solid #dddddd',
        padding: '5px 10px',
        color: 'black',
    };

    const loadTime = async (props, getHeader) => {
        const result = await Promise.all(props.data.map(async (item, index) => {
            return new Promise(async (resolve) => {
                const promisesHours = getHeader.map(async (cellRef, monthIndex) => {
                    return new Promise(async (resolveMap) => {
                        await new Promise(resolveY => setTimeout(resolveY, 10));
                        const hourObj = {
                            hour: await getHoursByTag(item.tag, item[`Period__${cellRef.replace("$__", "")}`] || null, item),
                            cellRef: cellRef
                        }
                        resolveMap(hourObj)
                    })
                })
                const Hours = await Promise.all(promisesHours)
                resolve(
                    <tr key={index}>
                        <td style={{ ...cellStyle, display: 'flex' }}>
                            <div style={{ width: '15px', height: '15px', backgroundColor: item.color, alignSelf: 'center', marginRight: '5px', borderRadius: '50%' }}></div>
                            {item.label}
                        </td>
                        {Hours.map((hourItem, monthIndex) => {
                            return (
                                <td style={{ ...cellStyle, position: 'relative' }} key={monthIndex}>
                                    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                                        {(item[hourItem.cellRef] || 0) + (hourItem.hour ? ' /' : '')}
                                        <div style={{ display: 'flex', fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                            {hourItem.hour && <Icon>history</Icon>}
                                            {hourItem.hour}
                                        </div>
                                        <HtmlTooltip
                                            title={(
                                                <React.Fragment>
                                                    <div style={{ margin: '10px', fontSize: '16px', justifyContent: 'center' }}>
                                                        {typeof item === 'object' && Object.keys(item).map((key, index) => {
                                                            if ([
                                                                'inProgress',
                                                                'completed',
                                                                'notStarted',
                                                                'paused',
                                                                'awaitAction'
                                                            ].includes(key)) {
                                                                let color = ''
                                                                switch (key) {
                                                                    case 'inProgress': color = "#011E41"; break;
                                                                    case 'completed': color = "#5A7F71"; break;
                                                                    case 'notStarted': color = "#929497"; break;
                                                                    case 'paused': color = "#EBA900"; break;
                                                                    case 'awaitAction': color = "pink"; break;
                                                                    default: color = 'white'
                                                                }
                                                                return (
                                                                    <div key={index} style={{ marginBottom: '8px' }}>
                                                                        {Object.keys(item[key])
                                                                            .filter((nestedKey, index) => nestedKey === hourItem.cellRef)
                                                                            .map((nestedKey, index) => {
                                                                                const value = item[key][nestedKey];
                                                                                return (
                                                                                    <div key={index} style={{ display: 'flex', marginLeft: '-10px', marginTop: '5px' }}>
                                                                                        <div style={{ borderRadius: '50%', backgroundColor: color, height: '20px', width: '20px', alignSelf: 'center', marginRight: '10px', marginTop: '-3.5px' }}> </div>
                                                                                        {translate(`$__${key}`)}: {value}
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                </React.Fragment>
                                            )} >
                                            <span style={{ width: '100%', height: "100%", margin: '0px', padding: '0px', position: 'absolute', left: '0px', top: '0px' }} />
                                        </HtmlTooltip >
                                    </div>
                                </td>
                            )
                        })}
                    </tr>
                )
            })
        }))

        if (result.length > 0 && mounted.current) {
            setRenderedData(result)
            setLoaded(true)
        }
    }

    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        };
    }, [])

    useEffect(() => {
        if (props.data.length > 0 && mounted.current)
            loadTime(props, getHeader)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);


    return (
        <div style={{ width: '100%', height: '100%' }} >
            <div>
                <GridContainer >
                    <GridItem className="grid" style={{ padding: '0px' }} xs={12}>
                        {getHeader.length > 0 && <table style={tableStyle}>
                            <thead>
                                <tr>
                                    <th tyle={cellStyle}></th>
                                    {getHeader
                                        .map((item, index) => {
                                            return <th style={cellStyle} key={index}>{item.length < 3 ? translate("$__week") + " " + (index + 1) : translate(`$__${item}`)}</th>
                                        })}
                                </tr>
                            </thead>
                            <tbody>
                                {!loaded && props.data.map((item, index) => (
                                    <tr key={index}>
                                        <td style={{ ...cellStyle, display: 'flex' }}>
                                            <div style={{ width: '15px', height: '15px', backgroundColor: item.color, alignSelf: 'center', marginRight: '5px', borderRadius: '50%' }}></div>
                                            {item.label}
                                        </td>
                                        {getHeader.map((cellRef, monthIndex) => {
                                            return <td style={{ ...cellStyle, position: 'relative' }} key={monthIndex}>
                                                {item?.[cellRef] || 0}
                                                <HtmlTooltip
                                                    title={(
                                                        <React.Fragment>
                                                            <div style={{ width: '200px', marginBottom: '20px', fontSize: '16px', marginTop: '20px' }}>

                                                            </div>
                                                        </React.Fragment>
                                                    )} >
                                                    <span style={{ width: '100%', height: "100%", margin: '0px', padding: '0px', position: 'absolute', left: '0px', top: '0px' }} />
                                                </HtmlTooltip>
                                            </td>
                                        })
                                        }
                                    </tr>
                                ))}
                            </tbody>
                            <tbody>
                                {loaded && renderedData}
                            </tbody>
                        </table>}
                    </GridItem>
                </GridContainer >
            </div>
        </div >
    )
}

const mapStateToProps = (store) => ({
    timeline: store.timeline
}
)

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(StatisticsModal));



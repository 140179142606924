//CORE
import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Card from "components/Card/";

function Component(props) {
    const { db } = reduxStore.getState()
    const { socket } = reduxStore.getState().functions

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let tasksCards = Object.keys(reduxStore.getState().db.cards)
        .filter(fil => {
            if (
                !reduxStore.getState().db.cards[fil].deleted
                && !reduxStore.getState().db.cards[fil].checklist
                && reduxStore.getState().db.cards[fil]._recurrenceId
                && reduxStore.getState().db.cards[fil]._parent === props.nodeId
            )
                return true
            return false
        }
        )
        .map(card => card)

    const onDragEnd = async result => {
        let cardsOn = Object.keys(reduxStore.getState().db.cards)
            .filter(fil => {
                if (reduxStore.getState().db.cards[fil] && reduxStore.getState().db.cards[fil].checklist && reduxStore.getState().db.cards[fil]._parent === props.nodeId)
                    return true
                return false
            }
            )
            .sort((a, b) => {
                if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low)) {
                    return -1
                } else if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) > parseInt(db.cards[b].order.low)) {
                    return 1
                } else {
                    return 0
                }
            })
            .map(card => card)

        const { destination, source, draggableId } = result

        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const itemOrderIds = cardsOn
        itemOrderIds.splice(source.index, 1)
        itemOrderIds.splice(destination.index, 0, draggableId)

        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "reorder"
        }, {
            cards: itemOrderIds
        })
        let cards = {}

        itemOrderIds.map((a, i) => {
            cards = {
                ...cards,
                [a]: db.cards[a]
            }
            cards = {
                ...cards,
                [a]: {
                    ...cards[a],
                    order: {
                        low: `${i + 1}`
                    }
                }
            }
            return true
        })
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...cards
            }
        });
        return

    }

    return (
        <div style={{ width: '100%' }}>
            <DragDropContext
                onDragEnd={onDragEnd}
            >
                <Droppable
                    droppableId={`Drop_tasks`} //ID OF BOARD
                    type="column"
                    direction={"vertical"}
                >
                    {(provided, snapshot) => (
                        <Container
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                        >
                            {tasksCards
                                .sort((a, b) => {
                                    if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low)) {
                                        return -1
                                    } else if (db.cards[a].order && db.cards[b].order && String(db.cards[a].order.low) && String(db.cards[b].order.low) && parseInt(db.cards[a].order.low) > parseInt(db.cards[b].order.low)) {
                                        return 1
                                    } else {
                                        return 0
                                    }
                                })
                                .map((card, index) => (
                                    <Draggable
                                        draggableId={card}
                                        index={index}
                                        key={card}
                                    >
                                        {(provided, snapshot) => (
                                            <Card
                                                provided={provided}
                                                snapshot={snapshot}
                                                cardId={card}
                                                db={'cards'}
                                                onlyOption
                                                showCompleteOption
                                                directDeletion
                                                editable
                                                noShowRecurrenceCard
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
        </div >
    )
}

const Container = styled.div`
            background-color:none;
            `;

const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

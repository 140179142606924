import React from "react"
import moment from "moment-timezone"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"

// @MATERIAL
import { withStyles } from "@material-ui/core/styles"

//DEPENDENCIES
import Resume from "./Resume"
import Accounts from "./Accounts"
import Languages from "./Languages"
import DataPicker from "components/DataPicker"


//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/dashboard"


function Dashboard() {
  const { session } = reduxStore.getState()
  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  const [sd, setSD] = React.useState(null)
  const [ed, setED] = React.useState(null)

  if (!session._access.master)
    return (<div>ACCESS DENIED</div>)

  if (!window.location.hash.split('/')[0].replace('#', '')) {
    // history.push('masterAdmin#tab/languages')
  }

  return (
    <div>
      {!hashs[0] &&
        <Resume />
      }
      {(hashs[0] === 'tab' && hashs[1] === 'accounts') &&
        <Accounts />
      }
      {(hashs[0] === 'tab' && hashs[1] === 'languages') &&
        <Languages />
      }

      {(hashs[0] === 'tab' && hashs[1] === 'tests') &&
        <div style={{ width: "300px" }}>
          <DataPicker
            type="datetime"
            onChangeStartDate={(d) => {
              console.log(moment(d).format("YYYY-MM-DD 23:59:59").unix('X'))
              setSD(moment(d).unix('X'))
            }}
            onChangeEndDate={(d) => {
              setED(moment(d).unix('X'))
            }}
            range
            // horizontal
            startDate={sd ? new Date(sd * 1000) : null}
            endDate={ed ? new Date(ed * 1000) : null}
            onClear={() => {
              setSD(null)
              setED(null)
            }}
          />
        </div>
      }
    </div >
  )
}

//EXPORT
const mapStateToProps = (store) => ({
  history: store.db.history
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard))
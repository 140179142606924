import axios from "axios"

axios.defaults.headers.post["Content-Type"] = "application/json; charset=UTF-8"

/**
 * Create base url for requisition ms
 */
const apims = axios.create({
  baseURL: process.env.REACT_APP_MS
});

axios.defaults.withCredentials = true;
/**
 * intecept verbs http to verify token
 */
apims.interceptors.request.use(async config => {
  config.headers["Content-Type"] = `${config.headers["Content-Type"] ? `${config.headers["Content-Type"]}` : `application/json`}; charset=UTF-8;`
  const token = localStorage.getItem("TOKEN") || ""
  if (String(token) !== "null" && String(token) !== "" && String(token) !== "undefined") {
    config.headers.Authorization = `Bearer ${token}`
    config.headers.Accept = "application/json;charset=UTF-8;application/x-www-form-urlencoded"
  }
  return config
});

apims.interceptors.response.use(
  async data => {
    return Promise.resolve(data)
  }
  ,
  error => {
    const response = Promise.reject(error.response)
    console.log(error.response)
    if (error && error.response && error.response.status && error.response.status === 401 && window.location.pathname !== '/login' && window.location.pathname !== '/register' && window.location.pathname !== '/activate') {
      console.log(error)
    }
    return response
  }
);

/**
 * Export ms
 */
export default apims;
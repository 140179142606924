//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Icon from "components/Icon"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"
import Backdrop from "@material-ui/core/Backdrop"

//FUNCTIONS
import { translate, customApp } from "functions/"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/LightBox.js"

function Component(props) {
    const { classes } = props

    if (!reduxStore.getState().lightBox.miro && !reduxStore.getState().lightBox.miroBoard)
        return <></>

    if (!reduxStore.getState().lightBox.miro && !reduxStore.getState().lightBox.miroBoard)
        return null

    return (
        <Backdrop className={classes.backdrop} open={reduxStore.getState().lightBox.miro}>
            <div className={classes.header}>
                <div className={classes.btClose}>
                    <Icon title={translate("$__close", "*")} color={customApp("light")} onClick={() => {
                        props.reduxFunction("ASYNC", "LIGHTBOX_CLOSE")
                    }}>close</Icon>
                </div>
            </div>
            <div className={classes.content} style={{ zIndex: 1000 }}>
                <iframe title="outModule"
                    style={{ position: "absolute", width: "100%", height: "100%" }}
                    src={`https://miro.com/app/live-embed/${reduxStore.getState().lightBox.miroBoard}=/?embedAutoplay=true&moveToViewport=-23165,-5837,13803,7546`}
                ></iframe>
            </div>
        </Backdrop >
    )
}

//REACT
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(withStyles(styles)(Component));
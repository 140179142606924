const initialState = {
    timelines: {},
    unreadeds: {},
    drafts: {}
}

const sideModule = (state = initialState, data) => {
    if (data.action === 'SET_CHATS' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_CHATS') {
        return {
            ...initialState
        }
    }
    else {
        return state
    }
};

export default sideModule;
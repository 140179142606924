//REACT
import React from "react";
import PropTypes from "prop-types";
//import api from "api/";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store";
import history from "store/history"

//COMPONENTS
import HtmlTooltip from "components/Tooltip/html"
import Icon from "components/Icon"

//@MATERIAL
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import withStyles from "@material-ui/core/styles/withStyles";

//STYLES
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/SideBarMenuUser";

//FUNCTIONS
import { customApp, translate } from "functions/";

function HeaderLinks(props) {
    const { db, session } = reduxStore.getState();
    const { socket } = reduxStore.getState().functions
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = i => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const logout = async () => {
        socket.emit("data", {
            module: "user",
            method: "post",
            action: "logout"
        }, {},
            () => {

            })

        // handleToggle()
        localStorage.clear()
        setTimeout(() => {
            window.location.href = '/login'
        }, 500)
    };

    const { classes } = props

    const user = {
        name: session?.name || null,
        _id: session?._id || null,
        image: session?.image || null
    }

    if (!user || !user.name)
        return null

    return (
        <div className={classes.component}>
            <div className={classes.selectedName} onClick={handleToggle}>
                <div
                    ref={anchorRef}
                    className={classes.selectedNameText}
                    style={{
                        color: customApp('light') || '#FFFFFF',
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <HtmlTooltip title={
                        <React.Fragment>
                            {user.name ? user.name : user.name}
                        </React.Fragment>
                    } >
                        <React.Fragment>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '33px',
                                    height: '33px',
                                    borderRadius: '50%',
                                    border: `solid 1px ${customApp('menu')}`,
                                }}
                            >
                                {!user.image ?
                                    <Icon icon="user" color={customApp("menu")} />
                                    :
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '90%',
                                            height: '90%',
                                            backgroundImage: `url("${user.image}")`,
                                            backgroundColro: "lightgray",
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundPosition: 'center center',
                                            borderRadius: '50%',
                                        }}
                                    ></div>
                                }
                            </div>
                        </React.Fragment>
                    </HtmlTooltip>
                </div>
            </div>
            <div style={{ zIndex: 10 }}>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                                        <MenuItem onClick={() => {
                                            props.reduxFunction("ASYNC", "SET_MODULE", {
                                                ...db.sideModule,
                                                id: session._id,
                                                activeModule: 'userEdit',
                                                db: 'users',
                                                data: {
                                                    type: 'user',
                                                    ...db.users[session._id],
                                                }
                                            })
                                        }}>{translate("$__myProfile", 1)}</MenuItem>
                                        <MenuItem onClick={() => {
                                            history.push("profile")
                                        }}>{translate("$__profileUser", 1)}</MenuItem>
                                        <MenuItem onClick={() => logout()}>{translate("$__logout", 1)}</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );

}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(withStyles(headerLinksStyle)(HeaderLinks));
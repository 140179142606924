import logoMachen from "assets/img/logoMachen.svg"
import login from "assets/img/fundoLogin.jpg"

const initialState = {
    custom: {
        ColumnTitleColor: `linear-gradient(90deg, rgba(67,18,102,1) 0%, rgba(90,35,130,1) 100%)`,
        customLogo: logoMachen,
        loginBackground: login,
        linearProgress: `linear-gradient(90deg, rgba(90,35,130,0) 0%, rgba(67,18,102,1) 30%, rgba(67,18,102,1) 70%, rgba(90,35,130,0) 100%)`,
        AvatarBackground: "radial-gradient(rgba(114,47,162,1) 0%, rgba(67,18,102,1) 100%)",
        AvatarIconColor: "#FFFFFF",
        active: 'rgb(192,178,131)',
        color: 'rgb(192,178,131)',
        colorText: 'rgb(192,178,131)',
        dark: 'rgb(8,27,49)',
        darkText: 'rgb(8,27,49)',
        disabled: 'rgb(200,200,200)',
        fieldRadius: '3px',
        fieldVariant: 'standard', //filled, outlined, standard
        light: '#FFFFFF',
        lightText: '#FFFFFF',
        line: 'rgb(192,178,131)',
        locale: 'pt-BR',
        subMenuColor: 'linear-gradient(90deg, rgba(233,233,233,1) 0%, rgba(237,237,237,1) 100%)',
        subMenuTextColor: 'rgb(125,125,125)',
        subMenuTextColorActive: 'rgb(192,178,131)',
        medium: 'rgb(125,125,125)',
        mediumText: 'rgb(150,150,150)',
        menu: 'rgb(67,18,102)',
        menuColor: `linear-gradient(90deg, rgba(67,18,102,1) 0%, rgba(90,35,130,1) 100%)`,
        menuButtonColor: 'rgb(192,178,131)',
        timelineMyMessage: '#ebe3f1'
    },
    cards: {
        load: [],
        adminViewLoader: false,
        adminView: false,
        loaded: false,
        expanded: false,
        hide: {
            image: false,
            description: false,
            dates: false,
            kpi: false,
            kpiChart: false,
            kpiChange: false,
        }
    },
    timeline: {
        load: []
    },
    sideModuleWindow: false,
    dashboard: {
        period: '$__last7Days'
    },
    period: '$__last7Days',
    users: {},
    showDeleted: false,
    showPrivate: false,
    disabledTour: false,
};

const session = (state = initialState, data) => {
    if (data.action === 'SET_SESSION' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_SESSION') {
        return {
            ...state,
        }

    } else {
        return state
    }
};

export default session;
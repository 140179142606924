import React from "react";
import moment from 'moment-timezone';

//COMPONENTS
import GroupedButton from "components/Buttons/grouped";
import Icon from "components/Icon";
import Switch from "components/Switch";
import TextField from "components/TextField";

//@MATERIAL
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";

//FUNCTIONS
import { translate, customApp } from "functions/";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        width: "100%",
    },
    options: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "solid 1px rgba(0,0,0,0.05)",
        marginTop: "7px"
    },
    custom: {
        position: "relative",
        width: "100%",
        padding: "7px",
        backgroundColor: 'rgba(0,0,0,0.05)',
        marginTop: '7px'
    },

    margin: {
        height: theme.spacing(3),
    },
}));

const clearRecurrenceData = {
    recurrence: "0",
    recurrenceRepeatDays: null,
    recurrenceEndDate: null,
    recurrenceLimit: null,
    recurrenceMyDay: null,
    recurrenceOnlyCompleted: null,
    recurrenceDuplicateCard: null,
}

function Comp(props) {
    const [data, setCompData] = React.useState({
        recurrence: "0"
    })
    const classes = useStyles();

    React.useEffect(() => {
        setCompData({
            recurrence: props.data && props.data.recurrence ? props.data.recurrence : "0",
            recurrenceRepeatDays: props.data && props.data.recurrenceRepeatDays ? props.data.recurrenceRepeatDays : null,
            recurrenceEndDate: props.data && props.data.recurrenceEndDate ? props.data.recurrenceEndDate : null,
            recurrenceLimit: props.data && props.data.recurrenceLimit ? props.data.recurrenceLimit : null,
            recurrenceMyDay: props.data && props.data.recurrenceMyDay ? props.data.recurrenceMyDay : null,
            recurrenceOnlyCompleted: props.data && props.data.recurrenceOnlyCompleted ? props.data.recurrenceOnlyCompleted : null,
            recurrenceDuplicateCard: props.data && props.data.recurrenceDuplicateCard ? props.data.recurrenceDuplicateCard : null,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {

    }, [props.data.startDate])

    const calcNextRecurrence = (values) => {
        let days = values.recurrence
        if (values.recurrence === 'custom')
            days = values.recurrenceRepeatDays
        let result = values
        let newDate = moment(props.data.startDate * 1000).tz('America/Sao_Paulo').add(days, 'd').format('YYYY-MM-DD')
        if (moment(props.data.startDate * 1000).tz('America/Sao_Paulo').add(days, 'd').unix('X') < moment().tz('America/Sao_Paulo').unix('X'))
            newDate = moment().tz('America/Sao_Paulo').add(days, 'd').format('YYYY-MM-DD')
        result = {
            ...values,
            recurrenceNextDate: newDate,
        }
        return result
    }

    const setData = (values) => {
        let result = values
        if (!isNaN(values.recurrence) || values.recurrence === 'custom') {
            result = calcNextRecurrence(values)
        }
        if (values.recurrence && parseInt(values.recurrence) > 0 && parseInt(values.recurrence) < 31)
            result = {
                ...result,
                recurrenceNextDate: moment().tz('America/Sao_Paulo').add(parseInt(values.recurrence), 'days').format('YYYY-MM-DD')
            }
        if (values.recurrence === 'everyFriday')
            result = {
                ...result,
                recurrenceNextDate: moment().tz('America/Sao_Paulo').add(1, 'weeks').isoWeekday(5).format('YYYY-MM-DD')
            }

        if (values.recurrence === 'everyMonday')
            result = {
                ...result,
                recurrenceNextDate: moment().tz('America/Sao_Paulo').add(1, 'weeks').isoWeekday(1).format('YYYY-MM-DD')
            }

        if (values.recurrence === 'everyFirstMonthDay')
            result = {
                ...result,
                recurrenceNextDate: moment().tz('America/Sao_Paulo').add(1, 'month').format('YYYY-MM-01')
            }

        setCompData(result)
        if (props.onChange)
            props.onChange(result)
    }

    return (
        <div id="RecurrencySettings" className={classes.root}>
            <div className={classes.options}>
                {translate('$__recurrence')}
                <GroupedButton
                    options={[
                        {
                            value: '0',
                            label: translate('$__nonrecurrence'),
                        },
                        {
                            value: '1',
                            label: translate('$__everyDay'),
                        },
                        {
                            value: '7',
                            label: translate('$__weekly'),
                        },
                        {
                            value: '15',
                            label: translate('$__every15days'),
                        },
                        {
                            value: '30',
                            label: translate('$__every30days'),
                        },
                        {
                            value: 'everyFirstMonthDay',
                            label: translate('$__everyFirstMonthDay'),
                        },
                        {
                            value: 'everyMonday',
                            label: translate('$__everyMonday'),
                        },
                        {
                            value: 'everyFriday',
                            label: translate('$__everyFriday'),
                        },
                        {
                            value: 'custom',
                            label: translate('$__custom'),
                        }
                    ]}
                    value={data && data.recurrence ? data.recurrence : "0"}
                    onChange={(v) => {
                        if (v === "0") {
                            setData({
                                ...clearRecurrenceData,
                                recurrence: null,
                                recurrenceRepeatDays: null
                            })
                        } else if (v !== 'custom') {
                            setData({
                                recurrence: `${v}`,
                                recurrenceRepeatDays: null
                            })
                        } else {
                            setData({
                                ...data,
                                recurrence: `${v}`,
                                recurrenceNextDate: null,
                                recurrenceRepeatDays: 1
                            })
                        }
                    }}
                />
            </div>
            {(data && data.recurrence && String(data.recurrence) !== "0") &&
                <div className={classes.custom}>
                    {translate("$__preffers", 1)}
                    {data && data.recurrence === 'custom' &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {translate('$__repeatEvery', 1)}
                            <TextField
                                style={{ width: "100px" }}
                                variant={customApp('standard')}
                                name={'recurrenceRepeatDays'}
                                type="number"
                                min="1"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    min: 1,
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon icon='replay_10' />
                                            {translate('$__days').toLowerCase()}
                                        </InputAdornment>
                                    ),
                                    onChange: (d) => {
                                        if (parseInt(d.target.value) > 0)
                                            setData({
                                                ...data,
                                                recurrenceRepeatDays: d.target.value
                                            })
                                    },
                                }}
                                defaultValue={data.recurrenceRepeatDays ? data.recurrenceRepeatDays : 1}
                            />
                        </div>
                    }
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {translate('$__repeatLimit', 1)}
                        <TextField
                            style={{ width: "152px" }}
                            variant={customApp('standard')}
                            name={'repeatLimit'}
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Icon icon='replay_10' />
                                        {translate('$__times').toLowerCase()}
                                    </InputAdornment>
                                ),
                                onChange: (d) => {
                                    setData({
                                        ...data,
                                        recurrenceLimit: d.target.value
                                    })
                                },
                            }}
                            defaultValue={data.recurrenceLimit}
                            helperText={translate('$__0toUnlimited')}
                        />
                    </div> */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {translate('$__limitDate', 1)}
                        <TextField
                            style={{ width: "200px" }}
                            variant={customApp('standard')}
                            name="endDate"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            type='date'
                            InputProps={{
                                endAdornment: data.recurrenceEndDate ? (
                                    <InputAdornment position="end">
                                        <Icon icon='clear'
                                            onClick={() => {
                                                setData({
                                                    ...data,
                                                    recurrenceEndDate: null
                                                })
                                            }}
                                        />
                                    </InputAdornment>
                                ) : null,
                                autoComplete: 'new-password',
                                onBlur: (d) => {
                                    setData({
                                        ...data,
                                        recurrenceEndDate: d.target.value ? moment(d.target.value).tz('America/Sao_Paulo').format('YYYY-MM-DD') : null
                                    }
                                    )
                                },
                                onChange: (d) => {
                                    setData({
                                        ...data,
                                        recurrenceEndDate: d.target.value ? moment(d.target.value).tz('America/Sao_Paulo').format('YYYY-MM-DD') : null,
                                    })
                                },
                            }}
                            defaultValue={props.data.recurrenceEndDate ? props.data.recurrenceEndDate : null}
                        />
                    </div>

                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {translate('$__addToMyDay', 1)}
                        <Switch
                            checked={data.recurrenceMyDay ? true : false}
                            onChange={(d) => {
                                setData({
                                    ...data,
                                    recurrenceMyDay: data.recurrenceMyDay ? false : true
                                })
                            }}
                            name="recurrenceMyDay"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div> */}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {translate('$__recurrenceDuplicateCard', 1)}
                        <Switch
                            checked={data.recurrenceDuplicateCard ? true : false}
                            onChange={(d) => {
                                setData({
                                    ...data,
                                    recurrenceDuplicateCard: data.recurrenceDuplicateCard ? false : true,
                                    ...data.recurrenceDuplicateCard ? { recurrenceCopyCatenation: false } : {}
                                })
                            }}
                            name="duplicate"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div>
                    {data.recurrenceDuplicateCard &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            {translate('$__copyCatenation', 1)}
                            <Switch
                                checked={data.recurrenceCopyCatenation ? true : false}
                                onChange={(d) => {
                                    setData({
                                        ...data,
                                        recurrenceCopyCatenation: data.recurrenceCopyCatenation ? false : true
                                    })
                                }}
                                name="recurrenceCopyCatenation"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </div>
                    }
                    {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {translate('$__createNewOnlyWhenPreviousIsCompleted', 1)}
                        <Switch
                            checked={data.recurrenceOnlyCompleted ? true : false}
                            onChange={(d) => {
                                setData({
                                    ...data,
                                    recurrenceOnlyCompleted: data.recurrenceOnlyCompleted ? false : true
                                })
                            }}
                            name="recurrenceOnlyCompleted"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div> */}
                </div>
            }
        </div >
    )
}

const mapStateToProps = (store, ownProps) => ({ store, ownProps });

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Comp);

//REDUX
import reduxStore from "store/"

//FUNCTIONS
import api from "api/"

export const tagUpdate = (props, data) => {
    let newTagResult = reduxStore.getState().db.tag
    if (newTagResult.filter(t => t.value === data.value).length > 0) {
        newTagResult.forEach((tag, i) => {
            if (tag.value === data.value) {
                newTagResult[i] = {
                    ...newTagResult[i],
                    ...data
                }
            }
        })
    } else {
        newTagResult.push(data)
    }
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        tag: [
            ...newTagResult
        ]
    })
}

export const tagDelete = async (props, data) => {
    const response = api.delete(`/tag/unique/${data._id}`, {
        ...data
    })
    if (response)
        localTagDelete(props, data)
}

export const localTagDelete = (props, data) => {
    let newTags = []
    reduxStore.getState().db.tag.forEach(t => {
        if (t.value !== data._id)
            newTags.push(t)
    })
    props.reduxFunction("ASYNC", "SET_DB", {
        ...reduxStore.getState().db,
        tag: newTags
    });
}

export const tagUnlink = async (props, data) => {
    const response = api.put(`/tag/unlink/`, {
        ...data
    })
    if (response)
        localTagUnlink(props, data)
}

export const tagLink = async (props, data) => {
    const { session } = reduxStore.getState()

    if (data.target === data._id)
        data.target = session.account

    const response = await api.put(`/tag/link/`, {
        ...data
    })
    if (response)
        return true
}


export const localTagUnlink = (props, data) => {
    let newTags = []
    let { session, db } = reduxStore.getState()
    db.tag.forEach(t => {
        let newData = t
        if (t.value === data._id)
            newData = {
                ...newData,
                count: 0
            }
        newTags.push(newData)
    })
    let cards = {
        ...db.cards
    }
    Object.keys(cards).filter(card =>
        cards[card]._users
        && cards[card]._users[session._id]
        && cards[card]._tags
        && cards[card]._tags.filter(tg => tg.value === data._id).length > 0
    ).forEach(card => {
        let newTags = []
        cards[card]._tags.forEach(tg => {
            if (tg.value !== data._id)
                newTags.push(tg)
        })
        cards[card]._tags = newTags
    })
    props.reduxFunction("ASYNC", "SET_DB", {
        ...db,
        tag: newTags,
        cards: cards
    });
}
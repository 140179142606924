import React from "react"
import Chartist from "chartist"
import moment from "moment-timezone"

//COMPONENTS
import Icon from "components/Icon"
import KpiDescription from "components/KPI/dataSave"
import SelectUsers from "components/User/select"
import TextField from "components/TextField"
import CustomButton from "components/Buttons/custom"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"


//FUNCTIONS
import { translate, customApp, appAlert, formatNumber } from "functions/"
import { kpiTimerStart, kpiTimerStop } from "functions/cards"


//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/KPI_change.js"

import {
    model
} from "variables/charts"
import "assets/css/components/chart.css"
import ErrorBoundary from "components/ErrorBoundary"

require("chartist-plugin-tooltips")

const TimerComponent = (props) => {
    const { classes } = props
    const { db, session } = reduxStore.getState()
    const { cards } = db
    const [reqSave, setReqSave] = React.useState(false)
    const [started, setStart] = React.useState(false)
    const [timer, setTimer] = React.useState({})
    const { data } = props
    const [cardsWithKpi, setCardsWithKpi] = React.useState([])
    const [startDate, setStartDate] = React.useState(moment(new Date()).tz('America/Sao_Paulo').format('YYYY-MM-DDT06:00'))
    const [endDate, setEndDate] = React.useState(moment(new Date()).tz('America/Sao_Paulo').format('YYYY-MM-DDT07:00'))
    const [manualTime, setManualTime] = React.useState(false)
    const [manualMinuteEntry, setManualMinuteEntry] = React.useState(0)
    const [manualHourEntry, setManualHourEntry] = React.useState(1)

    const mounted = React.useRef(true)

    let timerFunction
    let kpi_ini = data.kpi_type === "time" && data.kpi_ini ? parseFloat(data.kpi_ini) : 0
    let kpi_fin = data.kpi_type === "time" && data.kpi_fin ? parseFloat(data.kpi_fin) : 0

    React.useEffect(() => {
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    React.useEffect(() => {
        if (timerFunction) clearTimeout(timerFunction)
        verifyTimer()
        childrenCards(props.data._id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [started])


    React.useEffect(() => {
        if (timerFunction) clearTimeout(timerFunction)
        verifyTimer()
        childrenCards(props.data._id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.card[props.data._id])])

    //Filter all Children Cards with KPI = kpi_useParent
    let kpiCards = [props.data._id]
    const childrenCards = (cardId, children) => {
        let chields = Object.keys(cards)
            .filter(a => cards[a].kpi_useParent && cards[a]._parent === cardId)
        if (chields.length > 0) {
            chields.forEach(a => {
                childrenCards(a, true)
                kpiCards.push(a)
                setCardsWithKpi(Array.from(new Set(kpiCards)))
            })
        } else if (!children) {
            setCardsWithKpi(kpiCards)
        }
        return chields
    }
    const verifyTimer = () => {
        // TIMER NÃO ESTÁ ATUALIZANDO QUANDO DA PLAY OU STOP DENTRO DO CARD
        let newData = { ...reduxStore.getState().db.cards[props.data._id] }
        if (newData && newData._kpiTimer && Object.keys(newData._kpiTimer).filter(a => newData._kpiTimer[a].userId === session._id).length > 0) {
            let timer = { ...newData._kpiTimer[Object.keys(newData._kpiTimer).filter(a => newData._kpiTimer[a].userId === session._id)[0]] }
            timer.diff = moment().tz('America/Sao_Paulo').unix('x') - timer.created_at
            if (timer && timer.updated_at)
                timer.diff = parseInt(timer.updated_at) - parseInt(timer.created_at)
            calcTimer(timer, timer.updated_at ? true : false)
        } else {
            setReqSave(false)
            setTimer({})
            setStart(false)
        }
        if (Object.keys(timer).length === 0 && props.manualAdd) {
            setTimer({
                created_at: moment(startDate).tz('America/Sao_Paulo').unix('X'),
                updated_at: moment(endDate).tz('America/Sao_Paulo').unix('X')
            })
            calcTimer({
                created_at: moment(startDate).tz('America/Sao_Paulo').unix('X'),
                updated_at: moment(endDate).tz('America/Sao_Paulo').unix('X')
            }, timer.updated_at ? true : false)
        }
    }

    const calcTimer = (d, kill, ignoreManual = false) => {

        let newData = { ...d }
        let iniTimer = false

        let diff


        if (newData.diff) {
            diff = parseInt(newData.diff)
        } else {
            diff = parseInt(d.updated_at - d.created_at)
            if (d.updated_at - d.created_at < 0)
                diff = null
        }
        if (!diff && !manualTime) {
            newData.diffText = translate("$__theEndMustBeGreaterThanTheBeginning")
        } else if (diff < 60) {
            newData.diffText = `${newData.diff} ${translate('$__seconds', 1)}`
            if (!ignoreManual)
                setManualHourEntry(0)
        } else if (diff >= 3600) {
            let hours = Math.trunc(diff / 3600)
            diff = diff - (hours * 3600)
            let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
            diff = diff - (minutes * 60)
            if (minutes < 60) {
            } else {

            }
            let seconds = diff > 0 ? diff : 0
            newData.diffText = `${hours} hora${hours > 1 ? 's' : ''} ${minutes} ${translate('$__minutes', 1)} ${seconds} ${translate('$__seconds', 1)}`
            if (!ignoreManual) {
                setManualHourEntry(hours)
                setManualMinuteEntry(minutes)
            }
        } else {
            let minutes = Math.trunc(diff / 60) > 0 ? Math.trunc(diff / 60) : 0
            diff = diff - (minutes * 60)
            let seconds = diff > 0 ? diff : 0
            newData.diffText = `${minutes} ${translate('$__minutes', 1)} ${seconds} ${translate('$__seconds', 1)}`
            if (!ignoreManual) {
                setManualHourEntry(0)
                setManualMinuteEntry(minutes)
            }
        }
        setTimer({ ...timer, ...newData })

        if (d.updated_at) {
            newData.diff = parseInt(d.updated_at) - parseInt(d.created_at)
            if (!reqSave) setReqSave(true)
        } else {
            newData.diff = moment().tz('America/Sao_Paulo').unix('x') - parseInt(d.created_at)
            if (!started) {
                setStart(true)
                iniTimer = true
            }
        }

        if (!kill && !d.updated_at && iniTimer) {
            timerFunction = setTimeout(() => { verifyTimer() }, 1000)
        }
    }

    if (!data)
        return (null)

    const startTimer = async () => {
        const response = await kpiTimerStart(props, data._id)
        if (response) {
            console.log(response)
            calcTimer(response)
            setStart(true)
        }
    }
    const stopTimer = async () => {
        setStart(false)
        const response = await kpiTimerStop(props, data._id)
        if (response) {
            setTimer({
                ...timer,
                ...response
            })
        }
    }

    let kpiChart = {
        ...model
    }

    let series = []
    let labels = []

    let kpi_flow = {}
    cardsWithKpi.forEach(a => {
        kpi_flow = {
            ...kpi_flow,
            ...db.cards[a]._timeline || {}
        }
    })


    kpi_flow = Object.keys(kpi_flow).filter(a => kpi_flow[a].type === 'kpi').map(a => kpi_flow[a])

    let kpi_total = data.kpi_type !== 'time' ? parseFloat(kpi_ini) : 0
    let percent100 = parseFloat(kpi_ini) - parseFloat(kpi_fin)
    if (percent100 < 0) percent100 = percent100 * -1

    if (kpi_flow && Object.keys(kpi_flow).length > 0) {
        Object.keys(kpi_flow).sort((a, b) => {
            if (kpi_flow[a].created_at < kpi_flow[b].created_at)
                return -1
            return 1
        }).forEach((a, i) => {
            kpi_total = parseFloat(kpi_total) + parseFloat(kpi_flow[a].value)
            series.push({
                meta: kpi_flow[a].value,
                total: kpi_total,
                value: parseFloat(kpi_ini) > parseFloat(kpi_fin) ? 100 - Math.floor((kpi_total * 100) / percent100) : Math.floor((kpi_total * 100) / percent100)
            })
            labels.push(i + 1)
            if (i + 1 === Object.keys(kpi_flow).length)
                kpiChart = {
                    ...kpiChart,
                    data: {
                        labels: labels,
                        series: [[...series]]
                    },
                    options: {
                        //fullWidth: true,
                        ...kpiChart.options,
                        low: 0,
                        high: 100,
                        plugins: [Chartist.plugins.tooltip({
                            tooltipFnc: function (meta, value) {
                                return (
                                    `${meta > 0 ? '+' : ''}` +
                                    formatNumber(meta, data.kpi_type === 'time' ? 'number' : data.kpi_type) + '<br>' + formatNumber(value, 'percent')
                                )
                            }
                        })],
                        axisX: {
                            showGrid: false,
                        },
                        axisY: {
                            showGrid: false,
                        },
                        onlyInteger: true,
                    },
                }
        })
    }



    let sumKpi = kpi_ini < kpi_fin ? kpi_fin - kpi_ini : kpi_ini - kpi_fin


    if (kpi_flow && Object.keys(kpi_flow).length > 0) {
        Object.keys(kpi_flow).forEach(a => {
            sumKpi = parseFloat(sumKpi) + parseFloat(kpi_flow[a].value)
        })
    }


    let percentKpi = Math.ceil(kpi_ini < kpi_fin ? kpi_fin - kpi_ini : kpi_ini - kpi_fin)
    percentKpi = ((Math.ceil(sumKpi) * 100) / percentKpi) - 100
    if (percentKpi < 0)
        percentKpi = percentKpi * -1


    if (!props.data)
        return (<div>NO DATA RECEIVED</div>)

    return (
        <div className={classes.root}>
            <fieldset>
                <legend
                    style={
                        {
                            color: customApp('color')
                        }
                    }>
                    {!props.manualAdd ? translate('$__timer', 1) : translate('$__timerLog', 1)}
                </legend>
                {props.manualAdd &&
                    <React.Fragment>
                        {!manualTime ?
                            <React.Fragment>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='schedule' />
                                    <TextField
                                        label={translate('$__initialDate', 1)}
                                        variant={customApp('fieldVariant', 1)}
                                        name="startDate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='datetime-local'
                                        inputProps={{
                                            max: endDate,
                                            autoComplete: 'start-date',
                                            onChange: (d) => {
                                                if (moment(d.target.value).isValid()) {
                                                    setStartDate(d.target.value)
                                                    calcTimer({
                                                        ...timer,
                                                        created_at: moment(d.target.value).tz('America/Sao_Paulo').unix('X')
                                                    }, true)
                                                }
                                            },
                                        }}
                                        value={startDate}
                                    />
                                </div>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='watch_later' />
                                    <TextField
                                        label={translate('$__finalDate', 1)}
                                        variant={customApp('fieldVariant', 1)}
                                        name="endDate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='datetime-local'
                                        inputProps={{
                                            min: startDate,
                                            autoComplete: 'new-password',
                                            onChange: (d) => {
                                                if (moment(d.target.value).isValid()) {
                                                    setEndDate(d.target.value)
                                                    calcTimer({
                                                        ...timer,
                                                        updated_at: moment(d.target.value).tz('America/Sao_Paulo').unix('X')
                                                    }, true)
                                                }
                                            },
                                        }}
                                        value={endDate}
                                    />
                                </div>

                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='schedule' />
                                    <TextField
                                        label={translate('$__initialDate', 1)}
                                        variant={customApp('fieldVariant', 1)}
                                        name="startDate"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type='date'
                                        inputProps={{
                                            max: moment().format("YYYY-MM-DD"),
                                            autoComplete: 'start-date',
                                            onChange: (d) => {
                                                if (moment(d.target.value).isValid()) {
                                                    setStartDate(d.target.value)
                                                }
                                                let diffCalc = timer.updated_at - timer.created_at
                                                calcTimer({
                                                    ...timer,
                                                    created_at: moment(`${d.target.value} 00:00:00`).unix('X'),
                                                    updated_at: moment(`${d.target.value} 00:00:00`).unix('X') + diffCalc
                                                }, true, true)
                                            },
                                        }}
                                        value={startDate}
                                    />
                                </div>
                            </React.Fragment>
                        }
                        <div className={classes.timeInfo}>
                            <Icon color='color' icon='more_time' />
                            <div style={{
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <TextField
                                    label={translate('$__hours', 1)}
                                    variant={customApp('fieldVariant', 1)}
                                    name="hours"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type='number'
                                    inputProps={{
                                        min: 0,
                                        autoComplete: 'hours',
                                        onChange: (d) => {
                                            setManualHourEntry(d.target.value)
                                            setManualTime(true)
                                            if (startDate.indexOf("T") > -1)
                                                setStartDate(startDate.substring(0, 10))

                                            calcTimer({
                                                ...timer,
                                                created_at: moment(`${startDate.substring(0, 10)} 00:00:00`).unix('X'),
                                                updated_at: moment(`${startDate.substring(0, 10)} 00:00:00`).unix('X') + (d.target.value * 60 * 60) + (manualMinuteEntry * 60)
                                            }, true, true)
                                        }
                                    }
                                    }
                                    value={manualHourEntry}
                                    style={{
                                        paddingLeft: 7,
                                        paddingRight: 7
                                    }}
                                />
                                <TextField
                                    label={translate('$__minutes', 1)}
                                    variant={customApp('fieldVariant', 1)}
                                    name="minutes"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type='number'
                                    inputProps={{
                                        min: 0,
                                        max: 59,
                                        autoComplete: 'new-password',
                                        onChange: (d) => {
                                            setManualMinuteEntry(d.target.value)
                                            setManualTime(true)
                                            if (startDate.indexOf("T") > -1)
                                                setStartDate(startDate.substring(0, 10))
                                            calcTimer({
                                                ...timer,
                                                created_at: moment(`${startDate.substring(0, 10)} 00:00:00`).unix('X'),
                                                updated_at: moment(`${startDate.substring(0, 10)} 00:00:00`).unix('X') + (manualHourEntry * 60 * 60) + (d.target.value * 60)
                                            }, true, true)
                                        }
                                    }
                                    }
                                    value={manualMinuteEntry}
                                    style={{
                                        paddingLeft: 7,
                                        paddingRight: 7
                                    }}
                                />
                                <CustomButton
                                    title={translate("$__reset", 1)}
                                    icon={"update_disabled"}
                                    color={customApp("menu")}
                                    onClick={() => {
                                        setManualTime(false)
                                        setStartDate(`${startDate} 00:00:00`)
                                    }
                                    }
                                    size={"17px"}
                                    style={{
                                        marginLeft: 3,
                                        marginRight: 3,
                                        backgroundColor: "rgba(0,0,0,0.05)",
                                        padding: 3
                                    }}
                                    transparent
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                display: "flex",
                                alignItems: "center"
                            }}
                        >


                        </div>
                        {timer && timer.diffText &&
                            <div className={classes.timeInfo}>
                                <Icon color='color' icon='hourglass_full' />
                                <div className='text'>{timer.diffText}</div>
                            </div>
                        }
                        {parseInt(db.cards[data._id]._users[session._id].access) >= 3 ?
                            <ErrorBoundary>
                                <SelectUsers
                                    includeMe
                                    data={db.cards[data._id]}
                                    avatarSize="33px"
                                    permission={props.permission}
                                    noLimit
                                    multiple
                                    onSelect={(e) => {
                                        calcTimer({
                                            ...timer,
                                            to: e
                                        }, true)
                                    }}
                                />
                            </ErrorBoundary>
                            : <React.Fragment></React.Fragment>
                        }
                    </React.Fragment>
                }

                {!reqSave && !props.manualAdd ?
                    <div className={classes.time}>
                        {!started ?
                            <React.Fragment>
                                {kpi_fin && kpi_total ?
                                    <div className={classes.timeInfo}>
                                        <Icon color={(kpi_fin * 60) < kpi_total ? 'red' : 'color'} icon='schedule' />
                                        <div className='text'
                                            style={{
                                                color: (kpi_fin * 60) < kpi_total ? 'red' : 'grey'
                                            }}
                                        >{translate('$__timeLeft', 1)}
                                            : {((kpi_fin * 60) - kpi_total) > -60 && ((kpi_fin * 60) - kpi_total) < 60 ? `${((kpi_fin * 60) - kpi_total)} ${translate('$__seconds', 1)} `
                                                : `${Math.trunc(((kpi_fin * 60) - kpi_total) / 60)} ${translate('$__minutes', 1)} ${((kpi_fin * 60) - kpi_total) - (Math.trunc(((kpi_fin * 60) - kpi_total) / 60) * 60)} ${translate('$__seconds', 1)} `}
                                        </div>
                                    </div>
                                    : <React.Fragment></React.Fragment>
                                }
                                <div className={classes.timeInfo}>
                                    <Icon color='color'
                                        onClick={() => {
                                            startTimer()
                                        }} title={translate("$__playRegisterLogWork")}>play_circle_outline</Icon>
                                    <span style={{ marginLeft: 7, color: customApp("color") }}>{translate("$__startStopWatch")}</span>
                                    {/* {kpi_fin && kpi_total ?
                                        <div className='text'>
                                            {kpi_total < 60 ? `${kpi_total} ${translate('$__seconds', 1)} `
                                                : `${Math.trunc(kpi_total / 60)} ${translate('$__minutes', 1)} ${kpi_total - (Math.trunc(kpi_total / 60) * 60)} ${translate('$__seconds', 1)} `}
                                        </div>
                                        : <React.Fragment></React.Fragment>
                                    } */}
                                </div>
                            </React.Fragment>
                            :
                            <div className={classes.timeInfo}>
                                <Icon color='color'
                                    onClick={() => {
                                        appAlert({
                                            message: translate('$__confirmStopTimer', 1),
                                            variant: 'warning',
                                            persist: false,
                                            horizontal: 'right',
                                            confirm: () => {
                                                stopTimer()
                                            }
                                        })
                                    }}>pause_circle_filled</Icon>
                                <div className='text'>{timer.diffText}</div>
                            </div>
                        }
                    </div>
                    :
                    <div>
                        {!props.manualAdd
                            && timer
                            && timer.created_at
                            && timer.updated_at
                            && timer.diffText
                            && moment(timer.created_at * 1000).isValid() &&
                            <React.Fragment>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='schedule' />
                                    <div className='text'>{moment(timer.created_at * 1000).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')}</div>
                                </div>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='watch_later' />
                                    <div className='text'>{moment(timer.updated_at * 1000).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm:ss')}</div>
                                </div>
                                <div className={classes.timeInfo}>
                                    <Icon color='color' icon='hourglass_full' />
                                    <div className='text'>{timer.diffText}</div>
                                </div>
                            </React.Fragment>
                        }
                        {timer.created_at < timer.updated_at ?
                            <ErrorBoundary>
                                <KpiDescription
                                    manualAdd={props.manualAdd ? true : false}
                                    data={data}
                                    timer={timer}
                                    manualEntry={manualTime}
                                    callBack={() => {
                                        setReqSave(false)
                                        if (props.callBack)
                                            props.callBack()
                                    }}
                                />
                            </ErrorBoundary>
                            : <React.Fragment></React.Fragment>
                        }
                    </div>
                }
            </fieldset>
        </div >
    )
}
//REACT
const mapStateToProps = (store, props) => ({
    card: store.db.cards
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(TimerComponent))
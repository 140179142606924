//CORE
import React from "react"
import Moment from "moment-timezone"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Reactions from "components/Reactions_V2"
import IconButton from "components/CustomButtons/IconButton"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/BrightAndConnected-card.js"
import { translate, customApp } from "functions"


function Component(props) {
    const { classes } = props
    const [data, setData] = React.useState({})

    let cardRef = React.useRef({})

    React.useEffect(() => {
        if (cardRef.current) {
            if (props.onResize) {

                const resizeObserver = new ResizeObserver(() => {
                    let newSize = cardRef.current.clientHeight
                    props.onResize(newSize)
                });
                resizeObserver.observe(cardRef.current);
                return () => resizeObserver.disconnect(); // clean up 
            }
        }
        return () => {

        }
        // eslint-disable-next-line
    }, [cardRef]);

    React.useEffect(() => {
        setData(props.data)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sendToInsight = () => {
        props.reduxFunction("ASYNC", "SET_MODULE", {
            ...reduxStore.getState().sideModule,
            id: "new",
            activeModule: "cardEdit",
            db: "cards",
            data: {
                _id: "new",
                type: "link",
                name: data.title,
                description: data.contentSnippet,
                pubDate: data.isoDate ? data.isoDate : data.pubDate,
                image: data.img,
                url: data.link,
            }
        })
    }
    return (
        <div
            ref={cardRef}
            className={classes.feed}
            style={{ cursor: "pointer" }}
            onClick={props.onClick ? () => props.onClick() : null}
        >
            {data && data.img &&
                <div
                    style={
                        {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }
                    }
                >
                    <img
                        alt="MACHEN_IMAGE"
                        src={data.img}
                        style={{ width: "100%" }}
                        onError={() => {
                            setData({
                                ...data,
                                img: null
                            })
                        }}
                    />
                </div>
            }
            <div className={classes.infos}>
                <div className={classes.linkTitle}
                    dangerouslySetInnerHTML={{ __html: data.title }}>
                </div>

                <div
                    dangerouslySetInnerHTML={{ __html: data.contentSnippet }}
                    className={classes.linkContent}
                />
                <div className={classes.feedFooter} onClick={(e) => { e.stopPropagation() }}>
                    <div className={classes.feedDate}>{Moment(data.isoDate ? data.isoDate : data.pubDate).format("DD/MM/YYYY HH:MM")}</div>
                    {data.title && data.title.indexOf('Violet ganham') > -1 && console.log(data)}
                    <Reactions data={data}>
                        <div style={{ marginLeft: "15px" }}>
                            <IconButton
                                icon={"settings_input_antenna"}
                                title={translate(`$__sendToRadar`, 1)}
                                iconColor={customApp("menu")}
                                size={"18px"}
                                onClick={() => {
                                    sendToInsight()
                                }}
                            />
                        </div>
                    </Reactions>
                </div>
                {/* {!props.hideTimeline &&
                    <div className={classes.feedFooter} onClick={(e) => { e.stopPropagation() }}>
                        <Timeline data={data} />
                    </div>
                } */}
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(Component))
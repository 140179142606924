import React, { useState } from "react";

//@MATERIAL
import { FormControlLabel, Switch, Tooltip, makeStyles } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';

//FUNCTIONS
import { customApp, translate } from "functions/";

const useStyles = makeStyles({
    switch: {
        '& + .MuiSwitch-track': {
            backgroundColor: customApp('color'),
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: 'rgb(125, 125, 125)',
        },
        '& .Mui-checked': {
            '& + .MuiSwitch-track': {
                backgroundColor: customApp('color'),
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: customApp('color'),
            },
            '& .MuiSvgIcon-root': {
                backgroundColor: customApp('color'),
                borderRadius: "50%",
                width: '20px',
                height: '20px',
                color: customApp('color'),
            }
        },
        '& .Mui-disabled': {
            '& + .MuiSwitch-track': {
                opacity: 0.5,
            },
            '& .MuiSvgIcon-root': {
                backgroundColor: customApp('color'),
                borderRadius: "50%",
                width: '20px !important',
                height: '20px !important',
                padding: '3px',
                color: `${customApp('lightText')} !important`,
                transform: 'translate(0, 0, 0, 0.2em)',
            }
        }
    },
});

const SwitchCheck = ({ checked, setData, notificationType, notifications, notification, data }) => {
    const [check, setCheck] = useState(checked !== "block" ? checked : true);
    const colorSwitch = useStyles();
    const handleChangeNotifications = (check) => {
        const updateData = data?.map(updateNotifications => {
            if (updateNotifications === notifications) {
                const updateDataNotification = updateNotifications.notifications.map(updateNotification => {
                    if (updateNotification === notification) {
                        return { ...updateNotification, [notificationType]: check };
                    }
                    return updateNotification;
                })
                return { ...updateNotifications, notifications: updateDataNotification };
            }
            return updateNotifications;
        })
        setData(updateData);
    }

    return (
        <Tooltip title={checked === "block" ? translate('$__requiredNotification') : ''}>
            <FormControlLabel control={
                <Switch className={colorSwitch.switch}
                    color="default"
                    checked={check}
                    onChange={(event) => { setCheck(event.target.checked); handleChangeNotifications(event.target.checked) }}
                    disabled={checked === "block" ? true : false}
                    checkedIcon={<LockIcon />}
                />
            } />
        </Tooltip>
    )
}
export default SwitchCheck;

// ID_CREATED*************************

//CORE
import React from "react"
import { cpf, cnpj } from 'cpf-cnpj-validator';

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import ImgCropper from "components/Cropper"
import TextField from "components/TextField"
import CustomButton from "components/Buttons/custom"
import SelectUsers from "components/User/select";

//@MATERIAL
import Icon from "components/Icon"
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS
import { upload } from "functions/upload";
import {
    translate,
    customApp,
    appAlert,
    getDate
} from "functions/"

import ErrorBoundary from "components/ErrorBoundary"

import api from "api"

function Component(props) {
    const { session } = reduxStore.getState()
    const [data, setData] = React.useState(session.GlobalData ? session.GlobalData : {})
    const [iniData, setIniData] = React.useState(session.GlobalData ? session.GlobalData : {})

    const saveData = async () => {
        let newData = {}
        let file
        Object.keys(data).forEach(mp => {
            if (mp === "file") {
                file = data[mp]
            } else if (mp === 'document') {
                let valid = false
                if (data[mp].length === 11 && cpf.isValid(data[mp]))
                    valid = true
                if (data[mp].length === 14 && cnpj.isValid(data[mp]))
                    valid = true
                if (valid) {
                    newData = {
                        ...newData,
                        [mp]: data[mp] && typeof data[mp] === 'string' ? data[mp].trim() : data[mp]
                    }
                } else {
                    appAlert({
                        message: `${translate(`$__${mp}`)}: ${translate("$__invalidDocument")}`,
                        variant: 'warning',
                        persist: false,
                        horizontal: 'right',
                        confirm: null
                    })
                }

            } else if (data[mp] && (!iniData[mp] || JSON.stringify({ a: data[mp] }) !== JSON.stringify({ a: iniData[mp] }))) {
                newData = {
                    ...newData,
                    [mp]: data[mp] && typeof data[mp] === 'string' ? data[mp].trim() : data[mp]
                }
            } else if (!data[mp] && mp !== "_id") {
                appAlert({
                    message: `${translate(`$__${mp}`)}: ${translate("$__cantBeEmpty")}`,
                    variant: 'warning',
                    persist: false,
                    horizontal: 'right',
                    confirm: null
                })
            }
        })
        // JSON.str
        try {
            let imageResult = file ? await upload(session.account, file) : true
            if (imageResult) {
                if (String(imageResult) !== 'true') {
                    newData = {
                        ...newData,
                        image: imageResult
                    }
                }
                const response = await api.post("/account/save", newData)
                if (response) {
                    if (response.error) {
                        console.log("companyDataError", response.error)
                    } else {
                        props.reduxFunction("ASYNC", "SET_SESSION", {
                            ...reduxStore.getState().session,
                            GlobalData: {
                                ...reduxStore.getState().session.GlobalData,
                                ...newData
                            }
                        });
                        setIniData({
                            ...reduxStore.getState().session.GlobalData,
                            ...newData
                        })
                    }
                }
                // socket.emit("data", {
                //     module: "account",
                //     method: "post",
                //     action: "save"
                // }, newData, response => {
                //     if (response.error) {
                //         console.log("companyDataError", response.error)
                //     } else {
                //         props.reduxFunction("ASYNC", "SET_SESSION", {
                //             ...reduxStore.getState().session,
                //             GlobalData: {
                //                 ...reduxStore.getState().session.GlobalData,
                //                 ...newData
                //             }
                //         });
                //         setIniData({
                //             ...reduxStore.getState().session.GlobalData,
                //             ...newData
                //         })
                //     }
                // })
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <div
                id={`sideModule_user_foto`}
            >
                <ImgCropper
                    maxWidthOrHeight={600}
                    img={
                        data &&
                            data.image ?
                            data.image :
                            null
                    }
                    onChange={(a, file) => {
                        setData({
                            ...data,
                            file: file
                        })
                    }}
                    avatar
                    avatarIcon={'business'}
                />
            </div>
            <TextField
                id={`sideModule_user_fullName`}
                label={translate('$__fullCompanyName', 1)}
                variant={customApp('fieldVariant')}
                name={'fullName'}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon icon='business' />
                        </InputAdornment>
                    ),
                    onChange: (d) => {
                        setData({
                            ...data,
                            fullName: d.target.value
                        })
                    },
                    onBlur: (d) => {
                        if (data && !data.fullName) {
                            let name = d.target.value ? `${d.target.value.split(' ')[0]} ${d.target.value.split(' ')[1] ? d.target.value.split(' ')[1] : ''}` : ""
                            setData({
                                ...data,
                                fullName: name
                            })
                        }
                    }
                }}
                value={data && data.fullName ? data.fullName : ''}
                placeholder={translate('$__fullName', 1)}
                autoFocus
                disabled={true}
            />
            <TextField
                id={`sideModule_user_displayName`}
                label={translate('$__displayName', 1)}
                variant={customApp('fieldVariant')}
                name="name"
                InputProps={{
                    autoComplete: 'new-password',
                    multiline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon icon='view_comfy_ind' />
                        </InputAdornment>
                    ),
                    onChange: (d) => {
                        setData({
                            ...data,
                            name: d.target.value
                        })
                    },
                }}
                value={data && data.name ? data.name : ''}
                placeholder={translate('$__displayName', 1)}
                disabled={true}
            />
            <TextField
                id={`customURL`}
                label={translate('$__customURL', 1)}
                variant={customApp('fieldVariant')}
                name={'customURL'}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                        <InputAdornment position="end">
                            <span style={{ color: "gray", fontSize: 12 }}>.machen.ai</span>
                        </InputAdornment>
                    ),
                    onChange: (d) => {
                        setData({
                            ...data,
                            customURL: d.target.value.trim()
                        })
                    },
                    onBlur: (d) => {
                        if (data && !data.fullName) {
                            setData({
                                ...data,
                                customURL: d.target.value.trim()
                            })
                        }
                    }
                }}
                value={data && data.customURL ? data.customURL : ''}
                placeholder={translate('$__customURL', 1)}
                autoFocus
            />
            <TextField
                id={`__documentCPForCNPJ`}
                label={translate('$__documentCPForCNPJ', 1)}
                variant={customApp('fieldVariant')}
                name="document"
                InputProps={{
                    autoComplete: 'new-password',
                    multiline: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon icon='view_comfy_ind' />
                        </InputAdornment>
                    ),
                    onChange: (d) => {
                        setData({
                            ...data,
                            document: d.target.value
                        })
                    },
                }}
                value={data && data.document ? data.document : ''}
                placeholder={translate('$__displayName', 1)}
                disabled={iniData.document ? true : false}
            />
            <fieldset>
                <legend>{translate("$__accountResponsible")}</legend>
                <ErrorBoundary>
                    <SelectUsers
                        includeMe
                        admins
                        avatarSize="33px"
                        permission={true}
                        noLimit
                        selected={data.accountResponsible ? [data.accountResponsible] : null}
                        onSelect={(e) => {
                            setData({
                                ...data,
                                accountResponsible: e[0]
                            })
                        }}
                    />
                </ErrorBoundary>
            </fieldset>
            <fieldset>
                <legend>{translate("$__invoiceResponsible")}</legend>
                <ErrorBoundary>
                    <SelectUsers
                        includeMe
                        admins
                        avatarSize="33px"
                        permission={true}
                        noLimit
                        selected={data.invoiceResponsible ? [data.invoiceResponsible] : null}
                        onSelect={(e) => {
                            setData({
                                ...data,
                                invoiceResponsible: e[0]
                            })
                        }}
                    />
                </ErrorBoundary>
            </fieldset>
            <ul style={{
                color: customApp("color")
            }}>
                <li>
                    {translate("$__createdAt")}: {getDate(data.created_at)}
                </li>
                {data.expire_at ?
                    <li style={{
                        color: customApp("menu")
                    }}>
                        {translate("$__expireDate")}: {getDate(data.expire_at)}
                    </li>
                    : <React.Fragment></React.Fragment>
                }
            </ul>
            {JSON.stringify(data) !== JSON.stringify(iniData) ?
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        // shadow
                        title={translate("$__save")}
                        text={translate("$__save")}
                        color="green"
                        // tr
                        icon={"save"}
                        size={"25px"}
                        onClick={() => {
                            saveData()
                            // setEditor(false)
                        }}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
    )
}
const mapStateToProps = (store) => ({
    session: store.session
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
import React from "react";

//COMPONENTS
import Icon from "components/Icon";

//@MATERIAL
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { customApp } from "functions/";


const useStyles = makeStyles((theme) => ({
    iconButtonStyle: {
        margin: 0,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        }
    },
}));


export default function CustomButton(props) {
    const classes = useStyles();
    return (
        <Tooltip
            title={props.title ? props.title : ''}
        >
            <IconButton
                onClick={props.onClick ? (e) => {
                    props.onClick()
                } : null}
                className={classes.iconButtonStyle}
                style={props.style || {}}
            >
                <Icon
                    usePropagation
                    size={props.size ? props.size : "20px"}
                    color={props.color ? props.color : customApp('menu')}
                    icon={props.icon ? props.icon : 'circle'} />
            </IconButton>
        </Tooltip>
    )
}
import React from "react"
import { Calendar, momentLocalizer } from "react-big-calendar"
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from "moment"
import 'moment/locale/pt-br';
import HtmlTooltip from "components/Tooltip/transparent"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import ActivityCard from "components/Card/";
import Badge from "components/Icon/Badge";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardsLoader from "components/Card/loader"
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import MoreMenu from "components/MoreMenu";

//FUNCTIONS
import { customApp, translate, hexToRgba } from "functions";
import { updateCardData } from "functions/cards"

//STYLES
import "assets/css/components/react-big-calendar.css"
import "assets/css/components/react-big-calendar-resizable.css"
import SelectCardTypes from "componentsV3/SelectCardTypes";

const MachenCalendar = withDragAndDrop(Calendar)
//VARIABLES

const messages = {
  allDay: translate("$__allDay"),
  previous: '<',
  next: '>',
  today: translate("$__today"),
  month: translate("$__month"),
  week: translate("$__week"),
  day: translate("$__aday"),
  agenda: translate("$__agenda"),
  date: translate("$__date"),
  time: translate("$__hour"),
  event: translate("$__activity"),
  showMore: (total) => `+ (${total}) ${translate("$__activities")}`,
}

export let navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
}

function CustomToolbar(props) {
  let { label } = props

  const navigate = action => {
    props.onNavigate(action)
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button type="button"
          onClick={() => { navigate('PREV') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__prev")}</button>
      </span>
      <span className="rbc-toolbar-label">{label}</span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('NEXT') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__next")}</button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('TODAY') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__today")}</button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('day') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__day")}</button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('week') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__week")}</button>
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('month') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__month")}</button>
      </span>
      {/* <span className="rbc-btn-group">
        <button type="button" onClick={() => { navigate('agenda') }} style={{ backgroundColor: customApp('menu'), borderRadius: 0 }}>{translate("$__agenda")}</button>
      </span> */}
    </div >
  )
}

function CalendarComponent(props) {
  const { reduxFunction, search } = props
  const { db, session } = reduxStore.getState()
  const [events, setEvents] = React.useState([]);
  const [view, setView] = React.useState('week')
  const [selectedDate, setSelectedDate] = React.useState(moment().toLocaleString())
  const [showAllEvents, setShowAllEvents] = React.useState(false)
  const [severalDaysActivities, setSeveralDaysActivities] = React.useState(false)
  const [disabledTypes, setDisabledTypes] = React.useState([])

  moment.locale(session.language ? session.language.toLowerCase() : 'pt-br', {
    week: {
      dow: 1
    }
  });
  const localizer = momentLocalizer(moment);

  React.useEffect(() => {
    let preffers = session && session.calendarPreffers ? JSON.parse(session.calendarPreffers) : {}
    if (preffers.view)
      setView(preffers.view)
    if (preffers.view)
      setShowAllEvents(preffers.showAllEvents)
    if (preffers.severalDaysActivities)
      setSeveralDaysActivities(preffers.severalDaysActivities)
    if (preffers.disabledTypes)
      setDisabledTypes(Array.from(preffers.disabledTypes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    iniCards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cards, search])

  React.useEffect(() => {
    iniCards()
    saveCalendarPreffers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [severalDaysActivities, disabledTypes])

  const saveCalendarPreffers = async () => {
    let preffers = session.calendarPreffers ? JSON.parse(session.calendarPreffers) : {}
    preffers = {
      ...preffers,
      disabledTypes: Array.from(disabledTypes),
      showAllEvents: showAllEvents,
      severalDaysActivities: severalDaysActivities,
      view: view,
    }

    const { socket } = reduxStore.getState().functions
    socket.emit("data", {
      module: "user",
      method: "post",
      action: "preffers"
    }, {
      calendarPreffers: JSON.stringify(preffers)
    }, data => {
      if (data.error)
        console.log("hideSupportAndAppBanner", data.error)
    })
  }

  const iniCards = async () => {
    let CalendarEvents = []
    Object.keys(reduxStore.getState().db.cards).filter(dt =>
      reduxStore.getState().db.cards[dt]
      && reduxStore.getState().db.cards[dt]._users
      && reduxStore.getState().db.cards[dt]._users[session._id]
      &&
      (
        !reduxStore.getState().db.cards[dt].checklist
        || (
          reduxStore.getState().db.cards[dt].checklist
          && reduxStore.getState().db.cards[dt].showCard
        )
      )
      && (
        moment(reduxStore.getState().db.cards[dt].startDate * 1000).isValid()
        && moment(reduxStore.getState().db.cards[dt].endDate * 1000).isValid()
      )
      && (disabledTypes.length === 0
        || (
          disabledTypes.length > 0
          && disabledTypes.indexOf(reduxStore.getState().db.cards[dt].type) === -1
        )
      )
      && reduxStore.getState().db.cards[dt].type !== "plan"
      && reduxStore.getState().db.cards[dt].type !== "chatGroup"
      && reduxStore.getState().db.cards[dt].status !== "completed"
      && !reduxStore.getState().db.cards[dt].deleted
      && (
        severalDaysActivities
        ||
        (
          !severalDaysActivities
          && (
            moment(reduxStore.getState().db.cards[dt].startDate * 1000).format('YYYY-MM-DD') === moment(reduxStore.getState().db.cards[dt].endDate * 1000).format('YYYY-MM-DD')
          )
        )
      )
      && (
        search.tags.length === 0
        ||
        (
          search.tags.length > 0
          && reduxStore.getState().db.cards[dt]._tags
          && reduxStore.getState().db.cards[dt]._tags.length > 0
          && reduxStore.getState().db.cards[dt]._tags.filter(tag => {
            let result = false
            search.tags.forEach(tagFilter => {
              if (tagFilter.value === tag.value)
                result = true
            })
            return result
          }
          ).length > 0
        )
      )
      && (
        search.text === ""
        || (
          search.text !== ""
          && (
            reduxStore.getState().db.cards[dt].name.toLowerCase().indexOf(search.text.toLowerCase()) > -1
            || (
              reduxStore.getState().db.cards[dt].description
              && reduxStore.getState().db.cards[dt].description.toLowerCase().indexOf(search.text.toLowerCase()) > -1
            )
          )
        )
      )
      && search.cards.status[reduxStore.getState().db.cards[dt].status]
      && (
        search.cards.priority.all
        || (
          reduxStore.getState().db.cards[dt].priority
          && search.cards.priority[reduxStore.getState().db.cards[dt].priority]
        )
      )
      && (
        search.cards.risk.all
        || (
          reduxStore.getState().db.cards[dt].risk
          && search.cards.risk[reduxStore.getState().db.cards[dt].risk]
        )
      )
      && (
        search.cards.complexity.all
        || (
          reduxStore.getState().db.cards[dt].complexity
          && search.cards.complexity[reduxStore.getState().db.cards[dt].complexity]
        )
      )
      && (
        search.cards.impact.all
        || (
          reduxStore.getState().db.cards[dt].impact
          && search.cards.impact[reduxStore.getState().db.cards[dt].impact]
        )
      )
      && (
        Object.keys(search.users.selected).length === 0
        || (
          Object.keys(search.users.selected).length > 0
          && Object.keys(reduxStore.getState().db.cards[dt]._users).filter(uf => search.users.selected[uf]).length > 0
        )
      )
    ).forEach(dt => {
      let allDay = false
      let startDate = moment(reduxStore.getState().db.cards[dt].startDate * 1000).toDate()
      let endDate = moment(reduxStore.getState().db.cards[dt].endDate * 1000).toDate()
      let diffDate = moment(reduxStore.getState().db.cards[dt].endDate * 1000).unix('x') - moment(reduxStore.getState().db.cards[dt].startDate * 1000).unix('x')

      if (
        (
          moment(reduxStore.getState().db.cards[dt].startDate * 1000).format("HH:mm:ss") === "00:00:00"
          && moment(reduxStore.getState().db.cards[dt].endDate * 1000).format("HH:mm:ss") === "23:59:59"
        )
        ||
        (
          Math.ceil(diffDate / 60 / 60) > 23
        )
      )
        allDay = true

      CalendarEvents.push(
        {
          title: reduxStore.getState().db.cards[dt].name,
          allDay: allDay,
          startDate: startDate,
          endDate: endDate,
          _id: dt,
        }
      )
    })
    setEvents(CalendarEvents)
  }

  const addNewEvent = (e) => {
    reduxFunction("ASYNC", "SET_MODULE", {
      ...reduxStore.getState().sideModule,
      db: 'cards',
      id: 'new',
      module: "cardEdit",
      activeModule: 'cardEdit',
      data: {
        type: "task",
        startDate: moment(e.start).unix("x"),
        endDate: moment(e.end).unix("x"),
      },
    })
  };

  return (
    <div>
      <CardsLoader />
      <GridContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
          }}
        >
          <MoreMenu
            text={translate("$__legend")}
            icon={"style"}
            horizontalButton
            transparent
            size={'21px'}
            color={'lightgray'}
            btColor={'lightgray'}
            buttonStyle={{ backgroundColor: customApp('menu'), borderRadius: 0 }}
            options={[
              {
                name: translate("$__scheduleColor", 1),
                icon: 'label',
                iconColor: "gray",
                color: "gray",
                disabled: true,
              },
              {
                name: translate("$__late", 1),
                icon: 'label',
                iconColor: "red",
                disabled: true
              },
              ...db.status.map(x => {
                return {
                  name: translate(`${x.label}`, 1),
                  icon: 'label',
                  iconColor: x.color,
                  disabled: true
                }
              }),
              {
                name: translate("$__scheduleLeftBorderColor", 1),
                icon: 'border_left',
                color: 'gray',
                iconColor: "gray",
                disabled: true
              },
              ...db.priority.map(x => {
                return {
                  name: translate(`${x.label}`, 1),
                  icon: 'border_left',
                  color: x.color,
                  iconColor: x.color,
                  disabled: true
                }
              }),
            ]}
          />
          <SelectCardTypes options={disabledTypes} onChange={(d) => { setDisabledTypes(d) }} />
          <MoreMenu
            text={translate("$__options")}
            icon={"settings"}
            horizontalButton
            transparent
            size={'21px'}
            color={"lightgray"}
            btColor={"lightgray"}
            buttonStyle={{ backgroundColor: customApp('menu'), borderRadius: 0 }}
            style={{
              marginRight: "15px"
            }}
            options={[
              {
                name: translate(`$__showAllEvents`, '*'),
                icon: !showAllEvents ? 'radio_button_unchecked' : 'check_circle_outline',
                onClick: () => {
                  setShowAllEvents(prev => !prev)
                },
              },
              {
                name: translate(`$__activitiesTInSeveralDays`, '*'),
                icon: !severalDaysActivities ? 'radio_button_unchecked' : 'check_circle_outline',
                onClick: () => {
                  setSeveralDaysActivities(prev => !prev)
                },
              },
            ]}
          />
        </div>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <MachenCalendar
                selectable
                localizer={localizer}
                events={events}
                messages={messages}
                defaultDate={new Date()}
                onSelectSlot={addNewEvent}
                startAccessor="startDate"
                endAccessor="endDate"
                onView={(x) => {
                  setView(x)
                }}
                onSelectEvent={(event) => {
                  reduxFunction("ASYNC", "SET_MODULE", {
                    ...reduxStore.getState().sideModule,
                    db: 'cards',
                    id: event._id,
                    module: "cardEdit",
                    activeModule: "cardEdit",
                    data: reduxStore.getState().db.cards[event._id],
                  })
                }}
                eventPropGetter={
                  (event, start, end, isSelected) => {
                    let data = reduxStore.getState().db.cards[event._id]
                    let statusColor = reduxStore.getState().db.status.filter(x => x.value === reduxStore.getState().db.cards[event._id].status)[0].color
                    let backgroundColor = statusColor


                    let ini = moment(new Date(start)).unix('x')
                    let fin = moment(new Date(end)).unix('x')

                    if (moment(new Date(end)).unix('x') < moment().unix('x')
                    ) backgroundColor = "#ff0000"

                    let percent = (((fin - ini) / 60) / (24 * 60) * 100)

                    if (percent > 100)
                      percent = 100

                    let newStyle = {
                      height: `${percent}%`,
                      ...view === 'week' || view === "day" ? {
                        minHeight: `${percent}%`
                      } : {
                        minHeight: 22,
                      },
                      width: "100%",
                      border: "solid 1px rgba(0,0,0,0.1)",
                      borderRadius: 3,
                      // paddingRight: 7,
                      boxShadow: "0px 1x 2px 0px rgba(0,0,0,0.3)",
                      backgroundColor:
                        !isSelected
                          ? `${hexToRgba(backgroundColor, 0.5)}`
                          : `${hexToRgba(backgroundColor, 0.3)}`,
                      ...data.priority && db.priority.filter(a => String(a.value) === String(data.priority)).length > 0 ? {
                        borderLeft: `solid 5px ${db.priority.filter(a => String(a.value) === String(data.priority))[0].color}`
                      } : {}
                    };

                    return {
                      className: "",
                      style: newStyle
                    };
                  }
                }
                components={{
                  event: ({ event }) => {
                    let data = reduxStore.getState().db.cards[event._id]
                    let icon = data.type && db && db.dataType && db.dataType.filter(a => a.name === data.type)[0] ? db.dataType.filter(a => a.name === data.type)[0].icon : "$__topic"

                    return (
                      <HtmlTooltip
                        leaveDelay={800}
                        title={(
                          <React.Fragment>
                            <div style={{
                              display: "flex",
                              alignItems: "center",
                              zIndex: 5000,
                            }}
                            >
                              <ActivityCard data={data}
                                onlyOption
                                notGlobalExpanded
                              />
                            </div>
                          </React.Fragment>
                        )
                        }>
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 10,
                          height: "100%",
                          maxWidth: "100%",
                          textOverflow: "ellipsis",
                          overflow: "hidden"
                        }}>
                          <div style={{ marginRight: 5 }}>
                            <Badge
                              overlap="rectangular"
                              size={'12px'}
                              icon={icon}
                              color={"bkack"}
                              title={translate(`$__${data.type}`)}
                            />
                          </div>{event.title}

                        </div>

                      </HtmlTooltip>
                    )
                  },
                  toolbar: CustomToolbar,
                  month: {
                    dateHeader: ({ date, label }) => {
                      return <div style={{ color: customApp("menu"), cursor: "pointer" }}>{label}</div>
                    },
                  },
                  week: {
                    dateHeader: ({ date, label }) => {
                      return <div style={{ color: customApp("menu"), cursor: "pointer" }}>{label}</div>
                    },
                  }
                }}
                onNavigate={(dt, view, action) => {
                  if (!selectedDate || moment(dt).format("YYYY-MM") !== moment(selectedDate).format("YYYY-MM"))
                    setSelectedDate(dt)
                  if (action !== "NEXT" && action !== "PREV" && action !== "TODAY" && action !== "DATE")
                    setView(action)

                }}
                view={view}
                onEventResize={(d) => {
                  updateCardData(
                    {
                      ...props,
                      data: { _id: d.event._id }
                    }, {
                    startDate: moment(d.start).unix('x'),
                    endDate: moment(d.end).unix('x')
                  })
                }}
                onEventDrop={(d) => {
                  updateCardData(
                    {
                      ...props,
                      data: { _id: d.event._id }
                    }, {
                    startDate: moment(d.start).unix('x'),
                    endDate: moment(d.end).unix('x')
                  })
                }}
                showMultiDayTimes
                showAllEvents={showAllEvents}
                popup
                resizable={true}
                defaultView={"week"}
              />
            </CardBody>
          </Card>
          <div
            style={{
              height: 300,
              width: "100%"
            }}
          ></div>
        </GridItem>
      </GridContainer>
    </div >
  );
}

//EXPORT
const mapStateToProps = (store) => {
  return {
    search: store.search,
    cards: store.db.cards
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

//EXPORT
export default connect(mapStateToProps, mapDispatchToProps)(CalendarComponent)

import {
  containerFluid
} from "assets/jss/material-dashboard-pro-react";

const appStyle = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.66) !important"
  },
  wrapper: {
    position: "absolute",
    top: "70px",
    left: "0",
    right: "0",
    bottom: "0",
    height: "calc(100vh - 60px)",
  },
  mainPanel: {
    overflow: "auto",
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    maxHeight: "calc(100vh - 72px)",
    padding: 15
  },
  content: {
    marginTop: "0px",
    padding: "15px",
  },
  container: {
    ...containerFluid,
    height: "-webkit-fill-available",
    overflowY: "auto"
  },
  map: {
    marginTop: "0px"
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 0px)`
    }
  },
});

export default appStyle;

import React from 'react';
import { render } from 'react-dom';
import history from 'store/history';
import store from 'store/';
import Root from './root.jsx';


require('dotenv').config()

if (window.location.hostname.indexOf('192.168') === -1
  && window.location.hostname.indexOf('localhost') === -1
  && window.location.hostname.indexOf('hom2022.machen') === -1
  && window.location.hostname.indexOf('homologation.machen') === -1
  && window.location.hostname.indexOf('a1b2c3d4.machen') === -1
  && window.location.hostname.indexOf('sonne.machen') === -1
)
  console.log = () => { }

render(
  <Root store={store} history={history} />,
  document.getElementById('root'),
);

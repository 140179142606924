import React from "react"
import { ResponsiveBar } from '@nivo/bar'

import CustomButton from "components/Buttons/custom"
import ErrorBoundary from "components/ErrorBoundary"

import { translate, customApp } from "functions"


const ChartTagsStatistics = (props) => {
    const [layout, setLayout] = React.useState("horizontal")
    const [groupMode, setGroupMode] = React.useState("stacked")

    return (
        <ErrorBoundary>
            <div style={{
                display: "flex"
            }}>
                <CustomButton
                    color={customApp('lightgray')}
                    icon={'work_history'}
                    onClick={() => {
                        setGroupMode(prev => prev === "stacked" ? "grouped" : "stacked")
                    }}
                    size={'15px'}
                    title={`${translate("$__changeGroupMode")}`}
                    text={`${translate("$__changeGroupMode")}`}
                />
                <CustomButton
                    color={customApp('lightgray')}
                    icon={'work_history'}
                    onClick={() => {
                        setLayout(prev => prev === "vertical" ? "horizontal" : "vertical")
                    }}
                    size={'15px'}
                    title={`${translate("$__changeLayout")}`}
                    text={`${translate("$__changeLayout")}`}
                />

            </div>
            <ResponsiveBar
                data={props.data}
                keys={props.keys}
                indexBy="date"
                groupMode={groupMode}
                margin={{ top: 50, right: 250, bottom: 50, left: 80 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                layout={layout}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                // fill={[
                //     {
                //         match: {
                //             id: 'fries'
                //         },
                //         id: 'dots'
                //     },
                //     {
                //         match: {
                //             id: 'sandwich'
                //         },
                //         id: 'lines'
                //     }
                // ]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 250,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 140,
                        itemHeight: 10,
                        itemDirection: 'right-to-left',
                        itemOpacity: 0.85,
                        symbolSize: 10,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => e.id + ": " + e.formattedValue + ": " + e.indexValue}
            />
        </ErrorBoundary>
    )
}


export default ChartTagsStatistics;
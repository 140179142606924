
const adminNavbarLinksStyle = theme => ({
  avatar: {
    width: "30px",
    height: "30px",
    backgroundSize: "cover",
    borderRadius: "50%"
  },
  component: {
    //marginTop: '15px',
    //marginBottom: '15px',
    zIndex: '10',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  selectedName: {
    display: "inline-flex", cursor: "pointer"
  },
  selectedNameText: {
    textDecoration: "none",
    fontSize: "12px",
    fontFamily: "Soleto",
  },
});

export default adminNavbarLinksStyle;

//CORE
import React from 'react'
import moment from "moment-timezone";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Card from "components/Card";
import Icon from "components/Icon";
import Message from "componentsV3/Timeline/message";
import TextField from "components/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import HeaderWithIcon from "components/Header/card";

//@MATERIAL
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import Avatar from "components/Avatar";

//FUNCTIONS
import {
    customApp,
    getDate,
    Highlighted,
    translate,
} from 'functions';

function Component(props) {
    const { timeline, functions } = reduxStore.getState()
    const { socket } = functions
    const { db } = reduxStore.getState()
    const [viewMessage, setViewMessage] = React.useState(false)

    const [file, setFile] = React.useState(props.data)
    const fileName = file.name.split('.')
    let ext = fileName[fileName.length - 1]

    const [edit, setEdit] = React.useState(false)
    const [name, setName] = React.useState(fileName[0])

    const mounted = React.useRef(true)

    React.useEffect(() => {
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (file.name === 'blob') {
        file.name = `${translate('$__coverImage', '*')}.jpg`
        ext = 'jpg'
    }

    let img = `attachment`
    if (reduxStore.getState().db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1).length > 0)
        img = reduxStore.getState().db.fileExtensions.filter(a => a.ext.indexOf(ext.toLowerCase()) > -1)[0].icon
            || `https://machen.azureedge.net/${file.file}`


    const update = async (dataUpdate) => {
        try {
            socket.emit("data", {
                module: "node",
                method: "put",
                action: "update"
            }, {
                _id: file._id,
                ...dataUpdate
            }, async () => {
                setFile({ ...file, ...dataUpdate })
                setEdit(false)
            })
        } catch (e) {
            console.log(e)
        }
    }

    let timelineData = {
        ...db.cards[file.cardId] && timeline[file.cardId] && timeline[file.cardId][file.timelineId] ? timeline[file.cardId][file.timelineId] : file.message,
    }
    if (timelineData && !timelineData.message)
        timelineData = {
            ...timelineData,
            message: translate("$__onlyAttachment")
        }


    return (
        <div
            style={{
                width: '100%'
            }}
        >
            <div
                style={{
                    position: 'relative',
                    float: 'left',
                    width: '100%',
                    marginBottom: '5px',
                    cursor: 'pointer',
                }}
            >
                {props.resume ?
                    <React.Fragment>
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                // justifyContent: 'space-between'
                            }}
                            onClick={(e) => {
                                if (props.openLightBox)
                                    props.openLightBox()
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                    width: '33px',
                                    height: '33px',
                                    // marginRight: '7px',
                                    alignItems: 'center',
                                    display: 'flex',
                                    overflow: 'hidden'
                                }}
                            >
                                {typeof img === 'string' && img.indexOf('https') === -1 && img.indexOf('svg') === -1 ?
                                    <Icon icon={img} size='33px' />
                                    :
                                    <img alt="MACHEN_IMAGE" src={img} width='100%' style={{ width: 'auto', height: 'auto', maxHeight: '77%' }} onError={(e) => e.target.src = img} />
                                }
                            </div>

                            <div style={{
                                marginLeft: '7px',
                                width: "calc(100% - 80px)",
                                maxWidth: "calc(100% - 80px)",
                                minWidth: '150px',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                            }}
                            >
                                {Highlighted(file.name, props.searchText)}
                            </div>
                            {db.users[file.createdBy] &&
                                <div style={{
                                    position: 'absolute', right: '10px', top: '7px', zIndex: '10'
                                }}>
                                    <AvatarGroup max={7}>
                                        <Tooltip
                                            title={db.users[file.createdBy].displayName ? db.users[file.createdBy].displayName : db.users[file.createdBy].name}
                                        >
                                            <Avatar
                                                alt={db.users[file.createdBy].displayName ? db.users[file.createdBy].displayName : db.users[file.createdBy].name}
                                                src={`${db.users[file.createdBy].image}`}
                                                style={{ width: '15px', height: '15px' }}
                                            >
                                                {db.users[file.createdBy].name.substr(0, 1)}
                                            </Avatar>
                                        </Tooltip>
                                    </AvatarGroup>
                                </div>

                            }
                        </div>
                        {props.showParent &&
                            (
                                file.timelineId
                                || file.message
                            )
                            // && viewMessage
                            &&
                            (
                                (
                                    db.cards[file.cardId]
                                    && timeline[file.cardId]
                                    && timeline[file.cardId][file.timelineId]
                                )
                                ||
                                (
                                    file.message
                                )
                            )
                            &&
                            <div style={{ width: '100%', marginBottom: "33px" }} >
                                <Message
                                    openLightBox={(e) => {
                                        if (props.openLightBox)
                                            props.openLightBox(e)
                                    }}
                                    storie={timelineData}
                                    db={'cards'} nodeId={file.cardId}
                                />
                                <div style={{ clear: "both" }}></div>
                            </div>
                        }
                        {props.showParent && file.cardId && db.cards[file.cardId] &&
                            <div style={{ width: '100%', top: "33px" }}>
                                <Card db="cards" cardId={file.cardId} showConnectedIn />
                            </div>
                        }
                        <div style={{ width: "100%", height: "1px", backgroundColor: "rgba(0,0,0,0.1)", marginTop: "15px" }}></div>
                    </React.Fragment>
                    :
                    <div style={{
                        position: "relative",
                        float: 'left',
                        width: '100%',
                        marginTop: '7px',
                        padding: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '70px'
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: '0px',
                            width: 'calc(100% - 30px)',
                            borderBottom: `solid 1.5px ${customApp('color')}`,
                            alignItems: 'center',
                            display: 'flex',
                            color: customApp('color'),
                        }}>
                            <div
                                style={{
                                    width: '357px',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    cursor: 'pointer'
                                }}
                                onClick={(e) => {
                                    if (props.onClick)
                                        props.onClick()
                                }}
                                onDoubleClick={() => {
                                    setEdit(true)
                                }}
                            >
                                {edit ?
                                    <React.Fragment>
                                        <div
                                            style={{ position: 'relative', float: 'left', width: '100%', padding: '7px', display: 'flex', alignItems: 'center' }}>
                                            <div style={{ width: 'calc(100% - 50px)', display: 'flex' }}>
                                                <TextField
                                                    label={translate('$__taskTitle')}
                                                    variant={customApp('fieldVariant')}
                                                    name={'name'}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        autoComplete: 'new-password',
                                                        onChange: (d) => {
                                                            setName(d.target.value)
                                                        },
                                                        onBlur: (d) => {
                                                            if (d.target.value === '')
                                                                setEdit(!edit)
                                                        },
                                                        onKeyDown: (e) => {
                                                            if (e.keyCode === 13 && name) {
                                                                update({
                                                                    name: `${name}.${ext}`
                                                                })
                                                            }
                                                            if (e.keyCode === 27) {
                                                                setEdit(!edit)
                                                                setName()
                                                            }
                                                        }
                                                    }}
                                                    autoFocus
                                                    value={name}
                                                    placeholder={translate('$__taskTitlePlaceholder')}
                                                />
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div
                                                    onClick={() => update({
                                                        name: `${name}.${ext}`
                                                    })}
                                                >
                                                    <Icon color='green' icon={'save'} title={translate('$__save', '*')} size={props.actionsSize || '25px'} />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setEdit(!edit)
                                                        setName('')
                                                    }}
                                                >
                                                    <Icon color={customApp('medium')} icon={'cancel'} title={translate("$__cancel", "*")} size={props.actionsSize || '25px'} />
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div style={{ width: '100%' }}>

                                        {file.in && file.in.name &&
                                            <div style={{ display: 'flex', alignItems: "center", }}>
                                                {file.in.db === 'User' ?
                                                    <Tooltip
                                                        title={file.in.name}
                                                    >
                                                        <Avatar
                                                            alt={file.in.name}
                                                            src={file.in.image}
                                                            style={{ width: '15px', height: '15px' }}
                                                        >
                                                            {file.in.name}
                                                        </Avatar>
                                                    </Tooltip>
                                                    : <React.Fragment></React.Fragment>}
                                                <HeaderWithIcon
                                                    title={file.in.name}
                                                    icon={file.in.db === 'User' ? null : (file.in.type && db && db.dataType && db.dataType.filter(a => a.name === file.in.type)[0] && db.dataType.filter(a => a.name === file.in.type)[0].icon) || "$__topic"}
                                                    color={customApp('medium')}
                                                    style={{
                                                        padding: '7px',
                                                        width: 'calc(100% - 30px)',
                                                        textAlign: 'left'
                                                    }}
                                                    textStyle
                                                />
                                            </div>
                                        }
                                        <div
                                            style={{
                                                width: '100%',
                                                display: "flex",
                                                alignItems: 'center',
                                                padding: '0 15px'
                                            }}>
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '33px',
                                                    marginRight: '7px',
                                                    alignItems: 'center',
                                                    height: '50px',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                {typeof img === 'string' && img.indexOf('https') === -1 && img.indexOf('svg') === -1 ?
                                                    <Icon icon={img} size='33px' />
                                                    :
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            width: '33px',
                                                            height: '33px',
                                                            margin: '7px',
                                                            borderRadius: '50%',
                                                            overflow: 'hidden',
                                                            backgroundImage: `url("${img}")`,
                                                            backgroundPosition: 'center center',
                                                            backgroundSize: 'cover'
                                                        }}

                                                    >
                                                    </div>
                                                }
                                            </div>
                                            {file.name}
                                        </div>
                                        {file.createdBy &&
                                            <div style={{
                                                position: 'relative',
                                                display: "flex",
                                                alignItems: "center",
                                                color: props.dark ? "lightgray" : "gray",
                                                fontSize: 10,
                                                marginLeft: '42px',
                                                clear: 'both'
                                            }}>
                                                <Avatar
                                                    alt={file.createdBy.name}
                                                    src={file.createdBy.image}
                                                    style={{ width: '15px', height: '15px' }}
                                                >
                                                    {file.createdBy.name}
                                                </Avatar>
                                                <span style={{ marginLeft: 3 }}>
                                                    {file.createdBy.name} - ({getDate(file.created_at)})
                                                </span>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            <div
                                style={{
                                    width: '130px',
                                    textAlign: 'center',
                                    borderRight: `solid 1.5px ${customApp('color')}`,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    fontSize: '12px',
                                }}
                            >
                                {moment(new Date(file.created_at * 1000)).format("DD/MM/YYYY HH:mm")}
                            </div>
                            <div
                                style={{
                                    width: '130px',
                                    borderRight: `solid 1.5px ${customApp('color')}`,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    overflow: 'visible'
                                }}
                            >
                                {db.users[file.createdBy] &&
                                    <Tooltip
                                        title={db.users[file.createdBy].name}
                                    >
                                        <React.Fragment>
                                            <Avatar size="sm" src={`${db.users[file.createdBy].image}`} >
                                                {db.users[file.createdBy].name.substr(0, 1)}
                                            </Avatar>
                                        </React.Fragment>
                                    </Tooltip>
                                }
                            </div>
                            <div
                                style={{
                                    width: '300px',
                                    textAlign: 'left',
                                    borderRight: `solid 1.5px ${customApp('color')}`,
                                    paddingLeft: '15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}
                            >

                            </div>
                            <div
                                style={{
                                    width: '130px',
                                    textAlign: 'left',
                                    paddingLeft: '15px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex'
                                }}>
                                <Tooltip
                                    title={translate('$__showFile')}>
                                    <Icon
                                        onClick={props.openLightBox ? () => {
                                            props.openLightBox()
                                        } : null
                                        }
                                    >
                                        pageview
                                    </Icon>
                                </Tooltip>
                                {(file.timelineId || file.message) &&
                                    <Tooltip
                                        title={translate('$__viewMessage')}>
                                        <Icon
                                            size="20px"
                                            onClick={() => {
                                                setViewMessage(!viewMessage)
                                            }}
                                        >feedback</Icon>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                    </div>
                }
            </div >
            {
                (file.timelineId || file.message)
                && viewMessage
                &&
                (
                    (
                        db.cards[file.cardId]
                        && timeline[file.cardId]
                        && timeline[file.cardId][file.timelineId]
                    )
                    ||
                    (
                        file.message
                    )
                )
                &&
                <div style={{ width: '100%' }} >
                    <Message
                        openLightBox={(e) => {
                            if (props.openLightBox)
                                props.openLightBox(e)
                        }}
                        storie={timelineData}
                        db={'cards'} nodeId={file.cardId}
                    />
                </div>
            }
        </div >
    )
}
const mapStateToProps = () => ({})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

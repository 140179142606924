import React from "react";
import moment from "moment-timezone"
import MD5 from "crypto-js/md5"


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Icon from "components/Icon";
import SelectUsers from "components/User/select";

//@MATERIAL
import Tooltip from "@material-ui/core/Tooltip";
import Filter from "./index";
import ButtonOptions from "componentsV3/Buttons";

//FUNCTIONS
import { translate, customApp, textColor } from "functions/";

function MenuFilter(props) {
    const [searchID, setSearchId] = React.useState(props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString())
    const contentRef = React.useRef()

    React.useEffect(() => {
        let newId = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
        if (searchID !== newId)
            setSearchId(newId)
    }, [props.nodeId])

    const filters = {
        ...props.defaultValues || {},
        ...reduxStore.getState().searchs[searchID] || {}
    }

    React.useEffect(() => {
        if (props.defaultValues) {
            props.reduxFunction("ASYNC", "SET_SEARCH", {
                ...reduxStore.getState().searchs,
                [searchID]: {
                    ...props.defaultValues || {},
                    ...reduxStore.getState().searchs[searchID] || {}
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchID])

    const RenderSelected = ({ card, type, order }) => {
        return (
            <div
                key={card}
                style={{
                    padding: "3px 7px",
                    backgroundColor: "rgba(0,0,0,0.1)",
                    boxShadow: "rgba(0,0,0,0.2) 0px 0px 3px 3px 3px",
                    margin: "3px",
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    borderRadios: "0px 0px 8px 0px",
                }}

            >{reduxStore.getState().db.cards[card].name.substr(0, 20)} <Icon
                    icon={"close"}
                    color={"lightGray"}
                    style={{
                        marginLeft: 10,
                        width: 10,
                        height: 10,
                        padding: 7,
                        backgroundColor: "#80808087",
                        borderRadius: 7
                    }}
                    size={10}
                    onClick={() => {
                        let selectedCards = reduxStore.getState().searchs[searchID].selectedCards
                        if (order === 0) {
                            delete selectedCards.guideline
                            delete selectedCards.objective
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 1) {
                            delete selectedCards.objective
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 2) {
                            delete selectedCards.goal
                            delete selectedCards.action
                        }
                        if (order === 3)
                            delete selectedCards.action

                        props.reduxFunction("ASYNC", "SET_SEARCH", {
                            ...reduxStore.getState().searchs,
                            [searchID]: {
                                ...reduxStore.getState().searchs[searchID] || {},
                                selectedCards: selectedCards
                            },
                        })
                    }}
                />
            </div>
        )
    }

    return (
        <div style={{ zIndex: "100 !important", display: "flex", justifyContent: "space-between", alignItems: "center", ...props.style ? props.style : {} }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                width: "100%"
            }}>
                <ButtonOptions
                    text={translate("$__filter")}
                    icon={"filter_alt"}
                    popper={
                        (<div style={{ padding: "15px", zIndex: 1 }} ref={contentRef} id="cal-menufilter">
                            <Filter
                                {...props}
                                onChange={(data) => {
                                    console.log(data)
                                    if (props.onChange) props.onChange(data)
                                }}
                            />
                        </div>
                        )}
                />
                <div
                    style={{
                        padding: "3px 15px",
                        display: "flex",
                        cursor: "pointer"
                    }}
                >
                    <div style={{ display: "flex", fontSize: 11, width: 150 }}>
                        {console.log(reduxStore.getState().searchs)}
                        <SelectUsers
                            includeMe
                            defineResponsible
                            avatarSize="23px"
                            permission={true}
                            data={reduxStore.getState().db.cards[searchID]}
                            noLimit
                            multiple={true}
                            onSelect={(e) => {
                                props.reduxFunction("IMMEDIATE", "SET_SEARCH", {
                                    ...reduxStore.getState().searchs,
                                    [searchID]: {
                                        ...reduxStore.getState().searchs[searchID] || {},
                                        appliedFilters: {
                                            ...reduxStore.getState().searchs[searchID]?.appliedFilters || {},
                                            users: e
                                        },
                                        users: e
                                    },
                                })
                            }}
                            selected={filters.users}
                            transparent
                        />
                    </div>
                </div>
                {props.showFilters ?
                    <div
                        style={{
                            padding: "3px 15px",
                            display: "flex",
                            cursor: "pointer"
                        }}
                    >
                        {filters.text &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{props.title?.text || translate("$__text")}: <span style={{ color: customApp("darkText") }}> {filters.text}</span></div>
                        }
                        {filters.ref1 &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{props.title?.text || translate("$__text")}: <span style={{ color: customApp("darkText") }}> {filters.text}</span></div>
                        }
                        {filters.ref2 &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{props.title?.text || translate("$__text")}: <span style={{ color: customApp("darkText") }}> {filters.text}</span></div>
                        }
                        {filters.tagsByText &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__tagsByText")}: <span style={{ color: customApp("darkText") }}> {filters.tagsByText}</span></div>
                        }
                        {filters.startDate && filters.endDate &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: "7px 7px",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    justifyContent: 'space-between'
                                }}>
                                    <label
                                        style={{ marginRight: 7, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                    >
                                        {moment(filters.startDate * 1000).format("YYYY-MM-DD  HH:MM:ss")}
                                    </label>
                                    <Icon
                                        size={"22px"}
                                        color={customApp('color')}
                                        icon={'today'}
                                        onClick={props.onClick}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    width: "100%",
                                    padding: "7px 7px",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    justifyContent: 'space-between'
                                }}
                                >
                                    <label
                                        style={{ marginRight: 7, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                                    >
                                        {moment(filters.endDate * 1000).format("YYYY-MM-DD  HH:MM:ss")}
                                    </label>
                                    <Icon
                                        size={"22px"}
                                        color={customApp('color')}
                                        icon={'today'}
                                        onClick={props.onClick}
                                    />
                                </div>
                            </div>
                        }
                        {filters.responsible && filters.responsible.length > 0 ?
                            <div style={{ display: "flex", fontSize: 11, width: 150 }}>
                                <SelectUsers
                                    avatarSize="23px"
                                    data={reduxStore.getState().db.cards[searchID]}
                                    selected={filters.responsible}
                                    transparent
                                />
                            </div>
                            : <></>}
                        {filters.tags &&
                            <div style={{ display: "flex", fontSize: 11 }}>{filters.tags.map((t, i) =>
                                <div
                                    key={t.value}
                                    className={'tag'}
                                    style={{
                                        backgroundColor: t.color || "lightgray",
                                        color: t.color ? textColor(t.color, true) : '#FFFFFF',
                                        display: "flex", alignItems: "center",
                                        padding: "3px 7px",
                                        borderRadius: "7px",
                                        marginLeft: 5,
                                        whiteSpace: "nowrap",
                                        height: "fit-content"
                                    }}
                                >
                                    {t.parent && reduxStore.getState().db.tag.filter(tt => tt.value === t.parent).length > 0 ?
                                        <div
                                            style={{
                                                fontSize: 8,
                                                marginRight: 3
                                            }}
                                        >{reduxStore.getState().db.tag.filter(tt => tt.value === t.parent)[0].label} / </div>
                                        : <React.Fragment></React.Fragment>}
                                    {t.label}
                                </div>
                            )}
                            </div>
                        }
                        {filters.status && !filters.status.all &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__status")}: <div style={{ display: "flex", alignItems: "center" }}>
                                {Object.keys(filters.status)
                                    .filter((a, i) => filters.status[a])
                                    .map((a, i) => {
                                        return (
                                            <Tooltip
                                                title={translate(reduxStore.getState().db.status.filter(fil => fil.value === a)[0].label, 1)}
                                                key={i}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        float: 'left',
                                                        width: '11px',
                                                        height: '11px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reduxStore.getState().db.status.filter(fil => fil.value === a)[0].color,
                                                        margin: '7px 3px',
                                                    }}></div>
                                            </Tooltip>
                                        )
                                    })}
                            </div>
                            </div>
                        }
                        {filters.priority && !filters.priority.all &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__priority")}: <div style={{ display: "flex", alignItems: "center" }}>
                                {Object.keys(filters.priority)
                                    .filter((a, i) => filters.priority[a] && reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a).length > 0)
                                    .map((a, i) => {
                                        return (
                                            <Tooltip
                                                title={translate(reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a)[0].label, 1)}
                                                key={i}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        float: 'left',
                                                        width: '11px',
                                                        height: '11px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reduxStore.getState().db.priority.filter(fil => fil.value === a)[0].color,
                                                        margin: '7px 3px',
                                                    }}></div>
                                            </Tooltip>
                                        )
                                    })}
                            </div>
                            </div>
                        }
                        {filters.risk && !filters.risk.all &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__risk")}: <div style={{ display: "flex", alignItems: "center" }}>
                                {Object.keys(filters.risk)
                                    .filter((a, i) => filters.risk[a] && reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a).length > 0)
                                    .map((a, i) => {
                                        return (
                                            <Tooltip
                                                title={translate(reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a)[0].label, 1)}
                                                key={i}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        float: 'left',
                                                        width: '11px',
                                                        height: '11px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reduxStore.getState().db.priority.filter(fil => fil.value === a)[0].color,
                                                        margin: '7px 3px',
                                                    }}></div>
                                            </Tooltip>
                                        )
                                    })}
                            </div>
                            </div>
                        }
                        {filters.complexity && !filters.complexity.all &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__complexity")}: <div style={{ display: "flex", alignItems: "center" }}>
                                {Object.keys(filters.complexity)
                                    .filter((a, i) => filters.complexity[a] && reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a).length > 0)
                                    .map((a, i) => {
                                        return (
                                            <Tooltip
                                                title={translate(reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a)[0].label, 1)}
                                                key={i}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        float: 'left',
                                                        width: '11px',
                                                        height: '11px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reduxStore.getState().db.priority.filter(fil => fil.value === a)[0].color,
                                                        margin: '7px 3px',
                                                    }}></div>
                                            </Tooltip>
                                        )
                                    })}
                            </div>
                            </div>
                        }
                        {filters.impact && !filters.impact.all &&
                            <div style={{ color: "gray", padding: "0px 15px", borderRight: "solid 1px gray", display: "flex", alignItems: "center" }}>{translate("$__impact")}: <div style={{ display: "flex", alignItems: "center" }}>
                                {Object.keys(filters.impact)
                                    .filter((a, i) => filters.impact[a] && reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a).length > 0)
                                    .map((a, i) => {
                                        return (
                                            <Tooltip
                                                title={translate(reduxStore.getState().db.priority.filter(fil => a && fil && fil.value === a)[0].label, 1)}
                                                key={i}
                                            >
                                                <div
                                                    style={{
                                                        position: 'relative',
                                                        float: 'left',
                                                        width: '11px',
                                                        height: '11px',
                                                        borderRadius: '50%',
                                                        backgroundColor: reduxStore.getState().db.priority.filter(fil => fil.value === a)[0].color,
                                                        margin: '7px 3px',
                                                    }}></div>
                                            </Tooltip>
                                        )
                                    })}
                            </div>
                            </div>
                        }
                    </div>
                    : <></>}

                {filters && filters.selectedCards && Object.keys(filters.selectedCards).length > 0 ?
                    <div style={{
                        position: "relative",
                        width: "100%",
                        color: "gray",
                        font: 10,
                        alignItems: "center",
                        justifyContent: "flex-start",
                        grid: 3,
                        display: "flex"
                    }}>
                        {
                            filters.selectedCards?.guideline ?
                                <RenderSelected card={filters.selectedCards.guideline} type="guideline" order={0} />
                                : <></>
                        }
                        {
                            filters.selectedCards?.objective ?
                                <RenderSelected card={filters.selectedCards.objective} type="objective" order={1} />
                                : <></>
                        }
                        {
                            filters.selectedCards?.goal ?
                                <RenderSelected card={filters.selectedCards.goal} type="goal" order={2} />
                                : <></>
                        }
                        {
                            filters.selectedCards?.action ?
                                <RenderSelected card={filters.selectedCards.action} type="action" order={3} />
                                : <></>
                        }
                        {Object.keys(filters.selectedCards).length > 1 ?
                            <Icon
                                title={translate("$__clearAll")}
                                icon={"clear_all"}
                                color={"#80808087"}
                                size={17}
                                onClick={() => {
                                    props.reduxFunction("ASYNC", "SET_SEARCH", {
                                        ...reduxStore.getState().searchs,
                                        [searchID]: {
                                            ...reduxStore.getState().searchs[searchID] || {},
                                            selectedCards: {}
                                        },
                                    })
                                }}
                            />
                            : <></>}
                    </div>
                    : <></>}
            </div>
            {props.children ? props.children : <></>}
        </div >
    )
}

const mapStateToProps = (store) => ({
    searchs: store.searchs
})
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MenuFilter)
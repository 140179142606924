import React, { useState, useEffect } from "react"
import Icon from "components/Icon/"
import MatrixIcon from "assets/icons/Matrix.js"

import { translate } from "functions"


const GetHeader = (props) => {
    const { handlePrevScreen, name, prev, handleClose } = props
    return (

        <div>
            <p
                style={{
                    marginTop: "2px",
                    height: "80px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    borderBottom: "1px solid #d1d1cf",
                    fontSize: "16px"
                }}
            >
                <Icon
                    size="35px"
                    style={{
                        alignSelf: "center",
                        marginRight: "10px",
                        color: "#001836"
                    }}
                >
                    view_comfy
                </Icon>
                {name}
            </p>
            <div id="getElements_40">
                <Icon
                    style={{
                        alignSelf: "center",
                        height: "25px",
                        cursor: "pointer",
                        position: "absolute",
                        width: "25px",
                        right: "20px",
                        top: "18px",
                        color: "grey"
                    }}
                    size="30px"
                    onClick={handleClose ? () => { handleClose() } : null}
                >
                    close
                </Icon>
            </div>
            {prev && <div id="getElements_58"> <Icon
                style={{
                    alignSelf: "center",
                    height: "25px",
                    cursor: "pointer",
                    position: "absolute",
                    width: "25px",
                    left: "20px",
                    top: "18px",
                    color: "grey"
                }}
                onClick={handlePrevScreen}
                size="30px"
            >
                arrow_back
            </Icon></div>}
        </div>
    );
};


const GetButton = ({ name, handleEvent, style, disabled, id }) => {

    const [buttonHover, setButtonHover] = useState(false)
    const buttonBackground = buttonHover ? "rgba(0, 0, 0,0.05)" : "white"

    return (
        <button
            id={id}
            onMouseEnter={() => { setButtonHover(true) }}
            onMouseLeave={() => { setButtonHover(false) }}
            onClick={handleEvent}
            disabled={disabled}
            style={{
                ...style,
                minWidth: "160px",
                height: "45px",
                cursor: "pointer",
                border: "1px solid #d1d1cf",
                fontWeight: "bolder",
                backgroundColor: buttonBackground
            }}
        >
            {name}
        </button>
    )
}

const GetModal = ({ children }) => {

    return (
        <div
            style={{
                height: "100.4vh",
                width: "100vw",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                position: "absolute",
                left: "0% !important",
                right: "0%",
                top: "0%",
                bottom: "0%",
                zIndex: "9999",
                display: "flex",
                justifyContent: "center",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            {children}
        </div>
    );
}

const BoxModal = ({ children, widthSize, id }) => {


    const [scale, setScale] = useState({
        top: "10%",
        resize: "0.9"
    });

    useEffect(() => {
        const size = window.innerWidth < 1500;
        setScale(
            {
                resize: size ? "0.9" : "1",
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                width: widthSize ? widthSize : "900px",
                position: "absolute",
                borderRadius: "5px",
                backgroundColor: "#fafbfc",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                transform: `scale(${scale.resize})`,
                alignSelf: "center"
            }}
            id={id}
        >
            {children}
        </div>
    );
}

const BoxText = ({ tittle, text }) => {
    return (
        <div
            style={{
                border: "1px solid #ebebeb",
                marginLeft: "25px",
                minHeight: "50px",
                backgroundColor: "white",
                marginTop: "22px",
                width: "92%",
                height: "100px",
                position: "relative",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",

            }}
        >
            <p style={{ margin: "0px", marginLeft: "22px", marginTop: "16px", fontSize: "16px", fontWeight: "bold" }}>
                {tittle}
            </p>
            <Icon
                size={"35px"}
                style={{ alignSelf: "center", color: "#757380", marginRight: "10px", position: 'absolute', top: '-14px', left: '-14px' }}
            >
                info
            </Icon>
            <p style={{
                marginLeft: "22px",
                marginTop: "0px",
                display: 'flex',
                position: "relative",
                fontSize: "14px",
                color: "#7c828d",
                maxWidth: "370px"
            }}>
                {text}
            </p>
        </div>
    );
}



const ListItem = ({ item, index, handleItemClick, selectedPlan }) => {

    const [animate, setAnimate] = useState(null)

    const handleItemHover = (index) => {
        setAnimate(index);
    };

    return (
        <li
            id={item.id}
            onClick={() => handleItemClick(index, item)}
            onMouseEnter={() => handleItemHover(index)}
            onMouseLeave={() => handleItemHover(null)}
            style={{
                filter: `brightness(${selectedPlan?.index === index ? "100%" : "100%"})`,
                opacity: selectedPlan?.index === index ? "1" : "0.7",
                border: `2px solid ${selectedPlan?.index === index ? "#ebebeb" : "#ebebeb"}`,
                width: "90%",
                height: "65px",
                marginTop: index === 0 ? "15px" : "12px",
                marginLeft: "17px",
                display: "flex",
                alignItems: "center",
                boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                backgroundColor: selectedPlan?.index === index ? "#ebebeb" : "white",
                cursor: "pointer",
                transform: `scale(${animate === index ? "1.03" : "1"})`,
            }}
        >
            <Icon
                size={"35px"}
                style={{ alignSelf: "center", color: "#757380", marginLeft: "10px", marginRight: "10px", }}
                icon={item.icon}
            />
            {/* {item.icon} */}
            {/* </Icon> */}
            <p style={{ alignSelf: "center", marginTop: "9px", color: 'black', fontWeight: 'bold' }}>{translate(item.label)}</p>
            {/* <span
                style={{
                    width: "20px",
                    height: "20px",
                    border: "1px solid grey",

                    position: "absolute",
                    right: "15px",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                {selectedPlan?.index === index && (
                    <Icon
                        style={{
                            alignSelf: "center",
                            cursor: "pointer",
                            color: "#001836",
                            margin: "0px",
                            padding: "0px"
                        }}
                        size="16px"
                    >
                        done
                    </Icon>
                )}
            </span> */}
        </li>
    );
};



const GetAddTemplate = () => {


    return (
        <>
            <div style={{ display: "flex", border: "1px solid #ebebeb", cursor: "pointer", boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px", width: "303px", background: "#fafaff", padding: "5px" }}>
                <div style={{ height: "20px", borderRadius: "5px", marginLeft: "20px", width: "25px", alignSelf: "center", display: "flex", justifyContent: "center" }}>
                    <Icon
                        style={{
                            alignSelf: "center",
                            cursor: "pointer",
                            color: "grey",
                            fontWeight: "bolder",
                            borderRadius: "2px",
                            padding: "2px",
                            border: "1px solid grey",
                        }}
                        size="24px"
                    >
                        add
                    </Icon>
                </div>
                <p style={{
                    alignSelf: "center", marginLeft: "15px", fontSize: "16px",
                    color: "grey",
                    marginTop: "9px"
                }}>Adicionar Template</p>
            </div>
        </>
    )
}

const plans = [
    {
        label: "$__strategic",
        value: null,
        gif: "https://blobmatrix.blob.core.windows.net/saas/planType_gif.gif",
        description: "$__planStrategicDescription",
        // description: "O planejamento estratégico possui a metodologia MATRIX SWOT, que permite ajudar nos processos decisores da estratégia.",
        icon: MatrixIcon,
        id: "SelectPlan",
        index: 0
    },
    {
        label: "$__tacticAndOrOperational",
        value: "flow",
        gif: "https://blobmatrix.blob.core.windows.net/saas/planType_gifEstrategico.gif",
        description: "$__planTaticAndOperationalDescription",
        // description: "O plano operacional é indicado para criação de fluxos de execução e atividades sem a estratégia por base de análises de SWOT",
        icon: "account_tree",
        id: "c"
    },
    {
        label: "$__flowOrProcess",
        value: "operational",
        gif: "https://blobmatrix.blob.core.windows.net/saas/planType_gif2.gif",
        description: "$__planFlowOrProcessDescription",
        // description: "Planejamento com fluxo automatizado de aprovações, usuários, departamentos.",
        icon: "view_week",
        id: "a"
    },
    {
        label: "$__crm",
        value: "crm",
        gif: "https://blobmatrix.blob.core.windows.net/saas/planType_gif3.gif",
        description: "$__planCrmDescription",
        icon: "filter_alt",
        id: "b"
    },

]



export { GetHeader, ListItem, GetModal, BoxModal, BoxText, plans, GetButton, GetAddTemplate };
import React, { useEffect, useState } from 'react'

//REDUX
import reduxStore from "store"

//COMPONENTES
import UserAddList from './UserAddList'
import Icon from 'components/Icon'

//@MATERIAL
import { FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, makeStyles, Checkbox, ListItemText, OutlinedInput } from '@material-ui/core'

//FUNCTIONS
import { translate, customApp } from 'functions'

const useStyles = makeStyles({
  formControl:{
    width: '200px',
    '& .MuiOutlinedInput-root':{
      backgroundColor:'#fff',
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline':{
      borderColor: 'rgba(0, 0, 0, 0.23)',
    }, 
  },
  checkbox:{
    color:customApp('color'),
    '& .Mui-checked':{
      color:`${customApp('color')} !important`,
    }
  },
})

const ListUsers = ({setSelectUser}) => {
  const styles = useStyles();
  const [edit, setEdit] = useState(false)
  const [filter, setFilter] = useState('All')
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState(['nomecompleto','nomedeexibição','email', 'departamento/grupo',/* 'ramal','observações','celular' */])
  const [selectedColumns, setSelectedColumns] = useState(['nomecompleto','nomedeexibição','email'])
  const [column, setColumn] = useState(null); 
  const [dataUser, setDataUser] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [direction, setDirection] = useState('asc');
  const { db, session } = reduxStore.getState();
  const { users } = db;

  useEffect(()=>{
    const data = Object.values(users)
    .map(user => {
      return{
        nomedeexibição: user?.name,
        nomecompleto: user?.fullName,
        email:user?.email,
        image:user?.image,
        _id:user?._id,
        ['departamento/grupo']:user?.groups,
      }
    })

    setDataUser(data);

    const filteredData = data.filter((user) => {
      const dataFilter = filter === 'All' || user['departamento/grupo']?.some(group => group.label === filter)
      const dataSearch = Object.values(user).some(value => typeof value === 'string' && value.toLowerCase().includes(searchValue.toLowerCase()));
      return dataFilter && dataSearch;
    });
    setDataFilter(filteredData);
  
    if (data?.length > 0) {
      setLoading(false);
    }

  }, [filter, searchValue])

  const NameColumn = (name) =>{
    switch (name) {
      case 'nomecompleto': return translate("$__completeName") ;
          break;
      case 'nomedeexibição': return translate("$__showName") ;
          break;
      case 'email': return translate("$__email") ;
          break;
      case 'departamento/grupo': return translate("$__department/group") ;
          break;
      case 'ramal': return  translate("$__branchLine") 
          break;
      case 'observações': return translate("$__observation") ;
          break;
      case 'celular': return translate("$__phone");
          break;
      default:
          break;
    }
  }

  const orderColumn = (columnSelect) => { 
    if (columnSelect === column) { 
      setDirection(direction === 'asc' ? 'desc' : 'asc'); 
    } else { 
      setColumn(columnSelect); 
      setDirection('asc'); 
    } 
  }; 
  
  const orderData = dataFilter ? [...dataFilter]?.sort((a, b) => { 
      if (column) { 
        if (direction === 'asc') { 
          return a[column] > b[column] ? 1 : -1; 
        } else { 
          return a[column] < b[column] ? 1 : -1; 
        } 
      } 
      return 0; 
  }) : []

  const selectData = [...new Set(dataUser.flatMap((user) => user['departamento/grupo']?.map((group) =>  group?.label || [])))]
  return (
    <div
      style={{
        width: "95%",
        position: "absolute",
        zIndex: "9",
        borderRadius: "5px",
        backgroundColor: "#eee",
        minHeight:'100vh',
        height: "auto",
        margin: "0px",
        fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
      }}
    >
      <div
        style={{
          height: "100%",
          justifyContent: "center",
          margin: "0px",
          width: "100%",
          backgroundColor: "#eee",
        }}
      >
        <div
          style={{
            backgroundColor: "#eee",
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flexDirection: "column",
            gap: "5px",
            marginBottom: "30px",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#eee",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              gap: "5px",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <div style={{
              display:'flex',
              alignItems: "flex-end",
              flexDirection: "row",
            }}>
              <div style={{
                height: "100%",
                width:'auto',
                display:'flex',
                alignItems:'flex-start',
                gap:'0px',
                flexDirection:'column',
                marginRight:'25px',
                marginLeft:'10px',
              }}>
                <p style={{margin:'5px 0'}}>{translate('$__search')}:</p>
                <FormControl className={styles.formControl} variant="standard" size='small'>
                  <OutlinedInput
                    id="search"
                    type={"text"}
                    placeholder={translate('$__search')}
                    onChange={(e)=> setSearchValue(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon>search</Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div
                style={{
                  height: "100%",
                  width:'auto',
                  display:'flex',
                  alignItems:'flex-start',
                  gap:'0px',
                  flexDirection:'column',
                  marginRight:'25px',
                }}
              >
                <p style={{margin:'5px 0'}}>{translate("$__filterByDepartment")}:</p>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 220 }} className={styles.formControl} size='small'>
                  <Select
                    defaultValue={'All'}
                    displayEmpty
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <MenuItem value="All">{translate('$__all')}</MenuItem>
                    {selectData.sort().map((departamento) => (
                      <MenuItem value={departamento} key={departamento}>{departamento}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  height: "100%",
                  width:'auto',
                  display:'flex',
                  alignItems:'flex-start',
                  gap:'0px',
                  flexDirection:'column',
                  marginRight:'25px',
                }}
              >
                <p style={{margin:'5px 0'}}>{translate("$__columnName")}:</p>
                <FormControl sx={{ m: 1, width: 300 }} className={styles.formControl} size='small'>
                  <Select
                    labelId="checkbox-label"
                    id="multiple-checkbox"
                    multiple
                    defaultValue={selectedColumns}
                    onChange={(e)=> setSelectedColumns(e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.map(NameColumn).join(', ')}
                  >
                    {columns.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={selectedColumns.indexOf(name) > -1} color="default" className={styles.checkbox}/>
                        <ListItemText primary={NameColumn(name)} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{backgroundColor:'#fff', border:'1px solid rgba(0, 0, 0, 0.23)', borderRadius:'5px', padding:'10.5px 14px'}}>
                {orderData?.length} {orderData?.length > 1 || orderData?.length === 0 ? translate('$__users') : translate('$__user')}
              </div>
            </div>
            {/* <button
              onClick={() => setEdit(!edit)}
              style={{
                padding: "10px",
                boxShadow:
                  "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                backgroundColor: "white",
                color: "rgb(0, 24, 54)",
                border: "2px solid rgb(235, 235, 235)",
                borderRadius: "50%",
                fontWeight: "600",
                margin: "10px",
                width: "auto",
                cursor: "pointer",
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                gap:'5px',
              }}
            >
              {
                edit ? <Icon>visibility</Icon> : <Icon>edit</Icon>
              }
            </button> */}
          </div>
          {loading ? (
            <p>{translate('$__loading')}...</p>
          ) : (
           <UserAddList 
            edit={edit} 
            setSelectUser={setSelectUser}
            setEdit={setEdit} 
            dataUser={orderData} 
            columns={selectedColumns} 
            orderData={orderData} 
            orderColumn={orderColumn}
            column={column} /> )}
        </div>
      </div>
    </div>
  );
}

export default ListUsers
const style = theme => {
    return ({
        root: {
            position: 'relative',
        },
        timeInfo: {
            display: 'flex',
            alignItems: 'center',
            color: 'grey',
            width: 'calc(100% - 15px)',
            position: 'relative',
            float: 'left',
            marginBottom: '7px',
            '& .text': {
                marginLeft: '7px',
            }
        },
        dataSave: {
            padding: '7px'
        },
        fieldSet: {
            padding: '7px',
            width: 'calc(100% - 50px)'
        },
        progress: {
            //width: 'calc(100% - 50px)',
        },
        progressPercentage: {
            //width: '45px',
        }
    })
}

export default style;
//FUNCTIONS
import { customApp } from "functions";

const headerStyle = theme => ({
  btClose: {
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
    right: '0',
    top: '-64px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
  },
  image: {
    width: '100%',
    backgroundColor: 'rgb(0,88,0)',
    paddingTop: '45%'
  },
  data: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    overflowY: 'auto',
    marginTop: '10px',
    padding: '10px',
    '& fieldset': {
      border: 'solid 1px rgba(0,0,0,0.1)',
      '& legend': {
        marginBottom: '0px',
        fontSize: '16px',
        color: customApp('color')
      }
    }
  },
  loader: {
    position: 'relative', width: '100%', height: '40px', textAlign: 'center',
    '& .MuiCircularProgress-root': {
      color: `${customApp('color')} !important`,
    },
  },
  preview: {
    listStyle: 'none',
    padding: '0px',
    '& li': {
      padding: '7px',
      backgroundColor: 'rgba(0,0,0,0.05)',
      marginBottom: '15px',
      boxShadow: '0 0 7px 3px rgba(0,0,0,0.1)',
    }
  },
  previewTitle: {
    position: 'relative',
    width: '100%',
    fontWeight: 'bold',
    marginBottom: '7px'
  },
  feedTitle: {
    position: 'relative', width: '100%', marginBottom: '7px', borderBottom: `solid 1px ${customApp('medium', 0.2)}`
  },
  feedContent: {
    position: 'relative', width: '100%'
  },
  feedFooter: {
    width: '100%',
    textAlign: 'right',
    height: '16px',
    borderTop: '1px solid rgba(0,0,0,0.05)',
    paddingTop: '3px'
  },
  feedDate: {
    position: 'relative',
    left: '0px',
    fontSize: '12px',
    float: 'left'
  },
  feedLink: {
    position: 'relative',
    left: '0px',
    fontSize: '12px',
    float: 'right'
  },
  switch: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: customApp('medium')
  }

});

export default headerStyle;

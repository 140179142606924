import React from 'react';

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';
import history from "store/history";

//COMPONENTS
import CardsLoader from "components/Card/loader";
import FilterHeader from "components/Filter/header";

//DEPENCIES
import InsightBoards from "./InsightBoards";
import Radar from "./Radar";
import Feeds from "./Feeds";
import Home from "./Home";
import Read from "./Read";
import ListLinks from "components/BrightAndConnected/listLinks"

//FUNCTIONS

function App(props) {
    React.useEffect(() => {
        console.log("StrategyAndPlans")
        return () => {
            // return false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    if (!window.location.hash.split('/')[0].replace('#', '')) {
        history.push('bright-and-connected#tab/home')
    }

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    return (
        <div>
            {hashs[1]
                && (
                    hashs[1] === 'radar'
                    || hashs[1] === 'insight-boards'
                )
                &&
                <CardsLoader />
            }
            {hashs[1] && hashs[1] === 'home' &&
                <React.Fragment>
                    {/* <FilterHeader /> */}
                    <Home />
                </React.Fragment>
            }
            {hashs[1] && hashs[1] === 'read' &&
                <React.Fragment>
                    <FilterHeader />
                    <Read />
                </React.Fragment>
            }
            {hashs[1] && hashs[1] === 'insight-boards' &&
                <React.Fragment>
                    <FilterHeader />
                    <InsightBoards />
                </React.Fragment>
            }
            {hashs[1] && hashs[1] === 'radar' &&
                <div style={{ top: 33 }}>
                    {/* <FilterHeader /> */}
                    <Radar />
                </div>
            }
            {hashs[1] && (hashs[1] === 'feeds' || hashs[1] === 'bright-and-connected') &&
                <React.Fragment>
                    <FilterHeader />
                    <Feeds />
                </React.Fragment>
            }
            {hashs[1] && hashs[1] === 'hideFeeds' &&
                <React.Fragment>
                    <FilterHeader />
                    <Feeds showHidden={true} />
                </React.Fragment>
            }
            {hashs[1] && hashs[1] === 'allNews' &&
                <React.Fragment>
                    <FilterHeader />
                    <div style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        top: 0,
                    }}>
                        <ListLinks />
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(App);
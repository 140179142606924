//"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _createSvgIcon = _interopRequireDefault(require("@material-ui/icons/utils/createSvgIcon"));

var _default = (0, _createSvgIcon.default)(_react.default.createElement(_react.default.Fragment, null, _react.default.createElement("path", {
  fill: "none",
  d: "M3,19.77a1,1,0,0,1,.29-.7l2.43-2.43a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L4.65,20.48a1,1,0,0,1-1.41,0A1,1,0,0,1,3,19.77Z"
}), _react.default.createElement("path", {
  d: "M3,19.77a1,1,0,0,1,.29-.7l2.43-2.43a1,1,0,0,1,1.41,0,1,1,0,0,1,0,1.42L4.65,20.48a1,1,0,0,1-1.41,0A1,1,0,0,1,3,19.77Z"
}), _react.default.createElement("g", null, _react.default.createElement("path", {
  d: "M3.15,4.67A1,1,0,0,1,4.86,4L7.28,6.38a1,1,0,0,1,0,1.42,1,1,0,0,1-1.41,0L3.44,5.37A1,1,0,0,1,3.15,4.67Z"
}), _react.default.createElement("path", {
  d: "M15.67,17.18a1,1,0,0,1,.29-.7,1,1,0,0,1,1.41,0L19.8,18.9a1,1,0,0,1-1.42,1.42L16,17.89A1,1,0,0,1,15.67,17.18Z"
}), _react.default.createElement("path", {
  d: "M15.67,7.09A1,1,0,0,1,16,6.38L18.38,4A1,1,0,0,1,19.8,4a1,1,0,0,1,0,1.41L17.37,7.8A1,1,0,0,1,16,7.8,1,1,0,0,1,15.67,7.09Z"
}), _react.default.createElement("path", {
  d: "M.73,12a1,1,0,0,1,1-1H6.58a5.26,5.26,0,0,1,4.13-4.13V1.44a1,1,0,1,1,2,0V7.78a1,1,0,0,1-1,1A3.23,3.23,0,0,0,8.48,12a1,1,0,0,1-1,1H1.73A1,1,0,0,1,.73,12Z"
}), _react.default.createElement("path", {
  d: "M10.71,22.56V16.22a1,1,0,0,1,1-1A3.22,3.22,0,0,0,14.93,12a1,1,0,0,1,1-1h6.34a1,1,0,0,1,0,2H16.83a5.23,5.23,0,0,1-4.12,4.13v5.43a1,1,0,0,1-2,0Z"
}))), 'Brigth');

exports.default = _default;
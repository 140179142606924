//CORE
import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Card from "components/Card/";
import Icon from "components/Icon";
import IconButton from "components/CustomButtons/IconButton";
import TextField from "components/TextField";

//FUNCTIONS
import api from "api/";
import { translate, customApp } from "functions/"

function Component(props) {
    const { db, session } = reduxStore.getState()
    const { socket } = reduxStore.getState().functions
    const [name, setName] = React.useState(props.defaultName ? props.defaultName : '')
    const [add, setAdd] = React.useState(false)

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    const addCard = async () => {
        const { socket } = reduxStore.getState().functions

        socket.emit("data", {
            module: "cards",
            method: "post",
            action: "add"
        }, {
            _parent: hashs[0],
            planId: hashs[0] && hashs[0].length === 36 ? hashs[0] : null,
            name,
            type: props.cardType,
            status: 'notStarted'
        }, async (response) => {
            socket.emit('card.UPD', {
                cardId: response.id,
                cardData: {
                    ...response.data,
                    // _loaded: true,
                    _parent: hashs[0],
                    _timeline: {
                        [response.log._id]: {
                            ...response.log,
                            _parent: [response.id],
                        }
                    },
                    _users: {
                        [session._id]: {
                            name: reduxStore.getState().db.users[session._id].name ? reduxStore.getState().db.users[session._id].name : null,
                            displayName: reduxStore.getState().db.users[session._id].displayName ? reduxStore.getState().db.users[session._id].displayName : null,
                            image: reduxStore.getState().db.users[session._id].image ? reduxStore.getState().db.users[session._id].image : null,
                            access: 5,
                            _id: session._id
                        }
                    },
                },
            })
        })
        setName('')
    }


    const onDragEnd = async result => {
        const { destination, source, draggableId } = result

        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        const itemOrderIds = props.cards
        itemOrderIds.splice(source.index, 1)
        itemOrderIds.splice(destination.index, 0, draggableId)

        api.put("cards/reorder", {
            cards: itemOrderIds
        })

        let cards = {}

        itemOrderIds.map((a, i) => {
            cards = {
                ...cards,
                [a]: db.cards[a]
            }
            if (!cards[a].order)
                cards[a].order.low = i
            cards[a].order.low = i
            return true
        })
        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            cards: {
                ...reduxStore.getState().db.cards,
                ...cards
            }
        });
        socket.emit("card.UPD", cards)
        return

    }
    const onDragStart = async result => {
    }
    const onDragUpdate = async result => {
    }

    // return (<React.Fragment></React.Fragment>)

    return (
        <React.Fragment>
            <DragDropContext
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
            >
                <Droppable
                    droppableId={`Drop_${props.cardType || ''}`} //ID OF BOARD
                    type="column"
                    direction={"vertical"}
                >
                    {(provided, snapshot) => (
                        <Container
                            {...provided.droppableProps}
                            isDraggingOver={snapshot.isDraggingOver}
                            ref={provided.innerRef}
                        >
                            {props.cards.map((card, index) => (
                                <Draggable
                                    draggableId={card}
                                    index={index}
                                    key={card}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                        >
                                            <Card
                                                isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                                                snapshot={snapshot}
                                                cardId={card}
                                                db={'cards'}
                                                viewButton
                                                selectOption
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Container>
                    )}
                </Droppable>
            </DragDropContext>
            {
                !add &&
                <div style={{ position: 'relative', float: 'left', width: '100%', padding: '7px' }}>
                    <IconButton
                        text={translate("$__add", '*')}
                        icon="add_box"
                        iconColor={customApp('medium')}
                        onClick={() => {
                            setAdd(!add);
                        }}
                    />
                </div>
            }
            {
                add &&
                <React.Fragment>
                    <div
                        style={{ position: 'relative', float: 'left', width: '100%', padding: '7px', display: 'flex', alignItems: 'center' }}>
                        <div style={{ width: 'calc(100% - 50px)', display: 'flex' }}>
                            <TextField
                                label={translate('$__nameOfCard')}
                                variant={customApp('fieldVariant')}
                                name={'name'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    onChange: (d) => {
                                        setName(d.target.value)
                                    },
                                    onBlur: (d) => {
                                        if (d.target.value === '')
                                            setAdd(!add)
                                    },
                                    onKeyDown: (e) => {
                                        if (e.keyCode === 13 && name)
                                            addCard()
                                        if (e.keyCode === 27) {
                                            setAdd(!add)
                                            setName('')
                                        }
                                    }
                                }}
                                autoFocus
                                value={name}
                                placeholder={translate('$__titleOfCard_placeholder')}
                            />
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div
                                onClick={() => addCard()}
                            >
                                <Icon color='green' icon={'save'} title={translate('$__save', '*')} size={props.actionsSize || '25px'} />
                            </div>
                            <div
                                onClick={() => {
                                    setAdd(!add)
                                    setName('')
                                }}
                            >
                                <Icon color={customApp('medium')} icon={'cancel'} title={translate("$__cancel", "*")} size={props.actionsSize || '25px'} />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
        </React.Fragment >
    )
}

const Container = styled.div`
                    background-color:none;
                    `;

const mapStateToProps = store => ({
    store
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

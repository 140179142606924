//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Autocomplete from "components/AutoComplete"
import TextField from "components/TextField"
import CustomButton from "components/Buttons/custom"
import Switch from "components/Switch"

//FUNCTIONS
import {
    customApp,
    translate,
} from "functions/"

import api from "api"

function Component(props) {
    const {
        session,
        db
    } = reduxStore.getState()
    const [data, setData] = React.useState(session.GlobalData ? session.GlobalData : {})

    //hideDeletedUsers
    const [iniData, setIniData] = React.useState(session.GlobalData ? session.GlobalData : {})

    const saveData = async () => {
        let newData = {}
        Object.keys(data).forEach(mp => {
            if (String(data[mp]) && (!iniData[mp] || JSON.stringify({ a: data[mp] }) !== JSON.stringify({ a: iniData[mp] }))) {
                newData = {
                    ...newData,
                    [mp]: data[mp] && typeof data[mp] === 'string' ? data[mp].trim() : data[mp]
                }
            }
        })

        if (!newData.requireExpirePassword && newData.expiresIn)
            newData.expiresIn = null

        try {
            let res = await api.post("account/save", newData)
            if (res) {
                setData({
                    ...reduxStore.getState().session.GlobalData,
                    ...newData
                })
                setIniData({
                    ...reduxStore.getState().session.GlobalData,
                    ...newData
                })
                props.reduxFunction("ASYNC", "SET_SESSION", {
                    ...reduxStore.getState().session,
                    GlobalData: {
                        ...reduxStore.getState().session.GlobalData,
                        ...newData
                    }
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            style={{ grid: 7 }}
        >
            <fieldset style={{
                wordBreak: "break-word",
                marginBottom: 15
            }}>
                <legend>
                    {translate("$__hideDeletedUsersInTimelines")}
                </legend>
                <div style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Switch
                        checked={data && data.hideDeletedUsers ? true : false}
                        onChange={(d) => {
                            let newData = {
                                ...data,
                                hideDeletedUsers: data.hideDeletedUsers ? null : true,
                            }
                            setData(newData)
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {translate("$__required")}
                </div>
                <i>{translate("$__hideDeletedUsersDescription")}</i>
            </fieldset>
            <fieldset style={{
                wordBreak: "break-word",
                marginBottom: 15
            }}>
                <legend>
                    {translate("$__2FactorTokenRequired")}
                </legend>
                <div style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Switch
                        checked={data && data.requireToken ? true : false}
                        onChange={(d) => {
                            let newData = {
                                ...data,
                                requireToken: data.requireToken ? null : true,
                            }

                            setData(newData)
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {translate("$__required")}
                </div>
                <i>{translate("$__2FactorTokenRequiredDescription")}</i>
            </fieldset>
            <fieldset style={{
                wordBreak: "break-word",
                marginBottom: 15
            }}>
                <legend>
                    {translate("$__forceChangePassword")}
                </legend>
                <div style={{
                    position: "relative",
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <Switch
                        checked={data && data.requireExpirePassword ? true : false}
                        onChange={(d) => {
                            let newData = {
                                ...data,
                                requireExpirePassword: data.requireExpirePassword ? null : true,
                                ...data.requireExpirePassword ? { passwordExpire: null } : {}
                            }
                            setData(newData)
                        }}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {translate("$__required")}
                </div>
                <i>{translate("$__forceChangePasswordDescription")}</i>
                {data.requireExpirePassword ?
                    <fieldset>
                        <legend>{translate('$__sessionDuration', 1)}</legend>
                        <Autocomplete
                            id="combo-box-demo"
                            options={db.passwordExpire}
                            getOptionLabel={(option) => translate(option.label, 1)}
                            style={{ width: "100%" }}
                            name="status"
                            disableClearable
                            value={
                                data
                                    && data.passwordExpire
                                    && db.passwordExpire
                                    ? db.passwordExpire.filter(a =>
                                        String(a.value) === String(data.passwordExpire)
                                    )[0]
                                    : db.passwordExpire[0]
                            }
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label={translate("$__expiresIn", 1)}
                                    variant={customApp("fieldVariant")}
                                    value={
                                        data
                                            && data.passwordExpire
                                            && db.passwordExpire
                                            ? db.passwordExpire.filter(a =>
                                                String(a.value) === String(data.passwordExpire)
                                            )[0]
                                            : db.passwordExpire.filter(a =>
                                                !a.value
                                            )[0]
                                    }
                                />
                            }
                            onChange={
                                (d, v) => {
                                    setData({
                                        ...data,
                                        passwordExpire: v["value"]
                                    })
                                }
                            }
                        />
                    </fieldset>
                    : <React.Fragment></React.Fragment>
                }
            </fieldset>
            <fieldset>
                <legend>{translate('$__language', 1)}</legend>
                <Autocomplete
                    id="combo-box-demo"
                    options={db.languages}
                    getOptionLabel={(option) => translate(option.label, 1)}
                    style={{ width: "100%" }}
                    name="status"
                    disableClearable
                    value={data
                        && data.language
                        && db.languages
                        && db.languages.filter(a =>
                            String(a.value) === String(data.language)
                        ).length > 0
                        ? db.languages.filter(a =>
                            String(a.value) === String(data.language)
                        )[0]
                        : db.languages[0]
                    }
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={translate("$__language", 1)}
                            variant={customApp("fieldVariant")}
                            value={data
                                && data.language
                                && db.languages
                                && db.languages.filter(a =>
                                    String(a.value) === String(data.language)
                                ).length > 0
                                ? db.languages.filter(a =>
                                    String(a.value) === String(data.language)
                                )[0]
                                : db.languages[0]
                            }
                        />
                    }
                    onChange={
                        (d, v) => {
                            setData({
                                ...data,
                                language: v["value"]
                            })
                        }
                    }
                />
            </fieldset>
            <fieldset>
                <legend>{translate('$__sessionExpire', 1)}</legend>
                <Autocomplete
                    id="combo-box-demo"
                    options={db.expiresIn}
                    getOptionLabel={(option) => translate(option.label, 1)}
                    style={{ width: "100%" }}
                    name="status"
                    disableClearable
                    value={
                        data
                            && data.expiresIn
                            && db.expiresIn
                            ? db.expiresIn.filter(a =>
                                String(a.value) === String(data.expiresIn)
                            )[0]
                            : db.expiresIn.filter(a =>
                                !a.value
                            )[0]
                    }
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label={translate("$__expiresIn", 1)}
                            variant={customApp("fieldVariant")}
                            value={
                                data
                                    && data.expiresIn
                                    && db.expiresIn
                                    ? db.expiresIn.filter(a =>
                                        String(a.value) === String(data.expiresIn)
                                    )[0]
                                    : db.expiresIn.filter(a =>
                                        !a.value
                                    )[0]
                            }
                        />
                    }
                    onChange={
                        (d, v) => {
                            setData({
                                ...data,
                                expiresIn: v["value"]
                            })
                        }
                    }
                />
            </fieldset>
            {JSON.stringify(data) !== JSON.stringify(iniData) ?
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end"
                }}>
                    <CustomButton
                        // shadow
                        title={translate("$__save")}
                        text={translate("$__save")}
                        color="green"
                        // tr
                        icon={"save"}
                        size={"25px"}
                        onClick={() => {
                            saveData()
                            // setEditor(false)
                        }}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div >
    )
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(Component)
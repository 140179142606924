import React from 'react'
import DatePicker, { registerLocale } from "react-datepicker"
import pt from "date-fns/locale/pt"// the locale you want

//COMPONENTS
import Icon from "components/Icon"
import CustomButton from "components/Buttons/custom"

//STYLES
import "assets/css/components/react-datepicker.css";
//APIMS
import { translate, customApp } from 'functions';

import "./index.css";


function DatePickerComponent(props) {
    registerLocale("pt", pt); // register it with the name you want
    return (
        <div style={{
            width: '100%',
            ...props.horizontal ?
                {
                    display: "flex",
                    alignItems: "center"
                }
                : {},
            ...props.style ? props.style : {},
            justifyContent: "center"
        }}>
            <div style={{
                ...props.horizontal ? { width: props.onClear ? "calc(50% - 11px)" : "" } : {}
            }}>
                <DatePicker
                    locale="pt"
                    placeholderText={props.placeholder ? props.placeholder : translate("$__pleaseSelectStartDate")}
                    selected={props.startDate ? props.startDate : null}
                    onChange={props.onChangeStartDate ? (x) => {
                        props.onChangeStartDate(x)
                    } : null}
                    selectsStart
                    startDate={props.startDate ? props.startDate : null}
                    endDate={props.endDate ? props.endDate : null}
                    maxDate={!props.range && props.maxDate ? props.maxDate : null}
                    {...props.type && props.type === "datetime" ? {
                        timeInputLabel: translate("$__time"),
                        dateFormat: "dd/MM/yyyy HH:mm",
                        showTimeInput: true
                    } : {
                        dateFormat: "dd/MM/yyyy",
                    }}
                    customInput={(<CustomInput />)}
                    showYearDropdown
                />
            </div>
            {props.range ?
                <div
                    style={{
                        ...props.horizontal ? { marginLeft: 7, width: props.onClear ? "calc(50% - 18px)" : "calc(50% - 7px)" } : { marginTop: 7 },
                    }}
                >
                    <DatePicker
                        locale="pt"
                        placeholderText={translate("$__pleaseSelectEndDate")}
                        selected={props.endDate ? props.endDate : null}
                        onChange={props.onChangeEndDate ? (x) => {
                            props.onChangeEndDate(x)
                        } : null}
                        selectsEnd
                        startDate={props.startDate ? props.startDate : null}
                        endDate={props.endDate ? props.endDate : null}
                        minDate={props.minDate ? props.minDate : props.range && props.startDate ? props.startDate : null}
                        {...props.type && props.type === "datetime" ? {
                            timeInputLabel: translate("$__time"),
                            dateFormat: "dd/MM/yyyy HH:mm",
                            showTimeInput: true
                        } : {
                            dateFormat: "dd/MM/yyyy",
                        }}
                        maxDate={props.maxDate ? props.maxDate : null}
                        customInput={(<CustomInput />)}
                        showYearDropdown
                    // monthsShown={2}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
            {props.onClear && (props.startDate || props.endDate) ?
                <div
                    style={{
                        display: "flex",
                        justifyContent: props.clearButtonPosition ? props.clearButtonPosition : "center",
                        ...props.horizontal ? {

                        } : {
                            paddingTop: 3,
                        }
                    }}
                >
                    <CustomButton
                        color={customApp('lightgray')}
                        icon={'clear'}
                        onClick={props.onClear}
                        size={'15px'}
                        {...props.horizontal ? {
                        } : {

                            title: translate(`$__clearDate`, '*'),
                            text: translate(`$__clearDate`, '*')
                        }}
                    />
                </div>
                : <React.Fragment></React.Fragment>
            }
        </div>
    );
}

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <div style={{
            display: "flex",
            width: "100%",
            padding: "7px 7px",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: 1.5,
            color: "#212529",
            backgroundColor: "#fff",
            border: "1px solid #ced4da",
            borderRadius: 3,
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            appearance: 'none',
            justifyContent: 'space-between'
        }}
            onClick={props.onClick}
        >
            <label
                onClick={props.onClick}
                ref={ref}
                style={{ marginRight: 7, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
                {props.value || props.placeholder}
            </label>
            <Icon
                size={"22px"}
                color={customApp('color')}
                icon={'today'}
                onClick={props.onClick}
            />
        </div>
    );
});
export default DatePickerComponent
import React from "react";

//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as reduxActions from 'store/actions';

//COMPONENTS
import LinearProgress from "components/Progress/linear";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Message from "../message";

// @MATERIAL
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/search";

import apims from "apims/";

function SearchComponent(props) {
  const [lastChange, setLastChange] = React.useState("")
  const [loader, setLoader] = React.useState(false)
  const [filteredItems, setfilteredItems] = React.useState([]);

  let hashs = window.location.hash.split('/')
  hashs[0] = hashs[0].replace('#', '')

  React.useEffect(() => {
    if (props.text.length > 1 && props.text !== lastChange) {
      setLastChange(props.text)
      const timer = setTimeout(() => searchMessages(), 1000);
      setLoader(true)
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.text])

  const searchMessages = async () => {
    try {
      console.log({
        text: props.text.trim(),
        nodeId: props.nodeId,
        db: props.db,
        type: "comment"
      })
      let req = await apims.post(`/M_Timeline_Search`,
        {
          text: props.text.trim(),
          nodeId: props.nodeId,
          db: props.db,
          type: "comment"
        }
      )
      if (req && req.data) {
        if (props.onFinish)
          props.onFinish()
        setfilteredItems(req.data)
        setLoader(false)
      }
    } catch (e) {
      console.log("/SEARCH/MESSAGES/SearchMessages():::", e)
    }
  }

  return (
    <div
      style={{ width: "100%", maxHeight: '70vh' }}
    >
      <div style={{ positin: 'relative', width: '100%' }}>
        {loader &&
          <div style={{ display: "flex", alignItems: "center" }}>
            <LinearProgress />
          </div>
        }
        <GridContainer>
          {Object.keys(filteredItems).length > 0 &&
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              {Object.keys(filteredItems)
                .map((message, i) => {
                  return (
                    <div
                      key={message}
                      style={{ position: "relative", float: "left", width: 'calc(100% - 20px)', marginLeft: '10px', marginRight: '10px', marginBottom: '22px', clear: 'both' }}
                    >
                      <Message
                        messageClick={props.onAction ? () => {
                          // props.onAction(filteredItems[message])
                        } : null}
                        searchText={props.text.trim()}
                        storie={filteredItems[message]}
                        hideCards={props.hideCards ? true : false}
                        db={props.db}
                        nodeId={props.nodeId}
                      />
                    </div>
                  )
                })}
            </GridItem>
          }
        </GridContainer>
      </div>
    </div >
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch);

//EXPORT
export default connect(null, mapDispatchToProps)(withStyles(styles)(SearchComponent))
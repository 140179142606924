//FUNCTIONS
import { customApp } from "functions/";

const style = theme => ({
    root: {
        transform: 'translateZ(0px)',
        flexGrow: 1,
        zIndex: 1000,
        height: '100vh'
    },
    speedDial: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        '& .MuiFab-root': {
            backgroundColor: customApp('color'),
            color: customApp('lightText'),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }
    },
    speedDialInvisible: {
        //position: 'relative',
        //float: 'right',
        //bottom: theme.spacing(2),
        right: theme.spacing(2),
        bottom: '-60px',
        position: 'absolute',
        '& .MuiFab-root': {
            backgroundColor: customApp('color'),
            color: customApp('lightText'),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& .MuiButtonBase-root': {
            //display: 'none'
        }
    }
})

export default style;
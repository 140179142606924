import React, { useState } from 'react'
import { Icon, Chip, InputLabel, FormControl, makeStyles, Input, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  TextField:{
    color: 'rgb(150,150,150)',
    width: '100%',
    padding: '0',
    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    fontSize:'14px',
    '& label':{
      marginTop: '5px',
      fontSize:'14px',
    },
    '& .Mui-focused':{
      color: 'rgb(150,150,150)',
    },
    '& .MuiInput-underline:after':{
      border: '1px solid rgb(192,178,131)',
    },
    '& .MuiInput-underline:before:before':{
      borderBottom: '1px solid rgb(192,178,131)',
    },
    
  },
})
const ListNotes = ({menuNotes, notes, setNotes, addNote, handleChangeHidden}) => {
  const style = useStyles();

    function HtmlAsText({ html }) {
      return <div dangerouslySetInnerHTML={{ __html: html }} />;
    }
    const [filter, setFilter] = useState({
      text: '',
      tags: [],
    })
    function handleClickDelete(deleteNote){
      setNotes(notes.filter((note) => note !== deleteNote))
    }
    function convertDate(date){
      const today = new Date();
      if(date){
        if(date?.getDate() === today.getDate() && date?.getMonth() === today.getMonth() && date?.getYear() === today.getYear()){
          return `${("0" + date?.getHours()).slice(-2)}:${("0" + date?.getMinutes()).slice(-2)}`;
        }else{
          return `${("0" + date?.getDate()).slice(-2)}/${("0" + (date?.getMonth() + 1)).slice(-2)} - ${("0" + date?.getHours()).slice(-2)}:${("0" + date?.getMinutes()).slice(-2)}`;
        }
      }else{
        return "";
      }
    }

    const tags = ["Weekly", "Daily", "Yearly"]
  return (
    <>
      {menuNotes.list ? (
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "300px",
            zIndex: "998",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
            color: "#000000CC",
            height: "100%",
            borderLeft: "3px solid rgb(192,178,131)",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              width: "85%",
            }}
          >
            <h2 style={{marginBottom: '0'}}>Anotações</h2>
            <Icon style={{ cursor: "pointer" }} onClick={addNote}>
              add
            </Icon>
          </div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "flex-end",
              width: "85%",
            }}
          >
            <FormControl className={style.TextField} variant="standard">
              <InputLabel htmlFor="titleForm" className={style.TextField}>
                Buscar
              </InputLabel>
              <Input
                id="titleForm"
                type={"text"}
                onChange={(e) => setFilter({ ...filter, text: e.target.value })}
              />
            </FormControl>
            <Autocomplete
              className={style.TextField}
              multiple
              id="tags-filled"
              options={tags.map((option) => option)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                  />
                ))
              }
              onChange={(event, newValue) => {
                setFilter({
                  ...filter,
                  tags: newValue,
                });
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Etiquetas" size="small"/>
              )}
            />
          </div>

          {notes
            .filter((note) =>
              (filter.text === "" || note?.data?.message.includes(filter.text)) &&
              (filter.tags.length === 0 || filter.tags.some((tag) => note?.tags.includes(tag)))
            )
            .map((note, index) => (
              <div
                key={index}
                style={{
                  width: "85%",
                  boxShadow: "0 0px 5px 0 rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: "#bcbcbc",
                  }}
                >
                  <Icon style={{ cursor: "pointer", fontSize: "1.2rem" }}>
                    note
                  </Icon>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p style={{
                      fontSize:'0.8rem',
                      margin:'0'
                    }}>{convertDate(note?.date)}</p>
                    <Icon
                      style={{
                        cursor: "pointer",
                        fontSize: "1.2rem",
                      }}
                      onClick={() =>
                        handleChangeHidden(!note.hidden, index)
                      }
                    >
                      {note.hidden ? "visibility" : "visibility_off"}
                    </Icon>
                    <Icon style={{ 
                      cursor: "pointer", 
                      fontSize: "1.2rem" 
                    }}
                    onClick={() => handleClickDelete(note)}>
                      delete
                    </Icon>
                  </div>
                </div>
                {!note?.data?.message ||
                note?.data?.message === "" ||
                note?.data?.message === null ? (
                  <p style={{ color: "#bcbcbc", fontSize: "0.9rem" }}>
                    Escreva uma anotação...
                  </p>
                ) : (
                  <HtmlAsText html={note?.data?.message} />
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  {note?.tags.map((option, index) => (
                    <Chip label={option} key={index} size="small" />
                  ))}
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </>
  );
}

export default ListNotes
import React from "react";
import Routes from "routes";

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTS
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Icon from "components/Icon";
import IconButton from "components/CustomButtons/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

//@MATERIAL
import { makeStyles } from "@material-ui/core/styles";

//IMAGES
import defaultAvatar from "assets/img/fundo-sonne.png"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/filter";

//FUNCTIONS
import {
    customApp,
    translate,
} from "functions/";

//VARIABLES
import { initialState } from "store/search-initialState";

const useStyles = makeStyles(styles);
function FilterComponent(props) {
    const classes = useStyles();
    const { session, search, db } = reduxStore.getState();

    let fields = []

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    const locationPathName = window.location.pathname.split('/')
    const pathName = `/${locationPathName[1]}`

    let CardAccess = 1
    if (hashs[0]) {
        let data = db.cards[hashs[0]] || {}
        CardAccess = data && data._users && Object.keys(data._users).length > 0 ? data._users[Object.keys(data._users).find(a => a.indexOf(session._id) > -1)] : 0
        if (CardAccess && CardAccess.access)
            CardAccess = CardAccess.access
    }


    if (hashs[1] && Routes.filter(a => a.path === pathName).length > 0 && Routes.filter(a => a.path === pathName)[0].tabs && Routes.filter(a => a.path === pathName)[0].tabs.length > 0) {
        let tabs = Routes.filter(a => a.path === pathName)[0].tabs && Routes.filter(a => a.path === pathName)[0].tabs
        tabs.forEach(a => {
            if (a.path === hashs[1])
                fields = a.searchFields
        })
    } else if (Routes.filter(a => a.path === pathName).length > 0 && Routes.filter(a => a.path === pathName)[0].searchFields) {
        fields = Routes.filter(a => a.path === pathName)[0].searchFields
    }

    if (JSON.stringify(initialState) === JSON.stringify(search)) {
        return (<React.Fragment></React.Fragment>)
    }

    return (
        <div>
            <GridContainer>
                <GridItem
                    xs={12}
                    sm={9}
                    md={9}
                    lg={9}
                    xl={9}
                >
                    <span style={{
                        color: customApp('medium'),
                        fontWeight: 'bold',
                        fontSize: '18px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <Icon size="18px">search</Icon>
                        {translate('$__appliedFilters')}
                    </span>
                </GridItem>
                <GridItem
                    xs={12}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: 'flex-end'
                    }}>
                        <IconButton reverse icon={'clear_all'} text={translate("$__clearFilter", "*")}
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SEARCH_CLEAR")
                            }} />
                    </div>
                </GridItem>
                {fields && fields.indexOf('text') > -1 && search.text &&
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div className={classes.textField} style={{ width: "100%" }}>
                            {translate('$__text')}: {search.text}
                        </div>
                    </GridItem>
                }

                {/* FILTER BY TAGS */}

                {/* FILTER BY STATUS */}
                {fields && fields.indexOf('status') > -1 && JSON.stringify(search.cards.status) !== JSON.stringify(initialState.cards.status) &&
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div style={{ color: customApp('color'), fontSize: '16px' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                }}>
                                {translate('$__status')}
                            </div>
                            {Object.keys(search.cards.status)
                                .filter((a, i) => search.cards.status[a] && a !== 'all')
                                .map((a, i) => {
                                    return (
                                        <Tooltip
                                            title={translate(db.status.filter(fil => fil.value === a)[0].label, 1)}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '11px',
                                                    height: '11px',
                                                    borderRadius: '50%',
                                                    backgroundColor: db.status.filter(fil => fil.value === a)[0].color,
                                                    margin: '7px 3px',
                                                }}></div>
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </GridItem>
                }

                {/* FILTER BY PRIORITY */}
                {fields && fields.indexOf('priority') > -1 && JSON.stringify(search.cards.priority) !== JSON.stringify(initialState.cards.priority) &&
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div style={{ color: customApp('color'), fontSize: '16px' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    float: 'left',
                                }}>
                                {translate('$__priority')}
                            </div>
                            {Object.keys(search.cards.priority)
                                .filter((a, i) => search.cards.priority[a] && a !== 'all')
                                .map((a, i) => {
                                    return (
                                        <Tooltip
                                            title={translate(db.priority.filter(fil => String(fil.value) === String(a))[0].label, 1)}
                                            key={i}
                                        >
                                            <div
                                                style={{
                                                    position: 'relative',
                                                    float: 'left',
                                                    width: '11px',
                                                    height: '11px',
                                                    borderRadius: '50%',
                                                    backgroundColor: db.priority.filter(fil => String(fil.value) === String(a))[0].color,
                                                    margin: '7px 3px',
                                                }}></div>
                                        </Tooltip>
                                    )
                                })}
                        </div>
                    </GridItem>
                }

                {/* FILTER BY USER */}
                {fields && fields.indexOf('users') > -1 && JSON.stringify(search.users) !== JSON.stringify(initialState.users) &&
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div style={{ color: customApp('color'), fontSize: '16px' }}>
                            {translate('$__users')}
                            <AvatarGroup max={6}>
                                {Object.keys(reduxStore.getState().search.users.selected).length > 0 && Object.keys(reduxStore.getState().search.users.selected).map((a, i) => (
                                    a !== 'unassigned' && reduxStore.getState().search.users.selected[a] ?
                                        <Tooltip
                                            title={db.users[a].name}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={db.users[a].name} src={db.users[a].image || null} >
                                                {db.users[a].name.substr(0, 1)}
                                            </Avatar>
                                        </Tooltip>
                                        : a === 'unassigned' && reduxStore.getState().search.users.selected[a] &&
                                        <Tooltip
                                            title={translate('$__unassigned', 1)}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={translate('$__unassigned', 1)} src={defaultAvatar} >
                                                {translate('$__unassigned', 1)}
                                            </Avatar>
                                        </Tooltip>
                                ))}
                            </AvatarGroup>
                        </div>
                    </GridItem>
                }
                {fields && fields.indexOf('units') > -1 && JSON.stringify(search.units) !== JSON.stringify(initialState.units) &&
                    Object.keys(db.users).filter(a => db.users[a].type !== 'user').length > 0 && fields.indexOf('units') > -1 &&
                    /* FILTER BY CLIENT */
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div style={{ color: customApp('color'), fontSize: '16px' }}>
                            {translate('$__units')}
                            <AvatarGroup max={6}>
                                {Object.keys(reduxStore.getState().search.units.selected).length > 0 && Object.keys(reduxStore.getState().search.units.selected).map((a, i) => (
                                    a !== 'unassigned' && reduxStore.getState().search.units.selected[a] ?
                                        <Tooltip
                                            title={db.users[a].name}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={db.users[a].name} src={db.users[a].image || null} >
                                                {db.users[a].name.substr(0, 1)}
                                            </Avatar>
                                        </Tooltip>
                                        : a === 'unassigned' && reduxStore.getState().search.units.selected[a] &&
                                        <Tooltip
                                            title={translate('$__unassigned', 1)}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={translate('$__unassigned', 1)} src={defaultAvatar} >
                                                {translate('$__unassigned', 1)}
                                            </Avatar>
                                        </Tooltip>
                                ))}
                            </AvatarGroup>
                        </div>
                    </GridItem>
                }

                {
                    reduxStore.getState().search.plans && reduxStore.getState().search.plans.selected && Object.keys(reduxStore.getState().search.plans.selected).length > 0 && Object.keys(db.cards).filter(a => db.cards[a].type === 'plan').length > 0 && fields && fields.indexOf('plans') > -1 &&
                    /* FILTER BY PLANS */
                    <GridItem
                        xs={6}
                        sm={4}
                        md={2}
                        lg={2}
                        xl={2}
                    >
                        <div style={{ color: customApp('color'), fontSize: '16px' }}>
                            {translate('$__plans')}
                            <AvatarGroup max={6}>
                                {Object.keys(reduxStore.getState().search.plans.selected).length > 0 && Object.keys(reduxStore.getState().search.plans.selected).map((a, i) => (
                                    a !== 'unassigned' && reduxStore.getState().search.plans.selected[a] ?
                                        <Tooltip
                                            title={db.cards[a].name}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={db.cards[a].name} src={db.cards[a].image || null} >
                                                {db.cards[a].name.substr(0, 1)}
                                            </Avatar>
                                        </Tooltip>
                                        : a === 'unassigned' && reduxStore.getState().search.plans.selected[a] &&
                                        <Tooltip
                                            title={translate('$__unassigned', 1)}
                                            key={i}
                                        >
                                            <Avatar style={{ width: '30px', height: '30px' }} alt={translate('$__unassigned', 1)} src={defaultAvatar} >
                                                {translate('$__unassigned', 1)}
                                            </Avatar>
                                        </Tooltip>
                                ))}
                            </AvatarGroup>
                        </div>
                    </GridItem>
                }
                {/* FINAL FILTER PARAMETERS */}
                <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                >
                    {
                        //Cards
                    }
                </GridItem>
            </GridContainer >
        </div>
    );
}
const mapStateToProps = (store, ownProps) => ({
    store, ownProps
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent)
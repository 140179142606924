import React, { memo, useRef, useEffect } from "react";

//COMPONENTS
import Icon from "components/Icon/";

//@MATERIAL
import Avatar from "@material-ui/core/Avatar";
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { translate, customApp } from "functions/";


const useStyles = makeStyles((props) => ({
    iconButtonStyleTransaparent: {
        padding: "3px",
        margin: 0,
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        },
        ...props.buttonStyle ? props.buttonStyle : {}
    },
    iconButtonStyleWhite: {
        padding: "3px",
        margin: 0,
        backgroundColor: '#FFFFFF',
        '&:hover': {
            backgroundColor: 'rgba(250,250,250,0.9)'
        },
        ...props.buttonStyle ? props.buttonStyle : {}
    },
}));

function Comp(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null)

    const componentRef = useRef()

    useEffect(() => {
        componentRef.current = {
            mounted: true
        }

        return () => {
            if (componentRef && componentRef.current && componentRef.current.mounted)
                componentRef.current.mounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClose = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setTimeout(() => {
            if (componentRef?.current?.mounted)
                setOpen(false);
            if (props.onClose)
                props.onClose()
        }, 150)
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            if (componentRef?.current?.mounted)
                setOpen(false);
            if (props.onClose)
                props.onClose()
        }
    }

    if (!props.options || props.options.length === 0)
        return (<React.Fragment></React.Fragment>)

    return (
        <div style={{ zIndex: "100 !important", ...props.style ? props.style : {} }}
            className={open ? "ignoreCardClickAway" : ""}
        >
            <Tooltip
                title={props.title ? props.title : translate(`$__options`, 1)}
            >
                {!props.text ?

                    <IconButton
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        aria-label="menu"
                        onClick={(e) => {
                            if (componentRef?.current?.mounted)
                                setOpen((prevOpen) => !prevOpen);
                            if (!open && props.onOpen)
                                props.onOpen()
                            if (open && props.onClose)
                                props.onClose()
                            e.stopPropagation()
                        }}
                        className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                    >
                        <Icon
                            count={props.count ? props.count : null}
                            usePropagation
                            size={!props.size ? "20px" : props.size}
                            color={props.btColor ? props.btColor : customApp('menu')}
                            icon={props.icon ? props.icon : !props.horizontalButton ? 'more_vert' : 'more_horiz'}
                        />
                    </IconButton>
                    :
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        aria-label="menu"
                        onClick={(e) => {
                            if (componentRef?.current?.mounted)
                                setOpen((prevOpen) => !prevOpen);
                            if (!open && props.onOpen)
                                props.onOpen()
                            if (open && props.onClose)
                                props.onClose()
                            e.stopPropagation()
                        }}
                        className={!props.transparent ? classes.iconButtonStyleWhite : classes.iconButtonStyleTransaparent}
                    >
                        <React.Fragment>
                            <span
                                style={{
                                    marginRight: '15px',
                                    color: props.textColor ? props.textColor : props.color ? props.color : customApp('menu'),
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "90%",
                                    maxWidth: props.maxWidth ? props.maxWidth : "90%",
                                    whiteSpace: "nowrap"
                                }}>{props.text}</span>
                            <Icon
                                count={props.count ? props.count : null}
                                usePropagation
                                size={!props.size ? "20px" : props.size}
                                color={props.btColor ? props.btColor : customApp('menu')}
                                icon={props.icon ? props.icon : !props.horizontalButton ? 'more_vert' : 'more_horiz'}
                            />
                        </React.Fragment>
                    </Button>
                }
            </Tooltip >
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                style={{ zIndex: "100 !important" }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right center',
                            zIndex: "100 !important"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    style={{ zIndex: "100 !important" }}
                                >
                                    {props.options
                                        .filter(a => a && !a.hide)
                                        .map((a, i) =>
                                            <MenuItem
                                                key={i}
                                                style={{
                                                    color: a && a.color ? a.color : customApp('menu'),
                                                    padding: "12px 7px",

                                                }}
                                                className={"ignoreClickAway"}
                                                onClick={(e) => {
                                                    if (!props.forceStopPropagation)
                                                        handleClose(e)
                                                    if (a.onClick)
                                                        a.onClick()
                                                    if (props.onClose)
                                                        props.onClose()
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                                disabled={a.disabled ? true : false}
                                            >
                                                <div style={{
                                                    pointerEvents: 'none'
                                                }}>
                                                    {a.render ?
                                                        a.render
                                                        : a.avatar ?
                                                            <Avatar
                                                                alt={a.name} src={a.avatar || null}
                                                                style={{
                                                                    width: !props.size ? "20px" : props.size,
                                                                    height: !props.size ? "20px" : props.size
                                                                }}>
                                                                {a.name.substr(0, 1)}
                                                            </Avatar>
                                                            :
                                                            <Badge
                                                                overlap="rectangular"
                                                                badgeContent={a.count ? a.count : null}
                                                                color="secondary"
                                                            >
                                                                <Icon
                                                                    usePropagation
                                                                    icon={a.icon}
                                                                    style={{ margin: 'none !important' }}
                                                                    color={a.iconColor ? a.iconColor : a.color ? a.color : customApp('menu')} />
                                                            </Badge>
                                                    }
                                                    {!a.render &&
                                                        <span style={{ marginLeft: '7px' }}>
                                                            {translate(a.name, 1)}
                                                        </span>
                                                    }
                                                </div>
                                            </MenuItem>
                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}
export default memo(Comp);
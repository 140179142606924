import React, { memo } from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CreditCard from "components/Account/creditCard"


function App(props) {
    //SANDBOX
    //GALAX Id 5473
    //GALAX Hash 83Mw5u8988Qj6fZqS4Z8K7LzOo1j28S706R0BeFe
    const test = async () => {
        const { socket } = reduxStore.getState().functions

        socket.emit("data", {
            module: "galaxPay",
            method: "post",
            action: "integration"
        }, {
            action: "reqData",
            accountData: reduxStore.getState().session.GlobalData
        }, response => {
            console.log(response)
            if (response.error) {
                console.log("companyDataError", response.error)
                console.log(response)
            }
        })
    }
    return (
        <div>
            <CreditCard />
            <div style={{ padding: 15, backgroundColor: "black" }} onClick={() => { test() }}>TEST</div>
        </div >
    )
}

const mapStateToProps = (store) => ({
    history: store.db.history
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(memo(App))
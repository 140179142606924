import React from 'react'
import MD5 from "crypto-js/md5"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'

//COMPONENTES
import Forms from "componentsV3/Forms/edit"
import Filter from "componentsV3/Filter/menuFilter"

//FUNCTIONS
import { translate } from "functions/";
import { Paper } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import ButtonOptions from 'componentsV3/Buttons'
import GridContainer from 'components/Grid/GridContainer'

function FormsView(props) {
    const [search, setSearch] = React.useState({})
    const [edit, setEdit] = React.useState({})
    const searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    grid: 5,

                }}
            >
                <Filter
                    nodeId={searchID}
                    fields={['text']}
                    showFilters
                    style={{ marginLeft: 7 }}
                    onChange={(e) => {
                        setSearch(e)
                    }}
                    title={{
                        text: translate("$__formName")
                    }}
                />
                <ButtonOptions
                    text={translate("$__newForm")}
                    icon={"add"}
                    onClick={() => {
                        setEdit({ new: true })
                    }}
                />
            </div>
            <GridContainer
                style={{
                    margin: 0,
                    marginTop: 33,
                    padding: 0,
                    marginLeft: -15,
                }}
            >
                {props.forms && Object.keys(props.forms)
                    .filter(fil => !search || !search.text || (search && search.text && props.forms[fil] && props.forms[fil].name.toLowerCase().indexOf(search.text.toLowerCase()) > -1))
                    .map((f, i) => (
                        <GridItem
                            xs={12}
                            sm={12}
                            md={3}
                            lg={3}
                            xl={2}
                            key={`${f}-${i}`}
                        >
                            <Paper style={{ marginBottom: 15 }}>
                                <div
                                    style={{
                                        padding: 7,
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        setEdit(props.forms[f])
                                    }}
                                >
                                    <div style={{ fontSize: "18px", color: "gray", fontWeight: "bold" }}>{props.forms[f].name}</div>
                                    <div style={{ fontSize: "12px", color: "gray", marginTop: 15 }}>{translate("$__items")}: {JSON.parse(props.forms[f].elements).length}</div>
                                </div>
                            </Paper>
                        </GridItem>
                    ))}

            </GridContainer>
            {Object.keys(edit).length > 0 ?
                <Forms
                    data={edit}
                    onClose={() => {
                        setEdit({})
                    }}
                />
                : <></>}
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    forms: store.forms,
    // page: store.db.history
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormsView)
const cropperStyle = () => ({
  root: {

  },
  viewer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  image: {
    width: '100%',
    // backgroundColor: 'rgba(60,60,60)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  avatarImage: {
    marginTop: '7px',
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    boxShadow: '0px 0px 7px 7px rgba(0,0,0,0.2)'
  },
  addImage: {
    width: '100%',
    // backgroundColor: 'rgba(60,60,60,0.1)',
    padding: '15px',
    '&:hover': {
      // backgroundColor: 'rgba(60,60,60,0.3)',
    }
  },
  cropper: {
    overflow: 'hidden',
    '& .cropper-container': {
      width: '100% !important',
      overflow: 'hidden !important'
    }
  },
  circleCropper: {
    overflow: 'hidden',
    '& .cropper-container': {
      width: '100% !important',
      overflow: 'hidden !important'
    },
    '& .cropper-view-box': {
      borderRadius: '50%'
    }
  },
});

export default cropperStyle;

//CORE
import React from 'react';

//REDUX
import * as reduxActions from "store/actions";
import reduxStore from "store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//COMPONENTS
import Icon from "components/Icon";
import Card from "components/Card"
import Approve from "components/Card/approval"

//@MATERIAL
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';


//FUNCTIONS
import { translate, customApp } from 'functions/';

function RequireApproval(props) {
    const { db, session } = reduxStore.getState()
    const [open, setOpen] = React.useState(false);
    const [approvals, setApprovals] = React.useState([])
    const anchorRef = React.useRef(null)

    const cards = Object.keys(reduxStore.getState().db.cards).filter(a => {
        if (
            db.cards[a]
            && !db.cards[a].deleted
            && db.cards[a].type !== "chatGroup"
            && db.cards[a]._parent
            && reduxStore.getState().db.cards[reduxStore.getState().db.cards[a]._parent]
            && reduxStore.getState().db.cards[reduxStore.getState().db.cards[a]._parent].requireApproval
            && (
                (
                    reduxStore.getState().db.cards[reduxStore.getState().db.cards[a]._parent]._users[session._id]
                    && reduxStore.getState().db.cards[reduxStore.getState().db.cards[a]._parent]._users[session._id].approvalResponsible
                )
                ||
                (
                    db.cards[a]._users
                    && Object.keys(db.cards[a]._users).length === 1
                    && db.cards[a]._users[session._id]
                )
            )
        ) return true
        return false
    }).map(a => db.cards[a])

    React.useEffect(() => {
        if (cards.length === 0)
            setOpen(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.cards])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    if (cards.length === 0)
        return (<React.Fragment></React.Fragment>)

    return (
        <div id="notificationsApprovalBt"
            style={{
                zIndex: '100 !important'
            }}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge
                    overlap="rectangular"
                    badgeContent={cards?.filter(fil => fil.status !== 'completed').length}
                    color="secondary"
                >
                    <Icon
                        icon={'approval'}
                        color={'parent'}
                        title="$__forApproval"
                    />
                </Badge>
            </Button>
            {cards.length > 0 &&
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal
                    style={{ width: '400px' }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                padding: 11,
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div style={{
                                        height: 'auto',
                                        maxHeight: 'calc(90vh - 80px)',
                                        width: '100%',
                                        overflowY: 'auto'
                                    }}>
                                        <div
                                            style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: 14
                                            }}
                                        >{translate("$__attention", 1)}: <span style={{ color: customApp('menu') }}>{translate("$__thisActivityRequireApproval", 1)}</span></div>
                                        {cards
                                            .filter(a => approvals.indexOf(a._id) === -1)
                                            .sort((a, b) => {
                                                let au = a.updated_at && a.updated_at.low ? a.updated_at.low : a.updated_at
                                                let bu = b.updated_at && b.updated_at.low ? b.updated_at.low : b.updated_at
                                                if (au < bu)
                                                    return -1
                                                if (au > bu)
                                                    return 1
                                                return 0
                                            })
                                            .map((card, cardIndex) => {
                                                return (
                                                    <div
                                                        key={card._id}
                                                        style={{
                                                            display: "grid",
                                                            width: "100%",
                                                            border: "solid 1px rgba(0,0,0,0.1)",
                                                            padding: 12,
                                                            marginTop: 7,
                                                            borderRadius: 7,
                                                        }}
                                                    >
                                                        <Card
                                                            db="cards"
                                                            cardId={card._id}
                                                            onlyOption={true}
                                                        />
                                                        <Approve nodeId={card._id}
                                                            onAction={() => {
                                                                let newApprovals = Array.from(approvals)
                                                                if (newApprovals.indexOf(card._id) === -1)
                                                                    newApprovals.push(card._id)
                                                                setApprovals(newApprovals)
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            }
        </div >
    )
}

const mapStateToProps = (store, ownProps) => ({
    cards: store.db.cards
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RequireApproval)

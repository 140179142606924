const initialState = {

}

const session = (state = initialState, data) => {
    if (data.action === 'SET_OVERVIEW_NAV' && data.value) {
        return {
            ...data.value,
        }
    }
    return state
}

export default session;
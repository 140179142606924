import $ from "jquery"
import apims from "apims"

export const upload = async (id, fileData, fullData = false, functions) => {
    let file = fileData
    file.originalName = file.name
    var formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("id", id);

    let returnFile
    let send = await new Promise(async (resolve, reject) => {
        try {
            if (functions && functions.onUploadProgress) {
                const { onUploadProgress } = functions
                // apims
                let request = await apims.post("Upload/", formData, {
                    onUploadProgress,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                console.log(request)
                if (request)
                    returnFile = request.data

            } else {
                let request = await apims.post("Upload/", formData, {
                    headers: {
                        "Content-Type": "application/json; multipart/form-data; charset=UTF-8"
                    }
                })
                console.log(request)
                if (request)
                    returnFile = request.data
            }
        } catch (e) {
            if (functions && functions.onUploadError)
                functions.onUploadError(e)
            reject(false)
        } finally {
            resolve(true)
        }

    })

    if (send) {
        if (!fullData) {
            return `https://machen.azureedge.net/${returnFile.file}`
        } else {
            return returnFile
        }
    } else {
        return false
    }
}

export const uploadThumb = async (name, ext, fileData, instalThumb = false) => {
    let file = fileData
    file.originalName = file.name
    var formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("id", name);
    formData.append("ext", ext)
    formData.append("thumb", instalThumb)

    const api = process.env.REACT_APP_WS

    $.ajax({
        method: "POST",
        url: `${api}/upload/thumb/`,
        headers: {
            Authorization: `Bearer ${localStorage.getItem('TOKEN')}`
        },
        cache: false,
        data: formData,
        contentType: false,
        processData: false,
    })
    return true
}
//CORE
import React from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//@MATERIAL
import Autocomplete from "components/AutoComplete/multiple";
import { withStyles } from "@material-ui/core/styles";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/Tags-input";

import {
    translate,
    getAppAccess
} from "functions/";

function Component(props) {
    const [values, setValues] = React.useState([])

    const AppAccess = getAppAccess()

    React.useEffect(() => {
        if (props.data.values) {
            let newValues = []
            props.data.values.forEach(tag => {
                if (reduxStore.getState().db.tag.filter(a => a.value === tag.value).length > 0)
                    newValues.push(tag)
            })
            setValues(newValues)
        }
    }, [props.data])

    const changeTags = async (v) => {
        if (props.callBack)
            props.callBack(v)
        setValues(v)
    }

    return (
        <div>
            <Autocomplete
                dbNode={props.dbNode ? props.dbNode : 'tag'}
                secondaryParent={props.secondaryParent ? props.secondaryParent : null}
                options={reduxStore.getState().db.tag ? reduxStore.getState().db.tag : []}
                style={{ width: '100%' }}
                name="tag"
                ws
                title={translate("$__tags")}
                api={"tag/add"}
                add
                filterSelectedOptions
                colorPicker={AppAccess && AppAccess.plan && (AppAccess.planAdmin || AppAccess.planModerate) ? true : false}
                multiple
                freeSolo
                disableClearable
                disabled={props.disabled ? true : false}
                value={values}
                placeholder={props.placeholder || null}
                onChange={
                    (v) => {
                        changeTags(v)
                    }
                }
                colorPickerCallBack={(res) => {
                    reduxStore.getState().functions.socket.emit('tag.UPD', res)
                }}
            />
        </div>
    )
}

//REACT
const mapStateToProps = (store) => ({
    tags: store.db.tag,
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Component));
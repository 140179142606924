const stepsData = [
    {
        id: "top_user",
        tittle: "$__tour_step1_name",
        text: [
            "$__tour_step1_line1",
            "$__tour_step1_line2",
            "$__tour_step1_line3"
        ]
    },
    {
        id: "chatNotificationsBt",
        tittle: "$__tour_step2_name",
        text: [
            "$__tour_step2_line1",
            "$__tour_step2_line2",
            "$__tour_step2_line3"
        ]
    },
    {
        id: "sideBar_button_dashboard",
        tittle: "$__tour_step3_name",
        text: [
            "$__tour_step3_line1",
            "$__tour_step3_line2",
            "$__tour_step3_line3"
        ]
    },
    {
        id: "sideBar_button_brightAndConnected",
        tittle: "$__tour_step4_name",
        text: [
            "$__tour_step4_line1",
            "$__tour_step4_line2",
            "$__tour_step4_line3"
        ]
    },
    {
        id: "sideBar_button_myTasks",
        tittle: "$__tour_step5_name",
        text: [
            "$__tour_step5_line1",
            "$__tour_step5_line2",
            "$__tour_step5_line3"
        ]
    },
    {
        id: "sideBar_button_library",
        tittle: "$__tour_step6_name",
        text: [
            "$__tour_step6_line1",
            "$__tour_step6_line2",
            "$__tour_step6_line3"
        ]
    },
    {
        id: "sideBar_button_tags",
        tittle: "$__tour_step7_name",
        text: [
            "$__tour_step7_line1",
            "$__tour_step7_line2",
            "$__tour_step7_line3"
        ]
    },
    {
        id: "sideBar_button_calendar",
        tittle: "$__tour_step8_name",
        text: [
            "$__tour_step8_line1",
            "$__tour_step8_line2",
            "$__tour_step8_line3"
        ]
    },
    {
        id: "sideBar_button_StrategyAndPlans",
        tittle: "$__tour_step9_name",
        text: [
            "$__tour_step9_line1",
            "$__tour_step9_line2",
            "$__tour_step9_line3"
        ],
        show: false,
        click: true
    },
    {
        id: "QuickAddBt",
        tittle: "$__tour_step10_name",
        show: false,
        click: true
    },
    {
        id: "Plantype_67",
        tittle: "$__tour_step11_name",
        fake: true,
        buttonId: "intro_45",
        handleClose: "getElements_40",
        click: true,
        position: "left",
        margin: "-10px"
    },
    {
        id: "Plantype_672",
        tittle: "$__tour_step12_name",
        text: [
            "$__tour_step12_line1",
            "$__tour_step12_line2"
        ],
        fake: true,
        handleBack: "getElements_58",
        handleClose: "getElements_40",
        buttonId: "Plantype_110",
        click: true,
        position: "left",
        scale: "0.9",
        margin: "-10px",
        change: true,
        test: true
    },

];

const stepsData2 = [
    {
        id: 'quick_388',
        tittle: "$__tour_step13_name",
        show: false,
        click: true,
        wait: true,
        position: "left",
    },
    {
        id: 'quick_417',
        tittle: "$__tour_step14_name",
        text: [
            "$__tour_step14_line1",
            "$__tour_step14_line2",
            "$__tour_step14_line3"
        ],
        click: true,
        position: 'right'
    },
    {
        id: 'sideBar_subMenu_plan_overview',
        tittle: "$__tour_stepStrategic1_name",
        text: [
            "$__tour_stepStrategic1_line1",
            "$__tour_stepStrategic1_line2",
            "$__tour_stepStrategic1_line3"
        ],
        show: false,
        click: true,
        maskValue: 5,
        position: 'right'
    },
    {
        id: 'Agilev2_Column_552',
        tittle: "$__tour_stepStrategic2_name",
        click: true,
        show: false,
        position: 'top',
        margin: '0px 0px 50px 0px'
    },
    {
        id: 'addCard_321',
        tittle: "$__tour_stepStrategic3_name",
        click: true,
        input: true,
        maskValue: 5
    },
    {
        id: 'addCard_441',
        tittle: "$__tour_stepStrategic4_name",
        click: true,
        show: false,
        maskValue: 0,
        last: true
    }
];

const stepsData3 = [
    {
        id: 'quick_388',
        tittle: "$__tour_step13_name",
        show: false,
        click: true,
        wait: true,
        position: "left",
    },
    {
        id: 'quick_417',
        tittle: "$__tour_step14_name",
        text: [
            "$__tour_step14_line1",
            "$__tour_step14_line2",
            "$__tour_step14_line3"
        ],
        click: true,
        position: 'right'
    },
    {
        id: 'sideBar_subMenu_plan_board',
        tittle: "$__tour_stepFlux1",
        text: [
            "$__tour_stepFlux_line1",
            "$__tour_stepFlux_line2",
            "$__tour_stepFlux_line3"
        ],
        show: false,
        click: true
    },
    {
        id: 'AgileKanbanIndex_256',
        tittle: "$__tour_stepFlux2",
        show: false,
        click: true
    },
    {
        id: 'AgileKanbanIndex_282',
        tittle: "$__tour_stepFlux3",
        show: false,
        click: true
    },
    {
        id: 'AgileKanbanColumn_304',
        tittle: "$__tour_stepFlux4",
        show: false,
        click: true
    },
    {
        id: "addCard_321",
        tittle: "$__tour_stepFlux5",
        click: true,
        input: true,
        maskValue: 5
    },
    {
        id: "addCard_441",
        tittle: "$__tour_stepFlux6",
        click: true,
        show: false,
        maskValue: 0,
        last: true
    }
]

// CRIANDO ALIAS

// $__tour_step1_name
// $__tour_step1

//      tittle: "Configurações da Conta",
//         text: [
//             "Personalize o seu perfil.",
//             "Ajuste as suas preferências.",
//             "Torne a sua conta mais segura."
//         ]

// $__tour_step2_name
// $__tour_step2

//    {
//         id: "chatNotificationsBt",
//         tittle: "Conversas",
//         text: [
//             "Converse diretamente com outros usuários.",
//             "Crie grupos com diversos membros.",
//             "Compartilhe e gerencie arquivos com sua equipe."
//         ]
//     },

// $__tour_step3_name
// $__tour_step3

//    {
//         id: "sideBar_button_dashboard",
//         tittle: "Painel Inicial",
//         text: [
//             "Acesse seus planos favoritos.",
//             "Visualize tarefas sugeridas para o seu dia.",
//             "Confira notícias relevantes para o seu negócio."
//         ]
//     },

// $__tour_step4_name
// $__tour_step4

//   {
//         id: "sideBar_button_brightAndConnected",
//         tittle: "BRIGHT & CONNECTED©",
//         text: [
//             "Reúna diversos portais de notícia e assuntos de seu interesse.",
//             "Salve e compartilhe endereços relevantes.",
//             "Registre ideias e conteúdos importantes."
//         ]
//     },

// $__tour_step5_name
// $__tour_step5

//    {
//         id: "sideBar_button_myTasks",
//         tittle: "Atividades",
//         text: [
//             "Confira todas as suas atividades em um único lugar.",
//             "Aplique filtros para ter uma visualização personalizada.",
//             "Atualize suas tarefas de acordo com o progresso delas."
//         ]
//     },

// $__tour_step6_name
// $__tour_step6

//   {
//         id: "sideBar_button_library",
//         tittle: "Biblioteca",
//         text: [
//             "Acesse todos os arquivos já compartilhados em um único lugar.",
//             "Utilize a pesquisa para encontrar arquivos com facilidade.",
//             "Edite e organize arquivos de forma prática e rápida."
//         ]
//     },


// $__tour_step7_name
// $__tour_step7

//    {
//         id: "sideBar_button_tags",
//         tittle: "Etiquetas",
//         text: [
//             "Crie etiquetas personalizadas para organizar seus conteúdos.",
//             "Agrupe conteúdos seguindo critérios definidos.",
//             "Filtre e encontre com facilidade materiais desejados."
//         ]
//     },

// $__tour_step8_name
// $__tour_step8

//   {
//         id: "sideBar_button_calendar",
//         tittle: "Calendário",
//         text: [
//             "Visualize suas tarefas detalhadas em um calendário interativo.",
//             "Atualize qualquer atividade com facilidade.",
//             "Planeje seus próximos dias sem perder nenhum compromisso."
//         ]
//     },

// $__tour_step9_name
// $__tour_step9

//    {
//         id: "sideBar_button_StrategyAndPlans",
//         tittle: 'Clique em " Planos "',
//         text: [
//             "Faça o seu planejamento customizado ou a partir de um modelo.",
//             "Acesse seus planos por progresso, cliente, privacidade, e muito mais.",
//             "Salve seus planos como modelos para serem reutilizados."
//         ],
//         show: false,
//         click: true
//     },

// $__tour_step10_name
// $__tour_step10

//  {
//         id: "QuickAddBt",
//         tittle: "Clique em + para criar um novo plano",
//         show: false,
//         click: true
//     },

// $__tour_step11_name
// $__tour_step11

//     {
//         id: "Plantype_67",
//         tittle: "Insira o nome do seu plano e clique em próximo",
//         fake: true,
//         buttonId: "intro_45",
//         handleClose: "getElements_40",
//         click: true,
//         position: "left",
//         margin: "-10px"
//     },

// $__tour_step12_name
// $__tour_step12

//  {
//         id: "Plantype_672",
//         tittle: "Selecione o tipo do plano",
//         text: [
//             "Escolha o tipo de plano com base nas suas necessidades.",
//             'Clique em "Criar" para avançar para a próxima etapa do processo.'
//         ],
//         fake: true,
//         handleBack: "getElements_58",
//         handleClose: "getElements_40",
//         buttonId: "Plantype_110",
//         click: true,
//         position:  "left",
//         scale:"0.9",
//         margin: "-10px",
//         change: true,
//         test: true
//     },
// $__tour_step13_name
// $__tour_step13

//     {
//         id: 'quick_388',
//         tittle: 'Clique em "Usuários"',
//         show: false,
//         click: true,
//         wait: true,
//         position: "left",
//     },


// $__tour_step14_name
// $__tour_step14

//    {
//         id: 'quick_417',
//         tittle: 'Adicionar Usuários',
//         text: [
//             'Selecione os usuários que deseja convidar para o plano.',
//             'Atribua responsabilidades para cada usuário participante.',
//             'Remova usuários que devem deixar o plano.'
//         ],
//         click: true,
//         position: 'right'
//     },

// $__tour_stepStrategic1_name
// $__tour_stepStrategic1

//    {
//         id: 'sideBar_subMenu_plan_overview',
//         tittle: 'Clique em "Visão Geral"',
//         text: [
//             'Confira todas as atividades criadas no plano.',
//             'Visualize de forma personalizada a partir do sistema "Kanban".',
//             'Crie novas atividades e estabeleça vínculos com outras relacionadas.'
//         ],
//         show: false,
//         click: true,
//         maskValue: 5,
//         position: 'right'
//     },

// $__tour_stepStrategic2_name
// $__tour_stepStrategic2

//  {
//         id: 'Agilev2_Column_552',
//         tittle: 'Clique em "+ Adicionar" para criar uma nova atividade',
//         click: true,
//         show: false,
//         position: 'top',
//         margin: '0px 0px 50px 0px'
//     },

// $__tour_stepStrategic3_name
// $__tour_stepStrategic3

//     {
//         id: 'addCard_321',
//         tittle: 'Escreva o título da sua atividade',
//         click: true,
//         input: true,
//         maskValue: 5
//     },

// $__tour_stepStrategic4_name
// $__tour_stepStrategic4

//   {
//         id: 'addCard_441',
//         tittle: 'Clique em "Salvar" para concluir.',
//         click: true,
//         show: false,
//         maskValue: 0,
//         last: true
//     }


// $__tour_step13_name
// $__tour_step13

//     {
//         id: 'quick_388',
//         tittle: 'Clique em "Usuários"',
//         show: false,
//         click: true,
//         wait: true,
//         position: "left",
//     },


// $__tour_step14_name
// $__tour_step14

//    {
//         id: 'quick_417',
//         tittle: 'Adicionar Usuários',
//         text: [
//             'Selecione os usuários que deseja convidar para o plano.',
//             'Atribua responsabilidades para cada usuário participante.',
//             'Remova usuários que devem deixar o plano.'
//         ],
//         click: true,
//         position: 'right'
//     },


// $__tour_stepFlux1_name
// $__tour_stepFlux1

//    {
//         id: 'AgileKanbanIndex_256',
//         tittle: 'Clique em adicionar coluna',
//         show: false,
//         click: true
//     },

// $__tour_stepFlux2_name
// $__tour_stepFlux2

//   {
//         id: 'AgileKanbanIndex_282',
//         tittle: 'Escreva o título da sua coluna',
//         show: false,
//         click: true
//     },

// $__tour_stepFlux3_name
// $__tour_stepFlux3

//   {
//         id: 'AgileKanbanColumn_304',
//         tittle: 'Clique em "+ Adicionar" para criar uma nova atividade',
//         show: false,
//         click: true
//     },

// $__tour_stepFlux4_name
// $__tour_stepFlux4

//   {
//         id: "addCard_321",
//         tittle: 'Escreva o título da sua atividade',
//         click: true,
//         input: true,
//         maskValue: 5
//     },

// $__tour_stepFlux5_name
// $__tour_stepFlux5

//     {
//         id: "addCard_441",
//         tittle: 'Clique em "Salvar" para concluir.',
//         click: true,
//         show: false,
//         maskValue: 0,
//         last: true
//     }



export { stepsData, stepsData2, stepsData3 };
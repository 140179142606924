import React, { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer';
import Griditem from 'components/Grid/GridItem'
import DragDrop from './DragDrop';
import { customApp } from 'functions';
import UserAddList from './UserAddList';
import Icon from "components/Icon/"
import archive from './sample.xlsx'
import archiveoptions from './sampleoptions.xlsx'

//COMPONENTS
import { translate } from 'functions';

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"


const ImportUsers = (props) => {
    const [data, setData] = useState([]);
    const [adding, setAdding] = useState(false)

    const tableStyle = {
        borderCollapse: 'collapse',
        fontSize: '0.9em',
        fontFamily: 'sans-serif',
        width: '100%',
        minHeight: '300px',
        height: '100%',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
    };

    const headerCellStyle = {
        padding: '5px 10px',
    };

    const dataRowStyle = {
        borderBottom: '1px solid #dddddd',
    };

    const dataCellStyle = {
        padding: '5px 25px',
        fontWeight: 'bold',
        backgroundColor: '#f2f2f2',
    };

    const handleData = (array) => {
        const lowerCaseArray = array.reduce((acc, obj) => {
            const newObj = Object.entries(obj).reduce((newObjAcc, [key, value]) => {
                // console.log(value)
                newObjAcc[key.toLowerCase().replace(/\s/g, "")] = value.toString().trim();
                return newObjAcc;
            }, {});
            return [...acc, newObj];
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        setData(lowerCaseArray)
    }

    if (!props.importUsers)
        return <></>

    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 3000,
                backgroundColor: "rgba(250,250,250,0.7)",
                backdropFilter: "blur(3px)",
                padding: 33,
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContents: "center"
            }}
        >
            <GridContainer style={{ maxWidth: "1400px", backgroundColor: "#ffffff", borderRadius: '5px', border: "2px solid #dedede", position: 'relative', margin: "auto" }} >
                <Griditem item xs={12} style={{ borderBottom: "1px solid #ebebeb", justifyContent: "center", display: "flex" }} >
                    {data.length > 0 && !adding &&
                        < Icon
                            onClick={() => setData([])}
                            style={{
                                alignSelf: 'left',
                                color: customApp('menu'),
                                left: '25px',
                                top: '25px',
                                position: 'absolute'
                            }} size='30px'>arrow_back</Icon>}
                    {!adding &&
                        <Icon
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    importUsers: false,
                                })
                            }}
                            style={{
                                alignSelf: 'left',
                                color: customApp('menu'),
                                right: '25px',
                                top: '25px',
                                position: 'absolute'
                            }}
                            size='30px'>close</Icon>
                    }
                    <Icon style={{ alignSelf: 'center', color: customApp('menu') }} size='30px'>download</Icon>
                    <p
                        style={{
                            padding: '7px',
                            marginTop: "20px",
                            fontSize: "20px",
                            marginBottom: "20px",
                            width: "240px",
                            color: customApp('menu')
                        }}
                    >
                        {translate("$__importUsers")}
                    </p>
                </Griditem>
                {!data.length > 0 ?
                    <> <Griditem item xs={6} style={{}} >
                        <Griditem item xs={12} style={{ display: 'flex' }} >
                            <Icon style={{ alignSelf: 'center', color: customApp('menu') }} size='30px'>task</Icon>
                            <p
                                style={{
                                    padding: '7px',
                                    marginTop: "20px",
                                    fontSize: "20px",
                                    marginBottom: "10px"
                                }}
                            >
                                {translate("$__beforeImportUser")}
                            </p>
                        </Griditem>
                        <Griditem item xs={12} >
                            <p style={{ alignSelf: "center", marginBottom: "10px" }}>
                                {translate("$__importUsersDescp")}
                                <span style={{ fontWeight: 'bold' }}>"{translate("$__email")}" </span >
                                {translate("$__and")} <span style={{ fontWeight: 'bold' }}>"{translate("$__completeName")}"</span>.
                            </p>
                            <a href={archive} style={{ fontSize: '17px' }} target="_blank" rel="noopener noreferrer"
                                download={"Arquivo-Amostra"}>
                                <p style={{ color: '#478cd2', fontWeight: 'bold' }}>
                                    {translate("$__clickToDownloadXLSX")}
                                </p>
                            </a>
                        </Griditem>
                        <Griditem item xs={12} >
                            <p style={{ alignSelf: "center", marginTop: "10px" }}>
                                {translate("$__importUsersSecondDescp")}
                            </p>
                            <a href={archiveoptions} style={{ fontSize: '16px' }} target="_blank" rel="noopener noreferrer" download={"Arquivo-Amostra-Completo"}>
                                <p style={{ color: '#478cd2', fontWeight: 'bold' }}>
                                    {translate("$__clickToDownloadXLSXOptions")}
                                </p>
                            </a>
                        </Griditem>
                        <Griditem item xs={12} style={{ display: 'flex' }} >
                            <Icon style={{ alignSelf: 'center', color: customApp('menu') }} size='30px'>checklist</Icon>
                            <p
                                style={{
                                    padding: '7px',
                                    marginTop: "20px",
                                    fontSize: "20px",
                                    marginBottom: "20px",
                                    color: customApp('menu')

                                }}
                            >
                                {translate("$__fieldOptions")}
                            </p>
                        </Griditem >
                        <Griditem item xs={12} style={{ display: "flex", justifyContent: "center", marginBottom: "60px" }} >
                            <table style={tableStyle}>
                                <tbody>
                                    <tr style={dataRowStyle}>
                                        <th style={headerCellStyle}>{translate("$__phone")}</th>
                                        <td style={dataCellStyle}> {translate("$__phoneDescpImportUsers")}</td>
                                    </tr>
                                    <tr style={dataRowStyle}>
                                        <th style={headerCellStyle}>{translate("$__branchLine")}</th>
                                        <td style={dataCellStyle}>{translate("$__branchLineDescp")}</td>
                                    </tr>
                                    <tr style={dataRowStyle}>
                                        <th style={headerCellStyle}>{translate("$__observation")}</th>
                                        <td style={dataCellStyle}>{translate("$__observationImportDescp")}</td>
                                    </tr>
                                    <tr style={dataRowStyle}>
                                        <th style={headerCellStyle}>{translate("$__department/group")}</th>
                                        <td style={dataCellStyle}>{translate("$__department/groupDescp")}</td>
                                    </tr>
                                    <tr style={dataRowStyle}>
                                        <th style={headerCellStyle}>{translate("$__showName")}</th>
                                        <td style={dataCellStyle}> {translate("$__showNameDescp")}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </Griditem>
                    </Griditem>

                        <Griditem item xs={6} style={{ border: "1px solid #ebebeb", display: "flex", justifyContent: "center" }} >
                            <DragDrop handleData={handleData} />
                        </Griditem>
                    </> : <UserAddList dataUser={data} resetComponent={() => {
                        setData([])
                        setAdding(false)
                    }}
                        onAdding={(x = false) => { setAdding(x) }} />}
            </GridContainer>
        </div >
    )
}

const mapStateToProps = (store) => ({
    importUsers: store.controls.importUsers
})


const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImportUsers)
//CORE
import React from "react";

//@MATERIAL
import Autocomplete from ".";
import { makeStyles } from "@material-ui/core/styles";

//COMPONENTS
import Chip from 'components/Chip';
import TextField from "components/TextField";
import DarkTextField from "components/TextField/dark";
import ColorPicker from "components/ColorPicker";
import Icon from "@material-ui/core/Icon"

//FUNCTIONS
import {
    textColor,
    translate,
    customApp,
} from "functions/";

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/TextField";

const useStyles = makeStyles(styles);

function Component(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState([]);

    React.useEffect(() => {
        setValues(props.value)
    }, [props.value])

    const deleteThis = (dt) => {
        let newValues = Array.from(values)
        newValues.splice(newValues.findIndex(n => n.value === dt.value), 1)
        setValues(newValues)
        if (props.onChange)
            props.onChange(newValues)
    }

    return (
        <Autocomplete
            className={props.dark ? props.noLabel ? classes.noLabel : classes.labelTop : props.noLabel ? classes.noLabelLight : classes.labelTopLight}
            value={values}
            options={props.options ? props.options
                // .filter(val =>
                //     props.options.filter(a => a.parent === val.value).length === 0
                // )
                .sort((a, b) => {
                    let labelA = `${`${a.parent && props.options.filter(f => f.value === a.parent)[0]?.label.toUpperCase()} ${a?.label.toUpperCase()}` || `${a?.label.toUpperCase()}`}* ${a?.label.toUpperCase()}`
                    let labelB = `${`${b.parent && props.options.filter(f => f.value === b.parent)[0]?.label.toUpperCase()} ${b?.label.toUpperCase()}` || `${b?.label.toUpperCase()}`}* ${b?.label.toUpperCase()}`
                    if (labelA < labelB)
                        return -1
                    if (labelA > labelB)
                        return 1
                    return 0
                }) : []}
            getOptionLabel={(option) => {
                if (option && option.label) {
                    return translate(option.label, 1)
                } else {
                    return ''
                }
            }}
            filterSelectedOptions={props.filterSelectedOptions ? true : false}
            name={`autoComplete_${props.name || ``}`}
            style={{ width: "100%" }}
            multiple={props.multiple || false}
            limitTags={props.multiple ? props.limitTags ? props.limitTags : 2 : null}
            freeSolo={props.freeSolo || false}
            disableClearable={props.disableClearable || false}
            disabled={props.disabled}
            groupBy={props.groupByParent ? (option) => {
                return option.parent || "noParent"
            } : null}
            renderOption={!props.groupByParent ? (option) => {
                return (
                    <div style={{
                        zIndex: 3000,
                        ...option.color ?
                            {
                                paddingLeft: 7,
                                borderLeft: `solid 3px ${option.color}`
                            }
                            : {},
                    }}>
                        {option.label}
                    </div>
                )
            } : null}
            renderGroup={(params) => {
                return (
                    <li key={params.key}>
                        {params.group && props.options.filter(a => a.value === params.group).length > 0 ? <div
                            style={{
                                fontSize: 10,
                                color: customApp("menu"),
                                backgroundColor: params.color || '#b7b7b7',
                                padding: 3
                            }}>{props.options.filter(a => a.value === params.group)[0].label}</div> : <React.Fragment></React.Fragment>}
                        <div
                            style={{
                                ...props.options.filter(a => a.value === params.group).length > 0 && props.options.filter(a => a.value === params.group)[0].color ? {
                                    borderLeft: `solid 2px ${props.options.filter(a => a.value === params.group)[0].color}`
                                } : {}
                            }}
                        >{params.children}</div>
                    </li>
                )
            }}
            renderInput={(params) => {
                return (
                    !props.dark ?
                        <TextField
                            {...params}
                            label={translate(props.placeholder || '$__values', '*')}
                            variant={'standard'}
                            InputProps={{
                                ...params.InputProps,
                                ...props.InputProps,
                            }}
                        />
                        :
                        <DarkTextField
                            {...params}
                            label={translate(props.placeholder || '$__values', '*')}
                            variant={'standard'}
                            InputProps={{
                                ...params.InputProps,
                                ...props.InputProps,
                            }}
                        />
                )
            }}

            renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                    <div key={index}>
                        {props.colorPicker && option && option.label ?
                            <ColorPicker
                                label={option && option.label ? option.label : null}
                                color={option.color}
                                _id={option.value}
                                onChange={(c) => {
                                    let newTag = option
                                    newTag.color = c
                                    if (props.colorPickerCallBack)
                                        props.colorPickerCallBack(newTag)
                                }}
                            >
                                <Chip
                                    label={option && option.label ? option.label : null}
                                    style={{
                                        backgroundColor: option.color || '#b7b7b7',
                                        boxShadow: '0 0 7px 3px rgba(0,0,0,0.05)',
                                        color: textColor(option.color || "#b7b7b7", true)
                                    }}
                                    deleteIcon={<Icon
                                        style={{
                                            color: textColor(option.color || "#b7b7b7", true),
                                            fontSize: "20px"
                                        }}
                                    >delete</Icon>}
                                    onDelete={() => { deleteThis(option) }}
                                />
                            </ColorPicker>
                            : option && option.label ?
                                <Chip
                                    label={option && option.label ? option.label : null}
                                    style={{
                                        backgroundColor: option.color || '#b7b7b7',
                                        boxShadow: '0 0 7px 3px rgba(0,0,0,0.05)',
                                        color: textColor(option.color || "#b7b7b7", true)
                                    }}
                                    deleteIcon={<Icon
                                        style={{
                                            color: textColor(option.color || "#b7b7b7", true),
                                            fontSize: "20px"
                                        }}
                                    >delete</Icon>}
                                    onDelete={() => { deleteThis(option) }}
                                />
                                : <React.Fragment></React.Fragment>
                        }
                    </div>
                ))
            }
            }
            onChange={
                (d, v) => {
                    if (props.onChange)
                        props.onChange(v)
                    let newValues = []
                    if (props.multiple) {
                        v.forEach(nv => {
                            if (nv && nv.label) {
                                newValues.push(nv)
                            } else {
                                newValues.push({
                                    label: nv
                                })
                            }
                        })
                        setValues(newValues)
                    } else {
                        setValues(v)
                    }
                }
            }
            dbNode={props.dbNode ? props.dbNode : 'tag'}
            add={props.add && props.add === true ? true : false}
            inNode={props.inNode ? props.inNode : null}
            secondaryParent={props.secondaryParent ? props.secondaryParent : null}
            ws
            api={props.api ? props.api : null}
        />
    )
}

export default Component
import {
    defaultFont
} from "assets/jss/material-dashboard-pro-react";

//FUNCTIONS
import { customApp } from "functions/";

const textField = {
    labelTop: {
        ...defaultFont,
        padding: "0px",
        width: "100%",
        marginTop: "7px",
        marginBottom: "7px",
        '& .invalid': {
            borderBottom: 'solid 2px red !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: "11px 11px",
            fontSize: "12px",
            color: '#FFFFFF',
        },
        '& label': {
            marginTop: "-10px",
            fontSize: "12px",
            color: customApp('color'), //customApp
        },
        '& label.MuiFormLabel-filled': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : "0px"
        },
        '& label.Mui-focused': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : "0px"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customApp('line'), //customApp
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&.Mui-focused fieldset': {
                borderColor: customApp('line'), //customApp
            },
        },
        '& .MuiFilledInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '0px',
            // padding: "15px",
            '&.Mui-focused': {
                marginTop: '0px',
            },
            '&.MuiFormLabel-filled': {
                marginTop: '0px',
            }
        },
        '& .MuiInputBase-root': {
            marginTop: customApp('fieldVariant') === 'standard' ? "0px" : customApp('fieldVariant') === 'filled' ? "-15px" : "0px",
            borderRadius: customApp('fieldRadius'),
            color: '#FFFFFF',
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
    },
    noLabel: {
        ...defaultFont,
        padding: "0px",
        width: "100%",
        marginTop: "7px",
        marginBottom: "7px",
        '& .invalid': {
            borderBottom: 'solid 2px red !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: "11px 11px",
            fontSize: "12px",
            color: '#FFFFFF',
        },
        '& label': {
            textAlign: "right",
            fontSize: "12px",
            color: customApp('color'), //customApp
        },
        '& label.MuiFormLabel-filled': {
            display: 'none'
        },
        '& label.Mui-focused': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customApp('line'), //customApp
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&.Mui-focused fieldset': {
                borderColor: customApp('line'), //customApp
            },
        },
        '& .MuiFilledInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
        '& .MuiInputLabel-outlined': {
            display: 'none',
            marginTop: '0px',
            '&.Mui-focused': {
                marginTop: '0px',
            },
            '&.MuiFormLabel-filled': {
                marginTop: '0px',
            }
        },
        '& .MuiInputBase-root': {
            borderRadius: customApp('fieldRadius'),
            color: '#FFFFFF',
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
    },
    labelTopLight: {
        ...defaultFont,
        padding: "0px",
        width: "100%",
        marginTop: "7px",
        marginBottom: "7px",
        '& .invalid': {
            borderBottom: 'solid 2px red !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: "11px 11px",
            fontSize: "12px",
            color: '#FFFFFF',
        },
        '& label': {
            marginTop: "-10px",
            fontSize: "12px",
            color: customApp('color'), //customApp
        },
        '& label.MuiFormLabel-filled': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : "0px"
        },
        '& label.Mui-focused': {
            color: customApp('color'), //customApp
            marginTop: customApp('fieldVariant') === 'standard' ? "-10px" : customApp('fieldVariant') === 'filled' ? "-10px" : "0px"
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customApp('line'), //customApp
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&.Mui-focused fieldset': {
                borderColor: customApp('line'), //customApp
            },
        },
        '& .MuiFilledInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '0px',
            // padding: "15px",
            '&.Mui-focused': {
                marginTop: '0px',
            },
            '&.MuiFormLabel-filled': {
                marginTop: '0px',
            }
        },
        '& .MuiInputBase-root': {
            marginTop: customApp('fieldVariant') === 'standard' ? "0px" : customApp('fieldVariant') === 'filled' ? "-15px" : "0px",
            borderRadius: customApp('fieldRadius'),
            //color: '#FFFFFF',
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
    },
    noLabelLight: {
        ...defaultFont,
        padding: "0px",
        width: "100%",
        marginTop: "7px",
        marginBottom: "7px",
        '& .invalid': {
            borderBottom: 'solid 2px red !important',
        },
        '& .MuiOutlinedInput-input': {
            padding: "11px 11px",
            fontSize: "12px",
        },
        '& label': {
            textAlign: "right",
            fontSize: "12px",
            color: customApp('color'), //customApp
        },
        '& label.MuiFormLabel-filled': {
            display: 'none'
        },
        '& label.Mui-focused': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: customApp('line'), //customApp
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&.Mui-focused fieldset': {
                borderColor: customApp('line'), //customApp
            },
        },
        '& .MuiFilledInput-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
        '& .MuiInputLabel-outlined': {
            display: 'none',
            marginTop: '0px',
            '&.Mui-focused': {
                marginTop: '0px',
            },
            '&.MuiFormLabel-filled': {
                marginTop: '0px',
            }
        },
        '& .MuiInputBase-root': {
            borderRadius: customApp('fieldRadius'),
            '& fieldset': {
                borderColor: customApp('line'), //customApp
            },
            '&::before': {
                borderBottomColor: customApp('line')
            },
            '&::after': {
                borderBottomColor: customApp('line')
            },
            '&.Mui-focused': {
                borderBottomColor: customApp('line'), //customApp
                '&::before': {
                    borderBottomColor: customApp('line')
                },
                '&::after': {
                    borderBottomColor: customApp('line')
                },
            },
        },
    },
};

export default textField;

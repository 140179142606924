//CORE
import React from "react"
import styled from "styled-components"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import moment from "moment-timezone"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import SimpleCard from "./Card"
import Switch from "components/Switch"

//@MATERIAL
import CustomIconButton from "components/CustomButtons/IconButton"

//FUNCTIONS
import { translate, customApp } from "functions/"
import { updateCardData } from "functions/cards"

function Component(props) {
    const { db, session } = reduxStore.getState()
    const { socket } = reduxStore.getState().functions
    const [dragStatus, setDragStatus] = React.useState(false)
    const [showCompleted, setShowCompleted] = React.useState(false)
    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD')

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    if (hashs[0].length !== 36) {
        hashs[0] = null
    }

    let parent = {}
    if (reduxStore.getState().db[props.db] && reduxStore.getState().db[props.db][props.nodeId])
        parent = reduxStore.getState().db[props.db][props.nodeId]


    const tasksCards = Object.keys(reduxStore.getState().db.cards)
        .filter(fil => {
            if (
                !props.myDay
                && reduxStore.getState().db.cards[fil].type !== "step"
                && (
                    (
                        !props.noChecklist
                        && reduxStore.getState().db.cards[fil]
                        && !reduxStore.getState().db.cards[fil].deleted
                        && reduxStore.getState().db.cards[fil]._parent === props.nodeId
                    )
                    ||
                    (
                        props.noChecklist
                        && !reduxStore.getState().db.cards[fil].deleted
                        && reduxStore.getState().db.cards[fil]._parent === props.nodeId
                    )
                )
            )
                return true
            if (
                props.myDay
                &&
                (
                    db.cards[fil]._users
                    && db.cards[fil]._users[session._id]
                    && db.cards[fil]._users[session._id].myDay
                    && (
                        moment(new Date(db.cards[fil]._users[session._id].myDay * 1000)).tz('America/Sao_Paulo').format('YYYY/MM/DD') === today
                    )
                    && reduxStore.getState().db.cards[fil]
                    && !reduxStore.getState().db.cards[fil].deleted
                )
            )
                return true
            return false
        }
        )
        .sort((a, b) => {
            let orderA = db.cards[a] && db.cards[a].order && !db.cards[a].order.low ? parseInt(db.cards[a].order) : db.cards[a].order && db.cards[a].order.low ? parseInt(db.cards[a].order.low) : 0
            let orderB = db.cards[b] && db.cards[b].order && !db.cards[b].order.low ? parseInt(db.cards[b].order) : db.cards[b].order && db.cards[b].order.low ? parseInt(db.cards[b].order.low) : 0
            if (orderA < orderB)
                return -1
            if (orderA > orderB)
                return 1
            return 0
        })
        .map(card => card)


    const onDragEnd = async result => {
        setDragStatus(false)
        const { destination, source, draggableId } = result

        if (!destination) return false

        if (destination.droppableId === source.droppableId && destination.id === source.index) return false

        let itemOrderIds = Array.from(
            tasksCards
                .filter(card => db.cards[card].status !== "completed")
                .sort((a, b) => {
                    let orderA = db.cards[a].order || 0
                    if (String(orderA.low))
                        orderA = parseInt(orderA.low)

                    let orderB = db.cards[b].order || 0
                    if (String(orderB.low))
                        orderB = parseInt(orderB.low)

                    if (orderA < orderB)
                        return -1
                    if (orderA > orderB)
                        return 1
                    return 0
                })
                .map(card => card)
        )

        itemOrderIds.splice(source.index, 1)
        await setTimeout(500)

        itemOrderIds.splice(destination.index, 0, draggableId)
        await setTimeout(500)

        socket.emit("data", {
            module: "cards",
            method: "put",
            action: "reorder"
        }, {
            cards: itemOrderIds
        })

        let newCards = {
            ...reduxStore.getState().db.cards
        }

        let reorderAwait = await new Promise((resolve, reject) => {
            itemOrderIds.forEach((a, i) => {
                newCards = {
                    ...newCards,
                    [a]: {
                        ...newCards[a],
                        order: `${i + 1}`
                    }
                }
                if (i + 1 === itemOrderIds.length)
                    resolve(true)
            })
        })
        if (reorderAwait)
            props.reduxFunction("IMMEDIATE", "SET_DB", {
                ...db,
                cards: {
                    ...db.cards,
                    ...newCards
                }
            })
        return

    }

    const onDragStart = async result => {
        setDragStatus(true)
    }

    const onDragUpdate = async result => {
    }

    let openCards = tasksCards
        .filter(card => db.cards[card].status !== "completed")
        .sort((a, b) => {
            let orderA = db.cards[a] && db.cards[a].order && !db.cards[a].order.low ? parseInt(db.cards[a].order) : db.cards[a].order && db.cards[a].order.low ? parseInt(db.cards[a].order.low) : 0
            let orderB = db.cards[b] && db.cards[b].order && !db.cards[b].order.low ? parseInt(db.cards[b].order) : db.cards[b].order && db.cards[b].order.low ? parseInt(db.cards[b].order.low) : 0
            if (orderA < orderB)
                return -1
            if (orderA > orderB)
                return 1
            return 0
        })

    const hasBoard = Object.keys(db.cards).filter(card => db.cards[card]._parent === props.nodeId && !db.cards[card].deleted && db.cards[card].type === "step").length > 0 ? true : false
    return (
        <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
            {props.permission ?
                <>
                    <div style={{
                        width: '96%',
                        position: 'relative',
                        margin: "2%",
                        padding: "3px 10px",
                        border: "solid 1px rgba(0,0,0,0.1)",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{ width: "100%", color: "lightgray", textAlign: "center", padding: 3 }}>{translate(hasBoard ? "$__thisActivitieHasABoardExplain" : "$__createActivitieABoardExplain")}</div>
                        <CustomButton
                            title={translate(hasBoard ? "$__openThisBoard" : "$__createNewBoard")}
                            text={translate(hasBoard ? "$__openThisBoard" : "$__createNewBoard")}
                            color={customApp("menu")}
                            textColor={customApp("menu")}
                            icon={"schema"}
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    board: props.nodeId,
                                })
                                props.reduxFunction("ASYNC", "CLEAR_MODULE")
                            }}
                            size={"25px"}
                        />
                    </div>
                    <div style={{
                        width: '100%',
                        position: 'relative',
                        display: 'flex',
                        alignItems: "center",
                        padding: "3px 10px",
                        justifyContent: "center",
                    }}>
                        <div style={{ marginRight: 15 }}>
                            {translate("$__requireConcludedByStep")}
                        </div>
                        <Switch
                            checked={parent.listStepLock ? true : false}
                            onChange={() => {
                                updateCardData(
                                    {
                                        ...props,
                                        data: { _id: parent._id }
                                    }, {
                                    listStepLock: parent.listStepLock ? false : true
                                })

                            }}
                            name="listStepLock"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />

                    </div>

                </>
                : <React.Fragment></React.Fragment>}

            {props.showProgress && tasksCards.length > 0 ?
                <div
                    style={{
                        position: "relative",
                        width: "100%",
                    }}
                >
                    <div style={{
                        position: "absolute",
                        right: 7,
                        bottom: 0,
                        fontSize: 9,
                        color: customApp("menu"),
                        fontWeight: "bold"
                    }}>
                        {tasksCards.filter(card => db.cards[card].status === "completed").length}/<span style={{ fontWeight: "normal" }}>{tasksCards.length}</span>
                    </div>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: 5,
                            backgroundColor: "lightGray"
                        }}
                    >
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                backgroundColor: customApp("menu"),
                                width: `${(100 / tasksCards.length) * tasksCards.filter(card => db.cards[card].status === "completed").length}%`
                            }}
                        >
                        </div>
                    </div>
                </div>
                : <React.Fragment></React.Fragment>
            }
            {
                tasksCards.filter(card => db.cards[card].status !== "completed").length > 0 ?
                    <DragDropContext
                        onDragEnd={onDragEnd}
                        onDragStart={onDragStart}
                        onDragUpdate={onDragUpdate}
                    >
                        <Droppable
                            droppableId={`Drop_tasks`} //ID OF BOARD
                            type="column"
                            direction={"vertical"}
                        >
                            {(provided, snapshot) => (
                                <Container
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    {openCards
                                        .map((card, index) => {
                                            return (
                                                <Draggable
                                                    draggableId={card}
                                                    index={index}
                                                    key={`${card}${index}`}
                                                >
                                                    {(provided, snapshot) => (
                                                        <SimpleCard
                                                            dragStatus={dragStatus}
                                                            provided={provided}
                                                            snapshot={snapshot}
                                                            data={db.cards[card]}
                                                            db={'cards'}
                                                            selectable
                                                            noDelete={props.noDelete ? props.noDelete : false}
                                                            showCard={(!db.cards[card].checklist || (db.cards[card].checklist && (props.showCard || db.cards[card].showCard))) ? true : false}
                                                            permission={props.permission}
                                                            locked={parent.listStepLock && index > 0 && db.cards[openCards[index - 1]].status !== "completed"}
                                                        />
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                        )}
                                    {provided.placeholder}
                                </Container>
                            )}
                        </Droppable>
                    </DragDropContext>
                    : <React.Fragment></React.Fragment>
            }
            {
                (props.permission || props.myDay) &&
                <div style={{ position: 'relative', float: 'right', width: '100%', padding: '7px', display: 'flex', alignContent: 'flex-end', justifyContent: 'center' }}>
                    {/* <div> */}
                    <CustomIconButton
                        // reverse
                        text={translate("$__new", '*')}
                        icon="add_box"
                        iconColor={customApp('color')}
                        onClick={() => {
                            props.reduxFunction("ASYNC", "SET_CONTROL", {
                                ...reduxStore.getState().control,
                                myDay: props.myDay ? true : false,
                                addCard: {
                                    myDay: props.myDay ? true : false,
                                    _parent: parent?._id || null,
                                    rows: tasksCards.length,
                                    type: parent && parent.type && parent?.type?.indexOf('guideline') > -1 ? 'objective' : parent?.type === 'objective' ? 'goal' : parent?.type === 'goal' ? 'task' : 'task'
                                }
                            }
                            )
                        }}
                        style={{ padding: 7 }}
                    />
                    {!props.myDay ?
                        <CustomIconButton
                            // reverse
                            text={translate("$__newSimpleTask", '*')}
                            icon="playlist_add"
                            iconColor={customApp('medium')}
                            onClick={() => {
                                props.reduxFunction("ASYNC", "SET_CONTROL", {
                                    ...reduxStore.getState().control,
                                    myDay: props.myDay || false,
                                    checklist: true,
                                    addCard: {
                                        _parent: parent._id,
                                        rows: tasksCards.length,
                                        type: parent.type.indexOf('guideline') > -1 ? 'objective' : parent.type === 'objective' ? 'goal' : parent.type === 'goal' ? 'task' : 'task',
                                        checklist: true
                                    }
                                }
                                )
                            }}
                            style={{ padding: 7 }}
                        />
                        : <React.Fragment></React.Fragment>
                    }
                </div>
            }
            {tasksCards.filter(card => db.cards[card].status === "completed").length > 0 ?
                <div style={{
                    width: '96%',
                    position: 'relative',
                    margin: "2%",
                    padding: "3px 10px",
                    border: "solid 1px rgba(0,0,0,0.1)",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <CustomButton
                        title={`${translate(showCompleted ? "$__hideCompletedActivities" : "$__showCompletedActivities")} - (${tasksCards.filter(card => db.cards[card].status === "completed").length})`}
                        text={`${translate(showCompleted ? "$__hideCompletedActivities" : "$__showCompletedActivities")} - (${tasksCards.filter(card => db.cards[card].status === "completed").length})`}
                        color={customApp("menu")}
                        textColor={customApp("menu")}
                        icon={"done_all"}
                        onClick={() => {
                            setShowCompleted(prev => !prev)
                        }}
                        size={"25px"}
                    />
                </div>
                : <></>}
            {
                showCompleted && tasksCards.filter(card => db.cards[card].status === "completed").length > 0 ?
                    <React.Fragment>
                        {tasksCards
                            .filter(card => db.cards[card].status === "completed")
                            .sort((a, b) => {
                                let dataA = 0
                                let dataB = 0
                                if (db.cards[a].completed_at && db.cards[a].completed_at.low)
                                    dataA = parseInt(db.cards[a].completed_at.low)

                                if (db.cards[b].completed_at && db.cards[b].completed_at.low)
                                    dataB = parseInt(db.cards[b].completed_at.low)

                                if (dataA > dataB)
                                    return -1
                                if (dataA < dataB)
                                    return 1

                                return 0
                            })
                            .map((card, index) =>
                                <div key={`${card}${index}`}>
                                    <SimpleCard
                                        dragStatus={dragStatus}
                                        data={db.cards[card]}
                                        db={'cards'}
                                        selectable
                                        noDelete={props.noDelete ? props.noDelete : false}
                                        showCard={(!db.cards[card].checklist || (db.cards[card].checklist && (props.showCard || db.cards[card].showCard))) ? true : false}
                                        permission={props.permission}
                                        locked={parent.listStepLock && index > 0 && openCards[index - 1] && db.cards[openCards[index - 1]] && db.cards[openCards[index - 1]].status !== "completed"}
                                    />
                                </div>
                            )}
                    </React.Fragment>
                    : <React.Fragment></React.Fragment>
            }


        </div >
    )
}

const Container = styled.div`
            background-color:none
            ${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `opacity:0.88`
                : ``
    }
            `

const mapStateToProps = (store) => ({
    cards: store.db.cards,
}
)
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

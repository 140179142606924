import React from "react"
import moment from "moment-timezone"

//COMPONENTS
import Icon from "components/Icon"

//@MATERIAL
import Avatar from "@material-ui/core/Avatar"
import Tooltip from "@material-ui/core/Tooltip"


import {
    translate,
} from "functions/"


function AvatarList(props) {
    const { data } = props

    if (!(data && data._users && Object.keys(data._users).length > 0))
        return (<></>)

    const users = data._users ? data._users : {}

    return (
        <div>
            {users && Object.keys(users).length > 0 && Object.keys(users)
                .filter(u =>
                    String(u) !== "null"
                    && (
                        !props.customDefine
                        ||
                        (
                            props.customDefine
                            && props.customDefine.name
                            && users[u]
                            && users[u][props.customDefine.name]
                        )
                    )
                ).length === 0 ?
                translate("$__selectUsers")
                :
                <div
                    style={{
                        display: "flex"
                    }}
                >
                    {Object
                        .keys(users)
                        .filter(u =>
                            String(u) !== "null"
                            && (
                                !props.customDefine
                                ||
                                (
                                    props
                                    && props.customDefine
                                    && props.customDefine.name
                                    && users
                                    && users[u]
                                    && users[u][props.customDefine.name]
                                )
                            )
                        )
                        .sort((a, b) => {
                            if (users[a].isResponsible)
                                return -1
                            if (users[b].isResponsible)
                                return 1
                            if (users[a].name < users[b].name)
                                return -1
                            if (users[a].name > users[b].name)
                                return 1
                            return 0
                        })
                        .filter((a, i) => {
                            let limit = props.noLimit ? 7 : props.limit ? props.limit : 7
                            if (i <= limit)
                                if (users[a] && users[a].name)
                                    return true
                            return false
                        })
                        .map((a, i) => {
                            let myDay = false
                            let isResponsible = Object.keys(users).length > 1 && users[a].isResponsible ? true : false
                            let customDefine = props.customDefine && props.customDefine.name ? Object.keys(users).length > 1 && users[a][props.customDefine.name] ? true : false : false
                            if (users[a].myDay
                                && users[a].myDay
                                && moment(parseInt(users[a].myDay) * 1000).tz("America/Sao_Paulo").format("DD/MM/YYYY") === moment(new Date()).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                            ) {
                                myDay = true
                            }
                            let limit = props.noLimit ? 7 : props.limit ? props.limit : 7
                            if (i < limit) {
                                return (
                                    <div key={a} style={{ marginLeft: -7, position: "relative" }}>
                                        <Tooltip
                                            title={`${users[a].name}${myDay ? ` (${translate("$__inMyDay")})` : ``}`}
                                            key={a}
                                        >
                                            <div
                                                overlap="circular"
                                                alt={users[a].name}
                                                src={users[a].image || null}
                                                style={{
                                                    width: myDay || isResponsible ? '30px' : props.avatarSize ? props.avatarSize : "24px",
                                                    height: myDay || isResponsible ? '30px' : props.avatarSize ? props.avatarSize : "24px",
                                                    borderRadius: "50%",
                                                    ...!props.customDefine ? {
                                                        border: isResponsible ? `3px deepskyblue solid` : myDay ? `2px #ff8800 solid` : `none`,
                                                    } : {
                                                        border: customDefine ? props.customDefine.avatarBorder || `3px green solid` : `none`,
                                                    },
                                                    backgroundImage: users[a].image ? `url("${users[a].image}")` : `null`,
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center center",
                                                    backgroundColor: "lightgray",
                                                    textAlign: "center",
                                                }}>
                                                {!users[a].image ? users[a].name.substr(0, 1) : ``}
                                            </div>
                                        </Tooltip>
                                        {myDay &&
                                            <div style={{
                                                position: "absolute",
                                                backgroundColor: "#ff8800",
                                                width: "14px",
                                                height: "14px",
                                                borderRadius: "14px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                right: "7px",
                                                bottom: 0,
                                            }}>
                                                <Icon icon={"hourglass_top"} size="10px" color="#FFFFFF" />
                                            </div>
                                        }
                                    </div>
                                )
                            } else if (i === limit) {
                                let count = Object.keys(users).length - limit
                                return (
                                    <Tooltip
                                        title={(
                                            <ul style={{ listingStyle: "none", padding: 0 }}>
                                                {Object.keys(users)
                                                    .sort((a, b) => {
                                                        if (users[a].isResponsible)
                                                            return -1
                                                        if (users[b].isResponsible)
                                                            return 1
                                                        if (users[a].name < users[b].name)
                                                            return -1
                                                        if (users[a].name > users[b].name)
                                                            return 1
                                                        return 0
                                                    })
                                                    .filter((aa, aai) => {
                                                        if (aai >= limit &&
                                                            users[aa] &&
                                                            users[aa].name
                                                        ) return true
                                                        return false
                                                    })
                                                    .map((aa, aai) => {
                                                        return (
                                                            <li key={aa} style={{ display: "flex", alignItems: "center" }}>
                                                                <Avatar
                                                                    overlap="circular"
                                                                    alt={users[aa].name}
                                                                    src={users[aa].image || null}
                                                                    style={{
                                                                        width: props.avatarSize ? props.avatarSize : "22px",
                                                                        height: props.avatarSize ? props.avatarSize : "22px",
                                                                        border: users[aa].isResponsible ? `3px deepskyblue solid` : users[aa].myDay ? `2px #ff8800 solid` : `none`,
                                                                        ...!props.customDefine ? {
                                                                            border: users[aa].isResponsible ? `3px deepskyblue solid` : users[aa].myDay ? `2px #ff8800 solid` : `none`,
                                                                        } : {
                                                                            border: users[aa][props.customDefine.name] ? `3px green solid` : `none`,
                                                                        },
                                                                        marginRight: 7
                                                                    }}>
                                                                    {users[aa].name.substr(0, 1)}
                                                                </Avatar>{users[aa].name}
                                                            </li>)
                                                    })}
                                            </ul>
                                        )}
                                        key={i}
                                    >
                                        <Avatar style={{
                                            width: props.avatarSize ? props.avatarSize : 27,
                                            height: props.avatarSize ? props.avatarSize : 27,
                                            border: 'none'
                                        }} alt={`+${count}`} >
                                            <span style={{ fontSize: 10 }}>{`+${count}`}</span>
                                        </Avatar>
                                    </Tooltip>
                                )
                            }
                            return (<></>)
                        })}
                </div>
            }

        </div>
    )
}

export default AvatarList
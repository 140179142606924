//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Timeline from "componentsV3/Timeline"
import KPITimer from "./timer"
import Timesheet from "components/Timer/chart"
import CustomButton from "components/Buttons/custom"

//@MATERIAL

import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/timerLog"

//FUNCTIONS
import {
    translate,
} from "functions/"


function Component(props) {
    const { classes } = props;
    const { db, sideModule, session } = reduxStore.getState()
    const data = sideModule.data || {}
    const [addTimer, setAddTimer] = React.useState(false)
    const [view, setView] = React.useState(!reduxStore.getState().db.cards[data._id]._users[session._id] ? "chart" : "timeline")

    let hashs = window.location.hash.split('/')
    hashs[0] = hashs[0].replace('#', '')

    let kpi_flow = db.cards[data._id]._timeline || {}
    kpi_flow = Object.keys(kpi_flow).filter(a => kpi_flow[a].type === "kpi").map(a => kpi_flow[a])

    return (
        <div>
            {!addTimer &&
                <div style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex'
                }}>
                    {view !== 'chart' && reduxStore.getState().db.cards[data._id]._users[session._id] &&
                        <CustomButton
                            title={translate("$__add")}
                            text={translate("$__add")}
                            icon={"more_time"}
                            size={"25px"}
                            onClick={() => {
                                setAddTimer(true)
                            }}
                        />
                    }
                    {view !== 'chart' &&
                        <CustomButton
                            title={translate("$__showChart")}
                            text={translate("$__showChart")}
                            icon={"analytics"}
                            size={"25px"}
                            onClick={() => {
                                setView("chart")
                            }}
                        />
                    }
                    {view !== 'timeline' &&
                        <CustomButton
                            title={translate("$__showTimeline")}
                            text={translate("$__showTimeline")}
                            icon={"timeline"}
                            size={"25px"}
                            onClick={() => {
                                setView("timeline")
                            }}
                        />
                    }
                </div>
            }
            {view === 'chart' &&
                <div style={{ width: "calc(100% - 30px)", marginLeft: "15px" }}>
                    <Timesheet nodeId={data._id} />
                </div>
            }
            {(addTimer || view === 'timeline') &&
                <div
                    // className={classes.timeline} 
                    style={{
                        position: "absolute",
                        // ...addTimer ? { top: 333 } : { top: 35 },
                        top: 35,
                        left: 0,
                        right: 0,
                        bottom: 0,
                    }}>
                    <Timeline nodeId={data._id} type={"timer"} db={'cards'} disabledSend loadTimeline noSearch />
                </div>
            }
            {addTimer &&
                <div className={classes.timeline} style={{
                    top: '0px',
                    zIndex: 100,
                    backgroundColor: '#FFFFFF'
                }}>
                    <KPITimer data={data} manualAdd callBack={() => {
                        setAddTimer(false)
                    }} />
                </div>
            }
        </div >
    )
}
const mapStateToProps = () => ({

});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Component))
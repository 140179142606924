import React, { useState } from 'react';

//COMPONENTS
import Grid from 'components/Grid/GridItemV2';
import GridContainer from 'components/Grid/GridContainer';
import Icon from 'components/Icon/';

//REDUX
import reduxStore from 'store/';

//PREVIEW
import DoughDetailedPreview from './Preview/DoughDetailedPreview';
import GoalsDoughPreview from './Preview/GoalsDoughPreview';
import TaskByPersonPreview from './Preview/TaskByPersonPreview';
import TaskDoneByTimePreview from './Preview/TaskDoneByTimePreview';
import TasksPreview from './Preview/TasksPreview';
import funnelPreview from './Preview/FunnelPreview';

//WIDGETS
import AboutPlan from './Widgets/AboutPlan';
import Actions from './Widgets/Actions';
import ActionsDetailed from './Widgets/ActionsDetailed';
import ActionsDough from './Widgets/ActionsDough';
import DayTasks from './Widgets/DayTasks';
import Funnel from './Widgets/Funnel';
import Goal from './Widgets/Goal';
import GoalsDetailed from './Widgets/GoalsDetailed';
import GoalsDough from './Widgets/GoalsDough';
import Guideline from './Widgets/Guideline';
import LastCompleted from './Widgets/LastCompleted';
import LateTask from './Widgets/LateTask';
import MatrixDough from './Widgets/MatrixDough';
import MatrixDoughDetailed from './Widgets/MatrixDoughDetailed';
import Meta from './Widgets/Meta';
import NextTask from './Widgets/NextTask';
import Objective from './Widgets/Objective';
import ObjectiveDetailed from './Widgets/ObjectiveDetailed';
import TaskDoneBar from './Widgets/TaskDoneBar';
import TaskDoneDoughTable from './Widgets/TaskDoneDoughTable';
import TaskByPerson from './Widgets/TaskByPerson';
import TaskByCreator from './Widgets/TaskByCreator';
import TaskByResponsible from './Widgets/TaskByResponsible';
import TaskDoneByTime from './Widgets/TaskDoneByTime';
import TimeLine from './Widgets/TimeLine';
import ObjetiveDough from './Widgets/ObjetiveDough';
import Timesheet from './Widgets/Timesheet';
import TaskDoneBarMonth from "./Widgets/TaskDoneBarMonth";

//ICONS
import MatrixIcon from 'assets/icons/Matrix';

//FUNCTIONS
import { translate } from 'functions';

//STYLES
import './Filter.css';

const Filter = ({ onClose, filter, addWidget }) => {
  const [selectedPriority, setSelectedPriority] = useState('all');

  let hashs = window.location.hash.split("/")
  hashs[0] = hashs[0].replace('#', '')
  const { db } = reduxStore.getState()

  const filtrosList = [
    { name: translate('$__all'), type: 'all' },
    { name: translate('$__charts'), type: 'charts' },
    { name: translate('$__tasks'), type: 'tasks' },
    { name: translate('$__others'), type: 'others' },
  ];

  const filtros = [
    { Component: TaskDoneBar, widget: 'TaskDoneBar', type: "charts", name: 'TaskDoneBar', datagrid: { x: 0, y: 0, w: 4, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart', Preview: TaskByPersonPreview },
    { Component: TaskDoneDoughTable, widget: 'TaskDoneDoughTable', type: "charts", name: 'TaskDoneDoughTable', datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 2 }, icon: 'stacked_bar_chart', Preview: GoalsDoughPreview },
    { Component: TaskDoneByTime, widget: 'TaskDoneByTime', name: 'taskDoneInWeek', type: "charts", datagrid: { x: 0, y: 0, w: 5, h: 5, minW: 3, maxW: 12, minH: 4 }, icon: 'area_chart', Preview: TaskDoneByTimePreview },
    {
      Component: TaskByPerson, widget: 'TaskByPerson', name: 'TaskByPerson', type: "charts", datagrid: { x: 0, y: 0, w: 5, h: 5, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart', Preview: TaskByPersonPreview, filter: {
        index: 0,
        showQuantity: 5,
        number: filter?.users ? filter?.users?.length : 4,
        finalIndex: 5
      }
    },
    {
      Component: TaskByCreator, widget: 'TaskByCreator', name: 'TaskByCreator', type: "charts", datagrid: { x: 0, y: 0, w: 5, h: 5, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart', Preview: TaskByPersonPreview, filter: {
        index: 0,
        showQuantity: 5,
        number: filter?.users ? filter?.users?.length : 4,
        finalIndex: 5
      }
    },
    {
      Component: TaskByResponsible, widget: 'TaskByResponsible', name: 'TaskByResponsible', type: "charts", datagrid: { x: 0, y: 0, w: 5, h: 5, minW: 3, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart', Preview: TaskByPersonPreview, filter: {
        index: 0,
        showQuantity: 5,
        number: filter?.users ? filter?.users?.length : 4,
        finalIndex: 5
      }
    },
    { Component: TaskDoneBarMonth, widget: 'TaskDoneBarMonth', type: "charts", name: 'TaskDoneBarMonth', datagrid: { x: 0, y: 0, w: 6, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'stacked_bar_chart', Preview: TaskByPersonPreview },
    // { Component: Notes, widget: 'Notes', name: 'Notes', type: "others", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'note', Preview: TasksPreview },
    { Component: TimeLine, widget: 'TimeLine', name: 'TimeLine', type: "others", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 6, maxW: 12, minH: 4 }, icon: 'chat', Preview: TasksPreview },
    { Component: ActionsDetailed, widget: 'ActionsDetailed', name: 'actions', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 7, minW: 2, maxW: 12, minH: 7 }, icon: 'play_arrow', Preview: DoughDetailedPreview, plan: 'flow' },
    { Component: GoalsDetailed, widget: 'GoalsDetailed', name: 'goals', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 7, minW: 2, maxW: 12, minH: 7 }, icon: 'flag', Preview: DoughDetailedPreview, plan: 'flow' },
    { Component: MatrixDoughDetailed, widget: 'MatrixDoughDetailed', name: 'guidelines', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 7, minW: 2, maxW: 12, minH: 7 }, icon: MatrixIcon, Preview: DoughDetailedPreview, plan: 'flow' },
    { Component: ObjectiveDetailed, widget: 'ObjectiveDetailed', name: 'objectives', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 7, minW: 2, maxW: 12, minH: 7 }, icon: 'gps_fixed', Preview: DoughDetailedPreview, plan: 'flow' },
    { Component: ObjetiveDough, widget: 'ObjetiveDough', name: 'objectives', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 2 }, icon: 'gps_fixed', Preview: GoalsDoughPreview, plan: 'flow' },
    { Component: ActionsDough, widget: 'ActionsDough', name: 'actions', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 2 }, icon: 'play_arrow', Preview: GoalsDoughPreview, plan: 'flow' },
    { Component: MatrixDough, widget: 'MatrixDough', name: 'guidelines', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 2 }, icon: MatrixIcon, Preview: GoalsDoughPreview, plan: 'flow' },
    { Component: GoalsDough, widget: 'GoalsDough', name: 'goals', type: "charts", datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 2 }, icon: 'flag', Preview: GoalsDoughPreview, plan: 'flow' },
    { Component: Goal, widget: 'Goal', name: 'goals', datagrid: { x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'flag', Preview: Meta, plan: 'flow' },
    { Component: Objective, widget: 'Objective', name: 'objectives', datagrid: { x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'gps_fixed', Preview: Meta, plan: 'flow' },
    { Component: Guideline, widget: 'Guideline', name: 'guidelines', datagrid: { x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: MatrixIcon, Preview: Meta, plan: 'flow' },
    { Component: Actions, widget: 'Actions', name: 'actions', datagrid: { x: 0, y: 0, w: 2, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'play_arrow', Preview: Meta, plan: 'flow' },
    { Component: LastCompleted, widget: 'LastCompleted', type: "tasks", name: 'lastCompletedCards', datagrid: { x: 0, y: 0, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'today', Preview: TasksPreview },
    { Component: DayTasks, widget: 'DayTasks', type: "tasks", name: 'todayTasks', datagrid: { x: 0, y: 0, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'today', Preview: TasksPreview },
    { Component: LateTask, widget: 'LateTask', type: "tasks", name: 'lateActivities', datagrid: { x: 0, y: 0, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'today', Preview: TasksPreview },
    { Component: NextTask, widget: 'NextTask', type: "tasks", name: 'nextActivities', datagrid: { x: 0, y: 0, w: 3, h: 6, minW: 2, maxW: 12, minH: 4 }, icon: 'today', Preview: TasksPreview },
    { Component: Timesheet, widget: 'Timesheet', type: "charts", name: 'Timesheet', datagrid: { x: 0, y: 0, w: 6, h: 8, minW: 3, maxW: 12, minH: 8 }, icon: 'update', Preview: TasksPreview },
    { Component: Funnel, widget: 'Funnel', type: "charts", name: 'Etapas', datagrid: { x: 0, y: 0, w: 6, h: 4, minW: 4, maxW: 12, minH: 4 }, icon: 'filter_alt', Preview: funnelPreview },
    { Component: AboutPlan, widget: 'AboutPlan', type: "others", name: 'planResume', datagrid: { x: 0, y: 0, w: 4, h: 4, minW: 2, maxW: 12, minH: 4 }, icon: 'info', Preview: TasksPreview },
  ];

  const GetHeader = (props) => {
    const { name, prev } = props
    return (

      <div >
        <p
          style={{
            marginTop: "2px",
            height: "80px",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: '105%',
            marginLeft: '-2%',
            fontWeight: "bold",
            borderBottom: "1px solid #d1d1cf",
            fontSize: "16px",
            color: 'rgba(0, 0, 0, 0.80)'
          }}
        >
          <Icon
            size="35px"
            style={{
              alignSelf: "center",
              marginRight: "10px",
              color: "#001836"
            }}
          >
            analytics
          </Icon>
          {name}
        </p>
        <div id="getElements_40">
          <Icon
            style={{
              alignSelf: "center",
              height: "25px",
              cursor: "pointer",
              position: "absolute",
              width: "25px",
              right: "20px",
              top: "18px",
              color: "grey"
            }}
            size="30px"
            onClick={onClose}
          >
            close
          </Icon>
        </div>
        {prev && <div id="getElements_58"> <Icon
          style={{
            alignSelf: "center",
            height: "25px",
            cursor: "pointer",
            position: "absolute",
            width: "25px",
            left: "20px",
            top: "18px",
            color: "grey"
          }}

          size="30px"
        >
          arrow_back
        </Icon></div>}
      </div>
    );
  };



  return (
    <div className="container">
      <div className="gridContainer">
        <GridContainer >
          <Grid className="grid" xs={12}>
            <GetHeader name={translate('$__addCharts')} />
          </Grid>
          <Grid className="grid" xs={4}>
            <div className='list'>
              {filtrosList.map(({ name, type }, i) => {

                let newName = name.charAt(0).toUpperCase() + name.slice(1);

                if (type === 'tasks' && db.cards[hashs[0]].planType === "operational") return null
                return (
                  <p
                    key={i}
                    onClick={() => setSelectedPriority(type)}
                    onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'transparent' }}
                    className={'filter'}
                    style={{ paddingLeft: "20px", display: 'flex', alignItems: 'center', fontSize: '16px' }}
                  >
                    {newName}
                  </p>
                );
              })}
            </div>
          </Grid>
          <Grid className="grid" xs={8}>
            <div className="filterItemBox">
              {filtros &&
                filtros.map(
                  ({ name, image, Component, datagrid, type, icon, widget, Preview, filter, plan }, i) => {

                    if (type === 'tasks' && db.cards[hashs[0]].planType === "operational") return null
                    if (widget === 'Funnel' && db.cards[hashs[0]].planType !== "operational") return null
                    if (plan === 'flow' && db.cards[hashs[0]].planType !== "flow") return null
                    if (type === selectedPriority || selectedPriority === 'all')

                      return (
                        <Grid className="gridBox" xs={6} key={i}>
                          <div
                            style={{ position: 'absolute', zIndex: '10', width: '97%', height: '95%', cursor: 'pointer', marginLeft: '-4.5%' }}
                            onClick={() => {
                              addWidget(Component, name, datagrid, name, name, icon, widget, filter);
                              onClose()
                            }}
                            className='boxIncrease'
                          ></div>
                          <div
                            className="filterItem"
                          >
                            <Preview
                              tittle={translate(`$__${name}`, 1)}
                              title={translate(`$__${name}`, 1)}
                              preview={true}
                              data={1}
                              type={type}
                              icon={icon}
                            />
                          </div>
                        </Grid>
                      );
                    else return null
                  }
                )}
            </div>
          </Grid>
        </GridContainer>
      </div>
    </div >
  );
};

export default Filter;

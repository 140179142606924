import React, { useEffect, useState } from "react";

//@MATERIAL
import { FormControlLabel, Switch, Checkbox, makeStyles, Typography } from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';

//FUNCTIONS
import { customApp, translate } from "functions";

const useStyles = makeStyles({
  switch: {
    marginBottom: '10px',
    '& + .MuiSwitch-track': {
      backgroundColor: customApp('color'),
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: 'rgb(125, 125, 125)',
    },
    '& .Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: customApp('color'),
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: customApp('color'),
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: customApp('color'),
        borderRadius: "50%",
        width: '20px',
        height: '20px',
        color: customApp('color'),
      }
    },
    '& .Mui-disabled': {
      '& + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSvgIcon-root': {
        backgroundColor: customApp('color'),
        borderRadius: "50%",
        width: '20px !important',
        height: '20px !important',
        padding: '3px',
        color: `${customApp('lightText')} !important`,
        transform: 'translate(0, 0, 0, 0.2em)',
      }
    }
  },
  check: {
    '&': {
      display: 'none'
    }
  },
  checkbox: {
    '&': {
      padding: '0px',
    }
  },
});

const SwitchAndCheck = ({ checked, setData, notificationType, notification, data }) => {
  const [check, setCheck] = useState(checked !== "block" ? checked : true);
  const [checkbox, setCheckbox] = useState(checked === "block" ? true : false);
  const styles = useStyles();
  const notChange = (check) => {

    let newArr = []

    data.forEach(updateNotifications => {
      let newObject = updateNotifications
      let newNotifications = []
      newObject.notifications.forEach((item) => {
        let newNotificationsObject = item
        if (notification?.title === item.title) {
          newNotificationsObject = {
            ...newNotificationsObject,
            [notificationType]: check
          }
        }
        newNotifications = [...newNotifications, newNotificationsObject]
      })
      newObject = {
        ...newObject,
        notifications: newNotifications,
      }
      newArr = [...newArr, newObject]
    })

    console.log(newArr)

    setData(newArr);

  }

  useEffect(() => {
    setCheck(checked !== "block" ? checked : true);
  }, [checked]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '0px',
    }}>
      <FormControlLabel control={
        <>
          <Switch className={styles.switch}
            color="default"
            checked={check}
            onChange={(e) => { setCheck(e.target.checked); notChange(e.target.checked) }}
            disabled={checked === "block" ? true : false}
            checkedIcon={<LockIcon />}
          />
        </>
      } />
      <FormControlLabel style={{ marginTop: '-18px' }} control={
        <Checkbox
          color="default"
          onChange={(e) => { setCheckbox(e.target.checked); notChange(e.target.checked ? "block" : true) }}
          checked={checkbox}
          disabled={checked === true || checked === "block" ? false : true}
          className={styles.checkbox}
        />
      } label={<Typography style={{ fontSize: '12px' }}>{translate('$__required')}</Typography>} />
    </div>
  )
}
export default SwitchAndCheck;



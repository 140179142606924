import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>"/</h1>
          <h2 className={classes.subTitle}>Parece que você está OFF-LINE</h2>
          <h4 className={classes.description} style={{ color: '#FFFFFF' }}>
            Verifique a conexão de sua internet e tente novamente.
          </h4>
          <h4 className={classes.description} style={{ color: '#FFFFFF' }}>
            Caso você esteja com conexão de internet ativa e ainda esteja visualizando esta página após algumas tentativas, favor entrar em contato com nosso suporte.
          </h4>
          <h4 className={classes.description} style={{ color: '#FFFFFF' }}>
            <a href="/login" target="_self">Clique aqui para tentar novamente</a>
          </h4>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react'

//COMPONENTS
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import Icon from 'components/Icon'
import ButtonOptions from 'componentsV3/Buttons';
import Filter from 'componentsV3/Filter';
import Card from "components/Card"
import Pagination from '@material-ui/lab/Pagination'
import Header from '../Header'
import moment from "moment-timezone"

//REDUX
import reduxStore from "store/"
import { translate } from "functions"


const LastCompleted = (props) => {

    const [lastCompletedCards_page, setlastCompletedCards_page] = React.useState(1)
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()
    const [hover, setHover] = useState(false)
    const taskRef = useRef()
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [filter, setFilter] = useState("")
    const [height, setHeight] = useState(0)

    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);


    useEffect(() => {
        if (!taskRef.current) return;

        const resizeObserver = new ResizeObserver(() => {
            let containerItemCount = taskRef.current.clientHeight / 42
            setHeight((containerItemCount / 2) - 1)
        });
        resizeObserver.observe(taskRef.current);
        return () => resizeObserver.disconnect(); // clean up 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);




    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{ height: '100%', overflow: 'hidden' }}>
            <UiCard style={{ height: '100%', margin: '0px' }}>
                <div>
                    {props.edit ?
                        <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                            <Icon size={24} style={{ alignSelf: 'center', marginLeft: '12.5px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>pending_actions</Icon>
                            <p style={{ margin: '0px', marginLeft: '7.5px', alignSelf: 'center', fontWeight: 'bold', marginTop: '1px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>{translate(`$__lastCompletedCards`, 1)}</p>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    close
                                </Icon>
                            </div>
                            <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', position: 'absolute', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                    open_with
                                </Icon>
                            </div>
                        </div> : <Header
                            hover={hover}
                            tittle={translate(`$__lastCompletedCards`, 1)}
                            icon={'pending_actions'}
                            datafilter={false}
                            styles={props.styles}
                        />
                    }
                    {(hover && !props.edit) && <ButtonOptions
                        text={"Buscar"}
                        icon={"search"}
                        popper={
                            <div style={{ padding: '15px' }}>
                                <Filter
                                    fields={['text']}
                                    showFilters
                                    style={{ position: 'absolute', zIndex: '999' }}
                                    defaultValues={{
                                        text: ''
                                    }}
                                    onChange={(e) => e.text && setFilter(e?.text)}
                                />
                            </div>
                        }
                        style={{
                            alignSelf: 'center',
                            position: 'absolute',
                            right: '40px',
                            borderRadius: '5px',
                            backgroundColor: 'white',
                            top: '7px'
                        }}
                    />}
                </div>
                <UiCardBody>
                    <div ref={taskRef} style={{ overflowY: 'scroll', height: `calc(100% - 70px)`, overflowX: 'hidden' }}>
                        {Object.keys(props.planCards)
                            .filter((card, i) => {
                                let lastDateCard =
                                    db.cards[card].completed_at?.low
                                    || db.cards[card].completed_at
                                    || db.cards[card].updated_at?.low
                                    || db.cards[card].updated_at
                                    || db.cards[card].created_at?.low
                                    || db.cards[card].created_at

                                const dateCard = moment(lastDateCard * 1000);
                                const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                                const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                                return db.cards[card]._planId === hashs[0]
                                    && !db.cards[card].deleted
                                    && dateCard.isBetween(startDate, endDate, null, '[]')
                                    && (
                                        !db.cards[card].checklist
                                        ||
                                        (
                                            db.cards[card].checklist
                                            && db.cards[card].showCard
                                        )
                                    )
                                    && db.cards[card].status === 'completed'
                                    && db.cards[card].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
                            })
                            .sort((a, b) => {
                                let completeA = db.cards[a].completed_at && db.cards[a].completed_at.low ? parseInt(db.cards[a].completed_at.low) : typeof db.cards[a].completed_at === 'string' ? db.cards[a].completed_at : 0
                                let completeB = db.cards[b].completed_at && db.cards[b].completed_at.low ? parseInt(db.cards[b].completed_at.low) : typeof db.cards[b].completed_at === 'string' ? db.cards[b].completed_at : 0
                                if (completeA > completeB) {
                                    return -1
                                } else if (completeA < completeB) {
                                    return 1
                                } else {
                                    return 0
                                }
                            })
                            .filter((card, i) => i >= (lastCompletedCards_page - 1) * height && i < lastCompletedCards_page * height)
                            .map((card, i) => {
                                return (<Card
                                    db="cards"
                                    key={card}
                                    data={db.cards[card]}
                                    inLine={true}
                                />)
                            }
                            )}
                    </div>
                    {Math.ceil(Object.keys(props.planCards).filter(card => {
                        let lastDateCard =
                            db.cards[card].completed_at?.low
                            || db.cards[card].completed_at
                            || db.cards[card].updated_at?.low
                            || db.cards[card].updated_at
                            || db.cards[card].created_at?.low
                            || db.cards[card].created_at

                        const dateCard = moment(lastDateCard * 1000);
                        const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                        const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                        return db.cards[card]._planId === hashs[0]
                            && dateCard.isBetween(startDate, endDate, null, '[]')
                            && db.cards[card].status === 'completed'
                    }).length / height) > 1 &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', maxHeight: '30px' }} >
                            <Pagination
                                page={lastCompletedCards_page}
                                count={Math.ceil(Object.keys(db.cards).filter(card => {
                                    let lastDateCard =
                                        db.cards[card].completed_at?.low
                                        || db.cards[card].completed_at
                                        || db.cards[card].updated_at?.low
                                        || db.cards[card].updated_at
                                        || db.cards[card].created_at?.low
                                        || db.cards[card].created_at

                                    const dateCard = moment(lastDateCard * 1000);
                                    const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                                    const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                                    return db.cards[card]._planId === hashs[0]
                                        && dateCard.isBetween(startDate, endDate, null, '[]')
                                        && db.cards[card].status === 'completed'
                                        && db.cards[card].name.toLowerCase().includes(filter.toLowerCase() ? filter : "")
                                }).length / height)}
                                onChange={(e, p) => {
                                    setlastCompletedCards_page(p)
                                }}
                            />
                        </div>
                    }
                </UiCardBody>
            </UiCard>
        </div>
    )
}

export default LastCompleted
//CORE
import React from "react";
import ReactExport from "react-data-export";
import moment from "moment-timezone";

//REDUX
import reduxStore from "store/";

//@MATERIAL
import CustomButton from "components/Buttons/custom"


//FUNCTIONS
import { translate, getDate } from "functions/";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Component(props) {
    const [dataCompleted, setDataCompleted] = React.useState(false)
    const [dataSet, setDataSet] = React.useState([])
    const [userDataSet, setUserDataSet] = React.useState([])
    const [columns, setcolumns] = React.useState([{
        label: translate('$__step', 1),
        value: 'step'
    }, {
        label: translate('$__cardCode', 1),
        value: 'cardCode'
    }, {
        label: translate('$__ref1', 1),
        value: 'ref1'
    }, {
        label: translate('$__ref2', 1),
        value: 'ref2'
    }, {
        label: translate('$__name', 1),
        value: 'name'
    }, {
        label: translate('$__description', 1),
        value: 'description'
    }, {
        label: translate('$__value', 1),
        value: 'value'
    }, {
        label: translate('$__startDate', 1),
        value: 'startDate'
    }, {
        label: translate('$__endDate', 1),
        value: 'endDate'
    }, {
        label: translate('$__priority', 1),
        value: 'priority'
    }, {
        label: translate('$__risk', 1),
        value: 'risk'
    }, {
        label: translate('$__complexity', 1),
        value: 'complexity'
    }, {
        label: translate('$__impact', 1),
        value: 'impact'
    }, {
        label: translate('$__tags', 1),
        value: 'tags'
    }, {
        label: translate('$__responsive', 1),
        value: 'responsive'
    }, {
        label: translate('$__createdBy', 1),
        value: 'createdBy'
    }, {
        label: translate('$__createdAt', 1),
        value: 'created_at'
    }, {
        label: translate('$__updatedAt', 1),
        value: 'updated_at'
        // }, {
        //     label: translate('$__moveCount', 1),
        //     value: 'moveCount'
        //ENEVAAAAAAAAAAAAAAAAAAAAAAAAAAA
    }])


    const [usersColumns, setusersColumns] = React.useState([{
        label: translate('$__cardCode', 1),
        value: 'cardCode'
    }, {
        label: translate('$__user', 1),
        value: 'user'
    }, {
        label: translate('$__access', 1),
        value: 'level'
    }, {
        label: translate('$__isResposible', 1),
        value: 'responsible'
    }])
    const { db, forms } = reduxStore.getState()


    const mountDataFn = async (d) => {
        //reduxStore.getState().forms[props.formId].boardColumns

        let access = {
            ["0"]: translate('$__reader', 1),
            ["1"]: translate('$__executioner', 1),
            ["2"]: translate('$__collaborator', 1),
            ["3"]: translate('$__collaborator', 1),
            ["4"]: translate('$__supervisor', 1),
            ["5"]: translate('$__admin', 1)
        }

        let form
        let mountData = []
        let mountUsersData = []

        if (d.columnOrder.length > 0) {
            d.columnOrder.map(a => {
                if (db.cards[a]?.form) {
                    form = forms[db.cards[a]?.form]
                }
                return
            })
        }

        let customFields = columns
        if (form) {
            let formElements = JSON.parse(form.elements)
            formElements.map(a => {
                if (customFields.filter(cfa => cfa === a).length === 0)
                    customFields.push({ label: a.name, value: a.id })
            })
            if (customFields.length > 0)
                setcolumns(customFields)
        }

        Object.keys(d.columns).map(col => {
            const columnName = db.cards[col].name
            if (d.columns[col]?.items?.length > 0) {
                d.columns[col].items.map(card => {
                    let newUserLine = {}
                    let newLine = []


                    customFields.map(cf => {
                        let xl = ``
                        if (cf.value === 'updated_at' || cf.value === 'created_at') {
                            xl = getDate(card[cf.value]?.low || card[cf.value], "YYYY-MM-DD HH:mm") || ``
                        } else if (cf.value === 'startDate' || cf.value === 'endDate') {
                            if (card[cf.value]?.low || card[cf.value]) {
                                xl = getDate(card[cf.value]?.low || card[cf.value], "YYYY-MM-DD HH:mm") || ``
                            } else {
                                xl = ``
                            }
                        } else if (cf.value === 'createdBy') {
                            xl = card._createdBy.name
                        } else if (cf.value === 'responsive') {
                            xl = Object.keys(card._users).filter(a => card._users[a].isResponsible)[0]?.name || Object.keys(card._users).filter(a => card._users[a]?.access === "5")[0]?.name || ""
                        } else if (cf.value === 'cardCode') {
                            xl = card._cardCode
                        } else if (cf.value !== "tags" && cf.value !== "cardCode" && card[cf.value]) {
                            if (typeof card[cf.value] === 'object') {
                                if (card[cf.value].low) {
                                    xl = String(card[cf.value].low)
                                } else {
                                    card[cf.value].map((a, ai) => {
                                        xl += `${ai > 0 ? ` | ` : ``}${a}`
                                    })
                                }
                            } else {
                                xl = card[cf.value]
                            }
                        } else if (cf.value === "tags" && card._tags && card._tags.filter(a => a.value).length > 0) {
                            // let tags = `=CONCAT(`
                            card._tags.map((a, ai) => {
                                xl += `${ai > 0 ? ` || ` : ``}${a.label}`
                            })
                        }
                        newLine = {
                            ...newLine,
                            [cf.value]: String(xl) || ""
                        }
                    })
                    if (mountData.filter(a => a.cardCode === newLine.cardCode).length === 0)
                        mountData.push({
                            ...newLine,
                            step: columnName
                        })
                    // console.log(card)
                    Object.keys(card._users).forEach(us => {
                        if (mountUsersData.filter(a => a.cardCode === newLine.cardCode && a.user === card._users[us].name).length === 0)
                            mountUsersData.push({
                                cardCode: newLine.cardCode,
                                user: card._users[us].name,
                                responsible: card._users[us].isResponsible ? "Sim" : "Não",
                                level: access[String(card._users[us].access).substr(0, 1)]
                            })
                    })

                })
            }
        })
        setUserDataSet(mountUsersData)
        setDataSet(mountData)
    }

    React.useEffect(() => {
        if (props.data)
            mountDataFn(props.data)
    }, [props.data])


    if (dataSet.length === 0)
        return (
            <div>Sem informações para exportar</div>
        )

    console.log(dataSet)


    return (
        <ExcelFile
            filename={`${props.fileName ? props.fileName.replace(" ", "-") : "exportBoard"}-${moment().unix('x')}`}
            element={
                <CustomButton
                    title={translate("$__export")}
                    text={translate("$__export")}
                    icon={"file_download"}
                    size={"25px"}
                />
            }
        >
            <ExcelSheet data={dataSet} name={`${props.fileName ? props.fileName.replace(" ", "-") : "exportBoard"}-${moment().unix('x')}`}>
                {columns.map((a, ai) => {
                    return (<ExcelColumn key={ai} style={{ alignment: { wrapText: true } }} label={a.label} value={a.value} />)
                })}
            </ExcelSheet>
            <ExcelSheet data={userDataSet} name={'POR USUÁRIO'}>
                {usersColumns.map((a, ai) => {
                    return (<ExcelColumn key={ai} style={{ alignment: { wrapText: true } }} label={a.label} value={a.value} />)
                })}
            </ExcelSheet>
        </ExcelFile>
    )
}
export default Component
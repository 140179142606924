import React from "react"
import MD5 from "crypto-js/md5"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Card from "components/Card"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import UserCard from "components/User/card";


//IMAGES
import tour1 from "assets/img/tour/strategy-pt.png"

//FUNCTIONS
import { getAppAccess, customApp, translate } from "functions/"

function App(props) {
    const { db, session } = reduxStore.getState()

    let searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const search = props.searchs && props.searchs[searchID] ? props.searchs[searchID] : null

    let AppAccess = getAppAccess()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let plans = []

    plans = Object.keys(db.cards).filter(a =>
        db.cards[a]
        && db.cards[a].type
        && db.cards[a].type === "plan"
        && !db.cards[a].deleted
        && !db.cards[a].isTemplate
        && !db.cards[a].archived
        &&
        (
            (
                !props.completed
                && db.cards[a].status !== "completed"
            )
            ||
            (
                props.completed
                && db.cards[a].status === "completed"
            )

        )
    ).sort((a, b) => {
        if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) < parseInt(db.cards[b].order.low))
            return -1
        if (db.cards[a].order && db.cards[b].order && parseInt(db.cards[a].order.low) > parseInt(db.cards[b].order.low))
            return 1
        return 0
    }).map(a => db.cards[a])

    if (
        (
            AppAccess
            &&
            !AppAccess.owner
            &&
            (
                !AppAccess.planAdmin
                && !AppAccess.planEdit
                && !AppAccess.planRead
                && !AppAccess.planModerate
            )
            &&
            db.cards
            && session
            && session._id
        )
        || !db.adminView
    ) {

        plans = plans.filter(a =>
            a.type === "plan"
            && a && a._users
            && Object.keys(a._users).filter(us =>
                us === session._id
                || (
                    reduxStore.getState().db.users[us]
                    && reduxStore.getState().db.users[us].groups
                    && reduxStore.getState().db.users[us].groups.filter(fil =>
                        a._groups
                        && a._groups[fil.value]
                    ).length > 0
                )
            ).length > 0

        ).sort((a, b) => {
            if (a && a.order && b && b.order && parseInt(a.order.low) < parseInt(b.order.low))
                return -1
            return 1
        }).map(a => a)
    } else {
        plans = plans.filter(a =>
            a.type === "plan"
            && a
            && a._users
            && (
                Object.keys(a._users).filter(us =>
                    us === session._id
                    || (
                        reduxStore.getState().db.users[us]
                        && reduxStore.getState().db.users[us].groups
                        && reduxStore.getState().db.users[us].groups.filter(fil =>
                            a._groups
                            && a._groups[fil.value]
                        ).length > 0
                    )
                ).length > 0
                || (
                    !a.private
                    && Object.keys(a._users).filter(us => us !== session._id).length > 0
                )
            )
        ).sort((a, b) => {
            if (a.order && db.cards[b].order && parseInt(a.order.low) < parseInt(db.cards[b].order.low))
                return -1
            return 1
        }).map(a => a)
    }


    if (search) {
        if (search.text)
            plans = plans.filter(fil => {
                if (fil && fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                    return true
                return false
            }).map(res => res)
        plans = plans.filter(fil => {
            if ((fil && fil.status && search && search.status && search.status[fil.status]) || (!fil.status && (search && search.status && search.status["notStarted"])))
                return true
            return false
        }).map(res => res)

        plans = plans.filter(fil => {
            if ((fil && fil.priority && search.priority[fil.priority]) || (search.priority["null"] && !fil.priority))
                return true
            return false
        }).map(res => res)


        if (search && search.users && search.users.length > 0)
            plans = plans.filter(fil => {
                let result = false
                if (search.users.indexOf("ALL") > -1 && (!fil._users || Object.keys(fil._users).length === 0)) {
                    result = true
                } else if (fil
                    && fil._users
                    && Object.keys(fil._users).length > 0
                ) {
                    Object.keys(fil._users).filter(a => {
                        if (search.users.indexOf(a) > -1)
                            result = true
                        return false
                    })
                    return result
                }
                return false
            }).map(res => res)

        if (search && search.clients && search.clients.length > 0) {
            plans = plans.filter(fil => {
                if (search.clients.indexOf("ALL") > -1 && !fil.client)
                    return true
                if (fil.client && search.clients.indexOf(fil.client) > -1)
                    return true
                return false
            }).map(res => res)
        }
        if (search && search.tags && search.tags.length > 0) {
            plans = plans.filter(fil =>
                fil._tags && fil._tags.length > 0 && fil._tags.filter(tag => {
                    let result = false
                    search.tags.forEach(tagFilter => {
                        if (tagFilter.value === tag.value)
                            result = true
                    })
                    return result
                }
                ).length > 0
            ).map(res => res)
        }
    }

    return (
        <div >
            {plans.filter(pl => pl._isFavorite).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__myFavoritePlans`, 1)}
                        icon={"favorite"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Plans plans={plans.filter(pl =>
                            pl._isFavorite
                            && !pl.private
                        ).map(res => res)} />
                    </GridContainer>
                </div>
            }
            {plans.filter(pl => pl._isFavorite && pl.private).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__myFavoritePrivatePlans`, 1)}
                        icon={"private_connectivity"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Plans plans={plans.filter(pl =>
                            pl._isFavorite
                            && pl.private
                        ).map(res => res)} />
                    </GridContainer>
                </div>
            }
            {plans.filter(pl => pl.client && db.users[pl.client]).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__customerPlanning`, 1)}
                        icon={"business"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Client plans={plans.filter(pl => pl.client).map(res => res)} completed={props.completed ? true : false} />
                    </GridContainer>
                </div>
            }

            {plans.filter(pl => !pl.client && !pl.private).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__accountPlanning`, 1)}
                        icon={"work"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Plans plans={plans.filter(pl => !pl.client && !pl.private).map(res => res)} />
                    </GridContainer>
                </div>
            }
            {plans.filter(pl => pl.client && !db.users[pl.client]).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__consultingPlanning`, 1)}
                        icon={"work"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Plans plans={plans.filter(pl => pl.client && !db.users[pl.client]).map(res => res)} />
                    </GridContainer>
                </div>
            }
            {plans.filter(pl => !pl.client && pl.private).length > 0 &&
                <div
                    style={{ marginBottom: "33px" }}
                >
                    <HeaderWithIcon
                        title={translate(`$__privatePlanning`, 1)}
                        icon={"private_connectivity"}
                        color={customApp("menu")}
                    />
                    <GridContainer>
                        <Plans plans={plans.filter(pl => !pl.client && pl.private).map(res => res)} />
                    </GridContainer>
                </div>
            }
            {db.loaded && (!plans || plans.length === 0) &&
                <div style={{ width: "100%", height: "calc(100vh - 150px)", display: "flex", alignItems: "center", justifyContent: "center" }} >
                    <img alt="MACHEN_IMAGE" src={tour1} />
                </div>
            }
        </div>
    )
}
const Client = (props) => {
    const { db } = reduxStore.getState()

    let units = []
    props.plans.forEach(a => {
        if (units.filter(c => c._id === a.client).length === 0 && db.users[a.client])
            units.push(db.users[a.client])
    })
    return (
        <React.Fragment>
            {units.sort((a, b) => {
                let nameA = a.displayName || a.name
                let nameB = b.displayName || b.name
                if (nameA < nameB)
                    return -1
                if (nameA > nameB)
                    return 1
                return 0
            }).map((a, ai) => (
                <GridItem
                    key={`${a._id}-${ai}`}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={3}
                    style={{ height: "calc(100% - 34px)", padding: "0px 0px !important" }}
                >
                    <UserCard
                        data={a}
                        expanded={false}
                        noActions
                        style={{ height: "calc(100% - 34px)", margin: 0 }}
                        completed={props.completed ? true : false}
                    />
                </GridItem>
            )
            )}
        </React.Fragment>
    )
}

const Plans = (props) => {
    return (
        <React.Fragment>
            {props.plans
                .sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    } else if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })
                .map((a, ai) => (
                    <GridItem
                        key={`${a._id}_${ai}`}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={2}
                    >
                        <Card data={a} db="cards" Avatar expanded ignoreList />
                    </GridItem>
                )
                )}
        </React.Fragment>
    )
}


const mapStateToProps = (store) => {
    return ({
        cards: store.db.cards,
        users: store.db.users,
        searchs: store.searchs
    })
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(App)
import {
  infoColor,
  primaryColor,
  successColor,
  dangerColor,
  warningColor,
  whiteColor,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react";

import { customApp } from "functions/";;
const timelineStyle = theme => ({
  root: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    overflowY: 'auto'
  },
  content: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    overflowY: 'auto',
    backgroundColor: 'rgba(249,249,249,1)'
  },
  timeline: {
    listStyle: "none",
    padding: "0",
    position: "absolute",
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    width: '100%',
    '& .file-drop-target': {
      width: '100%'
    }
  },
  scrollEnd: {
    marginTop: '160px',
    position: 'relative',
    width: '100%',
    height: '0px',
    marginBottom: '-100px'
  },
  scrollEndNoEditor: {
    marginTop: '30px',
    position: 'relative',
    width: '100%',
    height: '0px',
    marginBottom: '-100px'
  },
  timelineSimple: {
    position: 'relative',
    // overflowX: 'auto',
    overflow: "hidden",
    marginTop: "0px",
    padding: "0 0 50px",
    width: '-webkit-fill-available',
    "&:before": {
      left: "15px"
    },
  },
  timelineSimpleWithHeader: {
    position: 'relative',
    overflowX: 'auto',
    marginTop: "60px",
    padding: "0 0 50px",
    width: '-webkit-fill-available',
    "&:before": {
      left: "15px"
    },
  },
  item: {
    marginBottom: "5px",
    position: "relative",
    paddingLeft: "15px",
    width: 'calc(100% - 15px)',
    "&:before,&:after": {
      content: '" "',
      display: "table"
    },
    "&:after": {
      clear: "both"
    }
  },
  timelineBadge: {
    color: whiteColor,
    width: "30px",
    height: "30px",
    lineHeight: "25px",
    fontSize: "12px",
    textAlign: "center",
    position: "absolute",
    bottom: "23px",
    left: "50%",
    marginLeft: "-7px",
    zIndex: "1",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  },
  timelineSimpleBadge: {
    left: "7px"
  },
  timelineBadgeInverted: {
    color: whiteColor,
    width: "30px",
    height: "30px",
    lineHeight: "25px",
    fontSize: "12px",
    textAlign: "center",
    position: "absolute",
    bottom: "23px",
    right: "50%",
    marginRight: "-7px",
    zIndex: "1",
    borderTopRightRadius: "50%",
    borderTopLeftRadius: "50%",
    borderBottomRightRadius: "50%",
    borderBottomLeftRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"

  },
  timelineSimpleBadgeInverted: {
    right: "7px"
  },
  custom: {
    backgroundColor: customApp('color'),
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(blackColor) +
      ", 0.4)"
  },
  info: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ", 0.4)"
  },
  success: {
    backgroundColor: successColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ", 0.4)"
  },
  danger: {
    backgroundColor: dangerColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ", 0.4)"
  },
  warning: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ", 0.4)"
  },
  primary: {
    backgroundColor: primaryColor[0],
    boxShadow:
      "0 4px 20px 0px rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 7px 10px -5px rgba(" +
      hexToRgb(primaryColor[0]) +
      ", 0.4)"
  },
  badgeIcon: {
    width: "14px",
    height: "14px"
  },
  timelinePanel: {
    maxWidth: "calc(100% - 70px)",
    float: "right",
    padding: "7px",
    marginBottom: "5px",
    marginRight: "40px",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: customApp('timelineMyMessage'),
  },
  timelinePanelInverted: {
    maxWidth: "calc(100% - 70px)",
    float: "left",
    padding: "7px",
    marginLeft: "40px",
    marginBottom: "5px",
    position: "relative",
    boxShadow: "0 1px 4px 0 rgba(" + hexToRgb(blackColor) + ", 0.14)",
    borderRadius: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
    background: '#ffffff',
  },


  timelineHeading: {
    marginBottom: "0px",
    display: "flex",
    alignItems: "center"
  },
  timelineDate: {
    display: 'flex',
    alignItems: "center",
    padding: '5px',
    backgroundColor: '#CCCCCC'
  },
  timelineBody: {
    overflowWrap: "anywhere",
    fontSize: "12px",
    lineHeight: "21px",
    position: 'relative',
    width: '100%',
    float: 'left',
  },
  timelineLog: {
    fontSize: "12px",
    lineHeight: "21px",
    // display: 'flex',
  },
  timelineFooter: {
    zIndex: "1",
    position: "relative",
    float: "left",
    width: '100%',
    display: "inline-flex",
    justifyContent: "space-between"
  },
  footerUser: {
    display: "flex",
    alignItems: "center",
    fontSize: '12px',
    fontWeight: 'bold',
    color: customApp('medium'),
    padding: '0 7px',
  },
  footerLine: {
    position: 'relative',
    marginTop: "10px",
    marginBottom: "5px"
  },
  dropFile: {
    display: 'none',
  },
  onDropFile: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFFFFF',
    backgroundColor: 'rgba(0,0,0,0.77)',
    zIndex: 1,
  },
  editor: {
    position: 'absolute',
    bottom: '0px',
    minHeight: '50px',
    padding: '10px',
    height: 'auto',
    width: 'calc(100% - 0px)',
    backgroundColor: 'rgba(240,240,240,1)',
    zIndex: 10,
  },
  files: {
    display: 'inline-table',
    position: 'relative'
  },
  textEditor: {
    //display: 'flex'
  },
  textEditorIcon: {
    cursor: 'pointer',
    color: customApp('color'),
  },
  progressBar: {
    backgroundColor: `${customApp('color', '0.1')} !important`,
    '& .MuiLinearProgress-indeterminate': {
      backgroundColor: `${customApp('color')} !important`
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: `${customApp('color')} !important`
    },
  },
  actionButton: {
    alignItems: 'center',
    cursor: 'pointer',
    float: 'left',
    height: '30px',
    position: 'relative',
    textAlign: 'center',
    width: '30px',
  },
  sendingMessage: {
    width: '100%'
  },
  valuePositive: {
    positive: 'relative',
    float: 'left',
    color: 'blue',
  },
  valueNegative: {
    positive: 'relative',
    float: 'left',
    color: 'red',
  },
  lastValue: {
    positive: 'relative',
    float: 'right',
    color: 'grey',
  },
  timeInfo: {
    display: 'flex',
    alignItems: 'center',
    color: 'grey',
    width: '100%',
    position: 'relative',
    float: 'left',
    marginBottom: '7px',
    '& .text': {
      marginLeft: '7px',
    }
  },
  urlPreview: {
    position: 'relative',
    float: 'left',
    marginBottom: '33px',
    '& img': {
      maxWidth: '20%',
      height: 'auto',
      // position: 'relative',
      // float: 'left',
      marginTop: '30px',
      marginRight: '7px'
    },
    '& .data': {
      // position: 'relative',
      // float: 'left',
      //width: '75%',
      '& .title': {
        fontSize: '16px',
        fontWeight: 'bold',
      },
      '& .siteName': {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#909090'
      },
      '& .contentSnippet': {
        fontSize: '12px',
        color: '#909090'
      }
    }
  },
  users: {
    position: 'relative',
    bottom: '0px',
    right: '2px',
    fontSize: '10px',
    '& .MuiAvatarGroup-root': {
      justifyContent: 'flex-end',
    },
    '& .MuiAvatar-root': {
      width: '25px',
      height: '25px',
      fontSize: '10px',
    }
  }
});

export default timelineStyle;

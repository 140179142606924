import React from "react";
import moment from "moment-timezone";

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//@MATERIALS
import Backdrop from "@material-ui/core/Backdrop"

//COMPONENTS
import CustomButton from "components/Buttons/custom"

//FUNTIONS
import { customApp, translate } from "functions/";

function LicensingAgreement(props) {
  const { session } = reduxStore.getState()
  const AgreeRef = React.useRef()

  if (session && (!session.require || (session.require && !session.require.licensingAgreement)))
    return (<React.Fragment></React.Fragment>)


  const agree = async () => {
    let consent = {
      accepted_at: `${moment().unix("X")}`,
      version: "2022-10-24",
    }
    const { socket } = reduxStore.getState().functions
    socket.emit("data", {
      module: "account",
      method: "post",
      action: "agreement"
    }, {
      version: JSON.stringify(consent)
    })
  }

  return (
    <div id={`licensingAgreement`}

    >
      <Backdrop
        style={{
          zIndex: 30000,
          backdropFilter: "blur(7px) sepia(90%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        open={true}
      >
        <div
          style={{
            position: "fixed",
            ...window.innerWidth > 600 ? {
              top: 77,
              left: 77,
              right: 77,
              bottom: 77,
            } : {
              top: 50,
              left: 7,
              right: 7,
              bottom: 7,
            },
            ...window.innerWidth > 600 ? {
              zIndex: 3,
            } : {
              zIndex: 6000,
            },
            boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)",
            borderRadius: "15px",
            overflow: 'hidden',
            backgroundColor: "#FFFFFF"
          }}
        >
          <div style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: 40,
            background: customApp('ColumnTitleColor'),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: customApp("color"),
            fontWeight: "bold"
          }}>
            {translate(`$__licensingAgreement`)}
          </div>
          <div style={{
            position: "absolute",
            top: 40,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <iframe title="outModule"
              id="licensing"
              ref={AgreeRef}
              onLoad={() => {
                // setScrollFunction()
                console.log("licensing")
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 40,
                width: "100%",
                height: "calc(100% - 40px)",
              }}
              width={"100%"}
              height={"100%"}
              src="https://machen.ai/contracts/MACHEN-Termos-e-Condicoes-Gerais-de-Uso-de-Software-2022-10-24.pdf"
            />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: 40,
              display: "flex",
              justifyContent: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: 33
              }}
            >
              <CustomButton
                id={`agreementButtonConsent`}
                title={translate(`$__IReadAndIAgree`, "*")}
                text={translate(`$__IReadAndIAgree`, "*")}
                color={customApp("menu")}
                icon={"verified"}
                onClick={() => {
                  agree()
                }}
                size={"25px"}
              // transparent
              />
            </div>
          </div>
        </div>

      </Backdrop>

    </div>
  );
}

//REACT
const mapStateToProps = (store) => ({
  session: store.session
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LicensingAgreement)

//FUNCTIONS
import { customApp } from "functions";

const headerStyle = theme => ({
  root: {
    position: "relative",
    float: "left",
    width: "calc(100% - 20px)",
    margin: "10px",
  },
  user: {
    position: "relative",
    width: "calc(100% - 20px)",
    margin: "10px",
    float: "left",
    height: "50px"
  },
  avatar: {
    position: "absolute",
    left: "-5px",
    top: "0px",
    border: `1px solid ${customApp("color")}`,
    width: "57px",
    height: "57px",
    borderRadius: "50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundColor: "#FFFFFF"
  },
  name: {
    position: "absolute",
    top: "5px",
    left: "36px",
    right: "0px",
    height: "30px",
    border: `1px solid ${customApp("color", 0.2)}`,
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "7px",
    fontWeight: "bold",
    fontSize: "12px",
    color: customApp("darkText"),
    boxShadow: "-5px 3px 7px 1px rgba(0,0,0,0.1)"
  },
  access: {
    position: "absolute",
    top: "33px",
    left: "45px",
    right: "0px",
    height: "30px",
    border: `1px solid ${customApp("color", 0.2)}`,
    borderRadius: "0 0 5px 5px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "16px",
    fontSize: "12px",
    color: customApp("color"),
    boxShadow: "0px 3px 7px 1px rgba(0,0,0,0.05)"
  },
  accessEditing: {
    position: "absolute",
    top: "33px",
    left: "0px",
    right: "0px",
    height: "auto",
    minHeight: "290px",
    padding: "7px",
    border: `1px solid ${customApp("color", 0.2)}`,
    borderRadius: "0 0 5px 5px",
    // display: "flex",
    // alignItems: "center",
    paddingTop: '15px',
    paddingLeft: "16px",
    fontSize: "12px",
    fontWeight: "bold",
    color: customApp("color"),
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 3px 7px 1px rgba(0,0,0,0.05)",
    "& ul": {
      fontSize: "12px"
    }
  },
  btActions: {
    position: "absolute",
    right: "7px",
    top: "7px",
    display: "flex"
  },
  switch: {
    fontSize: "12px",
    fontWeight: "bold",
    color: customApp("medium")
  },
  accessDescription: {
    fontSize: "12px",
    color: customApp("medium"),
  }
});

export default headerStyle;

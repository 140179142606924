import React from 'react'
import MD5 from "crypto-js/md5"
import moment from "moment-timezone"

//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"
import history from "store/history"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//COMPONENTES
import CardsLoader from "components/Card/loader"
import ErrorPage from "components/ErrorPages"
import Filter from "componentsV3/Filter/menuFilter"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithIcon from "components/Header/withIcon"
import UserCard from "components/User/card"
import IconButton from "components/CustomButtons/IconButton"
import MoreMenu from "components/MoreMenu/"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/views/users";

//FUNCTIONS
import { getAppAccess, translate, customApp } from "functions/";
import ImportUsers from 'components/ImportUsers/ImportUsers'

//API
import api from "api"


function UsersView(props) {
    const [external] = React.useState(false)
    const [deleted] = React.useState(false)
    const [orderBy, setOrderBy] = React.useState("name")
    const { db, session } = reduxStore.getState()
    const { users } = db

    let AppAccess = getAppAccess()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let filtered = Object.keys(users).filter(fil => !users[fil].deleted).map(a => users[a])

    const saveGroupData = async (dt) => {
        api.post("/user_group/save", dt)
        let newUserGroupData = reduxStore.getState().db.userGroup

        props.reduxFunction("ASYNC", "SET_DB", {
            ...reduxStore.getState().db,
            userGroup: newUserGroupData.map(a => {
                if (a.value === dt._id) {
                    a.label = dt.name
                    if (dt.icon)
                        a.icon = dt.icon
                }
                return a
            })
        })
    }

    const searchID = props.nodeId ? props.nodeId : MD5(`${window.location.pathname}-${window.location.hash}`).toString()
    const search = props.searchs && props.searchs[searchID] ? props.searchs[searchID] : {}
    if (search.text)
        filtered = filtered.filter(fil => {
            if ((fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                || (fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                || (fil.email && fil.email.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            )
                return true
            return false
        }
        ).map(a => a)

    if (hashs[0] === 'tab' && hashs[1] === 'users') {
        filtered = filtered.filter(c => c.type === 'user' && !c._parent).map(a => a)
    }
    if (hashs[0] === 'tab' && hashs[1] === 'units') {
        filtered = filtered.filter(c => c.type !== 'user').map(a => a)
    }


    if (search?.userGroups?.length > 0)
        filtered = filtered.filter(fil =>
            fil.groups && fil.groups.length > 0 && fil.groups.filter(g => {
                let result = false
                search.userGroups.forEach(group => {
                    if (group.value === g.value)
                        result = true
                })
                return result
            }
            ).length > 0
        ).map(a => a)


    let filteredUsers = Object.keys(users).map(a => users[a])

    if (hashs[0] === 'tab' && hashs[1] === 'units' && hashs[2] && hashs[2].length === 36) {
        filteredUsers = filteredUsers.filter(c => c.type === 'user' && c._parent === hashs[2]).map(a => a)
    }

    if (search?.users && search?.userGroup.length > 0)
        filteredUsers = filteredUsers.filter(fil => fil.groups.filter(group => group.value === search.users.userGroup[0].value).length > 0).map(a => a)

    if (search.text)
        filteredUsers = filteredUsers.filter(fil => {
            if ((fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                || (fil.name && fil.name.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
                || (fil.email && fil.email.toLowerCase().indexOf(search.text.toLowerCase()) > -1)
            )
                return true
            return false
        }
        ).map(a => a)

    if (search?.tags?.length > 0)
        filteredUsers = filteredUsers.filter(fil =>
            fil.tags && fil.tags.length > 0 && fil.tags.filter(tag => {
                let result = false
                search.tags.forEach(tagFilter => {
                    if (tagFilter.value === tag.value)
                        result = true
                })
                return result
            }
            ).length > 0
        ).map(a => a)


    if (AppAccess && !AppAccess.owner && !AppAccess.user)
        return (<ErrorPage />)

    if (!window.location.hash.split('/')[0].replace('#', '')) {
        history.push('c#tab/units')
    }

    const filterUsersGroup = (group, user) => {
        if (
            reduxStore.getState().db.users[user]
            && reduxStore.getState().db.users[user].groups
            && reduxStore.getState().db.users[user].groups.length > 0
            && reduxStore.getState().db.users[user].groups.filter(ug =>
                ug.value === group.value
            ).length > 0
            && (
                !search.text
                || (
                    search.text
                    && (
                        reduxStore.getState().db.users[user].name.toLowerCase().indexOf(search.text.toLowerCase()) > -1
                        || reduxStore.getState().db.users[user]?.email?.toLowerCase().indexOf(search.text.toLowerCase()) > -1
                    )
                )
            )
            && (
                !search.tags
                ||
                (
                    reduxStore.getState().db.users[user]?.tags?.filter(ug =>
                        search.tags.filter(tag => tag.value === ug.value).length > 0
                    ).length > 0
                )
            ) &&
            (
                (
                    !external
                    && (
                        !reduxStore.getState().db.users[user]._parent
                        || reduxStore.getState().db.users[user]._parent === session.account
                    )
                )
                ||
                (
                    external
                    && reduxStore.getState().db.users[user]._parent
                    && reduxStore.getState().db.users[user]._parent !== session.account
                )
            )
            && (
                (
                    !deleted
                    && !reduxStore.getState().db.users[user].deleted
                )
                ||
                (
                    deleted
                    && reduxStore.getState().db.users[user].deleted
                )
            )
        )
            return true
        return false
    }

    return (
        <div>
            <CardsLoader />
            <div>
                <div style={{ display: "flex" }}>
                    <Filter
                        {...!(hashs[0] === 'tab' && (hashs[1] === 'departments')) ? { fields: ['text', 'tags', 'userGroups'] } : { fields: ['text', 'tags'] }}
                        nodeId={searchID}
                        showFilters
                        style={{ marginLeft: 7 }}
                        onChange={(e) => {
                            // console.log(e)
                        }}
                        title={{
                            text: translate("$__name")
                        }}
                    />
                    <MoreMenu
                        noButton={true}
                        options={[
                            ...hashs && hashs[2] && hashs[2].length === 36 && orderBy === "date" ? [{
                                color: "lightblue",
                                icon: "sort_by_alpha",
                                name: "$__orderByName",
                                onClick: () => {
                                    setOrderBy("name")
                                },
                                show: "hover",
                            }] : hashs && hashs[2] && hashs[2].length === 36 && orderBy === "name" ? [{
                                color: "lightblue",
                                icon: "login",
                                name: "$__orderByLastAccess",
                                onClick: () => {
                                    setOrderBy("date")
                                },
                                show: "hover",
                            }] : []
                        ]}
                    />
                </div>
                <GridContainer>
                    {hashs[0] === 'tab' && (hashs[1] === 'departments') ?
                        <div>
                            <div>
                                {reduxStore.getState().db.userGroup.filter(group => {
                                    if (Object.keys(reduxStore.getState().db.users).filter(user => {
                                        return filterUsersGroup(group, user)
                                    }).length > 0)
                                        return true
                                    return false
                                })
                                    .sort((a, b) => {
                                        if (a.label < b.label)
                                            return -1
                                        if (a.label > b.label)
                                            return 1
                                        return 0
                                    })
                                    .map(group => {
                                        let users = Object.keys(reduxStore.getState().db.users).filter(user => {
                                            return filterUsersGroup(group, user)
                                        })
                                        return (
                                            <div key={group.value}
                                                style={{ width: "100%", padding: 7, marginBottom: 77 }}>
                                                <HeaderWithIcon
                                                    title={group.label}
                                                    icon={group.icon || null}
                                                    color={customApp('menu')}
                                                    style={{
                                                        padding: '7px'
                                                    }}
                                                    {...AppAccess
                                                        && (
                                                            AppAccess.userAdmin
                                                            ||
                                                            AppAccess.userModerate
                                                        )
                                                        ? {
                                                            // permitIcon: true,
                                                            iconOnEditable: true,
                                                            editable: true,
                                                            onEdit: (x) => {
                                                                saveGroupData({
                                                                    ...x,
                                                                    _id: group.value
                                                                })
                                                            },
                                                            placeholder: translate("$__groupName")
                                                        } : {}}
                                                />
                                                <GridContainer>
                                                    {users.sort((a, b) => {
                                                        let nameA = reduxStore.getState().db.users[a].name.toLowerCase()
                                                        let nameB = reduxStore.getState().db.users[b].name.toLowerCase()
                                                        if (nameA < nameB)
                                                            return -1
                                                        if (nameA < nameB)
                                                            return 1
                                                        return 0
                                                    }).map(id => {
                                                        return (
                                                            <GridItem
                                                                key={`${group.value}_${id}`}
                                                                xs={12}
                                                                sm={6}
                                                                md={4}
                                                                lg={4}
                                                                xl={3}
                                                                style={{ minWidth: "300px" }}
                                                            >
                                                                <UserCard data={reduxStore.getState().db.users[id]} />
                                                            </GridItem>
                                                        )
                                                    })}
                                                </GridContainer>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {filtered.sort((a, b) => {
                                if ((a && a.name && a.name < b.name) || (users && a && a.name && a.name < b.name))
                                    return -1
                                return 1
                            }).map((us, i) => (
                                !hashs[2] || (hashs[2] && us._id === hashs[2]) ?
                                    <GridItem
                                        key={us._id}
                                        xs={12}
                                        sm={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 6}
                                        md={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 4}
                                        lg={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 4}
                                        xl={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 3}
                                        style={{ minWidth: "300px" }}
                                    >
                                        <UserCard data={us} expanded={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? true : false} />
                                    </GridItem>
                                    : !hashs[2] &&
                                    <GridItem
                                        key={us._id}
                                        xs={12}
                                        sm={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 6}
                                        md={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 4}
                                        lg={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 3}
                                        xl={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? 12 : 3}
                                    >
                                        <UserCard data={us} expanded={hashs[1] && hashs[2] && (hashs[1] === 'units') && hashs[2].length === 36 && db.users[hashs[2]] ? true : false} />
                                    </GridItem>
                            ))}
                            {hashs[0] === 'tab'
                                && hashs[1] === 'units'
                                && hashs[2]
                                && hashs[2].length === 36
                                &&
                                filteredUsers.filter(a => !a.deleted).sort((a, b) => {
                                    let dA = ""
                                    let dB = ""
                                    if (orderBy === 'date') {
                                        dA = a._lastAccess || 1
                                        dB = b._lastAccess || 1
                                    }
                                    if (orderBy === 'name') {
                                        dA = a.name
                                        dB = b.name

                                    }
                                    if ((dA < dB) || (users && dA < dB))
                                        if (orderBy === 'name') {
                                            return -1
                                        } else {
                                            return 1
                                        }
                                    if ((dA > dB) || (users && dA > dB))
                                        if (orderBy === 'name') {
                                            return 1
                                        } else {
                                            return -1
                                        }
                                    return 0
                                }).map((us, i) => (
                                    <GridItem
                                        key={`${us._id}-${i}`}
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        xl={2}
                                    >
                                        <UserCard data={us} />
                                    </GridItem>
                                ))
                            }
                        </React.Fragment>
                    }

                </GridContainer>
            </div>
            <ImportUsers />
        </div>
    );
}

//REACT
const mapStateToProps = (store) => ({
    users: store.db.users,
    userGroup: store.db.userGroup,
    page: store.db.history,
    searchs: store.searchs
});
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UsersView));
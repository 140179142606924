const initialState = {
    cards: {},
    timeline: {}
}

const toLoad = (state = initialState, data) => {
    if (data.action === 'TO_LOAD' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else {
        return state
    }
};

export default toLoad;
import React, { useState, useEffect } from "react";

//REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as reduxActions from "store/actions";
import reduxStore from "store/";

//COMPONENTES
import Icon from "components/Icon";
import SwitchAndCheck from "./SwitchAndCheck";
import CustomButton from "components/Buttons/custom";

//FUNCTIONS
import { customApp, translate } from "functions";

const Notifications = (props) => {
  const { session } = reduxStore.getState();

  const [iniData, setIniData] = useState(session.GlobalData.notifications
    ? session.GlobalData.notifications
    : [
      {
        notificationName: translate("$__activities"),
        notifications: [
          { title: translate('$__lateActivities'), machen: "block", email: null },
          { title: translate('$__dayTasks'), machen: true, email: null },
          { title: translate('$__nextActivities'), machen: false, email: null },
          { title: translate("$__statusUpdate"), machen: true, email: null },
          { title: translate("$__youWasRemoved"), machen: false, email: null },
        ],
      },
      {
        notificationName: translate("$__messages"),
        notifications: [
          { title: translate("$__unreadMessages"), machen: "block", email: true },
          { title: translate("$__reactionsMyMessages"), machen: false, email: null },
        ],
      },
      {
        notificationName: "BRIGHT & CONNECTED",
        notifications: [
          { title: translate('$__radar'), machen: true, email: false },
          { title: translate("$__newInsights"), machen: "block", email: true },
        ],
      },
      {
        notificationName: translate("$__approvals"),
        notifications: [{ title: translate("$__pendingApprovals"), machen: true, email: "block" }],
      },
      {
        notificationName: translate('$__myDay'),
        notifications: [
          { title: translate('$__myDay'), machen: "block", email: null },
          { title: translate("$__otherDays"), machen: false, email: null },
        ],
      },
    ]);
  const [data, setData] = useState();

  useEffect(() => {
    setData(
      session.GlobalData.notifications
        ? session.GlobalData.notifications
        : iniData
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveData = async () => {
    setIniData(data);
    props.reduxFunction("ASYNC", "SET_SESSION", {
      ...reduxStore.getState().session,
      GlobalData: {
        ...reduxStore.getState().session.GlobalData,
        notifications: data,
      },
    });
  };
  return (
    <>
      <div
        style={{
          width: "fit-content",
          minWidth: '65vw',
          height: "auto",
          boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
          backgroundColor: "#fff",
          borderRadius: "3px",
          fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          margin: "0 auto",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "5px",
            justifyContent: "center",
            borderBottom: "#e8e8e8 1px solid",
            padding: "7px 0px",
            color: customApp("colorText"),
            background: customApp("ColumnTitleColor"),
          }}
        >
          <Icon style={{ color: customApp('color') }}>notifications_active</Icon>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {translate('$__notificationManagement')}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0px 10px",
            alignItems: "flex-start",
            padding: "0px 20px",
            flexWrap: "nowrap",
            fontSize: "16px",
          }}
        >
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  color: customApp("darkText"),
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontWeight: "600",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    width: "60%",
                    paddingLeft: "15px",
                  }}
                >
                  {translate('$__notifyMe')}
                </p>
                <p style={{ padding: "0 10px", width: "20%" }}>Machen</p>
                <p style={{ padding: "0 10px", width: "25%" }}>{translate('$__email')}</p>
              </div>
              <div
                style={{
                  color: customApp("darkText"),
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  fontWeight: "600",
                }}
              >
                <p
                  style={{
                    textAlign: "left",
                    width: "60%",
                    paddingLeft: "15px",
                  }}
                >
                  {translate('$__notifyMe')}
                </p>
                <p style={{ padding: "0 10px", width: "20%" }}>Machen</p>
                <p style={{ padding: "0 10px", width: "25%" }}>{translate('$__email')}</p>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr 1fr",
                gap: "10px",
                flexWrap: "wrap",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              {data?.map((notifications, index) => (
                <fieldset
                  key={notifications?.notificationName}
                  style={{
                    borderColor: "rgb(224 224 224)",
                    borderStyle: "solid",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "stretch",
                    flexDirection: "column",
                    width: "100%",
                    paddingBottom: "0px",
                    gridRow: index === 0 && "1 / 3",
                  }}
                >
                  <legend
                    style={{
                      padding: "0px 5px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      fontFamily:
                        "Soleto, Roboto, Helvetica, Arial, sans-serif",
                      color: customApp("colorText"),
                      margin: "0px",
                    }}
                  >
                    {notifications?.notificationName}
                  </legend>
                  {notifications?.notifications.map((notification) => (
                    <div
                      key={notification?.title}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "baseline",
                        gap: "30px",
                      }}
                    >
                      <p style={{ width: "60%" }}>{notification?.title}</p>
                      <div style={{ width: "20%" }}>
                        <SwitchAndCheck
                          checked={notification?.machen}
                          setData={setData}
                          notificationType={"machen"}
                          data={data}
                          notification={notification}
                        />
                      </div>
                      <div style={{ width: "20%", marginRight: notification?.email === null ? '20px' : '0px' }}>
                        {notification?.email !== null &&
                          <SwitchAndCheck
                            checked={notification?.email}
                            setData={setData}
                            notificationType={"email"}
                            data={data}
                            notification={notification}
                          />}
                      </div>
                    </div>
                  ))}
                </fieldset>
              ))}
            </div>
          </div>
        </div>
      </div>
      {JSON.stringify(data) !== JSON.stringify(iniData) ? (
        <div
          style={{
            position: "fixed",
            bottom: 50,
            right: 50,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginLeft: 15 }}>
            <CustomButton
              // shadow
              title={translate("$__save")}
              text={translate("$__save")}
              color="green"
              // tr
              icon={"save"}
              size={"25px"}
              onClick={() => {
                saveData();
                // setEditor(false)
              }}
            />
          </div>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </>
  );
};
const mapStateToProps = (store) => ({
  history: store.db.history,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(reduxActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);

import React, { useEffect, useState } from 'react'

//COMPONENTS
import Meta from './Meta'

//REDUX
import reduxStore from "store/"

//Functions
import moment from "moment-timezone"

const Goal = (props) => {
    // const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');

    const { tittle, preview, icon } = props
    const [data, setData] = useState([])
    const [dashFilterChart, setDashFilterChart] = useState({})

    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);


    useEffect(() => {
        let hashs = window.location.hash.split("/")
        hashs[0] = hashs[0].replace('#', '')
        const { db } = reduxStore.getState()

        let preData = [...db.status.map((st, index) => {
            return {
                value: Object.keys(db.cards).filter(fil => {
                    let lastDateCard =
                        db.cards[fil].completed_at?.low
                        || db.cards[fil].completed_at
                        || db.cards[fil].updated_at?.low
                        || db.cards[fil].updated_at
                        || db.cards[fil].created_at?.low
                        || db.cards[fil].created_at
                    const dateCard = moment(lastDateCard * 1000);
                    const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                    const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                    return db.cards[fil]._planId === hashs[0]
                        && !db.cards[fil].checklist
                        && !db.cards[fil].deleted
                        && dateCard.isBetween(startDate, endDate, null, '[]')
                        && db.cards[fil].type === 'goal'
                        && db.cards[fil].status && db.cards[fil].status === st.value
                }).length,
            }
        })]
        let newPredata = 0
        preData.forEach((item) => {
            newPredata = item.value + newPredata
        })
        setData(newPredata)
    }, [props.planCards, dashFilterChart]);




    return (
        <div style={{ height: '100%' }}>
            <Meta
                load={props.load}
                data={data && data.toString()}
                tittle={tittle}
                preview={preview}
                icon={icon}
                edit={props.edit}
                styles={props.styles}
                id={props.id}
                handleRemoveObject={(id) => props.handleRemoveObject(id)}
            />

        </div>
    )
}

export default Goal
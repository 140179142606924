const initialState = {
    copy: [],
    selected: [],
    action: null
}

const card = (state = initialState, data) => {
    if (data.action === 'SET_CARD' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'CLEAR_CARD') {
        return {
            ...initialState
        }
    }
    else {
        return state
    }
};

export default card;
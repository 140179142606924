//@MATERIAL 
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from "@material-ui/core/styles";

const Component = withStyles({
    root: {
        '& .MuiPaper-root': {
            overflow: "visible"
        },
    },
})(Dialog);

export default Component
import React from "react"

//COMPONENTS
import GridItem from "components/Grid/GridItem"
import HeaderWithMenu from "components/Header/card"
import UiCard from "components/Card/Card"
import UiCardHeader from "components/Card/CardHeader"

//REDUX
import reduxStore from "store/"

import { translate, customApp, textColor, } from "functions"

export default function RenderStatisticsTagMap(props) {
    const { type, text, tags, tagsChartMount } = props
    const { db, } = reduxStore.getState()

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    if (hashs[0].length !== 36) {
        hashs[0] = null
    }


    let titles = {}
    if (db.cards[hashs[0]]?.customColumns)
        titles = JSON.parse(db.cards[hashs[0]].customColumns)

    // return (<></>)

    return (
        <>
            {tags && Object.keys(tags).filter(a => tags[a].label.indexOf(text) > -1).length > 0 ?
                <GridItem
                    xs={12}
                    sm={6}
                    md={6}
                    lg={3}
                    style={{
                        zIndex: 0
                    }}
                >
                    <UiCard>
                        <HeaderWithMenu
                            title={titles[type] && titles[type].name ? titles[type].name : translate(`$__${type}`, 1)}
                            // icon={titles[type].icon ? titles[type].icon : MatrixIcon}
                            color={customApp('colorText')}
                            style={{
                                background: customApp('ColumnTitleColor'),
                                padding: '7px'
                            }}
                        />
                        <UiCardHeader >
                            {Object.keys(tags)
                                .filter(a => tags[a].label.indexOf(text) > -1)
                                .sort((a, b) => {
                                    if (tags[a].label.toUpperCase().trim() < tags[b].label.toUpperCase().trim())
                                        return -1
                                    if (tags[a].label.toUpperCase().trim() > tags[b].label.toUpperCase().trim())
                                        return 1
                                    return 0
                                })
                                .forEach(tag => {
                                    let totalStatus = {}
                                    db.status.forEach(st => {
                                        totalStatus = {
                                            ...totalStatus,
                                            [st.value]: 0
                                        }
                                    })

                                    if (tagsChartMount && tagsChartMount[tag] && tagsChartMount[tag].TotalCards)
                                        return (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginBottom: 7,

                                                }}
                                                key={tag}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: tags[tag]?.color || "none",
                                                        color: tags[tag]?.color ? textColor(tags[tag].color, true) : "none",
                                                        borderRadius: 5,
                                                        padding: "3px 5px",
                                                        width: "100%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"

                                                    }}><div>{tags[tag].label} ({tagsChartMount[tag].TotalCards})</div></div>
                                            </div>
                                        )
                                })
                            }
                        </UiCardHeader>

                    </UiCard >
                </GridItem >
                : <></>
            }
        </>
    )
}
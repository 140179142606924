import api from "api"
import { v4 as uuid } from "uuid";

//REDUX
import reduxStore from "store/"


const addCard = (newData, rows = 0) => {
    const { session, db } = reduxStore.getState()
    let data = {
        ...newData
    }

    let parentData = {
        ...newData._parent && reduxStore.getState().db.cards[newData._parent] ? reduxStore.getState().db.cards[newData._parent] : {}
    }
    Object.keys(data).forEach(x => {
        if (!data[x])
            delete (data[x])
    })
    let lines = data.name.trim().split("\n")
    let count = rows

    let newCards = {}
    let users = {}

    if (data?.users)
        data.users.forEach((uid, i) => {
            let userData = {
                ...i === 0 ? {
                    access: 5,
                    isResponsible: true,
                    ...parentData?.showInPanel ? { channel: true } : {}
                } : {
                    access: 5,
                }
            }

            if (parentData
                && db.cards[parentData._id]
                && db.cards[parentData._id]._users
                && db.cards[parentData._id]._users[uid]
            )
                userData = db.cards[parentData._id]._users[uid]

            let u = db.users[uid]
            users = {
                ...users,
                [uid]: {
                    _id: uid,
                    name: u.name,
                    image: u.image || null,
                    ...userData,
                    myDay: null
                }
            }
        })

    let addIds = []

    lines.map(async (name, nameI) => {
        if (name && name.trim()) {
            let newId = uuid()
            addIds.push(newId)
            api.post('card/add', {
                _id: newId,
                ...data,
                ...parentData.showInPanel ? { channel: true } : {},
                name,
                ...data.type === "step" ? {
                    step: `${count}`
                } : {
                    order: `${count}`
                },
            });

            if (data.channel)
                delete data.channel

            newCards = {
                ...newCards,
                [newId]: {
                    _id: newId,
                    ...data,
                    name,
                    _users: users,
                    _createdBy: {
                        name: reduxStore.getState().db.users[session._id].name,
                        image: reduxStore.getState().db.users[session._id].image,
                        _id: session._id,
                        channel: parentData?.showInPanel ? true : false
                    },
                    ...parentData._planId ? { _planId: parentData._planId } : {},
                    ...parentData.type === "plan" ? { _planId: parentData._id } : {},
                    ...data.type === "step" ? {
                        step: `${count}`
                    } : {
                        order: `${count}`
                    },
                }
            }
            if (newCards[newId]?.users)
                delete newCards[newId].users
            count = count + 1
        }
    })
    return newCards
}

export {
    addCard
}

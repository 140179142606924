import React from 'react';

//@MATERIAL
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';

//FUNCTIONS
import { customApp } from "functions/";



function ProgressLinear(props) {
    const styles = {
        root: {
            position: 'relative',
            width: `100%`,
            background: `transparent !important`,
            '& .MuiLinearProgress-indeterminate': {
                background: `transparent !important`
            },
            '& .MuiLinearProgress-bar': {
                background: `${customApp('linearProgress')} !important`
            },
            '& .text': {
                position: 'absolute',
                width: '40px',
                right: '-40px',
                top: '-9px',
                textAlign: 'right',
                textSize: '11px',
                fontWeight: 'bold',
                color: customApp('color')
            }
        },
        bar: {
            borderRadius: 7,
        },

    }
    const useStyles = makeStyles(styles);

    const classes = useStyles();

    let newProps = {
        ...props
    }
    if (newProps.showText) delete newProps.showText
    if (newProps.customColor) delete newProps.customColor

    return (
        <div className={classes.root} style={props.showText ? { width: `calc(100% - 40px)` } : {}}>
            <LinearProgress {...newProps} />
            {props.showText &&
                <div className={'text'}>{Math.floor(props.value)}%</div>
            }
        </div>
    )
}

export default ProgressLinear
//CORE
import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import TextField from "components/TextField"
import Icon from "components/Icon"
import ProgressLinear from "components/Progress/linear"


//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"

//FUNCTIONS
import { translate, customApp, appAlert, } from "functions/"
import { action } from "functions/kanban"



function Component(props) {
    const [reason, setReason] = React.useState("")
    const [preloader, setPreloader] = React.useState(false)

    return (
        <div style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
            <div style={{ position: 'relative', float: 'right', width: '100%', padding: '7px' }}>
                <div style={{ width: "100%", backgroundColor: "#FFFFFF", padding: 3, borderRadius: 7, }}>
                    <TextField
                        label={translate("$__ApprovalOrRejectReason", 1)}
                        variant={customApp("fieldVariant")}
                        name="reason"
                        InputProps={{
                            autoComplete: "new-password",
                            multiline: true,
                            minRows: 3,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Icon icon="toc" />
                                </InputAdornment>
                            ),
                            onChange: (d) => {
                                setReason(d.target.value)
                            },
                        }}
                        value={reason}
                        placeholder={translate("$__ApprovalOrRejectReason", 1)}
                    />
                </div>
                {preloader ?
                    <ProgressLinear />
                    :
                    <div style={{
                        display: 'flex', alignContent: 'flex-end', justifyContent: 'center'
                    }}>
                        <CustomButton
                            reverse
                            title={translate("$__indorse")}
                            text={translate("$__indorse")}
                            color={"green"}
                            textColor={"green"}
                            icon={"approval"}
                            onClick={() => {
                                appAlert({
                                    message: translate('$__confirmApproveItem', 1),
                                    variant: 'warning',
                                    persist: false,
                                    horizontal: 'right',
                                    confirm: () => {
                                        action({ ...props, reason, approval: true, approved: true },)
                                        setPreloader(true)
                                        if (props.onAction)
                                            props.onAction()
                                    }
                                })
                            }}
                            size={"25px"}
                            style={{ padding: 12 }}
                        />
                        <CustomButton
                            reverse
                            title={translate("$__reject")}
                            text={translate("$__reject")}
                            color={"red"}
                            textColor={"red"}
                            icon={"backspace"}
                            onClick={() => {
                                appAlert({
                                    message: translate('$__confirmRejectItem', 1),
                                    variant: 'warning',
                                    persist: false,
                                    horizontal: 'right',
                                    confirm: () => {
                                        action({ ...props, reason, approval: true })
                                        if (props.onAction)
                                            props.onAction()
                                    }
                                })
                            }}
                            size={"25px"}
                            style={{ padding: 12 }}
                        />
                    </div>
                }
            </div>
        </div >
    )
}

const mapStateToProps = (store) => ({
    cards: store.db.cards,
}
)
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Component)

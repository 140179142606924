import React from "react"

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import File from "components/Files/file_V2"
import HeaderWithIcon from "components/Header/card"
import Icon from "components/Icon"
import LinearProgress from "components/Progress/linear"
import TextField from "components/TextField/"
import ErrorBoundary from "components/ErrorBoundary"

//@MATERIAL
import InputAdornment from '@material-ui/core/InputAdornment';
import TablePagination from "@material-ui/core/TablePagination"

//FUNCTIONS
import {
    translate,
} from "functions/"

import { customApp } from "functions"

import { navFiles, navFolders } from "functions/files"

function LibraryView(props) {
    const { db, session } = reduxStore.getState()
    const [mounted, setMounted] = React.useState(false)
    const [selectedParent, setSelectedParent] = React.useState(null)
    const [page, setPage] = React.useState(0)
    const [limit, setLimit] = React.useState(10)
    const [files, setFiles] = React.useState([])
    const [countFiles, setCountFiles] = React.useState(0)
    const [orderBy, setOrderBy] = React.useState('created_at')
    const [orderDESC, setorderDESC] = React.useState(true)
    const [searchText, setSearchText] = React.useState("")
    const [preloader, setPreloader] = React.useState(true)
    const [folders, setFolders] = React.useState({})
    const [searchFolder, setSearchFolder] = React.useState("")

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace("#", "")

    let idPlan = null
    if (
        hashs[0]
        && hashs[0].length === 36
        && reduxStore.getState().db.cards[hashs[0]]
        && reduxStore.getState().db.cards[hashs[0]]._users
        && reduxStore.getState().db.cards[hashs[0]]._users[session._id]
    ) {
        idPlan = hashs[0]
    }

    React.useEffect(() => {
        reqFolders({ id: idPlan })
        navFolders()
        return () => {
            setFiles([])
            setFolders({})
            setPreloader(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (selectedParent)
            reqFiles({ id: selectedParent })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParent])

    React.useEffect(() => {
        setPage(0)
        setPreloader(true)
        if (!mounted) {
            setMounted(true)
        }
        const timer = setTimeout(() => {
            files && reqFiles({ id: idPlan })
        }, 1000)
        return () => clearTimeout(timer)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    React.useEffect(() => {
        reqFiles({ id: idPlan })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, orderBy, orderDESC])


    const reqFolders = async (data, fds) => {
        let lf = await navFolders({
            ids: data && data.id ? [data.id] : [],
            childrens: false,
            type: data.type ? data.type : null
        })
        if (lf) {
            let ff = fds ? fds : folders
            if (idPlan) {
                ff = {
                    ...ff,
                    [idPlan]: {
                        name: reduxStore.getState().db.cards[idPlan].name,
                        parent: reduxStore.getState().db.cards[idPlan]._parent,
                        private: null,
                        type: "plan",
                        _id: idPlan,
                        open: true,
                    }
                }
            }
            lf.forEach(folder => {
                ff = {
                    ...ff,
                    [folder._id]: {
                        ...ff[folder._id] ? ff[folder._id] : {},
                        ...folder,
                    }
                }
            })
            setFolders(ff)
        }
    }

    const reqFiles = async (data) => {
        let lf = await navFiles({
            ids: data && data.id ? [data.id] : [],
            type: (data && data.type) || "cards",
            limit,
            skip: page * limit,
            asc: !orderDESC,
            orderBy: orderBy || "created_at",
            search: searchText
        })
        if (lf) {
            setCountFiles(lf && lf[0] && lf[0].total ? parseInt(lf[0].total) : 0)
            setFiles(lf)
            setPreloader(false)
        }
    }



    let lightBoxfiles = []


    const openLightBox = (f) => {
        props.reduxFunction("ASYNC", "LIGHTBOX", {
            open: true,
            files: lightBoxfiles,
            file: f
        })
    }

    const order = (by) => {
        let ob = orderBy
        let desc = orderDESC
        if (orderBy === by) {
            desc = !orderDESC
        } else {
            ob = by
        }
        setOrderBy(ob)
        setorderDESC(desc)
    }

    const openFolder = async (data, option = false) => {
        const { id = null, type = null } = data
        let nFolders = folders

        nFolders = {
            ...nFolders,
            [id]: {
                ...nFolders[id],
                open: option,
                loading: true,
            }
        }
        if (Object.keys(folders).filter(a => folders[a].parent === id).length === 0) {
            reqFolders({ id, type }, nFolders)
        } else {
            setFolders(nFolders)
        }
    }

    const RenderFolder = (folder, f) => (
        <div
            key={f}
            style={{
                cursor: "pointer",
                borderLeft: "solid 0.5px lightgray",
                paddingLeft: 7,
                color: customApp("color")
            }}
            onClick={(e) => {
                if (!folder.private ||
                    (folder.private && reduxStore.getState().controls.lockPrivate)
                ) {
                    openFolder({
                        id: f,
                    }, folders[f] && folders[f].open ? false : true)
                    setSelectedParent(f)
                } else {

                }
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            <HeaderWithIcon
                private={folder.private && !reduxStore.getState().controls.lockPrivate ? true : false}
                unlockedPrivates={props.unlockedPrivates}
                setPrivate={(a) => {
                    let value = a ? 1 : -1
                    if (!a && props.unlockedPrivates === 0)
                        value = 0
                    props.reduxFunction("ASYNC", "SET_CONTROL", {
                        ...reduxStore.getState().controls,
                        unlockedPrivates: reduxStore.getState().controls.unlockedPrivates + value,
                    })
                }}
                moreButton={true}
                title={folder.name}
                tooltipText={`${folder.private && !reduxStore.getState().controls.lockPrivate ? translate("$__private") : folder.name}`}
                icon={(folder.type && db && db.dataType && db.dataType.filter(a => a.name === folder.type)[0].icon) || "$__topic"}
                fontSize="12px"
                color={customApp("color")}
            />
            {folders[f] && folders[f].open ? reqSubFolders(f) : <React.Fragment></React.Fragment>}
        </div>
    )

    const reqSubFolders = (id = null) => {
        return (
            <div style={{
                marginLeft: 7
            }}>
                {Object.keys(folders).filter(folder =>
                (
                    (
                        !id
                    )
                    ||
                    (
                        id &&
                        folders[folder].parent === id
                    )
                )
                ).length > 0 ?
                    Object.keys(folders).filter(folder =>
                    (
                        (
                            !id
                        )
                        ||
                        (
                            id &&
                            folders[folder].parent === id
                        )
                    )
                    ).sort((a, b) => {
                        if (folders[a].name.toLowerCase().trim() < folders[b].name.toLowerCase().trim())
                            return -1
                        if (folders[a].name.toLowerCase().trim() > folders[b].name.toLowerCase().trim())
                            return 1
                        return 0
                    }).map(f => {
                        return RenderFolder(folders[f], f)
                    }) : <React.Fragment></React.Fragment>
                }
            </div>
        )
    }

    return (
        <ErrorBoundary>
            <div>
                <div style={{
                    backgroundColor: "#FFFFFF",
                    position: "relative",
                    padding: "7px 15px",
                    float: "right",
                    boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
                    display: "flex",
                    alignItems: "center"
                }}>
                    <TextField
                        label={translate('$__search', '*')}
                        variant={'standard'}
                        autoFocus
                        value={searchText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Icon color={customApp('color')}>search</Icon>
                                </InputAdornment>
                            ),
                            onChange: e => {
                                setSearchText(e.target.value)
                            },
                            onKeyDown: e => {
                                // if (String(e.which) === '27')
                                //     setviewSearch(false)
                            }
                        }}
                    />
                    <div style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <CustomButton
                            id={`sideModule_saveButton`}
                            title={orderBy === "created_at" && orderDESC ? translate(`$__mostRecent`, 1) : translate(`$__olderFirst`, 1)}
                            color={orderBy === "created_at" ? customApp("color") : 'gray'}
                            icon={"calendar_month"}
                            onClick={() => { order('created_at') }}
                            size={"25px"}
                        />
                        {orderBy === "created_at" &&
                            <div
                                style={{
                                    position: "absolute",
                                    ...orderDESC ? { bottom: 0 } : { top: 0 },
                                    right: -5
                                }}>
                                <Icon
                                    size="15px"
                                    color={customApp("menu")}
                                    style={{ marginLeft: "-3px" }}
                                >{orderDESC ? "south" : "north"}</Icon>
                            </div>
                        }
                    </div>
                    <div style={{
                        position: "relative",
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <CustomButton
                            id={`sideModule_saveButton`}
                            title={orderBy === "name" && orderDESC ? translate(`$__nameDesc`, 1) : translate(`$__nameAsc`, 1)}
                            color={orderBy === "name" ? customApp("color") : 'gray'}
                            icon={"sort_by_alpha"}
                            onClick={() => { order('name') }}
                            size={"25px"}
                        />
                        {orderBy === "name" &&
                            <div
                                style={{
                                    position: "absolute",
                                    ...orderDESC ? { bottom: 0 } : { top: 0 },
                                    right: -5
                                }}>
                                <Icon
                                    size="15px"
                                    color={customApp("menu")}
                                    style={{ marginLeft: "-3px" }}
                                >{orderDESC ? "south" : "north"}</Icon>
                            </div>
                        }
                    </div>
                </div>
                <div style={{
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 0px 7px 3px rgba(0,0,0,0.1)",
                    clear: "both",
                    marginTop: -10,
                    minHeight: "calc(100vh - 150px)"
                }}>
                    <div style={{
                        position: "absolute",
                        width: 333,
                        height: "100%",
                        minHeight: 1,
                        padding: 15,
                        backgroundColor: "#f4f4f4",
                        borderRight: "solid 0.77px lightGray",
                        display: "flow-root"
                    }}>
                        <div style={{ display: "flex", marginTop: -10 }}>
                            <HeaderWithIcon
                                title={translate("$__folders", 1)}
                                icon={"folder"}
                                fontSize="20px"
                                color={customApp("menu")}
                            />
                            <TextField
                                label={translate('$__search', '*')}
                                variant={'standard'}
                                autoFocus
                                value={searchFolder}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <Icon color={customApp('lightgray')}>search</Icon>
                                        </InputAdornment>
                                    ),
                                    onChange: e => {
                                        setSearchFolder(e.target.value)
                                    },
                                    onKeyDown: e => {
                                        // if (String(e.which) === '27')
                                        //     setviewSearch(false)
                                    }
                                }}
                            />
                        </div>
                        <div style={{
                            position: "relative",
                            height: "calc(100% - 33px)",
                            overflowX: "auto",
                            backgroundColor: "rgba(250,250,250,0.9)",
                            borderRadius: "solid 0.5px rgba(0,0,0,0.1)",
                            padding: 15
                        }}>
                            {Object.keys(folders).filter(folder =>
                                (
                                    (
                                        (
                                            !hashs[0]
                                            ||
                                            (
                                                hashs[0]
                                                && hashs[0].length !== 36
                                            )
                                        )
                                        &&
                                        !folders[folder].parent
                                    )
                                    ||
                                    (
                                        (
                                            hashs
                                            && hashs[0]
                                            && hashs[0].length === 36
                                        )
                                        && (
                                            (
                                                folders[folder]._id === hashs[0]
                                            )
                                        )
                                    )
                                )
                                &&
                                (
                                    searchFolder === ""
                                    ||
                                    (
                                        searchFolder
                                        && folders[folder].name.toLowerCase().indexOf(searchFolder.toLowerCase().trim()) > -1
                                    )
                                )
                            ).sort((a, b) => {
                                if (folders[a].name.toLowerCase().trim() < folders[b].name.toLowerCase().trim())
                                    return -1
                                if (folders[a].name.toLowerCase().trim() > folders[b].name.toLowerCase().trim())
                                    return 1
                                return 0
                            }).map(f => {
                                return RenderFolder(folders[f], f)
                            }
                            )}
                        </div>
                    </div>
                    <div style={{ position: "relative", float: "left", marginLeft: 333, width: "calc(100% - 333px)", overflowY: "auto" }}>
                        <div style={{
                            position: "relative",
                            width: "100%",
                            padding: "15px",
                        }}>
                            <div style={{ position: "relative", float: "left", minWidth: 177 }}>
                                <HeaderWithIcon
                                    title={translate("$__files", 1)}
                                    icon={"cloud"}
                                    fontSize="20px"
                                    color={customApp("menu")}
                                />
                            </div>
                            <div style={{ position: "relative", float: "right", display: "flex", alignItems: "center", marginTop: -10 }}>
                                {/* PAGINATION */}
                                {translate("$__page")} {page + 1} {translate("$__of")} {Math.ceil(countFiles / limit)}
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <TablePagination
                                        component="div"
                                        count={countFiles}
                                        page={page}
                                        onPageChange={(event, newPage) => {
                                            setPage(newPage)
                                        }}
                                        rowsPerPage={limit}
                                        onRowsPerPageChange={(event) => {
                                            setLimit(parseInt(event.target.value, 10))
                                        }}
                                        labelRowsPerPage={translate("$__filesPerPage")}
                                    />
                                </div>
                            </div>
                        </div>
                        {preloader ?
                            <LinearProgress />
                            :
                            files && files.length > 0 ? files.map((f, fi) => {
                                lightBoxfiles.push(f.data)
                                let file = {
                                    ...f.data,
                                    createdBy: f.createdBy,
                                    in: f.in,
                                    // parents: f.parents.filter(a => a._id).length > 0 ? f.parents.reverse() : []
                                }
                                return (
                                    <div
                                        key={`file_${file._id}${fi}`}
                                        style={{
                                            position: "relative",
                                            width: "100%",
                                            paddingTop: "7px",
                                            paddingLeft: "15px",
                                            clear: "both"
                                        }}
                                    >
                                        <File
                                            data={file}
                                            openLightBox={
                                                () => openLightBox(file._id)
                                            }
                                            admin={
                                                file.createdBy._id === session._id
                                                    ||
                                                    file.in._id === session._id
                                                    ||
                                                    (file.access && parseInt(file.access) > 2)
                                                    ? true : false
                                            }
                                        />
                                        <div style={{ clear: "both" }}></div>
                                    </div>
                                )
                            }
                            ) : <React.Fragment></React.Fragment>}
                        {
                            countFiles > 10 ?
                                <div style={{
                                    position: "relative",
                                    width: "100%",
                                    padding: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    {/* PAGINATION */}
                                    {translate("$__page")} {page + 1} {translate("$__of")} {Math.ceil(countFiles / limit)}
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <TablePagination
                                            component="div"
                                            count={countFiles}
                                            page={page}
                                            onPageChange={(event, newPage) => {
                                                setPage(newPage)
                                            }}
                                            rowsPerPage={limit}
                                            onRowsPerPageChange={(event) => {
                                                setLimit(parseInt(event.target.value, 10))
                                            }}
                                            labelRowsPerPage={translate("$__filesPerPage")}
                                        />
                                    </div>
                                </div>
                                : <React.Fragment></React.Fragment>
                        }
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>

            </div >
        </ErrorBoundary>
    )
}

const mapStateToProps = (store) => ({
    showPrivate: store.controls.lockPrivate,
    history: store.db.history,
    unlockedPrivates: store.controls.unlockedPrivates,
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps
)(LibraryView)
import React from "react"
import moment from "moment-timezone"
import styled from "styled-components"

//REDUX
import * as reduxActions from "store/actions"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

//COMPONENTS
import Badge from "components/Icon/Badge"
import CardTags from "components/Tags/Card"
import NotificationCount from "componentsV3/Notifications/count"
import HtmlTooltip from "components/Tooltip/html"
import Icon from "components/Icon"
import QuickUsersList from "components/User/avatarList"

//@MATERIAL
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/CardSimple.js"

//FUNCTIONS
import { translate, customApp, getDate } from "functions/"


const Container = styled.div`
${props =>
        props.selectable && props.selected ? `
    opacity:1;`
            : props.selectable ? `
    opacity:0.5;`
                : ``
    }
${props =>
        props.locked ?
            `cursor:no-drop !important;`
            : `cursor:pointer;`
    }
${props =>
        props.priority ?
            `border-left:5px solid ${props.priority};`
            : ``
    }
${props =>
        !props.selectable && props.selected ?
            `border-bottom:solid 5px ${customApp("color")};`
            : ``
    }
${props =>
        props.isDragDisable
            ? ""
            : props.isDragging
                ? `opacity:0.88;
                 max-width:320px;`
                : ``
    }
${props =>
        props.inLine ?
            `position:relative;
            float:left;
            margin:1px;
            width:calc(20% - 7px);
            // maxWidth:320px;
            `
            : `
            width:calc(100%) !important;
            margin:1px 0px 1px 0px;
            `
    }
    width:calc(100%) !important;
    margin-top:7px;
    margin-right:7px;
    padding:15px;
`
function Card(props) {
    const { classes } = props;
    const { db } = reduxStore.getState();


    const mounted = React.useRef(true)

    React.useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const data = props.data && props.data._id ? reduxStore.getState().db.cards[props.data._id] : {}

    // const init = async () => {
    //      loadCardsV2({ ...props, ignoreLoader: true }, {
    //         idRel: [data._parent],
    //         cardLoad: "stepRels"
    //     })
    // }
    // React.useEffect(() => {
    //     init()
    // }, [])

    if (data.name === "Teste - MKT GJP")
        console.log(data, reduxStore.getState().db.cards[data._parent])

    let steps = Object.keys(reduxStore.getState().db.cards).filter(card =>
        reduxStore.getState().db.cards[card]
        && data
        && data._parent
        && reduxStore.getState().db.cards[data._parent]
        && reduxStore.getState().db.cards[card]._parent === reduxStore.getState().db.cards[data._parent]._parent
        && reduxStore.getState().db.cards[card].type === "step"
        && !reduxStore.getState().db.cards[card].deleted
    )
        .sort((a, b) => {
            let orderA = parseInt(reduxStore.getState().db.cards[a].step)
            let orderB = parseInt(reduxStore.getState().db.cards[b].step)

            if (orderA < orderB)
                return -1
            if (orderA > orderB)
                return 1
            return 0
        }).map(card => reduxStore.getState().db.cards[card])

    return (
        <Container
            {...props?.provided?.draggableProps || {}}
            ref={(props?.provided && props.provided?.innerRef) || null}
            isDragging={(props.snapshot && props.snapshot.isDragging) || false}
            isDragDisable={data.isDragDisable || props.isDragDisable || false}
            inLine={props.inLine || null}
            className={classes.container}
            selectable={props.showSelected || false}
            selected={data._selected || false}
            priority={data.priority ? db.priority.filter(a => String(a.value) === String(data.priority))[0].color : null}
            locked={props.locked || false}
            onClick={() => {
                props.reduxFunction("ASYNC", "SET_MODULE", {
                    ...reduxStore.getState().sideModule,
                    db: 'cards',
                    id: data._id,
                    module: "cardEdit",
                    activeModule: reduxStore.getState().db.dataType.filter(a => a.name === data.type)[0].iniModule || module,
                    data: data,
                })
            }}
        >
            <div {...(props.provided && props.provided.dragHandleProps) || {}}>

                <div
                    style={{
                        position: 'relative',
                        alignItems: 'center'
                    }}
                >

                    <div style={{
                        width: '100%',
                        padding: '7px',
                        display: "flex",
                        justifyContent: "space-between",
                    }}>
                        {data.name ? data.name : translate("$__new", 1)}
                        <div style={{
                            fontSize: 10,
                            color: "gray"
                        }}>
                            {translate("$__createdAt")}:{getDate(data.created_at)}
                        </div >
                        <NotificationCount nodeId={data._id} />
                    </div >
                    <div style={{
                        position: "relative",
                        float: "left",
                        width: "100%"
                    }}>
                        <div>{translate("$__step", "*")} {steps.findIndex(a => a._id === data._parent) + 1} {translate("$__of")} {steps.length}</div>
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                border: "solid 1px rgba(0,0,0,0.1)",
                                justifyContent: "space-between",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: 7
                            }}
                        >
                            {steps.map((x, xi) =>
                                <HtmlTooltip
                                    arrow
                                    key={x._id}
                                    title={(
                                        <div>
                                            {x.name} ({steps.findIndex(a => a._id === data._parent) > xi ? translate(`$__completed`) : steps.findIndex(a => a._id === data._parent) === xi ? translate(`$__${data.status}`) : translate(`$__waitingForPreviousStep`)})
                                        </div>
                                    )
                                    } >
                                    <div
                                        key={x._id}
                                        style={{
                                            height: 7,
                                            ...steps.findIndex(a => a._id === data._parent) > xi ? { backgroundColor: "#02b915" } : {},
                                            ...steps.findIndex(a => a._id === data._parent) === xi ? { backgroundColor: "#00a2e9" } : {},
                                            ...steps.findIndex(a => a._id === data._parent) < xi ? { backgroundColor: "#e9e9e9" } : {},
                                            width: "-webkit-fill-available",
                                            border: "solid 1px #FFFFFF"
                                        }}></div>
                                </HtmlTooltip>
                            )}
                            <HtmlTooltip
                                arrow
                                title={(
                                    <div>
                                        {translate(`$__${data.status}`, '*')}
                                    </div>
                                )
                                } >
                                <div>
                                    <Icon color={(data.status && reduxStore.getState().db.status.filter(st => st.value === data.status)[0].color) || null}>{reduxStore.getState().db.status.filter(st => st.value === data.status)[0].icon}</Icon>
                                </div>
                            </HtmlTooltip>
                        </div>
                    </div>

                    <div style={{
                        width: '100%', padding: '7px',
                        clear: "both"
                    }}>
                        {((data.startDate || data.endDate) || (data.startDate || data.endDate)) &&
                            <div className={classes.dates}>
                                {data.startDate &&
                                    <div className={classes.dateInfo}>
                                        <Badge
                                            overlap="rectangular"
                                            className={classes.dateIco} icon="insert_invitation" title={"startDate"} size="14px" disabled />
                                        {(new Date(data.startDate * 1000) instanceof Date && !isNaN(new Date(data.startDate * 1000))) ?
                                            moment(new Date(data.startDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                            :
                                            moment(data.startDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                        }
                                    </div>
                                }
                                {data.endDate &&
                                    <div className={classes.dateInfo}>
                                        <Badge
                                            overlap="rectangular"
                                            className={classes.dateIco} icon="event_available" title={"endDate"} size="14px" disabled />
                                        {(new Date(data.endDate * 1000) instanceof Date && !isNaN(new Date(data.endDate * 1000))) ?
                                            moment(new Date(data.endDate * 1000)).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                            :
                                            moment(data.endDate).tz("America/Sao_Paulo").format("DD/MM/YYYY")
                                        }
                                    </div>
                                }
                            </div>
                        }
                        {data && data._users && Object.keys(data._users).length > 1 &&
                            <div className={classes.usersContent}>
                                <div className={classes.users}>
                                    <QuickUsersList _id={data._id} data={data} transparent myDay limit={3} />
                                </div>
                            </div>
                        }
                    </div>
                    <div style={{
                        position: "relative",
                        width: "100%",
                        float: "left"
                    }}>
                        <CardTags options={data._tags} showOptions={true} />
                    </div>
                </div>
            </div>

        </Container>
    )
}

//REACT
const mapStateToProps = (store) => {
    return {
        store: {
            cards: store.db.cards,
        }
    }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(Card));
import React, { useEffect, useState } from 'react'

//COMPONENTS
import Meta from './Meta'
import MatrixIcon from "assets/icons/Matrix"

//REDUX
import reduxStore from "store/"

//Functions
import moment from "moment-timezone"


const Guideline = (props) => {
    const { tittle, preview } = props
    const [dashFilterChart, setDashFilterChart] = useState({})
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()

    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);


    let preData = [...db.status.map((st, index) => {
        return {
            value: Object.keys(db.cards).filter(fil => {
                let lastDateCard =
                    db.cards[fil].completed_at?.low
                    || db.cards[fil].completed_at
                    || db.cards[fil].updated_at?.low
                    || db.cards[fil].updated_at
                    || db.cards[fil].created_at?.low
                    || db.cards[fil].created_at
                const dateCard = moment(lastDateCard * 1000);
                const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                return db.cards[fil]._planId === hashs[0]
                    && !db.cards[fil].checklist
                    && dateCard.isBetween(startDate, endDate, null, '[]')
                    && !db.cards[fil].deleted
                    && db.cards[fil].type === 'guideline'
                    && db.cards[fil].status && db.cards[fil].status === st.value
            }).length,
        }
    })]
    let newPredata = 0
    preData.forEach((item) => {
        newPredata = item.value + newPredata
    })
    return (
        <div style={{ height: '100%' }}>
            <Meta
                data={newPredata}
                tittle={tittle}
                load={props.load}
                preview={preview}
                icon={MatrixIcon}
                edit={props.edit}
                id={props.id}
                styles={props.styles}
                handleRemoveObject={(id) => props.handleRemoveObject(id)} />
        </div>
    )
}

export default Guideline
import React from 'react'
import { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom';

//GRID
import { Responsive, WidthProvider } from "react-grid-layout"
import "react-grid-layout/css/styles.css"
import "react-resizable/css/styles.css"

//FUNCTIONS
import { getChildrens, getUserAccess } from "functions/cards";
import { v4 as uuid } from "uuid";
import { translate } from 'functions';
import { getAppAccess } from 'functions'

//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import Icon from 'components/Icon';
import ErrorBoundary from 'components/ErrorBoundary';
import { appAlert } from 'functions';
import Filter from 'components/Dashboards/Filter'
import ButtonOptions from 'componentsV3/Buttons'
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck';
import { updateCard } from "functions/cards"
import { initialState1, initialState2 } from './GetInitialState';
import PalleteButton from './PalleteButton';
import FilterDash from 'componentsV3/FilterDashboard/index';
import DisplayFilterOptions from 'componentsV3/FilterDashboard/DisplayFilterOptions';

//STYLES
import "./Widgets/Loader.css"

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = (props) => {

  const [state, setState] = useState([])
  const [filter, setFilter] = useState(false)
  const [filteredCards, setFilteredCards] = useState({})
  const [edit, setEdit] = useState(false)
  const [key1, setKey1] = useState(0)
  const [editState, setEditState] = useState([])
  const [load, setLoad] = useState(true)
  const { db } = reduxStore.getState()
  const [dashboardConfig, setDashboardConfig] = useState({
    filter: {}
  })

  const stateRef = useRef([{}])
  const filterRef = useRef(false)
  const filterStateRef = useRef({})


  let hashs = window.location.hash.split("/")
  hashs[0] = hashs[0].replace('#', '')

  let AppAccess = getAppAccess()
  let CardAccess = getUserAccess(hashs[0])

  //header
  const dashboardStyles = {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.80)'
  }

  // const jsonCards = JSON.stringify(props.cards)

  const divRef = useRef(null)

  const pupulateArr = (component, title, datagrid, type, chart, icon, widget, filter, indexarr) => {
    let newGraph = {
      component: component,
      name: title,
      type: type,
      datagrid: datagrid,
      chart: chart,
      icon: icon,
      widget: widget,
      key: uuid(),
      filter: {
        ...(filter ? filter : {}),
        ...dashboardConfig
      }
    }
    if (indexarr !== undefined) {
      setState(prev => prev.map((elemento, index) => (index === indexarr ? newGraph : elemento)));
    } else {
      edit ? setEditState(prev => [...prev, newGraph]) : setState(prev => [...prev, newGraph]);
    }
  }


  useEffect(() => {
    if (props.loaders) {
      if (
        Object.keys(props.loaders).length > 0 &&
        Object.keys(props.loaders).filter(l => props.loaders[l] === "loading").length > 0
      ) {
        if (load !== false)
          setLoad(true);
      } else {
        setLoad(false);
      }
    } else {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loaders])



  useEffect(() => {
    let initialState = []
    const getInitialState = () => {
      let hashs = window.location.hash.split("/")
      hashs[0] = hashs[0].replace('#', '')
      const localstorage = reduxStore.getState().db.cards[hashs[0]].customDashboard ? Object.values(JSON.parse(reduxStore.getState().db.cards[hashs[0]].customDashboard)) : null
      if (localstorage) {
        localstorage.sort((a, b) => {
          if (a.datagrid.y === b.datagrid.y) {
            return a.datagrid.x - b.datagrid.x;
          }
          return a.datagrid.y - b.datagrid.y;
        });

        let newState = []

        localstorage.map(async (obj, index) => {
          const { widget } = obj;
          let nameWidget = `${widget.substr(0, 1).toUpperCase()}${widget.substr(1, widget.length)}`
          const module = await import(`./Widgets/${nameWidget}.jsx`);
          const DynamicComponent = module.default;
          let newGraph = {
            component: DynamicComponent,
            name: obj.name,
            type: obj.name,
            datagrid: obj.datagrid,
            chart: obj.name,
            icon: obj.icon,
            widget: obj.widget,
            key: uuid(),
            filter: obj.filter ? obj.filter : false
          }
          newState = [...newState, newGraph]
          if (index === localstorage.length - 1) {
            setState(newState)
            newGraph?.filter?.colorsArr && setDashboardConfig({
              ...dashboardConfig,
              colorsArr: newGraph?.filter?.colorsArr
            })
          }
        });


      } else {
        if (db.cards[hashs[0]].planType !== "operational") {
          initialState = initialState1
        } else {
          initialState = initialState2
        }
        let newState = []
        initialState.forEach((item, index) => {
          let newGraph = {
            component: item.Component,
            name: item.name,
            type: item.name,
            datagrid: item.datagrid,
            chart: item.name,
            icon: item.icon,
            widget: item.widget,
            key: uuid(),
            filter: false
          }
          newState = [...newState, newGraph]
          if (index === initialState.length - 1) {
            setState(newState)
          }
        })
      }
    }

    if (!edit && Object.keys(filteredCards).length > 0) {

      getInitialState()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCards, edit]);



  useEffect(() => {

    if (!edit) {
      if (state.length === 0) {
        const localstorage = reduxStore.getState().db.cards[hashs[0]].customDashboard ? Object.values(JSON.parse(reduxStore.getState().db.cards[hashs[0]].customDashboard)) : null
        if (localstorage) {
          setState(localstorage)
        } else {
          let initialState = []
          if (db.cards[hashs[0]].planType !== "operational") {
            initialState = initialState1
          } else {
            initialState = initialState2
          }
          setState(initialState)
        }
      }
      const searchCards = async () => {
        await new Promise(resolve => setTimeout(resolve, 100));
        let rq = await getChildrens(hashs[0])
        if ((JSON.stringify(filteredCards) !== JSON.stringify(rq)))
          setFilteredCards(rq)
      }
      searchCards()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cards])

  function handleRemoveObject(key) {
    setEditState(prevArray => prevArray.filter(obj => obj.key !== key));
    // const updatedArray = state.filter(obj => obj.key !== key);
  }

  const change = (any, layouts) => {
    if (layouts?.lg?.length > 0) {
      stateRef.current = layouts?.lg
    }
  };


  const save = () => {

    let data = {}
    editState.forEach((item) => {
      stateRef.current.forEach((datagrid) => {
        if (datagrid.i === item.key) {
          data = {
            ...data,
            [item.key]: {
              ...item,
              datagrid: datagrid
            }
          }
        }
      })
    })

    if (Object.keys(filterRef).length > 0)
      editState.forEach((item) => {
        Object.values(filterStateRef.current).forEach((filterObj) => {
          if (filterObj.id === item.key) {
            data = {
              ...data,
              [item.key]: {
                ...data[item.key],
                filter: filterObj
              }
            }
          }
        })
      })
    updateCard(props, {
      _id: hashs[0],
      customDashboard: JSON.stringify(data)
    })

    props.reduxFunction("ASYNC", "SET_DB", {
      ...reduxStore.getState().db,
      cards: {
        ...reduxStore.getState().db.cards,
        [hashs[0]]: {
          ...reduxStore.getState().db.cards[hashs[0]],
          customDashboard: JSON.stringify(data)
        },
      }
    })

    //ifResponseTrue
    appAlert({
      message: `Dados salvos`,
      variant: 'info',
      persist: false,
      horizontal: 'right',
      confirm: null
    })

    setState(editState)
    setEdit(false);
  }



  const renderComponent = () => {
    if (filterRef.current) {
      ReactDOM.render(<Filter
        category={'plan'}
        filter={filter}
        onClose={() => ReactDOM.unmountComponentAtNode(document.getElementById('render-container'))}
        addWidget={(Component, values, datagrid, type, chart, icon, widget, filter) => pupulateArr(Component, values, datagrid, type, chart, icon, widget, filter)}
      />, document.getElementById('render-container'));
    } else {
      ReactDOM.unmountComponentAtNode(document.getElementById('render-container'));
    }
  };


  const callFilter = () => {
    filterRef.current = true
    setFilter(true)
    renderComponent()
  };

  const handleColorSet = (colors) => {
    let newArr = editState.map((item) => {
      return {
        ...item,
        filter: {
          ...item.filter,
          colorsArr: colors
        }
      }
    })
    setDashboardConfig({
      ...dashboardConfig,
      colorsArr: colors
    })
    setEditState(newArr)
  }

  const handleDashboardConfig = (values) => {
    setDashboardConfig({
      ...dashboardConfig,
      ...values
    })
  }


  const handleFilterData = (data) => {
    filterStateRef.current = {
      ...filterStateRef.current,
      [data.id]: {
        ...filterStateRef.current[data.id],
        ...data
      }
    }
  }

  const handleSetTags = (data) => {
    let newState = (edit ? editState : state).map((item) => {
      let newItem = item
      if (item.key === data.id) {
        newItem = {
          ...newItem,
          filter: {
            ...newItem.filter,
            tagsColors: data?.tagsColors,
            mainColorTags: data?.mainColor
          }
        }
      }

      if (Object.keys(filterRef).length > 0) {
        Object.values(filterStateRef.current).forEach((filterObj) => {
          if (filterObj.id === data.id) {
            newItem = {
              ...newItem,
              filter: {
                ...newItem.filter,
                tagsColors: data?.tagsColors
              }
            }
          }
        })
      }

      return newItem
    })
    edit ? setEditState([...newState]) : setState([...newState]);

  }

  const handleDatatGridTable = (id) => {
    let newState = (edit ? editState : state).map((item) => {
      let newItem = item
      if (item.key === id) {
        let newKey = uuid()
        newItem = {
          ...newItem,
          filter: {
            ...item.filter,
            table: item.filter.table ? !item.filter.table : true
          },
          key: newKey
        }
      }

      if (Object.keys(filterRef).length > 0) {
        Object.values(filterStateRef.current).forEach((filterObj) => {
          if (filterObj.id === item.key) {
            newItem = {
              ...newItem,
              filter: {
                ...newItem.filter,
                ...filterObj,
                table: newItem.filter.table,
              }
            }
          }
        })
      }

      if (Object.keys(stateRef).length > 0) {
        Object.values(stateRef.current).forEach((chart) => {
          if (chart.i === id) {
            newItem = {
              ...newItem,
              datagrid: {
                ...newItem["datagrid"],
                h: !item.filter.table ? (chart["h"] * 2 < 8 ? chart["h"] * 2 : 8) : (chart["h"] / 2 > 6 ? chart["h"] / 2 : 6),
                w: chart.w
              },
            }
          }
        })
      }
      return newItem
    })
    edit ? setEditState([...newState]) : setState([...newState]);
  }


  return (
    <div ref={divRef} key={key1} style={{ width: '100%', marginTop: '8px', marginBottom: '8%', fontSize: '19px', color: 'grey', overflowX: 'hidden', overflowY: 'hidden', paddingBottom: '80px', minHeight: '90vh' }}>
      <div id="render-container"></div>
      <div style={{ display: 'flex' }}>
        {edit &&
          <>
            <div style={{ marginLeft: '10px' }} onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
              onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
              < ButtonOptions
                text={translate("$__addCharts")}
                icon={"add"}
                onClick={() => callFilter()}
              >
              </ButtonOptions>
            </div>

            <div style={{ marginLeft: '10px' }} >
              <PalleteButton
                handleColorSet={(colorArr) => handleColorSet(colorArr)}
              >
              </PalleteButton>
            </div>

            <div style={{ marginLeft: '10px' }} onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
              onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
              <ButtonOptions
                text={translate("$__save")}
                icon={"save"}
                onClick={() => save()}
              >
              </ButtonOptions>
            </div>
          </>
        }
        {!edit && <div style={{ marginLeft: '10px' }} onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
          onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
          <ButtonOptions
            text={"Selecionar periodo"}
            icon={"filter_alt"}
            popper={
              <div style={{ padding: '15px' }}>
                <FilterDash
                  fields={['date']}
                  showFilters
                  style={{ zIndex: '999' }}
                  indicators={dashboardConfig?.filter || {}}
                  onChange={(e) => {
                    setDashboardConfig({
                      ...dashboardConfig,
                      filter: { ...e }
                    })
                  }}
                />
              </div>
            }
            style={{
              alignSelf: 'center',
              right: '107px',
            }}
          />
        </div>}

        {((AppAccess.plan && AppAccess.planAdmin) || (CardAccess > 3)) &&
          <div style={{ marginLeft: '10px' }} onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
            onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
            < ButtonOptions
              text={!edit ? translate("$__editMode") : translate("$__cancel")}
              icon={!edit ? "edit" : 'close'}
              onClick={() => {
                if (!edit) {
                  setEditState(state)
                  setEdit(prev => !prev);
                } else {
                  setEdit(prev => !prev);
                  setEditState(state)
                  setKey1(prev => prev + 1);
                }
              }}
              style={{ backgroundColor: edit && "white", color: edit && "black " }}>
            </ButtonOptions>
          </div>}
      </div>
      {dashboardConfig?.filter?.startDate && <div style={{
        marginRight: '15px',
        marginTop: '20px',
        border: '1px solid #D3D3D3',
        marginLeft: '10px',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '370px'
      }}>
        <DisplayFilterOptions handleDateChange={(e) => setDashboardConfig({
          ...dashboardConfig,
          filter: {
            ...dashboardConfig.filter,
            ...e
          }
        })} options={dashboardConfig.filter} />
      </div>}
      {
        (edit ? editState : state).length > 0 &&
        <ResponsiveGridLayout
          breakpoints={{ lg: 1200 }} // tamanho de tela
          cols={{ lg: 12, ll: 4 }} // coluna para cada tamanho
          rowHeight={80} // altura da linha
          isResizable={edit}
          onLayoutChange={change}
          style={{ zIndex: '0' }}
          draggableHandle='.drag-handle'>
          {(edit ? editState : state).filter(({ component, key, name, datagrid, type, icon, filter }) => key && name && datagrid && type && icon)
            .map(({ component, key, name, datagrid, type, icon, filter, tableKey, colorsArr }) => {
              const Component = component ? component : false
              return (
                <div key={key} data-grid={datagrid} className='box'>
                  <div style={{ height: '100%', width: '100%' }}>
                    <ViewportChecker >
                      {(inViewport) => (
                        <ErrorBoundary message={name}>
                          {(Object.keys(filteredCards).length > 0 && (typeof Component === 'function' || typeof Component?.WrappedComponent === 'function') && (inViewport || edit)) ?
                            <Component
                              styles={dashboardStyles}
                              handleRemoveObject={(key) => handleRemoveObject(key)}
                              edit={edit}
                              handleDatagridTable={() => handleDatatGridTable(key)}
                              handleSetFilter={(data) => handleFilterData(data)}
                              handleSetTags={(data) => handleSetTags(data)}
                              handleDashboardConfig={(data) => handleDashboardConfig(data)}
                              tittle={name} preview={false}
                              filterRef={filterStateRef.current?.[key]}
                              type={type}
                              icon={icon}
                              datagrid={datagrid}
                              planCards={filteredCards}
                              colorsArr={colorsArr}
                              id={key}
                              dashboardConfig={dashboardConfig}
                              filter={filter ? filter : {}}
                            />
                            : <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                              <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: 'white' }}>
                                <Icon size={24} style={{ alignSelf: 'center', marginLeft: '17px', color: 'rgba(0, 0, 0, 0.67)', marginTop: '1px' }}>blur_on</Icon>
                                <p style={{ margin: '0px', marginLeft: '7px', alignSelf: 'center', fontWeight: 'bold', marginTop: '2px', color: 'rgba(0, 0, 0, 0.67)', fontSize: '16px' }}>{translate(`$__${name}`, 1)}</p>
                              </div>
                              <div style={{ flex: '1', display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
                                <div style={{ alignSelf: 'center' }}>
                                  <div className="loader"></div>
                                </div>
                              </div>
                            </div>
                          }
                        </ErrorBoundary>
                      )}
                    </ViewportChecker>
                  </div>
                </div>
              )
            })}
        </ResponsiveGridLayout>
      }
    </div >
  )
}

const mapStateToProps = (store) => ({
  cards: store.db.cards,
  loaders: store.sessionLoads.cards.load
})
const mapDispatchToProps = dispatch =>
  bindActionCreators(reduxActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)




const initialState = {
    open: false,
    miro: false,
    files: [],
    file: 0
}

const sideModule = (state = initialState, data) => {
    if (data.action === 'LIGHTBOX' && data.value) {
        return {
            ...state,
            ...data.value,
        }
    } else if (data.action === 'LIGHTBOX_CLOSE') {
        return {
            ...initialState
        }
    }
    else {
        return state
    }
};

export default sideModule;
import React from 'react'
import Icon from 'components/Icon'

const TasksPreview = (props) => {
    return (
        <div style={
            {
                width: '100%',
                backgroundColor: 'white',
                height: "100%",
                display: 'flex',
                boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                justifyContent: 'center',
            }}>
            <div style={{ display: "flex", justifyContent: 'center', width: '210px', alignSelf: 'center' }}>
                <Icon size={40} icon={props.icon ? props.icon : 'close'} style={{ alignSelf: 'center' }}></Icon>
                <p style={{ margin: '0px', marginLeft: '10px', alignSelf: 'center', fontSize: '16px', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.67)' }}>
                    {props.title ? props.title : "Visualização não disponivel com filtro de período"}
                </p>
            </div>
        </div>
    )
}

export default TasksPreview
import React, { useState, useEffect, useRef } from 'react'
import moment from "moment-timezone"

//FUNCTIONS
import { translate } from "functions"

//COMPONENTS
import HeaderWithMenu from "components/Header/card"
import UiCard from "components/Card/Card"
import UiCardBody from "components/Card/CardBody"
import { ResponsivePie } from '@nivo/pie'
import Icon from "components/Icon"
import './Loader.css';
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck'


//REDUX
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as reduxActions from 'store/actions'
import reduxStore from "store/"


const ActionsDetailed = (props) => {

    const today = moment().tz('America/Sao_Paulo').format('YYYY/MM/DD');
    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')
    const { db } = reduxStore.getState()
    let titles = {}
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [data, setData] = useState([])
    const keysLabel = useRef([])



    useEffect(() => {

        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
    }, [props.dashboardConfig]);

    useEffect(() => {
        let newData = [
            ...db.status.map((st, index) => {


                return {
                    id: st.label.substring(3) === 'await' ? translate('$__awaitAction') : translate(st.label),
                    label: st.label.substring(3) === 'await' ? '$__awaitAction' : st.label,
                    value: Object.keys(props.planCards).filter(fil => {

                        let lastDateCard =
                            db.cards[fil].completed_at?.low
                            || db.cards[fil].completed_at
                            || db.cards[fil].updated_at?.low
                            || db.cards[fil].updated_at
                            || db.cards[fil].created_at?.low
                            || db.cards[fil].created_at

                        const dateCard = moment(lastDateCard * 1000);

                        const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                        const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                        return db.cards[fil]._planId === hashs[0]
                            && dateCard.isBetween(startDate, endDate, null, '[]')
                            && !db.cards[fil].deleted
                            && db.cards[fil].status
                            && db.cards[fil].status === st.value
                            && (
                                db.cards[fil].type === 'iniciative'
                                || db.cards[fil].type === 'task'
                                || db.cards[fil].type === 'meeting'
                                || db.cards[fil].type === 'iniciative'
                                || db.cards[fil].type === 'myTask'
                            )
                            && (
                                !db.cards[fil].checklist
                                || (
                                    db.cards[fil].checklist
                                    && db.cards[fil].showCard
                                )
                            )
                    }).length,
                    color: st.color
                }
            }),
            {
                id: translate('$__late'),
                label: '$__late',
                value: Object.keys(props.planCards).filter(fil => {
                    let lastDateCard =
                        db.cards[fil].completed_at?.low
                        || db.cards[fil].completed_at
                        || db.cards[fil].updated_at?.low
                        || db.cards[fil].updated_at
                        || db.cards[fil].created_at?.low
                        || db.cards[fil].created_at

                    const dateCard = moment(lastDateCard * 1000);

                    const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                    const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                    return db.cards[fil]._planId === hashs[0]
                        && !db.cards[fil].deleted
                        && dateCard.isBetween(startDate, endDate, null, '[]')
                        && db.cards[fil].status !== 'completed'
                        && (
                            db.cards[fil].type === 'iniciative'
                            || db.cards[fil].type === 'task'
                            || db.cards[fil].type === 'meeting'
                            || db.cards[fil].type === 'iniciative'
                            || db.cards[fil].type === 'myTask'
                        )
                        && (
                            !db.cards[fil].checklist
                            || (
                                db.cards[fil].checklist
                                && db.cards[fil].showCard
                            )
                        )
                        && db.cards[fil].endDate && moment(new Date(db.cards[fil].endDate * 1000)).tz('America/Sao_Paulo').format() <= moment(new Date(today)).tz('America/Sao_Paulo').format()
                }).length,
                color: 'red'
            }
        ]
        newData = newData.map((item) => {
            if (props.filter.colorsArr) {
                let newColor = ''
                props.filter.colorsArr.forEach((colorObj) => {
                    if (item.label.substring(3) === colorObj.label)
                        newColor = colorObj.color
                })
                return {
                    ...item,
                    color: newColor
                }
            }
            else return item
        })
        newData.forEach((item) => {
            const newLabel = {
                label: item.label.substring(3),
                color: item.color
            };
            if (!keysLabel.current.some(item => item.label === newLabel.label)) {
                keysLabel.current = [...keysLabel.current, newLabel];
            }
        })
        setData(newData)
    }, [props.planCards, props.filter, dashFilterChart]);

    if (db.cards[hashs[0]]?.customColumns)
        titles = JSON.parse(db.cards[hashs[0]].customColumns)

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div style={{ height: '100%' }}>
                        <UiCard style={{ height: '100%', marginTop: '0', display: 'flex' }}>
                            {props.edit ? <div style={{ width: '100%', height: '50px', display: 'flex', borderBottom: '1px solid #D3D3D3', backgroundColor: props.styles.backgroundColor ? props.styles.backgroundColor : 'white' }}>
                                <Icon size={24} style={{ alignSelf: 'center', marginLeft: '12.5px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>play_arrow</Icon>
                                <p style={{ margin: '0px', marginLeft: '7px', alignSelf: 'center', fontWeight: 'bold', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)' }}>{translate(`$__actions`, 1)}</p>
                                <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                    <Icon onClick={() => props.handleRemoveObject(props.id)} style={{ height: '25px', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', cursor: 'pointer', position: 'absolute', right: '20px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                        close
                                    </Icon>
                                </div>
                                <div onMouseEnter={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.12)' }}
                                    onMouseLeave={(e) => { e.target.style.backgroundColor = 'rgba(0, 0, 0, 0)' }}>
                                    <Icon className='drag-handle' style={{ height: '25px', cursor: 'move', color: props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)', position: 'absolute', right: '62px', top: '8px', border: '1px solid  #D3D3D3', padding: '14px' }}>
                                        open_with
                                    </Icon>
                                </div>
                            </div> : <HeaderWithMenu
                                title={titles?.actions?.name ? titles.actions.name : translate(`$__actions`, 1)}
                                icon={titles?.actions?.icon ? titles.actions.icon : "play_arrow"}
                                color={props.styles.color ? props.styles.color : 'rgba(0, 0, 0, 0.67)'}
                                style={{
                                    background: props.styles.backgroundColor ? props.styles.backgroundColor : 'white',
                                    padding: '12.4px',
                                    borderRadius: '5px 5px 0px 0px ',
                                    fontWeight: 'normal',
                                    borderBottom: '1px solid #D3D3D3'
                                }}
                            />}
                            <div style={{ display: 'flex', width: '100%', borderBottom: '1px solid #D3D3D3', position: 'relative', flex: '1 1 auto' }}>
                                {props.load && <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                                </div>}
                                {(!props.load && inViewport) &&
                                    <div style={{ position: "absolute", width: '100%', height: '100%', backgroundColor: '#f5f5f5' }}>
                                        <div className='componentCircle' style={{ position: "absolute", width: '100%', height: '100%' }}>
                                            <ResponsivePie
                                                data={data}
                                                colors={{ datum: 'data.color' }}
                                                margin={{ top: 17, right: 17, bottom: 17, left: 17 }}
                                                arcLabelsTextColor={{ from: 'color', modifiers: [['brighter', 3]] }}
                                                innerRadius={0.5}
                                                padAngle={3}
                                                cornerRadius={3}
                                                theme={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    legend: false,
                                                    background: 'transparent',
                                                }}
                                                activeOuterRadiusOffset={8}
                                                borderWidth={1}
                                                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                                                enableArcLinkLabels={false}
                                            />
                                        </div>
                                    </div>}
                            </div>
                            {(!props.load && inViewport) && <UiCardBody style={{ height: '15%', maxHeight: '280px', backgroundColor: 'white' }}>
                                <h3>
                                    <small>{translate('$__total', 1)}: {Object.keys(props.planCards)
                                        .filter(fil => {
                                            let lastDateCard =
                                                db.cards[fil].completed_at?.low
                                                || db.cards[fil].completed_at
                                                || db.cards[fil].updated_at?.low
                                                || db.cards[fil].updated_at
                                                || db.cards[fil].created_at?.low
                                                || db.cards[fil].created_at

                                            const dateCard = moment(lastDateCard * 1000);

                                            const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                                            const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                                            return db.cards[fil]._planId === hashs[0]
                                                && !db.cards[fil].deleted
                                                && dateCard.isBetween(startDate, endDate, null, '[]')
                                                && (
                                                    db.cards[fil].type === 'iniciative'
                                                    || db.cards[fil].type === 'task'
                                                    || db.cards[fil].type === 'meeting'
                                                    || db.cards[fil].type === 'iniciative'
                                                    || db.cards[fil].type === 'myTask'
                                                )
                                                && (
                                                    !db.cards[fil].checklist
                                                    || (
                                                        db.cards[fil].checklist
                                                        && db.cards[fil].showCard
                                                    )
                                                )
                                        }).length}
                                    </small>
                                </h3>
                                {db.status.map((st, index) => {
                                    return (
                                        <div key={`${index}_${st.value}`} style={{ color: 'black', display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', width: '100%' }}>
                                            <div style={{ display: 'flex' }}>
                                                <Icon color={st.color}>{st.icon}</Icon>
                                                <span style={{ marginLeft: '15px' }} >{translate(st.label, 1)}</span>
                                            </div>
                                            <div>
                                                {Object.keys(props.planCards)
                                                    .filter(fil => {
                                                        let lastDateCard =
                                                            db.cards[fil].completed_at?.low
                                                            || db.cards[fil].completed_at
                                                            || db.cards[fil].updated_at?.low
                                                            || db.cards[fil].updated_at
                                                            || db.cards[fil].created_at?.low
                                                            || db.cards[fil].created_at

                                                        const dateCard = moment(lastDateCard * 1000);

                                                        const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                                                        const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                                                        return db.cards[fil]._planId === hashs[0]
                                                            && !db.cards[fil].deleted
                                                            && dateCard.isBetween(startDate, endDate, null, '[]')
                                                            && (
                                                                db.cards[fil].type === 'iniciative'
                                                                || db.cards[fil].type === 'task'
                                                                || db.cards[fil].type === 'meeting'
                                                                || db.cards[fil].type === 'iniciative'
                                                                || db.cards[fil].type === 'myTask'
                                                            )
                                                            && (
                                                                !db.cards[fil].checklist
                                                                || (
                                                                    db.cards[fil].checklist
                                                                    && db.cards[fil].showCard
                                                                )
                                                            )
                                                            && db.cards[fil].status && db.cards[fil].status === st.value
                                                    }).length}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div style={{ color: 'black', display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', width: '100%' }}>
                                    <div style={{ display: 'flex' }}>
                                        <Icon color={'red'}>assignment_late</Icon>
                                        <span style={{ marginLeft: '15px' }} >{translate('$__late', 1)}</span>
                                    </div>
                                    <div>
                                        {Object.keys(props.planCards).filter(fil => {
                                            let lastDateCard =
                                                db.cards[fil].completed_at?.low
                                                || db.cards[fil].completed_at
                                                || db.cards[fil].updated_at?.low
                                                || db.cards[fil].updated_at
                                                || db.cards[fil].created_at?.low
                                                || db.cards[fil].created_at

                                            const dateCard = moment(lastDateCard * 1000);

                                            const startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000) : moment().subtract(10, 'years')
                                            const endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000) : moment()

                                            return db.cards[fil]._planId === hashs[0]
                                                && !db.cards[fil].deleted
                                                && dateCard.isBetween(startDate, endDate, null, '[]')
                                                && db.cards[fil].status !== 'completed'
                                                && (
                                                    db.cards[fil].type === 'iniciative'
                                                    || db.cards[fil].type === 'task'
                                                    || db.cards[fil].type === 'meeting'
                                                    || db.cards[fil].type === 'iniciative'
                                                    || db.cards[fil].type === 'myTask'
                                                )
                                                && (
                                                    !db.cards[fil].checklist
                                                    || (
                                                        db.cards[fil].checklist
                                                        && db.cards[fil].showCard
                                                    )
                                                )
                                                && db.cards[fil].endDate && moment(new Date(db.cards[fil].endDate * 1000)).tz('America/Sao_Paulo').format() <= moment(new Date(today)).tz('America/Sao_Paulo').format()
                                        }).length}
                                    </div>
                                </div>
                            </UiCardBody>}
                            {/* <UiCardFooter stats>
                            <div className={classes.stats}>
                                <Danger>
                                    <Warning />
                                    </Danger>
                                    <a href="#pablo" onClick={e => e.preventDefault()}>
                                    {translate('$__updatePlan', 1)}
                                    </a>
                            </div>
                        </UiCardFooter> */}
                        </UiCard>
                    </div>
                )}
            </ViewportChecker>
        </div>
    )
}

const mapStateToProps = (store) => ({
    store: { cards: store.db.cards }
}
)

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch);

export default connect(mapStateToProps,
    mapDispatchToProps
)(ActionsDetailed);

import React from 'react'
import { Icon, Chip, InputLabel, FormControl, makeStyles, Input, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles({
  TextField:{
    color: 'rgb(150,150,150)',
    width: '100%',
    padding: '0',
    fontFamily: '"Soleto", "Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '300',
    fontSize:'14px',
    '& label':{
      marginTop: '5px',
      fontSize:'14px',
    },
    '& .Mui-focused':{
      color: 'rgb(150,150,150)',
    },
    '& .MuiInput-underline:after':{
      border: '1px solid rgb(192,178,131)',
    },
    '& .MuiInput-underline:before:before':{
      borderBottom: '1px solid rgb(192,178,131)',
    },
    
  },
})

const ModalNotes = ({note, setModal}) => {
    const style = useStyles()
  return (
    <div style={{
        backgroundColor:'rgba(0 0 0 / 30%)',
        width:'100%',
        height:'100%',
        position:'fixed',
        zIndex:'1000',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        top:'0',
        left:'0',
    }}>
        <div
          style={{
            width:'400px',
            height: "auto",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            backgroundColor: "#fff",
            borderRadius: "3px",
            fontFamily: "Soleto, Roboto, Helvetica, Arial, sans-serif",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
              justifyContent: "space-between",
              borderBottom: "#e8e8e8 1px solid",
              padding: "10px 15px",
              color: "rgb(192,178,131)",
              background:
                "linear-gradient(90deg, rgb(1, 30, 65) 0%, rgb(0, 49, 112) 100%)",
            }}
          >
            <span>
              Nova
            </span>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}>
                <Icon>assistant_navigation</Icon>
                <Icon style={{
                    cursor:'pointer'
                }}
                onClick={() => setModal(false)}>close</Icon>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection:'column',
              gap: "15px",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "auto",
              padding:'5px 15px',
            }}
          >
            <FormControl className={style.TextField} variant="standard">
              <InputLabel htmlFor="titleForm" className={style.TextField}>
                Nova Sub Atividade
              </InputLabel>
              <Input
                id="titleForm"
                type={"text"}
              />
            </FormControl>
            <div style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent:'flex-start',
              gap:'10px',
            }}>
              {note?.tags.map((option, index) => (
                  <Chip label={option} key={index} size="small" />
              ))}
            </div>
            <div style={{
                width:'100%',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent:'space-between',
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                    cursor:'pointer',
                    color:'grey',
                }}
                onClick={() => setModal(false)}>
                    <Icon style={{
                      fontSize:'25px',
                    }}>cancel</Icon>
                    <p style={{
                      fontSize:'0.85rem',
                    }}>CANCELAR</p>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                    cursor:'pointer',
                    color:'#008000',
                }}>
                    <Icon style={{
                      fontSize:'25px',
                    }}>save</Icon>
                    <p style={{
                      fontSize:'0.85rem',
                    }}>SALVAR</p>
                </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ModalNotes
const defaultState = {
    notifications: [],
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case 'NEW_ALERT':
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.value.options.key,
                        ...action.value,
                    },
                ],
            };

        case 'CLOSE_ALERT':
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.dismissAll || notification.key === action.value)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            };

        case 'REMOVE_ALERT':
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.value,
                ),
            };

        default:
            return state;
    }
};

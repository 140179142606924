import React, { useEffect, useState, useRef } from 'react'

//COMPONENTS
import Legend from '../Legend';
import { ResponsiveBar } from '@nivo/bar'
import Header from 'components/Dashboards/Header'
import './Loader.css';
import ViewportChecker from 'componentsV3/ViewerportCheck/ViewrportCheck';

//REDUX
import reduxStore from "store/"
import moment from "moment-timezone"
import { translate } from 'functions';
import Icon from 'components/Icon';


const Barchart = (props) => {

    const keysLabel = useRef([])
    const { db } = reduxStore.getState()
    const [dashFilterChart, setDashFilterChart] = useState({})
    const [filter, setFilter] = useState({
        index: 0,
        showQuantity: 10,
        number: 10,
        finalIndex: 10
    })
    const [data, setData] = useState({})

    let hashs = window.location.hash.split("/")
    hashs[0] = hashs[0].replace('#', '')

    useEffect(() => {
        if (Object.keys(props.filter).length > 0) {
            setFilter({ ...filter, ...props.filter })
        }
        if (Object.keys(props?.dashboardConfig?.filter).length > 0) {
            setDashFilterChart({
                ...filter,
                ...props?.dashboardConfig?.filter,
                colorsArr: props.dashboardConfig?.colorsArr
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.filter, props.dashboardConfig]);

    useEffect(() => {
        let usersUsed = {}
        if (props.planCards && !props.load) {
            Object.keys(props.planCards).length > 0 && Object.keys(props.planCards).filter((fil) => !db.cards[fil].deleted).forEach(cardId => {

                Object.keys(db.cards[cardId]?._users).forEach(u => {
                    let usersFilter = filter?.users?.length > 0 ? filter.users.includes(db.cards[cardId]?._users[u]._id) : true


                    if (usersFilter) {
                        if (!usersUsed[u]) {
                            usersUsed = {
                                ...usersUsed,
                                [u]: {
                                    ...usersUsed[u],
                                    [db.cards[cardId].status]: 0,
                                    "allTask": 0,
                                    "user": db.cards[cardId]?._users[u].name.match(/\b(\w)/g).join(''),
                                    "id": u,
                                    "image": db.cards[cardId]?._users[u].image,
                                    "name": db.cards[cardId]?._users[u].name
                                }
                            }
                        }


                        // if (db.cards[cardId]?._users[u].isResponsible) {
                        let lastDateCard =
                            db.cards[cardId].completed_at?.low
                            || db.cards[cardId].completed_at
                            || db.cards[cardId].updated_at?.low
                            || db.cards[cardId].updated_at
                            || db.cards[cardId].created_at?.low
                            || db.cards[cardId].created_at

                        let dateCard = moment(lastDateCard * 1000).format("YYYYMMDD");
                        let startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000).format("YYYYMMDD") :
                            (filter.startDate ? moment(filter.startDate * 1000).format("YYYYMMDD") : moment().subtract(1, 'month').format("YYYYMMDD"));

                        let endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000).format("YYYYMMDD") :
                            (filter.endDate ? moment(filter.endDate * 1000).format("YYYYMMDD") : moment().format("YYYYMMDD"))

                        if (parseInt(dateCard) >= parseInt(startDate) && parseInt(dateCard) <= parseInt(endDate)) {
                            let newColor = db.status.filter(fil => fil.value === db.cards[cardId].status)[0].color
                            if (filter?.colorsArr) {
                                filter.colorsArr.forEach((item) => {
                                    if (item.label === db.cards[cardId].status) {
                                        newColor = item.color;
                                    }
                                });
                            }
                            if (db.cards[cardId].type !== 'step')
                                usersUsed = {
                                    ...usersUsed,
                                    [u]: {
                                        ...usersUsed[u],
                                        [db.cards[cardId].status]: usersUsed[u]?.[db.cards[cardId].status] ? usersUsed[u]?.[db.cards[cardId].status] + 1 : 1,
                                        "allTask": usersUsed[u]?.["allTask"] ? usersUsed[u]?.["allTask"] + 1 : 1,
                                        [db.cards[cardId].status + 'Color']: newColor
                                    }
                                }
                            const newLabel = {
                                label: db.cards[cardId].status,
                                color: newColor
                            }
                            if (!keysLabel.current.some(item => item.label === newLabel.label))
                                keysLabel.current = [...keysLabel.current, newLabel]
                        }
                    }
                    // }
                }
                )
            })
            if (data !== usersUsed) {
                setData(usersUsed)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.planCards, props.load, filter, dashFilterChart]);

    const getNames = (userID) => {
        let name = ""
        Object.values(data).forEach((item) => {
            if (item.id === userID) {
                name = item?.name.match(/\b(\w)/g).join('')
            }
        })
        return name
    }

    let startDate = dashFilterChart?.startDate ? moment(dashFilterChart?.startDate * 1000).format("DD/MM/YYYY") :
        (filter.startDate ? moment(filter.startDate * 1000).format("DD/MM/YYYY") : moment().subtract(1, 'month').format("DD/MM/YYYY"));

    let endDate = dashFilterChart?.endDate ? moment(dashFilterChart?.endDate * 1000).format("DD/MM/YYYY") :
        (filter.endDate ? moment(filter.endDate * 1000).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY"))

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <ViewportChecker>
                {(inViewport) => (
                    <div className='box' style={{ width: '100%', height: '100%', alignSelf: 'center', borderRadius: '5px', backgroundColor: 'transparent', display: 'flex', flexFlow: 'column', overflow: 'visible' }}>
                        <Header
                            handleSetSelected={(value) => {
                                setFilter({ ...filter, ...value })
                                props.handleSetFilter({ ...filter, ...value, id: props.id })
                            }}
                            filterConfig={{ filterSelect: Object.keys(data).length > 0 && Object.values(data).length }}
                            tittle={translate("$__" + props.tittle)}
                            icon={props.icon}
                            styles={props.styles}
                            indicators={filter && filter}
                            datafilter={true}
                            handleRemoveObject={() => props.handleRemoveObject(props.id)}
                            edit={props.edit}
                            handleSetoption={(value) => {
                                setFilter({ ...value })
                                props.handleSetFilter({ ...filter, ...value, id: props.id })
                            }}
                        />
                        <div style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}></div>
                        <div style={{}} >
                        </div>
                        <div style={{ position: 'relative', flex: '1 1 auto', fontSize: '16px' }}>
                            {(!props.load && inViewport) && <div style={{ position: 'absolute', right: '2%', top: '2%', padding: '5px', display: 'flex', zIndex: '9', justifyContent: 'center', alignContent: 'center' }}>
                                <div style={{ marginTop: '2px', marginRight: '4px' }}>
                                    {startDate + ' ~ '}
                                    {endDate + ' -- '}
                                    {filter.showQuantity < Object.keys(data).length ? filter.showQuantity + "/" : Object.keys(data).length + "/"}
                                    {Object.keys(data).length > 0 && <span>{Object.keys(data).length}</span>}
                                </div>
                                <Icon>person</Icon>
                            </div>}
                            {(filter.index !== 0) &&
                                <Icon
                                    onClick={() =>
                                        setFilter(
                                            {
                                                ...filter,
                                                index: filter.index - 1,
                                                finalIndex: filter.finalIndex - 1
                                            })
                                    }
                                    style={
                                        {
                                            position: 'absolute',
                                            zIndex: '999',
                                            top: '48%',
                                            left: '10px'
                                        }}>
                                    arrow_back_ios
                                </Icon>
                            }
                            {(Object.keys(data).length !== filter.index + filter.number && Object.keys(data).length > filter.showQuantity) && <Icon onClick={() => setFilter({ ...filter, index: filter.index + 1, finalIndex: filter.finalIndex + 1 })} style={{ position: 'absolute', zIndex: '999', top: '48%', right: '10px' }}>arrow_forward_ios</Icon>}
                            <div style={{ width: '100%', height: '100%', position: 'absolute', fontWeight: 'lighter', backgroundColor: '#f5f5f5' }}>
                                {(Object.keys(data).length > 0 && (!props.load && inViewport)) ?
                                    <div className='component' style={{ width: '100%', height: '100%' }}>
                                        <ResponsiveBar
                                            data={Object.values(data).sort(function (a, b) {
                                                if (a["allTask"] > b["allTask"]) {
                                                    return -1;
                                                }
                                                if (a["allTask"] < b["allTask"]) {
                                                    return 1;
                                                }
                                                return 0;
                                            }).slice(filter.index, filter.finalIndex)}
                                            keys={[
                                                'inProgress',
                                                'completed',
                                                'notStarted',
                                                'paused',
                                                'awaitAction'
                                            ]} // data
                                            colors={({ id, data }) => data[`${id}Color`]}
                                            tooltip={point => {

                                                return <div style={{
                                                    fontSize: '12px',
                                                    width: '200px',
                                                    marginBottom: '10px',
                                                    zIndex: '99999'
                                                }}>
                                                    <div style={{ display: 'flex', marginLeft: '5px', marginTop: '10px', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                width: '40px',
                                                                height: '32px',
                                                                backgroundImage: `url("${point.data.image}")`,
                                                                backgroundColro: "lightgray",
                                                                backgroundSize: 'cover',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center center',
                                                                borderRadius: '50%',
                                                                alignSelf: 'center'
                                                            }}
                                                        ></div>
                                                        <p style={{ margin: '0px', marginLeft: '12px', fontSize: '20px', alignSelf: 'center', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{point.data.name}</p>
                                                    </div>
                                                    <div style={{ borderBottom: '1px solid #d1d1cf', marginTop: '10px' }}></div>
                                                    <div style={{ width: "100%", fontSize: '18px', padding: '10px', marginBottom: '5px', marginTop: '4px' }}>
                                                        {Object.entries(point.data).map(([key, val] = Object.entries(point.data)) => {
                                                            if ([
                                                                'inProgress',
                                                                'completed',
                                                                'notStarted',
                                                                'paused',
                                                                'awaitAction'
                                                            ].includes(key)) {
                                                                let color = ''
                                                                switch (key) {
                                                                    case 'inProgress': color = "#011E41"; break;
                                                                    case 'completed': color = "#5A7F71"; break;
                                                                    case 'notStarted': color = "#929497"; break;
                                                                    case 'paused': color = "#EBA900"; break;
                                                                    case 'awaitAction': color = "pink"; break;
                                                                    default: color = 'white'
                                                                }
                                                                if (filter.colorsArr)
                                                                    filter.colorsArr.forEach((item) => {
                                                                        if (item.label === key)
                                                                            color = item.color
                                                                    })
                                                                return <div style={{ display: 'flex', marginBottom: '8px' }} key={key}>
                                                                    <div style={{ borderRadius: '50%', backgroundColor: color, height: '20px', width: '20px', alignSelf: 'center', marginRight: '10px' }}> </div>
                                                                    <div style={{ marginLeft: '14x' }}> {translate("$__" + key)} : <span style={{ fontWeight: 'bolder' }}>{val}</span></div>
                                                                </div>
                                                            } else return null
                                                        })}

                                                    </div>
                                                </div>;
                                            }}
                                            indexBy={"id"} //data
                                            margin={{ top: 40, right: 20, bottom: 30, left: 40 }}
                                            padding={0.3}
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: false }}
                                            enableLabel={false}
                                            yScale={{
                                                max: 100,
                                            }}
                                            minValue={0}
                                            theme={{
                                                fontSize: '14px',
                                                color: 'white',
                                                background: '#f5f5f5',
                                                axis: {
                                                    "ticks": {
                                                        "text": {
                                                            fill: 'rgba(0, 0, 0, 0.50)',
                                                            "fontSize": 14,
                                                            //cor dos numeros
                                                        },
                                                    }
                                                },
                                            }}

                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{
                                                from: 'color',
                                                modifiers: [
                                                    [
                                                        'darker',
                                                        1.6
                                                    ]
                                                ]
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={!props.preview ? {
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: 'middle',
                                                legendOffset: 32,
                                                format: (v) => getNames(v),
                                            } : null}
                                            axisLeft={!props.preview ? {
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legendPosition: 'middle',
                                                legendOffset: -40
                                            } : null}
                                            role="application"
                                        /> </div> : <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ alignSelf: 'center' }}>  <div className="loader"></div></div>
                                    </div>}
                            </div>
                        </div>
                        <div style={{ height: '15%', maxHeight: '60px', display: 'flex', backgroundColor: 'white' }}>
                            <Legend
                                legend={[...keysLabel.current.map((a, i) => {
                                    return {
                                        key: i,
                                        color: a.color,
                                        label: a.label
                                    }
                                })]}
                                customColors={filter?.colorsArr}
                            />
                        </div>
                    </div >
                )}
            </ViewportChecker>
        </div>
    )
}

export default Barchart

// ID_CREATED*************************

//CORE
import React from "react"
import ColorPicker from 'rc-color-picker';


//REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as reduxActions from "store/actions"
import reduxStore from "store/"

//COMPONENTS
import CustomButton from "components/Buttons/custom"
import FakeExpanded from "components/Card/fakeCardExpanded"
import GradientColor from "components/ColorPicker/Gradient"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import HeaderWithMenu from "components/Header/card"
import Icon from "components/Icon"
import ImgCropper from "components/Cropper"
import LinearProgress from "components/Progress/linear"
import TextField from "components/TextField"
import { Paper } from "@material-ui/core"

//@MATERIAL
import InputAdornment from "@material-ui/core/InputAdornment"
import { withStyles } from "@material-ui/core/styles"

//STYLES
import styles from "assets/jss/material-dashboard-pro-react/components/sideModule-Card.js"

//FUNCTIONS
import { upload } from "functions/upload";
import {
    translate,
    customApp,
} from "functions/"
import ErrorBoundary from "components/ErrorBoundary"

import 'rc-color-picker/assets/index.css';
import 'react-linear-gradient-picker/dist/index.css';


function WhiteLabelView(props) {
    const { session } = reduxStore.getState()
    const { custom } = session
    const [data, setData] = React.useState(session.custom || {})
    const [iniData, setIniData] = React.useState({})
    const [newData, setNewData] = React.useState({})
    const [logo, setLogo] = React.useState(null)
    const [background, setBackground] = React.useState(null)
    const [fieldsRefresh, setFieldsRefresh] = React.useState(false)
    const [saveDataLoading, setSaveDataLoading] = React.useState(false)

    React.useEffect(() => {
        let isSubscribed = true
        if (isSubscribed)
            setIniData(session.custom || {})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (fieldsRefresh)
            setTimeout(() => {
                setFieldsRefresh(false)
            }, 500)
    }, [fieldsRefresh])

    const setCustom = (d) => {
        props.reduxFunction("ASYNC", "SET_SESSION", {
            ...reduxStore.getState().session,
            custom: {
                ...reduxStore.getState().session.custom,
                [d.name]: d.value
            }
        });
        setNewData({
            ...newData,
            [d.name]: d.value
        })
        setData({
            ...data,
            [d.name]: d.value
        })
    }

    const resetData = async () => {
        setData(iniData)
        setNewData({})
        props.reduxFunction("ASYNC", "SET_SESSION", {
            ...reduxStore.getState().session,
            custom: iniData
        });
    }

    const saveData = async () => {
        setSaveDataLoading(true)
        let newCustom = {
            ...custom?.custom ? JSON.parse(custom?.custom) : {},
            ...newData,
        }
        setData(newCustom)
        setIniData(newCustom)


        try {

            let imageResult = logo ? await upload(session.account, logo) : true
            let backgroundResult = background ? await upload(session.account, background) : true
            if (imageResult && backgroundResult) {
                if (logo) {
                    newCustom = {
                        ...newCustom,
                        customLogo: imageResult
                    }
                }
                if (background) {
                    newCustom = {
                        ...newCustom,
                        loginBackground: backgroundResult
                    }
                }

                // console.log(newCustom)
                // return false
                const { socket } = reduxStore.getState().functions
                socket.emit("data", {
                    module: "account",
                    method: "post",
                    action: "save"
                }, {
                    custom: JSON.stringify(newCustom)
                }, response => {
                    setSaveDataLoading(false)
                    setNewData({})
                }
                )
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <GridContainer>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                        }}>{translate("$__logo")}</p>
                        <ImgCropper
                            maxWidthOrHeight={600}
                            img={custom.customLogo}
                            onChange={(a, file) => {
                                setLogo(file)
                                setCustom({
                                    name: 'customLogo',
                                    value: a
                                })
                            }}
                            transparent
                            ignoreAspect={true}
                            noGuides
                            viewMode={0}
                            accept={".png"}
                            checkOrientation
                        />
                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                        }}>{translate("$__loginBackground")}</p>
                        <div style={{width:'200px', display:'flex', margin:'10px auto'}}>
                            <ImgCropper
                                maxWidthOrHeight={2000}
                                img={custom.loginBackground}
                                onChange={(a, file) => {
                                    setBackground(file)
                                    setCustom({
                                        name: 'loginBackground',
                                        value: a
                                    })
                                }}
                                transparent
                                ignoreAspect={true}
                                noGuides
                                viewMode={0}
                                accept={".jpg, .jpeg"}
                                checkOrientation
                            />
                        </div>
                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                            marginBottom:'10px'
                        }}>{translate("$__backgroundColumnsTitle")}</p>
                        <HeaderWithMenu
                            title={translate(`$__backgroundColumnsTitle`, 1)}
                            icon={'gradient'}
                            color={customApp('colorText')}
                            style={{
                                background: customApp('ColumnTitleColor'),
                                padding: '7px',
                                margin:'10px auto',
                                width:'90%',
                            }}
                        />
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexFlow: "wrap"
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'-15px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__background")}</div>
                                <ErrorBoundary>
                                    <GradientColor value={
                                        {
                                            css: custom.ColumnTitleColor
                                        }

                                    }
                                    style={{padding:'0'}}
                                        onChange={(e) => {
                                            setCustom({
                                                name: 'ColumnTitleColor',
                                                value: e.css
                                            })
                                        }}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <ErrorBoundary>
                                <div style={{ marginRight: 7 }}>{translate("$__text")}</div>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.colorText || null}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'colorText',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topLeft"}
                                    />
                                </ErrorBoundary>
                            </div>
                        </div>

                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                            marginBottom:'10px',
                        }}>{translate("$__menu")}</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexFlow: "wrap"
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'-15px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__background")}</div>
                                <ErrorBoundary>
                                    <GradientColor value={
                                        {
                                            css: custom.menuColor
                                        }

                                    }
                                        onChange={(e) => {
                                            setCustom({
                                                name: 'menuColor',
                                                value: e.css
                                            })
                                        }}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <ErrorBoundary>
                                    <div style={{ marginRight: 7 }}>{translate("$__menuText")}</div>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.menuButtonColor}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'menuButtonColor',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <ErrorBoundary>
                                    <div style={{ marginRight: 7 }}>{translate("$__menuActiveText")}</div>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.active}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'active',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                        </div>

                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                            marginBottom:'10px',
                        }}>{translate("$__submenu")}</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexFlow: "wrap"
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'-15px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__background")}</div>
                                <ErrorBoundary>
                                    <GradientColor value={
                                        {
                                            css: custom.subMenuColor
                                        }

                                    }
                                        onChange={(e) => {
                                            setCustom({
                                                name: 'subMenuColor',
                                                value: e.css
                                            })
                                        }}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__menuTextIconColor")}</div>
                                <ErrorBoundary>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.subMenuTextColor}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'subMenuTextColor',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__subMenuActiveText")}</div>
                                <ErrorBoundary>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.subMenuTextColorActive}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'subMenuTextColorActive',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                        </div>

                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            margin: "0px",
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                            marginBottom:'10px',
                        }}>{translate("$__complement")}</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            flexFlow: "wrap"
                        }}>
                            <div style={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'-15px 10px',
                            }}>


                                <div style={{ marginRight: 7 }}>{translate("$__progress")}</div>
                                <ErrorBoundary>
                                    <GradientColor value={
                                        {
                                            css: custom.linearProgress
                                        }

                                    }
                                        onChange={(e) => {
                                            setCustom({
                                                name: 'linearProgress',
                                                value: e.css
                                            })
                                        }}
                                    />
                                </ErrorBoundary>
                                <LinearProgress customColor={custom.linearProgress} />
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <div style={{ marginRight: 7 }}>{translate("$__borderColor")}</div>
                                <ErrorBoundary>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.color}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'color',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                            <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                width: "100%",
                                justifyContent: "flex-start",
                                margin:'0px 10px',
                            }}>

                                <div style={{ marginRight: 7, fontWeight: "bold", color: customApp("menu") }}>{translate("$__primaryText")}</div>
                                <ErrorBoundary>
                                    <ColorPicker
                                        enableAlpha={false}
                                        animation="slide-up"
                                        color={custom.menu}
                                        mode={"RGB"}
                                        onChange={c => {
                                            setCustom({
                                                name: 'menu',
                                                value: c.color
                                            })
                                        }}
                                    // placement={"topRight"}
                                    />
                                </ErrorBoundary>
                            </div>
                        </div>

                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                    <p style={{
                        textAlign: "center",
                        width: "100%",
                        padding: "10px",
                        margin: "0px",
                        borderBottom: "1px solid #eee",
                        color: "#6d6d6d",
                        marginBottom:'10px',
                    }}>{translate("$__cardImageCover")}</p>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "flex-start",
                        
                    }}>
                        <p style={{ display: "flex", alignItems: "center" ,margin:'-25px 10px',}}>{translate("$__cardImageCover")}

                            <GradientColor value={
                                {
                                    css: custom.AvatarBackground,

                                }
                            }
                                onChange={(e) => {
                                    setCustom({
                                        name: 'AvatarBackground',
                                        value: e.css
                                    })
                                }}
                                type={"radial"}
                            />
                            <ColorPicker
                                enableAlpha={false}
                                animation="slide-up"
                                color={custom.avatarIconColor}
                                mode={"RGB"}
                                onChange={c => {
                                    setCustom({
                                        name: 'avatarIconColor',
                                        value: c.color
                                    })
                                }}
                            // placement={"topRight"}
                            />
                        </p>
                    </div>
                    <div style={{padding:'10px'}}>
                        <FakeExpanded
                            backgroundColor={custom.AvatarBackground}
                            avatarIconColor={custom.avatarIconColor}
                            responsive
                        />
                    </div>
                </Paper>
            </GridItem>
            <GridItem
                md={4}
            >
                <Paper style={{ marginTop: 15, width: "300px", height: "220px", }}>
                        <p style={{
                            textAlign: "center",
                            width: "100%",
                            padding: "10px",
                            marginBottom:'10px',
                            borderBottom: "1px solid #eee",
                            color: "#6d6d6d",
                        }}>
                            {translate("$__fields")}
                        </p>
                        <div style={{
                            display: "flex",
                            alignItems: "flex-start",
                            width: "100%",
                            justifyContent: "flex-start",
                            margin:'0px 10px',
                        }}>

                            <div style={{ marginRight: 7 }}>{translate("$__line")}</div>
                            <ErrorBoundary>
                                <ColorPicker
                                    enableAlpha={false}
                                    animation="slide-up"
                                    color={custom.line}
                                    mode={"RGB"}
                                    onChange={c => {
                                        setCustom({
                                            name: 'line',
                                            value: c.color
                                        })
                                        setFieldsRefresh(true)
                                    }}
                                // placement={"topRight"}
                                />
                            </ErrorBoundary>
                        </div>
                        <div style={{
                            display: "flex",
                            alignItems: "flex-start",
                            width: "100%",
                            justifyContent: "flex-start",
                            margin:'0px 10px',
                        }}>

                            <div style={{ marginRight: 7 }}>{translate("$__inputAdorment")}</div>
                            <ErrorBoundary>
                                <ColorPicker
                                    enableAlpha={false}
                                    animation="slide-up"
                                    color={custom.medium}
                                    mode={"RGB"}
                                    onChange={c => {
                                        setCustom({
                                            name: 'medium',
                                            value: c.color
                                        })
                                        setFieldsRefresh(true)
                                    }}
                                // placement={"topRight"}
                                />
                            </ErrorBoundary>
                        </div>

                        <div style={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                        }}>
                        {!fieldsRefresh ?
                            <TextField
                                label={translate("$__field", 1)}
                                variant={custom.fieldVariant}
                                name={"field"}
                                // InputLabelProps={{
                                //     shrink: true,
                                // }}
                                InputProps={{
                                    autoComplete: "new-password",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon icon="format_color_fill" color={custom.medium} />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{width:'90%', margin:'0 auto'}}
                            />
                            : <TextField
                                label={translate("$__field", 1)}
                                variant={custom.fieldVariant}
                                name={"field"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    autoComplete: "new-password",
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon icon="format_color_fill" />
                                        </InputAdornment>
                                    ),
                                }}
                                style={{width:'90%', margin:'0 auto'}}
                            />
                        }
                        </div>
                </Paper>
            </GridItem>
            {
                JSON.stringify(data) !== JSON.stringify(iniData) ?
                    <div style={{
                        position: "fixed",
                        bottom: 50,
                        right: 50,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}>
                        <div >
                            <CustomButton
                                // shadow
                                title={translate("$__reset")}
                                text={translate("$__reset")}
                                color="red"
                                // tr
                                icon={"format_color_reset"}
                                size={"25px"}
                                onClick={() => {
                                    resetData()
                                }}
                                load={saveDataLoading}
                            />
                        </div>
                        <div style={{ marginLeft: 15 }}>
                            <CustomButton
                                // shadow
                                title={translate("$__save")}
                                text={translate("$__save")}
                                color="green"
                                // tr
                                icon={"save"}
                                size={"25px"}
                                onClick={() => {
                                    saveData()
                                    // setEditor(false)
                                }}
                                load={saveDataLoading}
                            />
                        </div>

                    </div>
                    : <React.Fragment></React.Fragment>
            }
        </GridContainer>
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(null, mapDispatchToProps)(withStyles(styles)(WhiteLabelView))

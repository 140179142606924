//FUNCTIONS
const style = () => ({
    feed: {
        backgroundColor: 'rgba(255,255,255,1)',
        margin: 'auto',
        boxShadow: '0 0 7px 3px rgba(0,0,0,0.05)',
        width: "-webkit-fill-available",
        marginBottom: "15px",
        borderTopRightRadius: 22,
    },
    infos: {
        padding: '7px',
        cursor: "pointer"
    },
    feedRotate: {
        width: 'calc(20% - 20px)',
        minWidth: '250px',
        maxWidth: '400px',
        backgroundColor: 'rgba(255,255,255,1)',
        padding: '7px 7px 20px 7px',
        boxShadow: '0 0 7px 3px rgba(0,0,0,0.05)',
        placeSelf: 'flex-end',
        cursor: 'pointer'
    },
    image: {
        width: '100%',
        paddingTop: `calc((9 / 16) * 100%)`,
        backgroundPosition: 'center',
        backgroundRepeat: "no-repeat"
    },
    linkTitle: {
        marginTop: '7px',
        width: '100%',
        height: 'auto',
        fontWeight: 'bold',
        minHeight: "65px"
    },
    linkContent: {
        width: '100%',
        marginTop: '7px',
        height: '87px',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    feedFooter: {
        width: '100%',
        cursor: "auto",
        textAlign: 'right',
        justifyContent: 'space-between',
        paddingTop: '10px',
        display: 'flex',
        paddingBottom: '7px'
    },
    feedDate: {
        position: 'relative',
        left: '0px',
        fontSize: '12px',
        float: 'left'
    },
    feedLink: {
        position: 'relative',
        left: '0px',
        fontSize: '12px',
        float: 'right',
        display: 'flex'
    }
})

export default style;
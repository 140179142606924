//FUNCTIONS
import { customApp } from "functions/";

const style = theme => ({
    backdrop: {
        zIndex: 4000,
    },
    header: {
        position: 'absolute', top: '0px', left: '0px', right: '0px', height: '50px'
    },
    btClose: {
        position: 'absolute', right: '15px', top: '15px'
    },
    content: {
        position: 'absolute',
        top: '50px',
        bottom: '50px',
        left: '30px',
        right: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        position: 'absolute', top: 'calc(50% - 20px)', left: 'calc(50% - 20px)', width: '40px', height: '40px',
        '& .MuiCircularProgress-root': {
            color: `${customApp('color')} !important`,
        },
    },
    img: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    movie: {
        position: 'absolute',
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
    },
    embed: {
        position: 'absolute',
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
    },
    leftButtom: {
        position: 'absolute',
        top: '50px',
        bottom: '50px',
        left: '0px',
        width: '30px',
        display: 'flex',
        alignItems: 'center'
    },
    rightButtom: {
        position: 'absolute',
        top: '50px',
        bottom: '50px',
        width: '30px',
        right: '0px',
        display: 'flex',
        alignItems: 'center'
    },
    footer: {
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        right: '0px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
})

export default style;
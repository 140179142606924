import React, { useState } from 'react'
import GridContainer from 'components/Grid/GridContainer';
import Griditem from 'components/Grid/GridItem'
import Intro from './Intro';
import { v4 as uuid } from "uuid";

// import Article from './Article';
import { GetHeader, GetModal, BoxModal, plans, BoxText, ListItem, GetButton } from './getElements';
import { addCard } from "../Quick/functions"

//COMPONENTS
import PreLoader from "components/PreLoader"

//REDUX
import * as reduxActions from "store/actions"
import history from "store/history"
import reduxStore from "store"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"

import { translate } from "functions"

import {
    loadCardsV2,
} from "functions/loadData"

const Plantype = (props) => {
    const { handleClose } = props
    const { session } = reduxStore.getState()
    const [selectedPlan, setSelectedPlan] = useState(plans[0])
    const [screen, setScreen] = useState({
        intro: true,
        type: false,
        article: false
    })
    const [loader, setLoader] = useState(false)

    const handleItemClick = (index, item) => {
        setSelectedPlan({
            ...selectedPlan.name ? { name: selectedPlan.name } : {},
            ...item,
            index: index,
        })

    };

    const handleNameInput = (name) => {
        setSelectedPlan({
            ...selectedPlan,
            name: name,
        })
    };



    const PlanList = () => (
        <ul
            id={'testId11'}
            style={{
                width: '100%',
                height: '344px',
                listStyle: 'none',
                marginTop: '13px',
                padding: '5px 10px 10px 0px',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                paddingLeft: '0',
                border: '1px solid #ebebeb'
            }}
        >
            {plans.map((item, index) => (
                <ListItem
                    key={item.id}
                    item={item}
                    index={index}
                    handleItemClick={handleItemClick}
                    selectedPlan={selectedPlan}
                />
            ))}
        </ul>
    );

    const createPlan = async () => {
        setLoader(true)
        let newId = uuid()
        let result = await addCard({
            _id: newId,
            name: selectedPlan.name,
            type: "plan",
            planType: selectedPlan.value,
            users: [session._id]
        }, 0)
        if (result) {
            let lc = await loadCardsV2({ ...props, ignoreLoader: true }, { cardLoad: 'card', idRel: newId })
            if (lc) {
                history.push(`sp#${newId}/dashboard`)//#CORRECT
                setLoader(false)
                handleClose()
            }
        }
    }



    return (
        <GetModal>
            {screen.intro && <>
                <BoxModal id='Plantype_67' widthSize={"700px"}>
                    <Intro
                        handleNextScreen={() => setScreen({ ...screen, intro: false, type: true })}
                        handleName={handleNameInput}
                        handleClose={handleClose}
                    />
                </BoxModal>
            </>}
            <BoxModal  >
                {screen.type && <div id='Plantype_672'>
                    <GetHeader prev={true} name={translate("$__selectPlanType")} handlePrevScreen={() => setScreen({ ...screen, type: false, intro: true })} handleClose={handleClose} />
                    <GridContainer container spacing={0} style={{ width: '92%', marginLeft: '4%' }}>
                        <Griditem item xs={12} style={{ display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
                            <Griditem item xs={5} style={{ marginBottom: '10px', marginTop: '10px' }} >
                                <PlanList style={{ transition: '0.7s' }} />
                                {/* <GetAddTemplate /> */}
                            </Griditem>
                            <Griditem item xs={7} style={{ display: 'flex', marginTop: '10px', flexDirection: 'column', }}>
                                <div style={{
                                    padding: '20px',
                                    marginLeft: '25px',
                                    width: '400px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    border: '1px solid #ebebeb',
                                    marginTop: '14px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                                }}>
                                    <img alt="MACHEN_IMAGE" src={selectedPlan.gif} style={{
                                        width: '350px',
                                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                                        alignSelf: "center",
                                        height: '180px',
                                        zIndex: '100'
                                    }} />
                                </div>
                                <BoxText tittle={selectedPlan.type} text={translate(selectedPlan.description ? selectedPlan.description : plans[0].description, 1)} />
                            </Griditem>
                        </Griditem>

                        <Griditem item xs={12}>
                            {loader ?
                                <div style={{ width: '100%', display: 'flex', justifyContent: "center" }}><PreLoader /></div>
                                :
                                <GetButton
                                    id="Plantype_110"
                                    name={translate("$__create")}
                                    handleEvent={() => {
                                        createPlan()
                                    }}
                                    style={{
                                        width: '50%',
                                        marginLeft: '25%',
                                        marginTop: '35px',
                                        marginBottom: '45px',
                                        border: "2px solid #ebebeb",
                                    }}>
                                </GetButton>
                            }
                        </Griditem>
                    </GridContainer>  </div>}
                {/* {screen.article && <Article change={() => setScreen({ ...screen, type: false })} type={`infoType`} />} */}
            </BoxModal >
        </GetModal >
    )
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(reduxActions, dispatch)

export default connect(
    null,
    mapDispatchToProps
)(Plantype)